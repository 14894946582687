import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Demographic } from './demographic.model';

export enum ChangeRequestStatus {
    Pending = 'pending',
    Approved = 'approved',
    Denied = 'denied'
}

export interface ChangeRequest {
    id: string;
    created: firebase.firestore.FieldValue;
    last_updated: firebase.firestore.FieldValue;
    form_id: string;
    subprocess_id: string;
    process_id: string;
    module_id: string;
    deployment_id: string;
    requesting_user_id: string;
    approving_user_id?: string;
    question_id: string;
    question_name: string;
    current_value: any;
    requested_value: any;
    demographics_to_change: Demographic[];
    status: ChangeRequestStatus;
}
