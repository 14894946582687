<div class="import-questions-dialog">
  <h3 mat-dialog-title class="h3">Import {{data.entity || 'questions'}}</h3>
  <mat-dialog-content class="import-questions-content">
    <mat-form-field class="questions-list textarea">
      <textarea
        matInput
        matTextareaAutosize
        matAutosizeMinRows="5"
        matAutosizeMaxRows="10"
        cdkFocusInitial
        placeholder="{{placeholder}}"
        [(ngModel)]="questionsList"
        (ngModelChange)="onQuestionsListChange()"
      ></textarea>
    </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="import-questions-actions">
    <button class="tt-button-filled" (click)="onImport()">{{data.confirmBtnText}}</button>
    <button mat-dialog-close class="tt-button-outline">{{data.cancelBtnText}}</button>
  </mat-dialog-actions>
</div>
