import { ObjectType } from './deployment.model';
import { DeploymentItem } from './deployment-item';

export class Subprocess extends DeploymentItem {
    module_id?: string;
    process_id?: string;
    type = ObjectType.Subprocess;

    constructor(subprocess: Subprocess) {
        super(subprocess);
        this.module_id = subprocess.module_id || '';
        this.process_id = subprocess.process_id || '';
    }
}

export const testSubprocess = {
    id: 'test',
    deployment_id: 'test',
    name: 'test',
    module_id: 'testModule',
    process_id: 'testProcess',
    global: false,
    object_type: 'subprocess',
    type: ObjectType.Process
};
