import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnChanges {
    @Input() placeholder?: boolean;
    @Input() currentSearchValue?: string;
    @Output() onChange = new EventEmitter<string>();
    @ViewChild('search', { static: true }) search: ElementRef;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.currentSearchValue && this.currentSearchValue === '') {
            this.clearSearch();
        }

        if (changes.currentSearchValue && changes.currentSearchValue.firstChange && this.currentSearchValue) {
            this.search.nativeElement.value = this.currentSearchValue;
        }
    }

    clearSearch(): void {
        this.search.nativeElement.value = '';
        this.searchChanged('');
    }

    searchChanged(value: string): void {
        this.onChange.emit(value);
    }
}
