import { Module } from './module.model';
import { Process } from './process.model';
import { Subprocess } from './subprocess.model';
import { User } from './user.model';
import { Ownership } from './ownership.model';
import { Demographic, ChangeRequest } from '@thinktank/common-lib';

export interface ModuleViewState {
    deploymentId: string;
    authenticatedUserId: string;
    module: Module;
    processes: Process[];
    subprocesses: Subprocess[];
    selectedDemographics: Demographic[];
    deploymentOwnership: Ownership[];
    changeRequests: ChangeRequest[];
    moduleManager: User;
    isLoading: boolean;
}

export const initialModuleState = {
    deploymentId: '',
    authenticatedUserId: '',
    module: <Module>{},
    processes: [],
    subprocesses: [],
    selectedDemographics: [],
    deploymentOwnership: [],
    changeRequests: [],
    moduleManager: <User>{},
    isLoading: true
}

export interface ModuleEditorViewState {
    deploymentId: string;
    moduleId: string;
    moduleDemographics: Demographic[];
    deploymentDemographics: Demographic[];
    isLoading: boolean;
}

export const initialModuleEditorViewState = {
    deploymentId: '',
    moduleId: '',
    moduleDemographics: [],
    deploymentDemographics: [],
    isLoading: true
}
