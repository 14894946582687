import { of as observableOf, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CognitoAuth } from 'amazon-cognito-auth-js';

import { environment } from '@env/environment';

class CognitoAuthWithSecret extends CognitoAuth {
    private readonly clientSecret: string;
    private readonly clientId: string;

    constructor(config: any) {
        super(config);
        this.clientId = config.ClientId;
        this.clientSecret = config.ClientSecret;
    }

    getCognitoConstants(): any {
        const constants = super.getCognitoConstants();

        if (!!this.clientSecret) {
            const clientIdSecret = `${this.clientId}:${this.clientSecret}`;

            constants.HEADER = <any>{
                ...constants.HEADER,
                Authorization: `Basic ${window.btoa(clientIdSecret)}`,
            };
        }

        return constants;
    }
}

@Injectable()
export class AwsCognitoAuthService {
    private auth: CognitoAuthWithSecret;

    constructor() {
        const awsConfig = environment['aws'];

        if (!awsConfig) {
            return;
        }

        const authData = {
            ClientId: awsConfig.cognito.clientId,
            ClientSecret: awsConfig.cognito.clientSecret,
            AppWebDomain: awsConfig.cognito.appWebDomain,
            TokenScopesArray: awsConfig.cognito.tokenScopesArray,
            RedirectUriSignIn: awsConfig.cognito.redirectUriSignIn,
            RedirectUriSignOut: awsConfig.cognito.redirectUriSignOut,
            UserPoolId: awsConfig.cognito.userPoolId,
            AdvancedSecurityDataCollectionFlag: false,
        };

        this.auth = new CognitoAuthWithSecret(authData);
        this.auth.userhandler = {
            onSuccess: () => console.log('auth onSuccess called'),
            onFailure: error => console.log(`auth onFailure called: ${error}`),
        };
        this.auth.useCodeGrantFlow();
    }

    parseTokens(url: string): void {
        this.auth.parseCognitoWebResponse(url);
    }

    signOut(): Observable<void> {
        this.auth.signOut();

        return observableOf(undefined);
    }

    getIdToken(): string {
        return this.auth.getSignInUserSession().getIdToken().getJwtToken();
    }
}
