import { User } from './user.model';

export class OverlayTemplate {
    id: string;
    name: string;
    owners: User[];
    core_template_id: string;
    creator: string;
    created_date: Date;
    last_updated: Date;
}
