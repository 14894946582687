import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includes',
    pure: true
})
export class IncludesPipe implements PipeTransform {

    transform(array: string[], value: string): boolean {
        return !!(array || []).find(item => item === value);
    }

}
