import { createReducer, on, Action } from '@ngrx/store';

import * as FeatureTogglesActions from './feature-toggles.actions';
import { FeatureToggles } from '../../../core/models/feature-toggles.models';
import { BaseStateModel } from '../../models/base-state.model';

export const FEATURE_TOGGLES_FEATURE_KEY = 'featureToggles';

export interface FeatureTogglesState extends BaseStateModel {
    featureToggles: FeatureToggles;
}

export interface FeatureTogglesPartialState {
    readonly [FEATURE_TOGGLES_FEATURE_KEY]: FeatureTogglesState;
}

export const initialState: FeatureTogglesState = {
    featureToggles: {},
    loading: false,
    errorMessage: null
};

const featureTogglesReducer = createReducer(
    initialState,
    on(FeatureTogglesActions.loadFeatureToggles, (state) => ({
        ...state,
        loading: true,
        errorMessage: null
    })),
    on(FeatureTogglesActions.loadFeatureTogglesSuccess, (state, { featureToggles }) => ({
        ...state,
        featureToggles,
        loading: false
    })),
    on(FeatureTogglesActions.loadFeatureTogglesFailure, (state, { errorMessage }) => ({
        ...state,
        errorMessage
    }))
);

export function reducer(state: FeatureTogglesState | undefined, action: Action) {
    return featureTogglesReducer(state, action);
}
