<table #table matSort mat-table class="thinktank-theme" [dataSource]="dataSource">
  <!-- User Column -->
  <ng-container matColumnDef="firstName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>User</th>
    <td mat-cell *matCellDef="let element" class="user-cell">{{ element.firstName }} {{ element.lastName }}</td>
  </ng-container>
  <!--/ User Column -->

  <!-- Email Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
    <td
      mat-cell
      *matCellDef="let element"
      [ngClass]="{
        gray: element.email === userWithoutEmailText
      }"
    >
      {{ element.email }}
    </td>
  </ng-container>
  <!--/ Email Column -->

  <!-- Role Column -->
  <ng-container matColumnDef="app_role">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Role</th>
    <td mat-cell *matCellDef="let element">{{ element.app_role }}</td>
  </ng-container>
  <!--/ Role Column -->

  <!-- Last Login Column -->
  <ng-container matColumnDef="last_login">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Last login</th>
    <td mat-cell *matCellDef="let element">
      <ng-container *ngIf="element['last_login']">
        {{ element["last_login"] | date: "mediumDate" }} at {{ element["last_login"] | date: "shortTime" }}
      </ng-container>
    </td>
  </ng-container>
  <!--/ Last Login Column -->

  <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: tableColumns" (click)="navigateToProfile(row.key)"></tr>
</table>

<!-- Message if no results after search -->
<div *ngIf="!dataSource?.data?.length" class="empty-search-results">
  <p class="empty-search-results-text text">There are no results related to your search.</p>
</div>
<!--/ Message if no results after search -->
