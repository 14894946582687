import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'gs-confirm-action-dialog',
    templateUrl: './confirm-action-dialog.component.html',
    styleUrls: ['./confirm-action-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ConfirmActionDialogComponent implements OnInit {
    isRemove: boolean;
    textAsHtml: SafeHtml;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmActionDialogComponent>,
        private sanitizer: DomSanitizer
    ) {}

    handleConfirm(): void {
        const { handleConfirm, closeOnConfirm } = this.data;
        handleConfirm();

        if (closeOnConfirm) {
            this.dialogRef.close();
        }
    }

    handleCancel(): void {
        const { handleCancel } = this.data;
        if (!!handleCancel) {
            handleCancel();
        }

        this.dialogRef.close();
    }

    ngOnInit() {
        const confirmText = this.data.confirmBtnText.toLowerCase();
        this.isRemove = confirmText === 'remove'
            || confirmText === 'delete';

        if (this.data.isDisplayLikeHtml) {
            this.textAsHtml = this.sanitizer.bypassSecurityTrustHtml(this.data.text);
        }
    }
}
