<!-- Image -->
<ng-container *ngIf="question.type === 'image'">
  <div class="image-wrapper">
    <img src="{{question.image_url}}" alt="{{question.description}}" (click)="enlargeImage()" />
  </div>
</ng-container>
<!-- /Image -->

<!-- Video -->
<ng-container *ngIf="question.type === 'video'">
  <div class="video-wrapper">
    <iframe [src]="videoUrl" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
  </div>
</ng-container>
<!-- Video -->
