import { Module } from './module.model';
import { Process } from './process.model';
import { Subprocess } from './subprocess.model';
import { Demographic } from '@thinktank/common-lib';
import { Deployment } from './deployment.model';
import { Ownership } from './ownership.model';

export interface DeploymentViewState {
    deployment: Deployment;
    modules: Module[];
    processes: Process[];
    subprocesses: Subprocess[];
    selectedDemographics: Demographic[];
    deploymentManager: string;
    ownership: Ownership[];
    authenticatedUserId: string;
    isUserOwner: boolean;
    allCount: number;
    modulesCount: number;
    processesCount: number;
    subprocessesCount: number;
    isLoading: boolean;
}

export const initialDeploymentState = {
    deployment: <Deployment>{},
    modules: [],
    processes: [],
    subprocesses: [],
    selectedDemographics: [],
    ownership: [],
    deploymentManager: '',
    authenticatedUserId: '',
    isUserOwner: false,
    allCount: 0,
    modulesCount: 0,
    processesCount: 0,
    subprocessesCount: 0,
    isLoading: true
}

export interface DeploymentViewEditState {
    deployment: Deployment;
    modules: Module[];
    selectedDemographics: Demographic[];
    globalDemographics: Demographic[];
    isLoading: boolean;
}

export const initialDeploymentViewEditState = {
    deployment: {} as Deployment,
    modules: [],
    selectedDemographics: [],
    globalDemographics: [],
    isLoading: true
}
