import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

import { isEqual } from 'lodash';

@Component({
    selector: 'gs-section-description',
    templateUrl: './section-description.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionDescriptionComponent implements OnInit, OnDestroy, OnChanges {

    @Input() sectionId: string;
    @Input() repeatedSectionId: string;
    @Input() parentForm: FormGroup;
    @Input() demographicId: string;
    @Input() formId: string;
    @Input() deploymentId: string;

    private destroySubject = new Subject<void>();

    constructor(@Inject(DeploymentFormFacadeToken) private formFacade: DeploymentFormFacade) {
    }

    get uniqueId(): string {
        return `description-${this.demographicId}-${this.repeatedSectionId}`;
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log('SectionDescriptionComponent::ngOnChanges - changes', changes);
    }

    ngOnInit() {
        this.onValueChanges();
    }

    ngOnDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();
    }

    private onValueChanges(): void {
        this.parentForm.get('description')
            .valueChanges
            .pipe(
                startWith(''),
                pairwise(),
                takeUntil(this.destroySubject)
            )
            .subscribe(([ previous, current ]) => {
                if (!isEqual(previous, current)) {
                    console.log('SectionDescriptionComponent::onValueChanges - previous', previous);
                    console.log('SectionDescriptionComponent::onValueChanges - current', current);
                    this.formFacade.updateSectionDescription({
                        description: current,
                        sectionId: this.sectionId,
                        repeatedSectionId: this.repeatedSectionId,
                        demographicId: this.demographicId,
                        formId: this.formId,
                        deploymentId: this.deploymentId
                    });
                }
            });
    }
}
