import { Component, OnChanges, Input, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';

@Component({
    selector: 'gs-empty-screen',
    templateUrl: './empty-screen.component.html',
    styleUrls: ['./empty-screen.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyScreenComponent implements OnChanges {
    @Input() imageUrl: string;
    @Input() text?: string;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
    }
}
