import {
    Component,
    ChangeDetectionStrategy,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges
} from '@angular/core';
import { MatChipInputEvent } from '@angular/material/chips';
import { ENTER } from '@angular/cdk/keycodes';
import {
    AbstractControl,
    FormBuilder,
    FormControl,
    FormGroup,
    Validators
} from '@angular/forms';

import { UserRole } from '../../../models';

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// email validator
export function emailValidator(control: FormControl): { invalidEmail: boolean } | null {
    const value = (control.value || '').trim();

    if (value && !EMAIL_REGEX.test(value)) {
        return { invalidEmail: true };
    } else {
        return null;
    }
}

@Component({
    selector: 'gs-stakeholder-invite',
    templateUrl: './stakeholder-invite.component.html',
    styleUrls: ['./stakeholder-invite.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class StakeholderInviteComponent implements OnChanges {
    @Input() deploymentId: string;
    @Output() onRosterUpdate: EventEmitter<{emailList: {email: string}[], message?: string}> = new EventEmitter();

    inviteForm: FormGroup;
    stakeholders: { email: string }[];
    emailList: { email: string, username?: string, created_at?: number, role?: UserRole }[];
    emailExist: { stakeholders: boolean };
    emailEnter: { stakeholders: boolean };
    rolesMapping: any;
    formSubmitted: boolean;
    separatorKeysCodes = [ENTER];
    UserRole = UserRole;

    emailsErrors: {
        invalidEmails: any[],
        emailsExistInDeployment: {
            stakeholders: any[]
        }
    };
    emailsErrorMessages: {
        stakeholders: string
    };

    constructor(
        public fb: FormBuilder
    ) {
        this.inviteForm = new FormGroup({
            'stakeholders': new FormControl(this.stakeholders,
                Validators.compose([emailValidator])),
            'message': new FormControl('')
        });
        this.stakeholders = [];
        this.emailList = [];
        this.emailExist = { stakeholders: false };
        this.emailEnter = { stakeholders: false };
        this.rolesMapping = {
            stakeholder: this.stakeholders
        };
        this.emailsErrorMessages = {
            stakeholders: ''
        };
        this.resetEmailsErrors();
    }

    get inviteDisabled(): boolean {
        return !this.stakeholders.length;
    }

    get stakeholdersPlaceholder(): string {
        return this.stakeholders.length ? '' : 'Enter email';
    }

    // add action
    add(event: MatChipInputEvent, role: UserRole): void {
        const input = event.input;
        let value = (event.value || '').trim();
        const control: AbstractControl = this.inviteForm.controls[`${role}s`];

        if (value) {

            // Remove all commas and empty spaces
            // Lowercase force
            value = value.replace(/,/g, ' ').replace(/(\s+)/g, ' ');
            value = value.toLowerCase();
            input.value = value;
            const emailValues = value.split(' ');
            this.formSubmitted = true;
            this.emailsErrorMessages[`${role}s`] = '';

            emailValues.forEach((emailValue) => {
                emailValue = emailValue.trim();

                // check if user already exist
                // this.emailExist[`${role}s`] = this.instanceUsers.some((entry) => {
                //     if (emailValue === entry) {
                //         this.emailsErrors.emailsExistInDeployment[`${role}s`].push(emailValue);
                //     }
                //     return emailValue === entry;
                // });

                // check email
                const invalidEmail = !emailValue.match(EMAIL_REGEX);
                if (invalidEmail) {
                    this.emailsErrors.invalidEmails.push(emailValue);
                }
                control.setErrors({ invalidEmail });

                const allowSetEmail = !this.emailExist[`${role}s`]
                    && !this.emailEnter[`${role}s`]
                    && !control.hasError('invalidEmail');
                if (allowSetEmail) {

                    // push to the chips list
                    this.rolesMapping[role].push({
                        email: emailValue
                    });

                    // push to the main list
                    this.pushToEmailList(emailValue, role);
                    input.value = input.value
                        .replace(emailValue, '')
                        .replace(/,/g, ' ')
                        .replace(/(\s+)/g, ' ');
                }
            });

            const invalidEmails = this.emailsErrors.invalidEmails;
            const emailsExistInDeployment = this.emailsErrors.emailsExistInDeployment[`${role}s`];

            let messageText: string;

            const errorsGroup = {
                invalidEmails: {
                    errorsPackage: invalidEmails,
                    errorsName: 'invalidEmails'
                },
                emailsExistInDeployment: {
                    errorsPackage: emailsExistInDeployment,
                    errorsName: 'emailsExistInDeployment'
                }
            };

            const filteredGroups = Object.keys(errorsGroup).filter((key) => {
                return errorsGroup[key].errorsPackage.length > 0;
            });

            const matchGroup = filteredGroups.length > 1 ? 'multiple' : filteredGroups[0];

            switch (matchGroup) {
                case errorsGroup.invalidEmails.errorsName:
                    messageText = invalidEmails.length > 1
                        ? 'Please enter valid email addresses.'
                        : 'Please enter a valid email address.';

                    this.showMessageError(control, `${role}s`, true, false, messageText);
                    break;
                case errorsGroup.emailsExistInDeployment.errorsName:
                    messageText = emailsExistInDeployment.length > 1
                        ? 'Users with such email address are already deployement attendees.'
                        : 'User with this email address is already a deployment attendee.';

                    this.showMessageError(control, `${role}s`, false, true, messageText);
                    break;
                case 'multiple':
                    messageText = `Please enter valid email addresses.`;

                    this.showMessageError(control, `${role}s`, true, false, messageText);
                    break;
            }

            this.onRosterUpdate.emit({emailList: this.emailList, message: this.inviteForm.controls.message.value});
            this.resetEmailsErrors();
        }
    }

    updateSubmitState(role: UserRole): void {
        this.formSubmitted = false;
        this.emailsErrorMessages[`${role}s`] = '';
    }

    remove(value: any, role: UserRole): void {
        // remove from main list
        this.emailList = this.emailList.filter(obj => obj.email !== value.email);

        // remove from from chips list
        const index = this.rolesMapping[role].indexOf(value);
        if (index >= 0) {
            this.rolesMapping[role].splice(index, 1);
        }
        this.onRosterUpdate.emit({emailList: this.emailList, message: this.inviteForm.controls.message.value});
    }

    resetForm(): void {
        this.clearEmailErrors();
        this.resetEmailsErrors();
        this.stakeholders.splice(0, this.stakeholders.length);
        this.emailList = [];
        this.inviteForm.reset();
        this.inviteForm.markAsTouched();

        let control: AbstractControl = null;
        Object.keys(this.inviteForm.controls).forEach((name) => {
            control = this.inviteForm.controls[name];
            control.markAsTouched();
            control.markAsPristine();
            control.setErrors(null);
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!this.deploymentId) {
            this.resetEmailsErrors();
            this.clearEmailErrors();
        }
    }

    // clear errors
    private clearEmailErrors(): void {
        this.formSubmitted = false;
        this.emailExist = { stakeholders: false };
        this.emailEnter = { stakeholders: false };
    }

    private pushToEmailList(emailValue: string, role: UserRole): void {
        this.emailList.push({
            'email': emailValue,
            'username': emailValue,
            'created_at': Date.now(),
            'role': role
        });
    }

    private resetEmailsErrors(): void {
        this.emailsErrors = {
            invalidEmails: [],
            emailsExistInDeployment: {
                stakeholders: []
            }
        };
    }

    private showMessageError (
        control: AbstractControl,
        controlName: string,
        isEmailInvalid: boolean,
        isEmailExistInDeployment: boolean,
        text: string
    ): void {
        control.setErrors({ invalidEmail: isEmailInvalid });
        this.emailExist[controlName] = isEmailExistInDeployment;
        this.emailsErrorMessages[controlName] = text;
    }
}
