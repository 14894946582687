import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginService } from '@app/login/services/login.service';
import { DirectLoginComponent } from './direct-login.component';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [DirectLoginComponent],
    providers: [
        LoginService
    ]
})
export class DirectLoginModule {
}
