import { Component, EventEmitter, Input, OnInit, Output, OnChanges } from '@angular/core';

import { processDuration } from '@app/core/utils';

@Component({
    selector: 'app-time-picker',
    templateUrl: './time-picker.component.html',
    styleUrls: ['./time-picker.component.scss']
})

export class TimePickerComponent implements OnInit, OnChanges {
    @Input() time: string;
    @Input() disabled?: boolean;
    @Input() keyEnterAction?: Function;
    @Input() blurAction?: Function;
    @Output() timeChange = new EventEmitter();
    input: {
        min: string;
        sec: string;
    };

    constructor() {
        this.input = {min: '', sec: ''};
    }

    valueChanges(): string {
        const duration = processDuration(this.input.min, this.input.sec);
        const [min, sec] = duration.split(':');
        this.input = {min, sec};
        this.time = duration;
        this.timeChange.emit(duration);
        return duration;
    }

    setInput(): void {
        const [min, sec] = (this.time || '00:00').split(':');
        this.input = {min, sec};
    }

    ngOnChanges() {
        this.setInput();
    }

    ngOnInit() {
        this.setInput();
    }
}
