import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

import { FirebaseActivityService } from '@app/core/services/activity.service';
import { Activity } from '@app/core/models/activity.model';
import { trackByKey } from '@app/core/utils';

@Component({
    selector: 'app-event-flow-results-dialog',
    templateUrl: './event-flow-results-dialog.component.html',
    styleUrls: ['./event-flow-results-dialog.component.scss']
})
export class EventFlowResultsDialogComponent implements OnInit {
    alertConfirmBtnText: string;
    incorrectActivities: Activity[] = [];
    successActivities: Activity[] = [];
    trackByKey = trackByKey;

    private readonly voteActivityType = 'assessment';

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<EventFlowResultsDialogComponent>,
        private activityService: FirebaseActivityService
    ) {
        this.alertConfirmBtnText = this.data.confirmBtnText;
    }

    onSubmit(): void {
        this.dialogRef.close();
    }

    ngOnInit() {
        const sourceActivityKey = this.data.sourceActivityKey;

        this.checkCorrectnessOfActivities(sourceActivityKey);
    }

    private async checkCorrectnessOfActivities(sourceActivityKey: string): Promise<void> {
        const sourceActivity = await this.activityService.getActivity(sourceActivityKey).pipe((take(1))).toPromise();

        // only vote to vote sharing is possible erroneous
        // so if source activity type is not assessment there are not incorrect activities
        if (sourceActivity.activity_type !== this.voteActivityType) {
            return;
        }

        const destinationActivitiesKeys = sourceActivity.dependent_nodes || {};
        const appKey = sourceActivity.app;
        const appActivities = await this.activityService.getActivitiesByAppInstanceId(appKey).pipe((take(1))).toPromise();

        appActivities.forEach((destinationActivity: Activity) => {

            // skip activities that are not inherited data from the current one
            if (!destinationActivitiesKeys[destinationActivity.key]) {
                return;
            }

            const hasGroups = !!Object.keys(destinationActivity.groups || {}).length;
            const isApplyGroupsOn = !!destinationActivity.apply_groups;

            if (destinationActivity.activity_type === this.voteActivityType && isApplyGroupsOn && !hasGroups) {
                this.incorrectActivities.push(destinationActivity);
            } else {
                this.successActivities.push(destinationActivity);
            }
        });
    }
}
