import { Injectable } from '@angular/core';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { Observable, Subscriber } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { CognitoUtilityService } from './cognito-utility.service';

@Injectable()
export class AwsCognitoService {

    constructor(private cognitoUtilityService: CognitoUtilityService) {}

    changePassword(oldPassword: string, newPassword: string): Observable<void> {
        return this.cognitoUtilityService.getCurrentUser().pipe(mergeMap((cognitoUser: CognitoUser) => {
            return this.cognitoChangePassword(cognitoUser, oldPassword, newPassword);
        }));
    }

    private cognitoChangePassword(cognitoUser: CognitoUser, oldPassword: string, newPassword: string): Observable<void> {
        return new Observable((observer: Subscriber<void>) => {
            cognitoUser.changePassword(oldPassword, newPassword, (error) => {

                if (error) {
                    observer.error(error);
                } else {
                    observer.next(undefined);
                    observer.complete();
                }
            });
        });
    }
}
