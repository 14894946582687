import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { User, Ownership } from '../../../models';
import { ChangeRequest, Demographic, trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-view-change-requests-table',
    templateUrl: './view-change-requests-table.component.html',
    styleUrls: ['./view-change-requests-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ViewChangeRequestsTableComponent {
    @Input() changeRequests: ChangeRequest[];
    @Input() deploymentUsers: (User & Ownership)[];
    trackById = trackById;

    constructor(
        // private dialogService: DialogService
    ) {}

    showRequest(changeRequest: ChangeRequest): void {
        // this.dialogService.open('ViewChangeRequestDialogComponent', {
        //     maxWidth: '600px',
        //     width: '500px',
        //     changeRequestToView: changeRequest
        // });
    }

    getDemographicsAffected(changeRequest: ChangeRequest): string {
        let affectedDemographics = '';
        changeRequest.demographics_to_change.forEach((demographic: Demographic, index) => {
            affectedDemographics += demographic.name;
            if (index < changeRequest.demographics_to_change.length - 1) {
                affectedDemographics += ',';
            }
        });
        return affectedDemographics;
    }

    updateChangeRequestStatus(changeRequest: ChangeRequest, approved: boolean): void {
        // let status = ChangeRequestStatus.Denied;
        // if (approved) {
        //     status = ChangeRequestStatus.Approved;
        // }
        // this.changeRequestService.updateChangeRequestStatus(changeRequest, status);
    }
}
