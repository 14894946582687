export enum TaskType {
    Demographics = 'demographics',
    Present = 'presentation',
    Vote = 'vote',
    Collect = 'collect',
    Source = 'crowdsource',
    Forms = 'form'
}

export enum EngageFormStatus {
    InProgress = 'IN_PROGRESS',
    Draft = 'DRAFT',
    Locked = 'LOCKED',
    DraftReview = 'DRAFT_REVIEW',
    Alignment = 'ALIGNMENT',
    PendingApproval = 'PENDING_APPROVAL',
    Approved = 'APPROVED',
    Denied = 'DENIED'
}

export class Task {
    key?: string;
    name: string;
    type: TaskType;
    sequence: number;
    activity_id: string;
    complete?: {
        [userKey: string]: true
    };
    form_status?: EngageFormStatus;
    demographic_status?: {
        [demographicKey: string]: {
            name: string;
            status: EngageFormStatus;
        }
    };
}

export const testTask = <Task>{
    name: 'test task',
    type: TaskType.Vote,
    sequence: 1,
    activity_id: 'test activity id'
};

export interface TasksCount {
    totalTasksCount: number;
    incompletedTasksCount: number;
    completedTasksCount: number;
}
