import { GraphNode } from './graph-node.model';
import { Variable } from './variable.model';

/*
Section Nodes combine data from multiple questions. A Pick Node takes data for just one question and passes it on.

e.g.
 data{                                                                                    
     questionA: {...},                                                                  data{  
     questionB: {...},    ====>  Pick Node with pick_node_id set to 'questionC'  ==>        questionC: {...}
     questionC: {...}                                                                   }
 }
*/

export class PickNode extends GraphNode {

    type = 'pick';
    pickNodeId: string;
    data: any | any[];

    loadData(data:object) {
        super.loadData(data);
        this.pickNodeId = data['pick_node_id'] || '';
    }

    async propagateValues(demographicId: string): Promise<void> {
        const variable = this.buildVariable(demographicId, this.data);
        await this.propagateVariable(variable);
    }

    async setInput(name: string, variableData: Variable): Promise<void> {
        console.log(`PickNode::setInput - ${this.nodeId} ${name}`, this.consoleLogVariable(variableData));
        if (name === 'input') {
            const demographicId = variableData.demographicId;

            // The value passed through could be an array or an object so we will check it and process it accordingly
            const value = variableData.value || {};
            if (Array.isArray(value)) {
                this.data = value.map((value) => value[this.pickNodeId]).filter((val) => !!val) || [];
                if (this.data.length > 0) {
                    await this.propagateValues(demographicId);
                }
            } else {
                this.data = value[this.pickNodeId];
                if (!!this.data) {
                    await this.propagateValues(demographicId);
                }
            }
        }
    }

}
