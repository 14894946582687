import { Component, Inject, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormGroup, FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { distinctUntilChanged, filter } from 'rxjs/operators';

import { NameValidationService } from '@app/core/services/name-validation.service';
import { specialCharacterValidator } from '@app/shared/validators/special-symbol-validator';
import { validationMessages } from '@app/apps/apps.component.forms-constants';

@UntilDestroy()
@Component({
    selector: 'app-export-info-dialog',
    templateUrl: './export-info-dialog.component.html',
    styleUrls: ['./export-info-dialog.component.scss']
})
export class ExportInfoDialogComponent implements OnInit, OnDestroy {
    title: string;
    appKey: string;
    workbookIds: string[];
    workbookNames: { [workbookId: string]: string };
    versions: { [workbookId: string]: number };
    userKey: string;
    modalForm: FormGroup;
    isPendingStatus: boolean;
    @ViewChild('search', { static: true }) search: ElementRef;

    validationMessages = validationMessages;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ExportInfoDialogComponent>,
        private nameValidationService: NameValidationService
    ) {
        this.title = this.data.title;
        this.appKey = this.data.appKey;
        this.workbookIds = this.data.workbookIds;
        this.workbookNames = this.data.workbookNames;
        this.versions = this.data.versions;
        this.userKey = this.data.userKey;

        // close modal on outside click
        this.dialogRef.backdropClick()
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                this.cancel();
            });

        // close modal on esc button click
        this.dialogRef.keydownEvents()
            .pipe(untilDestroyed(this), filter(e => e && e.keyCode === 27))
            .subscribe(() => {
                this.cancel();
            });

        this.initForm();
    }

    get shouldWriteToRepositoryControl(): AbstractControl {
        return this.modalForm.get('shouldWriteToRepository').value;
    }

    export(): void {
        if (this.isPendingStatus) {
            return;
        }

        const {
            shouldWriteToRepository,
            shouldDownload,
            names
        } = this.modalForm.value;
        const versions = Object.keys(names).map(workbookId => ({
            workbookId,
            version: this.versions[workbookId],
            name: names[workbookId]
        }));
        this.data.handleConfirm({
            shouldWriteToRepository,
            shouldDownload,
            versions
        });
        this.dialogRef.close();
    }

    cancel(): void {
        this.data.handleCancel();
        this.dialogRef.close();
    }

    private initForm(): void {
        const namesFormGroup = (this.workbookIds || []).reduce((acc, workbookId) => {
            acc[workbookId] = new FormControl(this.workbookNames[workbookId], {
                validators: specialCharacterValidator,
                updateOn: 'blur'
            });
            return acc;
        }, {});
        this.modalForm = new FormGroup({
            shouldWriteToRepository: new FormControl(false),
            shouldDownload: new FormControl(false),
            names: new FormGroup(namesFormGroup)
        }, [this.exportToValidationFn()]);
    }


    ngOnInit() {
        this.modalForm.statusChanges.pipe(
            untilDestroyed(this),
            distinctUntilChanged()
        ).subscribe((status) => {
            if (status === 'PENDING') {
                this.isPendingStatus = true;
            }

            if (status === 'INVALID') {
                this.nameValidationService.isVersionNameInvalid = true;
                this.isPendingStatus = false;
            }

            if (status === 'VALID') {
                this.nameValidationService.isVersionNameInvalid = false;
                this.isPendingStatus = false;
            }
        });
    }

    ngOnDestroy() { }

    private exportToValidationFn(): ValidatorFn {
        return (modalFormControl: AbstractControl): { [key: string]: any } | null => {
            const toRepositoryControl = modalFormControl.get('shouldWriteToRepository');
            const shouldDownloadControl = modalFormControl.get('shouldDownload');
            const exportNotChosen = !toRepositoryControl.value && !shouldDownloadControl.value;

            const versionNames = <FormGroup>modalFormControl.get('names');
            const versionNamesControls = versionNames.controls || {};
            const workbookIds = Object.keys(versionNamesControls);

            workbookIds.forEach((workbookKey) => {
                const versionControl = versionNamesControls[workbookKey];
                this.nameValidationService.sameNameValidation(
                    versionControl,
                    toRepositoryControl.value,
                    workbookKey,
                    this.appKey,
                    this.userKey
                );
            });

            return exportNotChosen ? { 'notCheckedExportTo': true } : null;
        };
    }
}
