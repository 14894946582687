<div class="demographics">
  <p *ngIf="!regions.length" class="text-regular-small">
      No countries match your search criteria
  </p>
  <div
    *ngFor="let region of regions; trackBy: trackById;"
    class="demographics-region"
  >
    <p class="label">
      {{ region }}
      <button
        class="gs-button-flat gs-small"
        (click)="selectAllRegion(demographics[region], region)"
      >
        {{ selectAllChecked[region] ? 'Unselect All' : 'Select All' }}
      </button>
    </p>

    <div class="demographics-region-content">
      <div
        *ngFor="let demographic of demographics[region]; trackBy: trackById; let i = index"
        class="demographics-item"
      >
        <mat-checkbox
          color="primary"
          class="gs-demographic-checkbox"
          [value]="demographic.id"
          [checked]="isChecked(demographic.id)"
          (change)="selectDemographic($event, demographic)"
        >
          <ng-container>{{ demographic.name }}</ng-container>
        </mat-checkbox>
      </div>
    </div>
  </div>
</div>
