import { of, ReplaySubject, Observable, forkJoin, combineLatest, BehaviorSubject } from 'rxjs';
import { flatMap, map, take, mergeMap, filter } from 'rxjs/operators';
import { isArray } from 'lodash';

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';

import {
    Comment,
    Response,
    Members,
    User,
    Category,
    SSOTComment,
    Like,
    Question,
    Group,
    FormResponse,
} from '@app/core/models';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { FirebaseQuestionsService } from '@app/core/services/questions.service';
import { FirebaseActivityService } from '@app/core/services/activity.service';
import { FirebaseUsersService } from '@app/core/services/users.service';
import { removeEmptyKeys } from '../utils';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

@Injectable()
export class FirebaseResponsesService extends FirebaseUtilityService {
    // temporary solutions to notify users about the responses load delay
    responsesLoaded: { [questionId: string]: BehaviorSubject<number> } = {};

    constructor(
        private db: AngularFireDatabase,
        private authenticatedUserService: AuthenticatedUserService,
        private fbQuestionService: FirebaseQuestionsService,
        private fbActivityService: FirebaseActivityService,
        private fbUserService: FirebaseUsersService,
        private functions: AngularFireFunctions,
    ) {
        super();
    }

    clearResponsesLoaded(questionId: string): void {
        this.responsesLoaded[questionId] = null;
    }

    setResponseLoaded(questionId: string): void {
        if (!this.responsesLoaded[questionId]) {
            this.responsesLoaded[questionId] = new BehaviorSubject(0);
        }
        this.responsesLoaded[questionId].next(this.responsesLoaded[questionId].getValue() + 1);
    }

    getResponsesLoaded(questionId: string): Observable<number> {
        if (!this.responsesLoaded[questionId]) {
            this.responsesLoaded[questionId] = new BehaviorSubject(0);
        }

        return this.responsesLoaded[questionId].asObservable();
    }

    // uses to remove responses in crowdsource && present
    // all related data removed in cloud functions
    async deleteResponse(appKey: string, activityKey: string, questionKey: string, responseKey: string): Promise<void> {
        try {
            await this.functions
                .httpsCallable('updatePropertiesRelatedToResponse')({
                    appKey,
                    activityKey,
                    questionKey,
                    responseKey,
                })
                .toPromise();
        } catch (err) {
            console.log('Error while deleting response in source or present!');
            console.error({ err });
        }
    }

    // TODO: move this to cloud functions for updating ssot use_demographics and ssot vote_on_groups field
    removeActivityResponses(activityKey: string): Promise<void> {
        const responseQuestions = this.fbQuestionService.getQuestionsByActivityKey(activityKey).pipe(
            take(1),
            map(questions => {
                const questionUpdates = {};

                questions.forEach((question: Question) => {
                    questionUpdates[`responses/${question.key}`] = null;
                });

                return questionUpdates;
            }),
        );

        const responseGroups = this.fbActivityService.getActivityGroupsByKey(activityKey).pipe(
            take(1),
            map(groups => {
                const groupUpdates = {};

                groups.forEach((group: Group) => {
                    groupUpdates[`responses/${group.key}`] = null;
                });

                return groupUpdates;
            }),
        );

        const ssotResponse = this.getSsotResponsesByActivityKey(activityKey).pipe(
            take(1),
            map((responses: Response[]) => {
                const responseUpdates = {};

                responses.forEach((resp: Response) => {
                    responseUpdates[`ssot/_responses/${resp.key}`] = null;
                });

                return responseUpdates;
            }),
        );

        const mergeUpdates = combineLatest(responseQuestions, ssotResponse, responseGroups);

        mergeUpdates.subscribe(combinedUpdates => {
            const updates = {};

            combinedUpdates.forEach(combinedUpdate => {
                Object.assign(updates, combinedUpdate);
            });
            updates[`reportResponsesByDemographic/${activityKey}`] = null;
            updates[`reportResponsesByUser/${activityKey}`] = null;
            updates[`reportResponsesByQuestion/${activityKey}`] = null;
            updates[`reportCompletedQuestions/${activityKey}`] = null;
            this.db.object('/').update(updates);
        });

        return Promise.resolve();
    }

    getSsotResponsesByActivityKey(activityKey: string): Observable<Response[]> {
        return this.listWithKeys(
            this.db.list<Response>('/ssot/_responses', ref => ref.orderByChild('activity_id').equalTo(activityKey)),
        );
    }

    getSsotResponsesByActivityKeyMap(activityKey: string): Observable<{ [path: string]: Response }> {
        const userKey = this.authenticatedUserService.getCurrentUserId();

        return this.listWithKeys(
            this.db.list<Response>('/ssot/_responses', ref => ref.orderByChild('activity_id').equalTo(activityKey)),
        ).pipe(
            map(responses =>
                responses
                    .filter(response => response.owner_id === userKey)
                    .reduce(
                        (accum, response) => ({
                            ...accum,
                            [`${response.question_id}${response.sub_question_id}`]: response,
                        }),
                        {},
                    ),
            ),
        );
    }

    getFormResponseByQuestionId(questionKey: string): Observable<FormResponse[]> {
        return this.listWithKeys(
            this.db.list<FormResponse>('/ssot/_responses', ref => ref.orderByChild('question_id').equalTo(questionKey)),
        );
    }

    // TODO: rewrite getVoteResponsesAndFlatten to ssot version
    getVoteResponsesAndFlatten(activityKey: string, hasDemographics?: boolean): Observable<any[]> {
        return this.db
            .list(`/reportResponsesByQuestion/${activityKey}`)
            .snapshotChanges()
            .pipe(
                map(wrappedQuestionList => {
                    const flattenedResponses = [];

                    wrappedQuestionList.forEach(wrappedQuestion => {
                        const questionKey = wrappedQuestion.key;
                        const question = wrappedQuestion.payload.val();

                        Object.keys(question).forEach(subQuestionKey => {
                            const subQuestion = question[subQuestionKey];

                            Object.keys(subQuestion).forEach(userKey => {
                                const user = subQuestion[userKey];
                                const flattenedData = {
                                    question: questionKey,
                                    subQuestion: subQuestionKey,
                                    user: userKey,
                                };

                                if (hasDemographics) {
                                    flattenedData['demographics'] = user;
                                } else {
                                    flattenedData['value'] = user;
                                }
                                flattenedResponses.push(flattenedData);
                            });
                        });
                    });

                    return flattenedResponses;
                }),
            );
    }

    addResponseByKey(questionKey: string, response: Response, activityKey: string, owner?: string): Promise<string> {
        let newResponseKey: string;

        return this.calculateNumberOfResponsesForQuestion(questionKey).then(numberOfResponses => {
            const updates = {};

            newResponseKey = this.db.createPushId();
            updates[`activities/${activityKey}/questions/${questionKey}/number_responses`] = numberOfResponses;
            updates[`responses/${questionKey}/${newResponseKey}`] = this.buildResponse(response, owner);
            updates[`ssot/_responses/${newResponseKey}`] = this.buildSsotResponse(
                response,
                activityKey,
                questionKey,
                owner,
            );

            this.db.object('/').update(updates);

            return Promise.resolve(newResponseKey);
        });
    }

    addResponseByPredefinedKey(
        responseKey: string,
        questionKey: string,
        response: Response,
        activityKey: string,
        anonymous?: boolean,
    ): Promise<string> {
        return this.calculateNumberOfResponsesForQuestion(questionKey)
            .then(numberOfResponses => {
                const updates = {};

                updates[`activities/${activityKey}/questions/${questionKey}/number_responses`] = numberOfResponses;
                updates[`responses/${questionKey}/${responseKey}`] = this.buildResponse(
                    response,
                    anonymous ? '' : this.authenticatedUserService.getCurrentUserId(),
                );
                updates[`ssot/_responses/${responseKey}`] = this.buildSsotResponse(response, activityKey, questionKey);

                return this.db.object('/').update(updates);
            })
            .then(() => {
                return Promise.resolve(responseKey);
            });
    }

    addResponseWithoutOwner(
        questionKey: string,
        response: Response,
        activityKey: string,
        number_responses?: number,
    ): Promise<string> {
        return this.calculateNumberOfResponsesForQuestion(questionKey).then(numberOfResponses => {
            const responseKey = this.db.createPushId();
            const updates = {};

            updates[`activities/${activityKey}/questions/${questionKey}/number_responses`] = numberOfResponses;
            const responseWithoutOwner = this.buildResponse(response);

            delete responseWithoutOwner.owner;
            updates[`responses/${questionKey}/${responseKey}`] = responseWithoutOwner;
            const responseWithoutOwnerId = this.buildSsotResponse(response, activityKey, questionKey);

            delete responseWithoutOwnerId.owner_id;
            updates[`ssot/_responses/${responseKey}`] = responseWithoutOwnerId;

            this.db.object('/').update(updates);

            return Promise.resolve(responseKey);
        });
    }

    addResponseByKeys(questionKey: string, subQuestionKey: string, response: any, activityKey: string): void {
        const userKey = this.authenticatedUserService.getCurrentUserId();
        const responseData = {};

        responseData[`responses/${questionKey}/${userKey}/${subQuestionKey}`] = {
            date_created: +new Date(),
            owner: userKey,
            value: response,
        };
        responseData[`reportResponsesByUser/${activityKey}/${userKey}/${questionKey}/${subQuestionKey}`] = response;
        responseData[`reportResponsesByQuestion/${activityKey}/${questionKey}/${subQuestionKey}/${userKey}`] = response;

        // Add SSOT data
        const newResponseKey = this.db.createPushId();
        const newResponse = this.buildSsotResponse(<Response>{ value: response }, activityKey, questionKey, userKey);

        newResponse.sub_question_id = subQuestionKey;
        responseData[`ssot/_responses/${newResponseKey}`] = newResponse;
        this.db.object('/').update(responseData);
    }

    addVoteResponseByKeys(
        responseKey: string,
        questionKey: string,
        subQuestionKey: string,
        response: any,
        activityKey: string,
        anonymous?: boolean,
        abstain?: boolean,
    ): Promise<any> {
        const userKey = this.authenticatedUserService.getCurrentUserId();
        const responseData = {};

        responseData[`responses/${questionKey}/${userKey}/${subQuestionKey}`] = removeEmptyKeys({
            date_created: +new Date(),
            owner: userKey,
            value: response,
            anonymous: !!anonymous,
            abstain: !!abstain,
        });

        const shouldBeCountedOnSummary = this.shouldBeCountedOnVoteSummary(response, abstain);

        responseData[`reportResponsesByUser/${activityKey}/${userKey}/${questionKey}/${subQuestionKey}`] =
            shouldBeCountedOnSummary ? response : null;
        responseData[`reportResponsesByQuestion/${activityKey}/${questionKey}/${subQuestionKey}/${userKey}`] =
            shouldBeCountedOnSummary ? response : null;

        // Add SSOT data
        // eslint-disable-next-line max-len
        const ssotResponse = this.buildVoteSsotResponse(
            <Response>{ value: response, abstain: !!abstain },
            activityKey,
            questionKey,
            subQuestionKey,
            userKey,
            anonymous,
        );
        const ssotResponseKey = responseKey || this.db.createPushId();

        responseData[`ssot/_responses/${ssotResponseKey}`] = ssotResponse;

        return this.db.object('/').update(responseData);
    }

    // TODO: replace this with updateSsotResponseByKey when demographics are figured out
    updateResponseByKey(
        questionKey: string,
        response: any,
        activityKey: string,
        useDemographics?: boolean,
        userDemographics?: { [demographicKey: number]: string },
    ): void {
        const userKey = this.authenticatedUserService.getCurrentUserId();
        const responseData = {};

        responseData[`responses/${questionKey}/${userKey}`] = response;
        responseData[`reportResponsesByUser/${activityKey}/${userKey}/${questionKey}`] = response;
        responseData[`reportResponsesByQuestion/${activityKey}/${questionKey}/${userKey}`] = response;

        if (useDemographics) {
            // TODO: refactor after demographics activity refactoring
            // and save response by demographicKey, not demographic name
            Object.keys(response).forEach(demographicKey => {
                responseData[
                    `reportResponsesByDemographic/${activityKey}/${userDemographics[demographicKey]}/${questionKey}/${userKey}`
                ] = response[demographicKey];
            });
        }
        this.db.object('/').update(responseData);
    }

    updateSsotResponseByKey(responseKey: string, response: Response): Promise<void> {
        return this.db.object(`/ssot/_responses/${responseKey}`).update(response);
    }

    // TODO: Replace this with a new method that requires an actual responseKey and we know what data transforms we need
    removeBranchingResponseByKey(
        questionKeys: string[],
        activityKey: string,
        useDemographics?: boolean,
        userDemographics?: { [demographicKey: number]: string },
    ): void {
        const userKey = this.authenticatedUserService.getCurrentUserId();
        const responseData = {};

        questionKeys.forEach(questionKey => {
            responseData[`responses/${questionKey}/${userKey}`] = null;
            responseData[`reportResponsesByUser/${activityKey}/${userKey}/${questionKey}`] = null;
            responseData[`reportResponsesByQuestion/${activityKey}/${questionKey}/${userKey}`] = null;
            if (useDemographics) {
                // TODO: refactor after demographics activity refactoring
                // and remove response by demographicKey, not demographic name
                Object.keys(userDemographics).forEach(demographicKey => {
                    responseData[
                        `reportResponsesByDemographic/${activityKey}/${userDemographics[demographicKey]}/${questionKey}/${userKey}`
                    ] = null;
                });
            }
        });
        this.db.object('/').update(responseData);
    }

    // TODO: Replace this with a new method that requires an actual responseKey and we know what data transforms we need
    updateResponseWithOptionsByKey(
        questionKey: string,
        activityKey: string,
        optionKey: string,
        fieldId: number,
        response: string,
    ): void {
        const userKey = this.authenticatedUserService.getCurrentUserId();
        const responseData = {};

        responseData[`responses/${questionKey}/${userKey}/value/${optionKey}/${fieldId}`] = response;
        responseData[`responses/${questionKey}/${userKey}/date_created`] = +new Date();
        responseData[`responses/${questionKey}/${userKey}/owner`] = userKey;
        // report responses
        responseData[`reportResponsesByUser/${activityKey}/${userKey}/${questionKey}/value/${optionKey}/${fieldId}`] =
            response;
        responseData[
            `reportResponsesByQuestion/${activityKey}/${questionKey}/${userKey}/value/${optionKey}/${fieldId}`
        ] = response;

        this.db.object('/').update(responseData);
    }

    getActivityResponsesByUserKey(activityKey: string): Observable<any> {
        const userKey = this.authenticatedUserService.getCurrentUserId();

        return this.db.object(`reportResponsesByUser/${activityKey}/${userKey}`).valueChanges();
    }

    getResponseWithReaction(responseKey: string): Observable<Response> {
        return this.db
            .object<Response>(`ssot/_responses/${responseKey}`)
            .valueChanges()
            .pipe(
                map(
                    response =>
                        <Response>{
                            ...response,
                            comments: this.db.object<Comment>(`/reactions/${responseKey}/comments`).valueChanges(),
                            likes: this.db.object<Members>(`/reactions/${responseKey}/likes`).valueChanges(),
                            $key: responseKey,
                        },
                ),
            );
    }

    getResponsesRef(questionKey: string): AngularFireList<any> {
        return this.db.list(`/responses/${questionKey}`);
    }

    getResponsesList(questionKey: string): Observable<Response[]> {
        return this.listWithKeys(this.db.list<Response>(`/responses/${questionKey}`));
    }

    getResponseCommentsAsObject(responseKey: string): Observable<any> {
        return this.db.object(`/reactions/${responseKey}/comments`).valueChanges();
    }

    getResponseLikes(responseKey: string): Observable<any> {
        return this.db.object(`/reactions/${responseKey}/likes`).valueChanges();
    }

    getResponseOwner(userKey: string): Observable<User> {
        return this.db.object<User>(`/users/${userKey}`).valueChanges();
    }

    getResponsesByUserId(questionKey: string): Observable<Response> {
        const userKey = this.authenticatedUserService.getCurrentUserId();

        return this.db.object<Response>(`/responses/${questionKey}/${userKey}`).valueChanges();
    }

    // TODO: Replace this with a new method that requires an actual responseKey and we know what data transforms we need
    updateDemographicsSubResponse(
        questionKey: string,
        subQuestionKey: string,
        demographicKey: string,
        value: any,
        activityKey: string,
        anonymous?: boolean,
        abstain?: boolean,
    ): void {
        const userKey: string = this.authenticatedUserService.getCurrentUserId();
        const responseData = {};

        responseData[`responses/${questionKey}/${userKey}/${subQuestionKey}/${demographicKey}`] = removeEmptyKeys({
            value: value,
            date_created: +new Date(),
            owner: userKey,
            anonymous: !!anonymous,
            abstain: !!abstain,
        });

        const shouldBeCountedOnSummary = this.shouldBeCountedOnVoteSummary(value, abstain);

        responseData[
            `reportResponsesByQuestion/${activityKey}/${questionKey}/${subQuestionKey}/${userKey}/${demographicKey}`
        ] = shouldBeCountedOnSummary ? value : null;
        responseData[
            `reportResponsesByUser/${activityKey}/${userKey}/${questionKey}/${subQuestionKey}/${demographicKey}`
        ] = shouldBeCountedOnSummary ? value : null;
        responseData[
            `reportResponsesByDemographic/${activityKey}/${demographicKey}/${questionKey}/${subQuestionKey}/${userKey}`
        ] = shouldBeCountedOnSummary ? value : null;

        this.db.object('/').update(responseData);
    }

    // This is used by crowdsource and is actually using a response key so we can update it for SSOT
    updateResponse(questionKey: string, responseKey: string, response: Response): Promise<void> {
        return this.db
            .object(`/responses/${questionKey}/${responseKey}`)
            .update({
                ...response,
            })
            .then(() => {
                return this.db.object(`/ssot/_responses/${responseKey}`).update({
                    ...response,
                });
            });
    }

    // TODO: need to remove this with brainstorm and categorize activities
    // TODO: need to update params: activityKey: string, questionKey: string, responseKey: string, value: boolean, likeKey: string
    updateResponseReactions(
        questionKey: string,
        responseKey: string,
        value: { [user: string]: boolean },
        likes?: number,
    ): Promise<void> {
        const userKey = Object.keys(value)[0];
        const updates = {};

        if (value[userKey]) {
            updates[`reactions/${responseKey}/likes/${userKey}`] = true;
        } else {
            updates[`reactions/${responseKey}/likes/${userKey}`] = null;
        }

        // TODO: will need to remove the rest
        // updates for SSOT _likes
        // const userKey = this.authenticatedUserService.getCurrentUserId();
        const likeKey = `${userKey}_${responseKey}`;

        if (value[userKey]) {
            updates[`ssot/_likes/${likeKey}`] = <Like>{
                response_id: responseKey,
                user_id: userKey,
            };
        } else {
            updates[`ssot/_likes/${likeKey}`] = null;
        }
        // TODO: recalculate total_likes for response in cloud functions
        // TODO: recalculate activity_votes for activity for current user in cloud functions

        return this.db
            .object('/')
            .update(updates)
            .then(() => {
                const countUpdates = {};

                forkJoin(this.db.object(`reactions/${responseKey}/likes`).valueChanges().pipe(take(1)))
                    .toPromise()
                    .then(currentLikes => {
                        countUpdates[`responses/${questionKey}/${responseKey}/total_likes`] = Object.keys(
                            currentLikes[0] || {},
                        ).length;
                        countUpdates[`ssot/_responses/${responseKey}/total_likes`] = Object.keys(
                            currentLikes[0] || {},
                        ).length;

                        return this.db.object('/').update(countUpdates);
                    });
            });
    }

    // TODO: need to update params: activityKey: string, questionKey: string, responseKey: string, value: boolean, likeKey: string
    updateResponseVotes(
        questionKey: string,
        responseKey: string,
        value: { [user: string]: boolean },
        activityKey: string,
        userVotes?: number,
    ): Promise<void> {
        const userValueKey = Object.keys(value)[0];
        const updates = {};

        if (value[userValueKey]) {
            updates[`reactions/${responseKey}/likes/${userValueKey}`] = true;
        } else {
            updates[`reactions/${responseKey}/likes/${userValueKey}`] = null;
        }

        const userKey = this.authenticatedUserService.getCurrentUserId();

        // TODO: will need to remove the rest
        // const userKey = this.authenticatedUserService.getCurrentUserId();
        const likeKey = `${userKey}_${responseKey}`;

        if (value[userKey]) {
            updates[`ssot/_likes/${likeKey}`] = <Like>{
                response_id: responseKey,
                user_id: userKey,
            };
        } else {
            updates[`ssot/_likes/${likeKey}`] = null;
        }
        // TODO: recalculate total_likes for response in cloud functions
        // TODO: recalculate activity_votes for activity for current user in cloud functions

        return this.db
            .object('/')
            .update(updates)
            .then(() => {
                const activityVotesUpdates = {};

                if (userVotes) {
                    activityVotesUpdates[`activities/${activityKey}/activity_votes/${questionKey}/${userKey}`] =
                        userVotes;
                    activityVotesUpdates[`ssot/_activities/${activityKey}/activity_votes/${questionKey}/${userKey}`] =
                        userVotes;
                }

                forkJoin(this.db.object(`/reactions/${responseKey}/likes`).valueChanges().pipe(take(1)))
                    .toPromise()
                    .then(currentLikes => {
                        activityVotesUpdates[`responses/${questionKey}/${responseKey}/total_likes`] = Object.keys(
                            currentLikes[0] || {},
                        ).length;
                        activityVotesUpdates[`ssot/_responses/${responseKey}/total_likes`] = Object.keys(
                            currentLikes[0] || {},
                        ).length;

                        return this.db.object('/').update(activityVotesUpdates);
                    });
            });
    }

    // TODO: need to remove this with brainstorm and categorize activities (we do not have any reactions for the comments)
    updateCommentsReactions(
        responseKey: string,
        commentKey: string,
        value: { [user: string]: boolean },
    ): Promise<void> {
        const userKey = Object.keys(value)[0];
        const reactionLikesUpdates = {};

        if (value[userKey]) {
            reactionLikesUpdates[`reactions/${responseKey}/comments/${commentKey}/likes/${userKey}`] = true;
            reactionLikesUpdates[`ssot/_reactions/${responseKey}/comments/${commentKey}/likes/${userKey}`] = true;
        } else {
            reactionLikesUpdates[`reactions/${responseKey}/comments/${commentKey}/likes/${userKey}`] = null;
            reactionLikesUpdates[`ssot/_reactions/${responseKey}/comments/${commentKey}/likes/${userKey}`] = null;
        }

        return this.db
            .object('/')
            .update(reactionLikesUpdates)
            .then(() => {
                forkJoin(
                    this.db
                        .object(`/reactions/${responseKey}/comments/${commentKey}/likes`)
                        .valueChanges()
                        .pipe(take(1)),
                    this.db
                        .object(`/ssot/_reactions/${responseKey}/comments/${commentKey}/likes`)
                        .valueChanges()
                        .pipe(take(1)),
                )
                    .toPromise()
                    .then(currentLikes => {
                        const totalLikesUpdates = {};

                        totalLikesUpdates[`reactions/${responseKey}/comments/${commentKey}/total_likes`] = Object.keys(
                            currentLikes[0] || {},
                        ).length;
                        totalLikesUpdates[`ssot/_reactions/${responseKey}/comments/${commentKey}/total_likes`] =
                            Object.keys(currentLikes[0] || {}).length;

                        return this.db.object('/').update(totalLikesUpdates);
                    });
            });
    }

    // TODO: need to update params: questionKey: string, responseKey: string, value: string, anonymous?: boolean
    async addCommentByResponseKey(
        responseKey: string,
        value: string,
        questionKey: string,
        activityKey: string,
        anonymous?: boolean,
    ): Promise<void> {
        const commentKey = this.db.createPushId();
        const userKey = this.authenticatedUserService.getCurrentUserId();
        const updates = {};

        updates[`reactions/${responseKey}/comments/${commentKey}`] = this.buildComment(value, userKey);

        // TODO: for ssot - will need to remove the rest
        const userId = anonymous ? '' : this.authenticatedUserService.getCurrentUserId();
        const commentObject = this.buildSsotComment(value, userId);

        updates[`ssot/_responses/${commentKey}`] = {
            ...commentObject,
            response_id: responseKey,
            question_id: questionKey,
            activity_id: activityKey,
        };

        await this.db.object('/').update(updates);
    }

    // TODO: need to remove this for ssot and use previous method
    async addCommentWithoutOwner(
        responseKey: string,
        value: string,
        questionKey: string,
        activityKey: string,
    ): Promise<void> {
        const commentKey = this.db.createPushId();
        const updates = {};

        updates[`reactions/${responseKey}/comments/${commentKey}`] = this.buildComment(value);

        // TODO: for ssot - will need to remove the rest
        const commentObject = this.buildSsotComment(value, '');

        updates[`ssot/_responses/${commentKey}`] = {
            ...commentObject,
            response_id: responseKey,
            question_id: questionKey,
            activity_id: activityKey,
        };

        await this.db.object('/').update(updates);
    }

    getResponseComments(responseKey: string): AngularFireList<Comment> {
        return this.db.list<Comment>(`/reactions/${responseKey}/comments`);
    }

    getResponseCommentsWithOwners(appKey: string, responseKey: string): Observable<Comment[]> {
        const users$ = this.fbUserService.getInstanceUsersMap(appKey).pipe(filter(data => !!data));
        const comments$ = this.listWithKeys(this.db.list<Comment>(`/reactions/${responseKey}/comments`));

        return combineLatest(users$, comments$).pipe(
            map(([users, comments]) => {
                return comments.map(comment => {
                    const user = comment.owner ? users[comment.owner] : null;

                    return <Comment>{ ...comment, user };
                });
            }),
        );
    }

    getResponseComment(responseKey: string, commentKey: string): Observable<Comment> {
        return this.db
            .object<Comment>(`/reactions/${responseKey}/comments/${commentKey}`)
            .valueChanges()
            .pipe(
                mergeMap((comment: Comment) => {
                    if (comment.owner) {
                        return this.fbUserService.getUserInfo(comment.owner).pipe(map(user => ({ ...comment, user })));
                    }

                    return of(comment);
                }),
            );
    }

    updateComment(responseKey: string, commentKey: string, comment: Comment): Promise<void> {
        const updatedComment = {
            ...comment,
        };

        return this.db
            .object<Comment>(`/reactions/${responseKey}/comments/${commentKey}`)
            .update(updatedComment)
            .then(() => {
                return this.db.object(`/ssot/_reactions/${responseKey}/comments/${commentKey}`).update(updatedComment);
            });
    }

    // TODO: Update this method after SSOT reads are implemented due to the differences in the current response and SSOT response
    mergeResponse(
        questionKey: string,
        responseToMergeKey: string,
        responseKey: string,
        activityKey?: string,
    ): Observable<string> {
        const promise = new ReplaySubject<string>();

        this.db
            .object<Response>(`/responses/${questionKey}/${responseKey}`)
            .valueChanges()
            .pipe(
                flatMap(response => {
                    const responseToMerge = this.db
                        .object<Response>(`/responses/${questionKey}/${responseToMergeKey}`)
                        .valueChanges();
                    const responseToMergeLikes = this.db
                        .object<Response>(`/reactions/${responseToMergeKey}/likes`)
                        .snapshotChanges();

                    // TODO: get from ssot

                    return combineLatest([of(response), responseToMerge, responseToMergeLikes]).pipe(
                        map(([responseValue, responseToMergeValue, responseToMergeLikesValue]) => ({
                            response: responseValue,
                            responseToMerge: {
                                ...responseToMergeValue,
                                likes: responseToMergeLikesValue,
                            },
                        })),
                    );
                }),
                flatMap(responses => {
                    const commentsToMerge = this.listWithKeys(
                        this.db.list<Comment>(`/reactions/${responseToMergeKey}/comments`),
                    );
                    const likesToMerge = this.db.list(`/reactions/${responseToMergeKey}/likes`).snapshotChanges();

                    return combineLatest([of(responses), commentsToMerge, likesToMerge]).pipe(
                        map(([responsesValue, commentsToMergeValue, likesToMergeValue]) => ({
                            responses: responsesValue,
                            commentsToMerge: commentsToMergeValue,
                            likesToMerge: likesToMergeValue,
                        })),
                    );
                }),
                take(1),
            )
            .subscribe((result: { responses: any; commentsToMerge: any; likesToMerge: any }) => {
                let { total_comments: totalTargetResponseCounts } = result.responses.response;

                totalTargetResponseCounts = (totalTargetResponseCounts || 0) + (result.commentsToMerge.length || 0) + 1;

                let { total_likes: totalTargetResponseLikesCounts } = result.responses.response;

                totalTargetResponseLikesCounts =
                    (totalTargetResponseLikesCounts || 0) + (result.likesToMerge.length || 0);

                const { total_comments, ...responseToMerge } = result.responses.responseToMerge;
                const responseToMergeObject = <Comment>{
                    date_created: responseToMerge.date_created,
                    value: responseToMerge.value,
                };

                if (responseToMerge.owner) {
                    responseToMergeObject.owner = responseToMerge.owner;
                }

                // updates for ssot
                const responseMergeComment = this.buildSsotComment(responseToMerge.value, responseToMerge.owner || '');

                Promise.all([
                    // TODO: changes for ssot - need to remove the previous response update
                    this.db.object<Response>(`ssot/_responses/${responseKey}`).update({
                        total_comments: totalTargetResponseCounts,
                        total_likes: totalTargetResponseLikesCounts,
                    }),

                    // add 'response to merge' into target response comments list
                    this.db.object(`/reactions/${responseKey}/comments/${responseToMergeKey}`).update(<Comment>{
                        ...responseToMergeObject,
                    }),

                    // TODO: changes for ssot - need to remove the previous comment 'push'
                    // responseToMergeKey -> new response with type: comment for responseKey
                    this.db.object(`ssot/_responses/${responseToMergeKey}`).update({
                        ...responseMergeComment,
                        response_id: responseKey,
                        question_id: questionKey,
                    }),

                    // add 'response to merge' comments into target response comments list
                    result.commentsToMerge.forEach(commentToMerge => {
                        const { key, ...comment } = commentToMerge;

                        this.db.object(`/reactions/${responseKey}/comments/${key}`).update(comment);
                    }),

                    // push 'response to merge' comments into target response comments list
                    // TODO: change response_id in each comment, will need to know comment key and remove the previous method
                    // result.commentsToMerge.forEach(comment => {
                    //     this.db.object(`ssot/_responses/${comment.$key}`).update({
                    //         response_id: responseKey
                    //     });
                    // }),

                    // TODO: ssot - no need to remove just update type
                    // remove response to merge
                    this.db.object(`/responses/${questionKey}/${responseToMergeKey}`).remove(),

                    // TODO: ssot - review categories
                    // remove response from category
                    this.deleteResponseFromCategory(responseToMerge.category, responseToMergeKey, activityKey),

                    // remove the now comment's reaction
                    this.db.object(`/reactions/${responseToMergeKey}`).remove(),
                    // TODO: ssot - remove reactions for comment - with using cloud functions

                    // TODO: recalculate with using cloud functions
                    // push 'response to merge' likes into target response likes list
                    result.likesToMerge.forEach(like => {
                        this.db
                            .object(`/reactions/${responseKey}/likes`)
                            .valueChanges()
                            .pipe(take(1))
                            .subscribe(likesObject => {
                                if (likesObject) {
                                    const foundLike = Object.keys(likesObject).find(matchingLike => {
                                        return matchingLike === like.key;
                                    });

                                    // if the user key already exists under the response being merged into...
                                    // we have to give a vote back
                                    if (foundLike) {
                                        this.db
                                            .object<any>(
                                                `/activities/${activityKey}/activity_votes/${questionKey}/${foundLike}`,
                                            )
                                            .valueChanges()
                                            .pipe(take(1))
                                            .subscribe(votes => {
                                                const updatedValue = votes - 1 <= 0 ? 0 : votes - 1;

                                                this.db
                                                    .object(`/activities/${activityKey}/activity_votes/${questionKey}/`)
                                                    .update({
                                                        [foundLike]: updatedValue,
                                                    });
                                            });
                                    }
                                }
                            });

                        this.db.object(`reactions/${responseKey}/likes/${like.key}`).set(true);
                    }),
                ]).then(() => {
                    if (activityKey) {
                        // TODO: recalculate with using cloud functions
                        this.db
                            .list(`/responses/${questionKey}`)
                            .valueChanges()
                            .pipe(take(1))
                            .subscribe(responses => {
                                this.db.object(`/activities/${activityKey}/questions/${questionKey}`).update({
                                    number_responses: responses.length,
                                });
                                // TODO: remove this when we do the SSOT move for responses
                                this.db.object(`/ssot/_questions/${questionKey}`).update({
                                    number_responses: responses.length,
                                });
                            });
                    }
                    promise.next(responseKey);
                });
            });

        return promise.asObservable();
    }

    private deleteResponseFromCategory(
        responseCategoryKey: string,
        responseToMergeKey: string,
        activityKey: string,
    ): Promise<void> {
        if (!responseCategoryKey) {
            return Promise.resolve();
        }

        return this.db
            .object(`/categories/${activityKey}/${responseCategoryKey}/responses/${responseToMergeKey}`)
            .remove()
            .then(() => {
                return new Promise<void>((resolve, reject) => {
                    const category = this.db.object<Category>(`/categories/${activityKey}/${responseCategoryKey}/`);

                    category
                        .valueChanges()
                        .pipe(take(1))
                        .subscribe(categoryValue => {
                            category
                                .update({
                                    number_responses: Object.keys(categoryValue.responses || {}).length,
                                })
                                .then(() => {
                                    resolve();
                                })
                                .catch(reason => {
                                    reject(reason);
                                });
                        });
                });
            });
    }

    async deleteReaction(responseKey: string, reactionKey: string): Promise<void> {
        const updates = {
            [`reactions/${responseKey}/comments/${reactionKey}`]: null,
            [`ssot/_responses/${reactionKey}`]: null,
        };

        await this.db.object('/').update(updates);
    }

    private calculateNumberOfResponsesForQuestion(questionKey: string): Promise<number> {
        return this.db
            .list<Response>(`/responses/${questionKey}`)
            .valueChanges()
            .pipe(take(1))
            .toPromise()
            .then(responses => {
                const numberOfResponses = responses ? responses.length + 1 : 1;

                return Promise.resolve(numberOfResponses);
            });
    }

    private buildResponse(response: Response, ownerKey?: string): Response {
        return {
            ...response,
            date_created: +new Date(),
            flagged: false,
            owner: ownerKey || this.authenticatedUserService.getCurrentUserId(),
            total_comments: 0,
            total_likes: 0,
        };
    }

    private buildSsotResponse(
        response: Response,
        activityKey: string,
        questionKey: string,
        ownerKey?: string,
    ): Response {
        return removeEmptyKeys(<Response>{
            ...response,
            type: 'response',
            activity_id: activityKey,
            question_id: questionKey,
            owner_id: ownerKey || this.authenticatedUserService.getCurrentUserId(),
            date_created: +new Date(),
            flagged: false,
            total_comments: 0,
            total_likes: 0,
        });
    }

    private buildVoteSsotResponse(
        response: Response,
        activityKey: string,
        questionKey: string,
        subQuestionKey: string,
        ownerKey?: string,
        anonymous?: boolean,
    ): Response {
        return removeEmptyKeys(<Response>{
            ...response,
            type: 'response',
            activity_id: activityKey,
            question_id: questionKey,
            sub_question_id: subQuestionKey,
            owner_id: ownerKey || this.authenticatedUserService.getCurrentUserId(),
            date_created: +new Date(),
            anonymous: !!anonymous,
        });
    }

    private buildSsotComment(value: any, userKey?: string): any {
        const comment = <SSOTComment>{
            value,
            type: 'comment',
            date_created: +new Date(),
            total_likes: 0,
            flagged: false,
        };

        if (userKey) {
            comment['owner_id'] = userKey;
        }

        return comment;
    }

    private buildComment(value: any, userKey?: string): any {
        const comment = {
            value,
            date_created: +new Date(),
            total_likes: 0,
            flagged: false,
        };

        if (userKey) {
            comment['owner'] = userKey;
        }

        return comment;
    }

    private shouldBeCountedOnVoteSummary(responseValue: any, abstain: boolean): boolean {
        if (abstain) {
            return false;
        }

        const isResponseEmpty = isArray(responseValue) ? !responseValue.length : !responseValue && responseValue !== 0;

        return !isResponseEmpty;
    }
}
