import { createAction, props } from '@ngrx/store';
import { FormBranchingVisibility } from '@app/core/models/forms.model';

const getBranchingVisibilityType = '[Forms] get branching visibility';
const getBranchingVisibilitySuccessType = '[Forms] get branching visibility success';
const getBranchingVisibilityFailType = '[Forms] get branching visibility fail';


export const getBranchingVisibility = createAction(
    getBranchingVisibilityType,
    props<{ activityKey: string }>()
);

export const getBranchingVisibilitySuccess = createAction(
    getBranchingVisibilitySuccessType,
    props<{ activityKey: string, formBranchingVisibility: { [questionKey: string]: FormBranchingVisibility } }>()
);

export const getBranchingVisibilityFail = createAction(
    getBranchingVisibilityFailType,
    props<{ errorMessage: string }>()
);
