import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { Demographic, FormQuestion } from '@app/core/models';
import { FormComponentsService } from '@app/core/services/form-components.service';
import { AnsweredQuestionModel, FormBranchingVisibility, RepeatableQuestionsIds } from '../models/forms.model';

@Injectable()
export class FormService {
    public removedQuestion$ = new Subject<FormQuestion>();

    private selectedQuestionKey: string;
    private selectedQuestionSequence: number;
    private selectedQuestion = new Subject<FormQuestion>();
    private toggleFormOptions = new Subject<boolean>();

    constructor(
        private formComponentsService: FormComponentsService
    ) { }

    getSelectedQuestion(): Observable<FormQuestion> {
        return this.selectedQuestion.asObservable();
    }

    getToggleFormOptions(): Observable<boolean> {
        return this.toggleFormOptions.asObservable();
    }

    setToggleFormOptions(isOpened: boolean): void {
        this.toggleFormOptions.next(isOpened);
    }

    setSelectedQuestion(question: FormQuestion): void {
        if (!question) {
            this.selectedQuestionKey = '';
            this.selectedQuestionSequence = null;
            this.selectedQuestion.next(question);
            return;
        }

        const isQuestionChanged = this.selectedQuestionKey !== question.key;
        const isSequenceChanged = !isQuestionChanged && this.selectedQuestionSequence !== question.sequence;

        if (isQuestionChanged || isSequenceChanged) {
            return this.updateSelectedQuestion(question);
        }
    }

    getQuestionsByFormId(formKey: string): Observable<FormQuestion[]> {
        return this.formComponentsService.getQuestionsByField('form_id', formKey);
    }

    async addFormQuestion(
        appKey: string,
        activityKey: string,
        formKey: string,
        questionInfo: { question: FormQuestion, sequence: number }
    ): Promise<string> {
        const { question, sequence } = questionInfo;
        const newQuestion = await this.formComponentsService.buildQuestion(
            activityKey,
            question,
            sequence,
            formKey
        );

        // add question and update selected in right panel
        try {
            const questionKey = await this.formComponentsService.addQuestion(formKey, activityKey, newQuestion, appKey);
            this.setSelectedQuestion({ ...newQuestion, sequence: Math.round(sequence + 0.5), key: questionKey } as FormQuestion);
            return questionKey;
        } catch (e) {
            console.error('Add new form question error: ', e);
        }
    }

    async deleteFormQuestion(formKey: string, appKey: string, question: FormQuestion): Promise<void> {
        await this.formComponentsService.removeQuestion(formKey, question, appKey);
        this.removedQuestion$.next(question);
        this.setSelectedQuestion(null);
    }

    updateFormQuestionOrder(formKey: string, appKey: string, questionInfo: { question: FormQuestion, sequence: number }): void {
        const { question, sequence } = questionInfo;
        this.formComponentsService.updateQuestionsOrderAfterSort(formKey, appKey, question, sequence);
        this.setSelectedQuestion({ ...question, sequence: Math.round(sequence - 0.5) } as FormQuestion);
    }

    getAnsweredQuestionsRatio(
        answeredQuestions: AnsweredQuestionModel,
        questionKey: string,
        isSection: boolean,
        useDemographics: boolean,
        activeDemographics?: Demographic[],
        sectionDemographicKey?: string,
        repeatedQuestionsIds?: RepeatableQuestionsIds,
        sectionActiveDemographics?: string[],
        formBranchingVisibility?: FormBranchingVisibility
    ): [number, number] {

        if (useDemographics) {
            return this.getRatioWithDemographics(
                answeredQuestions,
                questionKey,
                isSection,
                repeatedQuestionsIds,
                sectionDemographicKey,
                activeDemographics,
                sectionActiveDemographics,
                formBranchingVisibility
            );
        } else {
            return this.getRatioWithoutDemographics(answeredQuestions, questionKey, isSection, repeatedQuestionsIds);
        }
    }

    private updateSelectedQuestion(question: FormQuestion): void {
        this.selectedQuestion.next(question);
        this.selectedQuestionKey = question.key;
        this.selectedQuestionSequence = question.sequence;
    }

    private getRatioWithoutDemographics(
        answeredQuestions: AnsweredQuestionModel,
        questionKey: string,
        isSection: boolean,
        repeatedQuestionsIds?: RepeatableQuestionsIds
    ): [number, number] {
        if (isSection) {
            const questionsKeys = Object.keys(answeredQuestions);
            const repeatedSectionsKeys = Object.keys(repeatedQuestionsIds || {});
            let max = 0;
            let answered = 0;

            questionsKeys.forEach(key => {
                const parsedKeyData = key.split(':');
                if (parsedKeyData.length === 1) {
                    return;
                }

                const sectionKey = parsedKeyData[0];
                if ([...repeatedSectionsKeys, questionKey].includes(sectionKey)) {
                    max++;

                    if (answeredQuestions[key]) {
                        answered++;
                    }
                }
            });

            return [answered, max];
        } else {
            return answeredQuestions[questionKey] ? [1, 1] : [0, 1];
        }
    }

    private getRatioWithDemographics(
        answeredQuestions: AnsweredQuestionModel,
        questionKey: string,
        isSection: boolean,
        repeatedQuestionsIds?: RepeatableQuestionsIds,
        sectionDemographicKey?: string,
        activeDemographics?: Demographic[],
        sectionActiveDemographics?: string[],
        formBranchingVisibility?: FormBranchingVisibility
    ): [number, number] {
        if (isSection) {

            if (!sectionActiveDemographics) {
                const questionsKeys = Object.keys(answeredQuestions[sectionDemographicKey] || {});
                const repeatedSectionsKeys = Object.keys(repeatedQuestionsIds || {});
                const repeatedSectionsKeysByDemographic = [];
                let max = 0;
                let answered = 0;

                repeatedSectionsKeys.forEach(repeatedSectionKey => {
                    if (repeatedQuestionsIds[repeatedSectionKey][sectionDemographicKey]) {
                       repeatedSectionsKeysByDemographic.push(repeatedSectionKey);
                    }
                });

                questionsKeys.forEach(key => {
                    const parsedKeyData = key.split(':');
                    if (parsedKeyData.length === 1) {
                        return;
                    }

                    const sectionKey = parsedKeyData[0];
                    if ([...repeatedSectionsKeysByDemographic, questionKey].includes(sectionKey)) {
                        max++;

                        if (answeredQuestions[sectionDemographicKey][key]) {
                            answered++;
                        }
                    }
                });

                return [answered, max];
            } else {
                // Case when section has own demographics

                let max = 0;
                let answered = 0;

                sectionActiveDemographics.forEach(sectionQuestionDemographicKey => {
                    const questionsKeys = Object.keys(answeredQuestions[sectionDemographicKey][sectionQuestionDemographicKey] || {});
                    questionsKeys.forEach(key => {
                        const parsedKeyData = key.split(':');
                        if (parsedKeyData[0] !== questionKey) {
                            return;
                        }

                        max++;

                        if (answeredQuestions[sectionDemographicKey][sectionQuestionDemographicKey][key]) {
                            answered++;
                        }
                    });
                });

                return [answered, max];
            }
        } else {
            let max = activeDemographics.length;
            let answered = 0;

            activeDemographics.forEach(activeDemographic => {
                const isVisible = this.checkDemographicVisibility(
                    formBranchingVisibility,
                    questionKey,
                    activeDemographic.key
                );

                // reduce num of max if question is disabled
                if (!isVisible) {
                    max--;
                }

                if (isVisible && answeredQuestions[activeDemographic.key][questionKey]) {
                    answered++;
                }
            });

            return [answered, max];
        }
    }

    private checkDemographicVisibility(
        formBranchingVisibility: FormBranchingVisibility, questionKey: string, demographicKey: string): boolean {

        if (!formBranchingVisibility) {
            return true;
        }

        return !!formBranchingVisibility[questionKey]
            && formBranchingVisibility[questionKey][demographicKey];
    }
}
