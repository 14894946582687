<div class="invite-page">
  <ng-container *ngIf="!isInviteFromAdmin; else inviteFromAdmin">
    <h1 class="title">Invite Attendees</h1>
  </ng-container>
  <ng-template #inviteFromAdmin>
    <span class="session-title">{{ sessionName$ | async }}</span>
    <h1 class="title">Invite Attendees</h1>
  </ng-template>

  <app-invite-form
    [instanceUsers]="instanceUsers"
    [users]="users"
    [addUsers]="addUsers"
    [cancelAction]="close"
    [isInviteFromAdmin]="isInviteFromAdmin"
    [queryParams]="queryParams$ | async"
  ></app-invite-form>
  <app-current-users-app-management
    *ngIf="isInviteFromAdmin && users"
    [users]="users"
    [userRoles]="userRoles"
  ></app-current-users-app-management>
</div>

<ul *ngIf="isNotificationActive" class="notification-container">
  <li class="tt-snackbar-notification notification">
    <p class="tt-snackbar-message">{{ inviteSentSuccessfullyText }}</p>
    <p matRipple class="tt-snackbar-button" (click)="closeNotification()">Got it</p>
  </li>
</ul>
