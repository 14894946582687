import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs/operators';

import { FirebaseActivityService } from '@app/core/services/activity.service';
import { trackByKey } from '@app/core/utils';
import { ValidationErrorsType } from '@app/core/models';
import { eventFlowValidationHtml } from '@app/apps/activity-drawer/dynamic-activity-drawer-components/activity-options-drawer/activity-options-drawer.constants';

@Component({
    selector: 'app-event-flow-validation-dialog',
    templateUrl: './event-flow-validation-dialog.component.html',
    styleUrls: ['./event-flow-validation-dialog.component.scss']
})
export class EventFlowValidationDialogComponent implements OnInit {
    validationTextData: string[];
    trackByKey = trackByKey;

    constructor(
        @Inject(MAT_DIALOG_DATA) private data: any,
        private dialogRef: MatDialogRef<EventFlowValidationDialogComponent>,
        private activityService: FirebaseActivityService
    ) { }

    onSubmit(): void {
        this.dialogRef.close();
    }

    async ngOnInit() {
        this.validationTextData = await this.initValidationErrors(this.data.validationData);
    }

    async initValidationErrors(validationData: ValidationErrorsType): Promise<string[]> {
        const validationErrors = [];

        if (!validationData) {
            return [];
        }

        for (let error of Object.keys(validationData)) {
            const activitiesData = validationData[error];
            const activitiesNames = await this.getActivitiesNames(activitiesData);
            const validationText = eventFlowValidationHtml[error];
            validationErrors.push(`${validationText} ${activitiesNames}`);
        }
        return validationErrors;
    }

    async getActivitiesNames(activitiesData: string[]): Promise<string> {
        let activitiesNamesStr = '';
        for (let [index, activityKey] of activitiesData.entries()) {
            const activity = await this.activityService.getActivity(activityKey).pipe((take(1))).toPromise();
            const name = index === 0 ? `'${activity.activity_name}'` : `, '${activity.activity_name}'`;
            activitiesNamesStr = activitiesNamesStr.concat(name);
        }
        return activitiesNamesStr;
    }
}
