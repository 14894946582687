import { GraphNode } from '../models/graph-node.model';

export interface GraphService {
    buildGraphNode(deploymentId: string, data: any): GraphNode;
    loadGraphNode(deploymentId: string, nodeId: string): Promise<GraphNode>;
    propagateAll(deploymentId: string): Promise<void>;
    propagateAllForDemographic(deploymentId: string, demographicId: string): Promise<void>;
    setResponse(deploymentId: string, nodeId: string, responseUpdate: any): Promise<void>;
    updateNode(deploymentId: string, nodeId: string, update: any): Promise<void>;
    writeVariableUpdates(
        deploymentId: string,
        nodeId: string,
        formId: string,
        graphPropertyPath: string,
        formViewPropertyPath: string,
        update: any
    ): Promise<void>;
}
