import { Component, Input } from '@angular/core';

import { User } from '@app/core/models/user.model';

@Component({
    selector: 'app-user-pic',
    templateUrl: './user-pic.component.html',
    styleUrls: ['./user-pic.component.scss']
})

export class UserPicComponent {
    // you can choose user or name to put on this component
    @Input() user?: User;
    @Input() name?: string;

    constructor() {}

    get shortName(): string {
        if (this.name) {
            const name = this.name.match(/\b\w/g).join('');
            return `${name.length > 1 ? name.substring(0, 2) : name[0]}`;
        }
        if (!this.user || (this.user && !this.user.email && !this.user.firstName)) {
            return '';
        } else if (this.user) {
            if (this.user.firstName) {
                return `${this.user.firstName[0]}${this.user.lastName[0]}`;
            } else if (this.user.email) {
                const email = this.user.email.replace(/\W|\d/g, '');
                return `${email.length > 1 ? email.slice(0, 2).toUpperCase() : ''}`;
            }
        }
    }
}
