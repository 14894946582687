import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-information-snack-bar',
  templateUrl: './information-snack-bar.component.html',
  styleUrls: ['./information-snack-bar.component.scss']
})

export class InformationSnackBarComponent {
    private _onOpen = new Subject<void>();

    constructor(
        public snackBarRef: MatSnackBarRef<InformationSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    onOpen(): Observable<void> {
        return this._onOpen.asObservable();
    }

    open(): void {
        this._onOpen.next();
    }

    close(event: any): void {
        event.stopPropagation();
        this.snackBarRef.dismiss();
    }
}
