import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { distinctUntilChanged, filter, take, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { NameValidationService } from '@app/core/services/name-validation.service';
import { specialCharacterValidator } from '@app/shared/validators/special-symbol-validator';
import { validationMessages } from '@app/apps/apps.component.forms-constants';

@UntilDestroy()
@Component({
    selector: 'app-export-rename-dialog',
    templateUrl: './export-rename-dialog.component.html',
    styleUrls: ['./export-rename-dialog.component.scss']
})
export class ExportRenameDialogComponent implements OnInit, OnDestroy {
    name: string;
    originKey: string;
    appKey: string;
    userKey: string;
    exportVersionKey: string;
    renameForm: FormGroup;
    isPendingStatus: boolean;

    validationMessages = validationMessages;

    private status$: BehaviorSubject<string> = new BehaviorSubject(null);

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ExportRenameDialogComponent>,
        private nameValidationService: NameValidationService
    ) {
        this.name = this.data.name;
        this.originKey = this.data.originKey;
        this.appKey = this.data.appKey;
        this.userKey = this.data.userKey;
        this.exportVersionKey = this.data.exportVersionKey;
        const nameControl = new FormControl(this.name, { validators: [specialCharacterValidator] });
        this.nameValidationService.sameNameValidation(
            nameControl,
            true,
            this.originKey,
            this.appKey,
            this.userKey,
            this.exportVersionKey
        );
        this.renameForm = new FormGroup({ name: nameControl }, { updateOn: 'blur' });
    }

    cancel(): void {
        this.dialogRef.close();
    }

    rename(): void {
        setTimeout(async () => {
            const isValid = await this.status$.pipe(
                filter(status => status.includes('VALID')),
                map(status => status === 'VALID'),
                take(1)
            ).toPromise();

            if (isValid) {
                const control = this.renameForm.get('name');
                this.data.handleConfirm(control.value.trim()).then(() => {
                    this.dialogRef.close();
                });
            }
        }, 500);
    }

    ngOnInit() {
        this.renameForm.statusChanges.pipe(
            untilDestroyed(this),
            distinctUntilChanged()
        ).subscribe((status) => {
            this.status$.next(status);

            if (status === 'PENDING') {
                this.isPendingStatus = true;
            }

            if (status === 'INVALID') {
                this.nameValidationService.isVersionNameInvalid = true;
                this.isPendingStatus = false;
            }

            if (status === 'VALID') {
                this.nameValidationService.isVersionNameInvalid = false;
                this.isPendingStatus = false;
            }
        });
    }

    ngOnDestroy() {}
}
