import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';

import { sessionRoles, User, UserSessionRole } from '@app/core/models';
import { FirebaseAppService, FirebaseUsersService } from '@app/core/services';
import { userWithoutEmailText } from '../current-users-app-management/current-users-table/current-users-table.constants';
import { inviteSentSuccessfullyText } from './invite.constants';

@UntilDestroy()
@Component({
    selector: 'app-invite',
    templateUrl: './invite.component.html',
    styleUrls: ['./invite.component.scss'],
})
export class InviteComponent implements OnInit, OnDestroy {
    instanceUsers: any[];
    instanceKey: string;
    instanceUrl: string;
    isInviteFromAdmin: boolean;
    users: User[];
    userRoles = <UserSessionRole[]>['Leader', 'Participant', 'Observer'];
    isNotificationActive = false;
    inviteSentSuccessfullyText = inviteSentSuccessfullyText;
    sessionName$: Observable<string>;
    queryParams$: Observable<Params>;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public location: Location,
        public fbUsrService: FirebaseUsersService,
        public firebaseAppService: FirebaseAppService,
        @Inject('Window') private window: Window,
    ) {}

    addUsers = (usersList: any, message?: string): void => {
        this.fbUsrService.handleUsers(usersList, this.instanceKey, this.instanceUrl, message);
        if (this.isInviteFromAdmin) {
            this.isNotificationActive = true;

            setTimeout(() => {
                this.closeNotification();
            }, 10000);
        }
    };

    close = (): void => {
        this.location.back();
    };

    closeNotification(): void {
        this.isNotificationActive = false;
    }

    ngOnInit() {
        const pageUrl = window.location.href;

        this.instanceUrl = pageUrl.substring(0, pageUrl.lastIndexOf('/'));
        this.instanceKey = this.router.url.split('/')[2]; // the second parameter
        this.isInviteFromAdmin = this.router.url.split('/')[1] === 'administration' ? true : false; // the first parameter

        this.fbUsrService
            .getInstanceUsers(this.instanceKey)
            .pipe(untilDestroyed(this))
            .subscribe((users): void => {
                this.users = users
                    .filter(user => user.key)
                    .map(user => {
                        const email = user.guest && !user.email.includes('@') ? userWithoutEmailText : user.email;

                        return {
                            key: user.key,
                            firstName: user.firstName || '',
                            lastName: user.lastName || '',
                            email,
                            app_role: sessionRoles?.[user?.app_instances?.[this.instanceKey]],
                            last_login: user.last_login,
                        };
                    });

                this.instanceUsers = users.map(user => user.email);
            });

        this.sessionName$ = this.firebaseAppService.getAppField(this.instanceKey, 'name');

        this.queryParams$ = this.route.queryParams;
    }

    ngOnDestroy(): void {}
}
