import { createAction, props } from '@ngrx/store';

import { FormStatusesMap } from '@app/root-store/models/forms-feature.models';

const getActivityFormStatusesType = '[Forms] get form statuses of activity';
const getActivityFormStatusesSuccessType = '[Forms] get form statuses of activity success';
const getActivityFormStatusesFailType = '[Forms] get form statuses of activity fail';

export const getActivityFormStatuses = createAction(
  getActivityFormStatusesType,
  props<{ activityKey: string }>()
);

export const getActivityFormStatusesSuccess = createAction(
  getActivityFormStatusesSuccessType,
  props<{ activityKey: string, formStatuses: FormStatusesMap }>()
);

export const getActivityFormStatusesFail = createAction(
  getActivityFormStatusesFailType,
  props<{ errorMessage: string }>()
);
