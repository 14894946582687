import { ObjectType, Process, Subprocess } from '../models';
import { ChangeRequestStatus, ChangeRequest } from '@thinktank/common-lib';

export const getSatisfactionValue = (item: Process | Subprocess, changeRequests: ChangeRequest[]): number => {
    const itemType = item.type;
    const totalRequests = changeRequests.filter((request) => {
        return itemType === ObjectType.Subprocess ? request.subprocess_id === item.id : request.process_id === item.id;
    });
    const openRequests = totalRequests.filter((request) => {
        return request.status === ChangeRequestStatus.Pending;
    });

    let returnValue = 100;

    if (totalRequests.length > 0) {
        returnValue = 100 - ((openRequests.length / totalRequests.length) * 100);
    }

    return returnValue;
}

export const getNumberChangeRequests = (item: Process | Subprocess, changeRequests: ChangeRequest[], isOpen: boolean): number => {
    const itemType = item.type;

    let requests = changeRequests.filter((request) => {
        return itemType === ObjectType.Subprocess
          ? request.subprocess_id === item.id
          : request.process_id === item.id;
    });

    if (isOpen) {
        requests = requests.filter((request) => {
            return request.status === ChangeRequestStatus.Pending;
        });
    }

    return requests.length;
}

export const getPulseDate = (item: Process | Subprocess): Date => {
    return new Date();
}
