<div class="gs-table-wrapper">
  <table class="gs-table">
    <!-- Table header -->
    <thead>
      <tr>
        <th class="gs-table-th">Module Name</th>
        <th class="gs-table-th">Action</th>
      </tr>
    </thead>
    <!-- /Table header -->

    <!-- Table body -->
    <tbody>
      <ng-container *ngFor="let module of deploymentModules; trackBy: trackById">
        <!-- Simple row -->
        <tr>
          <!-- Module Name -->
          <td class="gs-table-td">{{ module.name }}</td>
          <!-- /Module Name -->

          <!-- Action -->
          <td class="gs-table-td">
            <button
              *ngIf="!moduleIsInDeployment(module)"
              class="gs-button-flat gs-small"
              (click)="addModuleToDeployment(module)"
            >
              Enable Module
            </button>
            <button
              *ngIf="moduleIsInDeployment(module)"
              class="gs-button-flat gs-small"
              (click)="removeModuleFromDeployment(module)"
            >
              Disable Module
            </button>
          </td>
          <!-- /Action -->
        </tr>
        <!-- /Simple row -->
      </ng-container>
    </tbody>
  </table>
</div>
