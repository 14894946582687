import { FormBuilder } from '@angular/forms';
import { FormQuestion, SelectResponse } from '@thinktank/common-lib';
import { QuestionFormControlHandler } from './question-form-control-handler';

export class SingleSelectQuestionFormControlHandler extends QuestionFormControlHandler {

    constructor(formBuilder: FormBuilder) {
        super(formBuilder);
    }

    protected getResponse(question: FormQuestion, demographicId: string): string | SelectResponse {
        const response = super.getResponse(question, demographicId) as string;
        return response === '' ? '' : { value: response };
    }
}
