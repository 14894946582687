import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { authStateReducer } from '@app/root-store/features/auth/reducers/auth.reducer';
import { AuthEffects } from '@app/root-store/features/auth/effects/auth.effects';
import { AuthSubscriptionService } from '@app/root-store/features/auth/services/auth-subscription.service';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('authState', authStateReducer),
        EffectsModule.forFeature([AuthEffects])
    ],
    exports: [],
    providers: [
        AuthSubscriptionService
    ],
})
export class AuthStateModule { }
