import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './reducers';
import { FormsEffects } from './effects';
import { FormsFeatureService } from './services/forms.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('forms', reducer),
    EffectsModule.forFeature([FormsEffects])
  ],
  exports: [],
  providers: [FormsFeatureService]
})
export class FormsStateModule { }
