import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AssignmentViewModel, ObjectType, UserRole } from '../../models';

export interface AssignmentFacade {
    vm$: Observable<AssignmentViewModel>;

    assignUser(
        email: string,
        role: UserRole,
        deploymentId: string,
        objectId: string,
        objectType: ObjectType,
        activeDemographics: string[],
        isGlobal: boolean
    ): Promise<void>;

    updateOwnershipPrimary(primaryId: string, previousPrimaryId: string, deploymentId: string): Promise<void>;
}

export const AssignmentFacadeToken = new InjectionToken<AssignmentFacade>('AssignmentFacade');
