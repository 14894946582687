import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, Validator } from '@angular/forms';

@Directive({
    selector: '[appEmptyValue][ngModel],[appEmptyValue][formControl],[appEmptyValue][formControlName]',
    // eslint-disable-next-line
    providers: [{ provide: NG_VALIDATORS, useExisting: EmptyValueValidatorDirective, multi: true }],
})
export class EmptyValueValidatorDirective implements Validator {
    constructor() {}

    validate(control: AbstractControl): { [key: string]: any } {
        const value = control ? `${control.value ?? ''}`.trim() : '';

        return !value.length ? { emptyValue: { value: control.value } } : null;
    }
}
