import {
    Component,
    Input,
    Output,
    OnChanges,
    SimpleChanges,
    ChangeDetectionStrategy,
    EventEmitter
} from '@angular/core';
import { groupBy as _groupBy } from 'lodash';

import { Demographic, trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-demographics-list',
    templateUrl: './demographics-list.component.html',
    styleUrls: ['./demographics-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DemographicsListComponent implements OnChanges {

    @Input() demographicsList: Demographic[];
    @Input() searchValue: string;
    @Input() selectedDemographics?: Demographic[];
    @Output() onSelectDemographic = new EventEmitter<{ isChecked: boolean, demographic: Demographic }>();
    @Output() onSelectGroupDemographics = new EventEmitter<{ isChecked: boolean, demographics: Demographic[] }>();

    selectedValues: string[] = [];
    trackById = trackById;
    demographics: { [regionId: string]: Demographic[] } = {};
    selectAllChecked: { [regionId: string]: boolean } = {};
    regions: string[] = [];
    lastRegionSelected = '';

    constructor() { }

    selectAllRegion(demographics: Demographic[], region: string): void {
        this.selectAllChecked[region] = !!this.selectAllChecked[region]
            ? !this.selectAllChecked[region]
            : true;

        this.onSelectGroupDemographics.emit({
            isChecked: this.selectAllChecked[region],
            demographics
        });
    }

    isChecked(value: string): boolean {
        return !!this.selectedValues && this.selectedValues.includes(value);
    }

    selectDemographic(event: any, demographic: Demographic): void {
        this.onSelectDemographic.emit({
            isChecked: event.checked,
            demographic
        });

        this.lastRegionSelected = demographic.region;

    }

    filterDemographics(value?: string): void {
        const filterValue = (!!value && value) || '';

        const demographics =
            (this.demographicsList || []).filter((demographic: Demographic) => {
                return demographic.name.toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
            });
        this.demographics = _groupBy(demographics, 'region');
        this.regions = this.getRegions();

        // clear select all buttons
        this.selectAllChecked = {};
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.searchValue && !!this.demographicsList) {
             this.filterDemographics(this.searchValue);
        }

        if (changes.demographicsList && !!this.demographicsList) {
            this.demographics = _groupBy(this.demographicsList, 'region');
            this.regions = this.getRegions();
        }

        if (changes.selectedDemographics && !!this.selectedDemographics) {
            this.selectedValues = this.selectedDemographics.map(demographic => demographic.id);

            // See if the user has selected all regions by hand so we can set the select button text accordingly
            if (!!this.lastRegionSelected) {
                const lastRegionDemographicsSelected = this.selectedDemographics.filter((demographic) => this.byLastRegionSelected(demographic));
                this.selectAllChecked[this.lastRegionSelected] =
                    lastRegionDemographicsSelected.length === this.demographics[this.lastRegionSelected].length;
                this.lastRegionSelected = '';
            }
        }
    }

    private byLastRegionSelected(demographic: Demographic): boolean {
        return demographic.region === this.lastRegionSelected;
    }

    private getRegions(): string[] {
        return Object.keys(this.demographics).sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }));
    }

}
