import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InviteFormComponent } from './components/shared/invite-form/invite-form.component';
import { UsersPanelComponent } from './components/shared/users-panel/users-panel.component';
import { UsersPanelListComponent } from './components/shared/users-panel/users-panel-list/users-panel-list.component';
import { UsersPanelTabComponent } from './components/shared/users-panel/users-panel-tab/users-panel-tab.component';
import { DemographicsListComponent } from './components/shared/demographics-list/demographics-list.component';
import { CreateDeploymentComponent } from './components/create-deployment/create-deployment.component';
import { DeploymentTemplateCardComponent } from './components/create-deployment/deployment-template-card/deployment-template-card.component';
import { StakeholderInviteComponent } from './components/create-deployment/stakeholder-invite/stakeholder-invite.component';
import { TaskListCardComponent } from './components/create-deployment/task-list-card/task-list-card.component';
import { ItemTextPipe } from './components/shared/pipes/item-text.pipe';
import {
  DeploymentViewComponent,
  ModuleViewComponent,
  ProcessViewComponent,
  SubprocessViewComponent,
  ViewProcessesTableComponent,
  ViewChangeRequestsTableComponent,
  ViewAssigneesComponent,
  ImageComponent,
  ViewDemographicsComponent,
  AssigneesComponent,
  VolunteerDialogComponent,
  AssignmentComponent,
  DemographicsPanelComponent,
  DeploymentEditorComponent,
  ModuleSelectionEditorComponent,
  DemographicsEditorComponent,
  ModuleEditorComponent,
  CreateDeploymentContainerComponent
} from './components';
import {
  AttachmentQuestionComponent,
  BaseQuestionComponent,
  CondensedFormComponent,
  DateQuestionComponent,
  DynamicFormComponent,
  EmptyScreenComponent,
  FileQuestionComponent,
  FormEditComponent,
  FormImpactComponent,
  ImpactTableComponent,
  MediaQuestionComponent,
  NumericQuestionComponent,
  QuestionViewComponent,
  SectionComponent,
  SectionDescriptionComponent,
  SelectQuestionComponent,
  TextQuestionComponent
} from './deployment-form-view';
import { CommonLibModule, MaterialModule } from '@thinktank/common-lib';
import { DeploymentsService, LoaderService, WorkBookExportService } from './services';
import { FirestoreService, WindowService } from '@thinktank/common-lib';
import { InfoSnackbarComponent } from './components/shared/info-snackbar/info-snackbar.component';

@NgModule({
    declarations: [
        DeploymentViewComponent,
        ModuleViewComponent,
        ProcessViewComponent,
        SubprocessViewComponent,
        ViewProcessesTableComponent,
        ViewChangeRequestsTableComponent,
        ViewAssigneesComponent,
        ImageComponent,
        ViewDemographicsComponent,
        AssigneesComponent,
        VolunteerDialogComponent,
        AssignmentComponent,
        DemographicsPanelComponent,
        InviteFormComponent,
        UsersPanelComponent,
        UsersPanelListComponent,
        UsersPanelTabComponent,
        DeploymentEditorComponent,
        ModuleSelectionEditorComponent,
        DemographicsEditorComponent,
        DemographicsListComponent,
        ModuleEditorComponent,
        CreateDeploymentContainerComponent,
        CreateDeploymentComponent,
        DeploymentTemplateCardComponent,
        StakeholderInviteComponent,
        TaskListCardComponent,
        // deployment form view components
        CondensedFormComponent,
        DynamicFormComponent,
        QuestionViewComponent,
        AttachmentQuestionComponent,
        BaseQuestionComponent,
        DateQuestionComponent,
        FileQuestionComponent,
        MediaQuestionComponent,
        NumericQuestionComponent,
        SelectQuestionComponent,
        TextQuestionComponent,
        SectionComponent,
        SectionDescriptionComponent,
        FormEditComponent,
        EmptyScreenComponent,
        FormImpactComponent,
        ImpactTableComponent,
        InfoSnackbarComponent,
        ItemTextPipe
    ],
    imports: [
        CommonModule,
        CommonLibModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        DeploymentViewComponent,
        ModuleViewComponent,
        ProcessViewComponent,
        SubprocessViewComponent,
        AssignmentComponent,
        CreateDeploymentContainerComponent,
        InfoSnackbarComponent
    ],
    providers: [
        DeploymentsService,
        FirestoreService,
        WorkBookExportService,
        LoaderService,
        WindowService
    ]
})
export class CommonDeploymentsModule {
}
