export interface Attachment {
    date_created: number;
    owner: string;
    name: string;
    download_url: string;
    type?: string;
    file_type?: string;
    id?: string;
    sub_key?: string;
    response_id?: string;
    question_id?: string;
    form_id?: string;
    demographic_id?: string;
    deployment_id?: string;
}
