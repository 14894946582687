<div class="gs-impact-table">
  <div class="gs-table-wrapper">
    <ng-container *ngIf="!!impactData && questionIds?.length; else noDataBlock">
      <table class="gs-table">
        <!-- Table header -->
        <thead>
          <tr>
            <th class="gs-table-th">Requirement</th>
            <th class="gs-table-th">Process Impacted</th>
            <th class="gs-table-th">Sub Process Impacted</th>
            <th class="gs-table-th">Requirement Impacted</th>
          </tr>
        </thead>
        <!-- /Table header -->

        <tbody>
          <ng-container *ngFor="let questionId of questionIds">
            <tr *ngFor="let data of groupedImpactData[questionId]; trackBy: trackById; let i = index">
              <td *ngIf="i === 0" class="gs-table-td" [attr.rowspan]="groupedImpactData[questionId].length">
                {{ data.question_label?.current_value || data.question_label?.default }}
              </td>
              <td class="gs-table-td">
                {{ data.process_impacted_name }}
              </td>
              <td class="gs-table-td">
                {{ data.subprocess_impacted_name }}
              </td>
              <td class="gs-table-td">
                {{ data.question_impacted_label?.current_value || data.question_impacted_label?.default }}
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </ng-container>

    <ng-template #noDataBlock>
      <gs-empty-screen
        class="empty-screen"
        [imageUrl]="'/assets/svg/gs-empty-impact.svg'"
        [text]="'This form doesn’t impact on any processes and subprocesses'"
      ></gs-empty-screen>
    </ng-template>
  </div>
</div>
