export enum Frequency {
    Extreme = 'EXTREME',
    High = 'HIGH',
    Medium = 'MEDIUM',
    Low = 'LOW',
    NoFrequency = 'NO_FREQUENCY'
}

export const FrequencyData = {
    [Frequency.Extreme]: {
        value: 'Extreme',
        sequence: 4,
        icon: 'arrow_forward'
    },
    [Frequency.High]: {
        value: 'High',
        sequence: 3,
        icon: 'arrow_upward'
    },
    [Frequency.Medium]: {
        value: 'Medium',
        sequence: 2,
        icon: 'arrow_forward'
    },
    [Frequency.Low]: {
        value: 'Low',
        sequence: 1,
        icon: 'arrow_downward'
    },
    [Frequency.NoFrequency]: {
        value: 'No Frequency',
        sequence: 0,
        icon: 'not_interested'
    }
};

export interface FrequencyModel {
    [Frequency.Extreme]: FrequencyUnit,
    [Frequency.High]: FrequencyUnit,
    [Frequency.Medium]: FrequencyUnit,
    [Frequency.Low]: FrequencyUnit,
    [Frequency.NoFrequency]: FrequencyUnit
}

export interface FrequencyUnit {
    value: string,
    sequence: number,
    icon: string
}

export interface FrequencyConfig {
    [Frequency.Extreme]: boolean[],
    [Frequency.Extreme]: boolean[],
    [Frequency.Extreme]: boolean[],
    [Frequency.Extreme]: boolean[],
    [Frequency.Extreme]: boolean[],
}
