import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { of, Observable, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class RouterService {

    constructor(
        private router: Router,
        private route: ActivatedRoute
    ) {
    }

    getRouterUrl(): string {
        return this.router.url;
    }

    navigateTo(params: any[], additionalParams?: any): void {
        if (additionalParams) {
            this.router.navigate(params, additionalParams);
        } else {
            this.router.navigate(params);
        }
    }

    getRouterEvents(): Observable<ActivatedRoute> {
        return merge(
            of(new NavigationEnd(0, '', '')),
            this.router.events
        ).pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.route),
            map((activeRoute) => {
                while (activeRoute.firstChild) {
                    activeRoute = activeRoute.firstChild;
                }
                return activeRoute;
            })
        );
    }
}
