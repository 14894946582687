import { Component, Input, OnInit, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { environment } from '@env/environment';

import {
    AuthenticatedUserService,
    AwsCognitoAuthService,
    FirebaseNavigationService,
    FirebaseAuthenticationService,
    FeatureToggleService,
} from '@app/core/services';
import { Navigation } from '@app/core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DeploymentsService } from '@thinktank/common-deployments';
import { FilterAppStorageService } from '@app/core/services/filter-app-storage.service';

@UntilDestroy()
@Component({
    selector: 'app-header-container',
    templateUrl: './header-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderContainerComponent implements OnInit, OnDestroy {
    @Input() isNavigation: boolean;
    @Input() defaultNavigationKey?: string;

    currentUserKey: string;
    isAdmin: boolean;
    currentUserImage: string;
    currentUserName: string;
    shortUserName: string;
    selectedNavigationKey: string;
    selectedNavigationItem: Navigation;
    doesUserHaveApps: boolean;
    doesUserHaveDeployments: boolean;
    hasUserLeaderSessionRole$: Observable<boolean>;
    hasUserWorkbookPermission$: Observable<boolean>;
    fredgaged: boolean;
    navigationItems$: BehaviorSubject<Navigation[]> = new BehaviorSubject<Navigation[]>([]);

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        private fbNavigationService: FirebaseNavigationService,
        private fbAuthUserService: AuthenticatedUserService,
        private fbAuthenticationService: FirebaseAuthenticationService,
        private awsCognitoAuthService: AwsCognitoAuthService,
        private featureToggleService: FeatureToggleService,
        private deploymentsService: DeploymentsService,
        private authService: AuthenticationService,
        private filterAppStorageService: FilterAppStorageService,
    ) {
        this.currentUserName = '';
        this.shortUserName = '';
    }

    openTab(navigationItemKey: string): void {
        this.router.navigate(['navigation'], {
            relativeTo: this.route,
            queryParams: {
                tab: navigationItemKey,
            },
        });
    }

    async logout(): Promise<void> {
        await this.filterAppStorageService.removeFilterAppDefaultData(this.currentUserKey);

        this.fbAuthUserService
            .clearCurrentUser()
            .pipe(
                switchMap(() => this.fbAuthenticationService.signOut()),
                take(1),
            )
            .subscribe(() => {
                this.authService.isLoggedIn().then(result => {
                    if (result) {
                        this.authService.logout();
                    }
                });
            });
    }

    ngOnInit() {
        this.getUserInfo();
        this.getNavigationInfo();
    }

    ngOnDestroy() {}

    private getNavigationInfo(): void {
        const currentUserId = this.fbAuthUserService.getCurrentUserId();
        const queryParams$ = this.route.queryParams;
        const userHasApps$ = this.fbAuthUserService.hasUserApps(currentUserId);

        combineLatest([
            this.fbNavigationService.getNavigationData(),
            queryParams$,
            userHasApps$,
            this.featureToggleService.getFeatureToggleValue('fredgage'),
            this.fbAuthUserService.doesUserHaveDeployments(currentUserId),
            this.deploymentsService.getDeploymentTemplatesForUser(currentUserId),
        ])
            .pipe(untilDestroyed(this))
            .subscribe(
                ([
                    navigationItems,
                    queryParams,
                    userHasApps,
                    fredgageEnabled,
                    doesUserHaveDeployments,
                    userDeploymentTemplates,
                ]) => {
                    this.navigationItems$.next(navigationItems);
                    this.fredgaged = fredgageEnabled;
                    this.doesUserHaveDeployments = doesUserHaveDeployments || userDeploymentTemplates.length > 0;
                    this.doesUserHaveApps = userHasApps;
                    this.selectedNavigationKey = queryParams.tab || this.defaultNavigationKey;
                    this.selectedNavigationItem = navigationItems.find(item => item.key === this.selectedNavigationKey);
                    const isNavigationItemAvailable =
                        this.doesUserHaveApps ||
                        (!this.doesUserHaveApps &&
                            navigationItems &&
                            this.selectedNavigationItem !== navigationItems[0]);

                    if (
                        this.isNavigation &&
                        (navigationItems || []).length &&
                        (!this.selectedNavigationKey || !isNavigationItemAvailable)
                    ) {
                        const tabToOpen = this.doesUserHaveApps ? navigationItems[0] : navigationItems[1];

                        this.openTab(tabToOpen.key);
                    }
                },
            );
    }

    private getUserInfo(): void {
        this.currentUserKey = this.fbAuthUserService.getCurrentUserId();
        this.currentUserImage = this.fbAuthUserService.getCurrentImage();
        this.isAdmin = this.fbAuthUserService.isUserAdmin();
        this.hasUserLeaderSessionRole$ = this.fbAuthUserService.hasUserLeaderSessionRole(this.currentUserKey);
        this.hasUserWorkbookPermission$ = this.fbAuthUserService.hasCurrentUserWorkbookPermission();

        // update currentUserName after it was changed
        this.fbAuthUserService
            .isUserAuthenticated()
            .pipe(untilDestroyed(this))
            .subscribe((userLoaded: boolean) => {
                if (!userLoaded) {
                    return;
                }
                this.currentUserName = this.fbAuthUserService.getCurrentUserFullName();
                this.shortUserName = (this.currentUserName || '')
                    .split(' ')
                    .map(key => key[0])
                    .join('');
            });
    }
}
