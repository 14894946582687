import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ConfirmActionDialogComponent } from '../shared/dialogs/confirm-action-dialog/confirm-action-dialog.component';

@Injectable()
export class DialogService {
    private dialogComponents: object;
    private defaultDialogData: object;

    constructor(public dialog: MatDialog) {
        this.dialogComponents = {
            ConfirmActionDialogComponent,
        };

        this.defaultDialogData = {
            confirmBtnText: 'Ok',
            cancelBtnText: 'Cancel',
            closeOnConfirm: true
        };
    }

    open(dialogComponentName: string, data: any): MatDialogRef<any> {
        const dialogComponent = this.dialogComponents[dialogComponentName];

        return this.dialog.open(dialogComponent, {
            data: {
                ...this.defaultDialogData,
                ...data
            },
            width: data['width'] || '300px',
            height: data['height'] || 'auto',
            maxWidth: data['maxWidth'] || '300px',
            maxHeight: data['maxHeight'] || 'auto',
            panelClass: data['panelClass'] || '',
            position: data['position'] || '',
            disableClose: data['disableClose'] || false,
            restoreFocus: data['restoreFocus'] || false,
            backdropClass: data['backdropClass'] || ''
        });
    }
}
