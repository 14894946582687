import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { FirebaseAppService } from '@app/core/services/app.service';
import { App } from '@app/core/models/app.model';
import { sortByAlphabetic, trackByKey } from '@app/core/utils';

@Component({
    selector: 'app-sessions-list-dialog',
    templateUrl: './sessions-list-dialog.component.html',
    styleUrls: ['./sessions-list-dialog.component.scss']
})

export class SessionsListDialogComponent implements OnInit, OnDestroy {
    sessions: App[];
    selectedSessionKey: string;
    demographicSessionsCount = 0;
    searchText = '';
    trackByKey = trackByKey;
    private sessions$: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SessionsListDialogComponent>,
        private fbAppService: FirebaseAppService
    ) {}

    get showNote(): boolean {
        return !!this.sessions && this.data.isDemographics && !!this.demographicSessionsCount;
    }

    getSessions(): App[] {
        if (!this.sessions) {
            return null;
        }
        return this.sessions
            .filter(session => (session.name || '').toLowerCase().includes(this.searchText.toLowerCase()));
    }

    isDisabled(session: App): boolean {
        return this.data.isDemographics && session.hasDemographicActivity;
    }

    onClick(session: App): void {
        if (!this.isDisabled(session)) {
            this.selectedSessionKey = session.key;
        }
    }

    handleConfirm(): void {
        const { handleConfirm, closeOnConfirm } = this.data;
        handleConfirm(this.selectedSessionKey);
        if (closeOnConfirm) {
            this.dialogRef.close();
        }
    }

    ngOnInit() {
        this.sessions$ = this.fbAppService.getLeaderSessions(this.data.sessionKey, this.data.isDemographics)
            .subscribe((sessions) => {
                this.sessions = sortByAlphabetic(sessions, 'name') || [];
                this.demographicSessionsCount = sessions.filter(session => session.hasDemographicActivity).length;
            });
    }

    ngOnDestroy() {
        this.sessions$.unsubscribe();
    }
}
