import { ChangeDetectionStrategy, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormItemType, FormUpdatedResponse, trackById } from '@thinktank/common-lib';
import { BaseQuestionComponent } from '../base-question/base-question.component';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-select-question',
    templateUrl: './select-question.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectQuestionComponent extends BaseQuestionComponent implements OnChanges, OnInit, OnDestroy {
    trackById = trackById;

    constructor(@Inject(DeploymentFormFacadeToken) formFacade: DeploymentFormFacade) {
        super(formFacade);
    }

    get isMultiSelect(): boolean {
        return this.question.type === FormItemType.MultiSelect;
    }

    get panelClass(): string | string[] {
        return this.isMultiSelect
            ? ['mat-select-panel-with-checkboxes', 'mat-select-panel-custom']
            : 'mat-select-panel-custom';
    }

    compareValues(value1, value2): boolean {
        return !!value1 && !!value2 ? value1.value === value2.value : value1 === value2;
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`SelectQuestionComponent::ngOnChanges - changes for ${this.question.id}`, changes);
    }

    ngOnInit(): void {
        this.onValueChanges();
    }

    ngOnDestroy(): void {
        this.onDestroy();
    }

    protected buildUpdatedResponse(response: any): FormUpdatedResponse {
        let flattenedResponse = '';
        if (this.isMultiSelect) {
            flattenedResponse = !!response && response.length > 0
                ? response.map((individualResponse) => individualResponse.value)
                : response;
        } else {
            flattenedResponse = !!response && !!response.value
                ? response.value
                : response;
        }
        return super.buildUpdatedResponse(flattenedResponse);
    }
}
