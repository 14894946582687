import { createAction, props } from '@ngrx/store';

import { Demographic } from '@app/core/models';

const getSelectedDemographicsType = '[Demographics] get selected demographics';
const getSelectedDemographicsSuccessType = '[Demographics] get selected demographics success';
const getSelectedDemographicsFailType = '[Demographics] get selected demographics fail';

export const getSelectedDemographics = createAction(
    getSelectedDemographicsType,
    props<{ userId: string, demographicsClassKey: string }>()
);

export const getSelectedDemographicsSuccess = createAction(
    getSelectedDemographicsSuccessType,
    props<{ userId: string, demographicsClassKey: string, selectedDemographics: Demographic[] }>()
);

export const getSelectedDemographicsFail = createAction(
    getSelectedDemographicsFailType,
    props<{ errorMessage: string }>()
);
