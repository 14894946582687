import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, debounceTime, map, mergeMap, switchMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { FormValidationService } from '../services';
import * as FormValidationErrorsActions from '../actions';
import { from, of } from 'rxjs';

@UntilDestroy()
@Injectable()
export class FromValidationEffects implements OnDestroy {

    public getFormValidationErrors$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FormValidationErrorsActions.getFormValidationErrors),
            mergeMap((action) => {
                return this.formValidationService
                    .getValidationErrors(action.activityId)
                    .pipe(
                        debounceTime(300),
                        map(errors => FormValidationErrorsActions.getFormValidationErrorsSuccess({ errors })),
                        catchError(error => of(FormValidationErrorsActions.getFormValidationErrorsFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));


    public validateForm$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FormValidationErrorsActions.validateFormCall),
            switchMap((action) => {
                return from(this.formValidationService.validateForm(action.activityId))
                    .pipe(
                        map(() => FormValidationErrorsActions.validateFormCallSuccess()),
                        catchError(error => of(FormValidationErrorsActions.validateFormCallFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private formValidationService: FormValidationService
    ) { }

    ngOnDestroy() { }
}
