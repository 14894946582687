import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, of, combineLatest } from 'rxjs';
import { map, switchMap, mergeMap } from 'rxjs/operators';
import { sortByAlphabetic, FirestoreService, Demographic } from '@thinktank/common-lib';

import { Deployment, DeploymentTemplate, Module, Ownership, PendingDeployment, Process, Subprocess, UserRole } from '../models';

@Injectable({
    providedIn: 'root'
})
export class DeploymentsService {
    constructor(
        private firestoreService: FirestoreService,
        private afFun: AngularFireFunctions
    ) {}

    async createDeploymentInstance(
        clientName: string,
        deploymentToCopy: Deployment,
        modulesToCopy: Module[],
        roster: string[],
        selectedDemographics: Demographic[],
        selectedOverlayTemplateId: string,
        currentUserId: string
    ): Promise<string> {
        const newRoster = Array.from(roster || []);
        newRoster.push(currentUserId);
        const deploymentCopyId = this.firestoreService.getPushId();
        await this.afFun.httpsCallable('createDeployment')({
            sourceDeploymentId: deploymentToCopy.id,
            destinationDeploymentId: deploymentCopyId,
            moduleIds: modulesToCopy.map((moduleToCopy) => moduleToCopy.id),
            clientName,
            roster: newRoster,
            userId: currentUserId,
            demographics: selectedDemographics,
            deploymentName: deploymentToCopy.name,
            selectedOverlayTemplateId
        }).toPromise();
        return deploymentCopyId;
    }

    async deleteDeployment(deploymentId: string): Promise<void> {
        await this.afFun.httpsCallable('recursiveDelete')({
            deploymentId: deploymentId})
        .toPromise();
    }

    getUserDeployments(deploymentIds: string[]): Observable<Deployment[]> {
        return combineLatest(deploymentIds.map((deploymentId) => this.getDeployment(deploymentId)));
    }

    getDeployment(deploymentId: string): Observable<Deployment> {
        return this.firestoreService.getDocument<Deployment>(`/deployments/${deploymentId}`)
            .pipe(
                map((deployment) => new Deployment(deployment))
            );
    }

    getDeploymentTemplate(deploymentTemplateId: string): Observable<DeploymentTemplate> {
        return this.firestoreService.getDocument<DeploymentTemplate>(`/deployment_templates/${deploymentTemplateId}`)
            .pipe(
                map((deploymentTemplate) => new DeploymentTemplate(deploymentTemplate))
            );
    }

    getDeploymentModules(deploymentId: string): Observable<Module[]> {
        return this.firestoreService.getCollection<Module>(`/deployments/${deploymentId}/modules`)
            .pipe(
                map((modules) => modules.map((module) => new Module(module)))
            );
    }

    getDeploymentProcesses(deploymentId: string): Observable<Process[]> {
        return this.firestoreService.getCollection<Process>(`/deployments/${deploymentId}/processes`)
            .pipe(
                map((processes) => processes.map((process) => new Process(process)))
            );
    }

    getDeploymentSubprocesses(deploymentId: string): Observable<Subprocess[]> {
        return this.firestoreService.getCollection<Subprocess>(`/deployments/${deploymentId}/subprocesses`)
            .pipe(
                map((subprocesses) => subprocesses.map((subprocess) => new Subprocess(subprocess)))
            );
    }

    getDeploymentTemplates(): Observable<Deployment[]> {
        const collection$ = this.firestoreService.getCollection<Deployment>('/deployment_templates');
        return this.addModulesToDeploymentTemplates(collection$);
    }

    getDeploymentTemplatesWithModulesForUser(userId: string): Observable<DeploymentTemplate[]> {
        const collection$ = this.getDeploymentTemplatesForUser(userId);
        return this.addModulesToDeploymentTemplates(collection$);
    }

    getDeploymentTemplatesForUser(userId: string): Observable<DeploymentTemplate[]> {
        return this.firestoreService.getDocumentsByArrayContains<Deployment>('/deployment_templates', 'template_users', userId)
            .pipe(
                map((deploymentTemplates) => deploymentTemplates.map((deploymentTemplate) => new DeploymentTemplate(deploymentTemplate)))
            );
    }

    private addModulesToDeploymentTemplates(collection$: Observable<Deployment[]>): Observable<Deployment[]> {
        return collection$
            .pipe(
                switchMap((deploymentTemplates) => {
                    if (deploymentTemplates.length === 0) {
                        return of(deploymentTemplates);
                    }

                    const modules$ = deploymentTemplates.map((deploymentTemplate) => {
                        return this.getDeploymentTemplateModules(deploymentTemplate.id)
                            .pipe(
                                map((modules) => Object.assign(deploymentTemplate, { modules }))
                            );
                    });
                    return combineLatest(modules$);
                })
            );
    }

    getDeploymentTemplateModules(deploymentTemplateId: string): Observable<Module[]> {
        return this.firestoreService.getCollection<Module>(`/deployment_templates/${deploymentTemplateId}/modules`)
            .pipe(
                switchMap((modules) => {
                    const processes$ = modules.map((module) => {
                        return this.getDeploymentTemplateModuleProcesses(deploymentTemplateId, module.id)
                            .pipe(
                                map((processes) => Object.assign(module, { processes }))
                            );
                    });
                    return combineLatest(processes$);
                })
            );
    }

    getDeploymentTemplateModuleProcesses(deploymentTemplateId: string, moduleId: string): Observable<Process[]> {
        return this.firestoreService.getDocumentsByProperty<Process>(`/deployment_templates/${deploymentTemplateId}/processes`, 'module_id', moduleId);
    }

    getGlobalDemographics(): Observable<Demographic[]> {
        return this.firestoreService.getCollection<Demographic>('/global_demographics');
    }

    getSubprocessesByProcess(deploymentId: string, processId: string): Observable<Subprocess[]> {
        return this.firestoreService.getDocumentsByProperty<Subprocess>(`/deployments/${deploymentId}/subprocesses`, 'process_id', processId)
            .pipe(
                map((subprocesses) => subprocesses.map((subprocess) => new Subprocess(subprocess)))
            );
    }

    getModuleWithoutDemographics(deploymentId: string, moduleId: string): Observable<Module> {
        return this.firestoreService.getDocument<Module>(`/deployments/${deploymentId}/modules/${moduleId}`);
    }

    getModule(deploymentId: string, moduleId: string): Observable<Module> {
        return this.firestoreService.getDocument<Module>(`/deployments/${deploymentId}/modules/${moduleId}`)
            .pipe(
                switchMap((module) => {
                    if (!module) {
                        throw new Error(`Module ${moduleId} was not found`);
                    }
                    return this.firestoreService.getCollection<Demographic>(`/deployments/${deploymentId}/modules/${moduleId}/demographics`)
                        .pipe(
                            map((demographics) => ( { ...module, demographics } ))
                        )
                })
            );
    }

    getModuleProcesses(deploymentId: string, moduleId: string): Observable<Process[]> {
        return this.firestoreService.getDocumentsByProperty<Process>(`/deployments/${deploymentId}/processes`, 'module_id', moduleId)
            .pipe(
                map((processes) => processes.map((process) => new Process(process)))
            );
    }

    getModuleSubprocesses(deploymentId: string, moduleId: string): Observable<Subprocess[]> {
        return this.firestoreService.getDocumentsByProperty<Subprocess>(`/deployments/${deploymentId}/subprocesses`, 'module_id', moduleId)
            .pipe(
                map((subprocesses) => subprocesses.map((subprocess) => new Subprocess(subprocess)))
            );
    }

    getProcess(deploymentId: string, processId: string): Observable<Process> {
        return combineLatest([
            this.firestoreService.getDocument<Process>(`/deployments/${deploymentId}/processes/${processId}`),
            this.getSubprocessesByProcess(deploymentId, processId)
        ])
            .pipe(
                map(([ process, subprocesses]) => ({ ...process, subprocesses }))
            );
    }

    getSubprocess(deploymentId: string, subprocessId: string): Observable<Subprocess> {
        return this.firestoreService.getDocument<Subprocess>(`/deployments/${deploymentId}/subprocesses/${subprocessId}`);
    }

    getOwnership(deploymentId: string): Observable<Ownership[]> {
        return this.firestoreService.getCollection<Ownership>(`/deployments/${deploymentId}/ownership`);
    }

    getDemographics(deploymentId: string): Observable<Demographic[]> {
        return this.firestoreService.getCollection<Demographic>(`/deployments/${deploymentId}/demographics`);
    }

    getModuleDemographics(deploymentId: string, moduleId: string): Observable<Demographic[]> {
        return this.firestoreService.getCollection<Demographic>(`/deployments/${deploymentId}/modules/${moduleId}/demographics`);
    }

    getDeploymentDemographicById(deploymentId: string, demographicId: string): Observable<Demographic> {
        return this.firestoreService
            .getDocument<Demographic>(`/deployments/${deploymentId}/demographics/${demographicId}`);
    }

    addUserToDeployment(userId: string, deploymentId: string): Promise<void> {
        const deploymentRef = this.firestoreService.getDocumentRef<Deployment>(`/deployments/${deploymentId}`);
        return deploymentRef.update({
            roster: this.firestoreService.arrayUnion(userId),
            updated: this.firestoreService.timestamp
        });
    }

    getAssignedDemographicsForUserForObject(userId: string, deploymentId: string, objectId: string): Observable<Demographic[]> {
        const ownership$ = this.firestoreService.firestoreRef.collection<Ownership>(
            `/deployments/${deploymentId}/ownership`,
            ref => ref.where('user_id', '==', userId)
                    .where('object_id', '==', objectId)
                    .where('role', '==', UserRole.Stakeholder)
        ).valueChanges();

        return combineLatest([ ownership$, this.getDemographics(deploymentId) ])
            .pipe(
                map(([ userOwnership, deploymentDemographics ]) => {
                    const assignedDemographics = userOwnership.map((ownershipEntry) => {
                        return {
                            ...deploymentDemographics.find((d) => d.id === ownershipEntry.demographic_id),
                            id: ownershipEntry.demographic_id
                        }
                    });
                    return sortByAlphabetic(assignedDemographics, 'name');
                })
            );
    }

    getModuleByUserSubprocess(userId: string, deploymentId: string, subprocessId: string): Observable<Module> {
        return this.firestoreService.firestoreRef.collection<Ownership>(
            `/deployments/${deploymentId}/ownership`,
            ref => ref.where('user_id', '==', userId).where('object_id', '==', subprocessId)
        )
            .valueChanges()
            .pipe(
                switchMap((deploymentOwnership) => {
                    return !!deploymentOwnership ? this.getSubprocess(deploymentId, subprocessId) : of(null);
                }),
                mergeMap((subprocess) => {
                    const moduleId = !!subprocess && subprocess.module_id || null;
                    return !!moduleId ? this.getModule(deploymentId, moduleId) : of({} as Module)
                })
            );
     }

    getProcessIdBySubprocessId(userId: string, deploymentId: string, subprocessId: string): Observable<string> {
        return this.firestoreService.firestoreRef.collection<Ownership>(
            `/deployments/${deploymentId}/ownership`,
            ref => ref.where('user_id', '==', userId).where('object_id', '==', subprocessId)
        )
            .valueChanges()
            .pipe(
                switchMap((deploymentOwnership) => {
                    return !!deploymentOwnership ? this.getSubprocess(deploymentId, subprocessId) : of({} as Subprocess);
                }),
                map((subprocess) => subprocess.process_id)
            );
    }

    getPendingDeployments(userId: string): Observable<PendingDeployment[]> {
        return this.firestoreService.getDocumentsByProperty<PendingDeployment>(
            '/pending_deployments',
            'user_id',
            userId
        );
    }

    async updateModuleActiveInDeployment(flag: boolean, moduleId: string, deploymentId: string): Promise<void> {
        const dataToUpdateDeploymentWith = {
            updated: this.firestoreService.timestamp
        };
        const dataToUpdateModuleWith = {
            active: flag,
            updated: this.firestoreService.timestamp
        };
        const viewToUpdateModuleWith = {
            modules: {
                [moduleId]: {
                    active: flag
                }
            }
        };

        return this.firestoreService.writeBatch([
            { path: `/deployments/${deploymentId}/modules/${moduleId}`, data: dataToUpdateModuleWith},
            { path: `/deployments/${deploymentId}`, data: dataToUpdateDeploymentWith},
            { path: `/deployment_views/${deploymentId}`, data: viewToUpdateModuleWith}
        ]);
    }

    updateDeploymentDescription(deploymentId: string, newDescription: string): Promise<void> {
        return this.firestoreService.updateDoc(`/deployments/${deploymentId}`, { description: newDescription });
    }

    removeDemographicsById(deploymentId: string, demographicId: string, moduleId?: string): Promise<void> {
        const path = !!moduleId
            ? { path: `/deployments/${deploymentId}/modules/${moduleId}/demographics/${demographicId}` }
            : { path: `/deployments/${deploymentId}/demographics/${demographicId}` };

        return this.firestoreService.deleteBatch([
            path
        ]);
    }

    updateDemographics(deploymentId: string, demographic: Demographic, moduleId?: string): Promise<void> {
        const pathToUpdate = !!moduleId
            ? `/deployments/${deploymentId}/modules/${moduleId}/demographics/${demographic.id}`
            : `/deployments/${deploymentId}/demographics/${demographic.id}`;

        return this.firestoreService.writeBatch([
            { path: pathToUpdate, data: demographic }
        ]);
    }
}
