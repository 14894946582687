<img
  draggable="false"
  [src]="image"
  [ngStyle]="transformStyles"
  (mousemove)="dragImage($event)"
  (mouseup)="onDragEnd()"
  (dragstart)="$event.preventDefault()"
>

<button class="close" mat-icon-button (click)="close()">
  <mat-icon class="icon">close</mat-icon>
</button>

<div class="actions">
  <button mat-icon-button (click)="zoomOut()">
    <mat-icon class="icon">remove</mat-icon>
  </button>
  <button mat-icon-button [disabled]="isResetDisabled" (click)="resetZoom()">
    <mat-icon class="icon">search</mat-icon>
  </button>
  <button mat-icon-button (click)="zoomIn()">
    <mat-icon class="icon">add</mat-icon>
  </button>
</div>
