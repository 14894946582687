import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';

@Component({
    selector: 'app-sso-callback',
    templateUrl: './sso-callback.component.html',
    styleUrls: ['./sso-callback.component.scss'],
})
export class SsoCallbackComponent implements OnInit {
    constructor(private authenticationService: AuthenticationService) {}

    ngOnInit(): void {
        this.authenticationService.isLoggedIn().then(isLoggedIn => {
            if (!isLoggedIn) {
                this.authenticationService.completeAuthentication();
            }
        });
    }
}
