import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthenticationService } from '@app/core/services/authentication.service';
import { imagesResourceList } from '../background-images';

@Component({
    selector: 'app-user-login',
    templateUrl: './user-login.component.html',
    styleUrls: ['./user-login.component.scss'],
})
export class UserLoginComponent implements OnInit, OnDestroy {
    backgroundImages = imagesResourceList;
    authForm: FormGroup;
    email = new FormControl('', [Validators.required, Validators.email]);
    password = new FormControl('', Validators.required);
    isLoading: boolean;
    errorMessage: string;
    hide = true;

    private onDestroy: Subject<void> = new Subject();

    constructor(private authenticationService: AuthenticationService, private formBuilder: FormBuilder) {
        this.authForm = this.formBuilder.group({
            email: this.email,
            password: this.password,
        });
    }

    ngOnInit(): void {
        this.authenticationService
            .isLoading()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(value => {
                this.isLoading = value;
            });
        this.authenticationService
            .errorMessage()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(error => {
                this.errorMessage = error;
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    login() {
        this.authenticationService.login(this.email.value, this.password.value);
    }

    ssoLogin() {
        this.authenticationService.login();
    }
}
