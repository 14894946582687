import { Pipe, PipeTransform } from '@angular/core';
import { Module, objectTypeSet, Process, Subprocess } from '../../../models';

@Pipe({
    name: 'itemText'
})
export class ItemTextPipe implements PipeTransform {
    transform(item: Module | Process | Subprocess): any {
        return objectTypeSet[item.type].text;
    }
}
