import { AngularFireList, AngularFireObject } from '@angular/fire/compat/database';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { groupBy, isNull, isUndefined } from 'lodash';

import { OptionsChoice } from '@app/core/models/forms.model';
import { formStatusOrderMap } from '@app/core/models/form-status.model';
import { App } from './models';

export const sortByAlphabetic = (items: any[], field: string): any[] => {
    return items.sort((a, b) => (a[field] > b[field] ? 1 : b[field] > a[field] ? -1 : 0));
};

// Use only for numeric values
export const sortByField = <T>(array: T[], fieldName: string, order?: string): T[] => {
    return array.sort((a, b) => {
        const sign = order === 'asc' ? 1 : -1;
        let prev = a[fieldName];
        let next = b[fieldName];

        if (!prev && prev !== 0) {
            prev = 0;
        }

        if (!next && next !== 0) {
            next = 0;
        }

        return prev > next ? sign : next > prev ? -1 * sign : 0;
    });
};

// 'merge' sort - faster for a lot of data
export const mergeSortByAlphabetic = (items: any[], field: string): any[] => {
    const length = items.length;
    const merge = (left: any[], right: any[]) => {
        let result = [];

        while (left.length > 0 && right.length > 0) {
            const leftItem = left[0][field] ? left[0][field].trim().toLowerCase() : '';
            const rightItem = right[0][field] ? right[0][field].trim().toLowerCase() : '';

            // if we have empty value - add to the end
            result.push(
                leftItem === ''
                    ? right.shift()
                    : rightItem === ''
                    ? left.shift()
                    : leftItem > rightItem
                    ? right.shift()
                    : left.shift(),
            );
        }

        result = result.concat(left, right);

        return result;
    };

    if (length < 2) {
        return items;
    }

    const part = Math.ceil(length / 2);

    return merge(mergeSortByAlphabetic(items.slice(0, part), field), mergeSortByAlphabetic(items.slice(part), field));
};

export const sortBySequenceDesc = (items: any[]): any[] => {
    return items.sort((a, b) => -(a.payload.val().sequence - b.payload.val().sequence));
};

export const sortBySequenceAsc = (items: any[]): any[] => {
    return (items || []).sort((a, b) => {
        if (a.payload) {
            return a.payload.val().sequence - b.payload.val().sequence;
        } else {
            return a.sequence - b.sequence;
        }
    });
};

export const sortArrayByConstantSequence = (array: any[], constantObject: any) =>
    (array || []).sort((a, b) => constantObject[a].sequence - constantObject[b].sequence);

export const sortByStatuses = (items: any[]): any[] => {
    return (items || []).sort((a, b) => formStatusOrderMap[a.status] - formStatusOrderMap[b.status]);
};

// This is similar to the above function except this is in "point" form so it can be handed directly to the Array sort() function
// Usage: myArray.sort(sequenceAsc)
export const sequenceAsc = (a: any, b: any): number => {
    return a.payload ? a.payload.val().sequence - b.payload.val().sequence : a.sequence - b.sequence;
};

export const filterActive = (items: any[]): any[] => {
    return (items || []).filter(item => {
        if (item.payload) {
            return item.payload.val().active;
        } else {
            return item.active;
        }
    });
};

export const filterByMembership = (items: any[], userKey: string): any[] => {
    if (!userKey) {
        return [];
    }

    return items.filter(item => {
        if (item.payload) {
            return (item.payload.val().members || {})[userKey];
        } else {
            return (item.members || {})[userKey];
        }
    });
};

export const processDuration = (min: number | string, sec: number | string): string => {
    const minStr = +min < 10 ? `0${+min}` : min;
    const secStr = +sec < 10 ? `0${+sec}` : sec;

    return `${minStr}:${secStr}`;
};

export const calculateQuestionsTotalTime = (questions): string => {
    let totalMin = 0;
    let totalSec = 0;

    const process = duration => {
        if (duration) {
            const [min, sec] = duration.split(':');

            if (min && sec) {
                totalMin += +min;
                totalSec += +sec;
            }
        }
    };

    if (questions.map) {
        questions.map(question => process(question.duration));
    } else {
        Object.keys(questions).map(key => process(questions[key].duration));
    }

    totalMin += Math.floor(totalSec / 60);
    totalSec = totalSec % 60;

    return processDuration(totalMin, totalSec);
};

export const objectWithKey = <T>(obj: AngularFireObject<T>): Observable<T> => {
    return obj.snapshotChanges().pipe(
        map(objSnapshot => {
            return {
                ...(<any>objSnapshot.payload.val()),
                key: objSnapshot.key,
            };
        }),
    );
};

export const listWithKeys = <T>(list: AngularFireList<T>): Observable<T[]> => {
    return list.snapshotChanges().pipe(
        map(listSnapshot => {
            return listSnapshot.map(listItem => {
                return {
                    ...(<any>listItem.payload.val()),
                    key: listItem.key,
                };
            });
        }),
    );
};

export const checkIsIeOrEdgeBrowser = (): boolean => {
    return /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
};

export const checkIsMac = (): boolean => {
    // need for detection MAC OS - for styles fixes
    return navigator.platform.toUpperCase().indexOf('MAC') >= 0;
};

export const transformObjectIntoSortedArray = (items: { [key: string]: any }): any[] => {
    return sortBySequenceAsc(
        Object.keys(items || {}).map(key => ({
            ...items[key],
            key,
        })),
    );
};

export const cutStringWithThreeDots = (value: string, maxRowLength = 30, rowsCount = 4): string[] => {
    const maxResultLength = maxRowLength * (rowsCount + 1); // max length of whole title

    if (value.length < maxResultLength) {
        return [value];
    } else {
        const result = [];
        let tmp = '';

        // check title length and cut if more than maxTitleLength symbols
        const words = `${value.slice(0, maxResultLength - 3).trim()}...`.split(' ');

        words.forEach((word, j) => {
            // concat words until the length of the string is 30 and more
            if (tmp.length < maxRowLength) {
                if (words.length - 1 === j) {
                    result.push(tmp + word);
                } else {
                    tmp += `${word} `;
                }
            } else {
                result.push(tmp + word);
                tmp = '';
            }
        });

        return result;
    }
};

export const splitChartTitles = (titles: string[], maxRowLength = 30, rowsCount = 4): string[] => {
    // maxRowLength - max length of one title line
    const result = [];

    titles.forEach((title, i) => {
        result[i] = cutStringWithThreeDots(title, maxRowLength, rowsCount);
    });

    return result;
};

export const trackByKey = (_: number, item: any): string => {
    return item.key;
};

export const trackById = (_: number, item: any): string => {
    return item.id;
};

export const trackByIndex = (index: number): number => {
    return index;
};

export const trackByValue = (_: number, value: string): string => {
    return value;
};

export const excerptByCharacters = (maxStringLength: number, allText = '', wordsArray: string[] = []): string => {
    let currentStr = '';
    const words = allText ? allText.trim().split(' ') : wordsArray.join(', ').trim().split(' ');
    let excerpt = words
        .filter((word: string, i: number) => {
            if (wordsArray) {
                currentStr += i === 0 ? word : ', ' + word;
            } else {
                currentStr += ' ' + word;
            }

            return (i === 0 && currentStr.length > maxStringLength) || currentStr.length <= maxStringLength;
        })
        .join(' ')
        .trim();

    // delete chars from list: /,.-;:/ if it is the last char of excerpt
    const lastChar = excerpt.slice(-1);

    excerpt = new RegExp(/[.,-;:]{1}/).test(lastChar) ? excerpt.slice(0, -1).concat('...') : excerpt.concat('...');

    return excerpt;
};

export const generateTimeDifference = (dateCreated: number): string => {
    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
    };
    const timeDifferenceArray = new Date(dateCreated).toLocaleDateString('en-US', options).split(', ');
    let timeDifference = '';

    if (checkIsIeOrEdgeBrowser()) {
        const yearAndHMArray = timeDifferenceArray[1].split(' ');

        timeDifference = `${timeDifferenceArray[0]}, ${yearAndHMArray[0]} at ${yearAndHMArray[1]} ${yearAndHMArray[2]}`;
    } else {
        timeDifference = `${timeDifferenceArray[0]}, ${timeDifferenceArray[1]} at ${timeDifferenceArray[2]}`;
    }

    return timeDifference;
};

// Result in format "March 1, 2019"
export const getDateString = (dateCreated: number): string => {
    const options: Intl.DateTimeFormatOptions = { month: 'long', day: 'numeric', year: 'numeric' };

    return new Date(dateCreated).toLocaleDateString('en-US', options);
};

export const getCroppedText = (
    sourceText = '',
    maxRowLength: number,
    rowsCount: number,
    maxBlockHeight = 50,
    fakeElementClass = '',
): string => {
    // get initial cropped text with three dots
    let croppedText = cutStringWithThreeDots(sourceText, maxRowLength, rowsCount).join(' ').trim();

    // create fake element for measuring height
    const fakeElement = document.createElement('div');

    document.body.appendChild(fakeElement);

    // add class with styles to imitate source block dimensions
    fakeElement.classList.add(fakeElementClass);
    fakeElement.innerText = croppedText;

    // On each iteration check height and cut one symbol if element height doesn't match maxBlockHeight or less
    // we cut last 4 elements because of '...'
    while (parseInt(getComputedStyle(fakeElement).height, 10) > maxBlockHeight) {
        fakeElement.innerText = `${fakeElement.innerText.substring(0, fakeElement.innerText.length - 5)}...`;
    }

    croppedText = fakeElement.innerText;
    document.body.removeChild(fakeElement);

    return croppedText;
};

export const choicesToArray = (choices: { [choiceKey: string]: OptionsChoice }): OptionsChoice[] => {
    return Object.keys(choices || {})
        .reduce((accumulator, choiceKey) => {
            accumulator.push({
                ...choices[choiceKey],
                key: choiceKey,
            });

            return accumulator;
        }, [])
        .sort(sequenceAsc);
};

export const removeEmptyKeys = (data: any): any => {
    const result = {};

    for (const key in data) {
        if (!isNull(data[key]) && !isUndefined(data[key])) {
            result[key] = data[key];
        }
    }

    return result;
};

export const gettingNextData = (
    event: any,
    dataLength: number,
    linesLimit: number,
    partsToDisplay: number,
    padding = 0,
): number => {
    let displayingParts;

    displayingParts = partsToDisplay;

    if (dataLength < linesLimit * displayingParts) {
        return displayingParts;
    }

    const panelHeight = event.target.offsetHeight;
    const scrolledValue = event.target.scrollTop;
    const allCardsHeight = event.target.scrollHeight;

    if (panelHeight + scrolledValue + padding >= allCardsHeight) {
        ++displayingParts;
    }

    return displayingParts;
};

export const calculateMask = (...keys: string[]): string => {
    return keys.filter(key => !!key).join('_');
};

export const patternValidator = (regex: RegExp, error: ValidationErrors): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } => {
        if (!control.value) {
            // if control is empty return no error
            return null;
        }

        // test the value of the control against the regexp supplied
        const valid = regex.test(control.value);

        // if true, return no error (no error), else return error passed in the second parameter
        return valid ? null : error;
    };
};

export const isFirefox = () => navigator.userAgent.indexOf('Firefox') !== -1;

export const createUniqueName = (items: any[], name: string, field: string): string => {
    let index = 1;
    let newName = name;
    const compareNames = (item: any): boolean => {
        return (item[field] || '').trim().toLocaleLowerCase() === newName.trim().toLocaleLowerCase();
    };
    const findName = (): string => {
        while (items.find(item => compareNames(item))) {
            newName = `${name} - ${index++}`;
        }

        return newName;
    };

    return findName();
};

export const backwardCompatibilityForItemsNames = (items: any[], field: string): any[] => {
    const itemsNames = groupBy(items, item => item[field].trim().toLowerCase());
    const itemsToUpdate = [];

    Object.keys(itemsNames).forEach(itemName => {
        const sortedItems = sortBySequenceAsc(itemsNames[itemName]);
        const equalItemsNamesLength = sortedItems.length;

        if (equalItemsNamesLength > 1) {
            let index = 1;
            const compareNames = (item: any, oldName: string): boolean => {
                return (
                    (item[field] || '').trim().toLocaleLowerCase() ===
                    `${oldName} - ${index}`.trim().toLocaleLowerCase()
                );
            };
            const findName = (oldName: string): string => {
                while (items.find(item => compareNames(item, oldName))) {
                    index++;
                }

                return `${oldName} - ${index}`;
            };

            sortedItems.forEach((item, i) => {
                if (i === 0) {
                    return;
                }
                const newName = findName(item[field]);

                sortedItems[i][field] = newName;
                itemsToUpdate.push({
                    key: item.key,
                    [field]: newName,
                });
            });
        }
    });

    return itemsToUpdate;
};

export const preventKeyValueOrder = (): number => 0;

export const getFileExtension = (fileUrl: string): string => fileUrl.split('.').pop();

// it works for IE/Edge
const notSupportedScrollToNearest = (element: HTMLElement, scrollableBlock: HTMLElement) => {
    const elementData = element.getBoundingClientRect();
    const scrollableBlockData = scrollableBlock.getBoundingClientRect();

    // scroll to view selected activity with overlay in the top
    const topOverlay = scrollableBlockData.top - elementData.top;

    if (topOverlay > 0) {
        scrollableBlock.scrollTop = scrollableBlock.scrollTop - topOverlay;

        return;
    }

    // scroll to view selected activity with overlay in the bottom
    const bottomOverlay = elementData.top + elementData.height - (scrollableBlockData.top + scrollableBlockData.height);

    if (bottomOverlay > 0) {
        scrollableBlock.scrollTop = scrollableBlock.scrollTop + bottomOverlay;
    }
};

export const scrollActiveElementIntoView = (
    element?: HTMLElement,
    scrollableBlock?: HTMLElement,
    block?: ScrollLogicalPosition,
) => {
    const hasScrollOptionsSupport = element && 'scrollBehavior' in element.style;

    if (hasScrollOptionsSupport) {
        const blockView = block || 'nearest';

        element.scrollIntoView({ behavior: 'smooth', block: blockView, inline: 'nearest' });
    } else if (element && scrollableBlock) {
        notSupportedScrollToNearest(element, scrollableBlock);
    }
};

export const sortStringsByAlphabetic = (items: string[]): string[] => {
    return items.sort((a, b) => (a > b ? 1 : b > a ? -1 : 0));
};

export const filterByField = (field: string, filter = ''): boolean => {
    return field && field.toLocaleLowerCase().includes(filter.toLocaleLowerCase());
};

export const numbersOnlyValidator: ValidatorFn = (control: FormControl): null => {
    const controlValue = String(control.value).replace(/\D/g, '');

    if (controlValue !== String(control.value)) {
        control.setValue(+controlValue);
    }

    return null;
};

export const transformTagsInApps = (apps: App[]): App[] => {
    const appsArrayWithTags = [];

    apps.forEach(app => {
        if (app.tags) {
            const tagsArray = Object.keys(app.tags)
                .reduce((accumulator, tagKey) => {
                    accumulator.push({
                        name: app.tags[tagKey].name,
                        date_created: app.tags[tagKey].date_created,
                    });

                    return accumulator;
                }, [])
                .sort((a, b) => a.date_created - b.date_created);

            const tagNamesArray = tagsArray.reduce((accumulator, tag) => {
                accumulator.push(tag.name);

                return accumulator;
            }, []);

            const tagNames = tagNamesArray.join(', ');

            appsArrayWithTags.push({ ...app, tag_names: tagNames });
        } else {
            appsArrayWithTags.push({ ...app });
        }
    });

    return appsArrayWithTags;
};
