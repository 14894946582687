<div class="tags-panel">
  <div class="tags-panel-header" *ngIf="!isNavigationPanel; else navigationBlock">
    <div class="tags-panel-header-container">
      <h3 class="h3 text-dark-primary title">All Tags</h3>
    </div>
    <mat-icon (click)="closeTagsPanel.emit()" *ngIf="!isNavigationPanel">close</mat-icon>
  </div>

  <ng-template #navigationBlock>
    <div class="navigation-tags-panel-header">
      <mat-icon (click)="closeTagsPanel.emit()">arrow_back</mat-icon>
      <h5 class="h5 title">All Tags</h5>
    </div>
  </ng-template>

  <div class="sort" *ngIf="!isNavigationPanel">
    <div class="sort-header" (click)="collapsed = !collapsed">
      <span class="text sort-title"> Sort By </span>
      <button class="tt-button-icon activity-menu-button">
        <mat-icon class="drop-down-icon" [ngClass]="collapsed ? 'arrow-down' : 'arrow-up'">
          keyboard_arrow_down
        </mat-icon>
      </button>
    </div>
    <div *ngIf="!collapsed">
      <!-- Sort by -->
      <div class="thinktank-theme sort-container">
        <mat-radio-group class="sort-options" (change)="onChangeTypeSorting.emit($event.value)" [value]="typeSorting">
          <mat-radio-button class="sort-option" color="primary" value="frequency_of_use"
            >Frequency of Use</mat-radio-button
          >
          <mat-radio-button class="sort-option" color="primary" value="tag_name">Tag Name</mat-radio-button>
        </mat-radio-group>
      </div>
      <!-- /Sort by -->
    </div>
  </div>

  <div class="navigation-sort" *ngIf="isNavigationPanel">
    <!-- Sort by -->
    <mat-form-field appearance="outline" floatLabel="always">
      <mat-label> Sort By </mat-label>
      <mat-select
        [placeholder]="'Sort By'"
        [disableOptionCentering]="true"
        [panelClass]="['mat-select-panel-custom']"
        [value]="typeSorting"
        (selectionChange)="onChangeTypeSorting.emit($event.value)"
      >
        <mat-option value="frequency_of_use">Frequency of Use</mat-option>
        <mat-option value="tag_name">Tag Name</mat-option>
      </mat-select>
    </mat-form-field>
    <!-- /Sort by -->
  </div>

  <div class="all-tags">
    <p class="all-tags-title overline" *ngIf="!isNavigationPanel && collapsed">{{ sortingValues[typeSorting] }}</p>
    <mat-chip-list class="chip-list">
      <mat-chip
        *ngFor="let tag of allTags; trackBy: trackByKey"
        class="chip"
        [ngClass]="{ 'exist-chip': existTagNames.includes(tag.name), hide: tag.count === 0 }"
        (click)="checkExistTagInApp(tag)"
      >
        <span class="chip-text" [appSetTitle]="maxWidthTagText">{{ tag.name }}</span
        >&nbsp;({{ tag.count }})
      </mat-chip>
    </mat-chip-list>
  </div>
</div>
