import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';

import { validationMessages } from '@app/apps/apps.component.forms-constants';
import { ExportableFormat } from '@thinktank/common-lib';

@Component({
    selector: 'app-export-rename-dialog',
    templateUrl: './export-xml-json-dialog.component.html',
    styleUrls: ['./export-xml-json-dialog.component.scss']
})
export class ExportXmlJsonDialogComponent implements OnInit, OnDestroy {
    modalForm: FormGroup;
    isPendingStatus: boolean;
    isSpecifiedMappingTemplate: boolean;

    validationMessages = validationMessages;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ExportXmlJsonDialogComponent>
    ) {
        this.isSpecifiedMappingTemplate = this.data.isSpecifiedMappingTemplate;
        this.initForm();
    }

    export(): void {
        if (this.isPendingStatus) {
            return;
        }

        const value = this.modalForm.value;
        this.data.handleConfirm(value);
        this.dialogRef.close();
    }

    cancel(): void {
        this.dialogRef.close();
    }

    private initForm(): void {
        this.modalForm = new FormGroup({
            // TODO: change true on '!this.isSpecifiedMappingTemplate' after integration with Jitterbit
            [ExportableFormat.XML]: new FormControl({ value: false, disabled: true }),
            [ExportableFormat.JSON]: new FormControl(false)
        }, [this.exportToValidationFn()]);
    }

    ngOnInit() {}

    ngOnDestroy() {}

    private exportToValidationFn(): ValidatorFn {
        return (modalFormControl: AbstractControl): { [key: string]: any } | null => {
            const exportToXmlControl = modalFormControl.get(ExportableFormat.XML);
            const exportToJsonControl = modalFormControl.get(ExportableFormat.JSON);
            const exportFileNotChosen = !exportToXmlControl.value && !exportToJsonControl.value;

            return exportFileNotChosen ? { 'exportFileNotChosen': true } : null;
        };
    }
}
