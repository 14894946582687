import { createSelector } from '@ngrx/store';
import { FeatureToggles } from '../../../core/models/feature-toggles.models';
import { AppState } from '../../state';

export const getFeatureTogglesState = (state: AppState) => state.featureToggles;

export const getFeatureTogglesLoading = createSelector(getFeatureTogglesState, (state) => state.loading);

export const getFeatureTogglesError = createSelector(getFeatureTogglesState, (state) => state.errorMessage);

export const getAllFeatureToggles = createSelector(getFeatureTogglesState, (state) => state.featureToggles);

export const getFeatureToggle = createSelector(
    getAllFeatureToggles,
    (featureToggles: FeatureToggles, props: { key: string }) => featureToggles[props.key]
);
