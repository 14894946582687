import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';

import { FocusSnackBarComponent } from '@app/shared/snack-bars/focus-snack-bar/focus-snack-bar.component';

@Component({
  selector: 'app-activity-copied-snack-bar',
  templateUrl: './activity-copied-snack-bar.component.html',
  styleUrls: ['./activity-copied-snack-bar.component.scss']
})

export class ActivityCopiedSnackBarComponent {
    private _onOpen = new Subject<void>();

    constructor(
        public snackBarRef: MatSnackBarRef<FocusSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    onOpen(): Observable<void> {
        return this._onOpen.asObservable();
    }

    open(): void {
        this._onOpen.next();
    }

    close(event: any): void {
        event.stopPropagation();
        this.snackBarRef.dismiss();
    }
}
