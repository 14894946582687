import { Component, Input } from '@angular/core';
import {
    animate,
    state,
    style,
    transition,
    trigger
} from '@angular/animations';

type ViewType = 'deployments'
    | 'deployment_description'
    | 'client_name'
    | 'modules'
    | 'overlay_template'
    | 'demographics'
    | 'deployment_view'
    | 'participant_invite'
    | 'congrats_screen';

@Component({
    selector: 'gs-create-deployment',
    templateUrl: './create-deployment.component.html',
    styleUrls: ['./create-deployment.component.scss'],
    animations: [
    trigger('slide', [
      state('deployments', style({ transform: 'translateX(0)' })),
      state('deployment_description', style({ transform: 'translateX(-11.11%)' })),
      state('client_name', style({ transform: 'translateX(-22.22%)' })),
      state('modules', style({ transform: 'translateX(-33.33%)' })),
      state('overlay_template', style({ transform: 'translateX(-44.44%)' })),
      state('demographics', style({ transform: 'translateX(-55.55%)' })),
      state('deployment_view', style({ transform: 'translateX(-66.66%)' })),
      state('participant_invite', style({ transform: 'translateX(-77.77%)' })),
      state('congrats_screen', style({ transform: 'translateX(-88.88%)' })),
      transition('* => *', animate(0))
  ])]
})
export class CreateDeploymentComponent {

    @Input() activeView: ViewType = 'deployments';

    constructor() { }

}
