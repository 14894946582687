import { Component, OnChanges, ChangeDetectionStrategy, Input, SimpleChanges } from '@angular/core';

import { User } from '@app/core/models';
import { trackByKey } from '@app/core/utils';
import { DialogService } from '@app/core/services/dialog.service';

type UserWithDemographics = User & { demographicKeys: string[] };

@Component({
    selector: 'app-members',
    templateUrl: './members.component.html',
    styleUrls: ['./members.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MembersComponent implements OnChanges {
    @Input() usersList: UserWithDemographics[];
    @Input() modalTitle: string;
    @Input() demographicsNames?: { [demographicKey: string]: string };

    slicedUsersList: UserWithDemographics[] = [];
    usersNames: string[] = [];
    userNamesLength = 0;
    trackByKey = trackByKey;

    constructor(
        private dialogService: DialogService
    ) {}

    getUserName(user: User, shortName?: boolean): string {
        return User.getUserName(user, shortName);
    }

    getTooltipText(user: UserWithDemographics): string {
        const userShortName = this.getUserName(user);
        if (user.demographicKeys && user.demographicKeys.length && this.demographicsNames) {
            const demographics = user.demographicKeys.map(demoKey => this.demographicsNames[demoKey]).join(', ');
            return `${userShortName}\n${demographics}`;
        }
        return userShortName;
    }

    openContributorsListModal(): void {
        this.dialogService.open('ContributorsListDialogComponent', {
            usersList: this.usersNames,
            title: this.modalTitle
        });
    }

    ngOnChanges(changes: SimpleChanges) {

        if (changes.usersList) {
            const usersList = this.usersList || [];
            this.slicedUsersList = usersList.slice(0, 4);
            this.userNamesLength = usersList.slice(4).length;
            this.usersNames = usersList.map((user) => this.getUserName(user));
        }

        if (changes.userList || changes.demographicsNames) {
            this.slicedUsersList = this.slicedUsersList.map(user => ({
                ...user,
                tooltipText: this.getTooltipText(user)
            }));
        }
    }

}
