import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';

import * as FeatureTogglesActions from './feature-toggles.actions';
import { FeatureToggleService } from '@app/core/services/feature-toggle.service';
import { catchError, switchMap, map, take } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable()
export class FeatureTogglesEffects {
    loadFeatureToggles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FeatureTogglesActions.loadFeatureToggles),
            switchMap(() => {
                return this.featureTogglesService.getFeatureToggles()
                    .pipe(
                        map((featureToggles) =>
                            FeatureTogglesActions.loadFeatureTogglesSuccess({ featureToggles })),
                        catchError((error) => of(FeatureTogglesActions.loadFeatureTogglesFailure({ errorMessage: error }))),
                        take(1)
                    );
            })
        )
    );

    constructor(
        private actions$: Actions,
        private featureTogglesService: FeatureToggleService
    ) {}
}
