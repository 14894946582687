export const firebaseIdPattern = /\-([a-zA-Z\d\_\-]){19}/g;

const MAX_EXECUTION_TIME = 540000; // Timeout value in milliseconds. This value matches timeout in cloud functions.
export const httpsCallableOptions = {
    timeout: MAX_EXECUTION_TIME
};

export const defaultAuthUserError = 'auth/user-not-found';
export const defaultAuthPasswordError = 'auth/wrong-password';
export const authUserOrPasswordError = 'The username or password you entered is invalid';
