import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EfficientListViewService {
    private scrollReachedEndSubject = new Subject<void>();

    constructor() { }

    get scrollReachedEnd$() {
        return this.scrollReachedEndSubject.asObservable();
    }

    scrollReachedEnd(): void {
        this.scrollReachedEndSubject.next();
    }
}
