import { AlignmentService } from '@app/apps/activities/forms/form-services/alignment.service';
import { FormStatus } from './form-status.model';

export enum AlignmentStateEnum {
    Draft = 'DRAFT',
    Locked = 'LOCKED',
    DraftReview = 'DRAFT_REVIEW',
    Aligned = 'ALIGNED',
    PendingApproval = 'PENDING_APPROVAL',
    Approved = 'APPROVED'
}

export type AlignmentState = AlignmentStateEnum & FormStatus;

export class AlignmentStateObj {
    state: AlignmentState;
    owner_id?: string;
}

export type AlignmentStateMap = AlignmentStateObj | { [demographicKey: string]: AlignmentStateObj };

export interface AlignmentStateManageable {
    transition(context: AlignmentService, activityKey: string, formKey: string, demographicKeys: string[]);
}
