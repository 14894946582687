import { Variable } from './variable.model';
import { GraphService } from '../services/graph.service';

export interface PropagationData {
    node_id: string;
    input_type: string;
}

export interface Edge {
    node: GraphNode;
    inputType: string;
}

export class GraphNode {
    deploymentId: string;
    moduleId?: string;
    processId?: string;
    subprocessId?: string;
    nodeId: string;
    type: string;
    name: string;
    global: boolean;
    propagateTo: PropagationData[];

    constructor(deploymentId: string, nodeId: string, data: object, protected graphService: GraphService) {
        this.deploymentId = deploymentId;
        this.nodeId = nodeId;
        this.loadData(data);
    }

    loadData(data: object) {
        this.type = data['type'];
        this.name = data['name'];
        this.propagateTo = data['propagate_to'] || [];
        this.global = data['global'] || false;
        this.moduleId = data['module_id'] || undefined;
        this.processId = data['process_id'] || undefined;
        this.subprocessId = data['subprocess_id'] || undefined;
    }

    async propagateValues(demographicId: string): Promise<void> {}

    protected async propagateVariable(variable: Variable): Promise<void> {
        const edges = await this.getEdges();
        for (const edge of edges) {
            await edge.node.setInput(edge.inputType, variable);
        }
    }

    async setInput(name: string, variableData:Variable): Promise<void> {}

    protected buildVariable(demographicId: string, value: any): Variable {
        return {
            sourceNode: this,
            demographicId,
            value
        };
    }

    private async getEdges(): Promise<Edge[]> {
        return Promise.all(this.propagateTo.map((propagationData) => this.getEdge(propagationData)));
    }

    private async getEdge(edgeData: PropagationData): Promise<Edge> {
        return {
            node: await this.graphService.loadGraphNode(this.deploymentId, edgeData.node_id),
            inputType: edgeData.input_type
        };
    }

    protected consoleLogVariable(variable: Variable): string {
        return JSON.stringify({
            demographicId: variable.demographicId,
            value: variable.value,
            type: variable.type,
            sectionId: variable.sectionId
        });
    }

}
