import { FormBuilder } from '@angular/forms';
import { FormQuestion, SelectResponse } from '@thinktank/common-lib';
import { QuestionFormControlHandler } from './question-form-control-handler';

export class MultiSelectQuestionFormControlHandler extends QuestionFormControlHandler {

    constructor(formBuilder: FormBuilder) {
        super(formBuilder);
    }

    protected getResponse(question: FormQuestion, demographicId: string): string | SelectResponse[] {
        const response = super.getResponse(question, demographicId);
        if (response === '' || (response as string[]).length === 0) {
            return '';
        }
        return (response as string[] || [])
            .map((value) => {
                return {
                    value
                };
            });
    }
}
