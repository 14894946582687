<div [formGroup]="parentForm">
  <mat-form-field [ngClass]="{'without-label': isSectionQuestion}">
    <mat-label *ngIf="!isSectionQuestion">{{ demographic.name }}</mat-label>
    <ng-container *ngIf="isShortText">
      <input
        matInput
        type="text"
        placeholder="Your option"
        [formControlName]="controlName"
        [id]="controlId"
        [required]="!!question.required && question.required[demographic.id]"
      />
    </ng-container>
    <ng-container *ngIf="isLongText">
      <textarea
        matInput
        type="text"
        placeholder="Your option"
        [formControlName]="controlName"
        [id]="controlId"
        [required]="!!question.required && question.required[demographic.id]"
      ></textarea>
    </ng-container>
  </mat-form-field>
</div>
