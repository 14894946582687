/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormControl, ValidationErrors } from '@angular/forms';

// Regular expressions for detect and highlight video ID from url
const youtubePattern = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
const vimeoPattern = /^.*(vimeo\.com\/|vimeopro\.com\/)(([\w-]+\/[\w-]+\/video\/)|(channels\/[\w-]+\/)|(ondemand\/[\w-]+\/)|(groups\/[\w-]+\/videos\/)|(album\/[\w-]+\/video\/))?([0-9]+)/;
const videoUrlPattern = /^https:\/\/(www.youtube.com|youtu.be|vimeo.com|vimeopro.com|player.vimeo.com){1}([\w.,@?^=%&:\/~+#-]*[\w@?^=%&\/~+#-])?$/;

@Injectable()
export class VideoService {

    constructor(private sanitizer: DomSanitizer) {}

    transformVideoUrlToEmbed(url: string): SafeResourceUrl {
        let transformedUrl: string;

        if (!url) {
            return this.sanitizer.bypassSecurityTrustResourceUrl('');
        }

        const urlType = url.includes('youtu') ? 'youtube' : 'vimeo';

        switch (urlType) {
            case 'youtube':
                const match = url.match(youtubePattern);

                transformedUrl = match && match[2] ? `https://www.youtube.com/embed/${match[2]}` : url;
                break;
            case 'vimeo':
                const matchUrl = url.match(vimeoPattern);

                transformedUrl = matchUrl && matchUrl[8] ? `https://player.vimeo.com/video/${matchUrl[8]}` : url;
                break;
            default:
                transformedUrl = url;
        }

        return this.sanitizer.bypassSecurityTrustResourceUrl(transformedUrl);
    }

    validateVideoUrl(control: FormControl): ValidationErrors | null {
        return videoUrlPattern.test(control.value) ? null : { 'invalid-url': true };
    }
}
