import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { FirebaseActivityService } from '@app/core/services';
import { Gate, Task } from '@thinktank/common-lib';

@Injectable({
    providedIn: 'root'
})
export class TasksListService {
    private resetEmulationSubject = new Subject<any>();

    constructor(
        private activityService: FirebaseActivityService
    ) { }

    resetEmulation() {
        this.resetEmulationSubject.next({});
    }

    getResetEmulation(): Observable<any> {
        return this.resetEmulationSubject.asObservable();
    }

    updateBookmarkMap(list: (Task | Gate)[], bookmarkMap): { [activityId: string]: Observable<boolean> } {
        const newBookmarkMap = { ...bookmarkMap };
        list.forEach(item => {
            if ('activity_id' in item) {
                newBookmarkMap[item.activity_id] = this.getHasBookmarkActivityField(item.activity_id);
            }
        });

        return newBookmarkMap;
    }

    getHasBookmarkActivityField(activityId: string): Observable<boolean> {
        return this.activityService.getActivityFieldValue(activityId, 'has_bookmark')
            .pipe(take(1));
    }
}
