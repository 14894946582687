import { ObjectType } from './deployment.model';
import { Ownership } from './ownership.model';
import { User } from './user.model';
import { Demographic } from '@thinktank/common-lib';

export interface AssignmentViewModel {
    deploymentId: string;
    moduleId: string;
    processId: string;
    objectId: string;
    objectType: ObjectType;
    ownership: Ownership[];
    global: boolean;
    processHaveSomeGlobalSubprocesses: boolean;
    deploymentUserList: User[];
    usersOfObject: Ownership[];
    demographics: Demographic[];
    isLoading: boolean;
}

export const initialAssignmentViewModelState = {
    deploymentId: '',
    moduleId: '',
    processId: '',
    objectId: '',
    objectType: ObjectType.Module,
    ownership: [],
    global: false,
    processHaveSomeGlobalSubprocesses: false,
    deploymentUserList: [],
    usersOfObject: [],
    demographics: [],
    isLoading: true
};

