import { ChangeDetectionStrategy, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BaseQuestionComponent } from '../base-question/base-question.component';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-numeric-question',
    templateUrl: './numeric-question.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy, OnChanges {

    constructor(@Inject(DeploymentFormFacadeToken) formFacade: DeploymentFormFacade) {
        super(formFacade);
    }

    questionHasMinOrMaxError(): boolean {
        const control = this.parentForm.get(this.controlName);

        return this.question.range_enabled &&
               control.value <= this.question.numeric_range.max &&
               control.value >= this.question.numeric_range.min;
    }

    ngOnInit(): void {
        this.onValueChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`NumericQuestionComponent::ngOnChanges - changes for ${this.question.id}`, changes);
    }

    ngOnDestroy(): void {
        this.onDestroy();
    }

}
