import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './reducers';
import { DemographicsFeatureService } from './services/demographics.service';
import { DemographicsEffects } from './effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('demographics', reducer),
        EffectsModule.forFeature([DemographicsEffects])
    ],
    exports: [],
    providers: [DemographicsFeatureService],
})
export class DemographicsStateModule { }
