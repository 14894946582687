import { createReducer, on, Action } from '@ngrx/store';

import * as FormValidationErrorsActions from '../actions';
import { initialState, FormValidationErrorsState } from '../state';

const demographicReducer = createReducer(
    initialState,
    on(FormValidationErrorsActions.getFormValidationErrors, (state) => {
        return ({
            ...state,
            isErrorsVisible: false,
            errorMessage: null,
            loading: true
        });
    }),
    on(FormValidationErrorsActions.getFormValidationErrorsSuccess, (state, { errors }) => {
        return ({
            ...state,
            isErrorsVisible: true,
            errors,
            loading: false
        });
    }),
    on(FormValidationErrorsActions.getFormValidationErrorsFail, (state, { errorMessage }) => {
        return ({
            ...state,
            loading: false,
            errorMessage
        });
    }),
    on(FormValidationErrorsActions.clearValidationStore, (state) => {
        return ({
            ...state,
            isErrorsVisible: false,
            errors: [],
            errorMessage: null,
            loading: false
        });
    }),
    on(FormValidationErrorsActions.validateFormCall, (state, { activityId }) => {
        return ({
            ...state,
            activityId,
            errorMessage: null,
            loading: true
        });
    }),
    on(FormValidationErrorsActions.validateFormCallSuccess, (state) => {
        return ({
            ...state,
            loading: false
        });
    }),
    on(FormValidationErrorsActions.validateFormCallFail, (state, { errorMessage }) => {
        return ({
            ...state,
            loading: false,
            errorMessage
        });
    })
);

export function reducer(state: FormValidationErrorsState | undefined, action: Action) {
    return demographicReducer(state, action);
}
