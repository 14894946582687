import { SearchForResponsesData } from '@app/core/models';
import { createAction, props } from '@ngrx/store';
import { FoundResponse } from '@app/core/models/response.model';

const searchFormResponsesType = '[Forms] search form responses';
const searchFormResponsesSuccessType = '[Forms] search form responses success';
const searchFormResponsesFailType = '[Forms] search form responses fail';
const clearSearchFormResponsesType = '[Forms] clear search form responses';
const increaseСurrentFoundResponseType = '[Forms] increase current found answer';
const decreaseСurrentFoundResponseType = '[Forms] decrease current found answer';
const setCurrentFoundResponseType = '[Forms] set current found response';
const setNavigationActiveType = '[Forms] set navigation active';
const setNavigationDisactiveType = '[Forms] set navigation disactive';

export const searchFormResponses = createAction(
    searchFormResponsesType,
    props<{ searchData: SearchForResponsesData; }>()
);

export const searchFormResponsesSuccess = createAction(
    searchFormResponsesSuccessType,
    props<{ foundResponses: FoundResponse[]; }>()
);

export const searchFormResponsesFail = createAction(searchFormResponsesFailType, props<{ errorMessage: string }>());

export const clearSearchFormResponses = createAction(clearSearchFormResponsesType);

export const increaseСurrentFoundResponse = createAction(increaseСurrentFoundResponseType);

export const decreaseСurrentFoundResponse = createAction(decreaseСurrentFoundResponseType);

export const setCurrentFoundResponse = createAction(
    setCurrentFoundResponseType,
    props<{ currentResponseNumber: number }>()
);

export const setNavigationActive = createAction(setNavigationActiveType);

export const setNavigationDisactive = createAction(setNavigationDisactiveType);
