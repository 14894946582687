import { from as observableFrom, Observable, switchMap } from 'rxjs';
import { take, mergeMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

@Injectable()
export class FirebaseAuthenticationService {
    constructor(
        private angularFireAuth: AngularFireAuth,
        private angularFireDatabase: AngularFireDatabase,
        private angularFireFunctions: AngularFireFunctions,
    ) {}

    authenticate(firebaseToken: string): Observable<firebase.auth.UserCredential> {
        return observableFrom(firebase.auth().signInWithCustomToken(firebaseToken));
    }

    signOut(): Observable<void> {
        return this.angularFireAuth.idToken.pipe(
            switchMap(token => {
                return this.angularFireFunctions
                    .httpsCallable('revokeUserTokens')({ token })
                    .pipe(
                        tap(() => {
                            // To prevent errors associated with unclosed subscriptions in the database
                            this.angularFireDatabase.database.goOffline();
                        }),
                    );
            }),
        );
    }

    signOutForDeactivated(): Observable<void> {
        return this.angularFireAuth.idToken.pipe(
            mergeMap(() => {
                // To prevent errors associated with unclosed subscriptions in the database
                this.angularFireDatabase.database.goOffline();

                return observableFrom(firebase.auth().signOut());
            }),
            take(1),
        );
    }
}
