<ng-container *ngIf="questionVisible">
  <ng-container [ngSwitch]="question.type">
    <ng-container *ngSwitchCase="FormItemType.ShortText">
      <ng-container *ngTemplateOutlet="textQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.LongText">
      <ng-container *ngTemplateOutlet="textQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.SingleSelect">
      <ng-container *ngTemplateOutlet="selectQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.MultiSelect">
      <ng-container *ngTemplateOutlet="selectQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.Date">
      <ng-container *ngTemplateOutlet="dateQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.File">
      <ng-container *ngTemplateOutlet="fileQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.Image">
      <ng-container *ngTemplateOutlet="mediaQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.Attachment">
      <ng-container *ngTemplateOutlet="attachmentQuestion"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="FormItemType.Numeric">
      <ng-container *ngTemplateOutlet="numericQuestion"></ng-container>
    </ng-container>
    <ng-template #numericQuestion>
      <gs-numeric-question
        [question]="question"
        [demographic]="demographic"
        [parentForm]="parentForm"
        [deploymentId]="deploymentId"
        [authenticatedUserId]="authenticateUserId">
      </gs-numeric-question>
    </ng-template>
    <ng-template #attachmentQuestion>
      <gs-attachment-question
        [question]="question"
        [demographic]="demographic"
        [parentForm]="parentForm"
        [deploymentId]="deploymentId"
        [authenticatedUserId]="authenticateUserId">
      </gs-attachment-question>
    </ng-template>
    <ng-template #mediaQuestion>
      <gs-media-question
        [question]="question"
        [demographic]="demographic"
        [parentForm]="parentForm"
        [deploymentId]="deploymentId"
        [authenticatedUserId]="authenticateUserId">
      </gs-media-question>
    </ng-template>
    <ng-template #fileQuestion>
      <gs-file-question
        [question]="question"
        [demographic]="demographic"
        [attachedFiles]="attachedFiles"
        [parentForm]="parentForm"
        [deploymentId]="deploymentId"
        [authenticatedUserId]="authenticateUserId">
      </gs-file-question>
    </ng-template>
    <ng-template #dateQuestion>
      <gs-date-question
        [question]="question"
        [demographic]="demographic"
        [parentForm]="parentForm"
        [deploymentId]="deploymentId"
        [authenticatedUserId]="authenticateUserId">
      </gs-date-question>
    </ng-template>
    <ng-template #textQuestion>
      <gs-text-question
        [question]="question"
        [demographic]="demographic"
        [parentForm]="parentForm"
        [deploymentId]="deploymentId"
        [authenticatedUserId]="authenticateUserId">
      </gs-text-question>
    </ng-template>
    <ng-template #selectQuestion>
      <gs-select-question
        [question]="question"
        [demographic]="demographic"
        [parentForm]="parentForm"
        [deploymentId]="deploymentId"
        [authenticatedUserId]="authenticateUserId">
      </gs-select-question>
    </ng-template>
  </ng-container>
</ng-container>
