export const environment = {
    name: 'dev-hq',
    production: false,
    firebase: {
        cloudFunctionsURL: 'https://us-central1-thinktank-hi-q-dev.cloudfunctions.net',
        apiKey: 'AIzaSyCHtIqgjG5-37My6RhgGzNbL-m0TArA2Pc',
        authDomain: 'dev.thinktank.net',
        databaseURL: 'https://thinktank-hi-q-dev.firebaseio.com',
        projectId: 'thinktank-hi-q-dev',
        storageBucket: 'thinktank-hi-q-dev.appspot.com',
        messagingSenderId: '725636178278',
        appId: '1:725636178278:web:4986db7fdae554ac9228a7',
        measurementId: 'G-X41TBEBVS9',
    },
    pendo: {
        APIKey: '6bbe6103-b1d5-417a-5ea5-7de06a0e120c',
    },
    sso: {
        clientId: 'dc0f942b-1b56-42f2-a409-f0494b4604c1',
        authority: 'https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb',
        redirectUri: 'https://dev.thinktank.net/callback',
    },
};
