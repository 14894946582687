import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './reducers';
import { FromValidationEffects } from './effects';

@NgModule({
    imports: [
        StoreModule.forFeature('formSummary', reducer),
        EffectsModule.forFeature([FromValidationEffects])
    ],
})
export class FormSummaryStateModule { }
