import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';

import { FormQuestionValidationError } from '@app/core/models';
import { FirebaseUtilityService } from '@app/core/services';
import * as FormValidationErrorsActions from '../actions';
import { AppState } from '@app/root-store/state';
import * as FormValidationErrorsSelectors from '../selectors';
import { RoutingService } from '@app/apps/core/services/routing.service';

@Injectable()
export class FormValidationService extends FirebaseUtilityService {

    private validationLoaderStatus$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private _functions: AngularFireFunctions,
        private _database: AngularFireDatabase,
        private _store$: Store<AppState>,
        private _routingService: RoutingService
    ) {
        super();
    }

    getValidationLoaderStatus(): Observable<boolean> {
        return this.validationLoaderStatus$.asObservable();
    }

    setValidationLoaderStatus(isShow: boolean): void {
        this.validationLoaderStatus$.next(isShow);
    }

    async isFormValid(
        activityId: string,
        useDemographics: boolean,
        currentUserId: string,
    ): Promise<boolean> {
        await this.validateForm(activityId);

        // TODO: consider second submit action

        const props = { activityId };
        const action = FormValidationErrorsActions.getFormValidationErrors(props);
        this._store$.dispatch(action);

        const focusedDemographicKey = this._routingService.focusedDemographicKey;
        const focusedSectionDemographicKey = this._routingService.focusedSectionDemographicKey;

        const filterErrorsProps = {
            activityId,
            currentUserId,
            useDemographics,
            focusedDemographicKey,
            focusedSectionDemographicKey
        };

        const errors = await combineLatest([
            this._store$.pipe(select(FormValidationErrorsSelectors.filteredErrorsForCurrentUser, filterErrorsProps)),
            this._store$.pipe(select(FormValidationErrorsSelectors.isLoadingSelector))
        ])
            .pipe(
                skipWhile(([, isLoading]) => isLoading),
                map(([errorsData, ]) => errorsData),
                take(1)
            ).toPromise();

        return !(errors || []).length;
    }

    async validateForm(activityId: string): Promise<void> {
        const data = { activityId };
        await this._functions.httpsCallable('validateForm')(data).toPromise();
    }

    getValidationErrors(activityId: string): Observable<FormQuestionValidationError[]> {
        const list = this._database.list<FormQuestionValidationError>(`ssot/_form_validation/${activityId}`);
        return this.listWithKeys(list);
    }

    async clearValidationError(activityId: string, validationId: string): Promise<void> {
        const update = { [`ssot/_form_validation/${activityId}/${validationId}`]: null };
        return await this._database.object('/').update(update);
    }
}
