import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as workbooksActions from '../actions';
import { WorkbookService } from '@app/core/services/workbook.service';

@UntilDestroy()
@Injectable()
export class WorkbooksEffects implements OnDestroy {

    public getWorkbookSheets$ = createEffect(() =>
        this._actions$.pipe(
            ofType(workbooksActions.getWorkbookSheetsByActivity),
            filter((action) => !this._getWorkbookSheetsMap[action.activityId]),
            mergeMap((action) => {
                this._getWorkbookSheetsMap[action.activityId] = true;

                return this._workbookService
                    .getWorkbookSheetsByActivityKey(action.activityId)
                    .pipe(
                        map(workbookSheets => workbooksActions.getWorkbookSheetsByActivitySuccess({
                            activityId: action.activityId,
                            workbookSheets
                        })),
                        catchError((error) => of(workbooksActions.getWorkbookSheetsByActivityFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    public getFormSheetKey$ = createEffect(() =>
        this._actions$.pipe(
            ofType(workbooksActions.getFormSheetKey),
            filter((action) => !this._getFormSheetKeyMap[action.formId]),
            mergeMap((action) => {
                this._getFormSheetKeyMap[action.formId] = true;

                return this._workbookService
                    .getFormSheetKey(action.formId)
                    .pipe(
                        map(formSheetKey => workbooksActions.getFormSheetKeySuccess({
                            formId: action.formId,
                            formSheetKey
                        })),
                        catchError((error) => of(workbooksActions.getFormSheetKeyFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    public getQuestionSheetKey$ = createEffect(() =>
        this._actions$.pipe(
            ofType(workbooksActions.getQuestionSheetKey),
            filter((action) => !this._getQuestionSheetKeyMap[action.questionId]),
            mergeMap((action) => {
                this._getQuestionSheetKeyMap[action.questionId] = true;

                return this._workbookService
                    .getQuestionSheetKey(action.questionId)
                    .pipe(
                        map(questionSheetKey => workbooksActions.getQuestionSheetKeySuccess({
                            questionId: action.questionId,
                            questionSheetKey
                        })),
                        catchError((error) => of(workbooksActions.getQuestionSheetKeyFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    public getFormQuestionsWithWorkbookSheet$ = createEffect(() =>
        this._actions$.pipe(
            ofType(workbooksActions.getFormSheetKey),
            filter((action) => !this._getFormQuestionsWithWorkbookSheetMap[action.formId]),
            mergeMap((action) => {
                this._getFormQuestionsWithWorkbookSheetMap[action.formId] = true;

                return this._workbookService
                    .getFormQuestionsWithWorkbookSheet(action.formId)
                    .pipe(
                        map(questions => workbooksActions.getFormQuestionsWithWorkbookSheetSuccess({
                            questionId: action.formId,
                            questions
                        })),
                        catchError((error) => of(workbooksActions.getFormQuestionsWithWorkbookSheetFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));
        

    // This objects are used to store data that already created a subscription.
    // After check parameters if they already added we will not create new subscription.
    private _getWorkbookSheetsMap = {};
    private _getFormSheetKeyMap = {};
    private _getQuestionSheetKeyMap = {};
    private _getFormQuestionsWithWorkbookSheetMap = {};

    constructor(
        private _actions$: Actions,
        private _workbookService: WorkbookService
    ) { }

    ngOnDestroy(): void { }
}
