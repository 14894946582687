import { createSelector } from '@ngrx/store';

import { AppState } from '@app/root-store/state';
import { FormsState } from '../state';
import { FormStatusesMap } from '@app/root-store/models/forms-feature.models';
import { groupBy } from 'lodash';

export const formsState = (state: AppState) => state.forms;

export const formsKeysByActivityKeySelector = createSelector(
    formsState,
    (state: FormsState, props: { activityId: string }) => {
        return state.formsKeysByActivityKey[props.activityId] || [];
    }
);

export const parentFormKeyByActivityKeySelector = createSelector(
    formsState,
    (state: FormsState, props: { activityId: string }) => {
        return (state.parentFormByActivityKey[props.activityId]
            && state.parentFormByActivityKey[props.activityId]['formKey']) || null;
    }
);

export const formStatusesByActivityKeySelector = createSelector(
    formsState,
    (state: FormsState, props: { activityId: string }) => {
        return (state.formStatusByActivityKey || {})[props.activityId] || null;
    }
);

export const formStatusesByFormKeySelector = createSelector(
    formsState,
    formStatusesByActivityKeySelector,
    (state: FormsState, statusesMap: FormStatusesMap, props: { activityId: string, formId: string }) => {
        return (statusesMap || {})[props.formId] || null;
    }
);

export const formBranchingVisibilitySelector = createSelector(
    formsState,
    (state: FormsState, props: { activityId: string, useDemographics: boolean }) => {
        return state.formBranchingVisibility[props.activityId] || [];
    }
);

export const isFormActivitySubmittedSelector = createSelector(
    formsState,
    (state: FormsState, props: { activityId: string }) => {
        return state.parentFormByActivityKey[props.activityId]
            && state.parentFormByActivityKey[props.activityId]['isFormActivitySubmitted'];
    }
);

export const isFormQuestionFoundSelector = createSelector(
    formsState,
    (
        state: FormsState,
        props: { questionId: string, sectionId: string, demographicId: string }
    ) => {
        return (
            !!state.foundResponsesViaSearch.find((response) =>
                response.question_id === props.questionId ||
                response.form_id === props.sectionId && response.parent_demographic_id === props.demographicId ||
                response.repeatable_question_id === props.questionId && response.parent_demographic_id === props.demographicId)
        )
    }
);

export const formFoundResponsesSelector = createSelector(
    formsState,
    (state: FormsState) => {
        return Object.keys(groupBy(state.foundResponsesViaSearch, 'id')).reduce((acc, key) => {
            acc[key] = true;

            return acc
        }, {})
    }
)

export const loadingForSearchResponsesSelector = createSelector(
    formsState,
    (state: FormsState) => state.loadingForSearchResponses
)

export const numberOfResponsesFoundSelector = createSelector(
    formsState,
    (state: FormsState) => state.foundResponsesViaSearch.length
)

export const numberCurrentlyFoundResponseSelector = createSelector(
    formsState,
    (state: FormsState) => (state.currentResponseNumber + 1)
)

export const dataCurrentFoundResponseSelector = createSelector(
    formsState,
    (state: FormsState) => (state.foundResponsesViaSearch[state.currentResponseNumber])
)

export const isSearchResponsesActiveSelector = createSelector(
    formsState,
    (state: FormsState) => (state.isSearchResponsesActive)
)

export const foundResponsesViaSearchSelector = createSelector(
    formsState,
    (state: FormsState) => state.foundResponsesViaSearch
)

export const isNavigationActiveSelector = createSelector(
    formsState,
    (state: FormsState) => state.isNavigationActive
)

export const attachmentFoundResposesSelector = createSelector(
    formsState,
    (state: FormsState) => state.foundResponsesViaSearch.filter((el) => el.attachmentKey)
)
