import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Demographic, NavigationCardItem } from '@thinktank/common-lib';
import { Ownership } from './ownership.model';
import { Module } from './module.model';
import { Process } from './process.model';
import { DeploymentManager, userRole } from './user.model';

export enum ObjectType {
    Deployment = 'deployment',
    Module = 'module',
    Process = 'process',
    Subprocess = 'subprocess',
    Deployment_Template = 'deployment_template'
}

export const objectTypeSet = {
    [ObjectType.Deployment]: {
        type: 'deployment',
        text: 'Deployment'
    },
    [ObjectType.Module]: {
        type: 'module',
        text: 'Module'
    },
    [ObjectType.Process]: {
        type: 'process',
        text: 'Process'
    },
    [ObjectType.Subprocess]: {
        type: 'subprocess',
        text: 'Sub Process'
    }
};

export interface Tag {
    name: string;
    key?: string;
    count?: number;
    date_created?: number;
}

export abstract class AbstractDeployment implements NavigationCardItem {
    // NavigationCardItem members
    name: string;
    description: string;
    image_url?: string;
    ownerImageUrl?: string;
    members?: { [key: string]: string };
    owner?: string;
    date_last_updated?: number;

    id?: string;
    image?: string;
    modules?: Module[];
    processes?: Process[];
    subprocesses?: Process[];
    created?: firebase.firestore.Timestamp;
    updated?: firebase.firestore.Timestamp;
    version?: number;
    type?: ObjectType;
    tags?: { [key: string]: Tag };

    protected constructor(deployment: AbstractDeployment) {
        this.name = deployment.name;
        this.description = deployment.description;
        this.image_url = deployment.image;
        this.ownerImageUrl = deployment.ownerImageUrl;
        this.id = deployment.id || '';
        this.image = deployment.image;
        this.modules = deployment.modules || [];
        this.processes = deployment.processes || [];
        this.subprocesses = deployment.subprocesses || [];
        this.created = deployment.created;
        this.updated = deployment.updated;
        this.version = deployment.version;
    }
}

export class Deployment extends AbstractDeployment {
    date_created?: number;
    client_name?: string;
    demographics?: Demographic[];
    deployment_manager?: DeploymentManager;
    ownership?: {
        [combined_id: string]: Ownership // For subprocesses: userId_objectId_roleType
                                         // or userId_objectId_demographicId_roleType
                                         // For modules/processes userId_objectId
                                         // or userId_objectId_demographicId
    };
    deployment_users?: {
        [userId: string]: userRole
    };
    roster?: string[]; // user keys

    constructor(deployment: Deployment) {
        super(deployment);
        this.date_created = deployment.date_created;
        this.client_name = deployment.client_name;
        this.demographics = deployment.demographics || [];
        this.deployment_manager = deployment.deployment_manager;
        this.ownership = deployment.ownership || {};
        this.deployment_users = deployment.deployment_users || {};
        this.roster = deployment.roster || [];
        this.type = ObjectType.Deployment;
    }
}

export class DeploymentTemplate extends AbstractDeployment {
    template_users?: string[];
    owner?: string;
    deployments?: string[];

    constructor(deploymentTemplate: DeploymentTemplate) {
        super(deploymentTemplate);
        this.template_users = deploymentTemplate.template_users || [];
        this.owner = deploymentTemplate.owner;
        this.deployments = deploymentTemplate.deployments || [];
        this.type = ObjectType.Deployment_Template;
    }
}

export class PendingDeployment {
    id?: string;
    user_id: string;
    deployment_name: string;
    client_name: string;
}

export const testDeployment = {
    id: 'test_deployment',
    client_name: 'Cthulhu',
    name: 'Test Deployment',
    type: ObjectType.Deployment
};
