import { Inject, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { combineLatest, Observable, of, BehaviorSubject } from 'rxjs';
import { map, take, switchMap, mergeMap, debounceTime, tap } from 'rxjs/operators';
import { isUndefined, isEqual, groupBy as _groupBy, flatten } from 'lodash';
import { Store, select } from '@ngrx/store';

import { environment } from '@env/environment';
import {
    FormSummaryData,
    FormSummaryDBData,
    FormSummaryRequest,
    FormSummaryResponse,
    SummaryLogs,
} from '@app/core/models/form-summary.model';
import { Form, Review, ReviewState, Demographic, FormStatus } from '@app/core/models';
import { FormDemographicsService } from '@app/apps/activities/forms/form-services/form-demographics.service';
import { sortBySequenceAsc } from '@app/core/utils';
import { RepeatableQuestionsIds, FormResponse } from '@app/core/models/forms.model';
import { FormStatusModel } from '@app/core/models/form-status.model';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { AppState } from '@app/root-store/state';
import { DemographicsStoreActions, DemographicsStoreSelectors } from '@app/root-store/features/demographics';
import { FirebaseUtilityService } from './firebase-utility.service';
import { FirebaseActivityService } from './activity.service';
import { ReviewService } from './review.service';
import { FirebaseDemographicsService } from './demographics.service';
import { FirebaseUsersService } from './users.service';
import { FormComponentsService } from './form-components.service';
import { FirebaseQuestionsService } from './questions.service';

export const emptyResponse = 'EMPTY_RESPONSE';
export const emptyAttachement = 'EMPTY_ATTACHEMENT';

@Injectable({
    providedIn: 'root',
})
export class FormSummaryService extends FirebaseUtilityService {
    private reviewActiveDemographics: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(undefined);
    private shouldUpdateProgressBar: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(
        private db: AngularFireDatabase,
        private activityService: FirebaseActivityService,
        private userService: FirebaseUsersService,
        private authUserService: AuthenticatedUserService,
        private reviewService: ReviewService,
        private fbDemographicsService: FirebaseDemographicsService,
        private formDemographicsService: FormDemographicsService,
        private formComponentsService: FormComponentsService,
        private firebaseQuestionsService: FirebaseQuestionsService,
        @Inject('Window') private window: Window,
        private store$: Store<AppState>,
    ) {
        super();
    }

    async exportLogs(appKey: string, activityKey: string): Promise<void> {
        const currentUserId = this.authUserService.getCurrentUserId();
        const token = await this.authUserService.getAccessToken().pipe(take(1)).toPromise();
        const env = `https://${window.location.host}`;
        const url = `${environment.firebase.cloudFunctionsURL}/exportLogs?userId=${currentUserId}&appId=${appKey}&activityId=${activityKey}&token=${token}&env=${env}`;

        this.window.open(url);
    }

    getResponsesByActivity(activityKey: string): any {
        return this.db
            .list<Form>('ssot/_forms', ref => ref.orderByChild('activity_id').equalTo(activityKey))
            .snapshotChanges()
            .pipe(
                map((forms: any[]) =>
                    forms.map((form: Form) => {
                        return this.listWithKeys(
                            this.db.list<FormResponse>('ssot/_responses', ref =>
                                ref.orderByChild('form_id').equalTo(form.key),
                            ),
                        );
                    }),
                ),
                mergeMap(responsesRefs => combineLatest(responsesRefs)),
                map((responses: FormResponse[][]) => flatten(responses)),
                map((responses: FormResponse[]) => _groupBy(responses, 'question_id')),
            );
    }

    getReviewActiveDemographics(): Observable<string[]> {
        return this.reviewActiveDemographics.asObservable();
    }

    getAlignmentEnabled(activityKey: string): Observable<boolean> {
        return this.db.object<boolean>(`ssot/_activities/${activityKey}/alignment_enabled`).valueChanges();
    }

    getCollaborationEnabled(activityKey: string): Observable<boolean> {
        return this.db.object<boolean>(`ssot/_activities/${activityKey}/collaboration_enabled`).valueChanges();
    }

    getActivityField<T>(activityKey: string, field: string): Observable<T> {
        return this.db.object<T>(`ssot/_activities/${activityKey}/${field}`).valueChanges();
    }

    updateReviewActiveDemographics(demographics: string[]): void {
        this.reviewActiveDemographics.next(demographics);
    }

    getShouldUpdateProgressBarState(): Observable<boolean> {
        return this.shouldUpdateProgressBar.asObservable();
    }

    updateShouldUpdateProgressBarState(state: boolean): void {
        this.shouldUpdateProgressBar.next(state);
    }

    getUseDemographics(activityKey: string): Observable<boolean> {
        return this.db.object<boolean>(`ssot/_activities/${activityKey}/use_demographics`).valueChanges();
    }

    countProgressBar(
        appKey: string,
        activityKey: string,
        useDemographics: boolean,
        summaryView: boolean = false,
    ): Observable<number> {
        let tableDataVal;
        let initialize = true;

        return combineLatest(
            this.getFormSummaryData(activityKey, useDemographics),
            this.getActivitySelectedDemographics(activityKey, true),
            this.getShouldUpdateProgressBarState(),
        ).pipe(
            debounceTime(200),
            map(
                ([
                    tableData,
                    [activitySelectedDemographics, activityDemographicsMap],
                    shouldUpdateProgressBarState,
                ]) => {
                    // If summary view, update progress bar only after 'refresh' button click
                    const shouldUpdate = initialize || !(summaryView && !shouldUpdateProgressBarState);

                    if (!tableData) {
                        return 0;
                    }

                    if (shouldUpdateProgressBarState) {
                        this.updateShouldUpdateProgressBarState(false);
                    }

                    tableDataVal = shouldUpdate ? tableData : tableDataVal;
                    initialize = false;

                    return this.getAnsweredPercentage(
                        tableDataVal,
                        useDemographics,
                        activitySelectedDemographics,
                        activityDemographicsMap,
                    );
                },
            ),
        );
    }

    getSelectedDemographics(activityKey: string, filter?: boolean): Observable<Demographic[]> {
        return combineLatest(
            this.getMainFormKeyByActivity(activityKey),
            this.activityService.getActivityDemographicsClassKey(activityKey),
        ).pipe(
            switchMap(([formKey, demographicsClassKey]) => {
                return this.formDemographicsService.getFormDemographics(formKey, demographicsClassKey, filter);
            }),
        );
    }

    getMainFormKeyByActivity(activityKey: string): Observable<string> {
        return this.formComponentsService.getFormsByActivity(activityKey).pipe(
            take(1),
            map((forms: Form[]) => {
                const mainForm = forms.find((form: any) => {
                    return !form.parent_form_id;
                });

                return mainForm && mainForm.key;
            }),
        );
    }

    getDemographicsClassKey(activityKey: string): Observable<string> {
        return this.activityService.getActivityDemographicsClassKey(activityKey);
    }

    getFormStatus(activityKey: string): Observable<{ [formKey: string]: FormStatusModel }> {
        return this.db.object<{ [formKey: string]: FormStatusModel }>(`/ssot/formStatus/${activityKey}`).valueChanges();
    }

    hasSomeStatus(statusObject: any): boolean {
        if (!statusObject) {
            return false;
        }

        if (statusObject.owner_id) {
            return !!statusObject.status;
        }

        return Object.keys(statusObject).some((formOrDemographicKey: string) => {
            const innerStatusObject = statusObject[formOrDemographicKey];

            return this.hasSomeStatus(innerStatusObject);
        });
    }

    hasInProgressStatus(statusObject: any): boolean {
        if (!statusObject) {
            return true;
        }

        if (statusObject.owner_id) {
            return !statusObject.status;
        }

        return Object.keys(statusObject).some((formOrDemographicKey: string) => {
            const innerStatusObject = statusObject[formOrDemographicKey];

            return this.hasInProgressStatus(innerStatusObject);
        });
    }

    hasLockedStatus(statusObject: any): boolean {
        if (!statusObject) {
            return false;
        }

        if (statusObject.owner_id) {
            return statusObject.status === FormStatus.Locked;
        }

        return Object.keys(statusObject).some((formOrDemographicKey: string) => {
            const innerStatusObject = statusObject[formOrDemographicKey];

            return this.hasLockedStatus(innerStatusObject);
        });
    }

    getFormStatusByForm(activityKey: string, formKey: string): Observable<FormStatusModel> {
        return this.db.object<FormStatusModel>(`/ssot/formStatus/${activityKey}/${formKey}`).valueChanges();
    }

    getAnsweredPercentage(
        summaryData: FormSummaryData[] = [],
        useDemographics: boolean,
        formDemographics: Demographic[],
        demographicsMap: { [classKey: string]: Demographic[] },
    ): number {
        const questions = summaryData.reduce((accumulator, question) => {
            let tmpQuestions = [];

            if (question.response_type === 'subform') {
                // Check visibility of section
                const demographicKeys = formDemographics.map(demo => demo.key);
                const hidden = useDemographics
                    ? demographicKeys.reduce((acc, demographicKey) => {
                          return acc && !question.branching_visibility[demographicKey];
                      }, true)
                    : !question.branching_visibility;

                if (!hidden) {
                    if (useDemographics) {
                        tmpQuestions = demographicKeys.reduce((accum, demographicKey) => {
                            if (!question.branching_visibility[demographicKey]) {
                                return accum;
                            }

                            return [
                                ...accum,
                                ...question.questions.map(subQuestion => ({
                                    ...subQuestion,
                                    subQuestion: true,
                                    parentDemographicKey: demographicKey,
                                    parentDemographicClassKey: question.demographics_class_id,
                                })),
                            ];
                        }, []);
                    } else {
                        tmpQuestions = [
                            ...question.questions.map(subQuestion => ({
                                ...subQuestion,
                                subQuestion: true,
                                parentDemographicClassKey: question.demographics_class_id,
                            })),
                        ];
                    }
                }
            } else {
                tmpQuestions = [question];
            }

            return [...accumulator, ...tmpQuestions];
        }, []);
        const allQuestionsCount = questions.length;
        let answeredQuestionsCount = 0;
        let hiddenQuestionsCount = 0;

        questions.forEach(question => {
            const { subQuestion, parentDemographicClassKey } = question;
            const demographics = useDemographics ? formDemographics : false;
            const sectionDemographics = parentDemographicClassKey ? demographicsMap[parentDemographicClassKey] : false;

            let hidden;
            let answered;
            const byDemographics = subQuestion ? sectionDemographics || demographics : demographics;

            if (byDemographics) {
                const demographicKeys = byDemographics.map(demo => demo.key);

                hidden = demographicKeys.reduce((accumulator, demographicKey) => {
                    if (question.parentDemographicKey) {
                        if (parentDemographicClassKey) {
                            return (
                                accumulator &&
                                !question.branching_visibility &&
                                !question.branching_visibility[question.parentDemographicKey] &&
                                !question.branching_visibility[question.parentDemographicKey][demographicKey]
                            );
                        }

                        return accumulator && !question.branching_visibility[question.parentDemographicKey];
                    }

                    return accumulator && !question.branching_visibility[demographicKey];
                }, true);
                const demographicsList = byDemographics.map(demo => demo.key);

                answered = this.questionAnswered(question, true, demographicsList);
            } else {
                hidden = !question.branching_visibility;
                answered = this.questionAnswered(question);
            }

            if (hidden) {
                hiddenQuestionsCount++;
            } else if (answered) {
                answeredQuestionsCount++;
            }
        });

        return Math.round((answeredQuestionsCount / (allQuestionsCount - hiddenQuestionsCount)) * 100);
    }

    async getReviewKey(activityKey: string): Promise<string> {
        const parentFormKey = await this.activityService
            .getActivityFieldValue(activityKey, 'form_id')
            .pipe(take(1))
            .toPromise();

        if (!parentFormKey) {
            return Promise.resolve('');
        }

        return this.reviewService.getReviewKeyByFormKey(parentFormKey).pipe(take(1)).toPromise();
    }

    getReviewObject(reviewKey: string): Promise<Review> {
        return this.reviewService.getReviewByKey(reviewKey).pipe(take(1)).toPromise();
    }

    getReviewDemographics(reviewKey: string): Promise<Review> {
        return this.reviewService.getReviewDemographics(reviewKey).pipe(take(1)).toPromise();
    }

    getUsersData(appKey: string): Observable<any> {
        // get users data
        return this.userService.getInstanceUsers(appKey).pipe(
            map(users => {
                return users.map(userInfo => {
                    return {
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                        email: userInfo.email,
                        username: userInfo.username,
                        key: userInfo.key,
                    };
                });
            }),
        );
    }

    getSummaryLogs(activityId: string): Observable<SummaryLogs> {
        return this.db.object<SummaryLogs>(`ssot/logsSummary/${activityId}`).valueChanges();
    }

    getFormSummaryData(activityKey: string, useDemographics?: boolean): Observable<FormSummaryData[]> {
        return this.listWithKeys(this.db.list<FormSummaryDBData>(`ssot/formSummaryData/${activityKey}`)).pipe(
            debounceTime(200),
            mergeMap((formSummaryData: FormSummaryDBData[]) => {
                // Need to get questions for proper sequence update of formSummary table data
                return combineLatest(
                    of(formSummaryData),
                    this.firebaseQuestionsService.getQuestionsByActivityKey(activityKey).pipe(take(1)),
                );
            }),
            map(([formSummaryDataValue, questionsRef]) => {
                const updatedQuestions = {};
                const repeatableQuestions = {};

                questionsRef.forEach(question => {
                    updatedQuestions[question.key] = question;
                });

                return formSummaryDataValue
                    .map((question): FormSummaryData => {
                        if (question.repeatable_questions_ids) {
                            repeatableQuestions[question.key] = question.repeatable_questions_ids;
                        }

                        const repeatableQuestionsId = question.repeatable_question_id;

                        const repeatedFromQuestion = repeatableQuestionsId
                            ? repeatableQuestions[repeatableQuestionsId] &&
                              repeatableQuestions[repeatableQuestionsId][question.key]
                            : null;
                        const newQuestion = this.transformQuestionData(question, useDemographics, repeatedFromQuestion);

                        if (updatedQuestions[newQuestion.question_id]) {
                            newQuestion.sequence = updatedQuestions[newQuestion.question_id].sequence;
                        }

                        if (question.repeatable_questions_ids) {
                            newQuestion.sub_sequence = 1;
                        }

                        if (repeatableQuestionsId) {
                            const sourceSectionRepeatedKeys = Object.keys(
                                (updatedQuestions[question.repeatable_question_id] || {}).repeatable_questions_ids ||
                                    {},
                            );

                            newQuestion.sub_sequence = sourceSectionRepeatedKeys.indexOf(question.key) + 2;
                        }

                        return newQuestion;
                    })
                    .sort((a, b) => {
                        if (a.sequence === b.sequence) {
                            return a.sub_sequence < b.sub_sequence ? -1 : 1;
                        }

                        if (a.sequence < b.sequence) {
                            return -1;
                        }

                        if (a.sequence > b.sequence) {
                            return 1;
                        }

                        return 0;
                    });
            }),
        );
    }

    updateReviewState(
        reviewKey: string,
        reviewState: ReviewState,
        activityKey: string,
        alignmentEnabled?: boolean,
        comment?: string,
    ): Promise<void> {
        return this.reviewService.updateStateForReview(reviewKey, reviewState, activityKey, alignmentEnabled, comment);
    }

    calculateCommonResponse(question: FormSummaryData, demographics: Demographic[] = []): FormSummaryResponse | null {
        const responses = question.response;
        const branchingVisibility = question.branching_visibility;
        const demographicsKeys = Object.keys(responses || {});

        // if one if the responses skipped -> show 'view details'
        const allResponsesAreVisible = demographics.reduce((accumulator, demographic) => {
            return accumulator && !!branchingVisibility[demographic.key];
        }, true);

        if (!allResponsesAreVisible) {
            return null;
        }

        // if all responses are visible and there are no responses -> show 'no response given'
        if (!demographicsKeys.length || !demographics.length) {
            return emptyResponse;
        }

        // compare responses with the first
        const firstDemographicKey = demographics[0].key;
        let firstResponse = branchingVisibility[firstDemographicKey] ? responses[firstDemographicKey] : null;

        firstResponse = firstResponse === 0 ? 0 : firstResponse || emptyResponse;
        let responsesDiffer = false;

        demographics.slice(0).reduce((accumulator, demographic, i, array) => {
            let response = branchingVisibility[demographic.key] ? responses[demographic.key] : emptyResponse;

            response = response === 0 ? 0 : response || emptyResponse;
            if (!isEqual(accumulator, response)) {
                responsesDiffer = true;
                array.splice(1);
            }

            return accumulator;
        }, firstResponse);

        return responsesDiffer ? null : firstResponse;
    }

    // if filter == true - get only active demographics
    // if filter == false - get active demographics and inactive demographics
    getAllActivityDemographicsByClass(
        activityKey: string,
        filter?: boolean,
    ): Observable<{ [classKey: string]: Demographic[] }> {
        const userKey = this.authUserService.getCurrentUserId();

        return combineLatest(
            this.formComponentsService.getFormsByActivity(activityKey),
            this.store$.pipe(select(DemographicsStoreSelectors.selectedDemographicsObjectSelector)),
        ).pipe(
            this.addUndefinedDemographics(userKey),
            map(([forms]) => forms),
            map((forms): Observable<any> => {
                const demographicClassesKeys = [];
                const demographicClasses = forms.map((form: Form) => {
                    demographicClassesKeys.push(form.demographics_class_id || '');

                    return form.demographics_class_id
                        ? this.formDemographicsService.getFormDemographics(form.key, form.demographics_class_id, filter)
                        : of([]);
                });

                return combineLatest([of(demographicClassesKeys), ...demographicClasses]);
            }),
            mergeMap(data => data),
            map(([demographicClassesKeys, ...demographicClasses]) => {
                const demographicClassesMap = {};

                demographicClasses.forEach((demographics, index) => {
                    const key = demographicClassesKeys[index];

                    if (key) {
                        demographicClassesMap[key] = demographics;
                    }
                });

                return demographicClassesMap;
            }),
        );
    }

    getAllActivityDemographicsByForm(activityKey: string): Observable<{ [formKey: string]: Demographic[] }> {
        const userKey = this.authUserService.getCurrentUserId();

        return combineLatest(
            this.formComponentsService.getFormsByActivity(activityKey),
            this.store$.pipe(select(DemographicsStoreSelectors.selectedDemographicsObjectSelector)),
        ).pipe(
            this.addUndefinedDemographics(userKey),
            map(([forms]) => forms),
            map((forms: Form[]): Observable<any> => {
                const formsKeys = [];
                const demographicClasses = forms.map((form: Form) => {
                    formsKeys.push(form.key || '');

                    return form.demographics_class_id
                        ? this.formDemographicsService.getFormDemographics(form.key, form.demographics_class_id, true)
                        : of([]);
                });

                return combineLatest([of(formsKeys), ...demographicClasses]);
            }),
            mergeMap(data => data),
            map(([formsKeys, ...demographicClasses]) => {
                const formKeysMap = {};

                demographicClasses.forEach((demographics, index) => {
                    const key = formsKeys[index];

                    if (key) {
                        formKeysMap[key] = demographics;
                    }
                });

                return formKeysMap;
            }),
        );
    }

    getAllActivityDemographicsKeys(activityKey: string): Observable<{ [formKey: string]: string[] }> {
        const userKey = this.authUserService.getCurrentUserId();

        return combineLatest(
            this.formComponentsService.getFormsByActivity(activityKey),
            this.store$.pipe(select(DemographicsStoreSelectors.selectedDemographicsObjectSelector)),
        ).pipe(
            this.addUndefinedDemographics(userKey),
            map(([forms]) => forms),
            map((forms: Form[]): Observable<any> => {
                const statuses$ = this.getFormStatus(activityKey);
                const formsKeys = [];
                const demographicClasses = forms.map((form: Form) => {
                    formsKeys.push(form.key || '');

                    return form.demographics_class_id
                        ? this.formDemographicsService.getFormDemographics(form.key, form.demographics_class_id, true)
                        : of([]);
                });

                return combineLatest([statuses$, of(formsKeys), ...demographicClasses]);
            }),
            mergeMap(data => data),
            map(([statuses = {}, formsKeys, ...demographicClasses]) => {
                return demographicClasses.reduce((accumulator, demographics, index) => {
                    const formsKey = formsKeys[index];

                    if (!formsKey) {
                        return accumulator;
                    }
                    const demographicsKeys = demographics
                        .map(demo => demo.key)
                        .filter(demoKey => {
                            const status = statuses?.[formsKey]?.[demoKey];

                            return statuses ? this.hasInProgressStatus(status) || this.hasLockedStatus(status) : true;
                        });

                    return {
                        ...accumulator,
                        [formsKey]: demographicsKeys,
                    };
                }, {});
            }),
        );
    }

    getFormDemographics(activityKey: string, classKey: string): Observable<Demographic[]> {
        return this.formDemographicsService.getFormDemographics(activityKey, classKey);
    }

    getActivitySelectedDemographics(
        activityKey: string,
        filter?: boolean,
    ): Observable<[Demographic[], { [classKey: string]: Demographic[] }]> {
        return combineLatest([
            this.getSelectedDemographics(activityKey, filter),
            this.getAllActivityDemographicsByClass(activityKey, filter),
        ]);
    }

    private isFormInvalid(
        formSummaryData: FormSummaryData[],
        parentFormKey: string,
        useDemographics: boolean,
        formDemographicsMap: { [formKey: string]: Demographic[] },
        focusedDemographicKey: string,
        collaborationEnabled: boolean,
        activityStatus: { [formKey: string]: FormStatusModel },
        section?: FormSummaryData,
        parentDemographicKey?: string,
    ): boolean {
        let required = false;
        let questions;
        let demographics;
        const parentFormStatus = activityStatus[parentFormKey] || {};

        if (section) {
            questions = section.questions;
            required = section.required;
            demographics = section.demographics_class_id ? formDemographicsMap[section.subform_id] : null;
        } else {
            questions = formSummaryData;
            demographics = formDemographicsMap[parentFormKey];
        }

        return !!questions.find(question => {
            if (useDemographics) {
                if (question.response_type !== 'subform') {
                    return this.isQuestionWithDemographicsInvalid(
                        question,
                        required || question.required,
                        parentDemographicKey || null,
                        demographics,
                        focusedDemographicKey,
                        collaborationEnabled,
                        parentFormStatus,
                    );
                } else {
                    return !!formDemographicsMap[parentFormKey].find(parentDemographic => {
                        const isValidateSection =
                            question.branching_visibility[parentDemographic.key] &&
                            this.isFormInvalid(
                                formSummaryData,
                                parentFormKey,
                                useDemographics,
                                formDemographicsMap,
                                focusedDemographicKey,
                                collaborationEnabled,
                                activityStatus,
                                question,
                                parentDemographic.key,
                            );

                        if (isValidateSection) {
                            return true;
                        }
                    });
                }
            } else {
                if (question.response_type !== 'subform') {
                    return this.isQuestionInvalid(question, required || question.required);
                } else {
                    return (
                        question.branching_visibility &&
                        this.isFormInvalid(
                            formSummaryData,
                            parentFormKey,
                            useDemographics,
                            formDemographicsMap,
                            focusedDemographicKey,
                            collaborationEnabled,
                            activityStatus,
                            question,
                        )
                    );
                }
            }
        });
    }

    private isQuestionInvalid(question: FormSummaryData, required: boolean): boolean {
        return required && question.branching_visibility && !question.response && question.response !== 0;
    }

    private isQuestionWithDemographicsInvalid(
        question: FormSummaryData,
        required: boolean,
        parentDemographicKey: string,
        demographics: Demographic[],
        focusedDemographicKey: string,
        collaborationEnabled: boolean,
        parentFormStatus: FormStatusModel,
    ): boolean {
        if (demographics) {
            return !!demographics.find(demographic => {
                if (focusedDemographicKey && demographic.key !== focusedDemographicKey) {
                    return;
                }

                if (parentDemographicKey) {
                    // When use demographics and question inside section and section has second demographic class
                    const response =
                        question.response &&
                        question.response[parentDemographicKey] &&
                        question.response[parentDemographicKey][demographic.key];

                    return (
                        required &&
                        question.branching_visibility[parentDemographicKey][demographic.key] &&
                        !response &&
                        response !== 0
                    );
                } else {
                    if (
                        !collaborationEnabled &&
                        !this.getDemographicLockedByCurrentUser(parentFormStatus, demographic.key)
                    ) {
                        return false;
                    }

                    // When use demographics and question outside of section
                    const response = question.response && question.response[demographic.key];

                    return required && question.branching_visibility[demographic.key] && !response && response !== 0;
                }
            });
        } else {
            if (
                !collaborationEnabled &&
                !this.getDemographicLockedByCurrentUser(parentFormStatus, parentDemographicKey)
            ) {
                return false;
            }

            // When use demographics and question inside section
            const response = question.response && question.response[parentDemographicKey];

            return required && question.branching_visibility[parentDemographicKey] && !response && response !== 0;
        }
    }

    private getDemographicLockedByCurrentUser(status: FormStatusModel, demoKey: string): boolean {
        return (
            !!status[demoKey] &&
            status[demoKey].status === FormStatus.Locked &&
            status[demoKey].owner_id === this.authUserService.getCurrentUserId()
        );
    }

    private transformQuestionData(
        question: FormSummaryDBData,
        useDemographics: boolean,
        repeatedFromQuestion?: RepeatableQuestionsIds,
    ): FormSummaryData {
        const { key, response_type, questions, collaborations, requests, response } = question;
        const sectionQuestions = [];

        if (response_type === 'subform') {
            Object.keys(questions || {}).forEach(questionKey => {
                sectionQuestions.push(
                    this.transformQuestionData(
                        {
                            ...questions[questionKey],
                            key: questionKey,
                        },
                        question.use_demographics,
                    ),
                );
            });
        }

        return <FormSummaryData>{
            ...question,
            response_type,
            question_id: key,
            response: <FormSummaryResponse>(response || response === 0 ? response : useDemographics ? {} : ''),
            branching_visibility: isUndefined(question.branching_visibility) ? {} : question.branching_visibility,
            collaborations: this.transformCollaborationRequests(collaborations || {}),
            requests: this.transformCollaborationRequests(requests || {}),
            questions: sectionQuestions,
            repeatedFromQuestion,
        };
    }

    private transformCollaborationRequests(requests: {
        [requestKey: string]: FormSummaryRequest;
    }): FormSummaryRequest[] {
        return Object.keys(requests).map(requestKey => ({
            ...requests[requestKey],
            request_id: requestKey,
        }));
    }

    private questionAnswered(
        question: FormSummaryData & {
            parentDemographicKey: string;
            subQuestion: true;
            parentDemographicClassKey: string;
        },
        useDemographics?: boolean,
        demographicsKeys?: string[],
    ): boolean {
        const {
            subQuestion,
            required,
            branching_visibility,
            response,
            parentDemographicKey,
            parentDemographicClassKey,
        } = question;

        if (useDemographics) {
            let answeredCount = 0;

            if (subQuestion && parentDemographicKey && !parentDemographicClassKey) {
                const valueExists = !!response[parentDemographicKey] || response[parentDemographicKey] === 0;
                const enabled = branching_visibility[parentDemographicKey];

                if (enabled && valueExists) {
                    answeredCount++;
                }
            } else {
                demographicsKeys.forEach(demographicKey => {
                    let valueExists;
                    let enabled;

                    if (parentDemographicKey) {
                        // question inside section with demographics
                        const responseValue = (response[parentDemographicKey] || {})[demographicKey];

                        valueExists = !!responseValue || responseValue === 0;
                        enabled = (branching_visibility[parentDemographicKey] || {})[demographicKey];
                    } else {
                        // question outside section with demographics in parent form
                        valueExists = !!response[demographicKey] || response[demographicKey] === 0;
                        enabled = branching_visibility[demographicKey];
                    }

                    if (enabled && valueExists) {
                        answeredCount++;
                    }
                });
            }

            return required ? answeredCount === demographicsKeys.length : answeredCount >= 1;
        } else {
            return branching_visibility ? !!response || response === 0 : false;
        }
    }

    private addUndefinedDemographics(userKey: string) {
        return tap(([forms, selectedDemographicsObject]) => {
            forms.forEach(form => {
                if (
                    !!form.demographics_class_id &&
                    !selectedDemographicsObject[`${form.demographics_class_id}_${userKey}`]
                ) {
                    this.initializeDataForFormDemographics(form.demographics_class_id, userKey, form.key);
                }
            });
        });
    }

    private initializeDataForFormDemographics(demographicClassId: string, userKey: string, formKey: string): void {
        this.store$.dispatch(
            DemographicsStoreActions.getDemographics({
                demographicsClassKey: demographicClassId,
            }),
        );
        this.store$.dispatch(
            DemographicsStoreActions.getSelectedDemographics({
                demographicsClassKey: demographicClassId,
                userId: userKey,
            }),
        );
        this.store$.dispatch(
            DemographicsStoreActions.getActiveFormDemographicKeys({
                demographicsClassKey: demographicClassId,
                userId: userKey,
                formKey: formKey,
            }),
        );
        this.store$.dispatch(
            DemographicsStoreActions.getFormDemographicsClassId({
                formKey: formKey,
            }),
        );
    }
}
