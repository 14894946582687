import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, of } from 'rxjs';
import { map, take, mergeMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

import { FirestoreService } from './firestore.service';
import { FormResponsesHistory, FormHistoryResponse, FormHistoryResponseComment, DBFormHistoryResponse, UserHistoryResponseLink } from '../models/responses-history.model';
import { FirebaseService } from './firebase.service';

@Injectable()
export class ResponseHistoryService {

    constructor(
        private _firestoreService: FirestoreService,
        private _firebaseService: FirebaseService,
        private _angularFireFunctions: AngularFireFunctions,
        private _datePipe: DatePipe
    ) { }

    buildResponse(
        value: any,
        ownerId: string,
        ownerFullName: string,
        formStatus: string,
        questionResponseType?: string,
        old_date?: number,
        customName?: string,
    ): DBFormHistoryResponse {
        return {
            old_date,
            value: this.valueFactoryMethod(value, questionResponseType),
            owner_id: ownerId,
            owner_full_name: ownerFullName,
            date_created: this._firestoreService.timestamp,
            form_status: formStatus,
            comments_count: 0,
            upvotes: [],
            custom_name: customName,
        } as DBFormHistoryResponse;
    }

    getQuestionResponsesHistory(questionId: string, demographicId?: string, secondaryDemographicId?: string): Observable<FormResponsesHistory> {
        const path = this.buildPath(questionId, demographicId, secondaryDemographicId);
        return this._firestoreService.getDocument<FormResponsesHistory>(path);
    }

    getQuestionResponses(questionId: string, demographicId?: string, secondaryDemographicId?: string): Observable<FormHistoryResponse[]> {
        const path = this.buildPath(questionId, demographicId, secondaryDemographicId);
        return this._firestoreService.getCollection<FormHistoryResponse>(`${path}/responses`, 'date_created', 'desc');
    }

    getHistoryResponseComments(responseId: string, questionId: string, demographicId?: string, secondaryDemographicId?: string): Observable<FormHistoryResponseComment[]> {
        const path = this.buildPath(questionId, demographicId, secondaryDemographicId);
        return this._firestoreService.getCollection<FormHistoryResponseComment>(`${path}/responses/${responseId}/comments`, 'date_created');
    }

    getQuestionCurrentResponseCommentsCount(questionId: string, demographicId?: string, secondaryDemographicId?: string): Observable<number> {
        return this.getCurrentResponse(questionId, demographicId, secondaryDemographicId)
            .pipe(mergeMap(currentResponse => {
                if (currentResponse) {
                    const historyPath = this.buildPath(questionId, demographicId, secondaryDemographicId);
                    const path = `${historyPath}/responses/${currentResponse.id}`;
                    return this._firestoreService.getDocument<FormHistoryResponse>(path)
                        .pipe(map(currentResponse => currentResponse && currentResponse.comments_count));
                }

                return of(0);
            }));
    }

    getCurrentResponse(questionId: string, demographicId?: string, secondaryDemographicId?: string): Observable<FormHistoryResponse> {
        const historyPath = this.buildPath(questionId, demographicId, secondaryDemographicId);
        const responsesPath = `${historyPath}/responses`;
        return this._firestoreService.getCollection<FormHistoryResponse>(responsesPath)
            .pipe(map(responses => {
                const currentResponse = responses.find(response => response.current);
                return currentResponse || null;
            }));
    }

    async toggleUpvote(responseId: string, userId: string, questionId: string, demographicId?: string, secondaryDemographicId?: string): Promise<void> {
        const historyPath = this.buildPath(questionId, demographicId, secondaryDemographicId);
        const responsePath = `${historyPath}/responses/${responseId}`;
        const responserRef = this._firestoreService.getDocumentRef<FormHistoryResponse>(responsePath);

        await this._firestoreService.runTransaction<FormHistoryResponse>(async (transaction) => {
            const { upvotes } = (await transaction.get(responserRef)).data();
            const userIdIndex = (upvotes || []).findIndex((upvotedUserId => upvotedUserId === userId));

            let newUpvotes;
            if (userIdIndex === -1) {
                newUpvotes = [...upvotes, userId];
            } else {
                const slicedUpvotes = upvotes.slice();
                slicedUpvotes.splice(userIdIndex);
                newUpvotes = slicedUpvotes;
            }

            return transaction.update(responserRef, { upvotes: newUpvotes });
        });
    }

    async addComment(comment: FormHistoryResponseComment, responseId: string, formId: string, questionId: string, demographicId?: string, secondaryDemographicId?: string): Promise<void> {
        const historyPath = this.buildPath(questionId, demographicId, secondaryDemographicId);
        const responsePath = `${historyPath}/responses/${responseId}`;
        const responserRef = this._firestoreService.getDocumentRef<FormHistoryResponse>(responsePath);

        const commentsPath = `${responsePath}/comments`;
        const newCommentId = await this._firestoreService.addDocument(commentsPath, comment);
        await this._firestoreService.runTransaction<FormHistoryResponse>(async (transaction) => {
            const { comments_count } = (await transaction.get(responserRef)).data();

            const newCommentsCount = (comments_count || 0) + 1;
            return transaction.update(responserRef, { comments_count: newCommentsCount });
        });

        await this.linkResponseToUser(
            comment.owner_id,
            responseId,
            newCommentId,
            null,
            formId,
            questionId,
            demographicId,
            secondaryDemographicId
        );
    }

    async updateComment(comment: { id: string, value: string }, responseId: string, questionId: string, demographicId?: string, secondaryDemographicId?: string): Promise<void> {
        const historyPath = this.buildPath(questionId, demographicId, secondaryDemographicId);
        const commentPath = `${historyPath}/responses/${responseId}/comments/${comment.id}`;

        const timestamp = this._firestoreService.timestamp;
        await this._firestoreService.update(commentPath, {
            value: comment.value,
            date_updated: timestamp
        });
    }

    async deleteComment(commentId: string, responseId: string, ownerId: string, questionId: string, demographicId: string, secondaryDemographicId: string): Promise<void> {
        const historyPath = this.buildPath(questionId, demographicId, secondaryDemographicId);
        const responsePath = `${historyPath}/responses/${responseId}`;
        const commentPath = `${responsePath}/comments/${commentId}`;

        await this._firestoreService.delete(commentPath);

        const responserRef = this._firestoreService.getDocumentRef<FormHistoryResponse>(responsePath);
        await this._firestoreService.runTransaction<FormHistoryResponse>(async (transaction) => {
            const { comments_count } = (await transaction.get(responserRef)).data();

            return transaction.update(responserRef, { comments_count: comments_count - 1 });
        });

        await this.removeLinkedResponseToUser(responseId, ownerId, commentId);
    }

    async addResponse(response: DBFormHistoryResponse, previousResponse: DBFormHistoryResponse, formId: string, questionId: string, userId?: string, demographicId?: string, secondaryDemographicId?: string): Promise<void> {
        const data = { response, previousResponse, formId, questionId, demographicId, secondaryDemographicId, userId };
        try {
            await this._angularFireFunctions.httpsCallable('addFormHistoryResponse')(data).pipe(take(1)).toPromise();
        } catch (error) {
            console.log('Error while adding form response');
            console.log(error);
        }
    }

    async restoreResponse(
        responseId: string,
        ownerId: string,
        ownerFullName: string,
        formStatus: string,
        formId: string,
        questionId: string,
        demographicId?: string,
        secondaryDemographicId?: string
    ): Promise<void> {
        const data = { responseId, ownerId, ownerFullName, formStatus, formId, questionId, demographicId, secondaryDemographicId };

        try {
            await this._angularFireFunctions.httpsCallable('restoreFormHistoryResponse')(data).toPromise();
        } catch (error) {
            console.log('Error while restoring form response');
            console.log(error);
        }
    }

    private async linkResponseToUser(
        ownerId: string,
        responseId: string,
        commentId: string,
        isInitial: boolean,
        formId: string,
        questionId: string,
        demographicId?: string,
        secondaryDemographicId?: string
    ): Promise<void> {
        const linkObject = this.buildResponseToUserLinkObject(
            ownerId,
            formId,
            responseId,
            commentId,
            isInitial,
            questionId,
            demographicId,
            secondaryDemographicId
        );

        // link response to firebase user
        await this.linkResponseToFbUser(linkObject, ownerId);

        // TODO: link response to firestore user
    }

    private async linkResponseToFbUser(linkObject: UserHistoryResponseLink, ownerId: string): Promise<void> {
        const fbUserExists = !!(await this._firebaseService.object(`/users/${ownerId}`).pipe(take(1)).toPromise());
        if (fbUserExists) {
            const path = `ssot/_user_history_responses/${this._firebaseService.pushId}`;
            await this._firebaseService.update(path, linkObject);
        }
    }

    private async removeLinkedResponseToUser(responseId: string, ownerId: string, commentId?: string): Promise<void> {
        await this.removeLinkedResponseToFBUser(responseId, ownerId, commentId);
    }

    private async removeLinkedResponseToFBUser(responseId: string, ownerId: string, commentId?: string): Promise<void> {
        const fbUserExists = !!(await this._firebaseService.object(`/users/${ownerId}`).pipe(take(1)).toPromise());
        if (fbUserExists) {
            const path = `ssot/_user_history_responses`;
            const responseLinks = await this._firebaseService.listWithIds<UserHistoryResponseLink>(path, 'response_id', responseId).pipe(take(1)).toPromise();
            const userResponseLinks = responseLinks.filter(link => {
                return link.owner_id === ownerId
                    && (!!commentId ? link.comment_id === commentId : !link.comment_id);
            });

            const updates = userResponseLinks.reduce((acc, link) => {
                acc[`${path}/${link.id}`] = null;
                return acc;
            }, {});

            await this._firebaseService.multiPathUpdate(updates);
        }
    }

    private buildPath(questionId: string, demographicId?: string, secondaryDemographicId?: string): string {
        let path = `/response_history/${questionId}`;
        if (demographicId) {
            path += `:${demographicId}`;

            if (secondaryDemographicId) {
                path += `:${secondaryDemographicId}`;
            }
        }
        return path;
    }

    private buildResponseToUserLinkObject(
        ownerId: string,
        formId: string,
        responseId: string,
        commentId: string,
        isInitial: boolean,
        questionId: string,
        demographicId?: string,
        secondaryDemographicId?: string
    ): UserHistoryResponseLink {
        return {
            owner_id: ownerId,
            form_id: formId,
            response_id: responseId,
            comment_id: commentId || null,
            is_initial: isInitial || null,
            question_id: questionId,
            demographic_id: demographicId || null,
            secondary_demographic_id: secondaryDemographicId || null
        } as UserHistoryResponseLink;
    }

    private valueFactoryMethod(value: any, questionResponseType?: string): any {
        if (!questionResponseType) {
           return value;
        }

        switch (questionResponseType) {
            case 'date': return this._datePipe.transform(value, 'MM/dd/yyyy');
            default: return value;
        }
    }
}
