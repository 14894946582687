import { Component, OnChanges, Input } from '@angular/core';

import { invalidImageFormatMessage, defaultImageUrl, defaultImageFormats } from './image-circle.constants';

import { LoaderService, FirebaseAppService, FilestackService } from '@app/core/services';
import { FilestackStorage, FilestackUploadResult, FilestackUploadOptions, FilestackUploadType } from '@app/core/models';

@Component({
    selector: 'app-image-circle',
    templateUrl: './image-circle.component.html',
    styleUrls: ['./image-circle.component.scss']
})

export class ImageCircleComponent implements OnChanges {
    @Input() appKey: string;
    @Input() canEdit?: boolean;
    @Input() imageUrl?: string;

    invalidImageFormat: boolean;
    showImageDescription: boolean;

    invalidImageFormatMessage = invalidImageFormatMessage;
    defaultImageUrl = defaultImageUrl;
    imageFormats = defaultImageFormats;

    constructor(
        private filestackService: FilestackService,
        private appService: FirebaseAppService,
        private loaderService: LoaderService
    ) { }

    async setImage(): Promise<void> {
        const options = {
            storeTo: FilestackStorage.FirebaseStorage,
            fbFolder: 'apps',
            uploadType: FilestackUploadType.Images,
            onUploadStarted: () => this.loaderService.display(true),
            onUploadFailed: () => this.loaderService.display(false),
            onUploadDone: ({ url }: FilestackUploadResult) => {
                this.appService.updateAppField(this.appKey, 'image_url', url)
                    .then(() => this.loaderService.display(false))
                    .catch(() => this.loaderService.display(false));
            }
        } as FilestackUploadOptions;
        await this.filestackService.uploadFile(options);
    }

    ngOnChanges() {
        this.imageUrl = this.imageUrl || this.defaultImageUrl;
    }
}
