import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, combineLatest, BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { groupBy } from 'lodash';

import { FirestoreService } from './firestore.service';
import {
    Log,
    EditableLog,
    SetupOption,
    LogRequirement,
    LogImpact,
    MitigationStrategy,
    SeverityData,
    FrequencyData,
    SetupOptionsData,
} from '../models';
import { sortByStringSequenceAsc } from '../utils';

@Injectable({
    providedIn: 'root'
})
export class LogService {
    private _logWasRemovedSnackbarVisibleSubject = new BehaviorSubject<boolean>(false);
    logWasRemovedSnackbarVisible$ = this._logWasRemovedSnackbarVisibleSubject.asObservable().pipe(distinctUntilChanged());

    constructor(
        private firestoreService: FirestoreService,
        private afFun: AngularFireFunctions,
    ) { }

    async updateHiddenSetupOption(projectId: string, data: string[]): Promise<void> {
        this.firestoreService.update(`/logs/${projectId}/setup/options`, { hidden_setup_options: data });
    }

    async addSetupOption(projectId: string, optionName: string, sequence: string): Promise<void> {
        this.firestoreService.addDocument(`/logs/${projectId}/setup/options/${optionName}`, {
            sequence,
        });
    }

    async removeSetupOption(projectId: string, optionName: string, key: string): Promise<void> {
        this.firestoreService.delete(`/logs/${projectId}/setup/options/${optionName}/${key}`);
    }

    async updateSetupOption(projectId: string, optionName: string, key: string, data: SetupOption, valid: boolean): Promise<void> {
        if (valid) {
            this.firestoreService.update(`/logs/${projectId}/setup/options/${optionName}/${key}`, data);
        }
    }

    async addCheckBoxOption(projectId: string, data): Promise<void> {
        this.firestoreService.update(`/logs/${projectId}/setup/options`, data);
    }

    getSetupOptions(projectId: string, option: string): Observable<SetupOption[]> {
        return this.firestoreService.getCollection<SetupOption>(`/logs/${projectId}/setup/options/${option}`).pipe(
            map((options) => {
                return sortByStringSequenceAsc(options);
            })
        );
    }

    getDataOptions(projectId: string): Observable<SetupOptionsData> {
        return this.firestoreService.getDocument<SetupOptionsData>(
            `/logs/${projectId}/setup/options`
        );
    }

    toggleLogWasRemovedSnackbar(value: boolean): void {
        this._logWasRemovedSnackbarVisibleSubject.next(value);

        if (value) {
            setTimeout(() => this._logWasRemovedSnackbarVisibleSubject.next(false), 5000);
        }
    }

    getProjectLogs(projectId: string): Observable<Log[]> {
        return this.firestoreService.getCollection<Log>(`/logs/${projectId}/nodes`).pipe(this.mapImpactsToLogs(projectId));
    }

    getProjectCountries(projectId: string): Observable<string[]> {
        return this.firestoreService.getCollection<Log>(`/logs/${projectId}/nodes`).pipe(map(this.mapLogToCountryArray));
    }

    hasProjectLogs(projectId: string): Observable<boolean> {
        return this.firestoreService.getCollection<Log>(`/logs/${projectId}/nodes`).pipe(
            map((data: Log[]) => !!data.length),
            distinctUntilChanged()
        );
    }

    getActivityLogs(projectId: string, activityId: string): Observable<Log[]> {
        return this.firestoreService.getDocumentsByProperty<Log>(`/logs/${projectId}/nodes`, 'activity_id', activityId);
    }

    getSlideLogs(projectId: string, slideId: string): Observable<Log[]> {
        return this.firestoreService.getDocumentsByProperty<Log>(`/logs/${projectId}/nodes`, 'slide_id', slideId);
    }

    getLog(projectId: string, logId: string): Observable<Log> {
        return this.firestoreService.getDocument(`/logs/${projectId}/nodes/${logId}`);
    }

    async addLog(projectId: string, log: Log): Promise<string> {
        const {
            impacts,
            requirements,
            mitigation_strategies,
            ...logData } = log;

        const timestamp = this.firestoreService.timestamp;

        const logId = await this.firestoreService.addDocument<Log>(`/logs/${projectId}/nodes`, {
            ...logData,
            date_created: timestamp,
        } as Log);

        await this.addImpacts(projectId, logId, impacts);
        await this.addRequirements(projectId, logId, requirements);
        await this.addMitigationStrategies(projectId, logId, mitigation_strategies);

        return logId;
    }

    async updateLog(projectId: string, logId: string, log: Log, userId: string): Promise<void> {
        const {
            impacts,
            requirements,
            mitigation_strategies,
            ...logData } = log;
        const timestamp = this.firestoreService.timestamp;

        await this.firestoreService.update(`/logs/${projectId}/nodes/${logId}`, {
            log_severity: this.firestoreService.deleteField,
        });

        await this.deleteLogCollection(projectId, logId, 'impacts', userId);
        await this.deleteLogCollection(projectId, logId, 'requirements', userId);
        await this.deleteLogCollection(projectId, logId, 'mitigation_strategies', userId);

        await this.firestoreService.upsert(`/logs/${projectId}/nodes/${logId}`, {
            ...logData,
            date_updated: timestamp,
        });

        await this.addImpacts(projectId, logId, impacts);
        await this.addMitigationStrategies(projectId, logId, mitigation_strategies);

        if (!requirements.length) {
            await this.updateRequirementsMaps(projectId, logId);
            await this.updateLogDateLastUpdated(projectId, logId);
        } else {
            await this.addRequirements(projectId, logId, requirements);
        }
    }

    getLogImpacts(projectId: string, logId: string): Observable<LogImpact[]> {
        return this.firestoreService
            .getCollection<LogImpact>(`/logs/${projectId}/nodes/${logId}/impacts`, 'sequence')
            .pipe(this.sortByOwner());
    }

    getLogRequirements(projectId: string, logId: string): Observable<LogRequirement[]> {
        return this.firestoreService
            .getCollection<LogRequirement>(`/logs/${projectId}/nodes/${logId}/requirements`, 'sequence')
            .pipe(this.sortByOwner());
    }

    getLogMitigationStrategies(projectId: string, logId: string): Observable<MitigationStrategy[]> {
        return this.firestoreService
            .getCollection<MitigationStrategy>(`/logs/${projectId}/nodes/${logId}/mitigation_strategies`, 'sequence')
            .pipe(this.sortByOwner());
    }

    getFormLevelLogs(projectId: string, activityId: string): Observable<Log[]> {
        const formActivityLogs$ = this.getActivityLogs(projectId, activityId);
        const presentActivitiesLogs$ = this.firestoreService
            .getDocumentsByProperty<Log>(`/logs/${projectId}/nodes`, `requirements_activity_map.${activityId}`, true)
            .pipe(map((logs) => logs.filter((log) => log.activity_id !== activityId)));
        return combineLatest([formActivityLogs$, presentActivitiesLogs$]).pipe(
            map(([formActivityLogs, presentActivitiesLogs]) => [].concat(formActivityLogs, presentActivitiesLogs))
        );
    }

    hasFormLevelLogs(projectId: string, activityId: string): Observable<boolean> {
        const formActivityLogs$ = this.getActivityLogs(projectId, activityId);
        const presentActivitiesLogs$ = this.firestoreService.getDocumentsByProperty<Log>(
            `/logs/${projectId}/nodes`,
            `requirements_activity_map.${activityId}`,
            true
        );
        return combineLatest([formActivityLogs$, presentActivitiesLogs$]).pipe(
            map(([formActivityLogs, presentActivitiesLogs]) => !!(formActivityLogs.length + presentActivitiesLogs.length)),
            distinctUntilChanged()
        );
    }

    async deleteLog(projectId: string, logId: string, userId: string): Promise<void> {
        const path = `/logs/${projectId}/nodes/${logId}`;
        const deleteFn = this.afFun.httpsCallable('firestoreDocRecursiveDelete');
        try {
            return deleteFn({ path, projectId, userId }).pipe(take(1)).toPromise();
        } catch (err) {
            console.log('Error while deleting log!');
            console.error({ err });
        }
    }

    async deleteAllLogsByProjectId(projectId: string, userId: string): Promise<void> {
        const path = `/logs/${projectId}`;
        const deleteFn = this.afFun.httpsCallable('firestoreDocRecursiveDelete');
        try {
            return deleteFn({ path, projectId, userId }).pipe(take(1)).toPromise();
        } catch (err) {
            console.log('Error while deleting logs!');
            console.error({ err });
        }
    }

    async deleteLogCollection(projectId: string, logId: string, collectionName: string, userId: string): Promise<void> {
        const path = `/logs/${projectId}/nodes/${logId}/${collectionName}`;
        const deleteFn = this.afFun.httpsCallable('firestoreDocRecursiveDelete');
        try {
            return deleteFn({ path, projectId, userId }).pipe(take(1)).toPromise();
        } catch (err) {
            console.log('Error while deleting log!');
            console.error({ err });
        }
    }

    getLogWithSubCollections(projectId: string, logId: string): Observable<EditableLog> {
        const log$ = this.getLog(projectId, logId);
        const impacts$ = this.getLogImpacts(projectId, logId);
        const requirements$ = this.getLogRequirements(projectId, logId);
        const mitigationStrategies$ = this.getLogMitigationStrategies(projectId, logId);
        return combineLatest(log$, impacts$, requirements$, mitigationStrategies$).pipe(
            map(([log, impacts, requirements, mitigationStrategies]) => {
                return {
                    log,
                    impacts,
                    requirements,
                    mitigationStrategies,
                };
            })
        );
    }

    async toggleUpvote(projectId: string, logId: string, userId: string): Promise<void> {
        const logRef = this.firestoreService.getDocumentRef<Log>(`/logs/${projectId}/nodes/${logId}`);
        await this.firestoreService.runTransaction<Log>(async (transaction) => {
            const { upvotes } = (await transaction.get(logRef)).data();
            const upvotesMap = upvotes || {};
            const hasCurrentUserVote = upvotesMap.hasOwnProperty(userId);

            if (hasCurrentUserVote) {
                delete upvotesMap[userId];
            } else {
                upvotesMap[userId] = true;
            }

            const upvotesCount = Object.keys(upvotesMap).length;

            return transaction.update(logRef, { upvotes: upvotesMap }).update(logRef, { upvotes_count: upvotesCount });
        });
    }

    async toggleTopX(projectId: string, logId: string, userId: string): Promise<void> {
        const logRef = this.firestoreService.getDocumentRef<Log>(`/logs/${projectId}/nodes/${logId}`);
        await this.firestoreService.runTransaction<Log>(async (transaction) => {
            const { top_x } = (await transaction.get(logRef)).data();
            const topXMap = top_x || {};
            const hasCurrentUserTopX = topXMap.hasOwnProperty(userId);

            if (hasCurrentUserTopX) {
                delete topXMap[userId];
            } else {
                topXMap[userId] = true;
            }

            const topXCount = Object.keys(topXMap).length;

            return transaction.update(logRef, { top_x: topXMap }).update(logRef, { top_x_count: topXCount });
        });
    }

    async setLogAsReviewd(projectId: string, logId: string): Promise<void> {
        await this.firestoreService.update(`/logs/${projectId}/nodes/${logId}`, {
            is_new: this.firestoreService.deleteField,
        });
    }

    async updateImpact(
        projectId: string,
        logId: string,
        {
            id,
            value,
            severity,
            frequency,
            process,
            stakeholder,
            business_unit,
            country,
            change_mitigation_suggestion
        } : LogImpact): Promise<void> {
        const impactsRef = this.firestoreService.getDocumentRef<LogImpact>(`/logs/${projectId}/nodes/${logId}/impacts/${id}`);

        await impactsRef.update({
            value,
            severity,
            frequency,
            process,
            stakeholder,
            business_unit,
            country,
            change_mitigation_suggestion,
        });

        await this.updateLogSeverity(projectId, logId);
        await this.updateLogDateLastUpdated(projectId, logId);
    }

    async updateRequirements(
        projectId: string,
        logId: string,
        requirements: LogRequirement[],
        startSequence?: number,
        removedRequirementId?: string
    ): Promise<void> {
        if (removedRequirementId || requirements.length) {
            const batch = this.firestoreService.createBatch();
            const requirementsRef = this.firestoreService.getCollectionRef<LogRequirement>(
                `/logs/${projectId}/nodes/${logId}/requirements`
            );
            const sequence = startSequence || 0;

            requirements.forEach((requirement, i) => {
                requirement.sequence = sequence + i;
                batch.set(requirementsRef.doc(), requirement);
            });

            if (removedRequirementId) {
                const removedRequirementDoc = requirementsRef.doc(removedRequirementId);
                batch.delete(removedRequirementDoc);
            }

            await batch.commit();
            await this.updateRequirementsMaps(projectId, logId);
            await this.updateLogDateLastUpdated(projectId, logId);
        }
    }

    async addImpacts(projectId: string, logId: string, impacts: LogImpact[], startSequence?: number): Promise<void> {
        if (impacts.length) {
            const batch = this.firestoreService.createBatch();
            const impactsRef = this.firestoreService.getCollectionRef<LogImpact>(`/logs/${projectId}/nodes/${logId}/impacts`);
            const sequence = startSequence || 0;

            impacts.forEach((impact, i) => {
                impact.sequence = sequence + i;
                batch.set(impactsRef.doc(), impact);
            });

            await batch.commit();
            await this.updateLogSeverity(projectId, logId, impacts);
            await this.updateLogDateLastUpdated(projectId, logId);
        }
    }

    async addRequirements(projectId: string, logId: string, requirements: LogRequirement[], startSequence?: number): Promise<void> {
        if (requirements.length) {
            const batch = this.firestoreService.createBatch();
            const requirementsRef = this.firestoreService.getCollectionRef<LogRequirement>(
                `/logs/${projectId}/nodes/${logId}/requirements`
            );
            const sequence = startSequence || 0;

            requirements.forEach((requirement, i) => {
                requirement.sequence = sequence + i;
                batch.set(requirementsRef.doc(), requirement);
            });

            await batch.commit();
            await this.updateRequirementsMaps(projectId, logId);
            await this.updateLogDateLastUpdated(projectId, logId);
        }
    }

    async addMitigationStrategies(
        projectId: string,
        logId: string,
        mitigationStrategies: MitigationStrategy[],
        startSequence?: number
    ): Promise<void> {
        if (mitigationStrategies.length) {
            const batch = this.firestoreService.createBatch();
            const mitigationStrategyRef = this.firestoreService.getCollectionRef<MitigationStrategy>(
                `/logs/${projectId}/nodes/${logId}/mitigation_strategies`
            );
            const sequence = startSequence || 0;

            mitigationStrategies.forEach((strategy, i) => {
                strategy.sequence = sequence + i;
                batch.set(mitigationStrategyRef.doc(), strategy);
            });

            await batch.commit();
            await this.updateLogDateLastUpdated(projectId, logId);
        }
    }

    async updateRequirementsMaps(projectId: string, logId: string): Promise<void> {
        const requirements = await this.firestoreService
            .getCollection<LogRequirement>(`/logs/${projectId}/nodes/${logId}/requirements`)
            .pipe(take(1))
            .toPromise();
        const requirements_map = {};
        const requirements_activity_map = {};

        requirements.forEach((requirement) => {
            requirements_map[requirement.question_id] = requirement.activity_id;
            requirements_activity_map[requirement.activity_id] = true;
        });

        await this.firestoreService.updateDoc(`/logs/${projectId}/nodes/${logId}`, {
            requirements_map,
            requirements_activity_map,
        });
    }

    async updateLogSeverity(projectId: string, logId: string, impactsList?: LogImpact[]): Promise<void> {
        const impacts = await this.firestoreService
            .getCollection<LogImpact>(`/logs/${projectId}/nodes/${logId}/impacts`)
            .pipe(take(1))
            .toPromise();

        const maxSeverity = impacts.reduce((accSeverity, impact) => {
            const impactSeverity = impact.severity;
            if (!accSeverity && !!impactSeverity) {
                return impactSeverity;
            }
            if (!!accSeverity && !!impact.severity) {
                return SeverityData[impactSeverity].sequence > SeverityData[accSeverity].sequence ? impactSeverity : accSeverity;
            }
            return accSeverity;
        }, '');

        const maxFrequency = impacts.reduce((accFrequency, impact) => {
            const impactFrequency = impact.frequency;
            if (!accFrequency && !!impactFrequency) {
                return impactFrequency;
            }
            if (!!accFrequency && !!impact.frequency) {
                return FrequencyData[impactFrequency].sequence > FrequencyData[accFrequency].sequence ? impactFrequency : accFrequency;
            }
            return accFrequency;
        }, '');

        const process = {};
        const stakeholder = {};
        const businessUnit = {};
        const country = {};
        const changeMitigationSuggestion = {};

        impacts.forEach(impact => {
            if (impact.stakeholder) {
                stakeholder[impact.stakeholder] = true;
            }

            if (impact.business_unit) {
                businessUnit[impact.business_unit] = true;
            }

            if (impact.country) {
                country[impact.country] = true;
            }

            impact.process?.forEach(optionId => {
                if (optionId) {
                    process[optionId] = true;
                }
            })

            impact.change_mitigation_suggestion?.forEach(optionId => {
                if (optionId) {
                    changeMitigationSuggestion[optionId] = true;
                }
            })
        })

        await this.firestoreService.updateDoc(`/logs/${projectId}/nodes/${logId}`, {
            log_severity: maxSeverity,
            log_frequency: maxFrequency,
            processes_map: process,
            stakeholders_map: stakeholder,
            business_units_map: businessUnit,
            countries_map: country,
            change_mitigation_suggestions_map: changeMitigationSuggestion,
        });
    }

    async removeRequirement(projectId: string, logId: string, requirementId: string): Promise<void> {
        await this.removeSubCollectionDocument(projectId, logId, requirementId, 'requirements');
        await this.updateRequirementsMaps(projectId, logId);
        await this.updateLogDateLastUpdated(projectId, logId);
    }

    async removeImpact(projectId: string, logId: string, impactId: string): Promise<void> {
        await this.removeSubCollectionDocument(projectId, logId, impactId, 'impacts');
        await this.updateLogSeverity(projectId, logId);
        await this.updateLogDateLastUpdated(projectId, logId);
    }

    async removeMitigationStrategy(projectId: string, logId: string, mitigationStrategyId: string): Promise<void> {
        await this.removeSubCollectionDocument(projectId, logId, mitigationStrategyId, 'mitigation_strategies');
        await this.updateLogDateLastUpdated(projectId, logId);
    }

    async updateMitigationStrategy(projectId: string, logId: string, mitigationStrategyId: string, value: string): Promise<void> {
        await this.firestoreService.upsert(`/logs/${projectId}/nodes/${logId}/mitigation_strategies/${mitigationStrategyId}`, { value });
        await this.updateLogDateLastUpdated(projectId, logId);
    }

    private removeSubCollectionDocument(projectId: string, logId: string, documentId: string, documentType: string): Promise<void> {
        return this.firestoreService.delete(`/logs/${projectId}/nodes/${logId}/${documentType}/${documentId}`);
    }

    private async updateLogDateLastUpdated(projectId: string, logId: string): Promise<void> {
        const timestamp = this.firestoreService.timestamp;
        await this.firestoreService.upsert(`/logs/${projectId}/nodes/${logId}`, {
            date_updated: timestamp,
        });
    }

    private mapImpactsToLogs(projectId: string) {
        return switchMap((logs: Log[]) => {
            return combineLatest(
                logs.map((log) => {
                    return this.getLogImpacts(projectId, log.id).pipe(
                        map((impacts) => {
                            return { ...log, impacts };
                        })
                    );
                })
            );
        });
    }

    private mapLogToCountryArray(logs: Log[]): string[] {
        const countriesArray = [] as string[];
        logs
            .filter(log => log.countries_map)
            .forEach(log => {
                for (const country in log.countries_map) {
                    if (!countriesArray.includes(country)) {
                        countriesArray.push(country);
                    }
                }
            });

        return countriesArray;
    }

    private sortByOwner<T>() {
        return map((items: T[]) => {
            const groups = groupBy(items, 'owner_id');
            return Object.keys(groups).reduce((acc, nextId) => {
                acc.push(...groups[nextId]);
                return acc;
            }, []);
        });
    }
}
