import { createAction, props } from '@ngrx/store';

import { Demographic } from '@app/core/models';

const getDemographicsType = '[Demographics] get demographics';
const getDemographicsSuccessType = '[Demographics] get demographics success';
const getDemographicsFailType = '[Demographics] get demographics fail';

export const getDemographics = createAction(
    getDemographicsType,
    props<{ demographicsClassKey: string }>()
);

export const getDemographicsSuccess = createAction(
    getDemographicsSuccessType,
    props<{ demographicsClassKey: string, demographics: Demographic[] }>()
);

export const getDemographicsFail = createAction(
    getDemographicsFailType,
    props<{ errorMessage: string }>()
);
