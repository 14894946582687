import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, skipWhile, tap } from 'rxjs/operators';

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';

@Injectable()
export class LoginGuard implements CanActivate {
    constructor(private router: Router, private authenticatedUserService: AuthenticatedUserService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.authenticatedUserService.isUserAuthenticated().pipe(
            skipWhile(isUserAuthenticated => isUserAuthenticated === undefined),
            tap(isUserAuthenticated => {
                if (isUserAuthenticated) {
                    this.router.navigate(['/navigation']);
                }
            }),
            map(isUserAuthenticated => {
                if (!isUserAuthenticated) {
                    return true;
                }
            }),
        );
    }
}
