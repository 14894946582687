<div class="time-picker">
  <mat-form-field class="input-wrap">
    <input
      matInput
      type="number"
      min="0"
      max="99"
      required
      [disabled]="disabled"
      [(ngModel)]="input.min"
      (ngModelChange)="valueChanges()"
      (blur)="blurAction && blurAction(valueChanges())"
      (keyup.enter)="keyEnterAction && keyEnterAction(valueChanges())"
    />
  </mat-form-field>
  <span>:</span>
  <mat-form-field class="input-wrap">
    <input
      matInput
      type="number"
      min="0"
      max="60"
      required
      [disabled]="disabled"
      [(ngModel)]="input.sec"
      (ngModelChange)="valueChanges()"
      (blur)="blurAction && blurAction(valueChanges())"
      (keyup.enter)="keyEnterAction && keyEnterAction(valueChanges())"
    />
  </mat-form-field>
</div>
