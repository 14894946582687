import { AllSelectedDemographics } from '@app/core/models/demographic.model';

export const leaderRoles = ['leader', 'launch-only', 'owner', 'collaborator'];
export type userRole =
    | 'observer'
    | 'participant'
    | 'leader'
    | 'launch-only'
    | 'owner'
    | 'stakeholder'
    | 'collaborator'
    | 'deployment_manager';
export type Role = 'admin' | 'workbook';
export type userStatus = 'Active' | 'Inactive' | 'Deactivated';

export enum UserStatus {
    active = 'Active',
    inactive = 'Inactive',
    deactivated = 'Deactivated',
}

export class User {
    Navigation?: {
        [navigationKey: string]: boolean;
    };

    app_instances?: {
        [userKey: string]: userRole;
    };

    apps?: {
        [userKey: string]: userRole;
    };

    deployments?: Map<string, userRole>;
    created_at?: number;
    email: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    key?: string;
    online?: {
        [sessionKey: string]: true;
    };

    disabled?: boolean;
    phoneNumber?: string;
    firebaseAuthenticationId?: string;
    authenticationId?: string;
    image_url?: string;
    permission?: {
        [role in Role]?: true;
    };

    role?: UserAccess;
    active?: boolean;
    guest?: true;
    disable_email_notifications?: boolean;
    workbooks_connecting_in_process?: boolean;
    selectedDemographics?: AllSelectedDemographics;
    apps_role?: UserManagementRoles;
    last_status_change?: number;
    status?: string; // user status is calculating during getting data by method getRegularUsersList() in UsersService
    app_role?: UserSessionRole | UserTemplateRole;
    last_login?: number;

    static getUserName(user: User, shortName?: boolean): string {
        let userName: string;

        if (!user) {
            return;
        }

        if (shortName) {
            userName =
                user.firstName && user.lastName
                    ? `${user.firstName[0]}${user.lastName[0]}`
                    : (user.username || user.email).substring(0, 2); // if no first or last names get first 2 symbols of username
        } else {
            userName =
                user.firstName && user.lastName
                    ? `${user.firstName} ${user.lastName}`
                    : `${user.username || user.email}`;
        }

        return userName;
    }

    static getStatus(user: User): string {
        const userActive = user.active ? UserStatus.active : UserStatus.inactive;

        return user.disabled ? UserStatus.deactivated : userActive;
    }
}

export enum UserAccess {
    admin = 'Admin',
    basic = 'Basic',
}

export const testUser = <User>{
    Navigation: {
        'App Factory': true,
        'My Collections': true,
        'My ThinkTank': true,
    },
    app_instances: {
        instance1: 'leader',
        instance2: 'participant',
    },
    apps: {
        app1: 'leader',
        app2: 'observer',
    },
    created_at: 1510686478630,
    email: 'bartsimpson@gmail.com',
    username: 'bartsimpson@gmail.com',
    firstName: 'Bart',
    lastName: 'Simpson',
    key: 'userKey',
    online: {
        sessionKey1: true,
        sessionKey2: true,
    },
};

export const testAdminUser = <User>{
    Navigation: {
        'App Factory': true,
        'My Collections': true,
        'My ThinkTank': true,
    },
    app_instances: {
        instance1: 'leader',
        instance2: 'participant',
    },
    apps: {
        app1: 'leader',
        app2: 'observer',
    },
    created_at: 1510686478630,
    email: 'bartsimpson@gmail.com',
    username: 'bartsimpson@gmail.com',
    firstName: 'Bart',
    lastName: 'Simpson',
    key: 'adminUserKey',
    online: {
        sessionKey1: true,
        sessionKey2: true,
    },
    permission: {
        admin: true,
    },
};

export const testUsersMap = userKey => ({
    [`${userKey}`]: {
        firstName: 'John',
        lastName: 'Dou',
        email: 'john.dou@gmail.com',
    },
});

export enum UserAppRole {
    Leader = 'leader',
    Participant = 'participant',
    Observer = 'observer',
}

export enum UserManagementRoles {
    Leader = 'Leader',
    Participant = 'Participant',
}

export enum UserSessionRole {
    Leader = 'Leader',
    Participant = 'Participant',
    Observer = 'Observer',
}

export enum UserTemplateRole {
    Owner = 'Owner',
    Collaborator = 'Collaborator',
    SessionCreator = 'Session creator',
}

export enum AdminTabsNames {
    users = 'users',
    templates = 'templates',
    sessions = 'sessions',
}
