import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';

import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { DownloadUrlInfo } from '../models/download-url-info.model';

interface UrlsMap {
    [url: string]: string;
}

@Injectable({
    providedIn: 'root'
})
export class GoogleStorageService {

    constructor(private _functions: AngularFireFunctions) { }

    async validateUrls(filesInfo: DownloadUrlInfo[]): Promise<UrlsMap> {
        return this._functions.httpsCallable('getStorageSignedUrls')({ filesInfo }).pipe(take(1)).toPromise();
    }
}
