import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-contributors-list-dialog',
    templateUrl: './contributors-list-dialog.component.html',
    styleUrls: ['./contributors-list-dialog.component.scss']
})
export class ContributorsListDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {}
}
