export class FullscreenApi {
    enabled: string;
    element: string;
    request: string;
    exit: string;
    events: {
        change: string;
        error: string;
    };
}

export const testFullscreenApi = {
    enabled: 'fullscreenEnabled',
    element: 'fullscreenElement',
    request: 'requestFullscreen',
    exit: 'exitFullscreen',
    events: {
        change: 'fullscreenchange',
        error: 'fullscreenerror'
    }
};
