import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';

import { Category, Group, Response } from '@app/core/models';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';

@Injectable()
export class FirebaseCategoryService extends FirebaseUtilityService {

    constructor(
        private db: AngularFireDatabase,
        private authenticatedUserService: AuthenticatedUserService
    ) {
        super();
    }

    removeActivityGroup(activityKey: string, groupKey: string): void {
        // TODO: remove all from questions
        this.db.object<Group>(`/activities/${activityKey}/groups/${groupKey}`).remove();
    }

    updateGroupField(activityKey: string, groupKey: string, field: string, value: string): void {
        this.db.object<Group>(`/activities/${activityKey}/groups/${groupKey}`).update(<any>{
            [field]: value
        });
    }

    addCategoryByKey(activityKey: string, category: any): void {
        this.db.list<Category>(`/categories/${activityKey}`).push(<Category>{
            ...category,
            owner: this.authenticatedUserService.getCurrentUserId(),
            number_responses: 0,
            responses: []
        });
    }

    getCategoryByKey(activityKey: string): AngularFireList<Category> {
        return this.db.list<Category>(`/categories/${activityKey}`);
    }

    getGroupsByActivityKey(activityKey: string): Observable<Category[]> {
        return this.listWithKeys(this.db.list(`/categories/${activityKey}`));
    }

    updateCategoryField(activityKey: string, categoryKey: string, field: string, value: string): void {
        this.db.object<Category>(`/categories/${activityKey}/${categoryKey}`).update(<any>{
            [field]: value
        });
    }

    updateCategories(activityKey: string, groups: Object): void {
        this.db.object(`/categories/${activityKey}`).update(groups);
    }

    getCategoryResponsesStates(activityKey: string, categoryKey: string): Observable<boolean[]> {
        return this.db
            .list<boolean>(`/categories/${activityKey}/${categoryKey}/responses`)
            .valueChanges();
    }

    async updateResponseCategoryByKey(
        responseKey: string, questionKey: string, categoryKey: string,
        activityKey: string, previousCategoryKey: string
    ): Promise<void> {
        const updates = {};

        // update selected category responses
        updates[`responses/${questionKey}/${responseKey}/category`] = categoryKey ? categoryKey : null;
        updates[`ssot/_responses/${responseKey}/category`] = categoryKey ? categoryKey : null;
        updates[`categories/${activityKey}/${categoryKey}/responses/${responseKey}`] = categoryKey ? true : null;

        // remove response from previous category if exists
        if (previousCategoryKey) {
            updates[`categories/${activityKey}/${previousCategoryKey}/responses/${responseKey}`] = null;
        }

        await this.db.object('/').update(updates);

        // recalculate number of responses on selected category
        if (categoryKey) {
            const responses = await this.getCategoryResponsesStates(activityKey, categoryKey)
                .pipe(take(1))
                .toPromise();
            const responsesNumber = (responses || []).length;
            await this.db.object(`/categories/${activityKey}/${categoryKey}`).update({
                number_responses: responsesNumber
            });
        }

        // recalculate number of responses on previous category if exists
        if (previousCategoryKey) {
            const previousCategoryResponses = await this.getCategoryResponsesStates(activityKey, previousCategoryKey)
                .pipe(take(1))
                .toPromise();
            const previousCategoryResponsesNumber = (previousCategoryResponses || []).length;
            await this.db.object(`/categories/${activityKey}/${previousCategoryKey}`).update({
                number_responses: previousCategoryResponsesNumber
            });
        }
    }

    removeCategory(activityKey: string, categoryKey: string): Promise<any> {
        return this.db.object(`/categories/${activityKey}/${categoryKey}`).remove();
    }

    getResponsesWithoutCategory(questionKey: string, activityKey: string): Observable<number> {
        const responses$ = this.listWithKeys(
            this.db.list<Response>(`ssot/_responses`, (ref) => ref.orderByChild('question_id').equalTo(questionKey))
        );
        return responses$.pipe(map(responses => {
            return (responses || [])
                .filter(response => {
                    return response.type === 'response' && response.activity_id === activityKey && !response.category;
                })
                .length;
        }));
    }

}
