import { InjectionToken } from '@angular/core';
import { SubprocessViewState, Ownership } from '../../models';
import { Observable } from 'rxjs';

export interface SubprocessViewFacade {
    vm$: Observable<SubprocessViewState>;

    routeToDeployment(deploymentId: string): void;

    routeToAssignment(deploymentId: string, subprocessId: string): void;

    unassignUser(ownershipToRemove: Ownership, deploymentId: string, subprocessId: string): void;
}

export const SubprocessViewFacadeToken = new InjectionToken<SubprocessViewFacade>('SubprocessViewFacade');
