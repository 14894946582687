import { AbstractControl, FormGroup } from '@angular/forms';
import { Demographic, GlobalDemographic, FormItem } from '@thinktank/common-lib';
import { FormGroupHandler } from './form-group-handler';
import { QuestionFormControlFactory } from './question-form-control.factory';

export class GlobalQuestionFormGroupHandler implements FormGroupHandler {

    constructor(private questionFormControlFactory: QuestionFormControlFactory) {
    }

    build(item: FormItem, assignedDemographics: Demographic[]): AbstractControl {
        return this.questionFormControlFactory
            .build(item.type)
            .build(item, GlobalDemographic.id);
    }

    update(formGroup: FormGroup, item: FormItem, assignedDemographics: Demographic[]): void {
        const formControl = this.questionFormControlFactory.build(item.type);
        const control = formGroup.get(item.id);
        formControl.update(control, item, GlobalDemographic.id);
    }

}
