import { Subprocess } from './subprocess.model';
import { User } from './user.model';
import { Ownership } from './ownership.model';
import { Demographic, ChangeRequest } from '@thinktank/common-lib';

export interface SubprocessViewState {
    deploymentId: string;
    authenticatedUserId: string;
    subprocess: Subprocess;
    deploymentOwnership: Ownership[];
    changeRequests: ChangeRequest[];
    selectedDemographics: Demographic[];
    subprocessImpactData: any[];
    subprocessManager: User;
    isLoading: boolean;
}

export const initialSubprocessState = {
    deploymentId: '',
    authenticatedUserId: '',
    subprocess: <Subprocess>{},
    deploymentOwnership: [],
    selectedDemographics: [],
    changeRequests: [],
    subprocessImpactData: [],
    subprocessManager: <User>{},
    isLoading: true
}
