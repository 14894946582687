import { Directive, ElementRef, Input, Renderer2, OnInit } from '@angular/core';

@Directive({
    selector: '[appBackgroundImages]',
})
export class BackgroundImagesDirective implements OnInit {
    @Input() imagesList = [];

    constructor(private elementRef: ElementRef, private renderer: Renderer2) {}

    ngOnInit() {
        let imageIndex;
        let currentImage = localStorage.getItem('currentBgImage');

        if (!currentImage) {
            imageIndex = 0;
            localStorage.setItem('currentBgImage', this.imagesList[imageIndex]);
            currentImage = this.imagesList[0];
        }

        const currentImagePath = `assets/images/backgrounds/${currentImage}`;

        this.renderer.setStyle(this.elementRef.nativeElement, 'background-image', `url(${currentImagePath})`);

        currentImage = this.imagesList[this.imagesList.indexOf(currentImage) + 1] || this.imagesList[0];

        localStorage.setItem('currentBgImage', currentImage);
    }
}
