import { ObjectType } from './deployment.model';
import { UserRole } from './user.model';

export interface Ownership {
    user_id: string;
    user_name?: string;
    email_address?: string;
    first_name: string;
    last_name: string;
    object_id: string;
    object_type: ObjectType;
    role: UserRole;
    demographic_id?: string;
    demographic_name?: string;
    primary?: boolean;
    id?: string;
}
