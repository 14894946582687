import { Injectable } from '@angular/core';

import { AlignmentStateManageable, AlignmentState } from '@app/core/models/alignment-state.model';
import { DraftState } from '@app/apps/activities/forms/alignment/states/draft-state';
import { DraftReviewState } from '@app/apps/activities/forms/alignment/states/draft-review-state';
import { AlignedState } from '@app/apps/activities/forms/alignment/states/aligned-state';
import { PendingApprovalState } from '@app/apps/activities/forms/alignment/states/pending-approval-state';
import { ApprovedState } from '@app/apps/activities/forms/alignment/states/approved-state';
import { FormStatus } from '@app/core/models/form-status.model';

@Injectable({
    providedIn: 'root'
})
export class AlignmentStateManageableFactoryService {

    constructor() {
    }

    build(status: AlignmentState | FormStatus): AlignmentStateManageable {
        switch (status) {
            case FormStatus.Draft: return new DraftState();
            case FormStatus.DraftReview: return new DraftReviewState();
            case FormStatus.Alignment: return new AlignedState();
            case FormStatus.PendingApproval: return new PendingApprovalState();
            case FormStatus.Approved: return new ApprovedState();
            default: throw new Error('Invalid alignment state');
        }
    }
}
