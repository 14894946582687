import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { removeEmptyKeys } from '@app/core/utils';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { Disagreement } from '@app/core/models';

@Injectable()
export class FormDisagreementService extends FirebaseUtilityService {

    constructor(
        private db: AngularFireDatabase,
        private authenticatedUserService: AuthenticatedUserService,
    ) {
        super();
    }

    storeDisagreement(
        comment: string,
        activityKey: string,
        formKey: string,
        questionKey: string,
        responseValue: any,
        demographicKey?: string,
        parentDemographicKey?: string,
        parentFormKey?: string
    ): Promise<void> {

        const disagreementKey = this.db.createPushId();
        const newDisagreement = this.createDisagreement(comment, activityKey, formKey, questionKey, responseValue);

        const updates = {};

        let disagreementToStore = {
            ...newDisagreement,
            demographic_id: demographicKey,
            parent_demographic_id: parentDemographicKey,
            parent_form_id: parentFormKey
        };

        disagreementToStore = removeEmptyKeys(disagreementToStore);

        updates[`ssot/_disagreements/${disagreementKey}`] = disagreementToStore;

        return this.db.object('/').update(updates);
    }

    getDisagreementsByFormId(formId: string): Observable<Disagreement[]> {
        return this.listWithKeys(this.db.list<Disagreement>(
            '/ssot/_disagreements', ref => ref.orderByChild('form_id').equalTo(formId)
        ));
    }

    getDisagreementsByQuestionId(questionId: string): Observable<Disagreement[]> {
        return this.listWithKeys(this.db.list<Disagreement>(
            '/ssot/_disagreements', ref => ref.orderByChild('question_id').equalTo(questionId)
        ));
    }

    private createDisagreement(
        comment: string,
        activityKey: string,
        formKey: string,
        questionKey: string,
        responseValue: any
    ): Disagreement {
        const userKey = this.authenticatedUserService.getCurrentUserId();

        return (new Disagreement(comment, userKey, activityKey, formKey, questionKey, responseValue));
    }

    removeDisagreement(disagreements: Disagreement[]): Promise<void> {
        const updates = {};
        disagreements.forEach((disagreement: Disagreement) => {
            updates[`ssot/_disagreements/${disagreement.key}`] = null;
        });
        return this.db.object(`/`).update(updates);
    }
}
