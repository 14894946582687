import { Params, RouterStateSnapshot } from '@angular/router';
import { RouterStateSerializer, RouterReducerState } from '@ngrx/router-store';

export interface State {
    router: RouterReducerState<RouterStateUrl>;
}

export interface RouterStateUrl {
    url: string;
    queryParams: Params;
    params: Params;
    data: any;
}

export class CustomSerializer implements RouterStateSerializer<RouterStateUrl> {
    serialize(routerState: RouterStateSnapshot): RouterStateUrl {
        let route = routerState.root;

        while (route.firstChild) {
            route = route.firstChild;
        }

        const { url, root: { queryParams } } = routerState;
        const { params, data } = route;

        return { url, queryParams, params, data };
    }
}
