import { Component, OnInit } from '@angular/core';
import { ComponentType } from '@angular/cdk/portal';

import { PopoverRef } from './popover-ref';

@Component({
    selector: 'app-popover',
    templateUrl: './popover.component.html',
    styleUrls: ['./popover.component.scss']
})
export class PopoverComponent<T, D = any> implements OnInit {
    content: ComponentType<T>;

    constructor(
        private _popoverRef: PopoverRef<T, D>
    ) {}

    ngOnInit() {
        this.content = this._popoverRef.content;
    }
}
