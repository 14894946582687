<ng-container *ngIf="{ value: text$ | async } as text">
  <div class="restore-dialog">
    <h3 mat-dialog-title class="h3">{{data.title}}</h3>
    <mat-dialog-content class="restore-dialog-content">
      <p *ngIf="!!text.value" class="text" [innerHTML]="text.value"></p>
      <div *ngIf="!text.value" class="spinner-container">
        <mat-spinner [diameter]="48"></mat-spinner>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions class="restore-dialog-actions">
      <button 
        cdkFocusInitial
        class="tt-button-filled"
        [disabled]="!text.value"
        [ngClass]="{'danger': isRemove}" 
        (click)="handleConfirm()"
      >
        {{data.confirmBtnText}}
      </button>
      <button class="tt-button-outline" (click)="handleCancel()">
        {{data.cancelBtnText}}
      </button>
    </mat-dialog-actions>
  </div>
</ng-container>
