import {
    Component,
    ChangeDetectionStrategy,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges,
    OnInit
} from '@angular/core';
import { groupBy } from 'lodash';

import { Ownership } from '../../../models';
import { trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-view-assignees',
    templateUrl: './view-assignees.component.html',
    styleUrls: ['./view-assignees.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewAssigneesComponent implements OnChanges, OnInit {
    @Input() deploymentOwnership: Ownership[];
    @Input() useDemographics: boolean;
    @Input() selectedTab: number;
    @Input() viewType: string;
    @Output() assignUsers = new EventEmitter<void>();
    @Output() unassignUser = new EventEmitter<Ownership>();

    groupedByDemographic: any[];
    groupedByUsers: any[];
    trackById = trackById;
    currentTab = 0;
    assigneeDetailsVisible = false;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        if (!!this.deploymentOwnership) {
            const groupedByDemographicOwnership = groupBy(this.deploymentOwnership, 'demographic_name');
            this.groupedByDemographic = Object.keys(groupedByDemographicOwnership).map(demographic => ({
                demographic: demographic === 'undefined' ? null : demographic,
                rows: groupedByDemographicOwnership[demographic]
            }));

            const groupedByUsersOwnership = groupBy(this.deploymentOwnership, 'email_address');
            this.groupedByUsers = Object.keys(groupedByUsersOwnership).map(email => ({
                email,
                name: groupedByUsersOwnership[email][0].user_name,
                rows: groupedByUsersOwnership[email]
            }));
        }
    }

    ngOnInit(): void {
        this.currentTab = this.selectedTab || 0;
    }

    get users(): any[] {
        return this.groupedByUsers.map(userObject => userObject.rows[0]);
    }

    get tableData(): any[] {
        return !this.currentTab && this.useDemographics
            ? this.groupedByDemographic
            : this.groupedByUsers;
    }

    changeTab(requestedTab: number): void {
        this.currentTab = requestedTab;
    }

    toggleDetails(): void {
        this.assigneeDetailsVisible = !this.assigneeDetailsVisible;
    }
}
