<gs-response-history-card
  [currentUserId]="currentUserId"
  [currentUserFullName]="currentUserFullName"
  [response]="response"
  [comments]="historyResponseComments$ | async"
  [isCommentDisabled]="isCommentDisabled$.asObservable() | async"
  [shouldOpenResponseComments]="shouldOpenResponseComments"
  [isUserLeader]="isUserLeader"
  [isFocused]="isFocused"
  [canFocus]="canFocus"
  [hasDependentQuestions]="hasDependentQuestions"
  (onToggleUpvote)="toggleResponseUpvote()"
  (onUpdateComment)="updateComment($event)"
  (onDeleteComment)="openDeleteCommentDialog($event)"
  (onAddComment)="addComment($event)"
  (onToggleFocus)="toggleFocus($event)"
></gs-response-history-card>
