import { ChangeDetectionStrategy, Component, Inject, OnInit, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';

import { BaseQuestionComponent } from '../base-question/base-question.component';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';
import { ImageLightboxServiceInterface } from '@thinktank/common-lib';

@Component({
    selector: 'gs-media-question',
    templateUrl: './media-question.component.html',
    styleUrls: ['./media-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class MediaQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy, OnChanges {
    videoUrl: SafeResourceUrl;

    constructor(
        @Inject(DeploymentFormFacadeToken) formFacade: DeploymentFormFacade,
        private imageLightbox: ImageLightboxServiceInterface
    ) {
        super(formFacade);
    }

    enlargeImage(): void {
        if (!!this.question.image_url) {
            this.imageLightbox.open(this.question.image_url);
        }
    }

    ngOnInit(): void {
        this.onValueChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`MediaQuestionComponent::ngOnChanges - changes for ${this.question.id}`, changes);
        if (changes['question']) {
            if (this.question.type === 'video') {
                this.videoUrl = this.formFacade.transformVideoUrlToEmbed(this.question.video_url);
            }
        }
    }

    ngOnDestroy(): void {
        this.onDestroy();
    }

}
