<div class="export-info-dialog thinktank-theme">
  <div mat-dialog-title>
    <div mat-dialog-title class="dialog-title">
      <h3 class="h3">{{data.title}}</h3>
      <button mat-icon-button class="close" (click)="cancel()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content [formGroup]="renameForm">
    <p class="section-title text-dark">Please enter a new name for the file:</p>

    <mat-form-field
      class="large file-name"
      appearance="outline"
      floatLabel="always"
    >
      <input
        matInput
        appEmptyValue
        type="text"
        formControlName="name"
      />
      <mat-error *ngIf="renameForm.get('name').hasError('emptyValue')">
        {{ validationMessages.required }}
      </mat-error>
      <mat-error *ngIf="renameForm.get('name').hasError('nameAlreadyExists')">
        {{ validationMessages.nameAlreadyExists }}
      </mat-error>
      <mat-error *ngIf="renameForm.get('name').hasError('hasSpecialCharacter')">
        {{ validationMessages.specialCharacters }}
      </mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button
      cdkFocusInitial
      class="tt-button-filled confirm-button"
      [disabled]="renameForm.invalid"
      (mousedown)="rename()"
    >
      {{ data.confirmBtnText }}
      <app-spinner *ngIf="isPendingStatus" class="confirm-button-spinner"></app-spinner>
    </button>
    <button
      mat-dialog-close
      class="tt-button-outline"
      (click)="cancel()"
    >
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
