import { createAction, props } from '@ngrx/store';

import { FormSummaryData } from '@app/core/models/form-summary.model';

const getFormSummaryType = '[getFormSummaryType] form summary';
const getFormSummarySuccessType = '[getFormSummarySuccessType] form summary';
const clearFormSummaryType = '[clearFormSummary] form summary';

export const getFormSummary = createAction(
    getFormSummaryType,
    props<{ activityId: string }>()
);

export const getFormSummarySuccess = createAction(
    getFormSummarySuccessType,
    props<{ summary: FormSummaryData[] }>()
);

export const clearFormSummaryState = createAction(
    clearFormSummaryType,
    props<{ activityId?: string }>()
);
