import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './reducers';
import { FormValidationService } from './services';
import { FromValidationEffects } from './effects';

@NgModule({
    imports: [
        StoreModule.forFeature('formValidationErrors', reducer),
        EffectsModule.forFeature([FromValidationEffects])
    ],
    providers: [FormValidationService],
})
export class FormValidationErrorsStateModule { }
