import { Component, Inject, ViewChild, ElementRef, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { trackById, trackByValue } from '@thinktank/common-lib';

@Component({
    selector: 'gs-volunteer-dialog',
    templateUrl: './volunteer-dialog.component.html'
})
export class VolunteerDialogComponent implements AfterViewInit {
    @ViewChild('content', { static: true }) content: ElementRef;
    selectedDemographics: string[] = [];

    trackByValue = trackByValue;
    trackById = trackById;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<VolunteerDialogComponent>,
        private cd: ChangeDetectorRef
    ) { }

    get enableConfirm(): boolean {
        return this.data.isGlobal ||
            (!this.data.isGlobal && this.selectedDemographics.length > 0);
    }

    get isScrollContent(): boolean {
        if (!!this.content) {
            const content = this.content.nativeElement;
            return content.scrollHeight > content.offsetHeight;
        }

        return false;
    }

    isChecked(demographicId: string): boolean {
        return !!this.selectedDemographics && this.selectedDemographics.includes(demographicId);
    }

    getDemographicName(demographicId: string): string {
        if (!this.data.isGlobal) {
            const demographic = !!this.data
              && this.data.demographics.filter(item => item.id === demographicId)[0];
          return !!demographic ? demographic.name : '';
        }
        return '';
    }

    removeDemographic(demographicId: string): void {
        const index = this.selectedDemographics.indexOf(demographicId);
        if (index > -1) {
            this.selectedDemographics.splice(index, 1);
        }
    }

    selectDemographic(event: any, demographicId: string): void {

        if (!event.checked) {
            this.removeDemographic(demographicId);
            return;
        }

        this.selectedDemographics.push(demographicId);
    }

    handleConfirm(): void {
        const { handleConfirm } = this.data;
        handleConfirm(this.selectedDemographics);
        this.dialogRef.close();
    }

    handleCancel(): void {
        this.dialogRef.close();
    }

    // to avoid ExpressionChangedAfterItHasBeenCheckedError
    ngAfterViewInit() {
        this.cd.detectChanges();
    }
}
