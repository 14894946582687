import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFireAction,
    AngularFireDatabase,
    AngularFireObject,
    DatabaseSnapshot,
} from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import firebase from 'firebase/compat/app';
import { isEqual as _isEqual, unionWith as _unionWith } from 'lodash';
import { IAccount, IVisitor, NgxPendoService } from 'ngx-pendo';
import { BehaviorSubject, combineLatest, from, Observable, of, Subscription } from 'rxjs';
import { catchError, map, mergeMap, take, tap } from 'rxjs/operators';

import { ActivityTab, App, User, userRole } from '@app/core/models';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { AnalyticsService } from './analytics.service';

@Injectable()
export class AuthenticatedUserService extends FirebaseUtilityService {
    public static userIdKey = 'currentUserId';

    private currentUserId: string;
    private firebaseUser: firebase.User;
    private connectedRef: AngularFireObject<any>;
    private connectedSubject: BehaviorSubject<boolean>;
    private user: User;
    private userSubscription: Subscription;
    private userLoaded: BehaviorSubject<boolean | undefined>;
    private userSubject$ = new BehaviorSubject<User>(null);

    constructor(
        private db: AngularFireDatabase,
        private afAuth: AngularFireAuth,
        private afFun: AngularFireFunctions,
        private route: ActivatedRoute,
        private router: Router,
        private pendo: NgxPendoService,
        private analyticsService: AnalyticsService,
    ) {
        super();
        this.connectedSubject = new BehaviorSubject<boolean>(false);
        this.connectedRef = this.db.object('.info');
        this.userLoaded = new BehaviorSubject(undefined);

        // get currentUserId and user data if user already logged in
        this.currentUserId = localStorage.getItem(AuthenticatedUserService.userIdKey) || undefined;
        this.loadUserData();
    }

    getCurrentUserId(): string {
        return this.currentUserId;
    }

    getCurrentUser(): User {
        return this.user;
    }

    getCurrentImage(): string {
        return (!!this.user && this.user.image_url) || '';
    }

    hasAccessToCreateTemplate(): boolean {
        return !!this.user && this.user.email.toLowerCase().endsWith('@accenture.com') && !this.user.guest;
    }

    isUserAdmin(): boolean {
        return !!this.user && !!this.user.permission && !!this.user.permission['admin'];
    }

    isUserWorkbook(): boolean {
        return !!this.user && !!this.user.permission && this.user.permission['workbook'];
    }

    isUserReplicateApp(): boolean {
        return !!this.user && !!this.user.permission && this.user.permission['replicate_app'];
    }

    getCurrentUserFullName(): string {
        return User.getUserName(this.user);
    }

    isUserAuthenticated(): Observable<boolean> {
        if (!this.currentUserId || (this.user && this.user.disabled)) {
            return of(false);
        }

        return this.userLoaded.asObservable();
    }

    isUserLeader(location: 'apps' | 'app_instances', locationKey: string): boolean {
        if (!this.user) {
            return false;
        }

        const role = (this.user[location] || {})[locationKey];

        return ['leader', 'owner'].includes(role);
    }

    isLeader(isApp: boolean, appKey: string): boolean {
        if (!this.user) {
            return false;
        }

        const location = isApp ? 'apps' : 'app_instances';
        const role = (this.user[location] || {})[appKey];

        return ['leader', 'owner'].includes(role);
    }

    isUserParticipant(locationKey: string): boolean {
        if (!this.user) {
            return false;
        }

        const role = (this.user['app_instances'] || {})[locationKey];

        return role === 'participant';
    }

    isUserObserver(location: 'apps' | 'app_instances', locationKey: string): boolean {
        if (!this.user) {
            return false;
        }

        const role = (this.user[location] || {})[locationKey];

        return role === 'observer';
    }

    getUserSessionRoleMap(): { [sessionKey: string]: string } {
        const location = 'app_instances';
        const userSessionRoleMap = (!!this.user && this.user[location]) || {};

        return userSessionRoleMap;
    }

    isUserLeaderByCurrentRoute(): boolean {
        const isInstance = this.router.url.startsWith('/instance');
        const location = isInstance ? 'app_instances' : 'apps';
        let currentRoute = this.route;

        while (currentRoute.firstChild) {
            currentRoute = currentRoute.firstChild;
        }

        const params = currentRoute.snapshot.params || {};
        const appKey = params.appKey;

        return this.isUserLeader(location, appKey);
    }

    getCurrentUserAccess(
        name: string,
        location: 'apps' | 'app_instances' | 'deployments',
        locationKey: string,
        userKey?: string,
    ): boolean {
        if (!this.user) {
            return false;
        }

        const role = (this.user[location] || {})[locationKey];
        const isParticipant = role === 'participant';
        const isOwner = role === 'owner';
        const isLeader = ['leader', 'owner'].includes(role);
        const isSessionCreator = role === 'launch-only';
        const isObserver = role === 'observer';

        return {
            'start-session': isLeader, // can start session, has other leader access
            'delete-app': isOwner, // can delete app
            agenda: isLeader || isSessionCreator, // can interact with agenda nav bar
            sharing: isLeader, // can interact with sharing nav bar
            roster: isLeader, // can view roster
            'change-app-info': isLeader, // can update app/session info
            'change-category': isLeader, // can change response category
            'set-category': isLeader || isParticipant, // can change response category if it is empty
            'submit-category': isLeader, // can create new category in activity
            reactions: isLeader || isParticipant, // can answer questions, can interact with
            // response/comment/question likes (and comment)
            'votes-limit': isParticipant, // has limited num of votes
            'merge-enabled': isLeader || isParticipant, // allow merge responses
            flag: isLeader, // can interact with response/comment/question flag
            'votes-view': isLeader, // can view a total count for up-votes
            'smart-delete': isLeader || (isParticipant && userKey === this.currentUserId),
            'smart-edit': isLeader || (isParticipant && userKey === this.currentUserId),
            focus: isLeader, // can focus response or question
            answers: isLeader || isParticipant,
            interaction: isLeader || isParticipant, // common property for not observers
            'submit-question': isObserver,
            'flow-setup': isLeader,
        }[name];
    }

    async initializeGuestUser(user: firebase.User, userPushId: any): Promise<void> {
        this.currentUserId = userPushId;
        localStorage.setItem(AuthenticatedUserService.userIdKey, this.currentUserId);

        const result = await this.afFun.httpsCallable('verifyUserClaims')({ engageUserId: userPushId }).toPromise();

        console.log({ userPushId, result });
        await user.getIdTokenResult(true);
        this.loadUserData();
    }

    initializeCurrentUser(cognitoUserData): Observable<void> {
        return this.monitorFirebaseAuthenticationUser().pipe(
            mergeMap(() => {
                return this.monitorFirebaseConnectionState();
            }),
            mergeMap(() => {
                // We don't want to subscribe to this query so we use take to complete the observable
                return this.getUserByUsername(cognitoUserData.email).pipe(take(1));
            }),
            mergeMap((foundUsers: User[]) => {
                const currentUser = foundUsers[0];
                let currentUserId;

                if (!currentUser) {
                    // This is a new user, we need to create them and get their key
                    currentUserId = this.createUser(cognitoUserData).key;
                } else {
                    currentUserId = currentUser.key;
                    this.verifyUserFirstAndLastNameExists(currentUser, cognitoUserData);
                }

                return this.afFun
                    .httpsCallable('verifyUserClaims')({ engageUserId: currentUserId })
                    .pipe(
                        map(result => ({ updatedClaim: result.updatedClaim, currentUserId })),
                        catchError(error => {
                            console.error('Error verifying user claims', error);

                            return of({ updatedClaim: false, currentUserId });
                        }),
                    );
            }),
            mergeMap(verifyUserClaimsResult => {
                if (!!verifyUserClaimsResult.updatedClaim) {
                    // Need to refresh the user's ID token
                    return from(this.firebaseUser.getIdToken(true)).pipe(
                        map(() => verifyUserClaimsResult.currentUserId),
                        catchError(error => {
                            console.error('Error refreshing the Id token', error);

                            return of(verifyUserClaimsResult.currentUserId);
                        }),
                    );
                }

                return of(verifyUserClaimsResult.currentUserId);
            }),
            map((currentUserId: string) => {
                this.currentUserId = currentUserId;
                localStorage.setItem(AuthenticatedUserService.userIdKey, this.currentUserId);

                this.loadUserData();
            }),
        );
    }

    clearCurrentUser(): Observable<boolean> {
        // set last_login by user key when user is logged out
        this.setUserLastLogin();
        this.currentUserId = undefined;
        this.user = undefined;
        localStorage.removeItem(AuthenticatedUserService.userIdKey);
        localStorage.removeItem('tt_guest_user');

        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
        }

        return of(true);
    }

    getConnectedState(): Observable<boolean> {
        return this.connectedSubject;
    }

    getOnline(): Observable<{ [sessionKey: string]: true }> {
        return this.db.object<{ [sessionKey: string]: true }>(`users/${this.currentUserId}/online`).valueChanges();
    }

    setOnline(sessionKey: string): void {
        this.db.object<true>(`users/${this.currentUserId}/online/${sessionKey}`).set(true);
    }

    setOffline(sessionKey: string): void {
        this.db.object<string>(`users/${this.currentUserId}/online/${sessionKey}`).remove();
    }

    setUserLastLogin(): void {
        if (!this.currentUserId || !this.user) {
            return;
        }
        this.db.object<number>(`users/${this.currentUserId}/last_login`).set(Date.now());
    }

    getAccessToken(): Observable<string> {
        return this.afAuth.idToken;
    }

    getCurrentUserSessionRole(appKey: string): string {
        return (this.user['app_instances'] || {})[appKey];
    }

    getCurrentUserRole(isApp: boolean, appKey: string): Observable<userRole> {
        const appOrSession = isApp ? 'apps' : 'app_instances';

        return this.db.object<userRole>(`/users/${this.currentUserId}/${appOrSession}/${appKey}`).valueChanges();
    }

    navigateToForbiddenPage(): Promise<boolean> {
        // we need to navigate a user to an error screen when the user will be deactivated
        // there are 2 cases:
        // 1) user is already authorized and the application is opened => need to add handler for update of disabled field
        // 2) user isn't already authorized and he is trying to login => need to add handler for errors during login
        return this.router.navigate(['deactivated']);
    }

    public loadUserData(): void {
        if (!this.currentUserId) {
            this.currentUserId = localStorage.getItem(AuthenticatedUserService.userIdKey) || undefined;
        }

        if (this.currentUserId) {
            const user$ = this.getCurrentUserReference().snapshotChanges();

            this.userSubscription = user$.subscribe(
                user => {
                    this.user = this.createUserFromSnapshot(user);
                    this.userLoaded.next(!!(user && user.payload.val()));
                    this.userSubject$.next(this.user);
                    this.initPendo();

                    // user logged in - add login event with their key?
                    this.analyticsService.setUserId(this.currentUserId);
                    this.analyticsService.logAnalyticsEvent('user_login', {
                        timestamp: Date.now(),
                        userId: this.currentUserId,
                        isGuest: this.user.guest,
                        isAdmin: this.isUserAdmin(),
                        environment: environment.name,
                        permissions: Object.keys(this.user.permission || {})
                            .filter(key => this.user.permission[key])
                            .toString(),
                    });

                    // there is some delay between user was blocked and user have not access
                    // so we should add subscribe for the disabled field
                    if (this.user && this.user.disabled) {
                        this.navigateToForbiddenPage();
                    }
                },
                () => {
                    this.userLoaded.next(false);
                },
            );
            user$.pipe(take(1)).subscribe(
                () => {
                    // set last_login by user key when user is logged in
                    // we can do it only we successfully got the data of user
                    // because the user can be deactivated in firebase
                    this.setUserLastLogin();
                },
                () => {},
            );
        }
    }

    private initPendo(): void {
        const hasApps = this.canUserCreateApps();
        const isSessionLeader = Object.keys(this.user.app_instances || {}).find(sessionKey => {
            return this.user.app_instances[sessionKey] === 'leader';
        });
        const billingUserValue = hasApps || isSessionLeader ? 'leader' : 'participant';
        const userStatus = this.user.disabled ? 'disabled' : this.user.active ? 'active' : 'inactive';

        const userEmailArr = (this.user.email || '').split('@');
        const visitor = {
            id: this.currentUserId,
            email_domain: userEmailArr[1] || '',
            guest: `${!!this.user.guest}`,
            admin: `${this.isUserAdmin()}`,
            billing_user: billingUserValue,
            status: userStatus,
        } as IVisitor;
        const account = {
            id: environment.name, // Highly recommended
        } as IAccount;

        this.pendo.initialize(visitor, account);
    }

    private getCurrentUserReference(): AngularFireObject<User> {
        return this.db.object(`/users/${this.currentUserId}`);
    }

    private monitorFirebaseAuthenticationUser(): Observable<void> {
        return this.afAuth.authState.pipe(
            mergeMap((firebaseUser: firebase.User) => {
                this.firebaseUser = firebaseUser;

                return of(undefined);
            }),
        );
    }

    private monitorFirebaseConnectionState(): Observable<void> {
        return this.connectedRef.valueChanges().pipe(
            map(info => info.connected),
            mergeMap((connectionState: boolean) => {
                console.log('CONNECTION STATE::', connectionState);
                this.connectedSubject.next(connectionState);

                return of(undefined);
            }),
        );
    }

    getUserByUsername(username: string): Observable<User[]> {
        const lowercasedUsernameToMatch = username && username.toLowerCase();

        return combineLatest([
            this.getUsersByUsername(username),
            this.getUsersByUsername(lowercasedUsernameToMatch),
        ]).pipe(
            map(([given, lowercase]) => {
                // here we can get users with the same email but in mixed case
                // we sort users by created timestamp in desc order
                // so the first one will be the latest created
                const combinedUniqueValues = _unionWith(given, lowercase, _isEqual);

                return combinedUniqueValues
                    .filter((user: User) => {
                        const isGuest = !!user.guest;
                        const lowercasedUsername = user.username && user.username.toLowerCase();

                        return !isGuest && lowercasedUsername === lowercasedUsernameToMatch;
                    })
                    .sort((user1, user2) => {
                        if (!user2.created_at && !user1.created_at) {
                            return 0;
                        }

                        if (!user2.created_at) {
                            return -1;
                        }

                        if (!user1.created_at) {
                            return 1;
                        }

                        return user2.created_at - user1.created_at;
                    });
            }),
        );
    }

    private getUsersByUsername(username: string): Observable<User[]> {
        return this.listWithKeys(this.db.list<User>('/users', ref => ref.orderByChild('username').equalTo(username)));
    }

    private createUser(cognitoUserData: any): any {
        const email = cognitoUserData.email;
        const lowercasedEmail = email.toLowerCase();

        return this.db.list('/users').push({
            created_at: Date.now(),
            username: lowercasedEmail,
            email: lowercasedEmail,
            authenticationId: cognitoUserData.sub,
            firstName: cognitoUserData.given_name,
            lastName: cognitoUserData.family_name,
            active: true,
        });
    }

    private verifyUserFirstAndLastNameExists(currentUser: any, cognitoUserData: any): void {
        if (!(currentUser.firstName && currentUser.lastName)) {
            // This is an existing user that does not have a firstName and lastName since they were invited before
            // they have an account so set them those fields here for the user
            this.db.object(`/users/${currentUser.key}`).update({
                firstName: cognitoUserData.given_name,
                lastName: cognitoUserData.family_name,
                authenticationId: cognitoUserData.sub,
                active: true,
            });
        }
    }

    private createUserFromSnapshot(userSnapshot: AngularFireAction<DatabaseSnapshot<any>>): User {
        return <User>{
            key: userSnapshot.key,
            ...userSnapshot.payload.val(),
        };
    }

    public getUserCollectActivityPaths(activityKey: string): Observable<{ [demgraphicsKey: string]: string }> {
        return this.db
            .object<{ [demgraphicsKey: string]: string }>(
                `/users/${this.currentUserId}/collectActivities/${activityKey}/`,
            )
            .valueChanges();
    }

    private getUserDemographicPath(activityKey: string, demographicKey: string): Observable<string> {
        return this.db
            .object<string>(`/users/${this.currentUserId}/collectActivities/${activityKey}/${demographicKey}/`)
            .valueChanges()
            .pipe(take(1));
    }

    // Update demographics path for the activity for the current user
    public updateUserDemographicPath(
        activityKey: string,
        demographicKey: string,
        questionSequenceToAppend: any,
        clear?: boolean,
        currentQuestionSequence?: number,
    ): Promise<void> {
        return this.getUserDemographicPath(activityKey, demographicKey)
            .toPromise()
            .then(currentPath => {
                let splitPath = currentPath ? currentPath.split(',') : [];

                if (clear) {
                    splitPath = splitPath.filter(sequence => +sequence <= currentQuestionSequence && +sequence !== -1);
                } else {
                    splitPath = splitPath.filter(sequence => +sequence !== questionSequenceToAppend);
                }
                splitPath.push(questionSequenceToAppend);

                return this.db
                    .object<{ [demographicKey: string]: string }>(
                        `/users/${this.currentUserId}/collectActivities/${activityKey}/`,
                    )
                    .update({ [demographicKey]: splitPath.toString() });
            });
    }

    // Update activity path for the current user for the reporting
    public updateQuestionsCompletedReporting(
        activityKey: string,
        questionSequenceToAppend: number,
        clear?: boolean,
    ): Observable<any> {
        return this.db
            .object<string>(`/reportCompletedQuestions/${activityKey}/${this.currentUserId}`)
            .valueChanges()
            .pipe(
                take(1),
                mergeMap(activityPath => {
                    let path = activityPath ? activityPath.split(',') : [];

                    if (clear) {
                        path = path.filter(sequence => +sequence < questionSequenceToAppend && +sequence !== -1);
                    } else {
                        path = path.filter(sequence => +sequence !== questionSequenceToAppend);
                    }
                    path.push(`${questionSequenceToAppend}`);

                    return this.db
                        .object(`/reportCompletedQuestions/${activityKey}`)
                        .update({ [this.currentUserId]: path.toString() });
                }),
            );
    }

    public updateLastVisitedActivity(appKey: string, activityData: any): Promise<void> {
        if (activityData) {
            return this.db
                .object(`ssot/_users/${this.currentUserId}/last_selected_activity/${appKey}`)
                .update(activityData);
        } else {
            return this.db.object(`ssot/_users/${this.currentUserId}/last_selected_activity/${appKey}`).remove();
        }
    }

    async updateLastVisitedActivitySequence(activities: { [key: string]: ActivityTab }, appKey: string): Promise<void> {
        const lastVisitedActivityData = await this.getLastVisitedActivity(appKey).pipe(take(1)).toPromise();
        const lastVisitedActivity = lastVisitedActivityData && lastVisitedActivityData.activity;

        if (!!lastVisitedActivity) {
            const newSequence =
                (activities[lastVisitedActivity.key] && activities[lastVisitedActivity.key].sequence) ||
                lastVisitedActivity.sequence;

            this.db
                .object(`ssot/_users/${this.currentUserId}/last_selected_activity/${appKey}/activity/sequence`)
                .set(newSequence);
        }
    }

    public getLastVisitedActivity(appKey: string): Observable<any> {
        return this.db.object(`ssot/_users/${this.currentUserId}/last_selected_activity/${appKey}`).valueChanges();
    }

    public doesUserHaveDeployments(userId: string): Observable<boolean> {
        return this.db
            .list(`/users/${userId}/deployments`)
            .valueChanges()
            .pipe(
                map(
                    deployments =>
                        !!deployments.filter(
                            deploymentRole => deploymentRole === 'collaborator' || deploymentRole === 'owner',
                        ).length,
                ),
            );
    }

    public canUserCreateApps(): boolean {
        return Object.keys(this.user['apps'] || {}).length > 0;
    }

    public hasUserApps(userId: string): Observable<boolean> {
        return this.db
            .list<App>(`/users/${userId}/apps`)
            .snapshotChanges()
            .pipe(map(apps => !!apps && !!apps.length));
    }

    public hasUserLeaderSessionRole(userId: string): Observable<boolean> {
        return this.db
            .list(`/users/${userId}/app_instances`)
            .valueChanges()
            .pipe(map(sessions => !!sessions.filter(sessionRole => sessionRole === 'leader').length));
    }

    public hasCurrentUserWorkbookPermission(): Observable<boolean> {
        return this.userSubject$.pipe(
            map(user =>
                [...Object.values(user.apps || {}), ...Object.values(user.app_instances || {})].some(role =>
                    ['leader', 'owner'].includes(role),
                ),
            ),
            tap(async isHasCurrentUserWorkbookPermission => {
                const updates = {};

                updates[`users/${this.user.key}/permission/workbook`] = isHasCurrentUserWorkbookPermission;

                await this.db.object('/').update(updates);
            }),
        );
    }

    public getUserFullName(userId: string = this.currentUserId): Observable<string> {
        return this.db
            .object<User>(`/users/${userId}`)
            .valueChanges()
            .pipe(map(user => User.getUserName(user)));
    }
}
