import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngularFireList, AngularFireObject } from '@angular/fire/compat/database';

export abstract class FirebaseUtilityService {

    objectWithKey<T>(obj: AngularFireObject<T>): Observable<T> {
        return obj.snapshotChanges().pipe(
            map((objSnapshot) => {
                return {
                    ...<any>(objSnapshot).payload.val(),
                    key: objSnapshot.key
                };
            }));
    }

    listWithKeys<T>(list: AngularFireList<T>): Observable<T[]> {
        return list.snapshotChanges().pipe(
            map((listSnapshot) => {
                return listSnapshot.map((listItem) => {
                    return <T>{
                        ...<any>listItem.payload.val(),
                        key: listItem.key
                    };
                });
            }));
    }

}
