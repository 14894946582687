export const allAcceptedFileTypes = [
    '.ppt',
    '.pptx',
    '.doc',
    '.docx',
    '.xlsx',
    '.xls',
    '.odt',
    '.jpg',
    '.tiff',
    '.png',
    '.gif',
    '.bmp',
    '.mp4',
    '.mov',
    '.mpeg',
    '.avi',
    '.wmv',
    '.pdf',
    '.txt',
    '.rtf'
];

export const allAcceptedImageTypes = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp'
];
