import { FormArray, FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { Attachment, FormResponse, FilestackUploadResult } from '@app/core/models';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseResponsesService } from '@app/core/services/responses.service';
import { FilestackService } from '@app/core/services/filestack.service';
import { listWithKeys } from '@app/core/utils';

@Injectable()
export class FormAttachmentService {

    constructor(
        private db: AngularFireDatabase,
        private authenticatedUserService: AuthenticatedUserService,
        private filestackService: FilestackService,
        private responsesService: FirebaseResponsesService
    ) { }

    async formFilePicker(
        questionKey: string,
        formKey: string,
        attachmentsKeys: string[]
    ): Promise<{ result: string[], type: string }> {
        const attachments = [];
        await this.filestackService
            .uploadFile({
                onUploadDone: ({ fileName, url }: FilestackUploadResult) => {
                    const attachment = this.convertIntoFormAttachment(fileName, url);
                    attachments.push(attachment);
                }
            });
        const keys = await this.createFormAttachments(
            attachments,
            questionKey,
            formKey,
            attachmentsKeys
        );
        return {
            result: keys,
            type: 'resolve'
        };
    }

    getFormAttachments(questionKey: string): Observable<Attachment[]> {
        return listWithKeys<Attachment>(
            this.db.list<Attachment>(`ssot/_attachments`, ref => ref.orderByChild('question_id').equalTo(questionKey))
        );
    }

    deleteFormAttachment(
        attachmentKey: string,
        formKey: string,
        parentDemographicKey: string,
        demographicClassKey: string,
        demographicKey: string,
        questionKey: string,
        currentAttachmentsKeys: string[]
    ): Promise<string[]> {
        const useDemographics = !!demographicKey && !!demographicClassKey;
        const newAttachmentsKeys = [...currentAttachmentsKeys];
        newAttachmentsKeys.splice(currentAttachmentsKeys.indexOf(attachmentKey), 1);
        this.removeAttachmentsFromDb(useDemographics, attachmentKey, questionKey);
        return Promise.resolve(newAttachmentsKeys);
    }

    removeAttachmentsFromDb(
        useDemographics: boolean,
        attachmentKey: string,
        questionKey: string
    ): void {
        const deleteAttachment = {};
        deleteAttachment[`ssot/_attachments/${attachmentKey}`] = null;
        if (!useDemographics) {
            this.db.object(`/`).update(deleteAttachment);
        } else {
            this.responsesService.getFormResponseByQuestionId(questionKey)
                .pipe(take(1))
                .subscribe((attachmentResponses: FormResponse[]) => {

                    // search deleted attachment in all responses of the current question
                    const isAttachmentInOtherResponses = attachmentResponses.find((response: FormResponse) => {
                        if (!response.value) {
                            return false;
                        }
                        return response.value.find((attachmentRespKey: string) => {
                            return attachmentRespKey === attachmentKey;
                        });
                    });

                    // remove attachment if it was removed from all questions
                    if (isAttachmentInOtherResponses) {
                        this.db.object(`/`).update(deleteAttachment);
                    }
                });
        }
    }

    private createFormAttachments(
        attachments: Attachment[],
        questionKey: string,
        formKey: string,
        attachmentsKeys: string[]
    ): Promise<string[]> {
        return this.writeFormAttachmentsToDb(attachments, questionKey, formKey)
            .pipe(
                take(1),
                map((addedAttachmentsKeys) => {
                    const newAttachmentKeys = [...attachmentsKeys, ...addedAttachmentsKeys];
                    return newAttachmentKeys;
                })
            )
            .toPromise();
    }

    private writeFormAttachmentsToDb(
        attachments: Attachment[],
        questionKey: string,
        formKey: string
    ): Observable<string[]> {
        const attachmentsKeys = [];
        const userId = this.authenticatedUserService.getCurrentUserId();
        const updateObject = attachments.reduce((result, attachment) => {
            const newAttachmentId = this.db.createPushId();
            attachmentsKeys.push(newAttachmentId);
            result[newAttachmentId] = <Attachment>{
                ...attachment,
                form_id: formKey,
                question_id: questionKey,
                owner: userId,
                date_created: +(new Date())
            };

            return result;
        }, {});

        this.db.object(`ssot/_attachments`).update(updateObject);

        return of(attachmentsKeys);
    }

    private convertIntoFormAttachment(filename: string, downloadURL: string): Attachment {
        const attachment = <Attachment>{
            name: filename,
            download_url: downloadURL
        };

        return attachment;
    }
}
