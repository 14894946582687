import { ExportableFormat } from './exportable-format.model';
import { Timestamp } from 'firebase/firestore';

export class ExportVersion {
    type: ExportableFormat;
    name: string;
    version: number;
    project_id: string;
    origin_id: string;
    form_id?: string; // form activity id on engage
    url: string;
    key: string;
    date_created: Timestamp;
}

export const testExportVersion: ExportVersion = {
    type: ExportableFormat.Workbook,
    name: 'testName',
    version: 1,
    project_id: 'testProjectId1',
    origin_id: 'testWorkbookId1',
    form_id: 'testFormId1',
    url: '',
    key: 'testVersionKey1',
    date_created: Timestamp.now()
};
