export type NotificationType =
    | 'reviews'
    | 'collaboration_requests'
    | 'alignment_ready'
    | 'draft_complete'
    | 'gate_complete'
    | 'demographics_submit';

export class WorkFlowCard {
    app_id: string;
    date_created: number;
    notification_id: string;
    type: NotificationType;
    key?: string;
}

export class WorkbookExportData {
    appId?: string;
    activityId?: string;
    workbookIds?: string[];
    demographicIds?: string[];
    userId?: string;
    shouldWriteToRepository?: boolean;
    shouldDownload?: boolean;
    versions?: {
        name: string;
        version: number;
        workbookId: string;
        formId?: string; // form activity id on engage
    }[];
}

export class WorkbookImportData {
    activity_id: string;
    form_id: string;
    user_id: string;
    demographics_ids?: string[];
    custom_name?: string;
}

export const mockWorkFlowCard1 = <WorkFlowCard>{
    app_id: 'testApp1',
    date_created: 1543433731685,
    notification_id: 'test_notification_id1',
    type: 'reviews',
    key: 'testKey1',
};

export const mockWorkFlowCard2 = <WorkFlowCard>{
    app_id: 'testApp1',
    date_created: 1543433731685,
    notification_id: 'test_notification_id2',
    type: 'collaboration_requests',
    key: 'testKey2',
};
