import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Observable, of, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import {
    Form,
    FormQuestion,
    Workbook,
    WorkbookSheet,
    WorkbookExportData,
    WorkbookImportData,
    FilestackUploadResult,
    FilestackUploadType,
    App,
} from '@app/core/models';
import { sortByAlphabetic, sortBySequenceAsc, removeEmptyKeys } from '@app/core/utils';
import { FilestackService } from '@app/core/services/filestack.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { FormComponentsService } from '@app/core/services/form-components.service';
import { ImportInfo } from '../models/import-info.model';
import { DownloadUrlInfo } from '../models/download-url-info.model';
import { ConfirmActionDialogComponent } from '@app/shared/dialogs/confirm-action-dialog/confirm.action.dialog.component';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { DisableClearMappingButtonService } from '@app/apps/activities/forms/form-services/disable-clear-mapping-button.service';
import { LoaderService } from './loader.service';
import { httpsCallableOptions } from '../constants';

@Injectable()
export class WorkbookService extends FirebaseUtilityService {
    private readonly workbookExportTimeout = 540 * 1000; // it is the same value as was defined in the CF

    public importInProgressSubject = new BehaviorSubject<boolean>(false);
    public clearMappingInProgressSubject = new BehaviorSubject<{ type: 'form' | 'question'; questionId: string }>(null);
    public mappingsCleared$ = new Subject<FormQuestion>();

    constructor(
        private db: AngularFireDatabase,
        private filestackService: FilestackService,
        private authUserService: AuthenticatedUserService,
        private angularFireFunctions: AngularFireFunctions,
        private formComponentsService: FormComponentsService,
        private disableClearMappingButtonService: DisableClearMappingButtonService,
        private dialog: MatDialog,
        private loaderService: LoaderService,
    ) {
        super();
    }

    getWorkbooksList(): Observable<Workbook[]> {
        return this.listWithKeys<Workbook>(this.db.list<Workbook>('/workbooks'));
    }

    getUserWorkbooksList(selectedId?: string): Observable<Workbook[]> {
        const currentUserId = this.authUserService.getCurrentUserId();
        const filteredWorkbooks$ = combineLatest(
            this.db.object(`/users/${currentUserId}/app_instances`).valueChanges(),
            this.db.object(`/users/${currentUserId}/apps`).valueChanges(),
            this.getWorkbooksList(),
        ).pipe(
            map(([instances, apps, workbooks]) => {
                const appIds = Object.keys({
                    ...(instances as App),
                    ...(apps as App),
                });

                return workbooks.filter(
                    workbook =>
                        workbook.owner_id === currentUserId ||
                        appIds.some(appId => (workbook.app_ids || {})[appId]) ||
                        workbook.key === selectedId,
                );
            }),
        );

        return filteredWorkbooks$;
    }

    getWorkbooksListAsc(): Observable<Workbook[]> {
        return this.getWorkbooksList().pipe(map((workbooks: Workbook[]) => sortByAlphabetic(workbooks, 'name')));
    }

    getWorkbookListAscByKeys(workbookKeys: { [key: string]: any }): Observable<Workbook[]> {
        return this.getWorkbooksList().pipe(
            map((workbooksList: Workbook[]) => {
                return workbooksList.filter((workbook: Workbook) => workbook.key in workbookKeys);
            }),
        );
    }

    removeWorkbookByKey(workbookKey: string): Promise<void> {
        return this.db.object(`/workbooks/${workbookKey}`).remove();
    }

    getWorkbookByKey(workbookKey: string): Observable<Workbook> {
        return this.objectWithKey(this.db.object(`/workbooks/${workbookKey}`));
    }

    async openFilePicker(workbookNamesArray: string[]): Promise<void> {
        const updates = {};

        await this.filestackService.uploadFile({
            uploadType: FilestackUploadType.EXCELFiles,
            gsFolder: 'workbooks',
            onUploadDone: async ({ fileName, url }: FilestackUploadResult) => {
                const workbook = await this.createWorkbook(fileName, url, workbookNamesArray);
                const key = this.db.createPushId();

                updates[key] = workbook;
            },
        });
        await this.multiPathUpdate(updates);
    }

    getAppWorkbookKey(appKey: string): Observable<string> {
        return this.db
            .object(`/ssot/_apps/${appKey}/workbook_id`)
            .valueChanges()
            .pipe(
                map((workbookKey: string) => {
                    return workbookKey || 'none';
                }),
            );
    }

    getActivityWorkbookKey(activityKey: string): Observable<string> {
        return this.db
            .object(`/ssot/_activities/${activityKey}/workbook_id`)
            .valueChanges()
            .pipe(
                map((workbookKey: string) => {
                    return workbookKey || 'none';
                }),
            );
    }

    getWorkbookSheets(workbookKey: string): Observable<WorkbookSheet[]> {
        return this.listWithKeys(
            this.db.list<WorkbookSheet>('/ssot/_workbook_sheets', ref =>
                ref.orderByChild('workbook_id').equalTo(workbookKey),
            ),
        ).pipe(map((workbookSheets: WorkbookSheet[]) => sortBySequenceAsc(workbookSheets)));
    }

    getWorkbookSheetsByActivityKey(activityKey: string): Observable<WorkbookSheet[]> {
        return this.getActivityWorkbookKey(activityKey).pipe(
            switchMap((workbookKey: string) => {
                return workbookKey === 'none' ? of([]) : this.getWorkbookSheets(workbookKey);
            }),
        );
    }

    getFormSheetKey(formKey: string): Observable<string> {
        return this.db
            .object(`/ssot/_forms/${formKey}/workbook_sheet_id`)
            .valueChanges()
            .pipe(
                map((workbookSheetKey: string) => {
                    return workbookSheetKey || 'none';
                }),
            );
    }

    getQuestionSheetKey(questionKey: string): Observable<string> {
        return this.db
            .object(`/ssot/_questions/${questionKey}/workbook_sheet_id`)
            .valueChanges()
            .pipe(
                map((workbookSheetKey: string) => {
                    return workbookSheetKey || 'none';
                }),
            );
    }

    async exportToWorkbook(data: WorkbookExportData, cutValues: boolean): Promise<DownloadUrlInfo[]> {
        const timezone = new Date().getTimezoneOffset();
        const params = { ...data, cutValues, timezone };

        return this.angularFireFunctions
            .httpsCallable(
                'workbookExport',
                httpsCallableOptions,
            )(removeEmptyKeys(params))
            .toPromise();
    }

    importWorkbook(data: WorkbookImportData): Promise<any> {
        const timezone = new Date().getTimezoneOffset();

        this.importInProgressSubject.next(true);

        return this.angularFireFunctions
            .httpsCallable(
                'formImportDefaultValues',
                httpsCallableOptions,
            )(removeEmptyKeys({ ...data, timezone }))
            .toPromise()
            .catch(async () => {
                await this.changeStatusToAborted(data.activity_id);
            });
    }

    async changeStatusToAborted(activityId: string): Promise<void> {
        const path = `/ssot/_import_statuses/${activityId}`;
        const updates = {
            import_status: 'aborted',
            notified_users: null,
        };

        await this.db.object(path).update(updates);
    }

    getImportInfo(activityKey: string): Observable<ImportInfo> {
        return this.db
            .object(`/ssot/_import_statuses/${activityKey}`)
            .valueChanges()
            .pipe(
                map((importNode: any) => {
                    let userId: string;
                    let url: string;
                    let status = '';
                    let activityId = '';
                    let notifiedUsers = {};

                    if (importNode) {
                        status = importNode.import_status || '';
                        activityId = importNode.activity_id || '';
                        notifiedUsers = importNode.notified_users || {};
                        userId = importNode.import_owner;
                    }

                    if (status === 'complete' || status === 'complete_with_errors') {
                        this.importInProgressSubject.next(false);
                    }

                    if (status === 'in_progress') {
                        this.importInProgressSubject.next(true);
                    }

                    if (status === 'complete_with_errors') {
                        url = importNode.error_log;
                    }

                    if (!status) {
                        this.importInProgressSubject.next(false);
                    }

                    return {
                        activityId,
                        status,
                        notifiedUsers,
                        importOwner: userId || null,
                        url: url || null,
                    };
                }),
            );
    }

    setUserAsNotified(activityId: string, userId: string): Promise<any> {
        return this.db.object(`/ssot/_import_statuses/${activityId}/notified_users/${userId}`).set(true);
    }

    getFormQuestionsWithWorkbookSheet(formKey: string): Observable<FormQuestion[]> {
        return this.formComponentsService
            .getFormQuestionsByFormKey(formKey)
            .pipe(
                map(questions =>
                    questions.filter(
                        question => question.workbook_sheet_id || question.map_answer || question.map_options_enable,
                    ),
                ),
            );
    }

    getSubformsWithWorkbookSheet(activityKey: string): Observable<Form[]> {
        return this.formComponentsService
            .getFormsByActivity(activityKey)
            .pipe(map(forms => forms.filter(form => form.workbook_sheet_id && form.parent_form_id)));
    }

    async updateWorkbookField(activityKey: string, field: string, value: string | boolean): Promise<void> {
        await this.db.object(`/ssot/_activities/${activityKey}/${field}`).set(value);
    }

    async updateAppWorkbookKey(appKey: string, workbookKey: string): Promise<void> {
        const valueToWrite = workbookKey !== 'none' ? workbookKey : null;

        await this.db.object(`/ssot/_apps/${appKey}/workbook_id`).set(valueToWrite);
    }

    async updateActivityWorkbookKey(activityKey: string, workbookKey: string, workbookSheetId?: string): Promise<void> {
        const updates = {
            workbook_id: workbookKey !== 'none' ? workbookKey : null,
            workbook_selected_manually: true,
            workbook_sheet_selected: null,
        };

        await this.db.object(`/ssot/_activities/${activityKey}`).update(updates);
        await this.db.object(`/ssot/activities/${activityKey}`).update(updates);

        // recalculate rectangle for previous selected sheet
        const userId = this.authUserService.getCurrentUserId();

        await this.angularFireFunctions
            .httpsCallable(
                'recalculateRectangle',
                httpsCallableOptions,
            )({
                userId,
                questionId: null,
                type: 'form',
                prevWorkbookSheetId: workbookSheetId,
                currentWorkbookSheetId: 'none',
                activityId: activityKey,
                hasWorkbookChanged: true,
            })
            .toPromise();
    }

    async updateFormSheetKey(formKey: string, workbookSheetKey: string): Promise<void> {
        const valueToWrite = workbookSheetKey !== 'none' ? workbookSheetKey : null;
        const updates = {};

        updates[`/ssot/_forms/${formKey}/workbook_sheet_id`] = valueToWrite;
        await this.db.object('/').update(updates);
    }

    async clearMappings(
        type: 'form' | 'question',
        activityId: string,
        form: FormGroup,
        questionId?: string,
        questionsToUpdate?: { [questionKey: string]: AbstractControl },
        choicesToUpdate?: AbstractControl[],
        options?: { question: FormQuestion },
    ): Promise<any> {
        const isQuestion = type === 'question' && !!questionId;
        const isSection = type === 'form' && !!questionId;
        const resetEntity = isQuestion ? 'question' : isSection ? 'section' : 'form';
        const confirmationTitle = 'Clear Mapping Setup';
        const confirmationTest = `Are you sure you want to clear mapping setup for this ${resetEntity}?`;

        // use material dialog directly to avoid circular dependency

        this.dialog.open(ConfirmActionDialogComponent, {
            width: '300px',
            height: 'auto',
            maxWidth: '300px',
            panelClass: '',
            disableClose: false,
            restoreFocus: false,
            data: {
                closeOnConfirm: true,
                title: confirmationTitle,
                text: confirmationTest,
                confirmBtnText: 'Yes',
                cancelBtnText: 'Cancel',
                handleConfirm: () => {
                    this.clearMappingInProgressSubject.next({ type, questionId });
                    this.angularFireFunctions
                        .httpsCallable(
                            'clearWorkbookMappings',
                            httpsCallableOptions,
                        )({
                            type,
                            activity_id: activityId,
                            question_id: questionId,
                            user_id: this.authUserService.getCurrentUserId(),
                        })
                        .toPromise()
                        .then(() => {
                            switch (type) {
                                case 'question':
                                    this.clearFormControl(form);
                                    this.clearMultiSelectFormGroup(choicesToUpdate);
                                    break;
                                case 'form':
                                    Object.keys(questionsToUpdate || {}).forEach(key => {
                                        questionsToUpdate[key].setValue('');
                                    });
                                    this.clearMultiSelectFormGroup(choicesToUpdate);
                                    break;
                                default:
                                    break;
                            }

                            this.clearMappingInProgressSubject.next(null);
                            if (!!options && !!options.question) {
                                this.mappingsCleared$.next(options.question);
                            }
                        });
                },
            },
        });
    }

    async updateQuestionSheetKey(questionKey: string, workbookSheetKey: string): Promise<void> {
        const valueToWrite = workbookSheetKey !== 'none' ? workbookSheetKey : null;

        await this.db.object(`/ssot/_questions/${questionKey}/workbook_sheet_id`).set(valueToWrite);
    }

    async updateQuestionMapAnswer(questionKey: string, value: string): Promise<void> {
        await this.db.object(`/ssot/_questions/${questionKey}/map_answer`).set(value);
    }

    async updateRectangle(
        currentWorkbookSheetId: string,
        prevWorkbookSheetId: string,
        questionKey: string,
        type: string,
        activityId: string,
    ): Promise<void> {
        this.disableClearMappingButtonService.disable(true);
        const userId = this.authUserService.getCurrentUserId();

        await this.angularFireFunctions
            .httpsCallable(
                'recalculateRectangle',
                httpsCallableOptions,
            )({
                type,
                activityId,
                currentWorkbookSheetId,
                prevWorkbookSheetId,
                userId,
                questionId: questionKey || null,
            })
            .toPromise();

        this.disableClearMappingButtonService.disable(false);
    }

    async updateWorkbookSheetSelectedField(newWorkbookSheetId: any, activityId: string): Promise<void> {
        const workbookSheetSelectedFieldValue = !!newWorkbookSheetId && newWorkbookSheetId !== 'none' ? true : null;
        const updates = {};

        updates[`ssot/_activities/${activityId}/workbook_sheet_selected`] = workbookSheetSelectedFieldValue;
        updates[`ssot/activities/${activityId}/workbook_sheet_selected`] = workbookSheetSelectedFieldValue;
        await this.db.object('/').update(updates);
    }

    private clearFormControl(form: FormGroup): void {
        const formControlToClear = form.get('mapAnswer');

        formControlToClear.setValue('');
    }

    private clearMultiSelectFormGroup(choicesToUpdate: AbstractControl[]): void {
        if (choicesToUpdate || [].length) {
            choicesToUpdate.forEach(choice => {
                choice.setValue('');
            });
        }
    }

    private multiPathUpdate(updates: any): Promise<void> {
        return this.db.object('/workbooks').update(updates);
    }

    private async createWorkbook(
        filename: string,
        downloadURL: string,
        workbookNamesArray: string[],
    ): Promise<Workbook> {
        const isNameUniq = this.isWorkbookNameUniq(filename, workbookNamesArray);
        const ownerId = this.authUserService.getCurrentUserId();
        const ownerFullName = await this.authUserService.getUserFullName().pipe(take(1)).toPromise();

        let workbook: Workbook;

        if (isNameUniq) {
            workbook = new Workbook(filename, downloadURL, ownerId, ownerFullName);
        } else {
            const updatedWorkbookName = this.getWorkbookUniqName(filename, 0, workbookNamesArray);

            workbook = new Workbook(updatedWorkbookName, downloadURL, ownerId, ownerFullName);
        }

        return workbook;
    }

    private getWorkbookUniqName(workbookName: string, copyNumber: number, workbookNamesArray: string[]): string {
        const updatedNumber = copyNumber + 1;
        const updatedWorkbookNname = `${workbookName} (Copy ${updatedNumber})`;

        return this.isWorkbookNameUniq(updatedWorkbookNname, workbookNamesArray)
            ? updatedWorkbookNname
            : this.getWorkbookUniqName(workbookName, updatedNumber, workbookNamesArray);
    }

    private isWorkbookNameUniq(workbookName: string, workbookNamesArray: string[]): boolean {
        return workbookNamesArray.indexOf(workbookName) === -1;
    }
}
