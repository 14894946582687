import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Ownership, Process, Subprocess, Volunteer } from '../../models';
import { ProcessViewFacade, ProcessViewFacadeToken } from './process-view-facade';

@Component({
    selector: 'gs-process-view',
    templateUrl: './process-view.component.html',
    styleUrls: ['./process-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProcessViewComponent {
    vm$ = this.facade.vm$;
    processesActiveTab = 1;

    constructor(@Inject(ProcessViewFacadeToken) private facade: ProcessViewFacade) {
    }

    getFilteredOwnership(entireDeploymentOwnership: Ownership[], processId: string): Ownership[] {
        return (entireDeploymentOwnership || []).filter((ownershipObj) => ownershipObj.object_id === processId);
    }

    sendPulseForSubprocess(subprocess: Subprocess): void {
        console.log('SEND PULSE TO PEOPLE ASSOCIATED WITH:', subprocess);
    }

    goToAssignment(deploymentId: string, process: Process): void {
        this.facade.routeToAssignment(deploymentId, process.id);
    }

    volunteer(event: {volunteerDataObject: Volunteer, deploymentId: string}): void {
        this.facade.volunteer(event.volunteerDataObject, event.deploymentId);
    }

    unassignUser(ownershipToRemove: Ownership, deploymentId: string, processId: string): void {
        this.facade.unassignUser(ownershipToRemove, deploymentId, processId);
    }
}
