import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-project-name-dialog',
    templateUrl: './project-name-dialog.component.html',
    styleUrls: ['./project-name-dialog.component.scss'],
})
//eslint-disable-next-line
export class ProjectNameDialog implements OnInit {
    projectNameControl: FormControl;
    title: string;
    text: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<ProjectNameDialog>) {}

    handleConfirm(): void {
        const { handleConfirm, closeOnConfirm } = this.data;

        handleConfirm(this.projectNameControl.value);

        if (closeOnConfirm) {
            this.dialogRef.close();
        }
    }

    ngOnInit(): void {
        this.title = this.data.title || 'Project Name';
        this.text = this.data.text;
        this.projectNameControl = new FormControl('', [Validators.required]);
    }
}
