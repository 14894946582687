export class Group {
    key?: string;
    name: string;
    number_questions: number;
    sequence?: number;
    color?: string;
}

export const testGroup = <Group>{
    name: 'Winner',
    number_questions: 1,
    owner: 'user1',
    color: '#eee',
    sequence: 1
};
