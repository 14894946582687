import { InjectionToken } from '@angular/core';
import { DeploymentViewState, Volunteer } from '../../models';
import { Observable } from 'rxjs';

export interface DeploymentViewFacade {
    vm$: Observable<DeploymentViewState>;

    volunteerForItem(volunteer: Volunteer, deploymentId: string): void;
}

export const DeploymentViewFacadeToken = new InjectionToken<DeploymentViewFacade>('DeploymentViewFacade')
