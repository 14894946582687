export class LogRequirement {
    id?: string;
    sequence: number;
    activity_id: string;
    question_id: string;
    owner_id: string;
}

export class RequirementsQuestionMap {
    [questionKey: string]: {
        question: string;
        activity_id: string;
        activity_name: string;
    }
}
