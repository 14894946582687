import { FormStatus, FormStatusObject, FormStatusModel } from './form-status.model';

export enum ReviewState {
    Approved = 'Approved',
    Denied = 'Denied',
    Under_Review = 'Under Review',
    default = 'In progress'
}

export class DemographicStatusModel {
    [formKey: string]: FormStatusModel;
}

export class Review {
    key?: string;
    date_created: number;
    state: ReviewState;
    participant_id: string;
    activity_id: string;
    app_id: string;
    form_id?: string;
    reviewer_id?: string;
    demographics?: { [formKey: string]: string[] };
    comment?: string; // comment added when approve/deny
    instance_url?: string; // for email approve/deny
}

export const mockReview1 = <Review>{
    key: 'review_1',
    date_created: 1543433731685,
    state: ReviewState.Under_Review,
    participant_id: 'test_user_1',
    activity_id: 'test_activity_1',
    form_id: 'test_form_key_1',
    app_id: 'test_app',
};

export const mockReview2 = <Review>{
    key: 'review_2',
    date_created: 1543433731920,
    state: ReviewState.Under_Review,
    participant_id: 'test_user_2',
    activity_id: 'test_activity_1',
    form_id: 'test_form_key_1',
    app_id: 'test_app',
};

export const mockFormStatusObject = <FormStatusObject>{
    status: FormStatus.PendingApproval,
    owner_id: 'testUser1'
};

export const mockFormStatusModel = <{ [formKey: string]: FormStatusModel }>{
    ['testFormKey']: {
        ['testDemographickKey1']: {
            status: FormStatus.Approved,
            owner_id: 'testUser1'
        },
        ['testDemographicsKey2']: {
            status: FormStatus.PendingApproval,
            owner_id: 'testUser1'
        }
    }
};
