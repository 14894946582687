import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable, Subject } from 'rxjs';

import { AuthenticatedUserService } from '@app/core/services';
import { Members, ActiveDemographics, DemographicNames } from '@app/core/models';
import { FormStatusModel, FormStatusObject, FormStatus } from '@app/core/models/form-status.model';
import { FoundResponse, SearchForResponsesData } from '@app/core/models/response.model';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

export interface UserKeyWithDemographics {
    key: string;
    demographicKeys: string[];
}

@Injectable({
    providedIn: 'root'
})
export class FormInfoService {
    private _navigationTriggeredSubject = new Subject<boolean>();

    public readonly navigationTriggered$ = this._navigationTriggeredSubject.asObservable();

    constructor(
        private authService: AuthenticatedUserService,
        private db: AngularFireDatabase,
        private afFun: AngularFireFunctions,
    ) { }

    searchFormResponses(data: SearchForResponsesData): Observable<FoundResponse[]> {
        return this.afFun.httpsCallable('searchFormResponses')(data);
    }

    setNavigationTriggered(): void {
        this._navigationTriggeredSubject.next(true);
        setTimeout(() => { this._navigationTriggeredSubject.next(false) }, 2000)
    }

    toggleViewMode(activityKey: string, userKey: string, newState?: boolean): Promise<void> {
        const updates = {};
        updates[`ssot/condensed_view/${activityKey}/${userKey}`] = newState || null;
        return this.db.object('/').update(updates);
    }

    getViewMode(activityKey: string, userKey: string): Observable<boolean> {
        return this.db.object<boolean>(`/ssot/condensed_view/${activityKey}/${userKey}`).valueChanges();
    }

    getUsersWithDemographics(
        statesMap: FormStatusModel = {},
        activeDemographics: ActiveDemographics,
        demographicClassKey: string,
        demographicNames: DemographicNames
    ): UserKeyWithDemographics[] {
        const currentUserKey = this.authService.getCurrentUserId();

        if (typeof statesMap === 'string') {
            const { status, owner_id } = statesMap as FormStatusObject;

            if (status === FormStatus.Locked) {
                return [{ key: owner_id, demographicKeys: [] }];
            }
        }

        const currentUserDemographics = this.getDemographics(demographicClassKey, activeDemographics, demographicNames, currentUserKey);
        const demographicsPerUser = Object.keys(statesMap || {}).reduce((accum, demoKey) => {
            if (statesMap[demoKey].status === FormStatus.Locked) {
                const userKey = statesMap[demoKey].owner_id;
                const demographics = accum[userKey] || [];
                if (currentUserDemographics.includes(demoKey)) {
                    demographics.push(demoKey);
                }
                if (!demographics.length) {
                    return accum;
                }
                if (userKey) {
                    return { ...accum, [userKey]: demographics };
                }
                return accum;
            }
            return accum;
        }, {});
        return Object.keys(demographicsPerUser).map(userKey => ({ key: userKey, demographicKeys: demographicsPerUser[userKey] }));
    }

    getMembers(
        demographicClassKey: string,
        activeDemographics: ActiveDemographics,
        demographicNames: DemographicNames,
        members: Members
    ) {

        // activity without demographics
        if (!activeDemographics) {
            return Object.keys(members || {}).reduce((accum, key) => {
                if (members[key] !== 'observer') {
                    const user = { key, demographicKeys: [] };
                    return [...accum, user];
                }
                return accum;
            }, []);
        }

        // activity with demographics
        const currentUserKey = this.authService.getCurrentUserId();
        const currentUserDemographics = this.getDemographics(demographicClassKey, activeDemographics, demographicNames, currentUserKey);
        return Object.keys(members || {}).reduce((accum, key) => {
            if (members[key] !== 'observer') {
                const demographicKeys = this.getDemographics(
                    demographicClassKey,
                    activeDemographics,
                    demographicNames,
                    key,
                    currentUserDemographics
                );
                if (demographicKeys.length) {
                    const user = { key, demographicKeys };
                    return [...accum, user];
                }

                return accum;
            }
            return accum;
        }, []);
    }

    getDemographics(
        demographicClassKey: string,
        activeDemographics: ActiveDemographics,
        demographicNames: DemographicNames,
        userKey: string,
        filterBy?: string[]
    ): string[] {
        if (!demographicClassKey) {
            return [];
        }

        const userActiveDemographics = (activeDemographics || {})[`${userKey}_${demographicClassKey}`];

        if (!userActiveDemographics || userActiveDemographics === 'empty') {
            return [];
        }

        if (userActiveDemographics === 'all') {
            const demographicsKeys = Object.keys(demographicNames || {});
            return this.filterlist<string>(demographicsKeys, filterBy);
        }

        return this.filterlist<string>(userActiveDemographics, filterBy);
    }

    private filterlist<T>(list: T[], filter: T[]): T[] {
        if (!filter) {
            return list;
        }
        return list.reduce((accum, item) => {
            if (filter.includes(item)) {
                return [...accum, item];
            }
            return accum;
        }, []);
    }
}
