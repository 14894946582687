import { Subprocess } from './subprocess.model';
import { ObjectType } from './deployment.model';
import { DeploymentItem } from './deployment-item';

export class Process extends DeploymentItem {
    module_id?: string;
    activities?: any[];
    subprocesses?: Subprocess[];
    type = ObjectType.Process;

    constructor(process: Process) {
        super(process);
        this.module_id = process.module_id || '';
        this.activities = process.activities || [];
        this.subprocesses = process.subprocesses || [];
    }
}

export const testProcess = {
  id: 'test_process',
  deployment_id: 'test_deployment1',
  module_id: 'test_module1',
  subprocesses: [],
  global: false,
  object_type: 'process',
  name: 'test',
  type: ObjectType.Process
};
