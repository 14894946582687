<div *ngIf="(vm$ | async) as vm">
  <div *ngIf="!vm.isLoading" class="form-impact">
    <div class="form-impact-header">
        <p class="h5">Change Impact</p>
        <button
          class="gs-button-ghost gs-small"
          (click)="navigateToForm(vm.deploymentId, vm.formId)"
        >
          Back To Collect
        </button>
    </div>

    <gs-impact-table
      [formId]="vm.formId"
      [formName]="'Name'"
      [deploymentId]="vm.deploymentId"
      [impactData]="vm.impactData"
    ></gs-impact-table>
  </div>
</div>
