<div class="users-panel">
  <!-- Navigation -->
  <div class="header-content">
    <span *ngIf="isModule" class="tabs-label is-module text-medium">
      Owners
    </span>
    <ul *ngIf="!isModule" class="gs-tabs">
      <li class="gs-tab" [ngClass]="{ active: isOwnersActive }">
        <a
          (click)="setSelectedNavigationItem(UsersPanelNavigationItem.Owners)"
          [attr.data-content]="getOwnerNumber"
        >
          Owners
        </a>
      </li>
      <li
        *ngIf="isSubProcess"
        class="gs-tab"
        [ngClass]="{ active: !isOwnersActive }"
      >
        <a
          (click)="setSelectedNavigationItem(UsersPanelNavigationItem.Stakeholders)"
          [attr.data-content]="getStakeholderNumber"
        >
          SMEs
        </a>
      </li>
    </ul>
  </div>
  <!-- /Navigation -->

  <div class="tabs-content gs-card-wrapper">
    <ng-container *ngIf="isOwnersActive">
      <div class="tabs-content-tab">
        <div class="tabs-content-title overline">
          Name
        </div>
        <gs-users-panel-tab
          *ngIf="hasOwners"
          [demographics]="demographics"
          [users]="owners$.asObservable()"
          [isModule]="isModule"
          [isGlobal]="isGlobal"
          [processHaveSomeGlobalSubprocesses]="processHaveSomeGlobalSubprocesses"
        ></gs-users-panel-tab>
        <div
          *ngIf="!hasOwners"
          class="message-no-data"
        >
          There are no assigned Owners yet
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!isOwnersActive">
      <div class="tabs-content-tab">
        <div class="tabs-content-title overline">
          Name
        </div>
        <gs-users-panel-tab
          *ngIf="hasStakeholders"
          [demographics]="demographics"
          [users]="stakeholders$.asObservable()"
          [isStakeholder]="true"
          [isGlobal]="isGlobal"
          [deploymentId]="deploymentId"
        ></gs-users-panel-tab>
        <div
          *ngIf="!hasStakeholders"
          class="message-no-data"
        >
          There are no assigned SMEs yet
        </div>
      </div>
    </ng-container>
  </div>
</div>
