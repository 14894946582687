import { GraphNode } from './graph-node.model';
import { Variable } from './variable.model';

export class StringCompareNode extends GraphNode {

    type = 'string_compare';
    /* Reference string is the value to compare against */
    referenceString: string;

    loadData(data:object) {
        super.loadData(data);
        this.referenceString = data['reference_string'] || '';
    }

    async setInput(name: string, variableData: Variable): Promise<void> {
        console.log(`StringCompareNode::setInput - ${this.nodeId} ${name}`, this.consoleLogVariable(variableData));
        if (name === 'test_string') {
            const value = variableData.value === this.referenceString;
            const passVariable = <Variable> {
                type: 'boolean',
                ...this.buildVariable(variableData.demographicId, value)
            };
            await this.propagateVariable(passVariable);
        }
    }

}
