import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { isEqual as _isEqual } from 'lodash';

import { trackById, Demographic } from '@thinktank/common-lib';

const globalDemographic = {
    id: 'global',
    key: 'global',
    name: 'Global'
};

@Component({
    selector: 'gs-demographics-panel',
    templateUrl: './demographics-panel.component.html',
    styleUrls: ['./demographics-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DemographicsPanelComponent implements OnChanges {
    @Input() demographics: Demographic[];
    @Input() processHaveSomeGlobalSubprocesses: boolean;
    @Output() onUpdate: EventEmitter<string[]> = new EventEmitter<string[]>();

    trackById = trackById;

    sortedDemographics: { [demographicId: string]: Demographic };
    demographicsActive: { [demographicId: string]: Demographic };
    demographicsInactive: { [demographicId: string]: Demographic };

    constructor() {
        this.initializeComponent();
    }

    get showManagePanel(): boolean {
        return !!(this.hasInactiveDemographics);
    }

    get hasInactiveDemographics(): boolean {
        return !!Object.keys(this.demographicsInactive).length;
    }

    removeActiveDemographic(demographicId: string): void {
        this.demographicsInactive[demographicId] = this.demographicsActive[demographicId];
        delete this.demographicsActive[demographicId];

        this.onUpdate.emit(
            Object.keys(this.demographicsActive)
        );
    }

    addActiveDemographic(demographicId: string): void {
       this.demographicsActive[demographicId] = this.demographicsInactive[demographicId];
        delete this.demographicsInactive[demographicId];

        this.onUpdate.emit(Object.keys(this.demographicsActive));
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.didDemographicsChange(changes.demographics) && !!this.demographics) {
            if (Object.keys(this.demographicsActive).length > 0) {
                // TODO: if change - check changes and ad to active new demographics
            }

            if (this.processHaveSomeGlobalSubprocesses) {
              this.demographics.push(globalDemographic);
            }

            this.demographicsActive = this.demographics
                .reduce((accumulator, item) => ({ ...accumulator, [item.id]: item }), {});

            this.onUpdate.emit(
                Object.keys(this.demographicsActive)
            );
        }
    }

    private didDemographicsChange(change: SimpleChange): boolean {
        return !!change && !_isEqual(change.previousValue || [], change.currentValue || []);
    }

    private initializeComponent(): void {
        this.demographicsActive = {};
        this.demographicsInactive = {};
    }
}
