import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { BaseQuestionComponent } from '../base-question/base-question.component';
import { Attachment, trackByValue, WindowService } from '@thinktank/common-lib';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-attachment-question',
    templateUrl: './attachment-question.component.html',
    styleUrls: ['./attachment-question.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy, OnChanges {

    // private displayAttachments: Attachment[] = [];
    ownerFullName: string;
    disabled: boolean;
    trackByValue = trackByValue;

    constructor(
        @Inject(DeploymentFormFacadeToken) formFacade: DeploymentFormFacade,
        private windowService: WindowService
    ) {
        super(formFacade);
    }

    downloadFile(attachment: Attachment): void {
        this.windowService.open(attachment.download_url);
    }

    openFilePicker(event: any): void {
        this.formFacade.openFilePicker();
    }

    removeFile(attachment: Attachment, currentAttachments: Attachment[]): void {
        this.formFacade.deleteFormAttachment(currentAttachments, attachment, this.question, this.demographic);
    }

    ngOnInit(): void {
        this.onValueChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`AttachmentQuestionComponent::ngOnChanges - changes for ${this.question.id}`, changes);
        // if (changes.question && changes.question.currentValue.attached_files) {
        //     this.displayAttachments = changes.question.currentValue.attached_files;
        // }
    }

    ngOnDestroy(): void {
        this.onDestroy();
    }

}
