export interface RoutingFormChangeAnswerKeys {
    questionKey: string;
    sectionKey?: string;
    demographicKey?: string;
    sectionDemographicKey?: string;
    parentQuestionKey?: string;
    from?: string;
}

export interface RoutingFormStateKeys {
    questionKey: string;
    sectionDemographicKey?: string;
    sectionQuestionKey?: string;
}

export const initialFormState: RoutingFormStateKeys = {
    questionKey: null,
    sectionDemographicKey: null,
    sectionQuestionKey: null
};
