import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { ModuleEditorViewState } from '../../models';

export interface ModuleEditorFacade {
    vm$: Observable<ModuleEditorViewState>;
}

export const ModuleEditorFacadeToken = new InjectionToken<ModuleEditorFacade>('ModuleEditorFacade');
