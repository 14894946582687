import { Pipe, PipeTransform } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

@Pipe({
    name: 'timestampToDate',
    pure: true
})
export class TimestampToDatePipe implements PipeTransform {

    transform(value: firebase.firestore.Timestamp): Date {
        return !!value ? value.toDate() : null;
    }

}
