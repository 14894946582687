<app-login-header *ngIf="!isLoading"></app-login-header>

<div
  class="login-wrapper"
  fxLayout="row"
  fxLayoutAlign="center center"
  appBackgroundImages
  [imagesList]="backgroundImages"
>
  <ng-container *ngIf="isLoading; then loading; else prompt"> </ng-container>

  <ng-template #loading>
    <mat-spinner class="button-ripple-light" [strokeWidth]="5" [diameter]="200"></mat-spinner>
  </ng-template>

  <ng-template #prompt>
    <mat-card class="box">
      <mat-card-header>
        <img src="/assets/images/acn/tt_logo.png" alt="Accenture ThinkTank" class="logo" />
      </mat-card-header>
      <form class="signon-form" [formGroup]="emailForm" (ngSubmit)="passwordReset()">
        <mat-card-content>
          <mat-form-field class="signon-full-width">
            <input type="email" matInput placeholder="E-Mail" name="email" formControlName="email" />
            <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="email.hasError('required')"> Please enter your email </mat-error>
          </mat-form-field>
        </mat-card-content>
        <mat-error>
          <span>{{ errorMessage }}</span>
        </mat-error>
        <span *ngIf="confirmation"> Please check your inbox for an email on how to reset your password </span>
        <br />
        <br />
        <button mat-stroked-button color="primary" class="btn-block" type="submit" [disabled]="!emailForm.valid">
          Submit
        </button>
      </form>
    </mat-card>
  </ng-template>
</div>
