import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirestoreService } from '@thinktank/common-lib';
import { FilterAppDefaultData } from '@app/navigation/navigation-filters/navigation-filters.component.models';

@Injectable({
    providedIn: 'root',
})
export class FilterAppStorageService {
    constructor(private firestoreService: FirestoreService) {}

    async addFilterAppDefaultData(currentUserKey: string, data: FilterAppDefaultData): Promise<void> {
        await this.firestoreService.update(`/app_filters_values/${currentUserKey}`, data);
    }

    async removeFilterAppDefaultData(currentUserKey: string): Promise<void> {
        await this.firestoreService.delete(`/app_filters_values/${currentUserKey}`);
    }

    getDefaultFilters(currentUserKey: string): Observable<FilterAppDefaultData> {
        return this.firestoreService.getDocument<FilterAppDefaultData>(`/app_filters_values/${currentUserKey}`);
    }
}
