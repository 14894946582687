<div
  class="info-snackbar"
  [ngClass]="{
    'loading': isLoading,
    'ready': isReady
  }"
  (click)="setDeployment()"
>
  <div *ngIf="isLoading" class="info-snackbar-spinner">
    <gs-spinner
      class="gs-global-spinner-normal"
    ></gs-spinner>
  </div>
  <mat-icon *ngIf="isReady">
    info
  </mat-icon>
  <p class="subtitle-small">
    <b class="bold">{{ deploymentName }} {{ clientName }}</b> {{ text }}
  </p>
  <mat-icon class="close" (click)="close()">
    close
  </mat-icon>
</div>
