import { Injectable } from '@angular/core';
import { allAcceptedImageTypesToDownload } from '@thinktank/common-lib';

@Injectable()
export class ImagesService {
    private readonly imageExtensions = allAcceptedImageTypesToDownload;

    constructor() {
    }

    isImage(filename: string): boolean {
        const extension = filename.split('.').pop().toLowerCase();

        return this.imageExtensions.includes(extension);
    }
}
