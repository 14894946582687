import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { ChangeRequest, ChangeRequestStatus, Demographic, FormQuestion } from '@thinktank/common-lib';

export interface ChangeRequestService {

    createChangeRequest(
        newResponse: any,
        question: FormQuestion,
        demographics: Demographic[],
        formId: string,
        subprocessId: string,
        processId: string,
        moduleId: string,
        deploymentId: string
    ): Promise<void>;

    updateChangeRequestStatus(changeRequestToUpdate: ChangeRequest, newStatus: ChangeRequestStatus): Promise<void>;

    getChangeRequestsByModuleId(deploymentId: string, moduleId: string): Observable<ChangeRequest[]>;

    getChangeRequestsByProcessId(deploymentId: string, processId: string): Observable<ChangeRequest[]>;

    getChangeRequestsBySubprocessId(deploymentId: string, subprocessId: string): Observable<ChangeRequest[]>;

    getChangeRequestsByDeploymentFormId(deploymentId: string, formId: string): Observable<ChangeRequest[]>;
}

export const ChangeRequestServiceToken = new InjectionToken<ChangeRequestService>('ChangeRequestService');
