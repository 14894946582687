import { ChangeDetectionStrategy, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormItemType } from '@thinktank/common-lib';
import { BaseQuestionComponent } from '../base-question/base-question.component';
import { DeploymentFormFacadeToken, DeploymentFormFacade } from '../../deployment-form.facade';

@Component({
    selector: 'gs-text-question',
    templateUrl: './text-question.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TextQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy, OnChanges {

    constructor(@Inject(DeploymentFormFacadeToken) formFacade: DeploymentFormFacade) {
        super(formFacade);
    }

    get isShortText(): boolean {
        return this.question.type === FormItemType.ShortText || this.question.type === FormItemType.Numeric;
    }

    get isLongText(): boolean {
        return this.question.type === FormItemType.LongText;
    }

    ngOnInit(): void {
        this.onValueChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`TextQuestionComponent::ngOnChanges - changes for ${this.question.id}`, changes);
    }

    ngOnDestroy(): void {
        this.onDestroy();
    }

}
