import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { combineLatest, Observable, BehaviorSubject, of } from 'rxjs';
import { map, mergeMap, tap, delay, distinctUntilChanged } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { isEqual } from 'lodash';

import {
    Demographic,
    FormStatus,
    Form
} from '@app/core/models';
import { FirebaseDemographicsService } from '@app/core/services/demographics.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FormStatusObject, formStatusesMap } from '@app/core/models/form-status.model';
import { sortByStatuses } from '@app/core/utils';
import { DemographicsStoreActions, DemographicsStoreSelectors } from '@app/root-store/features/demographics';
import { AppState } from '@app/root-store/state';
import { FormComponentsService } from '@app/core/services/form-components.service';

@Injectable()
export class FormDemographicsService {
    private selectedDemographic: BehaviorSubject<Demographic> = new BehaviorSubject<Demographic>(undefined);

    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private fbDemographicsService: FirebaseDemographicsService,
        private store$: Store<AppState>,
        private formComponentsService: FormComponentsService
    ) { }

    updateQuestionAccepted(questionKey: string, demographicKey?: string): Promise<void> {
        return this.formComponentsService.updateQuestionAccepted(
            questionKey,
            demographicKey
        );
    }

    getSelectedDemographic(): Observable<Demographic> {
        return this.selectedDemographic.asObservable();
    }

    updateSelectedDemographic(demographic: Demographic): void {
        this.selectedDemographic.next(demographic);
    }

    // if filter == true - get only active demographics
    // if filter == false - get active demographics and inactive demographics
    getFormDemographics(
        formKey: string,
        classKey: string,
        filter?: boolean
    ): Observable<Demographic[]> {
        const limitLeaderInitiallyTo = 1;
        const isLeader = this.authUserService.isUserLeaderByCurrentRoute();
        const userId = this.authUserService.getCurrentUserId();


        return combineLatest([
            this.store$.pipe(select(DemographicsStoreSelectors.formActiveDemographicsKeysObjectSelector)),
            this.store$.pipe(select(DemographicsStoreSelectors.formDemographicClassIdObjectSelector))
        ])
            .pipe(
                tap(([formActiveDemographicsKeys, formDemographicClassIdObject]) => {
                    if (!formActiveDemographicsKeys[`${classKey}_${userId}_${formKey}`]) {
                        this.store$.dispatch(DemographicsStoreActions.getActiveFormDemographicKeys({
                            demographicsClassKey: classKey,
                            userId,
                            formKey
                        }));
                    }
                    if (!formDemographicClassIdObject[formKey]) {
                        this.store$.dispatch(DemographicsStoreActions.getFormDemographicsClassId({
                            formKey: formKey
                        }));
                    }
                }),
                mergeMap(() => {
                    return this.store$.pipe(
                        select(DemographicsStoreSelectors.getFormDemographicsSelector,
                            {
                                classKey,
                                userKey: userId,
                                formKey,
                                limitLeaderInitiallyTo,
                                isLeader,
                                filter
                            }
                        ),
                        distinctUntilChanged(isEqual),
                        tap((result) => {
                            if (result.needWriteInitialActiveDemographics) {
                                this.writeInitialActiveDemographics(result.formKey, result.classKey, result.initialActiveDemographics);
                            }
                        }),
                        delay(0), // fixes for ExpressionChangedAfterItHasBeenCheckedError
                        map((result) => result.demographics)
                    );
                })
            );
    }

    // all form demographics of current form demographic class with statuses
    // this method show all demographics for user who is leader and
    // demographics selected in demographic activity for participant
    getFormDemographicsWithStatuses(
        activityKey: string,
        formKey: string,
        classKey: string,
        alignmentEnabled: boolean
    ): Observable<Demographic[]> {
        const demographics$ = this.getFormDemographics(formKey, classKey, false);
        const statuses$ = this.db.object<FormStatusObject>(`/ssot/formStatus/${activityKey}/${formKey}`).valueChanges();
        return combineLatest([demographics$, statuses$]).pipe(
            map(([demographics, statuses]) => this.combineDemographicsWithStatuses([demographics, statuses], alignmentEnabled)),
            map(sortByStatuses)
        );
    }

    // all demographics of current form demographic class with statuses (1 demographic class for all users)
    getFormDemographicStatusesByFormId(
        activityKey: string,
        formKey: string,
        demographicClassKey: string,
        alignmentEnabled: boolean
    ): Observable<Demographic[]> {
        const demographics$ = this.fbDemographicsService.getDemographics(demographicClassKey);
        const statuses$ = this.db.object<FormStatusObject>(`/ssot/formStatus/${activityKey}/${formKey}`).valueChanges();
        return combineLatest([demographics$, statuses$]).pipe(
            map(([demographics, statuses]) => this.combineDemographicsWithStatuses([demographics, statuses], alignmentEnabled)),
            map(sortByStatuses)
        );
    }

    // get demographics which selected by user with statuses
    getFormDemographicStatusesByFormIdForUser(
        userKey: string,
        demographicClassKey: string,
        activityKey: string,
        formKey: string,
        alignmentEnabled: boolean
    ): Observable<Demographic[]> {
        const demographics$ = this.fbDemographicsService.getSelectedDemographicsForUser(userKey, demographicClassKey);
        const statuses$ = this.db.object<FormStatusObject>(`/ssot/formStatus/${activityKey}/${formKey}`).valueChanges();
        return combineLatest([demographics$, statuses$]).pipe(
            map(([demographics, statuses]) => this.combineDemographicsWithStatuses([demographics, statuses], alignmentEnabled)),
            map(sortByStatuses)
        );
    }

    private combineDemographicsWithStatuses([demographics, statuses], alignmentEnabled): any {
        return demographics.map((demographic) => {
            const statusObject = (statuses || {})[demographic.key];
            const defaultStatus = !alignmentEnabled ? FormStatus.InProgress : FormStatus.Draft;
            let status;
            if (!statusObject || (statusObject && !statusObject.status)) {
                status = defaultStatus;
            } else {
                status = (statusObject.status === FormStatus.Denied) ? defaultStatus : statusObject.status;
            }

            return {
                ...demographic,
                status: formStatusesMap[status]
            };
        });
    }

    getParentFormDemographics(form: Form): Observable<Demographic[]> {
        return this.db.object(`ssot/_forms/${form.parent_form_id}`)
            .valueChanges()
            .pipe(
                mergeMap((parentForm: Form) => {
                    return this.getFormDemographics(form.key, parentForm.demographics_class_id, true);
                })
            );
    }

    getParentFormDemographicClass(formKey: string): Observable<string> {
        return this.db.object<string>(`ssot/_forms/${formKey}/demographics_class_id`)
            .valueChanges();
    }

    removeDemographicFromActive(
        formKey: string,
        classKey: string,
        demographicKey: string,
        activeDemographics: Demographic[]
    ): Promise<void> {
        const userId = this.authUserService.getCurrentUserId();
        const updates = {};
        const newActiveDemographics = activeDemographics
            .filter((demographic: Demographic) => !(demographic.key === demographicKey))
            .map((demographic: Demographic) => demographic.key);

        // set empty flag if all demographics are inactive
        updates[`ssot/_forms/${formKey}/active_demographics/${userId}_${classKey}`] = newActiveDemographics.length
            ? newActiveDemographics
            : 'empty';
        return this.db.object('/').update(updates);
    }

    addDemographicToActive(
        formKey: string,
        classKey: string,
        demographicKey: string,
        activeDemographics: Demographic[]
    ): Promise<void> {
        const userId = this.authUserService.getCurrentUserId();
        const updates = {};
        const newActiveDemographics = activeDemographics
            .map((demographic: Demographic) => demographic.key) || [];
        newActiveDemographics.push(demographicKey);
        updates[`ssot/_forms/${formKey}/active_demographics/${userId}_${classKey}`] = newActiveDemographics;
        return this.db.object('/').update(updates);
    }

    addAllInactiveDemographicsToActive(
        formKey: string,
        classKey: string,
        activeDemographics: Demographic[],
        inactiveDemographics: Demographic[],
        filteredDemographics: Demographic[]
    ): Promise<void> {
        const userId = this.authUserService.getCurrentUserId();
        const updates = {};
        const filteredLn = filteredDemographics.length;
        const inactiveLn = inactiveDemographics.length;

        const notAdd = !filteredLn || !inactiveLn;
        const addFiltered = filteredLn !== inactiveLn;
        if (notAdd) {
            return Promise.resolve();
        }
        if (addFiltered) {
            updates[`ssot/_forms/${formKey}/active_demographics/${userId}_${classKey}`] = activeDemographics
                .concat(filteredDemographics)
                .map((demographic: Demographic) => demographic.key);
            return this.db.object('/').update(updates);
        }

        updates[`ssot/_forms/${formKey}/active_demographics/${userId}_${classKey}`] = activeDemographics
            .concat(filteredDemographics)
            .map(demographic => demographic.key);
        return this.db.object('/').update(updates);
    }

    getFormActiveDemographicsKeys(formKey: string, classKey: string): Observable<string | string[]> {
        const userId = this.authUserService.getCurrentUserId();

        return this.store$
            .pipe(
                select(DemographicsStoreSelectors.activeFormDemographicKeysSelector,
                    {
                        classKey,
                        formKey,
                        userKey: userId
                    }
                ));
    }

    getFormDemographicsClassId(formKey: string): Observable<string> {
        return this.store$
            .pipe(
                select(DemographicsStoreSelectors.formDemographicClassIdSelector, { formKey })
            );
    }

    getUserSelectedDemographics(demographicClassKey: string): Observable<Demographic[]> {
        return this.fbDemographicsService.getSelectedDemographicsByClass(demographicClassKey);
    }

    getDemographicsActivityKey(appKey: string): Observable<string> {
        return this.fbDemographicsService.getDemographicsActivityKey(appKey);
    }

    getSelectedDemographicsByActivityId(activityId: string): Observable<string[]> {
        const userId = this.authUserService.getCurrentUserId();

        return combineLatest([
            this.formComponentsService.getFormsByActivity(activityId),
            this.store$.pipe(select(DemographicsStoreSelectors.selectedDemographicsObjectSelector))
        ])
            .pipe(
                this.addUndefinedDemographics(userId),
                map(([forms,]) => forms),
                map((forms): Observable<any> => {
                    const demographicClassesIds = [];
                    const demographicClasses = forms.map((form: Form) => {
                        demographicClassesIds.push(form.demographics_class_id || '');

                        return form.demographics_class_id
                            ? this.getFormDemographics(form.key, form.demographics_class_id, false)
                            : of([]);
                    });
                    return combineLatest([of(demographicClassesIds), ...demographicClasses]);
                }),
                mergeMap(data => data),
                map(([demographicClassesIds, ...demographicClasses]) => {
                    const activeDemographics = [];
                    demographicClasses.forEach((demographics, index) => {
                        const key = demographicClassesIds[index];
                        if (key) {
                            activeDemographics.push(...demographics);
                        }
                    });
                    return activeDemographics.map(({ key }) => key);
                })
            );
    }

    private writeInitialActiveDemographics(formKey: string, classKey: string, demographics: Demographic[]): Promise<void> {
        const userId = this.authUserService.getCurrentUserId();
        const demographicKeys = demographics.map((demographic) => demographic.key);
        return this.db.object(`/ssot/_forms/${formKey}/active_demographics/${userId}_${classKey}`).set(demographicKeys);
    }

    private addUndefinedDemographics(userId: string) {
        return tap(([forms, selectedDemographicsObject]) => {
            forms.forEach(form => {
                if (!!form.demographics_class_id && !selectedDemographicsObject[`${form.demographics_class_id}_${userId}`]) {
                    this.initializeDataForFormDemographics(form.demographics_class_id, userId, form.key);
                }
            });
        });
    }

    private initializeDataForFormDemographics(demographicClassId: string, userId: string, formId: string): void {
        this.store$.dispatch(DemographicsStoreActions.getDemographics({
            demographicsClassKey: demographicClassId
        }));
        this.store$.dispatch(DemographicsStoreActions.getSelectedDemographics({
            demographicsClassKey: demographicClassId,
            userId: userId
        }));
        this.store$.dispatch(DemographicsStoreActions.getActiveFormDemographicKeys({
            demographicsClassKey: demographicClassId,
            userId: userId,
            formKey: formId
        }));
        this.store$.dispatch(DemographicsStoreActions.getFormDemographicsClassId({
            formKey: formId
        }));
    }
}
