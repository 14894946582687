<div class="gs-table-wrapper change-request-table">
  <table class="gs-table">
    <thead>
      <tr>
        <th class="gs-table-th">Requirement</th>
        <th class="gs-table-th">Geographies Impacted</th>
        <th class="gs-table-th">Actions</th>
      </tr>
    </thead>

    <tbody>
      <ng-container *ngIf="changeRequests?.length; else noChangeRequests">
        <tr *ngFor="let changeRequest of changeRequests; trackBy: trackById">
          <td class="gs-table-td">{{changeRequest.question_name}}</td>
          <td class="gs-table-td">
            {{getDemographicsAffected(changeRequest)}}
          </td>
          <td class="gs-table-td">
            <div class="actions">
              <button
                class="gs-button-flat gs-small"
                (click)="showRequest(changeRequest)"
              >
                View Request
              </button>
              <button
                class="gs-button-flat-success gs-small"
                [disabled]="changeRequest.status !== 'pending'"
                (click)="updateChangeRequestStatus(changeRequest, true)"
              >
                Approve
              </button>
              <button
                class="gs-button-flat-danger gs-small"
                [disabled]="changeRequest.status !== 'pending'"
                (click)="updateChangeRequestStatus(changeRequest, false)"
              >
                Deny
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #noChangeRequests>
        <tr>
          <td class="caption text-gray text-center gs-table-td" colspan="7">
            There are no change requests for this subprocess yet
          </td>
        </tr>
      </ng-template>
    </tbody>
  </table>
</div>
