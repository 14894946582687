<div class="copy-app-dialog">
  <h3 mat-dialog-title class="h3">Target Environment Config</h3>
  <mat-dialog-content class="copy-app-dialog-content">
    <p class="text">Please specify the config of the target environment to copy the template to</p>
    <mat-form-field appearance="outline" floatLabel="always" class="textarea">
      <textarea
        matInput
        required
        cdkFocusInitial
        class="copy-app-dialog-textarea"
        placeholder="Paste your config here"
        [formControl]="appConfigControl"
        [value]="appConfigControl.value || ''"
      ></textarea>
      <mat-error *ngIf="appConfigControl.hasError('required')">This field is required</mat-error>
      <mat-error *ngIf="appConfigControl.hasError('requiredFields')"
        >There are no all required fields to access another environment</mat-error
      >
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="disagree-dialog-actions">
    <button cdkFocusInitial class="tt-button-filled" [disabled]="appConfigControl.invalid" (click)="handleConfirm()">
      Copy Template
    </button>
    <button mat-dialog-close class="tt-button-outline">Cancel</button>
  </mat-dialog-actions>
</div>
