import { Attachment, ChangeRequest, Demographic, Form, FormStatus, FormQuestion, ImpactView } from '@thinktank/common-lib';

export interface FormState {
    form: Form;
    canSubmit: boolean;
    availableDemographics: string[];
    assignedDemographics: Demographic[];
    isLoading: boolean;
    changeRequests: ChangeRequest[];
    attachedFiles: Attachment[];
    deploymentId: string;
    authenticatedUserId: string;
    moduleId?: string;
    moduleName?: string;
    processId?: string;
}

export const initialFormState: FormState = {
    form: { name: '', items: [], owner_id: '', parent_id: '', status: {} },
    canSubmit: true,
    availableDemographics: [],
    assignedDemographics: [],
    isLoading: true,
    changeRequests: [],
    attachedFiles: [],
    deploymentId: '',
    authenticatedUserId: '',
    moduleId: '',
    moduleName: '',
    processId: ''
};

export interface FormImpactState {
    formId: string;
    deploymentId: string;
    impactData: ImpactView[];
}

export const initialFormImpactState = {
    formId: '',
    deploymentId: '',
    impactData: []
};

export interface CondensedFormState {
    deploymentId: string;
    alignmentEnabled: boolean;
    collaborationEnabled: boolean;
    form: Form;
    availableDemographics: Demographic[];
    selectedDemographics: Demographic[];
    selectedQuestion: FormQuestion;

    changeRequests: ChangeRequest[];
    attachedFiles: Attachment[];

    alignmentStep?: FormStatus;

    isLoading: boolean;
}

export const initialCondensedFormState = {
    deploymentId: '',
    alignmentEnabled: false,
    collaborationEnabled: false,
    form: { name: '', items: [], owner_id: '', parent_id: '', status: {} },
    availableDemographics: [],
    selectedDemographics: [],
    selectedQuestion: null,
    changeRequests: [],
    attachedFiles: [],
    alignmentStep: FormStatus.Draft,
    isLoading: true
};
