import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'every',
    pure: true
})
export class EveryPipe implements PipeTransform {

    transform(array: any[], value: any): boolean {
        return (array || []).every(item => item === value);
    }

}
