import { Component, Input, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';

import { checkIsMac } from '@app/core/utils';
import { FormStatus, formStatusesSet, formStatusesMap } from '@app/core/models/form-status.model';

@Component({
    selector: 'app-form-state',
    templateUrl: './form-state.component.html',
    styleUrls: ['./form-state.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class FormStateComponent implements OnChanges {
    @Input() state: FormStatus;
    @Input() clickable?: boolean;
    @Input() isAlignment?: boolean;

    formState: string;
    formStateClass: string;
    isMac = checkIsMac();

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
        const defaultState = this.isAlignment ? FormStatus.Draft : FormStatus.InProgress;
        const state = formStatusesMap[this.state ? this.state || defaultState : defaultState];
        const source = formStatusesSet[state as string];
        if (!!source) {
            this.formState = source.state;
            this.formStateClass = source.color;
        }
    }

}
