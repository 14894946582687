import { FormStatus } from './form-status.model';


export class DemographicsClass {
    key?: string;
    name: string;
    description?: string;
    sequence: number;
    options?: {
        [optionKey: string]: Demographic;
    };
    // duplicate of the options field to store data for view
    // see getDemographicsClasses method
    demographics?: Demographic[];
    app_id?: string;
    activity_id?: string;
    owner_id?: string;

    constructor(sequence?: number) {
        this.name = '';
        this.sequence = sequence || 1;
    }
}

export class Demographic {
    key?: string;
    active?: boolean;
    name: string;
    sequence: number;
    class_id?: string;
    activity_id?: string;
    app_id?: string;
    owner_id?: string;
    state?: FormStatus;
    status?: FormStatus;
    alignment_state?: FormStatus; // TODO: review

    constructor(sequence?: number, name?: string) {
        this.name = name || '';
        this.sequence = sequence || 1;
    }
}

export class AllSelectedDemographics {
    [classKey: string]: SelectedDemographics;
}

export class SelectedDemographics {
    activity_id: string;
    class_id?: string;
    app_id?: string;
    user_id?: string;
    options: {
        [demographicKey: string]: boolean;
    };
}

export interface SubmittedDemographics {
    [classKey: string]: {
        [demographicKey: string]: boolean;
    };
}

export class DemographicNames {
    [demographicKey: string]: string
}

export interface ActiveDemographics {
    [userKey_classKey: string]: string[] | 'all' | 'empty';
}

export const testDemographic = <Demographic>{
    name: 'Countries',
    key: 'testKey',
    class_id: 'test_demographic_class',
    sequence: 1
};

export const testDemographicsClass = <DemographicsClass>{
    key: 'testClassKey',
    name: 'test',
    description: '',
    sequence: 1,
    options: {
        'testOptionKey': testDemographic
    },
    demographics: [{
        name: 'test',
        key: 'testKey',
        sequence: 1
    }]
};

export const testSelectedDemographics = <SelectedDemographics>{
    activity_id: 'activity1',
    options: {
        'testOptionKey': true
    }
};

export const mockSelectedDemographics = <Demographic[]>[
    {
        active: true,
        key: 'demograhpicKey1',
        activity_id: 'activity1',
        app_id: 'app1',
        class_id: 'classKey',
        name: 'nameKu1',
        owner_id: 'ownerKey',
        sequence: 1
    }, {
        active: false,
        key: 'demograhpicKey2',
        activity_id: 'activity1',
        app_id: 'app1',
        class_id: 'classKey',
        name: 'nameLu2',
        owner_id: 'ownerKey',
        sequence: 2
    }
];

export const testDemographicNames = {
    ['demograhpicKey1']: 'Name1',
    ['demograhpicKey2']: 'Name2'
};

export const enum DemographicsDetailsPanelNavigationItems {
    Users = 'Users',
    Demographics = 'Demographics'
}
