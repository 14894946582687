<div class="contributions-list-dialog">
  <div mat-dialog-title>
    <h3 mat-dialog-title class="h3 dialog-title">
      {{data.title}}
      <button mat-icon-button mat-dialog-close class="close tt-button-flat">
        <mat-icon>close</mat-icon>
      </button>
    </h3>
  </div>

  <mat-dialog-content class="contributions-list-dialog-content">
    <div class="list">
      <div
        *ngFor="let user of data.usersList" class="list-item">
        <div class="text">
          {{user}}
        </div>
      </div>
    </div>
  </mat-dialog-content>
</div>

