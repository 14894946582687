import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';

import { UserEvent } from '@app/core/models';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';

// this service is created to spread some user events across application
// without changing the model of component data
// For example, it can be used to implement who is typing indicator for some fields
@Injectable({
    providedIn: 'root'
})
export class UserEventsService extends FirebaseUtilityService {
    constructor(
        private db: AngularFireDatabase
    ) {
        super();
    }

    getEvents(path: string[]): Observable<UserEvent[]> {
        const channelKey = this.bootstrapChannelKey(path);

        return this.listWithKeys<UserEvent>(
            this.db.list<UserEvent>(`/ssot/_user_events/${channelKey}`)
        );
    }

    // the path argument is used to generate unique key
    // for further subscribing for events of this type
    triggerEvent(path: string[], userKey: string, data: UserEvent): Promise<void> {
        const channelKey = this.bootstrapChannelKey(path);
        const event = <UserEvent>{
            ...data,
            created_at: Date.now()
        };

        return this.db.object(`/ssot/_user_events/${channelKey}/${userKey}`).set(event);
    }

    private bootstrapChannelKey(path: string[]): string {
        return path.join(':');
    }
}
