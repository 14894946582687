import { Component, Inject, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { FormQuestion, FormUpdatedResponse, Demographic, GlobalDemographic } from '@thinktank/common-lib';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-base-question',
    template: `<p>THIS IS NEVER INSTANTIATED!</p>`
})
export class BaseQuestionComponent {

    @Input() question: FormQuestion;
    @Input() demographic: Demographic;
    @Input() parentForm: FormGroup;
    @Input() deploymentId: string;
    @Input() authenticatedUserId: string;

    protected destroySubject = new Subject<void>(); // why this? why not untilDestroyed?

    constructor(@Inject(DeploymentFormFacadeToken) protected formFacade: DeploymentFormFacade) {
    }

    get questionVisible(): boolean {
        return Object.values(this.question.visible).some((value) => value);
    }

    get controlId(): string {
        if (this.isSectionQuestion) {
            return this.sectionControlName;
        }
        return this.isGlobal ? this.question.id : this.demographic.id;
    }

    get controlName(): string {
        return this.isSectionQuestion || this.isGlobal ? this.question.id : this.demographic.id;
    }

    private get sectionControlName(): string {
        const baseControlName = `${this.question.section_response_id}_${this.question.id}`;
        return this.isGlobal ? baseControlName : `${baseControlName}_${this.demographic.id}`;
    }

    get isSectionQuestion(): boolean {
        return !!this.question.section_node_id;
    }

    protected onValueChanges(): void {
        const control = this.parentForm.get(this.controlName);

        if (!!control) {
            control
                .valueChanges
                .pipe(
                    startWith(''),
                    pairwise(),
                    takeUntil(this.destroySubject)
                )
                .subscribe(([previous, current]) => {
                    if (!isEqual(previous, current)) {
                        console.log('BaseQuestionComponent::onValueChanges - previous', previous);
                        console.log('BaseQuestionComponent::onValueChanges - current', current);
                        this.formFacade.updateResponse(this.buildUpdatedResponse(current), this.deploymentId, this.authenticatedUserId);
                    }
                });
        }
    }

    protected onDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();
    }

    private get isGlobal(): boolean {
        return this.demographic.id === GlobalDemographic.id;
    }

    protected buildUpdatedResponse(response: any): FormUpdatedResponse {
        const updatedResponse = {
            value: response,
            questionId: this.question.id,
            demographicId: this.demographic.id,
            sectionNodeId: this.question.section_node_id,
            sectionResponseId: this.question.section_response_id,
            sectionSequence: this.question.section_sequence,
            formId: this.question.form_id
        };
        if (!this.isSectionQuestion) {
            delete updatedResponse.sectionNodeId;
            delete updatedResponse.sectionResponseId;
            delete updatedResponse.sectionSequence;
        }
        return updatedResponse;
    }
}
