import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormControlHandler } from './form-control-handler';
import { FormItem, FormItemType, FormQuestion, SelectResponse } from '@thinktank/common-lib';

export class QuestionFormControlHandler implements FormControlHandler {
    constructor(protected formBuilder: FormBuilder) {
    }

    build(item: FormItem, demographicId: string): AbstractControl {
        return this.buildQuestionControl(item as FormQuestion, demographicId);
    }

    update(control: FormControl, item: FormQuestion, demographicId: string): void {
        this.handleVisibilityChange(control, item, demographicId);
        const itemResponse = this.getResponse(item, demographicId);
        if (control.value !== itemResponse && Object.keys(item.response).length > 0) {
            control.setValue(itemResponse, { emitEvent: false });
        }
    }

    protected getResponse(question: FormQuestion, demographicId: string): string | string[] | SelectResponse | SelectResponse[] {
        const responseId = !!question.section_response_id ? `${demographicId}_${question.section_response_id}` : demographicId;
        const response = !!question.response && !!question.response[responseId] && question.response[responseId].value
            ? question.response[responseId].value
            : '';

        return !!question.default_response && !response ? question.default_response : response;
    }

    private handleVisibilityChange(control: AbstractControl, item: FormItem, visibilityId: string): void {
        if (item.visible[visibilityId] && control.disabled) {
            // The response is now visible and should be enabled
            control.enable();
        }
        if (!item.visible[visibilityId] && control.enabled) {
            // The response is now invisible and should be disabled
            control.disable();
        }
    }

    private buildQuestionControl(question: FormQuestion, demographicId: string): FormControl {
        const validators = [];
        if (this.isQuestionRequired(question, demographicId)) {
            validators.push(Validators.required);
        }
        if (question.type === FormItemType.Numeric) {
            validators.push(Validators.pattern('[-+]?([0-9]*\.[0-9]+|[0-9]+)'));
        }

        const response = this.getResponse(question, demographicId);
        const itemControl = this.formBuilder.control(response, validators);

        if (!question.visible[demographicId]) {
            itemControl.disable();
        }
        return itemControl;
    }

    private isQuestionRequired(question: FormQuestion, demographicId: string): boolean {
        return !!question.required[demographicId] || !!question.required['default'];
    }

}
