<div class="confirm-dialog">
  <h3 mat-dialog-title class="h3">{{data.title}}</h3>
  <mat-dialog-content class="confirm-dialog-content">
    <p class="text">{{data.text}}</p>
  </mat-dialog-content>
  <div class="confirm-dialog-checkbox" *ngIf="data.checkbox?.showCheckBox">
    <mat-checkbox
      color="primary"
      class="gs-demographic-checkbox"
      [value]="data.checkbox.disableText"
      (change)="data.checkbox.disablePopupHandler($event.checked)"
    >
      <ng-container>{{data.checkbox.disableText}}</ng-container>
    </mat-checkbox>
  </div>
  <mat-dialog-actions class="confirm-dialog-actions">
    <button
      cdkFocusInitial
      class="tt-button-filled"
      [ngClass]="{'danger': isRemove}"
      (click)="handleConfirm()"
    >
      {{data.confirmBtnText}}
    </button>
    <button class="tt-button-outline" (click)="handleCancel()">
      {{data.cancelBtnText}}
    </button>
  </mat-dialog-actions>
</div>
