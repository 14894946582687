import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
    Client,
    init as initFilestackClient,
    PickerFileMetadata,
    PickerOptions,
    PickerResponse,
    TransformOptions,
} from 'filestack-js';
import firebase from 'firebase/compat/app';
import { firstValueFrom } from 'rxjs';
import UploadTaskSnapshot = firebase.storage.UploadTaskSnapshot;

import {
    excelFilesUploadConfig,
    filesUploadConfig,
    imagesUploadConfig,
    presentationFilesUploadConfig,
} from '../filestack.config';
import {
    FilestackStorage,
    FilestackUploadDoneFn,
    FilestackUploadOptions,
    FilestackUploadResult,
    FilestackUploadType,
    FileUpload,
} from '../models';
import { FileUploadService } from '../services/file-upload.service';

@Injectable()
export class FilestackService {
    private _inTransitReason = 'Still has the InTransit status';
    private _inTransitInterval = 1000;
    private _filestackClient: Client;

    constructor(
        private _firebaseFileUploadService: FileUploadService,
        private _http: HttpClient,
        private _angularFireFunctions: AngularFireFunctions,
    ) {}

    async uploadFile(options: FilestackUploadOptions): Promise<void> {
        await this.ensureFilestackClient();

        return new Promise((resolve, reject) => {
            const defaultOptions = this.getPickerOptions(options);
            const storageOptions = this.getStorageOptions(options);

            this._filestackClient
                .picker({
                    ...defaultOptions,
                    ...storageOptions,
                    onUploadStarted: () => {
                        if (options.onUploadStarted) {
                            options.onUploadStarted();
                        }
                    },
                    onUploadDone: async response => {
                        if (response.filesUploaded && response.filesUploaded.length) {
                            await this.onUploadDone(options, response);
                            resolve();
                        } else {
                            if (options.onUploadFailed) {
                                options.onUploadFailed();
                            }
                            resolve();
                        }
                    },
                    onCancel: () => {
                        resolve();
                    },
                })
                .open();
        });
    }

    async getSlidesCount(fileUrl: string): Promise<number> {
        await this.ensureFilestackClient();
        const docInfoUrl = this._filestackClient.transform(fileUrl, {
            output: {
                docinfo: true,
            },
        } as TransformOptions);

        return (await this._http.post(docInfoUrl, null).toPromise())['numpages'];
    }

    private getMetadata(client: Client, handle: string): Promise<any> {
        // build random params to prevent IE caching for requests with the same parameters
        const unnecessaryParams = [
            'size',
            'mimetype',
            'filename',
            'uploaded',
            'writeable',
            'cloud',
            'sourceUrl',
            'location',
        ];
        const timeStampBinary = Date.now().toString(2).slice(-unnecessaryParams.length);
        const randomUnnecessaryParams = timeStampBinary.split('').reduce((result, currentFlag, index) => {
            const currentField = unnecessaryParams[index];
            const currentValue = !!parseInt(currentFlag, 10);

            result[currentField] = currentValue;

            return result;
        }, {});

        return client.metadata(handle, {
            ...randomUnnecessaryParams,
            container: true,
            path: true,
        });
    }

    private async onUploadDone(options: FilestackUploadOptions, response: PickerResponse): Promise<void> {
        switch (options.storeTo) {
            case FilestackStorage.FirebaseStorage:
                return this.prepareDataAndUploadFileToFirebaseStorage(options, response);
            default:
                return this.getGoogleStorageFileUrl(response, options);
        }
    }

    private async prepareDataAndUploadFileToFirebaseStorage(
        options: FilestackUploadOptions,
        response: PickerResponse,
    ): Promise<void> {
        const filestackFile = response.filesUploaded[0];
        const filestackFileId = filestackFile.handle;
        const uploadedImage = {
            $key: filestackFile.handle,
            file: filestackFile.originalFile as File,
            name: filestackFile.filename,
            url: filestackFile.url,
        } as FileUpload;

        await this.uploadFileToFirebaseStorage(uploadedImage, filestackFileId, options.fbFolder, options.onUploadDone);
    }

    private async uploadFileToFirebaseStorage(
        uploadedImage: FileUpload,
        filestackFileId: string,
        folderName: string,
        updateFn: FilestackUploadDoneFn,
    ): Promise<void> {
        const uploadFile = this._firebaseFileUploadService.uploadFile(uploadedImage, folderName);

        uploadFile((uploadSnapshot: UploadTaskSnapshot) => {
            uploadSnapshot.ref.getDownloadURL().then((downloadUrl: string) => {
                if (updateFn) {
                    updateFn({ url: downloadUrl });
                }
                this.completeUpload([filestackFileId]);
            });
        });
    }

    private async getGoogleStorageFileUrl(response: PickerResponse, options: FilestackUploadOptions): Promise<void> {
        const promises: Promise<FilestackUploadResult>[] = response.filesUploaded.map((file: PickerFileMetadata) => {
            const filestackFileId = file.handle;
            const filestackFileUrl = file.url;
            const fileName = file.filename;

            return new Promise((resolve, reject) => {
                const intervalID = setInterval(() => {
                    this.getMetadata(this._filestackClient, filestackFileId)
                        .then(metaRes => {
                            const path = metaRes.path;
                            const container = metaRes.container;

                            if (path && container) {
                                return Promise.resolve(
                                    `https://storage.googleapis.com/${metaRes.container}/${metaRes.path}`,
                                );
                            } else {
                                return Promise.reject(this._inTransitReason);
                            }
                        })
                        .then((downloadURL: string) => {
                            resolve({
                                fileName,
                                filestackFileId,
                                filestackFileUrl,
                                url: downloadURL,
                            });
                            clearInterval(intervalID);
                        })
                        .catch(reason => {
                            if (reason !== this._inTransitReason) {
                                reject(reason);
                                clearInterval(intervalID);
                            }
                        });
                }, this._inTransitInterval);
            });
        });

        const results = await Promise.all(promises);
        const filesIds = [];

        if (options.onUploadDone) {
            for (const result of results) {
                await options.onUploadDone(result);
                filesIds.push(result.filestackFileId);
            }
        }

        this.completeUpload(filesIds);
    }

    private completeUpload(filestackFileIds: string[]): void {
        for (const filestackFileId of filestackFileIds) {
            this._filestackClient.removeMetadata(filestackFileId);
        }
    }

    private getPickerOptions(options: FilestackUploadOptions): PickerOptions {
        switch (options.uploadType) {
            case FilestackUploadType.Images:
                return imagesUploadConfig;
            case FilestackUploadType.EXCELFiles:
                return excelFilesUploadConfig;
            case FilestackUploadType.PresentationFiles:
                return presentationFilesUploadConfig;
            default:
                return filesUploadConfig;
        }
    }

    private getStorageOptions(options: FilestackUploadOptions): PickerOptions {
        return options.storeTo === FilestackStorage.FirebaseStorage
            ? {}
            : {
                  storeTo: {
                      location: 'gcs',
                      path: options.gsFolder ? `/${options.gsFolder}/` : '/uploads/',
                  },
              };
    }

    private async ensureFilestackClient(): Promise<void> {
        if (!this._filestackClient) {
            const { apiKey, security } = await firstValueFrom(
                this._angularFireFunctions.httpsCallable('getFilestackInitializationOptions')({}),
            );

            this._filestackClient = initFilestackClient(apiKey, { security });
        }
    }
}
