import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    ViewChild,
    AfterViewInit,
} from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';

import { User, UserSessionRole } from '@app/core/models';
import { CurrentUsersAppManagementPipe } from '../current-users-app-management.pipe';
import { userWithoutEmailText } from './current-users-table.constants';

@Component({
    selector: 'app-current-users-table',
    templateUrl: './current-users-table.component.html',
    styleUrls: ['./current-users-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentUsersTableComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() users: User[];
    @Input() roleFilter: ('all' | UserSessionRole)[];
    @Input() searchValue: string;
    @ViewChild(MatSort) sort: MatSort;

    tableColumns = ['firstName', 'email', 'app_role', 'last_login'];
    dataSource: MatTableDataSource<any> | null = new MatTableDataSource([]);
    userWithoutEmailText = userWithoutEmailText;
    usersMatchCurrentFilters: User[];

    private currentUsersAppManagementPipe = new CurrentUsersAppManagementPipe();

    constructor(public router: Router) {}

    navigateToProfile(userKey: string): void {
        this.router.navigate(['administration', 'profile', userKey]);
    }

    ngOnInit(): void {
        this.dataSource.data = this.users;
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.roleFilter || changes.searchValue || changes.users) {
            this.dataSource.data = this.currentUsersAppManagementPipe.transform(
                this.users,
                this.roleFilter,
                this.searchValue,
            );
        }
    }
}
