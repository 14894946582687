import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-import-questions-dialog',
    templateUrl: './import-questions-dialog.component.html',
    styleUrls: ['./import-questions-dialog.component.scss']
})

export class ImportQuestionsDialogComponent {
    questionsList: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<ImportQuestionsDialogComponent>
    ) {}

    get placeholder(): string {
        return `Paste ${this.data.entity || 'questions'} from Word or Excel document`;
    }

    onQuestionsListChange() {
        this.questionsList = this.questionsList.split('\t').join('\n');
    }

    onImport(): void {
        const resultQuestions = (this.questionsList || '')
            .split('\n')
            .map(question => question.trim())
            .filter(question => !!question.length);

        this.dialogRef.close(resultQuestions);
    }
}
