<app-header
  class="header"
  [isNavigation]="isNavigation"
  [currentUserKey]="currentUserKey"
  [isAdmin]="isAdmin"
  [currentUserImage]="currentUserImage"
  [currentUserName]="currentUserName"
  [shortUserName]="shortUserName"
  [navigationItems]="navigationItems$.asObservable() | async"
  [selectedNavigationKey]="selectedNavigationKey"
  [selectedNavigationItem]="selectedNavigationItem"
  [doesUserHaveApps]="doesUserHaveApps"
  [hasUserWorkbookPermission]="hasUserWorkbookPermission$ | async"
  [hasUserLeaderSessionRole]="hasUserLeaderSessionRole$ | async"
  [fredgaged]="fredgaged"
  [doesUserHaveDeployments]="doesUserHaveDeployments"
  (logout)="logout()"
  (openTab)="openTab($event)"
></app-header>
