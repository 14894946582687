<div class="users-container">
  <div class="users-header thinktank-theme">
    <h3 class="users-header-title h3">Users</h3>
    <div class="header-filters">
      <!-- search by name-->
      <div class="search">
        <app-search
          [placeholder]="'Search'"
          [currentSearchValue]="searchValue"
          (onChange)="applySearch($event)"
        ></app-search>
      </div>
      <!-- /search by name-->

      <!-- filter by role -->
      <span class="text-small label">Filter by:</span>

      <div class="filter">
        <div class="dropdown white">
          <mat-form-field appearance="outline" class="no-label">
            <mat-select
              multiple
              #rolesFilter
              placeholder="Roles"
              [formControl]="roleControl"
              [panelClass]="['mat-select-panel-with-checkboxes', 'mat-select-panel-custom']"
              [disableOptionCentering]="true"
            >
              <mat-select-trigger>
                {{ showCustomTrigger(rolesFilter.triggerValue, allRoles) }}
              </mat-select-trigger>
              <mat-option #allRoles value="all" (click)="selectAll(roleControl, userRoles)"> All Roles </mat-option>
              <mat-option
                *ngFor="let role of userRoles; trackBy: trackByKey"
                [value]="role"
                (click)="selectionChange(roleControl, userRoles, allRoles)"
              >
                {{ role }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <!-- /filter by role -->
    </div>
  </div>
  <div class="users-content">
    <app-current-users-table
      [users]="users"
      [roleFilter]="roleControl.value"
      [searchValue]="searchValue"
    ></app-current-users-table>
  </div>
</div>
