<app-login-header *ngIf="!isLoading"></app-login-header>

<ng-container *ngIf="isLoading; then loading; else prompt"> </ng-container>

<ng-template #loading>
  <div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-spinner class="button-ripple-light" [strokeWidth]="5" [diameter]="200"></mat-spinner>
  </div>
</ng-template>

<ng-template #prompt>
  <div
    class="login-wrapper"
    fxLayout="row"
    fxLayoutAlign="center center"
    appBackgroundImages
    [imagesList]="backgroundImages"
  >
    <mat-card class="box">
      <mat-card-header class="login-header">
        <img src="/assets/images/acn/tt_logo.png" alt="Accenture ThinkTank" class="logo" />
      </mat-card-header>

      <button mat-button class="sso-login-button" (click)="ssoLogin()">
        Log in with Accenture Single Sign On
        <img src="/assets/images/acn/logo.svg" alt="SSO" class="sso-login" />
      </button>

      <span class="user-login-text">All other users:</span>

      <form class="signon-form" [formGroup]="authForm" (ngSubmit)="login()">
        <mat-card-content>
          <mat-form-field class="signon-full-width">
            <input type="email" matInput placeholder="E-Mail" name="email" formControlName="email" />
            <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="email.hasError('required')"> Please enter your email </mat-error>
          </mat-form-field>

          <mat-form-field class="signon-full-width password-field">
            <input
              matInput
              type="password"
              placeholder="Password"
              name="password"
              formControlName="password"
              [type]="hide ? 'password' : 'text'"
            />
            <mat-icon matSuffix (click)="hide = !hide">
              {{ hide ? "visibility_off" : "visibility" }}
            </mat-icon>
            <mat-error *ngIf="password.hasError('required')"> Please enter your password </mat-error>
          </mat-form-field>
          <div class="forgot-password pt-16">
            <a routerLink="../forgotPassword">
              <mat-label> Forgot Password? </mat-label>
            </a>
          </div>
        </mat-card-content>
        <br />
        <mat-error>
          <span>{{ errorMessage }}</span>
        </mat-error>
        <br />

        <button mat-stroked-button color="primary" class="btn-block" type="submit" [disabled]="!authForm.valid">
          Log in
        </button>
      </form>
    </mat-card>
  </div>
</ng-template>
