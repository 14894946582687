import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, switchMap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';

import { FirestoreService, Attachment } from '@thinktank/common-lib';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { listWithKeys } from '@app/core/utils';
import { FilestackService } from '@app/core/services/filestack.service';
import { FilestackUploadResult } from '../models';
import { GoogleStorageService } from './google-storage.service';

@Injectable()
export class AttachmentService {

    constructor(
        private db: AngularFireDatabase,
        private authenticatedUserService: AuthenticatedUserService,
        private filestackService: FilestackService,
        private firestoreService: FirestoreService,
        private googleStorageService: GoogleStorageService
    ) { }

    getAttachmentsByDeploymentFormId(deploymentId: string, formId: string): Observable<Attachment[]> {
        return this.firestoreService.getDocumentsByProperty<Attachment>(`/deployments/${deploymentId}/attached_files`, 'form_id', formId);
    }

    async filePicker(key: string, type: string, subKey?: string): Promise<void> {
        const attachments = [];
        await this.filestackService
            .uploadFile({
                onUploadDone: ({ fileName, url }: FilestackUploadResult) => {
                    const attachment = this.convertIntoAttachment(type, fileName, url, subKey);
                    attachments.push(attachment);
                }
            });
        await this.createAttachments(key, attachments);
    }

    getAllAttachmentsByActivityKey(activityKey: string): Observable<{ [questionKey: string]: Attachment[] }> {
        let questionKeysArray;
        return this.db.list(`/activities/${activityKey}/questions`)
            .snapshotChanges(['child_added', 'child_changed'])
            .pipe(
                map((questions) => questions.map((questionSnapshot) => questionSnapshot.key)),
                mergeMap((questionKeys) => {
                    questionKeysArray = questionKeys;
                    return combineLatest(questionKeys.map((questionKey) => {
                        return this.db
                            .list<Attachment>(
                                `attachments/${questionKey}`,
                                ref => ref.orderByChild('owner')
                            )
                            .snapshotChanges();
                    }));
                }),
                map((attachmentsLists) => {
                    return attachmentsLists.map((attachmentsList) => {
                        return attachmentsList
                            .filter((attachmentSnapshot) => attachmentSnapshot.payload.val().type === 'response')
                            .map((attachmentSnapshot) => (<Attachment>{
                                ...attachmentSnapshot.payload.val(),
                                key: attachmentSnapshot.key
                            }));
                    });
                }),
                map((attachmentsLists) => {
                    return attachmentsLists.reduce((result, attachmentsList, index) => {
                        const questionKey = questionKeysArray[index];
                        result[questionKey] = attachmentsList;

                        return result;
                    }, {});
                })
            );
    }

    getActivityAttachmentsNamesByQuestionKey(activityKey: string): Observable<{ [questionKey: string]: Attachment[] }> {
        const owner = this.authenticatedUserService.getCurrentUserId();
        let questionKeysArray;
        return this.db.list(`/activities/${activityKey}/questions`)
            .snapshotChanges(['child_added', 'child_changed'])
            .pipe(
                map((questions) => questions.map((questionSnapshot) => questionSnapshot.key)),
                mergeMap((questionKeys) => {
                    questionKeysArray = questionKeys;
                    return combineLatest(questionKeys.map((questionKey) => {
                        return this.db
                            .list<Attachment>(
                                `attachments/${questionKey}`,
                                ref => ref.orderByChild('owner').equalTo(owner)
                            )
                            .snapshotChanges();
                    }));
                }),
                map((attachmentsLists) => {
                    return attachmentsLists.map((attachmentsList) => {
                        return attachmentsList
                            .filter((attachmentSnapshot) => attachmentSnapshot.payload.val().type === 'response')
                            .map((attachmentSnapshot) => (<Attachment>{
                                ...attachmentSnapshot.payload.val(),
                                key: attachmentSnapshot.key
                            }));
                    });
                }),
                map((attachmentsLists) => {
                    return attachmentsLists.reduce((result, attachmentsList, index) => {
                        const questionKey = questionKeysArray[index];
                        result[questionKey] = attachmentsList;

                        return result;
                    }, {});
                })
            );
    }

    getAttachments(key: string): AngularFireList<Attachment> {
        return this.db.list(`/attachments/${key}`);
    }

    getAttachmentByOwner(key: string, owner: string, type?: string): Observable<Attachment[]> {
        return listWithKeys<Attachment>(
            this.db.list<Attachment>(`/attachments/${key}`, ref => ref.orderByChild('owner').equalTo(owner))
        ).pipe(map((attachments: Attachment[]) => {
            return attachments.filter((attachment) => type ? attachment.type === 'response' : true);
        }));
    }

    getAttachmentByType(key: string, type: string): Observable<Attachment[]> {
        return listWithKeys<Attachment>(
            this.db.list<Attachment>(`/attachments/${key}`, ref => ref.orderByChild('type').equalTo(type))
        );
    }

    getAttachmentsWithValidatedUrlsByType(key: string, type: string): Observable<Attachment[]> {
        const attachments$ = listWithKeys<Attachment>(
            this.db.list<Attachment>(`/attachments/${key}`, ref => ref.orderByChild('type').equalTo(type))
        );
        const attachmentsUrls$ = attachments$.pipe(
            distinctUntilChanged((prev, next) => {
                return prev.length === next.length;
            }),
            switchMap(attachments => {
                const attachmentsUrls = attachments.map(attachment => ({ url: attachment.download_url }));
                return this.googleStorageService.validateUrls(attachmentsUrls);
            })
        );

        return combineLatest([
            attachmentsUrls$,
            attachments$
        ]).pipe(
            map(([urlsMap, attachments]) => {
                return attachments.map(attachment => ({
                    ...attachment,
                    download_url: urlsMap[attachment.download_url]
                }));
            })
        );
    }

    getAttachmentDownloadURL(key: string, attachmentKey: string): Observable<any> {
        return this.db.object(`/attachments/${key}/${attachmentKey}`).valueChanges();
    }

    createAttachment(key: string, attachment: Attachment): Promise<any> {
        const pushID = this.db.createPushId();
        return this.db.object(`/attachments/${key}/${pushID}`).update({
            ...attachment,
            owner: this.authenticatedUserService.getCurrentUserId(),
            date_created: +(new Date())
        })
            .then(() => {
                return Promise.resolve(pushID);
            });
    }

    createAttachments(key: string, attachments: Attachment[]): Promise<void> {
        const results = attachments.reduce((result, attachment) => {
            const pushId = this.db.createPushId();
            result[pushId] = {
                ...attachment,
                owner: this.authenticatedUserService.getCurrentUserId(),
                date_created: +(new Date())
            };

            return result;
        }, {});

        return this.db.object(`/attachments/${key}`).update(results);
    }

    deleteAttachment(key: string, attachmentKey: string): void {
        this.db.object(`/attachments/${key}/${attachmentKey}`).remove();
    }

    private convertIntoAttachment(type: string, filename: string, downloadURL: string, subKey?: string): Attachment {
        const attachment = {
            name: filename,
            type,
            download_url: downloadURL
        } as Attachment;

        if (subKey) {
            attachment.sub_key = subKey;
        }

        return attachment;
    }
}
