import { Injectable, Inject } from '@angular/core';

import { DownloadUrlInfo } from '../models/download-url-info.model';
import { GoogleStorageService } from './google-storage.service';
import { getFileNameWithoutPrefix, parseUrl } from '@thinktank/common-lib';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadingService {

    constructor(
        private _googleStorageService: GoogleStorageService,
        @Inject('Window') private window: Window
    ) { }

    async downloadUrls(filesInfo: DownloadUrlInfo[], openInNewWindow?: boolean): Promise<void> {
        if (!filesInfo.length) { return; }

        const linksMap = await this._googleStorageService.validateUrls(filesInfo);

        for (const { url } of filesInfo) {
            const src = linksMap[url];

            if (openInNewWindow) {
                window.open(src);
            } else {
                const iframe = document.createElement('iframe');
                document.body.appendChild(iframe);
                iframe.src = src;
            }
        }
    }

    async openInNewWindow(url: string, fileName?: string): Promise<void> {
        const urlsMap = await this._googleStorageService.validateUrls([{ url, fileName }]);
        const validUrl = urlsMap[url];
        this.window.open(validUrl);
    }

    getFileNameToDownloadFromUrl(url: string): string {
        const parsedFileName = parseUrl(url).fileName;
        return getFileNameWithoutPrefix(parsedFileName);
    }
}
