const firstConfirmationText = (type: string) =>
    `Are you sure you want to remove this ${type}? You will lose all associated data.`;

const secondConfirmationText = (type: string) =>
    `We know how hard you worked on this ${type}. We really hate to see you lose all that time ` +
    'you and your team invested. This will seriously be gone forever with all the associated data ' +
    'if you click \'Remove\'.';

export const getConfirmationText = (step: number, type: string) => {
    switch (step) {
        case 0:
            return firstConfirmationText(type);
        case 1:
            return secondConfirmationText(type);
    }
};

export const demographicsModalTitle = 'Select Demographics for Export';

export const duplicateActivityTitle = 'Responses and Event Flow Settings';

export const duplicateActivityText =
    'Responses, repeated sections and event flow settings applied to this activity will not be carried over to the duplicated activity';

export const confirmCopyActivityTitle = 'Responses, Event Flow, and Demographics Settings';

export const confirmCopyActivityText =
    'Responses, repeated sections, event flow and demographics settings applied to this activity' +
    ' will not be carried over to the duplicated activity';

export const copyActivityTitle = 'Live Sessions';

export const deleteActivityTitle = (name: string) => `Remove ${name} activity`;

export const sessionToAppTitle = 'Promote Session to New Template';

export const sessionToAppText =
    'Session settings, Activities, Event Flow settings will be duplicated into the new template.\n' +
    'Activities created by Real Time Event Flow will be duplicated into the new template.\n' +
    'Responses and repeated sections from this session WILL NOT be copied to the template.';

export const importModalTitle = 'Import Workbook';

export const importModalDescription =
    'Once you import a workbook, the form status will be reset and any responses will be overwritten. Do you wish to continue?';

export const demographicsImportModalTitle = 'Select Demographics for Import';

export const exportInfoModalTitle = 'Export Info';

export const exportRenameModalTitle = 'Rename';

export const exporToXmlJsonModalTitle = 'File Format';

export const exporToJiraTitle = 'Project Name';

export const exporToJiraText = 'Please specify your Jira project name:';

export const projectNameText =
    'Please enter Client & Project name for the session you`re launching in order to continue';

export const projectNameForSessionText =
    'Please enter Client & Project name for the session in order before inviting attendees';

export const clientAndProjectNameTitle = 'Client & Project Name';
