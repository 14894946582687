import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, filter, switchMap, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';

import * as formsActions from '../actions';
import { FormsFeatureService } from '../services/forms.service';
import { FormStatusesMap } from '@app/root-store/models/forms-feature.models';
import { FormInfoService } from '@app/apps/activities/forms/form-services';
import { RoutingService } from '@app/apps/core/services/routing.service';
import { AppState } from '@app/root-store/state';

@UntilDestroy()
@Injectable()
export class FormsEffects implements OnDestroy {
    constructor(
        private actions$: Actions,
        private store$: Store<AppState>,
        private formFeatureService: FormsFeatureService,
        private formInfoService: FormInfoService,
        private routingService: RoutingService
    ) { }

    private getFormsKeysMap = {};
    private getActivityFormKeyMap = {};
    private getActivityFormStatusesMap = {};
    private getBranchingVisibilityMap = {};

    public getFormsKeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formsActions.getActivityFormsKeys),
            filter((action) => !this.getFormsKeysMap[action.activityKey]),
            mergeMap((action) => {
                this.getFormsKeysMap[action.activityKey] = true;

                return this.formFeatureService
                    .getFormsKeysByActivityKey(action.activityKey)
                    .pipe(
                        map((formsKeys) => formsActions.getActivityFormsKeysSuccess({
                            activityKey: action.activityKey,
                            formsKeys
                        })),
                        catchError((error) => of(formsActions.getActivityFormsKeysFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        )
    );

    public getActivityFormKeyForms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formsActions.getActivityParentFormKey),
            filter((action) => !this.getActivityFormKeyMap[action.activityKey]),
            mergeMap((action) => {
                this.getActivityFormKeyMap[action.activityKey] = true;

                return this.formFeatureService
                    .getFormKeyByActivityKey(action.activityKey)
                    .pipe(
                        map(formKey => formsActions.getActivityParentFormKeySuccess({
                            activityKey: action.activityKey,
                            formKey
                        })),
                        catchError((error) => of(formsActions.getActivityParentFormKeyFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        )
    );

    public getActivityFormStatuses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formsActions.getActivityFormStatuses),
            filter((action) => !this.getActivityFormStatusesMap[action.activityKey]),
            mergeMap((action) => {
                this.getActivityFormStatusesMap[action.activityKey] = true;

                return this.formFeatureService
                    .getFormStatusesByActivityKey(action.activityKey)
                    .pipe(
                        map((formStatuses: FormStatusesMap) => formsActions.getActivityFormStatusesSuccess({
                            activityKey: action.activityKey,
                            formStatuses
                        })),
                        catchError((error) => of(formsActions.getActivityFormsKeysFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        )
    );

    public getBranchingVisibility$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formsActions.getBranchingVisibility),
            filter((action) => !this.getBranchingVisibilityMap[action.activityKey]),
            mergeMap((action) => {
                this.getBranchingVisibilityMap[action.activityKey] = true;

                return this.formFeatureService
                    .getBranchingVisibilityByActivityKey(action.activityKey)
                    .pipe(
                        map(formBranchingVisibility => formsActions.getBranchingVisibilitySuccess({
                            activityKey: action.activityKey,
                            formBranchingVisibility
                        })),
                        catchError((error) => of(formsActions.getBranchingVisibilityFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        )
    );

    public getSearchFormResponses$ = createEffect(() =>
        this.actions$.pipe(
            ofType(formsActions.searchFormResponses),
            debounceTime(500),
            distinctUntilChanged(),
            switchMap((action) => {
                const searchData = action.searchData;
                if (!searchData.searchValue.trim()) {
                    this._searchDestroySubject.next();
                    return of(formsActions.clearSearchFormResponses());
                }

                return this.formInfoService
                    .searchFormResponses(searchData)
                    .pipe(
                        takeUntil(this._searchDestroySubject),
                        map((foundResponses) => {
                            const queryParams = this.routingService.queryParams;
                            const currentIndex = foundResponses.findIndex((el) => el.question_id === queryParams.qk ||
                                el.parent_demographic_id === queryParams.sdk && el.parent_question_id === queryParams.sqk &&
                                (el.repeatable_question_id === queryParams.qk || el.parent_question_id === queryParams.qk));

                            this.store$.dispatch(formsActions.setCurrentFoundResponse({currentResponseNumber: currentIndex}));
                            return formsActions.searchFormResponsesSuccess({
                                foundResponses,
                            });
                        }),
                        catchError((error) => of(formsActions.searchFormResponsesFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        )
    );

    private _searchDestroySubject = new Subject<void>();

    ngOnDestroy() { }
}
