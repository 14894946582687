<div [formGroup]="parentForm" class="file-view">
  <mat-form-field [ngClass]="{'without-label': isSectionQuestion}">
    <mat-label *ngIf="!isSectionQuestion">{{ demographic.name }}</mat-label>
    <ul class="uploaded-files-list">
      <ng-container *ngFor="let file of displayFiles; trackBy: trackById;">
        <li class="uploaded-files-item">
          <span class="filename text">
            {{file.name}}
          </span>
          <div class="actions">
            <button
              class="gs-button-icon"
              (click)="downloadFile(file)"
              [disabled]="isUserObserver"
            >
              <mat-icon matPrefix>file_download</mat-icon> Download
            </button>
          </div>
        </li>
      </ng-container>
    </ul>
  </mat-form-field>
</div>
