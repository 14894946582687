<div class="disagree-dialog">
  <h3 mat-dialog-title class="h3">Why do you disagree?</h3>
  <mat-dialog-content class="disagree-dialog-content">
    <p class="text">Please specify the reason you disagree with this answer</p>
    <mat-form-field
      class="disagree-comment textarea"
      appearance="outline"
      floatLabel="always"
    >
      <textarea
        matInput
        required
        cdkFocusInitial
        placeholder="Your comment here"
        [formControl]="disagreeCommentControl"
        [value]="disagreeCommentControl.value || ''"
      ></textarea>
      <mat-error *ngIf="disagreeCommentControl.hasError('required')">This field is required</mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="disagree-dialog-actions">
    <button
      cdkFocusInitial
      class="tt-button-filled"
      [disabled]="disagreeCommentControl.invalid"
      (click)="handleConfirm()"
    >
      Disagree
    </button>
    <button mat-dialog-close class="tt-button-outline">Cancel</button>
  </mat-dialog-actions>
</div>
