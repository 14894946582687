import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';

import { AuthenticatedUserService } from './authenticated-user.service';

@Injectable()
export class WorkbookGuard implements CanActivate, CanActivateChild {

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.checkWorkbookRole();
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(route, state);
    }

    private checkWorkbookRole(): boolean {
        if (this.authenticatedUserService.isUserWorkbook()) {
            return true;
        }

        this.router.navigate(['/navigation']);

        return false;
    }
}
