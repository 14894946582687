import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { FeatureToggles } from '../models/feature-toggles.models';
import { getFeatureToggle } from '@app/root-store/features/feature-toggles/feature-toggles.selectors';
import { AppState } from '../../root-store/state';
import { Store } from '@ngrx/store';

@Injectable()
export class FeatureToggleService {

    constructor(
        private db: AngularFireDatabase,
        private store: Store<AppState>
    ) {}

    getFeatureToggleValue(featureName: string): Observable<boolean> {
        return this.store.select(getFeatureToggle, { key: featureName });
    }

    getFeatureToggles(): Observable<FeatureToggles> {
        return this.db.object<FeatureToggles>('feature_toggle')
            .valueChanges();
    }
}
