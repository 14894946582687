<!-- Progressbar -->
<div class="tasks-progressbar-container">
  <p class="text-secondary">
    {{tasksCount?.completedTasksCount}} of {{tasksCount?.totalTasksCount}} task{{tasksCount?.totalTasksCount > 1 ? 's': ''}} complete
  </p>

  <div class="tasks-progressbar">
    <div
      class="progress-line"
      [style.width]="tasksCount?.completedTasksCount | percentagePipe: tasksCount?.totalTasksCount"
    ></div>
  </div>
</div>
<!-- /Progressbar -->

