import { Pipe, PipeTransform } from '@angular/core';

import { formStatusesSet } from '../../models';

@Pipe({
    name: 'formStatus',
    pure: true
})
export class FormStatusPipe implements PipeTransform {

    transform(value: string, field: 'color' | 'text'): string {
        return !!value ? formStatusesSet[value][field] : '';
    }

}
