import { createReducer, on, Action } from '@ngrx/store';

import * as DemographicsActions from '../actions';
import { initialState, DemographicsState } from '../state';

const demographicReducer = createReducer(
    initialState,
    on(DemographicsActions.getDemographics, (state) => {
        return ({
            ...state,
            loading: true,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getDemographicsSuccess, (state, { demographicsClassKey, demographics }) => {
        state.demographicsByClassKey[demographicsClassKey] = [...demographics];

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getDemographicsFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(DemographicsActions.getSelectedDemographics, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(DemographicsActions.getSelectedDemographicsSuccess, (state, { userId, demographicsClassKey, selectedDemographics }) => {
        state.selectedDemographicsByClassKey[`${demographicsClassKey}_${userId}`] = [...selectedDemographics];

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getSelectedDemographicsFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(DemographicsActions.getFormDemographics, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(DemographicsActions.getFormDemographicsSuccess, (state, { userId, demographicsClassKey, formKey, demographics }) => {
        state.formDemographics[`${demographicsClassKey}_${userId}_${formKey}`] = [...demographics];

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getFormDemographicsFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(DemographicsActions.getActiveFormDemographicKeys, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(DemographicsActions.getActiveFormDemographicKeysSuccess, (state, { userId, demographicsClassKey, formKey, keys }) => {
        state.formActiveDemographicsKeys[`${demographicsClassKey}_${userId}_${formKey}`] = keys;

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getActiveFormDemographicKeysFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(DemographicsActions.getFormDemographicsClassId, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(DemographicsActions.getFormDemographicsClassIdSuccess, (state, { formKey, demographicClassId }) => {
        state.formDemographicsClassId[formKey] = demographicClassId;

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getFormDemographicsClassIdFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(DemographicsActions.getParentFormActiveDemographics, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(DemographicsActions.getParentFormActiveDemographicsSuccess, (state, { formKey, activeDemographics }) => {
        state.parentFormActiveDemographics[formKey] = activeDemographics;

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getParentFormActiveDemographicsFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    ))
);

export function reducer(state: DemographicsState | undefined, action: Action) {
    return demographicReducer(state, action);
}
