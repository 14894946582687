import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';

import { IMAGE_LIGHTBOX_DATA, IMAGE_LIGHTBOX_CAPTION } from '../image-lightbox.model';
import { ImageLightboxRef } from '../image-lightbox-ref';

@Component({
    selector: 'app-slide-lightbox',
    templateUrl: './slide-lightbox.component.html',
    styleUrls: ['./slide-lightbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SlideLightboxComponent implements OnInit {

    constructor(
        public dialogRef: ImageLightboxRef,
        @Inject(IMAGE_LIGHTBOX_DATA) public image: string,
        @Inject(IMAGE_LIGHTBOX_CAPTION) public caption: string
    ) { }

    ngOnInit() {
    }

    close(): void {
        this.dialogRef.close();
    }

}
