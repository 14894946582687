<div [formGroup]="parentForm">
  <mat-form-field [ngClass]="{'without-label': isSectionQuestion}">
    <mat-label *ngIf="!isSectionQuestion">{{ demographic.name }}</mat-label>
    <mat-select
      placeholder="Your option"
      [formControlName]="controlName"
      [disableOptionCentering]="true"
      [panelClass]="panelClass"
      [compareWith]="compareValues"
      [id]="controlId"
      [multiple]="isMultiSelect"
      [required]="!!question.required && question.required[demographic.id]"
    >
      <mat-option
        *ngFor="let option of question.options; trackBy: trackById"
        [value]="option"
      >
        {{ option.name || option.value }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
