import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-submit-form-snack-bar',
  templateUrl: './form-change-status-snack-bar.component.html',
  styleUrls: ['./form-change-status-snack-bar.component.scss']
})
export class FormChangeStatusSnackBarComponent implements OnDestroy {
    private _onOpen = new Subject<void>();

    constructor(
        public snackBarRef: MatSnackBarRef<FormChangeStatusSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) {}

    onOpen(): Observable<void> {
        return this._onOpen.asObservable();
    }

    open(): void {
        this._onOpen.next();
    }

    close(event: any): void {
        event.stopPropagation();
        this.snackBarRef.dismiss();
    }

    ngOnDestroy() {
        this._onOpen.complete();
    }
}
