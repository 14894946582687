import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-confirm-action-dialog',
    templateUrl: './confirm.action.dialog.component.html',
    styleUrls: ['./confirm.action.dialog.component.scss'],
})
export class ConfirmActionDialogComponent implements OnInit, OnDestroy {
    isRemove: boolean;
    isConfirmed: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmActionDialogComponent>
    ) {}

    handleConfirm(): void {
        const { handleConfirm, closeOnConfirm } = this.data;
        handleConfirm();
        this.isConfirmed = true;

        if (closeOnConfirm) {
            this.dialogRef.close();
        }
    }

    handleCancel(): void {
        const { handleCancel, checkbox } = this.data;

        if (!!handleCancel) {
            handleCancel();
        }

        if (checkbox) {
            checkbox.disablePopupHandler(false);
        }

        this.dialogRef.close();
    }

    ngOnInit() {
        const confirmText = this.data.confirmBtnText.toLowerCase();
        this.isRemove = confirmText === 'remove' || confirmText === 'delete';
    }

    ngOnDestroy(): void {
        if (this.data.checkbox && !this.isConfirmed) {
            this.data.checkbox.disablePopupHandler(false);
        }
    }
}
