<div class="assignees">
  <ng-container *ngIf="groupedByUsers?.length; else noUsers">
    <gs-assignees [users]="users"></gs-assignees>
    <button
      class="assignees-button gs-button-flat gs-small"
      (click)="toggleDetails()"
    >
      {{ assigneeDetailsVisible ? 'Hide' : 'Show' }} Details
    </button>
  </ng-container>
  <ng-template #noUsers>
    <p class="caption text-gray">There are no Stakeholders in this {{ viewType | titlecase }} yet</p>
  </ng-template>
  <button
    *ngIf="assigneeDetailsVisible || !groupedByUsers?.length"
    class="assignees-button gs-button-filled gs-small"
    (click)="assignUsers.emit()"
  >
    Assign Users
  </button>
</div>

<!-- tabs -->
<div *ngIf="assigneeDetailsVisible && useDemographics" class="gs-tabs">
  <div class="gs-tab" [ngClass]="{active: currentTab === 0}">
    <a (click)="changeTab(1)">Countries</a>
    <span class="gs-badge">{{ groupedByDemographic?.length }}</span>
  </div>
  <div class="gs-tab" [ngClass]="{active: currentTab === 1}">
    <a (click)="changeTab(0)">Users</a>
    <span class="gs-badge">{{ groupedByUsers?.length }}</span>
  </div>
</div>
<!-- /tabs -->

<!-- assignees details table -->
<div *ngIf="assigneeDetailsVisible" class="gs-table-wrapper">
  <table class="gs-table">
    <thead>
      <tr>
        <th *ngIf="useDemographics && currentTab === 0" class="gs-table-th">demographic</th>
        <th class="gs-table-th">name</th>
        <th *ngIf="useDemographics && currentTab === 1" class="gs-table-th">demographic</th>
        <th class="gs-table-th">email</th>
        <th class="gs-table-th">role</th>
        <th class="gs-table-th">action</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let data of tableData; trackBy: trackById">
        <tr *ngFor="let item of data.rows; let first = first; trackBy: trackById">
          <ng-container *ngIf="useDemographics && first">
            <td class="gs-table-td" [attr.rowspan]="data.rows.length">
              {{ useDemographics && currentTab === 0 ? data.demographic || 'Global' : data.name }}
            </td>
          </ng-container>
          <td *ngIf="currentTab === 0" class="gs-table-td">{{ item.first_name }} {{ item.last_name }}</td>
          <td *ngIf="useDemographics && currentTab === 1" class="gs-table-td">{{ item.demographic_name || 'Global' }}</td>
          <td class="gs-table-td">{{ item.email_address }}</td>
          <td class="gs-table-td">{{ item.role | titlecase }}</td>
          <td class="gs-table-td">
            <button class="gs-button-flat gs-small" (click)="unassignUser.emit(item)">Unassign</button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>
<!-- /assignees details table -->
