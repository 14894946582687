import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentType } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export class PopoverRef<T, D = any> {
    private _afterClosed = new Subject<any>();
    public readonly afterClosed$ = this._afterClosed.asObservable();

    constructor(
        public overlayRef: OverlayRef,
        public content: ComponentType<T>,
        public data: D
    ) {
        overlayRef.backdropClick()
            .pipe(takeUntil(this.afterClosed$))
            .subscribe(() => this.close());
    }

    close(data?: any): void {
        this.overlayRef.dispose();
        this._afterClosed.next(data);
        this._afterClosed.complete();
    }
}
