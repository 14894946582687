<div
  *ngIf="response"
  class="response-card"
  [ngClass]="{
    'focused': isFocused,
    'cursor-pointer': canToggleFocus
  }"
  (click)="toggleFocus()"
>
  <div class="response-card-container">

    <div class="response-card-container-body">
      <span *ngIf="response.form_status" class="form-status overline">
        <i class="icon {{ response.form_status | formStatus: 'color' }}"></i>
        <span class="form-status-text">{{ response.form_status | formStatus: 'text' }}</span>
      </span>

      <!-- response value -->
      <p class="response-value">

        <!-- response owner -->
        <span class="response-owner">{{ response.custom_name || response.owner_full_name }}</span>
        <!-- /response owner -->

        <!-- response value or no response given placeholder-->
        <span [ngClass]="{ 'no-response': isResponseEmpty }">{{ getResponse }}</span>
        <!-- /response value or no response given placeholder-->

        <!-- response date -->
        <span class="response-date">
          {{ (response.date_created | timestampToDate) | date: "MMMM d, yyyy, h:mm a" }}
        </span>
        <!-- /response date -->

      </p>
      <!-- /response value -->

      <!-- initial response owner -->
      <p *ngIf="response?.initial_owner_full_name" class="initial-response">
        <mat-icon class="edit-icon">edit</mat-icon>
        Initial response -&nbsp;
        <span class="initial-response-owner">{{ response?.initial_owner_full_name }}</span>
      </p>
      <!-- /initial response owner -->

    </div>
  </div>

  <div
    class="response-card-footer"
    [ngClass]="{ bordered: !commentsView }"
  >
    <!-- comments -->
    <div
      mat-ripple
      class="response-card-footer-item comments"
      [ngClass]="{ active: (comments | includesByField: 'owner_id': currentUserId) }"
      [matRippleCentered]="true"
      (click)="toggleCommentsView($event)"
    >
      <mat-icon>comment</mat-icon>
      <span class="item-text">Comment</span>
      <span class="item-count">
        <ng-container *ngIf="!!response.comments_count">{{ response.comments_count }}</ng-container>
      </span>
    </div>
    <!-- /comments -->

    <!-- upvote -->
    <div
      mat-ripple
      class="response-card-footer-item upvote"
      [ngClass]="{ 'active': response.upvotes | includes: currentUserId }"
      [matRippleCentered]="true"
      (click)="updateResponseUpvote($event)"
    >
      <mat-icon>add</mat-icon>
      <span class="item-text">Upvote</span>
      <span class="item-count">
        <ng-container *ngIf="!isUserLeader && (response.upvotes | includes: currentUserId)">1</ng-container>
        <ng-container *ngIf="isUpvoteCounterVisible">{{ upvotesCount }}</ng-container>
      </span>
    </div>
    <!-- /upvote -->

    <!-- branching -->
    <div *ngIf="hasDependentQuestions" class="branching">
      <mat-icon>warning</mat-icon>
      <span class="item-text">Has Branching</span>
    </div>
    <!-- /branching -->
  </div>

  <div
    *ngIf="commentsView"
    class="response-card-comments"
    [ngClass]="{ bordered: !commentsView }"
  >

    <!-- comments list-->
    <div
      *ngIf="comments?.length"
      class="response-comments-list"
      [ngClass]="{ hiddenListBorder: !editCommentsMode && isUserLeader }"
    >
      <div
        *ngFor="let comment of comments; trackBy: trackById"
        class="response-comment-container"
      >

        <!-- User name-->
        <div class="user">
          <span class="user-text" (click)="$event.stopPropagation()">{{ comment.owner_full_name | shortName }}</span>
        </div>
        <!--/ User name-->

        <div
          class="response-comments-body"
          [ngClass]="{ selected: editableComment?.id === comment.id }"
          (click)="$event.stopPropagation()"
        >

          <!-- edit/delete comment menu -->
          <div class="response-card-comment" >

            <mat-icon
              *ngIf="isCurrentUserCommentOwner(comment.owner_id) || isUserLeader"
              class="actions-icon"
              [matMenuTriggerFor]="menu"
            >
              more_vert
            </mat-icon>

            <mat-menu
              #menu="matMenu"
              [class]="'comment-menu-list'"
            >
              <button mat-menu-item (click)="editComment(comment)">
                <mat-icon>edit</mat-icon>
                <span>Edit</span>
              </button>
              <button mat-menu-item (click)="deleteComment(comment)">
                <mat-icon>delete</mat-icon>
                <span>Delete</span>
              </button>
            </mat-menu>

            <span class="response-comment-owner">
              {{ comment.owner_full_name }}
            </span>
            {{ comment.value }}
          </div>
          <!-- /edit/delete comment menu -->

        </div>
      </div>
    </div>
    <!-- /comments list-->

    <!-- add/edit comment input -->
    <div class="response-comments-add"
      [ngClass]="{
        bordered: !!comments?.length,
        disabled: isCommentDisabled
      }"
    >
      <div *ngIf="!commentControlValue && !isCommentDisabled" class="user-container">
        <div class="user">
          <span class="user-text" (click)="$event.stopPropagation()">{{ currentUserFullName | shortName }}</span>
        </div>
      </div>
      <div *ngIf="isCommentDisabled" class="user-container">
        <gs-spinner class="spinner-sm spinner-primary"></gs-spinner>
      </div>
      <mat-icon
        *ngIf="commentControlValue"
        class="clear-icon"
        (click)="resetComment($event)"
      >
        close
      </mat-icon>
      <mat-form-field
        appearance="outline"
        class="no-label"
        (mousedown)="$event.stopPropagation()"
      >
        <textarea
          matInput
          cdkTextareaAutosize
          cdkAutosizeMaxRows="6"
          class="comment-text-field text"
          placeholder="Enter comment here..."
          [formControl]="commentControl"
          (click)="$event.stopPropagation()"
          (keyup.enter)="submitComment()"
        >
        </textarea>
        <mat-icon
          *ngIf="commentControlValue"
          matSuffix
          class="send-icon"
          (click)="submitComment($event)"
          [class.disabled]="isCommentDisabled"
        >
          send
        </mat-icon>
      </mat-form-field>
    </div>
    <!--/ add/edit comment input -->

  </div>
</div>
