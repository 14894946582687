<div class="panel">
  <p class="gs-block-title">Selected Countries</p>
  <div class="chip-list">
    <ng-container *ngFor="let selected of (demographicsActive | keyvalue); trackBy: trackById">
      <div class="demographics-list-item gs-demographic-chip-selected">
        <div class="demographics-chip-icon">
          <mat-icon class="gs-demographic-chip-icon-done">done</mat-icon>
        </div>
        <span class="gs-demographic-chip-label">
          {{ selected.value.name }}
        </span>
        <mat-icon class="gs-demographic-chip-icon-clear" (click)="removeActiveDemographic(selected.key)">clear</mat-icon>
      </div>
    </ng-container>
  </div>
</div>

<div *ngIf="showManagePanel" class="panel">
  <p class="gs-block-title">Other available Countries</p>
  <div class="chip-list">
    <ng-container *ngFor="let unselected of (demographicsInactive | keyvalue); trackBy: trackById">
      <div
        class="gs-demographic-chip"
        (click)="addActiveDemographic(unselected.key)"
      >
        <span class="gs-demographic-chip-label">{{ unselected.value.name }}</span>
      </div>
    </ng-container>
  </div>
</div>
