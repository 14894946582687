import {
    ChangeDetectionStrategy,
    Component,
    Inject,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges
} from '@angular/core';
import { Attachment, trackById, WindowService } from '@thinktank/common-lib';
import { BaseQuestionComponent } from '../base-question/base-question.component';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-file-question',
    templateUrl: './file-question.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy, OnChanges {

    @Input() attachedFiles: Attachment[];

    isUserObserver: boolean;
    displayFiles: Attachment[] = [];
    trackById = trackById;

    constructor(
        @Inject(DeploymentFormFacadeToken) formFacade: DeploymentFormFacade,
        private windowService: WindowService
    ) {
        super(formFacade);
    }

    downloadFile(file: Attachment): void {
        this.windowService.open(file.download_url);
    }

    ngOnInit(): void {
        this.onValueChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`FileQuestionComponent::ngOnChanges - changes for ${this.question.id}`, changes);
        if (!!changes.attachedFiles && !!changes.attachedFiles.currentValue) {
            this.displayFiles = changes.attachedFiles.currentValue;
        }
    }

    ngOnDestroy(): void {
        this.onDestroy();
    }

}
