import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { UserRole } from '@thinktank/common-deployments';
import { AuthenticatedUserService, FirebaseActivityService, RouterService } from '@app/core/services';

@Injectable()
export class DeploymentsGuard implements CanActivate, CanActivateChild {

    constructor(
        private activityService: FirebaseActivityService,
        private routerService: RouterService,
        private authenticatedUserService: AuthenticatedUserService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.activityService.getDeploymentsActivityTemplate()
            .pipe(
                map((deployments) => {
                    if (!!deployments && !!deployments['available']) {
                        return true;
                    }
                    this.routerService.navigateTo(['/navigation']);
                    return false;
                })
            );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        const deploymentId = route.params.deploymentId;

        if (!!deploymentId) {
            const myUser = this.authenticatedUserService.getCurrentUser();
            return of([UserRole.Collaborator, UserRole.Deployment_Manager].includes((myUser.deployments || {})[deploymentId]));
        }
        return of(false);
    }
}
