<div class="confirm-dialog">
  <h3 mat-dialog-title class="h3">{{ data.title }}</h3>

  <mat-dialog-content class="confirm-dialog-content">
    <p *ngIf="!data.isDisplayLikeHtml" class="text">{{ data.text }}</p>
    <p *ngIf="data.isDisplayLikeHtml" class="text" [innerHtml]="textAsHtml"></p>
  </mat-dialog-content>

  <mat-dialog-actions class="confirm-dialog-actions">
    <button
      cdkFocusInitial
      class="tt-button-filled"
      [ngClass]="{ 'danger': isRemove }"
      (click)="handleConfirm()"
    >
      {{ data.confirmBtnText }}
    </button>
    <button
      *ngIf="!data.noCancelBtn"
      class="tt-button-outline"
      (click)="handleCancel()"
    >
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>

</div>
