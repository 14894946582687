import { Input, Component, ViewChild, TemplateRef } from '@angular/core';

@Component({
    selector: 'app-efficient-list-item',
    templateUrl: './efficient-list-item.component.html',
})
export class EfficientListChildComponent {
    @Input() id: string;

    @ViewChild('innerTemplate', { static: true }) public innerTemplate: TemplateRef<any>;
}
