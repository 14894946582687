import { Process } from './process.model';
import { Subprocess } from './subprocess.model';
import { User } from './user.model';
import { Ownership } from './ownership.model';
import { Demographic, ChangeRequest } from '@thinktank/common-lib';

export interface ProcessViewState {
    deploymentId: string;
    authenticatedUserId: string;
    process: Process;
    subprocesses: Subprocess[];
    selectedDemographics: Demographic[];
    deploymentOwnership: Ownership[];
    processManager: User;
    changeRequests: ChangeRequest[];
    impactData: any;
    isLoading: boolean;
}

export const initialProcessState = {
    deploymentId: '',
    authenticatedUserId: '',
    process: <Process>{},
    subprocesses: [],
    deploymentOwnership: [],
    selectedDemographics: [],
    processManager: <User>{},
    changeRequests: [],
    impactData: {},
    isLoading: true
}
