import { FormBuilder, FormGroup } from '@angular/forms';
import { FormQuestion, Demographic } from '@thinktank/common-lib';
import { QuestionFormControlFactory } from './question-form-control.factory';
import { FormGroupHandler } from './form-group-handler';

export class QuestionFormGroupHandler implements FormGroupHandler {

    constructor(
        protected formBuilder: FormBuilder,
        protected questionFormControlFactory: QuestionFormControlFactory
    ) {
    }

    build(item: FormQuestion, assignedDemographics: Demographic[]): FormGroup {
        // Add a form group for each question to group the demographic response controls
        const questionFormGroup = this.formBuilder.group({});
        const questionFormControlHandler = this.questionFormControlFactory.build(item.type);
        assignedDemographics.forEach((demographic) => {
            // Add a control for each demographic question response
            const questionFormControl = questionFormControlHandler.build(item, demographic.id);
            questionFormGroup.addControl(demographic.id, questionFormControl);
        });

        return questionFormGroup;
    }

    update(formGroup: FormGroup, question: FormQuestion, assignedDemographics: Demographic[]): void {
        const questionFormControlHandler = this.questionFormControlFactory.build(question.type);
        const questionFormGroup = formGroup.get(question.id) as FormGroup;

        assignedDemographics.forEach((demographic) => {
            const formControl = questionFormGroup.get(demographic.id);
            questionFormControlHandler.update(formControl, question, demographic.id);
        });
    }
}
