import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import {
    Activity,
    activityTypesDictionary,
    InstanceFocus,
    InstanceFocusPair,
    Question
} from '@app/core/models';
import { FirebaseActivityService } from '@app/core/services/activity.service';
import { FirebaseAppService } from '@app/core/services/app.service';
import { sortBySequenceAsc } from '@app/core/utils';

@Injectable()
export class FocusAbilityService {

    constructor(
        private router: Router,
        private fbActivityService: FirebaseActivityService,
        private fbAppService: FirebaseAppService,
        private db: AngularFireDatabase
    ) { }

    getFirstSuitableActivity(appKey: string): Observable<InstanceFocusPair> {
        let isSessionInProgress;

        return this.fbActivityService.getActivitiesByAppInstanceId(appKey)
            .pipe(
                switchMap((activities: Activity[]) => {
                    isSessionInProgress = false;

                    const reorderedActivities = sortBySequenceAsc(activities).filter(activity => {
                        if (activity.status === 'in_progress') {
                            isSessionInProgress = true;
                        }
                        return ['incomplete'].includes(activity.status) && (!!activity.questions || !!activity.demographics);
                    });
                    return of(reorderedActivities[0]);
                }),
                map((incompleteActivity: Activity) => {
                    let focusObject = null;

                    if (incompleteActivity) {
                        const activityKey = incompleteActivity.key;
                        const activity = incompleteActivity;
                        switch (activity.activity_type) {
                            case 'questionnaire':
                            case 'collect':
                                focusObject = this.getCollectFocusObject(activityKey, activity);
                                break;
                            case 'assessment':
                            case 'vote':
                                focusObject = this.getVoteFocusObject(activityKey, activity);
                                break;
                            case 'crowdsource':
                                focusObject = this.getCrowdsourceFocusObject(activityKey, activity);
                                break;
                            case 'demographics':
                                focusObject = this.getDemographicsFocusObject(activityKey, activity);
                                break;
                            case 'presentation':
                                focusObject = this.getPresentationFocusObject(activityKey, activity);
                                break;
                            case 'form':
                                focusObject = this.getFormFocusObject(activityKey, activity);
                                break;
                            default:
                                focusObject = null;
                                break;
                        }
                    }

                    return <InstanceFocusPair>{
                        isSessionInProgress,
                        focusObject,
                        activity: incompleteActivity
                    };
                })
            );
    }

    getCollectFocusObject(activityKey: string, activity: Activity, mode: string = 'vote-group'): InstanceFocus {
        if (!activityKey || !activity || !this.isSuitableCollectActivity(activity)) {
            return null;
        }

        const focusObject = this.getBaseFocusObject(activityKey, activity);

        return <InstanceFocus>{
            ...focusObject,
            mode
        };
    }

    getVoteFocusObject(activityKey: string, activity: Activity, groupKey?: string, mode: string = 'collect'): InstanceFocus {

        if (!activityKey || !activity || !this.isSuitableVoteActivity(activity)) {
            return null;
        }

        const focusObject = this.getBaseFocusObject(activityKey, activity);

        return <InstanceFocus>{
            ...focusObject,
            group_id: groupKey || '',
            mode
        };
    }

    getCrowdsourceFocusObject(
        activityKey: string,
        activity: Activity,
        questionKey?: string,
        mode: string = 'crowdsource',
        filterKey?: string
    ): InstanceFocus {

        if (!activityKey || !activity) {
            return null;
        }

        const questions = activity.questions || {};
        const questionsKeys = Object.keys(questions);
        const questionsNumber = questionsKeys.length;

        if (!questionKey) {
            questionKey = questionsNumber
                ? questionsKeys[0]
                : '';
        }

        if (!this.isSuitableCrowdsourceActivity(activity, questionKey, mode)) {
            return null;
        }

        const focusObject = this.getBaseFocusObject(activityKey, activity);

        return <InstanceFocus>{
            ...focusObject,
            question_id: questionKey,
            mode,
            filter_id: filterKey || null
        };
    }

    getDemographicsFocusObject(activityKey: string, activity: Activity, classKey?: string): InstanceFocus {

        if (!activityKey || !activity) {
            return null;
        }

        const demographics = activity.demographics || {};
        const demographicsKeys = Object.keys(demographics);
        const demographicsNumber = demographicsKeys.length;

        if (!classKey) {
            classKey = demographicsNumber
                ? demographicsKeys[0]
                : '';
        }

        if (!this.isSuitableDemographicsActivity(activity, classKey)) {
            return null;
        }

        const focusObject = this.getBaseFocusObject(activityKey, activity);

        return <InstanceFocus>{
            ...focusObject,
            class_id: classKey
        };
    }

    getPresentationFocusObject(activityKey: string, activity: Activity, slideKey?: string): InstanceFocus {

        if (!activityKey || !activity) {
            return null;
        }

        const slides = activity.questions || {};
        const slidesKeys = Object.keys(slides);
        const slidesNumber = slidesKeys.length;

        if (!slideKey) {
            slideKey = slidesNumber
                ? slidesKeys[0]
                : '';
        }

        if (!this.isSuitablePresentationActivity(activity, slideKey)) {
            return null;
        }

        const focusObject = this.getBaseFocusObject(activityKey, activity);

        return <InstanceFocus>{
            ...focusObject,
            slide_id: slideKey,
        };
    }

    getFormFocusObject(activityKey: string, activity: Activity): InstanceFocus {

        if (!activityKey || !activity || !this.isSuitableFormActivity(activity)) {
            return null;
        }

        return this.getBaseFocusObject(activityKey, activity);
    }

    navigateUsingFocusObject(appKey: string, focusObject: InstanceFocus): Promise<boolean> {
        if (!appKey || !focusObject) {
            return Promise.resolve(false);
        }

        const activityType = activityTypesDictionary[focusObject.activity_type] || focusObject.activity_type;
        const activityKey = focusObject.activity_id;
        const mode = focusObject.mode;
        const questionKey = focusObject.question_id;
        const groupKey = focusObject.group_id;
        const filterKey = focusObject.filter_id;
        let path: string[];

        switch (activityType) {
            case 'collect':
            case 'vote':
                path = ['instance', appKey, activityType, activityKey];

                if (['reporting'].includes(mode)) {
                    return this.router.navigate(['instance', appKey, 'reporting', activityKey]);
                }

                if (groupKey) {
                    return this.router.navigate(path,
                        {
                            queryParams: {
                                groupKey: groupKey
                            }
                        });
                }

                return this.router.navigate(path);
            case 'crowdsource':
                path = ['instance', appKey, activityType, activityKey, 'question', questionKey];

                // because crowdsource mode is used by default
                if (mode !== 'crowdsource') {
                    path.push(mode);
                }

                if (mode === 'group') {
                    return this.router.navigate(path, {
                        queryParams: {
                            filterKey: filterKey || null
                        }
                    });
                }

                return this.router.navigate(path);
            case 'presentation':
                const slideKey = focusObject.slide_id;
                return this.router.navigate(['instance', appKey, activityType, activityKey],
                    {
                        queryParams: {
                            slide: slideKey
                        }
                    });
            case 'demographics':
                const classKey = focusObject.class_id;
                return this.router.navigate(['instance', appKey, activityType, activityKey],
                    {
                        queryParams: {
                            classKey: classKey
                        }
                    });
            default:
                return this.router.navigate(['instance', appKey, activityType, activityKey]);
        }
    }

    getFocus(appKey: string): Observable<InstanceFocus> {
        return this.db.object<InstanceFocus>(`/ssot/_apps/${appKey}/focus`).valueChanges();
    }

    updateFocus(appKey: string, focusObject: InstanceFocus): Promise<void> {
        return this.fbAppService.updateInstanceFocus(appKey, focusObject);
    }

    private isSuitableCollectActivity(activity: Activity): boolean {
        const questions = activity.questions || {};

        return !!Object.keys(questions).length
            && activity.visible;
    }

    private isSuitableVoteActivity(activity: Activity): boolean {
        if (!activity.questions) {
            return false;
        }

        const questionsKeys = Object.keys(activity.questions).filter(key => activity.questions[key].type === 'row') || [];
        const subQuestionsKeys = Object.keys(activity.questions).filter(key => activity.questions[key].type === 'column') || [];
        return !!(questionsKeys.length
            && subQuestionsKeys.length)
            && activity.visible;
    }

    private isSuitableCrowdsourceActivity(activity: Activity, questionKey: string, mode: string): boolean {
        const questions = activity.questions || {};
        const questionsKeys = Object.keys(questions);
        const questionsNumber = questionsKeys.length;

        return !!questionsNumber
            && !!questionKey
            && this.isCrowdsourceQuestionVisible(questions, questionKey)
            && this.isAllowedCrowdsourceMode(mode)
            && activity.visible;
    }

    private isSuitableDemographicsActivity(activity: Activity, classKey: string): boolean {
        const demographics = activity.demographics || {};
        const demographicsKeys = Object.keys(demographics);
        const demographicsNumber = demographicsKeys.length;

        return !!demographicsNumber
            && !!classKey
            && activity.visible;
    }

    private isSuitablePresentationActivity(activity: Activity, slideKey: string): boolean {
        const slides = activity.questions || {};
        const slidesKeys = Object.keys(slides);
        const slidesNumber = slidesKeys.length;

        return !!slidesNumber
            && slidesKeys.includes(slideKey)
            && activity.visible;
    }

    private isSuitableFormActivity(activity: Activity): boolean {
        const questionsLength = activity.number_questions || 0;

        return !!questionsLength && activity.visible;
    }

    private isAllowedCrowdsourceMode(mode: string): boolean {
        return ['crowdsource', 'group'].includes(mode);
    }

    private isCrowdsourceQuestionVisible(questions: { [questionKey: string]: Question }, questionKey: string): boolean {

        if (!questionKey || !questions[questionKey]) {
            return false;
        }
        return questions[questionKey].visible;
    }

    private getBaseFocusObject(activityKey: string, activity: Activity): InstanceFocus {
        return <InstanceFocus>{
            activity_id: activityKey,
            activity_type: activity.activity_type,
            activity_name: activity.activity_name
        };
    }
}
