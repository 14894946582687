<div class="slide-view">
  <div class="slide">

    <!-- slide image -->
    <img
      *ngIf="image"
      draggable="false"
      class="slide-image"
      [src]="image"
    />
    <!-- /slide image -->

    <!-- slide while image is loading -->
    <app-spinner *ngIf="!image" class="slide-image spinning"></app-spinner>
    <!-- /slide while image is loading -->

    <button class="close" mat-icon-button (click)="close()">
      <mat-icon class="icon">close</mat-icon>
    </button>
  </div>

  <div class="caption">
    <p class="slide-name">
      {{ caption }}
    </p>
  </div>
</div>
