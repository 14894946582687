import { random } from 'lodash';

export enum NotificationTypeSnackBar {
    export = 'Export',
    updateStatus = 'UpdateStatus'
}

export interface IUpdateStatusSnackBarInfo {
    message: string,
    duration: number,
    continueMessage: string,
    isAlignment: boolean
}

export class Notification {
    key?: string;
    type?: NotificationTypeSnackBar;
    data?: IUpdateStatusSnackBarInfo;
    activity_id: string;
    export_id?: string;
    export_type?: string;
    owner_id: string;
    export_aborted?: boolean;
    export_complete?: boolean;
    write_to_repository?: boolean;
}

export const testNotification = <Notification>{
    export_id: `testExportId${random(1, 1000)}`,
    export_type: 'session',
    owner_id: `testOwnerId${random(1, 1000)}`,
    key: `testKey${random(1, 1000)}`,
};
