import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';

import { Demographic, trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-view-demographics',
    templateUrl: './view-demographics.component.html',
    styleUrls: ['./view-demographics.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewDemographicsComponent {
    @Input() demographics: Demographic[];
    @Input() canChange?: boolean;
    @Input() viewType: string;
    @Output() change = new EventEmitter<void>();
    @Output() removeDemographic = new EventEmitter<void>();

    trackById = trackById;

    constructor() { }

}
