<form class="invite-form" [formGroup]="inviteForm">
  <mat-label class="caption-gray">Collaborator's email</mat-label>
  <mat-form-field appearance="outline" floatLabel="always">
    <mat-chip-list #stakeholdersList>
      <mat-chip
        *ngFor="let user of stakeholders"
        class="gs-chip-filled"
        [selectable]="false"
        [removable]="true"
        (removed)="remove(user, UserRole.Stakeholder)"
      >
        {{ user.email }}
        <mat-icon class="gs-chip-icon-filled" matChipRemove>close</mat-icon>
      </mat-chip>
      <input
        matInput
        formControlName="stakeholders"
        floatLabel="never"
        [placeholder]="stakeholdersPlaceholder"
        [matChipInputFor]="stakeholdersList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, UserRole.Stakeholder)"
        (input)="updateSubmitState(UserRole.Stakeholder)"
      />
    </mat-chip-list>
  </mat-form-field>
  <mat-error *ngIf="formSubmitted && emailsErrorMessages['stakeholders']">
    {{ emailsErrorMessages['stakeholders'] }}
  </mat-error>

  <mat-label class="caption-gray">Message</mat-label>
  <mat-form-field appearance="outline" floatLabel="always">
    <textarea
      matInput
      floatLabel="never"
      placeholder="Enter message here"
      formControlName="message"
    ></textarea>

    <!-- Only for IE and EDGE -->
    <!-- <textarea
      *ngIf="isIeOrEdgeBrowser"
      matInput
      matTextareaAutosize
      placeholder="Message"
      formControlName="message"
    ></textarea> -->
  </mat-form-field>
</form>
