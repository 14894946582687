export class Disagreement {
    comment: string;
    user_id: string;
    form_id: string;
    activity_id: string;
    question_id: string;
    date_created: number;
    response_value: any;
    response_id?: string;
    demographic_id?: string;
    parent_demographic_id?: string;
    parent_form_id?: string; // for sections
    key?: string;

    constructor(
        comment: string,
        userKey: string,
        activityKey: string,
        formKey: string,
        questionKey: string,
        responseValue: any
    ) {
        this.comment = comment;
        this.user_id = userKey;
        this.form_id = formKey;
        this.activity_id = activityKey;
        this.question_id = questionKey;
        this.response_value = responseValue;
        this.date_created = +new Date();
    }
}

export const testDisagreement = <Disagreement>{
    response_value: '',
    comment: '',
    user_id: 'testUserKey',
    form_id: 'testFormKey',
    activity_id: 'testActivityKey',
    question_id: 'testQuestionKey',
    date_created: +new Date(),
    demographic_id: 'testDemographicKey'
};
