import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormItemType } from '@thinktank/common-lib';
import { QuestionFormControlHandler } from './question-form-control-handler';
import { SingleSelectQuestionFormControlHandler } from './single-select-question-form-control-handler';
import { FormControlHandler } from './form-control-handler';
import { MultiSelectQuestionFormControlHandler } from './multi-select-question-form-control-handler';

@Injectable({
    providedIn: 'root'
})
export class QuestionFormControlFactory {

    constructor(private formBuilder: FormBuilder) {
    }

    build(type: FormItemType): FormControlHandler {
        switch (type) {
            case FormItemType.SingleSelect:
                return new SingleSelectQuestionFormControlHandler(this.formBuilder);
            case FormItemType.MultiSelect:
                return new MultiSelectQuestionFormControlHandler(this.formBuilder);
            default:
                return new QuestionFormControlHandler(this.formBuilder);
        }
    }
}
