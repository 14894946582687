<div class="export-info-dialog thinktank-theme">
  <div mat-dialog-title>
    <div mat-dialog-title class="dialog-title">
      <h3 class="h3">{{data.title}}</h3>
      <button
        mat-icon-button
        tabindex="-1"
        class="close"
        (click)="cancel()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content [formGroup]="modalForm">
    <h6 class="section-title text-dark">Export to</h6>
    <div class="export-to-option">
      <mat-checkbox
        formControlName="shouldWriteToRepository"
      >
        Engage Repository
      </mat-checkbox>
    </div>
    <div class="export-to-option">
      <mat-checkbox formControlName="shouldDownload">
        My Device
      </mat-checkbox>
    </div>

    <h6 class="section-title text-dark">File name</h6>
    <div class="workbook-names" [formGroup]="modalForm.get('names')">

      <ng-container *ngIf="shouldWriteToRepositoryControl; else shouldNotWriteToRepositoryControl">
        <mat-form-field
          *ngFor="let workbookId of workbookIds"
          class="large file-name"
          appearance="outline"
          floatLabel="always"
        >
          <mat-label>Version {{ versions[workbookId] }}</mat-label>
          <input
            matInput
            appEmptyValue
            type="text"
            [formControlName]="workbookId"
          />
          <mat-error *ngIf="modalForm.controls['names'].get(workbookId).hasError('emptyValue')">
            {{ validationMessages.required }}
          </mat-error>
          <mat-error *ngIf="modalForm.controls['names'].get(workbookId).hasError('hasSpecialCharacter')">
            {{ validationMessages.specialCharacters }}
          </mat-error>
          <mat-error *ngIf="modalForm.controls['names'].get(workbookId).hasError('nameAlreadyExists')">
            {{ validationMessages.nameAlreadyExists }}
          </mat-error>
        </mat-form-field>
      </ng-container>

      <ng-template #shouldNotWriteToRepositoryControl>
        <mat-form-field
          *ngFor="let workbookId of workbookIds"
          class="large file-name"
          appearance="outline"
          floatLabel="always"
        >
          <input
            matInput
            appEmptyValue
            type="text"
            [formControlName]="workbookId"
          />
          <mat-error *ngIf="modalForm.controls['names'].get(workbookId).hasError('emptyValue')">
            {{ validationMessages.required }}
          </mat-error>
          <mat-error *ngIf="modalForm.controls['names'].get(workbookId).hasError('hasSpecialCharacter')">
            {{ validationMessages.specialCharacters }}
          </mat-error>
        </mat-form-field>
      </ng-template>

    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button
      class="tt-button-filled confirm-button"
      [disabled]="modalForm.invalid"
      (click)="export()"
    >
      {{ data.confirmBtnText }}
      <app-spinner *ngIf="isPendingStatus" class="confirm-button-spinner"></app-spinner>
    </button>
    <button
      mat-dialog-close
      class="tt-button-outline"
      (click)="cancel()"
    >
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
