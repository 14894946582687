import firebase from 'firebase/compat/app';

import { User } from '@thinktank/common-deployments';

export interface AuthState {
    user: User;
    firebaseUser: firebase.User;
    isLoading: boolean;
}

export const initialAuthState: AuthState = {
    user: null,
    firebaseUser: null,
    isLoading: true
};
