import { Injectable } from '@angular/core';

function getWindow(): Window {
    return window;
}

@Injectable()
export class WindowService {
    open(url: string): Window {
        return getWindow().open(url);
    }
}
