import { LexoRank } from 'lexorank';

export const sortBySequenceAsc = <T>(items: (T & { sequence: number })[]): T[] => {
    return (items || []).sort((a, b) => a.sequence - b.sequence);
};

export const sortByStringSequenceAsc = <T>(items: (T & { sequence: string })[]): T[] => {
    return (items || []).sort((a, b) => a.sequence?.localeCompare(b?.sequence));
};

export const sortByDateDesc = (a: any, b: any) => {
    if (!a.date_created || !b.date_created)
        return 0;
    if (b.date_created.toDate() < a.date_created.toDate())
        return -1;
    if (b.date_created.toDate() > a.date_created.toDate())
        return 1;
    return 0;
};

//deep compare two javascript objects
export const isEqual = (a, b) => {
    if (a === b) return true;
    if (a instanceof Date && b instanceof Date) return a.getTime() === b.getTime();
    if (!a || !b || (typeof a !== 'object' && typeof b !== 'object')) return a === b;
    if (a === null || a === undefined || b === null || b === undefined) return false;
    if (a.prototype !== b.prototype) return false;
    const keys = Object.keys(a);
    if (keys.length !== Object.keys(b).length) return false;
    return keys.every(k => isEqual(a[k], b[k]));
};

export const removeEmptyKeys = (dataOb: object): any => {
    //remove any keys that have null values, they will cause an error for firebase write
    const badKeys = [];
    for (const key in dataOb) {
        if (dataOb[key] === null || dataOb[key] === undefined) {
            badKeys.push(key);
        }
    }
    badKeys.forEach(badKey => {
        delete dataOb[badKey];
    });
    return dataOb;
}

export const sortByAlphabetic = (items: any[], field: string): any[] => {
    return items.sort((a, b) => (a[field] > b[field]) ? 1 : ((b[field] > a[field]) ? -1 : 0));
};

export const mapToArray = <T>(mapObject: T): T[] => {
    return Object.keys(mapObject).map((id) => ({ ...mapObject[id], id}));
}

export const trackByKey = (_: number, item: any): string => {
    return item.key;
};

export const trackById = (index: number, item: any): string => {
    return item ? item.id : '' + index;
};

export const trackByValue = (_: number, value: string): string => {
    return value;
};

export const filterObjectsById = (items: any[]): any[] => {
    return items.reduce((acc, data) => {
        const index = acc.findIndex(({ id }) => id === data.id);
        if (index === -1) {
            acc.push(data);
            return acc;
        }
        return acc;
    }, []);
};

export const parseUrl = (url: string): any => {
    const parsedUrl = new URL(url);
    const pathName = parsedUrl.pathname.replace(/%2F/g, '/');
    const splitPath = pathName.split('/');
    return {
        bucket: splitPath[1],
        path: decodeURIComponent(splitPath.slice(2).join('/')),
        fileName: decodeURIComponent(splitPath.slice(3)[0])
    };
};

export const getFileNameWithoutPrefix = (fileName: string): string => {
    const startIndex = fileName.indexOf('_') + 1;
    const lastIndex = fileName.lastIndexOf('.');
    return lastIndex === -1 ? fileName.slice(startIndex) : fileName.slice(startIndex, lastIndex);
};

export class SequenceGeneration {
    static between(previousSequence: string, nextSequence: string): string {
        if (!previousSequence || !nextSequence) {
            const errorMessage = `${previousSequence ? 'nextSequence is falsy' : nextSequence
                ? 'previousSequence and nextSequence are falsy' : 'previousSequence is falsy'}`;
            throw new TypeError(errorMessage);
        }

        const previousRank = LexoRank.parse(previousSequence);
        const nextRank = LexoRank.parse(nextSequence);
        return previousRank.between(nextRank).toString();
    }

    static beforeFirst(firstSequence: string): string {
        if (!firstSequence) {
            throw new TypeError('firstSequence is falsy');
        }
        return LexoRank.parse(firstSequence).genPrev().toString();
    }

    static afterLast(lastSequence: string): string {
        if (!lastSequence) {
            throw new TypeError('lastSequence is falsy');
        }
        return LexoRank.parse(lastSequence).genNext().toString();
    }

    static initial(): string {
        return LexoRank.middle().toString();
    }
}
