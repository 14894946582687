<app-base-header>
  <!-- navigation -->
  <div class="header-center-content desktop">
    <div
      *ngFor="let item of navigationItems; trackBy: trackByKey"
      class="navigation-item"
    >
      <button
        *ngIf="shouldShowNavigationItem(item)"
        mat-icon-button
        class="header-nav-link button-ripple-light"
        [ngClass]="{'active': item.key === selectedNavigationKey}"
        (click)="openTabFunction(item.key)"
      >
        <mat-icon>{{item.icon}}</mat-icon>
        <span>{{item.navigation_object_name}}</span>
      </button>
    </div>
  </div>

  <div class="header-center-content mobile">
    <div class="navigation-item">
      <mat-menu
        fullWidth
        #headerMenu="matMenu"
        class="header-menu"
        [overlapTrigger]="false"
      >
        <ng-container *ngFor="let item of navigationItems; trackBy: trackByKey">
          <button
            *ngIf="shouldShowNavigationItem(item)"
            mat-menu-item
            class="header-nav-link"
            (click)="openTabFunction(item.key)"
          >
            <mat-icon>{{item.icon}}</mat-icon>
            <span>{{item.navigation_object_name}}</span>
          </button>
        </ng-container>
      </mat-menu>

      <button
        class="header-nav-link active"
        mat-icon-button
        [matMenuTriggerFor]="headerMenu"
      >
        <mat-icon>{{selectedNavigationItem?.icon}}</mat-icon>
        <span>{{selectedNavigationItem?.navigation_object_name}}</span>
        <mat-icon> arrow_drop_down </mat-icon>
      </button>
    </div>
  </div>
  <!-- /navigation -->

  <div class="menu-container">
    <button
      mat-icon-button
      class="button-ripple-light"
      [matMenuTriggerFor]="userMenu"
    >
      <div class="user-info-button">
        <div class="user-photo">
          <img *ngIf="currentUserImage" src="">
          <span *ngIf="!currentUserImage">{{shortUserName}}</span>
        </div>
        <div class="user-info">
          <span class="user-info-text">Welcome</span>
          <span class="user-info-text user-name">
              <span>{{currentUserName}}</span>
              <mat-icon>
                arrow_drop_down
              </mat-icon>
            </span>
        </div>
      </div>
    </button>

    <mat-menu
        #userMenu="matMenu"
        class="header-menu"
        [overlapTrigger]="false"
    >
      <button
        mat-menu-item
        class="header-nav-link"
        [routerLink]="['/administration/profile/' + currentUserKey]"
      >
        User Profile
      </button>
      <button
        *ngIf="isAdmin"
        mat-menu-item
        class="header-nav-link"
        [routerLink]="['/administration/manage']"
      >
        Administration
      </button>
      <button
        *ngIf="hasUserWorkbookPermission"
        mat-menu-item
        class="header-nav-link"
        [routerLink]="['/administration/workbook']"
      >
        Workbook
      </button>
      <button
        *ngIf="hasUserLeaderSessionRole"
        mat-menu-item
        class="header-nav-link"
        [routerLink]="['/administration/repository']"
      >
        Repository
      </button>
      <button
        mat-menu-item
        class="header-nav-link"
        (click)="logoutFunction()"
      >
        Logout
      </button>
    </mat-menu>
  </div>
</app-base-header>
