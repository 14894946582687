<mat-card class="task-list-card">
  <mat-card-header>
    <h3> {{ moduleName }} Tasks:</h3>
  </mat-card-header>

  <mat-card-content>
    <ul>
      <li *ngFor="let task of taskList">
        {{ task.name }}
      </li>
    </ul>
  </mat-card-content>
</mat-card>
