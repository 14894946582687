<div class="confirm-dialog thinktank-theme">
  <h3 mat-dialog-title class="h3">{{data.title}}</h3>
  <mat-dialog-content class="confirm-dialog-content">
    <p class="text">{{data.text}}</p>
    <mat-form-field appearance="outline" class="keyword textarea has-hint">
      <textarea
        matInput
        cdkFocusInitial
        class="form-control"
        maxlength="250"
        [placeholder]="data.commentPlaceholder || ''"
        [formControl]="comment"
      ></textarea>
      <mat-error>This field is required</mat-error>
      <mat-hint align="end">{{comment?.value?.length}} / 250</mat-hint>
    </mat-form-field>
    <p class="text-center text-secondary note">
      {{data.note}}
    </p>
  </mat-dialog-content>

  <mat-dialog-actions class="confirm-dialog-actions">
    <button
      cdkFocusInitial
      class="tt-button-filled"
      [ngClass]="{'danger': isDeny}"
      [disabled]="comment.invalid"
      (click)="handleConfirm()"
    >
      {{data.confirmBtnText}}
    </button>
    <button mat-dialog-close class="tt-button-outline">
      {{data.cancelBtnText}}
    </button>
  </mat-dialog-actions>
</div>
