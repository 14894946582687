import { FormBuilder, FormGroup } from '@angular/forms';
import { RepeatedSectionAddInfo, FirestoreService } from '@thinktank/common-lib';
import { QuestionFormControlFactory } from './question-form-control.factory';

export class RepeatedSectionBuilder {
    constructor(
        private formBuilder: FormBuilder,
        private questionFormControlFactory: QuestionFormControlFactory,
        private firestoreService: FirestoreService
    ) {}

    build(sectionInfo: RepeatedSectionAddInfo): FormGroup {
        const repeatedSectionFormGroup = this.formBuilder.group({});
        repeatedSectionFormGroup.addControl('description', this.formBuilder.control(''));

        const demographicId = sectionInfo.demographic.id;
        sectionInfo.section.questionsForView.forEach((question) => {
            const questionFormControlHandler = this.questionFormControlFactory.build(question.type);
            const repeatedSectionQuestion = questionFormControlHandler.build(question, demographicId);
            repeatedSectionQuestion.setValue(sectionInfo.sectionResponses[demographicId][question.id]);
            repeatedSectionFormGroup.addControl(question.id, repeatedSectionQuestion);
        });
        return repeatedSectionFormGroup;
    }

    getSectionId(): string {
        return this.firestoreService.getPushId();
    }
}
