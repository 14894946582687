import { ReviewState, AlignmentState } from '../models';

export enum FormStatus {
    InProgress = 'IN_PROGRESS',
    Draft = 'DRAFT',
    Locked = 'LOCKED',
    DraftReview = 'DRAFT_REVIEW',
    Alignment = 'ALIGNMENT',
    PendingApproval = 'PENDING_APPROVAL',
    Approved = 'APPROVED',
    Denied = 'DENIED'
}

export class FormStatusObject {
    status: FormStatus | ReviewState | AlignmentState;
    owner_id?: string;
}

export type FormStatusModel = FormStatusObject | { [demographicKey: string]: FormStatusObject } | null;

export const formStatusesSet = {
    'DRAFT': {
        state: 'draft',
        color: 'draft',
        route: 'draft'
    },
    'DENIED': {
        state: 'draft',
        color: 'draft',
        route: 'draft'
    },
    'LOCKED': {
        state: 'locked',
        color: 'locked',
        route: 'locked'
    },
    'DRAFT_REVIEW': {
        state: 'draft review',
        color: 'draft-review',
        route: 'draft-review'
    },
    'ALIGNED': {
        state: 'alignment',
        color: 'alignment',
        route: 'alignment'
    },
    'ALIGNMENT': {
        state: 'alignment',
        color: 'alignment',
        route: 'alignment'
    },
    'PENDING_APPROVAL': {
        state: 'pending approval',
        color: 'pending-approval',
        route: 'pending-approval'
    },
    'Under Review': {
        state: 'pending approval',
        color: 'pending-approval',
        route: 'pending-approval'
    },
    'APPROVED': {
        state: 'approved',
        color: 'approved',
        route: 'approved'
    },
    'Approved': {
        state: 'approved',
        color: 'approved',
        route: 'approved'
    },
    'In progress': {
        state: 'in progress',
        color: 'in-progress',
        route: 'in-progress'
    },
    'IN_PROGRESS': {
        state: 'in progress',
        color: 'in-progress',
        route: 'in-progress'
    }
};

// Use me to sort enumeration arrays easier!
export const formStatusOrderMap = {
    [FormStatus.Draft]: 0,
    [FormStatus.InProgress]: 0,
    [FormStatus.Locked]: 1,
    [FormStatus.DraftReview]: 2,
    [FormStatus.Alignment]: 3,
    [FormStatus.PendingApproval]: 4,
    [FormStatus.Approved]: 5
};

export const formStatusesMap = {
    'DRAFT': FormStatus.Draft,
    'LOCKED': FormStatus.Locked,
    'DRAFT_REVIEW': FormStatus.DraftReview,
    'ALIGNED': FormStatus.Alignment,
    'ALIGNMENT': FormStatus.Alignment,
    'PENDING_APPROVAL': FormStatus.PendingApproval,
    'Under Review': FormStatus.PendingApproval,
    'APPROVED': FormStatus.Approved,
    'Approved': FormStatus.Approved,
    'In progress': FormStatus.InProgress,
    'IN_PROGRESS': FormStatus.InProgress,
    'DENIED': FormStatus.Denied,
    'Denied': FormStatus.Denied
};

export class UserReadyForAlignment {
    app_key: string;
    activity_key: string;
    demographic_class_key: string;
    demographic_key: string;
    form_key: string;
    instance_url: string;
    move_all_master_toggle: boolean;
    user_key: string;
}

export const testAlignmentStateMap = <FormStatusModel>{
    'keyActive1': <FormStatusObject>{
        status: FormStatus.DraftReview,
        owner_id: 'userKey1'
    },
    'keyActive2': <FormStatusObject>{
        status: FormStatus.Draft
    },
    'keyActive3': <FormStatusObject>{
        status: FormStatus.Alignment
    }
};
