<mat-toolbar class="nav-bar-container thinktank-theme">
  <mat-toolbar-row class="toolbar-row">
    <a class="tt-logo-container" [routerLink]="['/']">
      <img src="/assets/svg/header-logo.svg" class="header-logo" alt="Logo My Concerto" />
    </a>
    <span class="nav-tool-items">
      <a mat-button routerLink="../login" routerLinkActive="active">Log in</a>
      <a mat-button routerLink="../register" routerLinkActive="active">Register</a>
    </span>
  </mat-toolbar-row>
</mat-toolbar>
