import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    loading$ = this.loadingSubject.asObservable().pipe(distinctUntilChanged());

    display(value: boolean): void {
        this.loadingSubject.next(value);
    }
}
