import { createAction, props } from '@ngrx/store';

import { Demographic } from '@app/core/models';

const getFormDemographicsType = '[Demographics] get form demographics';
const getFormDemographicsSuccessType = '[Demographics] get form demographics success';
const getFormDemographicsFailType = '[Demographics] get form demographics fail';

const getFormDemographicsClassIdType = '[Demographics] get active form demographics class id';
const getFormDemographicsClassIdSuccessType = '[Demographics] get active form demographics class id success';
const getFormDemographicsClassIdFailType = '[Demographics] gget active form demographics class id fail';

export const getFormDemographics = createAction(
    getFormDemographicsType,
    props<{ userId: string, demographicsClassKey: string, formKey: string }>()
);

export const getFormDemographicsSuccess = createAction(
    getFormDemographicsSuccessType,
    props<{ userId: string, demographicsClassKey: string, formKey: string, demographics: Demographic[] }>()
);

export const getFormDemographicsFail = createAction(
    getFormDemographicsFailType,
    props<{ errorMessage: string }>()
);

export const getFormDemographicsClassId = createAction(
    getFormDemographicsClassIdType,
    props<{ formKey: string }>()
);

export const getFormDemographicsClassIdSuccess = createAction(
    getFormDemographicsClassIdSuccessType,
    props<{ demographicClassId: string, formKey: string }>()
);

export const getFormDemographicsClassIdFail = createAction(
    getFormDemographicsClassIdFailType,
    props<{ errorMessage: string }>()
);
