import { GraphNode } from './graph-node.model';
import { Variable } from './variable.model';

export class ListMaskNode extends GraphNode {

    type = 'list_mask';
    cache: Map<string, any[]>;

    loadData(data:object) {
        super.loadData(data);
        this.cache = !!data['cache'] && new Map(Object.entries(data['cache'])) || new Map();
    }

    async propagateValues(demographicId: string): Promise<void> {
        const list = this.cache.get('list') || [];
        const mask = this.cache.get('mask') || [];
        if (list.length > 0) {
            const value = list.filter((element, index) => !!mask[index] && mask[index] !== 'Inactive');
            const variable = this.buildVariable(demographicId, value);
            await this.propagateVariable(variable);
        }
    }

    async setInput(name: string, variableData: Variable): Promise<void> {
        console.log(`ListMaskNode::setInput - ${this.nodeId} ${name}`, this.consoleLogVariable(variableData));

        if (name === 'list') {
            const list = variableData.value || [];
            if (list.length > 0) {
                this.cache.set('list', list);
                await this.graphService.updateNode(this.deploymentId, this.nodeId, { ['cache.list']: list });
                await this.propagateValues(variableData.demographicId);
            }
        }
        if (name === 'mask'){
            const mask = variableData.value || [];
            if (mask.length > 0) {
                this.cache.set('mask', mask);
                await this.graphService.updateNode(this.deploymentId, this.nodeId, { ['cache.mask']: mask });
                await this.propagateValues(variableData.demographicId);
            }
        }

    }
}
