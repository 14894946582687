import { Directive, Input, EventEmitter, Output, ElementRef, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

import { isFirefox } from '@app/core/utils';

@Directive({
    selector: '[appScrollSpy]'
})
export class ScrollSpyDirective implements AfterViewInit, OnDestroy {
    @Input() spiedTarget = ''; // spied class name
    @Input() disabled: boolean;
    @Input() disableSections: boolean;
    @Input() disableSpinner: boolean;
    @Output() sectionChange = new EventEmitter<Element>();
    @Output() reachEnd = new EventEmitter<any>();

    private currentSection: Element;
    private mousewheelSubject = new Subject<void | Event>();
    // to emit event if first first check will be when scroll already reached end
    private prevScrollPosition = -55;

    constructor(private _el: ElementRef) { }

    @HostListener('scroll', ['$event'])
    @HostListener('mousewheel', ['$event'])
    onEventHandler(event: Event) {
        if (this.disabled) {
            return;
        }

        this.checkReachEnd(event);

        if (!this.disableSections) {
            setTimeout(() => {
                this.mousewheelSubject.next(event);
            }, 0);
        }
    }

    ngAfterViewInit(): void {
        this.mousewheelSubject
            .subscribe(() => this.emitSectionChange());
    }

    ngOnDestroy(): void {
        this.mousewheelSubject.next();
        this.mousewheelSubject.complete();
    }

    private checkReachEnd(event: Event): void {
        const el = this._el.nativeElement;
        const scrollPosition = el.scrollHeight - el.offsetHeight - el.scrollTop;
        const diff = Math.abs(this.prevScrollPosition - scrollPosition);
        this.prevScrollPosition = scrollPosition;
        // diff - to prevent large amount of emits
        // 48 - spinner height
        const shouldEmitReachEnd = this.disableSpinner
            ? diff > 5 && scrollPosition < 48
            : scrollPosition < 5;
        if (shouldEmitReachEnd) {
            this.reachEnd.emit(event);
        }
    }

    private emitSectionChange(): void {
        let currentSection: Element;
        const el = this._el.nativeElement;
        const children = el.getElementsByClassName(this.spiedTarget);
        const scrollTop = el.scrollTop;
        const parentOffset = el.offsetTop;

        if (scrollTop === 0) {
            this.currentSection = undefined;
            this.sectionChange.emit(this.currentSection);
            return;
        }

        for (let i = 0; i < children.length; i++) {
            const element = children[i];
            let elementOffset = element.offsetTop;
            if (element.classList.contains('section-top-question')) {
                const section = element.closest('.question-view-container');
                const paddingOffset = !element.classList.contains('using-demographics') ? 24 : 0;
                const sectionOffset = !!section ? section.offsetTop : 0;
                const firefoxOffset = isFirefox() ? 13 : 0;
                // custom calculations for pinning question inside section
                elementOffset = element.offsetTop // element offset of the parent (from the section)
                    + paddingOffset // padding offset for section without demographics
                    + firefoxOffset // because Firefox calculates offsetTop differently
                    + sectionOffset; // section offset of the entire form
                // to be able to scroll to the element with 8px offset
                element.setAttribute('data-scroll-position', elementOffset - 8);
            }

            if ((elementOffset - parentOffset) <= scrollTop) {
                currentSection = element;
            }
        }

        if (currentSection !== this.currentSection) {
            this.currentSection = currentSection;
            this.sectionChange.emit(this.currentSection);
        }
    }
}
