import { createSelector } from '@ngrx/store';

import { FormBranchingVisibility, FormQuestionValidationData, FormQuestionValidationError } from '@app/core/models';
import { AppState } from '@app/root-store/state';
import { FormValidationErrorsState } from '../state';
import { currentUserActiveDemographicsKeysSelector } from '@app/root-store/features/demographics/selectors';
import {
    formBranchingVisibilitySelector,
    formStatusesByActivityKeySelector,
    isFormActivitySubmittedSelector,
    parentFormKeyByActivityKeySelector
} from '@app/root-store/features/forms/selectors';
import { FormStatusesMap } from '@app/root-store/models/forms-feature.models';
import { filterErrorsByBranching } from './utils';

export const formValidationErrorsState = (state: AppState): FormValidationErrorsState => state.formValidationErrors;

export const isLoadingSelector = createSelector(
    formValidationErrorsState,
    (state) => state.loading
);

export const errorsSelector = createSelector(
    [
        formValidationErrorsState,
        formBranchingVisibilitySelector
    ], (
        state: FormValidationErrorsState,
        formBranchingVisibility: { [questionKey: string]: FormBranchingVisibility },
        props: FormQuestionValidationData
    ): FormQuestionValidationError[] => {
    const isBranchingEmpty = !formBranchingVisibility || !Object.keys(formBranchingVisibility).length;
    if (isBranchingEmpty) { return []; }

    const filter = filterErrorsByBranching(formBranchingVisibility);
    const errors = state && (state.errors || []).filter(filter);

    return errors;
});

export const filteredErrorsForCurrentUser = createSelector(
    [
        errorsSelector,
        currentUserActiveDemographicsKeysSelector,
        parentFormKeyByActivityKeySelector,
        formStatusesByActivityKeySelector
    ],
    (
        errors: FormQuestionValidationError[],
        demographicsKeysMap: { [formId: string]: string[] },
        parentFormKey: string,
        formStatuses: FormStatusesMap,
        props: FormQuestionValidationData
    ) => {
        const formIds = Object.keys(demographicsKeysMap || {});
        const getStatus = (formId: string, demographicId: string): string =>
            (((formStatuses || {})[formId] || {})[demographicId] || {}).status;

        if (props.useDemographics && formIds.length) {
            const filterErrorsByDemographics = errors.filter((error: FormQuestionValidationError) => {
                const focusedDemographicCheck = props.focusedDemographicKey
                    ? error.demographic_id === props.focusedDemographicKey
                    : true;
                const hasErrorForParentDemographic = focusedDemographicCheck && (demographicsKeysMap[parentFormKey] || []).includes(error.demographic_id);
                const focusedSectionDemographicCheck = props.focusedSectionDemographicKey
                    ? error.secondary_demographic_id === props.focusedSectionDemographicKey
                    : true;
                const demographicKeysStatusesCheck = props.focusedDemographicKey
                    ? true
                    : !getStatus(parentFormKey, error.demographic_id) && !getStatus(error.form_id, error.secondary_demographic_id);

                return error.secondary_demographic_id
                    ? (demographicsKeysMap[error.form_id] || []).includes(error.secondary_demographic_id)
                    && hasErrorForParentDemographic
                    && focusedSectionDemographicCheck
                    && demographicKeysStatusesCheck
                    : hasErrorForParentDemographic;
            }
            );

            return filterErrorsByDemographics;
        }

        return errors;
    }
);

export const errorsCountSelector = createSelector(
    filteredErrorsForCurrentUser,
    (
        errors: FormQuestionValidationError[],
        props: FormQuestionValidationData
    ): number => (errors || []).length
);

export const isErrorsVisibleSelector = createSelector(
    formValidationErrorsState,
    (state: FormValidationErrorsState): boolean => {
        return state.isErrorsVisible;
    }
);

export const questionErrorsSelector = createSelector(
    filteredErrorsForCurrentUser,
    (errors: FormQuestionValidationError[], props: FormQuestionValidationData) => {
        return (errors || []).filter(error => {
            return (!!props.question_id ? props.question_id === error.question_id : true)
                && (!!props.source_section_question_id ? props.source_section_question_id === error.source_section_question_id : true)
                && (!!props.demographic_id ? props.demographic_id === error.demographic_id : true);
        });
    }
);

export const questionHasErrorsSelector = createSelector(
    [
        questionErrorsSelector,
        isFormActivitySubmittedSelector
    ],
    (
        errors: FormQuestionValidationError[],
        isFormActivitySubmitted: boolean,
        props: FormQuestionValidationData
    ) => (!!errors.length && isFormActivitySubmitted)
);

export const isQuestionOutsideSectionHasErrorSelector = createSelector(
    filteredErrorsForCurrentUser,
    (
        errors: FormQuestionValidationError[],
        props: FormQuestionValidationData
    ): boolean => !!(errors || []).find(error => !error.section_question_id)
);

export const isQuestionInsideSectionHasErrorSelector = createSelector(
    filteredErrorsForCurrentUser,
    (
        errors: FormQuestionValidationError[],
        props: { currentUserId: string, useDemographics: boolean, activityId: string }
    ): boolean => !!(errors || []).find(error => !!error.section_question_id)
);
