import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { FirestoreService } from './firestore.service';
import { Task } from '../models/task.model';

@Injectable({
    providedIn: 'root'
})
export class TaskService {

    constructor(private firestoreService: FirestoreService) {
    }

    getTasks(projectKey: string): Observable<Task[]> {
        return this.firestoreService.getCollection<Task>(`taskslist/${projectKey}/tasks`);
    }

    async completeTask(projectKey: string, taskKey: string, userKey: string): Promise<void> {
        const path = `taskslist/${projectKey}/tasks/${taskKey}`;
        return this.firestoreService.upsert(path, {
            complete: {
                [userKey]: true
            }
        });
    }

}
