import { ElementRef, Injectable, Injector } from '@angular/core';
import { ConnectedPosition, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector, ComponentType } from '@angular/cdk/portal';

import { PopoverRef } from './popover-ref';
import { PopoverConfig } from './popover-config';
import { PopoverComponent } from './popover.component';

@Injectable()
export class PopoverService {
    private readonly _positions: ConnectedPosition[] = [{
        originX: 'center', originY: 'bottom',
        overlayX: 'center', overlayY: 'top',
    }, {
        originX: 'center', originY: 'top',
        overlayX: 'center', overlayY: 'bottom'
    }];

    constructor(
        private _overlay: Overlay,
        private _injector: Injector
    ) {}

    open<T, D = any>(
        componentRef: ComponentType<T>,
        element: HTMLElement,
        config: PopoverConfig = {}
    ): PopoverRef<T, D> {
        const overlayRef = this._createOverlay(element, config);
        const popoverRef = new PopoverRef(overlayRef, componentRef, config.data);

        const injector = this._createInjector(popoverRef);
        overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

        return popoverRef;
    }

    private _createOverlay(element: HTMLElement, config: PopoverConfig): OverlayRef {
        const strategy = this._overlay.position()
            .flexibleConnectedTo(element)
            .withPositions(this._positions)
            .withPush(false);
        const overlayConfig = new OverlayConfig({
            positionStrategy: strategy,
            width: config.width,
            height: config.height,
            hasBackdrop: true,
            backdropClass: 'popover-backdrop',
            scrollStrategy: this._overlay.scrollStrategies.reposition()
        });
        return this._overlay.create(overlayConfig);
    }

    private _createInjector<T, D = any>(
        panelRef: PopoverRef<T, D>
    ): PortalInjector {
        const injectionTokens = new WeakMap([
            [PopoverRef, panelRef]
        ]);

        return new PortalInjector(this._injector, injectionTokens);
    }
}
