import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromFeatureToggles from './feature-toggles.reducer';
import { FeatureTogglesEffects } from './feature-toggles.effects';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature(fromFeatureToggles.FEATURE_TOGGLES_FEATURE_KEY, fromFeatureToggles.reducer),
        EffectsModule.forFeature([FeatureTogglesEffects])
    ],
    exports: []
})
export class FeatureTogglesModule { }
