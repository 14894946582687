import { BaseStateModel } from '@app/root-store/models/base-state.model';
import { FormQuestion, WorkbookSheet } from '@app/core/models';

export interface WorkbooksState extends BaseStateModel {
    workbooksSheetsByActivity: { [activityId: string]: WorkbookSheet[] };
    formSheetKeyByForm: { [formId: string]: string };
    questionSheetKeyByQuestion: { [questionId: string]: string };
    formQuestionsWithWorkbookSheet: { [questionId: string]: FormQuestion[] };
}

export const initialState: WorkbooksState = {
    loading: false,
    errorMessage: null,
    workbooksSheetsByActivity: {},
    formSheetKeyByForm: {},
    questionSheetKeyByQuestion: {},
    formQuestionsWithWorkbookSheet: {}
};
