import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { Attachment, FormItemType, FormQuestion, Demographic } from '@thinktank/common-lib';

@Component({
    selector: 'gs-question-view',
    templateUrl: './question-view.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuestionViewComponent {
    @Input() question: FormQuestion;
    @Input() demographic: Demographic;
    @Input() parentForm: FormGroup;
    @Input() deploymentId: string;
    @Input() authenticateUserId: string;
    @Input() attachedFiles: Attachment[];

    FormItemType = FormItemType;

    constructor() {
    }

    get questionVisible(): boolean {
        return this.question.visible[this.demographic.id];
    }
}
