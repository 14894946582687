/* eslint-disable max-len */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { StarRatingModule } from 'angular-star-rating';

import { MaterialModule, CommonLibModule } from '@thinktank/common-lib';
import { CommonDeploymentsModule } from '@thinktank/common-deployments';
import { TimePickerComponent } from '@app/shared/time-picker/time-picker.component';
import { ConfirmActionDialogComponent } from '@app/shared/dialogs/confirm-action-dialog/confirm.action.dialog.component';
import { ChangePasswordDialogComponent } from '@app/shared/dialogs/change-password-dialog/change-password-dialog.component';
import { AttachmentsDialogComponent } from '@app/shared/dialogs/attachments-dialog/attachments-dialog.component';
import { AlertDialogComponent } from '@app/shared/dialogs/alert-dialog/alert-dialog.component';
import { UserRoleChangedComponent } from '@app/shared/dialogs/user-role-changed-dialog/user-role-changed-dialog.component';
import { ImportQuestionsDialogComponent } from '@app/shared/dialogs/import-questions-dialog/import-questions-dialog.component';
import { FocusSnackBarComponent } from '@app/shared/snack-bars/focus-snack-bar/focus-snack-bar.component';
import { DeploymentsCreateSnackBarComponent } from '@app/shared/snack-bars/deployments-create-snack-bar/deployments-create-snack-bar.component';
import { ActivityCopiedSnackBarComponent } from '@app/shared/snack-bars/activity-copied-snack-bar/activity-copied-snack-bar.component';
import { TtListItemComponent } from '@app/shared/tt-list-item/tt-list-item.component';
import { UserPicComponent } from '@app/shared/user-pic/user-pic.component';
import { PopoverComponent } from '@app/shared/popover';
import { ApprovalRequestDialogComponent } from '@app/shared/dialogs/approval-request-dialog/approval-request-dialog.component';
import { ConfirmDialogWithKeywordComponent } from '@app/shared/dialogs/confirm-dialog-with-keyword/confirm-dialog-with-keyword.component';
import { EventFlowResultsDialogComponent } from '@app/shared/dialogs/event-flow-results-dialog/event-flow-results-dialog.component';
import { EventFlowValidationDialogComponent } from '@app/shared/dialogs/event-flow-validation-dialog/event-flow-validation-dialog.component';
import { FormSubmissionDialogComponent } from '@app/shared/dialogs/form-submission-dialog/form-submission-dialog.component';
import { ProjectNameDialog } from '@app/shared/dialogs/project-name-dialog/project-name-dialog.component';
import { DisagreeDialogComponent } from '@app/shared/dialogs/disagree-dialog/disagree-dialog.component';
import { CopyAppDialogComponent } from '@app/shared/dialogs/copy-app-dialog/copy-app-dialog.component';
import { SessionsListDialogComponent } from '@app/shared/dialogs/sessions-list-dialog/sessions-list-dialog.component';
import { PaginatorComponent } from '@app/shared/paginator/paginator.component';
import { ImageLightboxComponent } from '@app/shared/lightboxes/image-lightbox/image-lightbox.component';
import { SlideLightboxComponent } from '@app/shared/lightboxes/slide-lightbox/slide-lightbox.component';
import { SelectDemographicsForExportComponent } from '@app/shared/dialogs/select-demographics-for-export-dialog/select-demographics-for-export-dialog.component';
import { WorkbooksExportDialogComponent } from '@app/shared/dialogs/workbooks-export-dialog/workbooks-export-dialog.component';
import { AlertHtmlDialogComponent } from '@app/shared/dialogs/alert-html-dialog/alert-html-dialog.component';
import { OptionsViewDialogComponent } from '@app/shared/dialogs/options-view-dialog';
import { SimplePaginationComponent } from '@app/shared/simple-pagination/simple-pagination.component';
import { SimplePaginationContainerComponent } from '@app/shared/simple-pagination/simple-pagination-container.component';

import { EmptyValueValidatorDirective } from '@app/shared/directives/empty-value-validator.directive';
import { InitialFocusDirective } from '@app/shared/directives/initial-focus.directive';
import { AgWordCloudDirective } from '@app/shared/directives/angular-word-cloud.directive';
import { ComponentHostDirective } from '@app/shared/directives/component-host.directive';
import { SelectAllCaptionDirective } from '@app/shared/directives/select-all-caption.directive';
import { ScrollSpyDirective } from '@app/shared/directives/scroll-spy.directive';

import { UserRolePipe } from '@app/shared/pipes/user-role.pipe';
import { CheckedPipe } from '@app/shared/pipes/checked.pipe';
import { OfActivityTypePipe } from '@app/shared/pipes/of-activity-type.pipe';
import { TimesPipe } from '@app/shared/pipes/times.pipe';
import { QuestionGroupPipe } from '@app/shared/pipes/question-group.pipe';
import { HeaderContainerComponent } from '@app/shared/header/header-container.component';
import { HeaderComponent } from '@app/shared/header/header.component';
import { BaseHeaderComponent } from '@app/shared/base-header/base-header.component';
import { SearchComponent } from '@app/shared/search/search.component';
import { ChangeUsernameDialogComponent } from '@app/shared/dialogs/change-username-dialog/change-username-dialog.component';
import { SessionPromotedSnackBarComponent } from '@app/shared/snack-bars/session-promoted-snack-bar/session-promoted-snack-bar.component';
import { MembersComponent } from '@app/shared/members/members.component';
import { FormChangeStatusSnackBarComponent } from '@app/shared/snack-bars/form-change-status-snack-bar/form-change-status-snack-bar.component';
import { InformationSnackBarComponent } from '@app/shared/snack-bars/information-snack-bar/information-snack-bar.component';

import { ImageLightboxService } from '@app/shared/lightboxes/image-lightbox.service';
import { PopoverService } from '@app/shared/popover';
import { ContributorsListDialogComponent } from '@app/shared/dialogs/contributors-list-dialog/contributors-list-dialog.component';
import { TasksListComponent } from '@app/shared/tasks-list/tasks-list.component';
import { TasksListContainerComponent } from '@app/shared/tasks-list/tasks-list-container.component';
import { TaskComponent } from '@app/shared/tasks-list/task/task.component';
import { TasksCountComponent } from '@app/shared/tasks-count/tasks-count.component';
import { GateComponent } from '@app/shared/tasks-list/gate/gate.component';
import { FormStateComponent } from '@app/shared/form-state/form-state.component';
import { ImageCircleComponent } from '@app/shared/image-circle/image-circle.component';
import { TasksCountContainerComponent } from '@app/shared/tasks-count/tasks-count-container.component';
import { TrimValueDirective } from '@app/shared/directives/trim-value.directive';
import { AppSpinnerComponent } from '@app/shared/spinner/spinner.component';
import { StarRatingComponent } from '@app/shared/star-rating/star-rating.component';
import { EfficientListViewComponent } from './efficient-list-view/efficient-list-view.component';
import { EfficientListChildComponent } from './efficient-list-view/efficient-list-item.component';
import { ExportInfoDialogComponent } from './dialogs/export-info-dialog/export-info-dialog.component';
import { ExportRenameDialogComponent } from './dialogs/export-rename-dialog/export-rename-dialog.component';
import { ExportXmlJsonDialogComponent } from '@app/shared/dialogs/export-xml-json-dialog/export-xml-json-dialog.component';
import { OverlappedQuestionsDialogComponent } from '@app/shared/dialogs/overlapped-questions-dialog/overlapped-questions-dialog.component';
import { RestoreResponseBranchedQestionsDialogComponent } from '@app/shared/dialogs/restore-response-branched-question-dialog/restore-response-branched-questions-dialog.component';
import { ActivityProgressLabelComponent } from '@app/shared/activity-progress-label/activity-progress-label.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { SharingFormComponent } from './sharing-form/sharing-form.component';
import { InviteComponent } from './invite/invite.component';
import { InviteFormComponent } from './invite/invite-form/invite-form.component';
import { CurrentUsersTableComponent } from './current-users-app-management/current-users-table/current-users-table.component';
import { CurrentUsersAppManagementComponent } from './current-users-app-management/current-users-app-management.component';
import { CurrentUsersAppManagementPipe } from './current-users-app-management/current-users-app-management.pipe';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { AllTagsPanelComponent } from './all-tags-panel/all-tags-panel.component';
import { SetTitleDirective } from './directives/set-title.directive';
import { BulkInviteDialogComponent } from './dialogs/bulk-invite-dialog/bulk-invite-dialog.component';

@NgModule({
    providers: [ImageLightboxService, PopoverService],
    declarations: [
        // components
        TimePickerComponent,
        ConfirmActionDialogComponent,
        ChangePasswordDialogComponent,
        AttachmentsDialogComponent,
        SessionsListDialogComponent,
        AlertDialogComponent,
        UserRoleChangedComponent,
        ImportQuestionsDialogComponent,
        ConfirmDialogWithKeywordComponent,
        DeploymentsCreateSnackBarComponent,
        FocusSnackBarComponent,
        ActivityCopiedSnackBarComponent,
        TtListItemComponent,
        ProjectNameDialog,
        DisagreeDialogComponent,
        CopyAppDialogComponent,
        ApprovalRequestDialogComponent,
        EventFlowResultsDialogComponent,
        EventFlowValidationDialogComponent,
        UserPicComponent,
        HeaderComponent,
        HeaderContainerComponent,
        BaseHeaderComponent,
        PaginatorComponent,
        MembersComponent,
        ImageLightboxComponent,
        TasksListContainerComponent,
        TasksListComponent,
        TaskComponent,
        GateComponent,
        ActivityProgressLabelComponent,
        FormStateComponent,
        ImageCircleComponent,
        TasksCountComponent,
        SelectDemographicsForExportComponent,
        WorkbooksExportDialogComponent,
        AlertHtmlDialogComponent,
        OptionsViewDialogComponent,
        SimplePaginationComponent,
        SimplePaginationContainerComponent,
        PopoverComponent,
        AppSpinnerComponent,
        StarRatingComponent,
        EfficientListViewComponent,
        EfficientListChildComponent,
        ExportInfoDialogComponent,
        ExportXmlJsonDialogComponent,
        OverlappedQuestionsDialogComponent,
        RestoreResponseBranchedQestionsDialogComponent,
        AutocompleteComponent,
        SharingFormComponent,
        InviteComponent,
        InviteFormComponent,
        CurrentUsersAppManagementComponent,
        CurrentUsersTableComponent,
        AllTagsPanelComponent,
        BulkInviteDialogComponent,
        // directives
        EmptyValueValidatorDirective,
        AgWordCloudDirective,
        ComponentHostDirective,
        SelectAllCaptionDirective,
        ScrollSpyDirective,
        TrimValueDirective,
        NumbersOnlyDirective,
        SetTitleDirective,
        // pipes
        UserRolePipe,
        InitialFocusDirective,
        CheckedPipe,
        OfActivityTypePipe,
        TimesPipe,
        QuestionGroupPipe,
        FormSubmissionDialogComponent,
        SearchComponent,
        ChangeUsernameDialogComponent,
        SessionPromotedSnackBarComponent,
        FormChangeStatusSnackBarComponent,
        InformationSnackBarComponent,
        ContributorsListDialogComponent,
        TasksCountContainerComponent,
        SlideLightboxComponent,
        ExportRenameDialogComponent,
        CurrentUsersAppManagementPipe,
    ],
    imports: [
        MaterialModule,
        RouterModule,
        // StarRatingModule.forRoot(),
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        CommonLibModule,
        CommonDeploymentsModule,
    ],
    exports: [
        // components
        TimePickerComponent,
        ConfirmActionDialogComponent,
        ChangePasswordDialogComponent,
        AttachmentsDialogComponent,
        SessionsListDialogComponent,
        AlertDialogComponent,
        UserRoleChangedComponent,
        ImportQuestionsDialogComponent,
        ConfirmDialogWithKeywordComponent,
        TtListItemComponent,
        ProjectNameDialog,
        DisagreeDialogComponent,
        CopyAppDialogComponent,
        ApprovalRequestDialogComponent,
        EventFlowResultsDialogComponent,
        EventFlowValidationDialogComponent,
        UserPicComponent,
        HeaderContainerComponent,
        HeaderComponent,
        BaseHeaderComponent,
        SearchComponent,
        PaginatorComponent,
        DeploymentsCreateSnackBarComponent,
        MembersComponent,
        TasksListContainerComponent,
        TasksListComponent,
        TaskComponent,
        GateComponent,
        ActivityProgressLabelComponent,
        FormStateComponent,
        ImageCircleComponent,
        TasksCountComponent,
        TasksCountContainerComponent,
        SelectDemographicsForExportComponent,
        WorkbooksExportDialogComponent,
        AlertHtmlDialogComponent,
        OptionsViewDialogComponent,
        SimplePaginationComponent,
        SimplePaginationContainerComponent,
        AppSpinnerComponent,
        StarRatingComponent,
        EfficientListViewComponent,
        EfficientListChildComponent,
        ExportInfoDialogComponent,
        ExportXmlJsonDialogComponent,
        OverlappedQuestionsDialogComponent,
        RestoreResponseBranchedQestionsDialogComponent,
        AutocompleteComponent,
        SharingFormComponent,
        InviteComponent,
        InviteFormComponent,
        CurrentUsersAppManagementComponent,
        CurrentUsersTableComponent,
        AllTagsPanelComponent,
        BulkInviteDialogComponent,
        // directives
        EmptyValueValidatorDirective,
        AgWordCloudDirective,
        ComponentHostDirective,
        SelectAllCaptionDirective,
        ScrollSpyDirective,
        TrimValueDirective,
        NumbersOnlyDirective,
        SetTitleDirective,
        // pipes
        UserRolePipe,
        InitialFocusDirective,
        CheckedPipe,
        OfActivityTypePipe,
        TimesPipe,
        QuestionGroupPipe,
        CurrentUsersAppManagementPipe,
    ],
})
export class SharedModule {}
