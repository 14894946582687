import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter
} from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { activityTypesDisplayNames, AnsweredQuestionModel } from '@app/core/models';
import {
    Task,
    TaskType,
    EngageFormStatus
} from '@thinktank/common-lib';
import { trackByKey } from '@app/core/utils';
import { FocusAbilityService } from '@app/core/services/focus-ability.service';
import { TaskService } from '@app/core/services/tasks.service';
import { FormDemographicsService } from '@app/apps/activities/forms/form-services';

@Component({
    selector: 'app-task',
    templateUrl: './task.component.html',
    styleUrls: ['../task-gate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TaskComponent implements OnInit {
    @Input() data: Task;
    @Input() type: TaskType;
    @Input() projectKey: string;
    @Input() demographics: { name: string, status: EngageFormStatus }[];
    @Input() completed?: boolean;
    @Input() blocked?: boolean;
    @Input() activityLink?: boolean;
    @Input() emulate?: boolean;
    @Input() isShowFormState: boolean;
    @Input() hasBookmark: boolean;
    @Input() tooltipMessage?: string;
    @Input() isLeader: boolean;
    @Input() isHome?: boolean;
    @Input() answeredQuestions?: AnsweredQuestionModel;
    @Output() checkboxClick = new EventEmitter<void>();

    expanded: boolean;
    displayType: string;
    trackByKey = trackByKey;
    activeDemographicsArray$: Observable<string[]>;

    constructor(
        private router: Router,
        private focusAbilityService: FocusAbilityService,
        private taskService: TaskService,
        private formDemographicsService: FormDemographicsService
    ) { }

    get showExpandIcon(): boolean {
        return !this.emulate
            && !this.blocked
            && (!!this.data.demographic_status && !this.isLeader);
    }

    get showProgressInfo(): boolean {
        return !!this.answeredQuestions && Object.keys(this.answeredQuestions).length
            && !this.emulate
            && !this.completed
            && !this.blocked
            && !!this.data && this.data.type === TaskType.Forms
            && this.hasBookmark; // for BC to not show this functionality for activity without bookmark;
    }

    get formStatus(): EngageFormStatus {

        if (!!this.data.demographic_status && this.isShowFormState) {
            return EngageFormStatus.Approved;
        }

        return this.data.form_status;
    }

    toggleExpanded(): void {
        this.expanded = !this.expanded;
    }

    toggleCompleted(): void {
        if (this.emulate && !this.blocked) {
            this.checkboxClick.emit();
        }
    }

    async navigate(): Promise<void> {
        const focus = await this.focusAbilityService.getFocus(this.projectKey).pipe(take(1)).toPromise();

        if (!this.isHome) {
            this.taskService.setCompleteMyTaskMode(true);
        }

        // Navigate first of all to focused activity
        if (!!focus) {
            this.focusAbilityService.navigateUsingFocusObject(this.projectKey, focus);
            return;
        }

        this.router.navigate([this.activityLink]);
    }

    getUserSelectedDemographics(item: Task): Observable<string[]> {

        const { activity_id } = item as Task;
        return this.formDemographicsService.getSelectedDemographicsByActivityId(activity_id);
    }

    ngOnInit() {
        this.displayType = activityTypesDisplayNames[this.type];

        // get selected demographics for this user for task
        this.activeDemographicsArray$ = this.getUserSelectedDemographics(this.data);
    }
}
