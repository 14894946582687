import {
    Component,
    Input,
    ChangeDetectionStrategy
} from '@angular/core';

import { trackById } from '@thinktank/common-lib';
import { Module } from '../../../models';
import { DeploymentsService } from '../../../services/deployments.service';

@Component({
    selector: 'gs-module-selection-editor',
    templateUrl: './module-selection-editor.component.html',
    styleUrls: ['./module-selection-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleSelectionEditorComponent {

    @Input() deploymentId: string;
    @Input() deploymentModules: Module[];

    trackById = trackById;

    constructor(
      private deploymentService: DeploymentsService
    ) {}

    moduleIsInDeployment(moduleToCheck: Module): boolean {
        return !!moduleToCheck && moduleToCheck.active;
    }

    addModuleToDeployment(moduleToAdd: Module): void {
        this.deploymentService.updateModuleActiveInDeployment(true, moduleToAdd.id, this.deploymentId);
    }

    removeModuleFromDeployment(moduleToRemove: Module): void {
        this.deploymentService.updateModuleActiveInDeployment(false, moduleToRemove.id, this.deploymentId);
    }
}
