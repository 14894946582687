<ul class="tasks-list">
  <ng-container *ngFor="let item of list; trackBy: trackByKey; let index = index">
    <ng-container
      *ngIf="{
        completed: isCompleted(item),
        blocked: isBlocked(item, index)
      } as metadata"
    >
      <li
        *ngIf="showItem(item)"
        class="tasks-list-item"
        [ngClass]="{
          completed: metadata.completed,
          blocked: metadata.blocked
        }"
      >
        <app-task
          *ngIf="!item.data"
          [data]="item"
          [demographics]="getDemographics(item)"
          [type]="item.type"
          [projectKey]="projectKey"
          [isHome]="isHome"
          [isLeader]="isLeader"
          [activityLink]="getActivityLink(item, index)"
          [emulate]="emulate"
          [blocked]="metadata.blocked"
          [completed]="metadata.completed"
          [isShowFormState]="isShowFormState(item)"
          [tooltipMessage]="getTooltipMessage(item, index, metadata.blocked, metadata.completed)"
          [answeredQuestions]="getAnsweredQuestionsByActivityId(item)"
          [hasBookmark]="bookmarkMap[item.activity_id] | async"
          (checkboxClick)="toggleCompleted(item.key)"
        ></app-task>
        <app-gate
          *ngIf="item.data"
          [data]="item.data"
          [projectKey]="projectKey"
          [isLeader]="isLeader"
          [isApp]="isApp"
          [emulate]="emulate"
          (checkboxClick)="toggleCompleted(item.key)"
          (gateCompleted)="completeGate(item.key, item.data.name)"
        ></app-gate>
      </li>
    </ng-container>
  </ng-container>
</ul>
