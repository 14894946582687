<div class="export-info-dialog thinktank-theme">
  <div mat-dialog-title>
    <div mat-dialog-title class="dialog-title">
      <h3 class="h3">{{data.title}}</h3>
      <button
        mat-icon-button
        tabindex="-1"
        class="close"
        (click)="cancel()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content [formGroup]="modalForm">
    <h6 class="section-title text-dark">Save As</h6>
    <div class="export-to-option export-to-xml">
      <mat-checkbox
        formControlName="xml"
      >
        XML
      </mat-checkbox>
      <!--TODO: add '*ngIf="!isSpecifiedMappingTemplate' to the next block after integration with Jitterbit-->
      <p class="text-hint text-secondary dialog-info">{{ validationMessages.specifyMappingTemplate }}</p>
    </div>
      <div class="export-to-option export-to-json">
      <mat-checkbox formControlName="json">
        JSON
      </mat-checkbox>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="actions">
    <button
      class="tt-button-filled confirm-button"
      [disabled]="modalForm.invalid"
      (click)="export()"
    >
      {{ data.confirmBtnText }}
      <app-spinner *ngIf="isPendingStatus" class="confirm-button-spinner"></app-spinner>
    </button>
    <button
      mat-dialog-close
      class="tt-button-outline"
      (click)="cancel()"
    >
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
