import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FirestoreService } from '@thinktank/common-lib';
import { combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, take } from 'rxjs/operators';

import {
    Activity,
    App,
    appInviteTypes,
    AppRoster,
    AppUser,
    emptyApp,
    FilterForAppsData,
    InstanceFocus,
    InstanceStatus,
    InvitedUser,
    User,
} from '@app/core/models';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';

@Injectable()
export class FirebaseAppService extends FirebaseUtilityService {
    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private firestoreService: FirestoreService,
        private angularFireFunctions: AngularFireFunctions,
    ) {
        super();
    }

    getAllApps(): Observable<App[]> {
        return this.listWithKeys(this.db.list<App>('/ssot/_apps')).pipe(
            map(apps =>
                apps
                    .filter(app => app.name && app.date_created)
                    .map(app => {
                        return {
                            ...app,
                            number_of_users: Object.keys(app?.ownership || app?.member_ids || {})?.length || 1,
                        };
                    }),
            ),
        );
    }

    getApp(appKey: string): Observable<App> {
        return this.objectWithKey(this.db.object<App>(`/ssot/_apps/${appKey}`));
    }

    getAppTitle(appKey: string): Observable<string> {
        return this.db.object<string>(`/apps/${appKey}/name`).valueChanges();
    }

    getLeaderSessions(currentSessionKey: string, isDemographicActivity?: boolean): Observable<App[]> {
        const currentUserKey = this.authUserService.getCurrentUserId();
        const userSessions$ = this.db
            .list<App>(`/users/${currentUserKey}/app_instances`)
            .snapshotChanges()
            .pipe(
                map(sessions => {
                    return sessions
                        .map(session => ({ key: session.key, role: session.payload.val() }))
                        .filter((session: any) => {
                            return ['leader', 'owner'].includes(session.role) && session.key !== currentSessionKey;
                        });
                }),
            );
        const compare = (a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0);
        let demographicsMap$;

        if (isDemographicActivity) {
            demographicsMap$ = this.listWithKeys(
                this.db.list<Activity>('/ssot/_activities', ref =>
                    ref.orderByChild('activity_type').equalTo('demographics'),
                ),
            ).pipe(
                map(activities => {
                    return activities.reduce(
                        (accumulator, activity) => ({
                            ...accumulator,
                            [activity['app_id']]: true,
                        }),
                        {},
                    );
                }),
            );
        } else {
            demographicsMap$ = of({});
        }

        return combineLatest(userSessions$, demographicsMap$).pipe(
            mergeMap(([useSessions, demographicsMap]) => {
                const sessionsObservables = useSessions.length
                    ? useSessions.map(session => {
                          return this.objectWithKey(this.db.object<App>(`/apps/${session.key}`));
                      })
                    : of({});

                return combineLatest(sessionsObservables).pipe(
                    map((sessions: any[]) => {
                        return sessions
                            .filter(session => !!session.key)
                            .map(session => {
                                return <App>{
                                    ...session,
                                    hasDemographicActivity: demographicsMap[session.key],
                                };
                            })
                            .sort(compare);
                    }),
                );
            }),
        );
    }

    getAppField(appKey: string, field: string): Observable<any> {
        return this.db.object<any>(`/ssot/_apps/${appKey}/${field}`).valueChanges();
    }

    generateAppPasskeyGUID(appKey: string): Promise<any> {
        return this.updateAppField(appKey, 'passkeyGUID', this.db.createPushId());
    }

    async createApp(owner: User): Promise<string> {
        const key = this.db.createPushId();
        const app = <App>{
            ...emptyApp,
            date_created: Date.now(),
            date_last_updated: Date.now(),
            owner: owner.key,
            members: {
                [owner.key]: 'owner',
            },
            ownership: {
                [owner.key]: {
                    email: owner.email,
                    role: 'owner',
                },
            },
        };

        // We need to write /apps first so it can be used in the security rule to allow a write to /ssot/_apps
        await this.db.object(`/apps/${key}`).update(app);

        const updates = {};

        updates[`users/${owner.key}/apps/${key}`] = 'owner';
        updates[`ssot/_apps/${key}`] = this.mapAppForSsot(app);

        await this.db.object('/').update(updates);

        return key;
    }

    updateAppField(appKey: string, field: string, fieldValue: any): Promise<any> {
        const updates = {};

        updates[`apps/${appKey}/${field}`] = fieldValue;
        updates[`ssot/_apps/${appKey}/${field}`] = fieldValue;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    removeApp(appKey: string, live?: string): void {
        const updates = {};
        const membersOrOwnerships = live ? 'members' : 'ownership';

        this.db
            .list(`/apps/${appKey}/${membersOrOwnerships}`)
            .snapshotChanges()
            .pipe(take(1))
            .subscribe(members => {
                members.forEach(member => {
                    const memberKey = member.key;

                    // We are going to remove the app from the users in cloud functions so we will not add secondary paths here
                    if (!live) {
                        updates[`users/${memberKey}/apps/${appKey}`] = null;
                    } else {
                        updates[`users/${memberKey}/app_instances/${appKey}`] = null;
                    }
                });

                updates[`apps/${appKey}`] = null;
                updates[`ssot/_apps/${appKey}`] = null;

                this.db.object('/').update(updates);
            });
    }

    removeAppSsot(appKey: string): Promise<void> {
        // TODO: replace removeApp with this after we move to SSOT
        return this.db.object(`/ssot/_apps/${appKey}`).remove();
    }

    getInstanceStatus(appKey: string): Observable<InstanceStatus> {
        return this.db.object<InstanceStatus>(`/ssot/_apps/${appKey}/status`).valueChanges();
    }

    updateInstanceFocus(appKey: string, focus: InstanceFocus): Promise<void> {
        const updatedFocus = focus
            ? {
                  ...focus,
                  user_id: this.authUserService.getCurrentUserId(),
                  user_name: this.authUserService.getCurrentUserFullName(),
                  timestamp: Date.now(),
              }
            : null;

        const updates = {};

        updates[`apps/${appKey}/focus`] = updatedFocus;
        updates[`ssot/_apps/${appKey}/focus`] = updatedFocus;

        return this.db.object('/').update(updates);
    }

    getAppUsers(appKey: string): Observable<AppUser[]> {
        return this.listWithKeys(
            this.db.list<AppUser>('/ssot/appSharing', ref => ref.orderByChild('app_id').equalTo(appKey)),
        );
    }

    removeUserFromApp(appKey: string, userKey: string, appSharingKey?: string): Promise<void> {
        const updates = {};

        updates[`apps/${appKey}/ownership/${userKey}`] = null;
        updates[`ssot/_apps/${appKey}/ownership/${userKey}`] = null;
        updates[`users/${userKey}/apps/${appKey}`] = null;
        if (appSharingKey) {
            updates[`ssot/appSharing/${appSharingKey}`] = null;
        }

        return this.db.object('/').update(updates);
    }

    // TODO: Rename this to removeUserFromApp when we move the app to use the SSOT tables
    removeUserFromAppSsot(appSharingKey: string): Promise<void> {
        return this.db.object(`/ssot/appSharing/${appSharingKey}`).remove();
    }

    // TODO: Break this function out to support adding collaborators to apps and add session collaborators to app instances
    async handleUsersInApp(collaborators: any[], appKey: string, appUrl: string, message?: string): Promise<void> {
        const result = {};
        const app = await this.getApp(appKey).pipe(take(1)).toPromise();
        const appName = (app && app.name) || null;
        const appImage = (app && app.image_url) || null;
        const sender = this.authUserService.getCurrentUser();
        const senderName = User.getUserName(sender);
        const sideEffectPromises = collaborators.map(async user => {
            const snapshot = await this.authUserService.getUserByUsername(user.email).pipe(take(1)).toPromise();
            const isNewUser = snapshot.length === 0;
            const newMessageKey = this.db.createPushId();
            const userKey = isNewUser ? this.db.createPushId() : snapshot[0].key;
            const invitedUserRole = await this.addOrUpdateUserInApp(user, userKey, appKey, isNewUser, snapshot);
            const disableEmailNotifications = (<User>(snapshot[0] || {})).disable_email_notifications;

            if (!disableEmailNotifications) {
                result[`/messages/${newMessageKey}`] = {
                    email: user.email,
                    instanceUrl: appUrl,
                    type: appInviteTypes[invitedUserRole],
                    data: {
                        appName,
                        appImage,
                        senderName,
                        comment: message || null,
                        instanceUrl: appUrl,
                        env: appUrl.split('/app')[0],
                    },
                };
            }
        });

        await Promise.all(sideEffectPromises);
        await this.db.object('/').update(result);
    }

    async handleUsersInApps(
        apps: App[],
        usersData: InvitedUser[],
        message: string,
        isInviteToTemplates: boolean,
    ): Promise<void> {
        try {
            const environmentLink = isInviteToTemplates
                ? window.location.origin + '/navigation?tab=My%20Collections'
                : window.location.origin + '/navigation?tab=My%20ThinkTank';
            const environmentName = window.location.host;

            const sender = this.authUserService.getCurrentUser();
            const senderName = User.getUserName(sender);

            const data = {
                usersData,
                apps,
                environmentLink,
                environmentName,
                senderName,
                message,
                isInviteToTemplates,
                env: window.location.origin,
            };

            await this.angularFireFunctions.httpsCallable('handleUsersInApps')(data).toPromise();
        } catch (err) {
            console.log('Error while handling users in apps!');
            console.error({ err });
        }
    }

    async addOrUpdateUserInApp(
        user: any,
        userKey: string,
        appKey: string,
        isNewUser: boolean,
        snapshot: User[],
    ): Promise<string> {
        const updates = {};
        const collaboratorData = {
            email: user.email,
            role: user.role,
        };
        let userData;

        if (isNewUser) {
            const newUserData = {
                email: user.email,
                username: user.email,
                created_at: user.created_at,
                apps: {
                    [appKey]: user.role,
                },
            };

            updates[`users/${userKey}`] = newUserData;
            updates[`ssot/_users/${userKey}`] = newUserData;
            userData = newUserData;
        } else {
            updates[`users/${userKey}/apps/${appKey}`] = user.role;
            userData = snapshot[0];
        }

        updates[`ssot/_apps/${appKey}/member_ids/${userKey}`] = user.role;
        updates[`apps/${appKey}/member_ids/${userKey}`] = user.role;

        updates[`ssot/_apps/${appKey}/ownership/${userKey}`] = collaboratorData;
        updates[`apps/${appKey}/ownership/${userKey}`] = collaboratorData;

        // TODO: Add the user to appSharing and appRoster for the time being until we can break them out accordingly
        const sharingKey = this.db.createPushId();
        const appRosterData = this.buildAppRoster(userData, user.role, appKey, userKey);

        updates[`ssot/appSharing/${sharingKey}`] = appRosterData;
        updates[`ssot/appRoster/${sharingKey}`] = appRosterData;

        await this.db.object('/').update(updates);

        return user.role;
    }

    isAppUser(userKey: string, appKey: string): Observable<boolean> {
        // get a legacy app because its data is used to detect the visibility of each app on the dashboard
        // so the legacy app is an actual source for members data
        // see the method getAllAppsForUser in the FirebaseNavigationService for detailed information
        return this.getApp(appKey).pipe(
            map(app => {
                const isLegacySessionUser = !!(app.members || {})[userKey];
                const isSessionUser = !!(app.member_ids || {})[userKey];
                const isAppUser = !!(app.ownership || {})[userKey];

                return isLegacySessionUser || isSessionUser || isAppUser;
            }),
        );
    }

    async exportTemplate(
        env: string,
        isTemplate: boolean,
        userKey: string,
        filterData: FilterForAppsData,
    ): Promise<void> {
        try {
            const timezone = new Date().getTimezoneOffset();
            const data = {
                userId: this.authUserService.getCurrentUserId(),
                env,
                isTemplate,
                timezone,
                userKey,
                filterData,
            };

            await this.angularFireFunctions.httpsCallable('exportTemplates')(data).toPromise();
        } catch (err) {
            console.log('Error while exporting templates!');
            console.error({ err });
        }
    }

    private mapAppForSsot(app: App): App {
        const ssotApp = {
            ...app,
            owner_id: app.owner,
            instance_ids: app.instances,
            member_ids: app.members,
        };

        delete ssotApp.owner;
        delete ssotApp.instances;
        delete ssotApp.members;

        return ssotApp;
    }

    private buildAppRoster(user: User, role: string, appKey: string, userKey: string): AppRoster {
        return {
            app_id: appKey,
            user_id: userKey,
            first_name: user.firstName || '',
            last_name: user.lastName || '',
            username: user.username,
            role: role,
        };
    }
}
