import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { AssignmentFacade, AssignmentFacadeToken } from './assignment.facade';
import { ObjectType, UserRole } from '../../models';

@Component({
    selector: 'gs-assignment',
    templateUrl: './assignment.component.html',
    styleUrls: ['./assignment.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AssignmentComponent {
    vm$ = this.assignmentFacade.vm$;
    activeDemographics: string[];

    constructor(
        @Inject(AssignmentFacadeToken) private assignmentFacade: AssignmentFacade
    ) {}

    useDemographics(isGlobal: boolean, objectType: ObjectType): boolean {
        return isGlobal ? false : objectType !== ObjectType.Module;
    }

    demographicsLength(isGlobal: boolean, objectType: ObjectType): boolean {
        return this.useDemographics(isGlobal, objectType)
            && !!this.activeDemographics
            && this.activeDemographics.length > 0;
    }

    demographicsInviteEnabled(isGlobal: boolean, objectType: ObjectType): boolean {
        return !this.useDemographics(isGlobal, objectType)
             || this.demographicsLength(isGlobal, objectType);
    }

    isSubprocess(objectType: ObjectType): boolean {
        return !!objectType && objectType === ObjectType.Subprocess;
    }

    invite(
        emailList: { email_address: string, role: UserRole }[],
        deploymentId: string,
        objectId: string,
        objectType: ObjectType,
        isGlobal: boolean
    ): void {
        emailList.forEach(userData => {
            this.assignmentFacade.assignUser(
                userData.email_address,
                userData.role,
                deploymentId,
                objectId,
                objectType,
                this.activeDemographics,
                isGlobal
            );
        });
    }

    onUpdateDemographics(demographics: string[]): void {
        this.activeDemographics = demographics;
    }
}
