import { createReducer, on, Action } from '@ngrx/store';

import * as DemographicsActions from '../actions';
import { initialState, WorkbooksState } from '../state';

const demographicReducer = createReducer(
    initialState,
    on(DemographicsActions.getWorkbookSheetsByActivity, (state) => {
        return ({
            ...state,
            loading: true,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getWorkbookSheetsByActivitySuccess, (state, { activityId, workbookSheets }) => {
        state.workbooksSheetsByActivity[activityId] = [...workbookSheets];

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getWorkbookSheetsByActivityFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(DemographicsActions.getFormSheetKey, (state) => {
        return ({
            ...state,
            loading: true,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getFormSheetKeySuccess, (state, { formId, formSheetKey }) => {
        state.formSheetKeyByForm[formId] = formSheetKey;

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getFormSheetKeyFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
        
    )),
    on(DemographicsActions.getQuestionSheetKey, (state) => {
        return ({
            ...state,
            loading: true,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getQuestionSheetKeySuccess, (state, { questionId, questionSheetKey }) => {
        state.questionSheetKeyByQuestion[questionId] = questionSheetKey;

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getQuestionSheetKeyFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(DemographicsActions.getFormQuestionsWithWorkbookSheet, (state) => {
        return ({
            ...state,
            loading: true,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getFormQuestionsWithWorkbookSheetSuccess, (state, { questionId, questions }) => {
        state.formQuestionsWithWorkbookSheet[questionId] = [...questions];

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(DemographicsActions.getFormQuestionsWithWorkbookSheetFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    ))
);

export function reducer(state: WorkbooksState | undefined, action: Action) {
    return demographicReducer(state, action);
}
