import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors } from '@angular/forms';

import { NameValidationData } from '@app/core/models/validators-models';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';

@Injectable({
    providedIn: 'root',
})
export class NameValidationService {
    public isSlideNameInvalid = false;
    public isVersionNameInvalid = false;

    constructor(private functions: AngularFireFunctions, private _authenticatedUserService: AuthenticatedUserService) {}

    nameAlreadyExistsAsyncValidator(data: NameValidationData): AsyncValidatorFn {
        return async (control: FormControl): Promise<ValidationErrors | null> => {
            if (!(control.value || '').trim()) {
                return null;
            }

            if (data.type === 'activity' && control.pristine) {
                return null;
            }

            if (data.type === 'slide' && !control.dirty) {
                return this.isSlideNameInvalid ? { nameAlreadyExists: true } : null;
            }

            if (data.type === 'version' && !control.dirty) {
                return this.isVersionNameInvalid ? { nameAlreadyExists: true } : null;
            }

            try {
                const isValid = await this.functions
                    .httpsCallable('validateName')({
                        ...data,
                        name: control.value,
                        userKey: this._authenticatedUserService.getCurrentUserId(),
                    })
                    .toPromise();
                const errors = isValid ? null : { nameAlreadyExists: true };

                control.setErrors(errors);

                return errors;
            } catch (e) {
                control.setErrors(null);
                console.log(e.message);

                return null;
            }
        };
    }

    sameNameValidation(
        control: AbstractControl,
        isInRepository: boolean,
        originKey: string,
        appKey: string,
        userKey: string,
        exportVersionKey?: string,
    ): void {
        if (isInRepository) {
            const nameValidationData = <NameValidationData>{
                appKey,
                type: 'version',
                userKey,
                originKey,
                exportVersionKey,
            };

            control.setAsyncValidators(this.nameAlreadyExistsAsyncValidator(nameValidationData));
        } else {
            control.clearAsyncValidators();
        }
    }
}
