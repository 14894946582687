<div class="members">

  <div
    *ngFor="let user of slicedUsersList; trackBy: trackByKey"
    class="user"
    matTooltip="{{ getTooltipText(user) }}"
    matTooltipClass="members-mat-tooltip"
    matTooltipPosition="above"
  >
    <div
      *ngIf="user.image_url"
      class="user-image"
      [ngStyle]="{'backgroundImage': 'url(' + user.image_url + ')'}"
    ></div>
    <span class="user-text" *ngIf="!user.image_url">{{ getUserName(user, true) }}</span>
  </div>

  <div
    *ngIf="usersList?.length >= 5"
    class="user with-number"
    (click)="openContributorsListModal()"
  >
    <div
      *ngIf="usersList[4].image_url"
      class="user-image"
      [ngStyle]="{'backgroundImage': 'url(' + usersList[4].image_url + ')'}"
    ></div>
    <span class="user-number-bg"></span>
    <span class="user-number-text">{{ userNamesLength }}</span>
  </div>
</div>
