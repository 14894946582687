import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Injectable()
export class AnalyticsService {

    constructor(private analytics: AngularFireAnalytics) {}

    setUserId(userId: string): void {
        this.analytics.setUserId(userId);
    }

    logAnalyticsEvent(eventName: string, eventData?: any): void {
        this.analytics.logEvent(eventName, eventData);
    }
}
