import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectValues',
    pure: true
})
export class ObjectValuesPipe implements PipeTransform {

    transform(object: Object): any[] | boolean {
        return Object.values(object || {});
    }

}
