import { Component } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';

@Component({
    selector: 'app-login-header',
    templateUrl: './login-header.component.html',
    styleUrls: ['./login-header.component.scss'],
})
export class LoginHeaderComponent {
    constructor(private authenticationService: AuthenticationService) {}

    ssoLogin() {
        this.authenticationService.login();
    }
}
