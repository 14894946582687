import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { listWithKeys } from '@app/core/utils';
import { FormStatusesMap } from '@app/root-store/models/forms-feature.models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormBranchingVisibility } from '@app/core/models/forms.model';

@Injectable()
export class FormsFeatureService {

    constructor(
        private db: AngularFireDatabase
    ) { }

    public getFormsKeysByActivityKey(activityKey: string): Observable<string[]> {
        if (!activityKey) {
            return of([]);
        }

        return listWithKeys(
            this.db.list<any>(
                '/ssot/_forms',
                (ref) => ref.orderByChild('activity_id').equalTo(activityKey)
            )
        ).pipe(
            map(list => {
                return list.map(form => {
                    return form.key;
                });
            })
        );
    }

    public getFormKeyByActivityKey(activityKey: string): Observable<string> {
        if (!activityKey) {
            return of('');
        }

        return this.db.object<string>(`/ssot/_activities/${activityKey}/form_id`).valueChanges();
    }

    public getFormStatusesByActivityKey(activityKey: string): Observable<FormStatusesMap> {
        if (!activityKey) {
            return of({});
        }

        return this.db.object<FormStatusesMap>(`/ssot/formStatus/${activityKey}`).valueChanges();
    }

    public getBranchingVisibilityByActivityKey(activityKey: string): Observable<{ [questionKey: string]: FormBranchingVisibility }> {
        if (!activityKey) {
            return of({});
        }
        return this.db.object<{ [questionKey: string]: FormBranchingVisibility }>(`/ssot/formBranching/${activityKey}`)
            .valueChanges();
    }
}
