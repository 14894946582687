import { Observable, combineLatest, forkJoin, of } from 'rxjs';
import { distinctUntilChanged, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import {
    AngularFireDatabase,
    AngularFireList,
    AngularFireObject,
    AngularFireAction,
    DatabaseSnapshot
} from '@angular/fire/compat/database';
import { isEqual } from 'lodash';

import {
    Navigation,
    App,
    User
} from '@app/core/models';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { listWithKeys, sortBySequenceAsc } from '@app/core/utils';
import { FirebaseUsersService } from '@app/core/services/users.service';

@Injectable()
export class FirebaseNavigationService {

    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private usersService: FirebaseUsersService
    ) { }

    getNavigationData(): Observable<Navigation[]> {
        return listWithKeys<Navigation>(
            this.db.list<Navigation>('/Navigation')
        ).pipe(map(sortBySequenceAsc));
    }

    getApps(): AngularFireList<App> {
        return this.db.list<App>('/apps');
    }

    getAppById(key: string): AngularFireObject<App> {
        return this.db.object<App>(`/apps/${key}`);
    }

    pushNewSession(appKey: string): void {
        const key = this.db.createPushId();
        const userKey = this.authUserService.getCurrentUserId();
        this.db.object(`/work_queue/app_instance/pending/${key}`).update({
            [appKey]: userKey
        });
    }

    getAppsForUser(userKey: string): Observable<App[]> {
        return this.db.list<App>('/apps')
            .snapshotChanges()
            .pipe(map((changes) => {
                return changes
                    .filter((change) => (change.payload.val().members || {})[userKey])
                    .map((change) => <App>({
                        key: change.key,
                        ...change.payload.val()
                    }));
            }));
    }

    getAllAppsForUser(userKey: string): Observable<App[]> {
        return combineLatest(
            this.db.list<App>(`/users/${userKey}/app_instances`).snapshotChanges(),
            this.db.list<App>(`/users/${userKey}/apps`).snapshotChanges(),
            (appInstances, apps) => {
                return appInstances.concat(apps);
            }
        ).pipe(
            switchMap((userApps) => {
                if (!userApps.length) {
                    return of([]);
                }

                // if this part will be refactored
                // please take a look at the isAppUser method in the FirebaseAppService
                const appObservables = userApps.map((app) => this.db.object<App>(`/apps/${app.key}`)
                    .snapshotChanges()
                    .pipe(distinctUntilChanged(this.distinctUntilChangedCompareFunction)));

                if (!appObservables.length) {
                    return of(userApps.map((app) => {
                        return <App>{
                            key: app.key,
                            ...app.payload.val()
                        };
                    }));
                }

                return combineLatest(...appObservables).pipe(
                    map((apps) => {
                        return apps
                            .filter((app) => app.key !== null)
                            .map((app) => {
                                return <App>{
                                    key: app.key,
                                    ...app.payload.val()
                                };
                            });
                    }),
                    mergeMap((apps) => {
                        // We have to go get the owner full name and image URL for each app unless we own it
                        const ownerFullNames = {
                            [this.authUserService.getCurrentUserId()]: {
                                fullName: this.authUserService.getCurrentUserFullName(),
                                imageUrl: this.authUserService.getCurrentImage()
                            }
                        };

                        // This builds an object of owner lookups, only 1 lookup per owner and don't include me please!
                        const ownerLookups = apps.reduce((accumulator, app) => {
                            if (!accumulator[app.owner]) {
                                accumulator[app.owner] = this.usersService.getUserInfo(app.owner).pipe(
                                    take(1),
                                    tap((owner: User) => {
                                        ownerFullNames[app.owner] = {
                                            fullName: User.getUserName(owner),
                                            imageUrl: owner && owner.image_url || ''
                                        };
                                    })
                                );
                            }
                            return accumulator;
                        }, {});

                        // Now we run the lookups and when they are finished we have all the owner info and can add it to the apps
                        return forkJoin(Object.values(ownerLookups)).pipe(
                            map(() => {
                                return apps.map((app) => {

                                    if (!app.owner) {
                                        return null;
                                    }

                                    app.ownerFullName = ownerFullNames[app.owner].fullName;
                                    app.ownerImageUrl = ownerFullNames[app.owner].imageUrl;
                                    return app;
                                }).filter(app => !!app);
                            })
                        );
                    })
                );
            })
        );
    }

    getAllAppsForUserProfile(userKey: string): Observable<App[]> {
        return combineLatest(
            this.db.list<App>(`/users/${userKey}/app_instances`).snapshotChanges(),
            this.db.list<App>(`/users/${userKey}/apps`).snapshotChanges(),
            (appInstances, apps) => {
                return appInstances.concat(apps);
            }
        ).pipe(
            switchMap((userApps) => {
                if (!userApps.length) {
                    return of([]);
                }

                const appObservables = userApps.map((app) => this.db.object<App>(`/apps/${app.key}`)
                    .snapshotChanges()
                    .pipe(distinctUntilChanged(this.distinctUntilChangedCompareFunction)));

                if (!appObservables.length) {
                    return of(userApps.map((app) => {
                        return <App>{
                            key: app.key,
                            ...app.payload.val()
                        };
                    }));
                }

                return combineLatest(appObservables).pipe(
                    map((apps) => {
                        return apps
                            .filter((app) => app.key !== null)
                            .map((app) => {
                                return <App>{
                                    key: app.key,
                                    ...app.payload.val()
                                };
                            });
                    })
                );
            })
        );
    }

    private distinctUntilChangedCompareFunction(
        prev: AngularFireAction<DatabaseSnapshot<App>>,
        curr: AngularFireAction<DatabaseSnapshot<App>>
    ): boolean {
        const prevData = prev.payload.val();
        const currentData = curr.payload.val();

        if (!prevData || !currentData) {
            return true;
        }

        const valueChangesMap = {
            name: prevData.name === currentData.name,
            key: prevData.key === currentData.key,
            members: isEqual(prevData.members, currentData.members),
            ownership: !prevData.live ? isEqual(prevData.ownership, currentData.ownership) : true,
            isAppParticipant: prevData.isAppParticipant === currentData.isAppParticipant,
            participants_tasks_list: prevData.participants_tasks_list === currentData.participants_tasks_list
        };

        // When at least one of the values is false (previous value different from current value) emit App
        return valueChangesMap.name
            && valueChangesMap.key
            && valueChangesMap.members
            && valueChangesMap.ownership
            && valueChangesMap.isAppParticipant
            && valueChangesMap.participants_tasks_list;
    }
}
