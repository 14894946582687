import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DisableClearMappingButtonService {
    private clearDisabled$ = new BehaviorSubject<boolean>(false);

    constructor() {}

    disable(status: boolean): void {
        this.clearDisabled$.next(status);
    }

    getStatus(): Observable<boolean> {
        return this.clearDisabled$.asObservable();
    }
}
