import { Inject, Injectable } from '@angular/core';
import { Observable, fromEvent, EMPTY } from 'rxjs';
import { delay, map } from 'rxjs/operators';

import { FullscreenApi } from '@app/core/models';

@Injectable()
export class FullscreenService {
    private readonly apis = <{ [apiKey: string]: FullscreenApi }>{
        w3: {
            enabled: 'fullscreenEnabled',
            element: 'fullscreenElement',
            request: 'requestFullscreen',
            exit: 'exitFullscreen',
            events: {
                change: 'fullscreenchange',
                error: 'fullscreenerror'
            }
        },
        webkit: {
            enabled: 'webkitFullscreenEnabled',
            element: 'webkitCurrentFullScreenElement',
            request: 'webkitRequestFullscreen',
            exit: 'webkitExitFullscreen',
            events: {
                change: 'webkitfullscreenchange',
                error: 'webkitfullscreenerror'
            }
        },
        moz: {
            enabled: 'mozFullScreenEnabled',
            element: 'mozFullScreenElement',
            request: 'mozRequestFullScreen',
            exit: 'mozCancelFullScreen',
            events: {
                change: 'mozfullscreenchange',
                error: 'mozfullscreenerror'
            }
        },
        ms: {
            enabled: 'msFullscreenEnabled',
            element: 'msFullscreenElement',
            request: 'msRequestFullscreen',
            exit: 'msExitFullscreen',
            events: {
                change: 'MSFullscreenChange',
                error: 'MSFullscreenError'
            }
        }
    };
    private readonly api: FullscreenApi;
    private readonly changeObservable: Observable<boolean>;

    constructor(
        @Inject('Document') private document: Document
    ) {
        for (const vendor in this.apis) {
            if (this.apis[vendor].enabled in this.document) {
                this.api = this.apis[vendor];
                break;
            }
        }

        this.changeObservable = (this.api)
            ? fromEvent(this.document, this.api.events.change)
                .pipe(

                    // we need to add zero delay because of safari closing event issue
                    // safari triggers closing event before the end of updating the layout
                    // and that may trigger some layout issues
                    delay(0),
                    map(() => {
                        return !!this.document[this.api.element];
                    })
                )
            : EMPTY;
    }

    getFullScreenApi(): FullscreenApi {
        return this.api;
    }

    onChange(): Observable<boolean> {
        return this.changeObservable;
    }
}
