import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FirebaseService {

    constructor(private db: AngularFireDatabase) { }

    get pushId(): string {
        return this.db.createPushId();
    }

    object<T>(path: string): Observable<T> {
        return this.db.object<T>(path).valueChanges();
    }

    objectWithIds<T>(path: string): Observable<T> {
        return this.db.object<T>(path).snapshotChanges().pipe(map(snapshot => {
            return ({
                ...<T>snapshot.payload.val(),
                id: snapshot.key
            });
        }));
    }

    list<T>(path: string): Observable<T[]> {
        return this.db.list<T>(path).valueChanges();
    }

    listWithIds<T>(path: string, filterField?: string, filterValue?: any): Observable<T[]> {
        const filter = !!filterField ? ref => ref.orderByChild(filterField).equalTo(filterValue) : null;
        return this.db.list<T>(path, filter).snapshotChanges().pipe(map(snapshots => {
            return snapshots.map(snapshot => ({
                ...<T>snapshot.payload.val(),
                id: snapshot.key
            }));
        }));
    }

    update<T>(path: string, data: T): Promise<void> {
        return this.db.object<T>(path).update(data);
    }

    multiPathUpdate(updates: any): Promise<void> {
        return this.db.object('/').update(updates);
    }
}
