import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseAppService } from '@app/core/services/app.service';

@Injectable()
export class AppGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticatedUserService: AuthenticatedUserService,
        private appService: FirebaseAppService
    ) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const appKey = next.params.appKey || '';
        return this.verifyAppUser(appKey);
    }

    private verifyAppUser(appKey: string): Observable<boolean> {
        return this.appService.isAppUser(this.authenticatedUserService.getCurrentUserId(), appKey).pipe(
            map((result) => {
                if (result) {
                    return true;
                }

                this.router.navigate(['/navigation']);
                return false;
            }));
    }
}
