import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { Observable } from 'rxjs';

import { trackByKey } from '@app/core/utils';
import { WorkbookService } from '@app/core/services/workbook.service';
import { Workbook } from '@app/core/models/workbook.model';

@Component({
    selector: 'app-workbooks-export-dialog',
    templateUrl: './workbooks-export-dialog.component.html',
    styleUrls: ['./workbooks-export-dialog.component.scss']
})
export class WorkbooksExportDialogComponent implements OnInit {
    workbooks$: Observable<Workbook[]>;
    exportedWorkbook: FormGroup;
    trackByKey = trackByKey;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<WorkbooksExportDialogComponent>,
        private workbookService: WorkbookService
    ) {
        const formControls = this.data.workbookIds.reduce((formControlsObj, workbookKey) => {
            formControlsObj[workbookKey] = new FormControl(false);
            return formControlsObj;
        }, {});
        this.exportedWorkbook = new FormGroup(formControls, {validators: this.notCheckedValidator});
    }

    handleConfirm(): void {
        const selectedWorkbookKeys = Object.keys(this.exportedWorkbook.value || {}).filter((key) => this.exportedWorkbook.value[key]);
        this.data.handleConfirm(selectedWorkbookKeys);
        this.dialogRef.close();
    }

    ngOnInit() {
        this.workbooks$ = this.workbookService.getWorkbookListAscByKeys(this.exportedWorkbook.value);
    }

    private notCheckedValidator(control: FormGroup): (ValidationErrors | null) {
        const hasChecked = !!Object.keys(control.value || {}).find((workbookKey: string) => control.value[workbookKey]);
        return (!hasChecked && {noChecked: true}) || null;
    }

}
