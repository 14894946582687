import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

import { FirestoreService } from './firestore.service';
import { ExportVersion } from '../models/export-version.model';

@Injectable({
    providedIn: 'root'
})
export class RepositoryService {

    constructor(
        private firestoreService: FirestoreService
    ) { }

    getExportVersions(): Observable<ExportVersion[]> {
        return this.firestoreService.getCollection<ExportVersion>('/exportVersions');
    }

    async getProjectExportLatestVersion(originId: string, projectId: string): Promise<ExportVersion> {
        const versions = await this.firestoreService
            .getDocumentsByProperty<ExportVersion>('exportVersions', 'origin_id', originId)
            .pipe(take(1)).toPromise();
        const projectVersions = versions
            .filter(version => version.project_id === projectId)
            .sort((v1, v2) => v1.version - v2.version);
        const maxVersion = projectVersions.pop();
        return maxVersion;
    }

    getProjectExportVersions(originId: string, projectId: string): Observable<ExportVersion[]> {
        return this.firestoreService
            .getDocumentsByProperty<ExportVersion>('exportVersions', 'origin_id', originId)
            .pipe(
                map(versions => versions
                    .filter(version => version.project_id === projectId)
                    .sort((v1, v2) => v1.version - v2.version))
            );
    }

    getProjectLatestVersion(originId: string, projectId: string): Observable<number> {
        return this.firestoreService
            .getDocumentByKey<ExportVersion>('versionsData', projectId)
            .pipe(map((document: any) => {
                return document && document.latestVersions && document.latestVersions[originId] || 0;
            }));
    }

    async deleteExportVersion(key: string): Promise<void> {
        await this.firestoreService.delete(`exportVersions/${key}`);
    }

    updateExportName(key: string, newName: string): Promise<void> {
        return this.firestoreService.update(`exportVersions/${key}`, { name: newName });
    }
}
