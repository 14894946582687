<mat-form-field appearance="outline" class="simple-content no-label form">
  <input
    matInput
    type="text"
    placeholder="Start typing tag name"
    [formControl]="autocompleteControl"
    autocomplete="off"
    class="options-input"
  />
  <mat-icon class="all-options-icon" (click)="openOptionsPanel.emit()">
    {{ "sell" }}
  </mat-icon>
  <mat-error *ngIf="autocompleteControl.hasError('maxlength')">
    {{ validationMessages.maxCharactersLength("tag", 255) }}
  </mat-error>
</mat-form-field>
<div class="autocomplete-wrapper">
  <ul
    [ngClass]="{
      active: !!optionArray?.length,
      disabled: noMatchesFound
    }"
    class="autocomplete-panel"
  >
    <li *ngIf="noMatchesFound" class="option disabled">
      {{ "No matches found" }}
    </li>

    <li
      *ngFor="let option of filteredOptions$ | async; trackBy: trackByIndex"
      class="option"
      (click)="onClickByOption(option)"
    >
      {{ option.name }}
    </li>
  </ul>
</div>
