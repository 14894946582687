import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    Output,
    SimpleChanges,
    ViewChild
} from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { ENTER } from '@angular/cdk/keycodes';

import { ObjectType, User, UserRole } from '../../../models';
import { trackById } from '@thinktank/common-lib';

// eslint-disable-next-line max-len
const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// email validator
export function emailValidator(control: FormControl): { invalidEmail: boolean } | null {
    const value = control.value && (control.value || '').toString().trim();

    if (value && !EMAIL_REGEX.test(value)) {
        return { invalidEmail: true };
    } else {
        return null;
    }
}

@Component({
    selector: 'gs-invite-form',
    templateUrl: './invite-form.component.html',
    styleUrls: ['./invite-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class InviteFormComponent implements OnChanges {
    @Input() type: ObjectType;
    @Input() users: any; // should contain roles and email_address
    @Input() demographicsLength: boolean;
    @Input() activeDemographicList: string[];
    @Input() deploymentUserList: User[]; // roster of users associated to the deployment
    @Input() isGlobal?: boolean;
    @Output() onInvite: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
    @ViewChild('ownersInput', { static: true }) ownersInput: ElementRef<HTMLInputElement>;
    @ViewChild('stakeholdersInput', { static: true }) stakeholdersInput: ElementRef<HTMLInputElement>;

    owners: { email_address: string }[];
    stakeholders: { email_address: string }[];

    emailList: { email_address: string, role: UserRole, demographic_id?: string }[];
    emailExist: { owners: boolean, stakeholders?: boolean };
    emailEnter: { owners: boolean, stakeholders?: boolean };

    filteredUsers: any;
    trackById = trackById;

    rolesMapping: any;

    emailsErrors: {
        invalidEmails: any[],
        emailsAlreadyExist: {
            owners: any[],
            stakeholders?: any[]
        },
        emailsAlreadyExistInSession: {
            owners: any[],
            stakeholders?: any[]
        }
    };

    emailsErrorMessages: {
        owners: string,
        stakeholders: string
    };

    separatorKeysCodes = [ENTER];
    inviteForm: FormGroup;
    UserRole = UserRole;

    constructor(
        private location: Location
    ) {
        this.emailList = [];
        this.emailExist = { owners: false, stakeholders: false };
        this.emailEnter = { owners: false, stakeholders: false };
        this.resetEmailsErrors();
        this.initForm();
    }

    get assignDisabled(): boolean {
        const disableByDemographics = this.isGlobal ? false : !this.demographicsLength;

        return disableByDemographics
            || !!this.emailsErrorMessages['owners']
            || !!this.emailsErrorMessages['stakeholders']
            || (!this.owners.length && !this.stakeholders.length);
    }

    get showSMERole(): boolean {
        return !!this.type && this.type === ObjectType.Subprocess;
    }

    get isGlobalSubprocess(): boolean {
        return !!this.type && this.type === ObjectType.Subprocess && this.isGlobal;
    }

    getPlaceholder(list: { email_address: string }[], text: string): string {
        return !!list.length ? '' : text;
    }

    filterUsers(event: any): void {
        const filterValue = event.target.value.toLowerCase();

        this.filteredUsers =
            (this.deploymentUserList || []).filter((user: User) => {
                return user.email_address.toLowerCase().indexOf(filterValue) !== -1
                    || (user.first_name || '').toLowerCase().indexOf(filterValue) !== -1
                    || (user.last_name || '').toLowerCase().indexOf(filterValue) !== -1;
            });
    }

    assignUsers(): void {
        this.onInvite.emit(this.emailList);
        this.clear();
    }

    resetForm(): void {
        this.clear();
        this.location.back();
    }

    selected(event: MatAutocompleteSelectedEvent, role: UserRole): void {
        this.addEmail(event, role, true);

        // reset filters
        this.filteredUsers = this.deploymentUserList;
    }

    addEmail(event: any, role: UserRole, auto?: boolean): void {
        const nativeInput = role === 'owner'
            ? this.ownersInput.nativeElement
            : this.stakeholdersInput.nativeElement;

        const input = auto ? nativeInput : event.input;
        let value = auto
            ? (event.option.value || '').trim()
            : (event.value || '').trim();

        const control: AbstractControl = this.inviteForm.controls[`${role}s`];

        if (value) {
            // Remove all commas and empty spaces
            // Lowercase force
            value = value.replace(/,/g, ' ').replace(/(\s+)/g, ' ');
            value = value.toLowerCase();
            input.value = value;
            const emailValues = value.split(' ');

            this.emailsErrorMessages[`${role}s`] = '';

            emailValues.forEach((emailValue) => {
                emailValue = emailValue.trim();

                // check if user already exist
                this.emailExist[`${role}s`] = (this.users || []).some((entry: any) => {
                    const demographicCheck = (this.isGlobal || this.type === ObjectType.Module)
                        ? true
                        : this.activeDemographicList.includes(entry.demographic_id);

                    if (emailValue === entry.email_address &&
                        role === entry.role &&
                        demographicCheck
                    ) {
                        this.emailsErrors.emailsAlreadyExist[`${role}s`].push(emailValue);
                    }

                    return emailValue === entry.email_address && role === entry.role && demographicCheck;
                });

                // check if user already exist in current session
                this.emailEnter[`${role}s`] = this.emailList.some((entry: any) => {
                    const demographicCheck = this.isGlobal ? true : this.activeDemographicList.includes(entry.demographic_id);

                    if (emailValue === entry.email_address &&
                        role === entry.role &&
                        demographicCheck
                    ) {
                        this.emailsErrors.emailsAlreadyExistInSession[`${role}s`].push(emailValue);
                    }

                    return emailValue === entry.email_address && role === entry.role && demographicCheck;
                });

                // check email
                const invalidEmail = !emailValue.match(EMAIL_REGEX);
                if (invalidEmail) {
                    // check to see if the value is a user's name vs an email before saying error
                    const foundUser = this.deploymentUserList.find((user) => {
                        const fullName = `${user.first_name} ${user.last_name}`;
                        return fullName.includes(emailValue);
                    });

                    if (!foundUser) {
                        this.emailsErrors.invalidEmails.push(emailValue);
                    }
                }
                control.setErrors({ invalidEmail });

                const allowSetEmail = !this.emailExist[`${role}s`]
                    && !this.emailEnter[`${role}s`]
                    && !control.hasError('invalidEmail');

                if (allowSetEmail) {
                    // push to the chips list
                    this.rolesMapping[role].push({
                        email_address: emailValue
                    });

                    // push to the main list
                    this.pushToEmailList(emailValue, role);
                    input.value = input.value
                        .replace(emailValue, '')
                        .replace(/,/g, ' ')
                        .replace(/(\s+)/g, ' ');
                }
            });

            const invalidEmails = this.emailsErrors.invalidEmails;
            const emailsExist = this.emailsErrors.emailsAlreadyExist[`${role}s`];
            const emailsExistInSession = this.emailsErrors.emailsAlreadyExistInSession[`${role}s`];

            let messageText: string;

            const errorsGroup = {
                invalidEmails: {
                    errorsPackage: invalidEmails,
                    errorsName: 'invalidEmails'
                },
                emailsExist: {
                    errorsPackage: emailsExist,
                    errorsName: 'emailsExist'
                },
                emailsExistInSession: {
                    errorsPackage: emailsExistInSession,
                    errorsName: 'emailsExistInSession'
                }
            };

            const filteredGroups = Object.keys(errorsGroup).filter((key) => {
                return errorsGroup[key].errorsPackage.length > 0;
            });

            const matchGroup = filteredGroups.length > 1 ? 'multiple' : filteredGroups[0];
            switch (matchGroup) {
                case errorsGroup.invalidEmails.errorsName:
                    messageText = invalidEmails.length > 1
                        ? 'Please enter valid email addresses'
                        : 'Please enter a valid email address';

                    this.showMessageError(control, `${role}s`, true, false, false, messageText);
                    break;
                case errorsGroup.emailsExist.errorsName:
                    messageText = emailsExist.length > 1
                        ? 'Users with such email address are already exist'
                        : 'User with this email address is already exist';

                    this.showMessageError(control, `${role}s`, false, true, false, messageText);
                    break;
                case errorsGroup.emailsExistInSession.errorsName:
                    messageText = emailsExistInSession.length > 1
                        ? 'Users with such email address are already invited with other roles'
                        : 'User with this email address is already invited with another role';

                    this.showMessageError(control, `${role}s`, false, false, true, messageText);
                    break;
                case 'multiple':
                    messageText = 'Please enter valid email addresses';

                    this.showMessageError(control, `${role}s`, true, false, false, messageText);
                    break;
            }

            this.resetEmailsErrors();
        }
    }

    removeEmail(value: any, role: string): void {
        // remove from main list
        this.emailList = this.emailList.filter(obj => obj.email_address !== value.email_address);

        // remove from from chips list
        const index = this.rolesMapping[role].indexOf(value);
        if (index >= 0) {
            this.rolesMapping[role].splice(index, 1);
        }
    }

    pushToEmailList(emailValue: any, role: UserRole): void {
        this.emailList.push({
            'email_address': emailValue,
            'role': role
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.users && !!this.users) {
            this.initForm();
        }

        if (changes.deploymentUserList && !!this.deploymentUserList) {
            this.filteredUsers = this.deploymentUserList;
        }
    }

    private clear(): void {
        this.clearEmailErrors();
        this.resetEmailsErrors();
        this.owners.splice(0, this.owners.length);
        this.stakeholders.splice(0, this.stakeholders.length);
        this.emailList = [];
        this.inviteForm.reset();
        this.inviteForm.markAsTouched();

        let control: AbstractControl = null;
        Object.keys(this.inviteForm.controls).forEach((name) => {
            control = this.inviteForm.controls[name];
            control.markAsTouched();
            control.markAsPristine();
            control.setErrors(null);
        });
    }

    private initForm(): void {
        this.inviteForm = new FormGroup({
            owners: new FormControl(
                this.owners,
                Validators.compose([emailValidator])
            ),
            stakeholders: new FormControl(
                this.stakeholders,
                Validators.compose([emailValidator])
            )
        });

        this.owners = [];
        this.stakeholders = [];

        this.emailsErrorMessages = {
            owners: '',
            stakeholders: ''
        };

        this.rolesMapping = {
            owner: this.owners,
            stakeholder: this.stakeholders
        };

        this.resetEmailsErrors();
        this.clearEmailErrors();
    }

    private clearEmailErrors(): void {
        this.emailExist = { owners: false, stakeholders: false };
        this.emailEnter = { owners: false, stakeholders: false };
    }

    private resetEmailsErrors(): void {
        this.emailsErrors = {
            invalidEmails: [],
            emailsAlreadyExist: {
                owners: [],
                stakeholders: []
            },
            emailsAlreadyExistInSession: {
                owners: [],
                stakeholders: []
            }
        };
    }

    private showMessageError(
        control: AbstractControl,
        controlName: string,
        isEmailInvalid: boolean,
        isEmailExist: boolean,
        isEmailExistInSession: boolean,
        text: string
    ): void {
        control.setErrors({ invalidEmail: isEmailInvalid });
        this.emailExist[controlName] = isEmailExist;
        this.emailsErrorMessages[controlName] = text;
        this.emailEnter[controlName] = isEmailExistInSession;
    }
}
