import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { CreateDeploymentView, Deployment, Module, UserRole } from '../../models';
import { Demographic } from '@thinktank/common-lib';

export interface CreateDeploymentFacade {
    vm$: Observable<CreateDeploymentView>;

    getNewDeploymentId(): string;

    createDeployment(
        clientName: string,
        selectedDeployment: Deployment,
        selectedModules: Module[],
        deploymentRoster: any,
        selectedDemographics: Demographic[],
        selectedOverlayTemplateId: string,
        currentUserId: string
    ): Promise<string>;

    addUser(email: string, deploymentId: string, role?: UserRole): Promise<string>;

    viewOverlayTemplate(overlayTemplateId): void;
    // {
        // this.dialogService.open('ConfirmActionDialogComponent', {
        //     text: 'Viewing an overlay template will close the create deployment process, do you wish to continue?',
        //     confirmBtnText: 'View Overlay Template',
        //     cancelBtnText: 'Cancel',
        //     handleConfirm: (selectedDemographicKeys: string[]) => {
        //         this.closeCreateDeployment.emit();
        //         this.routerService.navigateTo([`overlay_templates/${overlayTemplateId}`]);
        //     }
        // });
    // }

    createEditOverlayTemplate(core_template_id: string, overlayTemplateId?: string): void;
    // {
        // const isEdit = !!overlayTemplateId;
        // const text = isEdit
        //   ? 'Editing an overlay template will close the create deployment process, do you wish to continue?'
        //   : 'Creating an overlay template will close the create deployment process, do you wish to continue?';
        // const confirmBtnText = isEdit ? 'Edit Overlay Template' : 'Create Overlay Template';
        //
        // this.dialogService.open('ConfirmActionDialogComponent', {
        //     text,
        //     confirmBtnText,
        //     cancelBtnText: 'Cancel',
        //     handleConfirm: () => {
        //         this.closeCreateDeployment.emit();
        //         let templateId = overlayTemplateId;
        //         if (!overlayTemplateId) {
        //             templateId = await this.overlayTemplateService.initializeNewTemplate(core_template_id).pipe(take(1)).toPromise();
        //         }
        //         return this.routerService.navigateTo([`overlay-templates/${templateId}/edit`]);
        //     }
        // });
    // }

    deleteOverlayTemplate(overlayTemplateId: string): void;
}

export const CreateDeploymentFacadeToken = new InjectionToken<CreateDeploymentFacade>('CreateDeploymentFacade');
