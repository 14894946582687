import { BaseStateModel } from '@app/root-store/models/base-state.model';
import { Demographic, ActiveDemographics } from '@app/core/models';

export interface DemographicsState extends BaseStateModel {
    demographicsByClassKey: { [classKey: string]: Demographic[] };
    selectedDemographicsByClassKey: { [classAndUserKeys: string]: Demographic[] };
    formActiveDemographicsKeys: { [classUserFormKeys: string]: string | string[] }; // ${classKey}_${userKey}_${formKey}
    formDemographics: { [classUserFormKeys: string]: Demographic[] }; // ${classKey}_${userKey}_${formKey}
    formDemographicsClassId: { [formKey: string]: string };
    parentFormActiveDemographics: { [formKey: string]: ActiveDemographics };
}

export const initialState: DemographicsState = {
    loading: false,
    errorMessage: null,
    demographicsByClassKey: {},
    selectedDemographicsByClassKey: {},
    formDemographics: {},
    formActiveDemographicsKeys: {},
    formDemographicsClassId: {},
    parentFormActiveDemographics: {}
};
