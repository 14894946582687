import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RoutingService } from '@app/apps/core/services/routing.service';

import {
    OverlappedQuestionsByActivity,
    OverlappedQuestionsResponse
} from '@app/core/models/workbook.model';
import { trackByKey, preventKeyValueOrder } from '@app/core/utils';

@Component({
    selector: 'app-overlapped-questions-dialog',
    templateUrl: './overlapped-questions-dialog.component.html',
    styleUrls: ['./overlapped-questions-dialog.component.scss']
})
export class OverlappedQuestionsDialogComponent implements OnInit {
    overlappedQuestionsResponse: OverlappedQuestionsResponse;
    currentQuestionMapping: string;
    overlappedActivities: OverlappedQuestionsByActivity;
    trackByKey = trackByKey;
    preventKeyValueOrder = preventKeyValueOrder;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<OverlappedQuestionsDialogComponent>,
        private routingService: RoutingService
    ) {
        this.getOverlappedQuestionsResponse();
    }

    handleConfirm(): void {
        this.dialogRef.close();
    }

    redirectToForm(activityKey: string): void {
        this.routingService.navigateToFormEditNewWindow(activityKey, 'form');
    }

    async getOverlappedQuestionsResponse(): Promise<void> {
        const { currentQuestionMapping, overlappedQuestionsResponse } = this.data;
        this.currentQuestionMapping = currentQuestionMapping;

        try {
            this.overlappedQuestionsResponse = await overlappedQuestionsResponse;
            this.overlappedActivities = this.overlappedQuestionsResponse && this.overlappedQuestionsResponse.overlappedQuestions;
        } catch (e) {
            console.log('error while getting overlappedQuestionsResponseData: ', e);
        }
    }

    ngOnInit() {

    }

}
