<app-tasks-list
  [isApp]="isApp"
  [isLeader]="isLeader"
  [projectKey]="projectKey"
  [list]="tasksAndGates$ | async"
  [emulate]="emulate"
  [isHome]="isHome"
  [resetEmulation]="resetEmulation$ | async"
  [answeredQuestions]="answeredQuestions$ | async"
  [demographicStatusesMap]="demographicStatusMapByTaskKey$ | async"
></app-tasks-list>
