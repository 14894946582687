import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './material.module';
import { ConfirmActionDialogComponent } from './shared/dialogs/confirm-action-dialog/confirm-action-dialog.component';
import { ResponseHistoryCardComponent } from './shared/response-history-card/response-history-card.component';
import { ResponseHistoryCardContainerComponent } from './shared/response-history-card/response-history-card-container.component';
import { ResponseHistoryService } from './services/response-history.service';
import { DialogService } from './services/dialog.service';
import { TimestampToDatePipe } from './shared/pipes/timestamp-to-date.pipe';
import { FormStatusPipe } from './shared/pipes/form-status.pipe';
import { IncludesPipe } from './shared/pipes/includes.pipe';
import { IncludesByFieldPipe } from './shared/pipes/includes-by-field.pipe';
import { ShortNamePipe } from './shared/pipes/short-name.pipe';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { PercentagePipe } from './shared/pipes/percentage.pipe';
import { InitialFocusDirective } from './shared/directives/initial-focus.directive';
import { EveryPipe } from './shared/pipes/every.pipe';
import { LengthPipe } from './shared/pipes/length.pipe';
import { ObjectKeysPipe } from './shared/pipes/object-keys.pipe';
import { ObjectValuesPipe } from './shared/pipes/object-values.pipe';

@NgModule({
    declarations: [
        // components
        ResponseHistoryCardComponent,
        ResponseHistoryCardContainerComponent,
        ConfirmActionDialogComponent,
        SpinnerComponent,
        // pipes
        TimestampToDatePipe,
        FormStatusPipe,
        IncludesPipe,
        PercentagePipe,
        IncludesByFieldPipe,
        ShortNamePipe,
        EveryPipe,
        LengthPipe,
        ObjectKeysPipe,
        ObjectValuesPipe,
        // directives
        InitialFocusDirective
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule
    ],
    exports: [
        // components
        ResponseHistoryCardComponent,
        ResponseHistoryCardContainerComponent,
        SpinnerComponent,
        // pipes
        TimestampToDatePipe,
        FormStatusPipe,
        IncludesPipe,
        PercentagePipe,
        IncludesByFieldPipe,
        ShortNamePipe,
        EveryPipe,
        LengthPipe,
        ObjectKeysPipe,
        ObjectValuesPipe,
        // directives
        InitialFocusDirective
    ],
    providers: [
        ResponseHistoryService,
        DialogService
    ]
})

export class CommonLibModule { }
