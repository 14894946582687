import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackgroundImagesDirective } from './background-images.directive';
import { UserLoginComponent } from './user-login/user-login.component';
import { UserRegisterComponent } from './user-register/user-register.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LoginHeaderComponent } from './login-header/login-header.component';
import { ThinkTankRoutingModule } from '../app-routing.module';
import { MaterialModule } from '@thinktank/common-lib';
import { SsoCallbackComponent } from './sso-callback/sso-callback.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

@NgModule({
    declarations: [
        UserLoginComponent,
        UserRegisterComponent,
        LoginHeaderComponent,
        SsoCallbackComponent,
        ForgotPasswordComponent,
        BackgroundImagesDirective,
    ],
    imports: [CommonModule, FlexLayoutModule, FormsModule, ReactiveFormsModule, MaterialModule, ThinkTankRoutingModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SignonModule {}
