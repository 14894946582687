<div class="gs-dialog volunteer-dialog">
  <h6 class="h6 gs-dialog-title">Would you like to Volunteer? <mat-icon class="close" (click)="handleCancel()">close</mat-icon></h6>
  <mat-dialog-content class="gs-dialog-content">
    <div #content class="scroll-content">
      <ng-container *ngIf="data.isGlobal">
        <div class="text-regular">
          Please confirm that you would like to be a Primary SME of the
          <span class="bold">{{ !!data.subprocess && data.subprocess.name }}</span> Sub Process.
        </div>
      </ng-container>

      <ng-container *ngIf="!data.isGlobal">
        <div class="text-regular">
          Please select countries to be a primary SME for
          <span class="bold">{{ !!data.subprocess && data.subprocess.name }}</span> Sub Process:
        </div>
        <div class="demographics">
          <div *ngIf="selectedDemographics.length > 0" class="selected-demographics">
            <p class="caption-gray">Selected countries</p>
            <div class="chip-list">
              <ng-container *ngFor="let selected of selectedDemographics; trackBy: trackByValue">
                <div class="demographics-list-item gs-demographic-chip-selected">
                  <div class="demographics-chip-icon">
                    <mat-icon class="gs-demographic-chip-icon-done">done</mat-icon>
                  </div>
                  <span class="gs-demographic-chip-label">
                    {{ getDemographicName(selected) }}
                  </span>
                  <mat-icon class="gs-demographic-chip-icon-clear" (click)="removeDemographic(selected)">clear</mat-icon>
                </div>
              </ng-container>
            </div>
          </div>
          <p class="caption-gray">{{ selectedDemographics.length > 0 ? 'Available countries' : 'Select countries' }}</p>
          <div class="demographics-list">
            <ng-container *ngFor="let demographic of data.demographics; trackBy: trackById">
              <mat-checkbox
                color="primary"
                class="gs-demographic-checkbox demographics-option"
                [value]="demographic.id"
                [checked]="isChecked(demographic.id)"
                (change)="selectDemographic($event, demographic.id)"
              >
                {{ demographic.name }}
              </mat-checkbox>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions class="gs-dialog-actions" [ngClass]="{'border': isScrollContent}">
    <button
      class="gs-button-filled"
      [disabled]="!enableConfirm"
      (click)="handleConfirm()"
    >
      {{ data.confirmBtnText }}
    </button>
    <button
      class="gs-button-ghost"
      (click)="handleCancel()"
    >
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
