export class Attachment {
    date_created: number;
    owner: string;
    name: string;
    download_url: string;
    type?: string;
    file_type?: string;
    key?: string;
    sub_key?: string;
    response_id?: string;
    question_id?: string;
    form_id?: string;
    demographic_id?: string;
}

export class ImageAttachment {
    url: string;
}

export const testImageAttachment = <ImageAttachment>{
    url: 'image_url'
};

export const testAttachment = <Attachment>{
    date_created: 1510686478630,
    owner: 'user1',
    name: 'FileName.jpeg',
    download_url: 'some_url',
    type: 'response',
    file_type: 'jpeg',
    key: 'attachment1'
};
