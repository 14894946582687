import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { map, takeUntil, take, debounceTime, delay, filter } from 'rxjs/operators';
import { isNull } from 'lodash';

import { FormComponentsService, FirebaseUsersService } from '@app/core/services';
import {
    FormQuestion,
    Demographic,
    FormBranchingVisibility,
    User
} from '@app/core/models';
import { FormDemographicsService } from './form-demographics.service';
import { FormBranchingService } from './form-branching.service';

@Injectable()
export class FormStoreService {
    public allLastQuestions: Subject<FormQuestion[]> = new Subject();

    private _usersMap: { [userInfoKey: string]: any } = {};

    private _questionsMap: Map<string, BehaviorSubject<FormQuestion[]>> = new Map();
    private _demographicsMap: Map<string, BehaviorSubject<Demographic[]>> = new Map();
    private _branchingVisibilityMap: Map<string, BehaviorSubject<{ [questionKey: string]: FormBranchingVisibility }>> = new Map();
    private _branchingDataMap: Map<string, BehaviorSubject<{ [questionKey: string]: FormQuestion[] }>> = new Map();

    private _questionsDestroySubject = new Subject<void>();
    private _demographicsDestroySubject = new Subject<void>();
    private _branchingVisibilityDestroySubject = new Subject<void>();
    private _branchingDataDestroySubject = new Subject<void>();

    constructor(
        private formComponentsService: FormComponentsService,
        private formDemographicsService: FormDemographicsService,
        private formBranchingService: FormBranchingService,
        private usersService: FirebaseUsersService
    ) { }

    getQuestions(formKey: string): BehaviorSubject<FormQuestion[]> {
        if (!this._questionsMap.has(formKey)) {
            const questions$ = new BehaviorSubject<FormQuestion[]>(null);

            this.formComponentsService.getFormQuestionsByFormKey(formKey)
                .pipe(
                    delay(0),
                    debounceTime(100),
                    takeUntil(this._questionsDestroySubject)
                )
                .subscribe(data => { data && questions$.next(data); });

            this._questionsMap.set(
                formKey,
                questions$
            );
        }

        return this._questionsMap.get(formKey);
    }

    getQuestion(formKey: string, questionKey: string): Observable<FormQuestion> {
        return this.getQuestions(formKey)
            .pipe(map(questions => (questions || []).find(question => question.key === questionKey)));
    }

    getDemographics(formKey: string, classKey: string): BehaviorSubject<Demographic[]> {
        if (!this._demographicsMap.has(formKey)) {
            const demographics$ = new BehaviorSubject<Demographic[]>(null);

            this.formDemographicsService.getFormDemographics(formKey, classKey)
                .pipe(
                    delay(0),
                    debounceTime(100),
                    takeUntil(this._demographicsDestroySubject)
                )
                .subscribe(data => { data && demographics$.next(data); });

            this._demographicsMap.set(
                formKey,
                demographics$
            );
        }

        return this._demographicsMap.get(formKey);
    }

    getActiveDemographics(formKey: string, classKey: string): Observable<Demographic[]> {
        return this.getDemographics(formKey, classKey)
            .pipe(map(demographics => (demographics || []).filter(demographic => demographic.active)));
    }

    getFormBranchingVisibility(activityKey: string): BehaviorSubject<{ [questionKey: string]: FormBranchingVisibility }> {
        if (!this._branchingVisibilityMap.has(activityKey)) {
            const branchingVisibility$ = new BehaviorSubject<{ [questionKey: string]: FormBranchingVisibility }>(null);

            this.formBranchingService.getFormQuestionsVisibility(activityKey)
                .pipe(
                    delay(0),
                    debounceTime(100),
                    takeUntil(this._branchingVisibilityDestroySubject)
                )
                .subscribe(data => data && branchingVisibility$.next(data));

            this._branchingVisibilityMap.set(
                activityKey,
                branchingVisibility$
            );
        }

        return this._branchingVisibilityMap.get(activityKey);
    }

    getQuestionToFormMap(formKey: string): Observable<{ [questionKey: string]: string }> {
        return this.formComponentsService.getFormQuestionsByFormKey(formKey)
            .pipe(map(questions => questions
                .reduce((accumulator, question) => {
                    if (question.response_type === 'subform') {
                        accumulator[question.key] = question.subform_id;
                    }
                    return accumulator;
                }, {})));
    }

    async getQuestionsHasBranching(formId: string, questionId: string): Promise<boolean> {

        if (!this._branchingDataMap.has(formId)) {
            const branchingDependencies$ = new BehaviorSubject<{ [questionKey: string]: FormQuestion[] }>(null);

            this.getQuestions(formId)
                .pipe(
                    delay(0),
                    debounceTime(100),
                    takeUntil(this._branchingDataDestroySubject)
                )
                .subscribe(questionsData => {
                    const branchingDependencies = this.formBranchingService.buildBranchingDependencies(questionsData);
                    return branchingDependencies && branchingDependencies$.next(branchingDependencies);
                });

            this._branchingDataMap.set(
                formId,
                branchingDependencies$
            );
        }

        return this._branchingDataMap.get(formId)
            .asObservable()
            .pipe(
                filter(value => !isNull(value)),
                map(branchingData => !!branchingData && !!branchingData[questionId]),
                take(1)
            )
            .toPromise();
    }

    getQuestionBranchingVisibility(formKey: string, questionKey: string): Observable<FormBranchingVisibility> {
        return this.getFormBranchingVisibility(formKey)
            .pipe(map(visibility => visibility && visibility[questionKey]));
    }

    async getUsers(appKey: string): Promise<void> {
        this._usersMap = await this.usersService.getInstanceUsersMap(appKey).pipe(take(1)).toPromise();
    }

    async getUser(userKey: string, appKey: string): Promise<User> {
        if (!this._usersMap[userKey]) {
            await this.getUsers(appKey);
        }
        return this._usersMap[userKey];
    }

    clear(): void {
        this._questionsDestroySubject.next();
        this._branchingVisibilityDestroySubject.next();
        this._branchingDataDestroySubject.next();

        this._questionsMap.clear();
        this._branchingVisibilityMap.clear();
        this._branchingDataMap.clear();
    }

    clearDemographics(): void {
        this._demographicsDestroySubject.next();
        this._demographicsMap.clear();
    }
}

