import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-users-panel-list',
    templateUrl: './users-panel-list.component.html',
    styleUrls: ['./users-panel-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UsersPanelListComponent implements OnChanges {
    @Input() users: any;
    @Input() isStakeholder?: boolean;
    @Input() isModule?: boolean;
    @Input() isGlobal?: boolean;
    @Input() isEdit?: boolean;
    @Output() onUpdatePrimary?: EventEmitter<{primaryId: string, previousPrimaryId: string}>
        = new EventEmitter<{primaryId: string, previousPrimaryId: string}>();

    trackById = trackById;
    primaryId: string;
    selectedId: string;

    constructor() {}

    get showUsersList(): boolean {
        return !this.isStakeholder || (this.isStakeholder && !this.isEdit);
    }

    onChangeSelected(event: any): void {
        this.selectedId = event.value;

        this.onUpdatePrimary.emit({
            primaryId: this.selectedId,
            previousPrimaryId: this.primaryId
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.users && !!this.users) {
            const selectedItem = this.users.filter((item: any) => item.primary);

            if (selectedItem.length) {
                this.primaryId = selectedItem[0].key;
                this.selectedId = this.primaryId;
            }
        }
    }
}
