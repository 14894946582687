import { of, combineLatest, Observable } from 'rxjs';
import { map, flatMap, take } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';

import {
    ActivityStatus,
    ActivityTemplate,
    ActivityTab,
    SessionOptions,
    Activity,
    defaultSessionOptions,
    Group,
    Question,
    ActivityType,
} from '@app/core/models';
import { sortBySequenceAsc, createUniqueName } from '@app/core/utils';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { VoteReportingOptions, activityTypesDictionary } from '@app/core/models/activity.model';
import { IssueType, ImportType } from '@thinktank/common-lib';
import { ValidationErrorsType } from '../models/event-flow.model';

@Injectable()
export class FirebaseActivityService extends FirebaseUtilityService {
    constructor(
        private db: AngularFireDatabase,
        private afFun: AngularFireFunctions,
        public authUserService: AuthenticatedUserService,
    ) {
        super();
    }

    getActivityAlignmentEnabled(activityKey: string): Observable<boolean> {
        return this.db.object<boolean>(`/ssot/_activities/${activityKey}/alignment_enabled`).valueChanges();
    }

    getActivityFormId(activityKey: string): Observable<string> {
        return this.db.object<string>(`/ssot/_activities/${activityKey}/form_id`).valueChanges();
    }

    getActivityTitle(activityKey: string): Observable<string> {
        return this.db.object<string>(`/ssot/_activities/${activityKey}/activity_name`).valueChanges();
    }

    getActivityNameWithKey(activityKey: string): Observable<{ activity_name: string; activity_id: string }> {
        return this.db
            .object<string>(`/ssot/_activities/${activityKey}/activity_name`)
            .valueChanges()
            .pipe(
                map(activityName => {
                    return {
                        activity_name: activityName,
                        activity_id: activityKey,
                    };
                }),
            );
    }

    getActivity(key: string | any): Observable<Activity> {
        return this.objectWithKey(this.db.object<Activity>(`/ssot/activities/${key}`));
    }

    getActivitySsot(key: string | any): Observable<Activity> {
        return this.objectWithKey(this.db.object<Activity>(`/ssot/_activities/${key}`));
    }

    getActivityStatus(key: string): Observable<ActivityStatus> {
        return this.db.object<ActivityStatus>(`/ssot/_activities/${key}/status`).valueChanges();
    }

    getActivityAllowChangeResponse(key: string): Observable<boolean> {
        return this.db.object<boolean>(`/ssot/_activities/${key}/change_response`).valueChanges();
    }

    // old db used for backward compatibility
    getActivityVotes(key: string, userKey: string, questionKey: string): Observable<any> {
        return this.db.object<any>(`/activities/${key}/activity_votes/${questionKey}/${userKey}`).valueChanges();
    }

    getActivityDemographicsClassKey(key: string): Observable<string> {
        return this.db.object<string>(`/ssot/_activities/${key}/demographics_class_id`).valueChanges();
    }

    getActivitiesByAppInstanceId(id: string): Observable<Activity[]> {
        return this.listWithKeys(this.db.list('/ssot/activities', ref => ref.orderByChild('app').equalTo(id)));
    }

    getFormActivitiesByAppInstanceId(key: string): Observable<Activity[]> {
        return this.getActivitiesByAppInstanceId(key).pipe(
            map(activities => {
                const formActivities = activities.filter(activity => activity.activity_type === 'form');

                return sortBySequenceAsc(formActivities);
            }),
        );
    }

    getSsotActivitiesByApp(appKey: string): Observable<Activity[]> {
        return this.listWithKeys(
            this.db.list('/ssot/_activities', ref => ref.orderByChild('app_id').equalTo(appKey)),
        ).pipe(map(sortBySequenceAsc));
    }

    getActivityFieldValue(activityKey: string, activityField: string): Observable<any> {
        return this.db.object<any>(`/ssot/_activities/${activityKey}/${activityField}`).valueChanges();
    }

    getActivityQuestionsCount(activityKey: string): Observable<number> {
        return this.listWithKeys(
            this.db.list<Question>('/ssot/_questions', ref => ref.orderByChild('activity_id').equalTo(activityKey)),
        ).pipe(
            map((questions: Question[]) => {
                return questions.length;
            }),
        );
    }

    updateMoveAllToJiraFlag(appKey: string, activityKey: string, value: number): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/move_to_jira`] = value;
        updates[`ssot/_activities/${activityKey}/move_to_jira`] = value;

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    updateActivityName(activityKey: string, appKey: string, name: string): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/activity_name`] = name;
        updates[`ssot/_activities/${activityKey}/activity_name`] = name;

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    updateMultiplyActivityNames(activitiesNames: any[], appKey: string): Promise<void> {
        const updates = {};

        activitiesNames.forEach(activity => {
            updates[`activities/${activity.key}/activity_name`] = activity.activity_name;
            updates[`ssot/_activities/${activity.key}/activity_name`] = activity.activity_name;

            updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
            updates[`apps/${appKey}/date_last_updated`] = Date.now();
        });

        return this.db.object('/').update(updates);
    }

    async triggerSlidesNamesUpdate(activitiesKeys: string[]): Promise<void> {
        const updates = {};

        activitiesKeys.forEach(key => {
            updates[`ssot/_activities/${key}/update_slides_names`] = true;
        });

        try {
            await this.db.object('/').update(updates);
        } catch (e) {
            console.info('Not an error, please ignore');
        }
    }

    updateActivityDescription(activityKey: string, appKey: string, description: string): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/activity_description`] = description;
        updates[`ssot/_activities/${activityKey}/activity_description`] = description;

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    updateActivityDuration(activityKey: string, appKey: string, duration: string): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/duration`] = duration;
        updates[`ssot/_activities/${activityKey}/duration`] = duration;

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    updateActivityVisibility(activityKey: string, appKey: string, visible: boolean): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/visible`] = visible;
        updates[`ssot/_activities/${activityKey}/visible`] = visible;

        return this.db.object('/').update(updates);
    }

    updateActivityCanDownloadPresentation(appKey: string, activityKey: string, flag: boolean): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/canDownloadPresentation`] = flag;
        updates[`ssot/_activities/${activityKey}/can_download_presentation`] = flag;

        return this.db.object('/').update(updates);
    }

    updateActivityApplyGroups(
        activityKey: string,
        appKey: string,
        applyGroups: boolean,
        remove: boolean,
    ): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/apply_groups`] = applyGroups;
        updates[`ssot/_activities/${activityKey}/apply_groups`] = applyGroups;

        if (remove) {
            updates[`activities/${activityKey}/questions`] = null;
            updates[`activities/${activityKey}/groups`] = null;
            updates[`ssot/_activities/${activityKey}/questions`] = null;
            updates[`ssot/_activities/${activityKey}/groups`] = null;
        }

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    async updateActivityCrowdsourceGroupKey(activityKey: string, groupKey: string): Promise<void> {
        const updates = {};

        updates[`ssot/_activities/${activityKey}/crowdsource_group_key`] = groupKey;

        return this.db.object('/').update(updates);
    }

    getActivityApplyGroups(activityKey: string): Observable<boolean> {
        return this.db.object<boolean>(`/ssot/_activities/${activityKey}/apply_groups`).valueChanges();
    }

    getActivityVoteOnGroups(activityKey: string): Observable<boolean> {
        return this.db
            .object<boolean>(`ssot/_activity_settings/${activityKey}/session_options/vote_on_groups`)
            .valueChanges();
    }

    updateActivityField(activityKey: string, appKey: string, field: string, value: any): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/${field}`] = value;
        updates[`ssot/_activities/${activityKey}/${field}`] = value;

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    getActivitiesTemplates(): Observable<ActivityTemplate[]> {
        return this.listWithKeys(this.db.list('/activity_templates'));
    }

    getDeploymentsActivityTemplate(): Observable<ActivityTemplate> {
        return this.db.object<ActivityTemplate>('/activity_templates/deployments').valueChanges();
    }

    getTemplateStatus(type: string): Observable<boolean> {
        return this.db.object<boolean>(`/activity_templates/${type}/active`).valueChanges();
    }

    async createActivity(
        isInstance: boolean,
        appKey: string,
        template: ActivityTemplate,
        selectedActivityKey: string,
        isEvenFlow?: boolean,
    ): Promise<string> {
        const updates = {};
        const sequence = await this.calculateActivitySequence(appKey, updates, selectedActivityKey);
        const key = this.db.createPushId();
        const formKey = this.db.createPushId();
        const activity = <Activity>{
            ...template,
            app: appKey,
            date_created: Date.now(),
            date_last_updated: Date.now(),
            live: 'all',
            no_of_users: 0,
            number_questions: 0,
            owner: this.authUserService.getCurrentUserId(),
            sequence,
            status: 'incomplete',
            visible: true,
            has_bookmark: true,
        };

        if (!isEvenFlow) {
            const sessionActivities = await this.getSsotActivitiesByApp(appKey).pipe(take(1)).toPromise();
            const uniqueActivityName = createUniqueName(sessionActivities, template.activity_name, 'activity_name');

            activity.activity_name = uniqueActivityName;
        }

        const options = Object.assign({}, defaultSessionOptions);

        if (template.activity_type.includes('presentation')) {
            delete options['enable_comments'];
            delete options['demographics'];
            delete options['votes'];
            delete options['view'];
            delete options['sort_by'];
        } else if (
            !template.activity_type.includes('brainstorm') &&
            !template.activity_type.includes('crowdsource') &&
            !isInstance &&
            !template.activity_type.includes('presentation')
        ) {
            delete options['enable_comments'];
            delete options['enable_responses'];
            delete options['votes'];

            if (!template.activity_type.includes('categorize')) {
                delete options['responses_visible'];
                delete options['show_name_tags'];
                delete options['sort_by'];
                delete options['sort_order'];
                delete options['view'];
            }
        }

        updates[`activities/${key}`] = activity;
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        updates[`ssot/_activities/${key}`] = this.mapActivityForSsot(activity, formKey);
        updates[`ssot/_activity_settings/${key}/session_options`] = options;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();

        if (template.activity_type.includes('form')) {
            updates[`ssot/_forms/${formKey}/activity_id`] = key;
        }

        await this.db.object('/').update(updates);

        return key;
    }

    // TODO: Update this to SSOT as part of the Activity Settings card...
    getActivityOptions(activityKey: string, appKey: string): Observable<SessionOptions> {
        return this.db
            .object<SessionOptions>(`/activity_settings/${activityKey}/session_options`)
            .valueChanges()
            .pipe(
                flatMap((activityOptions: SessionOptions) => {
                    const agendaVisible = this.db.object<boolean>(`/apps/${appKey}/agenda_visible`).valueChanges();
                    const selfNavigate = this.db.object<boolean>(`/apps/${appKey}/self_navigate`).valueChanges();
                    const status = this.db
                        .object<ActivityStatus>(`/ssot/_activities/${activityKey}/status`)
                        .valueChanges();

                    return combineLatest(of(activityOptions), agendaVisible, selfNavigate, status).pipe(
                        map(([activityOptionsValue, agendaVisibleValue, selfNavigateValue, statusValue]) => ({
                            ...activityOptionsValue,
                            agenda_visible: agendaVisibleValue,
                            self_navigate: selfNavigateValue,
                            status: statusValue,
                        })),
                    );
                }),
            );
    }

    updateActivityOptionsFields(activityKey: string, value: { [field: string]: any }): Promise<void> {
        const updates = {};

        Object.keys(value || {}).forEach(fieldName => {
            updates[`ssot/_activity_settings/${activityKey}/session_options/${fieldName}`] = value[fieldName];
            updates[`activity_settings/${activityKey}/session_options/${fieldName}`] = value[fieldName];
        });

        // if anonymous mode is on "view all/self" button is disabled and toggle is set to "all"
        if (value['anonymous']) {
            updates[`ssot/_activity_settings/${activityKey}/session_options/responses_visible`] = false;
            updates[`activity_settings/${activityKey}/session_options/responses_visible`] = false;
        }

        if (value['activity_timer'] === false) {
            updates[`ssot/_activity_settings/${activityKey}/session_options/timer_data`] = null;
            updates[`activity_settings/${activityKey}/session_options/timer_data`] = null;
        }

        return this.db.object('/').update(updates);
    }

    updateTimerDataFields(activityKey: string, value: { [field: string]: any }): Promise<void> {
        const updates = {};

        Object.keys(value || {}).forEach(fieldName => {
            updates[`ssot/_activity_settings/${activityKey}/session_options/timer_data/${fieldName}`] =
                value[fieldName];
            updates[`activity_settings/${activityKey}/session_options/timer_data/${fieldName}`] = value[fieldName];
        });

        return this.db.object('/').update(updates);
    }

    getActivityOptionsField<T>(activityKey: string, field: string): Observable<T> {
        return this.db.object<T>(`ssot/_activity_settings/${activityKey}/session_options/${field}`).valueChanges();
    }

    // TODO: Update this to SSOT as part of the Activity Settings card...
    getActivityShowNameTagsOptionField(activityKey: string): AngularFireObject<boolean> {
        return this.db.object<boolean>(`/activity_settings/${activityKey}/session_options/show_name_tags`);
    }

    completeActivity(appKey: string, activityKey: string): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/status`] = 'complete_ready';
        updates[`ssot/_activities/${activityKey}/status`] = 'complete_ready';

        return this.db.object('/').update(updates);
    }

    getNextActivity(appKey: string, sequence: number): Observable<{ key: string; type: string } | null> {
        return this.getSsotActivitiesByApp(appKey).pipe(
            take(1),
            flatMap(activities => {
                const sortedActivities = activities.filter(
                    activity => activity.sequence > sequence && activity.status === 'incomplete',
                );

                if (!sortedActivities.length) {
                    return of(null);
                }

                const activityType = activityTypesDictionary[sortedActivities[0].activity_type];

                // TODO check task status === incomplete!!! after EN-1692 implementation
                return of({
                    key: sortedActivities[0].key,
                    type: activityType,
                });
            }),
        );
    }

    getFocusedOrNextActivity(appKey: string, sequence: number): Observable<{ key: string; type: string } | null> {
        return this.getSsotActivitiesByApp(appKey).pipe(
            take(1),
            map(activities => {
                let resultActivity;

                resultActivity = activities.find(activity => activity.status === 'in_progress' && activity.visible); // focused activity
                if (!resultActivity) {
                    resultActivity = activities.find(
                        activity =>
                            activity.sequence > sequence && activity.status === 'incomplete' && activity.visible,
                    ); // next activity
                }

                return resultActivity
                    ? {
                          key: resultActivity.key,
                          type: activityTypesDictionary[resultActivity.activity_type],
                      }
                    : null;
            }),
        );
    }

    getActivityDemographics(activityKey: string): Observable<boolean> {
        return this.db.object<boolean>(`/ssot/_activities/${activityKey}/use_demographics`).valueChanges();
    }

    updateDemographics(activityKey: string, demographics: any, appKey: string): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/demographics`] = demographics;
        updates[`ssot/_activities/${activityKey}/demographics`] = demographics;

        return this.db.object('/').update(updates);
    }

    updateActivitiesOrder(activities: { [key: string]: ActivityTab }): Promise<void> {
        const activityUpdates = {};

        Object.keys(activities).forEach(activityKey => {
            activityUpdates[`activities/${activityKey}/sequence`] = activities[activityKey].sequence;
            activityUpdates[`ssot/_activities/${activityKey}/sequence`] = activities[activityKey].sequence;
            activityUpdates[`ssot/activities/${activityKey}/sequence`] = activities[activityKey].sequence;
        });

        return this.db.object('/').update(activityUpdates);
    }

    removeAppActivity(activityKey: string, appKey: string): Promise<void> {
        return this.removeActivity(activityKey, appKey, {});
    }

    async removeInstanceActivity(activityKey: string, appKey: string, activityType?: string): Promise<void> {
        const promises = [];

        if (activityType && [ActivityType.Form, ActivityType.Present].includes(activityTypesDictionary[activityType])) {
            try {
                const userId = this.authUserService.getCurrentUserId();

                promises.push(
                    this.afFun
                        .httpsCallable('updateDependentChangeManagementData')({
                            projectId: appKey,
                            activityId: activityKey,
                            activityType,
                            userId,
                        })
                        .toPromise(),
                );
            } catch (e) {
                console.error(e, 'error while updating change management data');
            }
        }

        promises.push(
            this.removeActivity(activityKey, appKey, {
                [`activity_settings/${activityKey}`]: null,
            }),
        );

        await Promise.all(promises).catch(e =>
            console.error('error while executing promises in removeInstanceActivity method'),
        );
    }

    async getEventFlowValidation(
        activityKey: string,
        activityType: string,
        dependentNodes: any = {},
    ): Promise<ValidationErrorsType> {
        const userId = this.authUserService.getCurrentUserId();

        try {
            const eventFlowValidationRes = await this.afFun
                .httpsCallable('eventFlowValidation')({
                    sourceActivityId: activityKey,
                    sourceActivityType: activityType,
                    dependentNodes,
                    userId,
                })
                .pipe(take(1))
                .toPromise();

            return eventFlowValidationRes;
        } catch (e) {
            console.log('Error while Event Flow process!');
            console.log(e);
        }
    }

    private async removeActivity(activityKey: string, appKey: string, additionalPaths: any): Promise<void> {
        const multiPathDelete = Object.assign({}, additionalPaths);
        const activities = await this.getSsotActivitiesByApp(appKey).pipe(take(1)).toPromise();
        const activityToDelete = activities.find(activity => activity.key === activityKey);

        if (!activityToDelete) {
            return;
        }

        activities.forEach(activity => {
            if (activityToDelete.sequence < activity.sequence) {
                multiPathDelete[`activities/${activity.key}/sequence`] = activity.sequence - 1;
                multiPathDelete[`ssot/_activities/${activity.key}/sequence`] = activity.sequence - 1;
            }
        });

        multiPathDelete[`activities/${activityKey}`] = null;
        multiPathDelete[`ssot/_activities/${activityKey}`] = null;

        multiPathDelete[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        multiPathDelete[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(multiPathDelete);
    }

    getActivityGroupsByKey(activityKey: string): Observable<Group[]> {
        return this.listWithKeys(this.db.list<Group>(`/ssot/_activities/${activityKey}/groups`));
    }

    hasDemographicsActivityBefore(appKey: string, activityKey?: string): Observable<boolean> {
        return this.db
            .list<Activity>('/ssot/_activities', ref => ref.orderByChild('app_id').equalTo(appKey))
            .snapshotChanges()
            .pipe(
                map(activities => {
                    activities = sortBySequenceAsc(activities);
                    for (let i = 0; i < activities.length; i++) {
                        if (activities[i].key === activityKey) {
                            return false;
                        }
                        const type = activities[i].payload.val().activity_type;

                        if (type === 'demographics') {
                            return true;
                        }
                    }

                    return false;
                }),
            );
    }

    getDemographicsActivityKey(appKey: string): Observable<string> {
        return this.db
            .list<Activity>('/ssot/_activities', ref => ref.orderByChild('app_id').equalTo(appKey))
            .snapshotChanges()
            .pipe(
                map(activities => {
                    const demographicsActivity = activities.find(activitySnapshot => {
                        const type = activitySnapshot.payload.val().activity_type;

                        return type === 'demographics';
                    });

                    return demographicsActivity ? demographicsActivity.key : undefined;
                }),
            );
    }

    updateActivityGroup(appKey: string, activityKey: string, groupKey: string, value: any): Promise<string> {
        const updates = {};

        groupKey = groupKey || this.db.createPushId();
        updates[`activities/${activityKey}/groups/${groupKey}`] = value;
        updates[`ssot/_activities/${activityKey}/groups/${groupKey}`] = value;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db
            .object('/')
            .update(updates)
            .then(() => groupKey);
    }

    updateActivityGroupsOrder(appKey: string, activityKey: string, value: any): Promise<void> {
        const updates = {};

        Object.keys(value).forEach(groupKey => {
            updates[`activities/${activityKey}/groups/${groupKey}/sequence`] = value[groupKey];
            updates[`ssot/_activities/${activityKey}/groups/${groupKey}/sequence`] = value[groupKey];
        });
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    removeActivityGroup(appKey: string, activityKey: string, groupKey: string): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/groups/${groupKey}`] = null;
        updates[`ssot/_activities/${activityKey}/groups/${groupKey}`] = null;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    updateGroupFlag(activityKey: string, groupKey: string, value: boolean): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/groups/${groupKey}/flagged`] = value;
        updates[`ssot/_activities/${activityKey}/groups/${groupKey}/flagged`] = value;

        return this.db.object('/').update(updates);
    }

    updateVoteReportingOptions(activityKey: string, options: VoteReportingOptions): Promise<void> {
        return Promise.all([
            this.db.object(`activities/${activityKey}/vote_reporting_options`).update(options),
            this.db.object(`ssot/_activities/${activityKey}/vote_reporting_options`).update(options),
        ]).then(() => Promise.resolve());
    }

    getVoteReportingOptions(activityKey: string, field = ''): Observable<any> {
        return this.db.object<any>(`/ssot/_activities/${activityKey}/vote_reporting_options/${field}`).valueChanges();
    }

    updateJiraIssueTypeActivityLevel(appKey: string, activityKey: string, jiraIssueType: IssueType): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/jira_issue_type`] = jiraIssueType;
        updates[`ssot/_activities/${activityKey}/jira_issue_type`] = jiraIssueType;

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    updateImportType(appKey: string, activityKey: string, importType: ImportType): Promise<void> {
        const updates = {};

        updates[`activities/${activityKey}/import_type`] = importType;
        updates[`ssot/_activities/${activityKey}/import_type`] = importType;

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    private mapActivityForSsot(activity: Activity, formKey: string | null): Activity {
        const ssotActivity = {
            ...activity,
            owner_id: activity.owner,
            app_id: activity.app,
        };

        delete ssotActivity.app;
        delete ssotActivity.owner;
        if (activity.activity_type.includes('form')) {
            ssotActivity['form_id'] = formKey;
        }

        return ssotActivity;
    }

    private async calculateActivitySequence(
        appKey: string,
        updates: any,
        selectedActivityKey: string,
    ): Promise<number> {
        const activities = await this.getSsotActivitiesByApp(appKey).pipe(take(1)).toPromise();

        // If hasn't activity yet or last selected activity then add new activity to the end
        let sequence = activities.length + 1;
        const lastVisitedActivity = activities.find((activity: Activity) => activity.key === selectedActivityKey);

        if (!!lastVisitedActivity && lastVisitedActivity.sequence !== activities.length) {
            // Update sequence of all activities which follow after selected activity
            activities.forEach((activity: Activity) => {
                if (activity.sequence > lastVisitedActivity.sequence) {
                    updates[`activities/${activity.key}/sequence`] = activity.sequence + 1;
                    updates[`ssot/_activities/${activity.key}/sequence`] = activity.sequence + 1;
                }
            });

            sequence = lastVisitedActivity.sequence + 1;
        }

        return sequence;
    }
}
