import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { isBoolean, isObject } from 'lodash';

import { AnsweredQuestionModel } from '@app/core/models';
import { Task } from '@thinktank/common-lib';

@Component({
	selector: 'app-activity-progress-label',
	templateUrl: './activity-progress-label.component.html',
	styleUrls: ['./activity-progress-label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ActivityProgressLabelComponent implements OnChanges {
	@Input() activeDemographicsIds: string[];
	@Input() answeredQuestions: AnsweredQuestionModel;
	@Input() task: Task;

	activityProgressPercentage$: BehaviorSubject<number> = new BehaviorSubject(0);

	constructor() { }

	get calculateProgressPercentage(): number {
        const demographicStatus = !!this.task && this.task.demographic_status || null;
        const activeDemographics = !!demographicStatus ? Object.keys(demographicStatus) : [];
		const answeredQuestions = this.answeredQuestions;
		
		if (!answeredQuestions) { return 0; }

        // if activity has demographics
        if (activeDemographics.length > 0) {
			
			// if no active demographics for user
			if (!this.activeDemographicsIds) { return 0; }

            let allQuestionsCount = 0;
			let answeredQuestionsCount = 0;
			let secondaryDemographicsLength = 0;

            // check every demographic id
            activeDemographics.forEach(demographicId => {
				secondaryDemographicsLength = 0;
                const demographicAnsweredQuestions = answeredQuestions[demographicId];
				
				if (!demographicAnsweredQuestions) { return 0; }

                // questionOrSecondaryDemographicId -> could be questionId or secondaryDemographicId
                for (const questionOrSecondaryDemographicId of Object.keys(demographicAnsweredQuestions)) {
					const demographicQuestionsData = demographicAnsweredQuestions[questionOrSecondaryDemographicId];

					// is object -> has secondary demographics
					if (isObject(demographicQuestionsData)) {
						const secondaryDemographicId = questionOrSecondaryDemographicId;
						secondaryDemographicsLength += 1;
						const isSectionDemographicActive = this.activeDemographicsIds.includes(secondaryDemographicId);

						// calculate data for secondary demographics
						if (isSectionDemographicActive) {
							const secondaryDemographicQuestionsIds = Object.keys(demographicQuestionsData);
							allQuestionsCount += secondaryDemographicQuestionsIds.length;
							answeredQuestionsCount += secondaryDemographicQuestionsIds.filter(id => demographicQuestionsData[id]).length;
						}
						continue;
					}

					// not an object -> has only main demographic
					const demographicQuestionsIds = Object.keys(demographicAnsweredQuestions);
					allQuestionsCount += (demographicQuestionsIds.length - secondaryDemographicsLength);
					answeredQuestionsCount += demographicQuestionsIds.filter(id => isBoolean(demographicAnsweredQuestions[id]) && demographicAnsweredQuestions[id]).length;
                }
			});

            return Math.round((answeredQuestionsCount) / (allQuestionsCount) * 100);
		}

        // if no demographics for activivty
        const questionIds = Object.keys(answeredQuestions);
        const allQuestionsCount = questionIds.length;
        const answeredQuestionsCount = questionIds.filter(item => !!answeredQuestions[item]).length;
        return Math.round((answeredQuestionsCount) / (allQuestionsCount) * 100);
    }

	ngOnChanges(changes: SimpleChanges) {
		if (!!this.answeredQuestions && (changes.answeredQuestions || changes.activeDemographicsIds)) {
			this.activityProgressPercentage$.next(this.calculateProgressPercentage);
		}
	}
}
