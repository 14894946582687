import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { App, InvitedUser } from '@app/core/models';
import { checkIsIeOrEdgeBrowser, gettingNextData } from '@app/core/utils';

/* eslint-disable */
const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */
// email validator
const emailValidator = (control: FormControl): { invalidEmail: boolean } | null => {
    const value = (control.value || '').trim();

    if (value && !EMAIL_REGEX.test(value)) {
        return { invalidEmail: true };
    }

    return null;
};

type SharingRole = 'collaborators' | 'sessionCreators' | 'participants' | 'observers' | 'leaders';

@Component({
    selector: 'app-bulk-invite-dialog',
    templateUrl: './bulk-invite-dialog.component.html',
    styleUrls: ['./bulk-invite-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BulkInviteDialogComponent implements OnInit {
    searchValue = '';
    userRoleMap = {
        collaborators: 'leader',
        sessionCreators: 'launch-only',
        participants: 'participant',
        observers: 'observer',
        leaders: 'leader',
    };

    partsToDisplay = 1;
    linesLimit = 50;

    tableColumns: string[];
    appsMatchCurrentFilters: App[];
    dataSource: MatTableDataSource<any> | null = new MatTableDataSource([]);
    selection = new SelectionModel<any>(true, []);

    collaborators: { email: string }[];
    sessionCreators: { email: string }[];
    participants: { email: string }[];
    observers: { email: string }[];
    leaders: { email: string }[];
    message: string;
    sharingForm: FormGroup;
    formSubmitted: {
        collaborators?: boolean;
        sessionCreators?: boolean;
        participants?: boolean;
        observers?: boolean;
        leaders?: boolean;
    };

    emailExist: {
        collaborators?: boolean;
        sessionCreators?: boolean;
        participants?: boolean;
        observers?: boolean;
        leaders?: boolean;
    };

    emailEnter: {
        collaborators?: boolean;
        sessionCreators?: boolean;
        participants?: boolean;
        observers?: boolean;
        leaders?: boolean;
    };

    emailList: {
        collaborators: InvitedUser[];
        sessionCreators: InvitedUser[];
        participants: InvitedUser[];
        observers: InvitedUser[];
        leaders: InvitedUser[];
    };

    separatorKeysCodes = [ENTER, COMMA];
    emailsErrors: {
        invalidEmails: string[];
        emailsAlreadyExistInSession: {
            collaborators: string[];
            sessionCreators: string[];
            participants: string[];
            observers: string[];
            leaders: string[];
        };
    };

    emailsErrorMessages: {
        collaborators: string;
        sessionCreators: string;
        participants: string;
        observers: string;
        leaders: string;
    };

    isIeOrEdgeBrowser: boolean;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<BulkInviteDialogComponent>) {
        this.formSubmitted = {};
        this.message = '';
        this.emailExist = {};
        this.emailEnter = {};
        // Detecting Internet Explorer or Edge browsers
        this.isIeOrEdgeBrowser = checkIsIeOrEdgeBrowser();
    }

    get searchPlaceholder(): string {
        return this.data.isInviteToTemplates ? 'Search for a template' : 'Search for a session';
    }

    get isConfirmButtonDisabled(): boolean {
        return (this.data.step === 0 && !this.selection.selected.length) || this.selection.selected.length > 200;
    }

    get errorMessage(): string {
        if (this.data.isInviteToTemplates) {
            return 'You have selected more than 200 Templates';
        } else {
            return 'You have selected more than 200 Sessions';
        }
    }

    get inviteDisabled(): boolean {
        if (this.data.step === 2) {
            return (
                !this.collaborators.length &&
                !this.sessionCreators.length &&
                !this.participants.length &&
                !this.observers.length &&
                !this.leaders.length
            );
        } else {
            return false;
        }
    }

    getNextData(event: any, dataLength: number): void {
        this.partsToDisplay = gettingNextData(event, dataLength, this.linesLimit, this.partsToDisplay);
        this.dataSource.data = this.appsMatchCurrentFilters.slice(0, this.partsToDisplay * this.linesLimit);
    }

    handleConfirm(): void {
        if (this.data.step === 1) {
            this.data.handleConfirm(this.data.apps);
        }

        if (this.data.step === 0) {
            this.data.handleConfirm(this.selection.selected);
        }

        if (this.data.step === 2) {
            const invites = [
                ...this.emailList['collaborators'],
                ...this.emailList['sessionCreators'],
                ...this.emailList['participants'],
                ...this.emailList['observers'],
                ...this.emailList['leaders'],
            ];

            this.data.handleConfirm(this.data.apps, invites, this.sharingForm.controls.message.value);
        }
        this.dialogRef.close();
    }

    closeModal(): void {
        this.dialogRef.close();
    }

    back(): void {
        this.data.back(this.data.apps);
        this.dialogRef.close();
    }

    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.appsMatchCurrentFilters.length;

        return numSelected === numRows;
    }

    toggleAllRows(): void {
        if (this.isAllSelected()) {
            this.selection.clear();

            return;
        }

        this.selection.select(...this.appsMatchCurrentFilters);
    }

    applySearch(value: string): void {
        this.selection.clear();
        this.searchValue = value.trim().toLowerCase() || '';
        this.appsMatchCurrentFilters = this.data.apps.filter((app: App) => {
            return (
                app.name.trim().toLowerCase().includes(this.searchValue) ||
                app.description.trim().toLowerCase().includes(this.searchValue) ||
                app.tag_names?.trim().toLowerCase().includes(this.searchValue) ||
                app.owner_name?.trim().toLowerCase().includes(this.searchValue)
            );
        });

        this.dataSource.data = this.appsMatchCurrentFilters.slice(0, this.partsToDisplay * this.linesLimit);
    }

    addUser(event: MatChipInputEvent, controlName: SharingRole): void {
        const input = event.input;
        let value = (event.value || '').trim();
        const control: AbstractControl = this.sharingForm.controls[controlName];

        if (value) {
            // Remove all commas and empty spaces
            // Lowercase force
            value = value.replace(/,/g, ' ').replace(/(\s+)/g, ' ');
            value = value.toLowerCase();
            input.value = value;
            const emailValues = value.split(' ');

            this.formSubmitted[controlName] = true;

            this.emailsErrorMessages[controlName] = '';

            emailValues.forEach(emailValue => {
                emailValue = emailValue.trim();

                // check if user already exist in current session
                this.emailEnter[controlName] = [
                    ...this.emailList['collaborators'],
                    ...this.emailList['sessionCreators'],
                    ...this.emailList['leaders'],
                    ...this.emailList['participants'],
                    ...this.emailList['observers'],
                ].some(entry => {
                    if (emailValue === entry.email) {
                        this.emailsErrors.emailsAlreadyExistInSession[controlName].push(emailValue);
                    }

                    return emailValue === entry.email;
                });

                // check email
                const invalidEmail = !emailValue.match(EMAIL_REGEX);

                if (invalidEmail) {
                    this.emailsErrors.invalidEmails.push(emailValue);
                }
                control.setErrors({ invalidEmail });

                if (
                    !this.emailExist[controlName] &&
                    !this.emailEnter[controlName] &&
                    !control.hasError('invalidEmail')
                ) {
                    // push to the chips list
                    this[controlName].push({
                        email: emailValue,
                    });

                    // push to the main list
                    this.emailList[controlName].push({
                        email: emailValue,
                        username: emailValue,
                        created_at: Date.now(),
                        role: this.userRoleMap[controlName],
                    });

                    input.value = input.value.replace(emailValue, '').replace(/,/g, ' ').replace(/(\s+)/g, ' ');
                }
            });

            const invalidEmails = this.emailsErrors.invalidEmails;
            const emailsExistInSession = this.emailsErrors.emailsAlreadyExistInSession[controlName];

            let messageText: string;

            const errorsGroup = {
                invalidEmails: {
                    errorsPackage: invalidEmails,
                    errorsName: 'invalidEmails',
                },
                emailsExistInSession: {
                    errorsPackage: emailsExistInSession,
                    errorsName: 'emailsExistInSession',
                },
            };

            const filteredGroups = Object.keys(errorsGroup).filter(key => {
                return errorsGroup[key].errorsPackage.length > 0;
            });

            const matchGroup = filteredGroups.length > 1 ? 'multiple' : filteredGroups[0];

            switch (matchGroup) {
                case errorsGroup.invalidEmails.errorsName:
                    messageText =
                        invalidEmails.length > 1
                            ? 'Please enter valid email addresses.'
                            : 'Please enter a valid email address.';

                    this.showMessageError(control, controlName, true, false, messageText);
                    break;
                case errorsGroup.emailsExistInSession.errorsName:
                    messageText =
                        emailsExistInSession.length > 1
                            ? 'Users with such email address are already invited with other roles'
                            : 'User with this email address is already invited with another role.';

                    this.showMessageError(control, controlName, false, true, messageText);
                    break;
                case 'multiple':
                    messageText = 'Please enter valid email addresses.';

                    this.showMessageError(control, controlName, true, false, messageText);
                    break;
            }

            this.resetEmailsErrors();
        }
    }

    removeUser(value: any, controlName: SharingRole): void {
        this.emailList[controlName] = this.emailList[controlName].filter(entry => {
            return entry.email !== value.email;
        });
        const index = this[controlName].indexOf(value);

        if (index > -1) {
            this[controlName].splice(index, 1);
        }
    }

    updateSubmitState(controlName: SharingRole): void {
        this.formSubmitted[controlName] = false;
        this.emailsErrorMessages[controlName] = '';
    }

    ngOnInit(): void {
        switch (this.data.step) {
            case 0:
                if (this.data.isAdminPanel) {
                    this.tableColumns = ['select', 'name', 'description', 'tag_names', 'date_created', 'owner_name'];
                } else {
                    this.tableColumns = ['select', 'name', 'description', 'tag_names', 'date_created'];
                }
                break;
            case 1:
                if (this.data.isAdminPanel) {
                    this.tableColumns = ['name', 'description', 'tag_names', 'date_created', 'owner_name'];
                } else {
                    this.tableColumns = ['name', 'description', 'tag_names', 'date_created'];
                }
                break;
            case 2:
                this.sharingForm = new FormGroup({
                    collaborators: new FormControl(this.collaborators, Validators.compose([emailValidator])),
                    sessionCreators: new FormControl(this.sessionCreators, Validators.compose([emailValidator])),
                    participants: new FormControl(this.participants, Validators.compose([emailValidator])),
                    observers: new FormControl(this.observers, Validators.compose([emailValidator])),
                    leaders: new FormControl(this.leaders, Validators.compose([emailValidator])),
                    message: new FormControl(this.message),
                });
                this.collaborators = [];
                this.sessionCreators = [];
                this.participants = [];
                this.observers = [];
                this.leaders = [];
                this.emailList = {
                    collaborators: [],
                    sessionCreators: [],
                    participants: [],
                    leaders: [],
                    observers: [],
                };
                this.emailsErrorMessages = {
                    collaborators: '',
                    sessionCreators: '',
                    participants: '',
                    observers: '',
                    leaders: '',
                };
                this.resetEmailsErrors();
                break;
        }
        this.appsMatchCurrentFilters = this.data.apps;
        this.dataSource.data = this.appsMatchCurrentFilters.slice(0, this.partsToDisplay * this.linesLimit);
    }

    private resetEmailsErrors(): void {
        this.emailsErrors = {
            invalidEmails: [],
            emailsAlreadyExistInSession: {
                collaborators: [],
                sessionCreators: [],
                participants: [],
                observers: [],
                leaders: [],
            },
        };
    }

    private showMessageError(
        control: AbstractControl,
        controlName: string,
        isEmailInvalid: boolean,
        isEmailExistInSession: boolean,
        text: string,
    ): void {
        control.setErrors({ invalidEmail: isEmailInvalid });
        this.emailEnter[controlName] = isEmailExistInSession;
        this.emailsErrorMessages[controlName] = text;
    }
}
