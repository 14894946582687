import { Variable } from './variable.model';
import { SelectQuestionNode } from './select-question-node.model';

export class MultiSelectQuestionNode extends SelectQuestionNode {

    type = 'multi_select';

    async setInput(name: string, variableData: Variable): Promise<void> {
        if (name === 'options') {
            console.log(`MultiSelectQuestionNode::setInput - ${this.nodeId} ${name}`, this.consoleLogVariable(variableData));
            const optionValues = (variableData.value || []).map((option) => ({ value: option }));
            if (optionValues.length > 0) {
                const formViewPropertyPath = this.calculateFormViewPropertyPath(name);
                await this.graphService.writeVariableUpdates(this.deploymentId, this.nodeId, this.formId, name, formViewPropertyPath, optionValues);
                await this.propagateValues(variableData.demographicId);
            }
            return;
        }
        return super.setInput(name, variableData);
    }

    getResponseValueForId(demographicId: string): any {
        return (this.response[demographicId] || {}).value || [];
    }
}
