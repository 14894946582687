import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router, ActivatedRouteSnapshot } from '@angular/router';
import { BehaviorSubject, merge, of, Observable } from 'rxjs';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { activityTypesDictionary, FormStatus } from '@app/core/models';
import { DownloadTypes } from '@app/file-download/file-download.models';
import { RoutingFormChangeAnswerKeys, RoutingFormStateKeys, initialFormState } from '../models/routing.model';

@Injectable()
export class RoutingService {
    private _queryParamsSubject = new BehaviorSubject<Params>({});

    private _modeSubject = new BehaviorSubject<string | null>(null);
    private _selectedSourceModeSubject = new BehaviorSubject<string | null>(null);
    private _selectedActivityKeySubject = new BehaviorSubject<string | null>(null);
    private _selectedActivityTypeSubject = new BehaviorSubject<string | null>(null);
    private _selectedQuestionKeySubject = new BehaviorSubject<string | null>(null);

    private _mergeModeResponsesKeysSubject = new BehaviorSubject<string[] | null>([]);

    private _formFocusedQuestionDataKeysSubject = new BehaviorSubject<RoutingFormChangeAnswerKeys>({} as RoutingFormChangeAnswerKeys);
    private _formStateKeysSubject = new BehaviorSubject<RoutingFormStateKeys>({} as RoutingFormStateKeys);

    private _appModeSubject = new BehaviorSubject<string | null>(null);
    private _alignmentStepSubject = new BehaviorSubject<FormStatus | null>(null);
    private _focusedSlideKeySubject = new BehaviorSubject<string | null>(null);
    private _editableLogIdSubject = new BehaviorSubject<string | null>(null);
    private _editableLogProjectIdSubject = new BehaviorSubject<string | null>(null);
    private _editableLogHighlightingSubject = new BehaviorSubject<string | null>(null);
    private _detailsViewSubject = new BehaviorSubject<boolean | null>(null);

    private _params: Params = {};

    public readonly queryParams$ = this._queryParamsSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    public readonly mode$ = this._modeSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly selectedActivityKey$ = this._selectedActivityKeySubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly selectedActivityType$ = this._selectedActivityTypeSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly selectedQuestionKey$ = this._selectedQuestionKeySubject
        .asObservable()
        .pipe(distinctUntilChanged());

    public readonly mergeModeResponsesKeysSubject$ = this._mergeModeResponsesKeysSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    public readonly focusKeys$ = this._formFocusedQuestionDataKeysSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly formParentQuestionKey$ = this._formFocusedQuestionDataKeysSubject
        .asObservable()
        .pipe(map(keys => keys && keys.parentQuestionKey), distinctUntilChanged());
    public readonly changeAnswerFrom$ = this._formFocusedQuestionDataKeysSubject
        .asObservable()
        .pipe(map(keys => keys && keys.from), distinctUntilChanged());
    public readonly formSelectedSectionDemographicKey$ = this._formStateKeysSubject
        .asObservable()
        .pipe(map(keys => keys && keys.sectionDemographicKey), distinctUntilChanged());
    public readonly formSelectedSectionQuestionKey$ = this._formStateKeysSubject
        .asObservable()
        .pipe(map(keys => keys && keys.sectionQuestionKey), distinctUntilChanged());
    public readonly formSelectedQuestionKey$ = this._formStateKeysSubject
        .asObservable()
        .pipe(map(keys => keys && keys.questionKey), distinctUntilChanged());

    public readonly selectedSourceMode$ = this._selectedSourceModeSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly appMode$ = this._appModeSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly alignmentStep$ = this._alignmentStepSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly focusedSlideKey$ = this._focusedSlideKeySubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly editableLogId$ = this._editableLogIdSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly editableLogProjectId$ = this._editableLogProjectIdSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly highlightedLogId$ = this._editableLogHighlightingSubject
        .asObservable()
        .pipe(distinctUntilChanged());
    public readonly detailsView$ = this._detailsViewSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fbAuthUserService: AuthenticatedUserService,
        @Inject('Window') private window: Window
    ) {
        this.getCurrentActiveRoute()
            .subscribe(({ snapshot }: ActivatedRoute) => {
                if (!snapshot) {
                    return;
                }
                this.handleSnapshotChange(snapshot);
            });
    }

    get queryParams(): Params {
        return this._queryParamsSubject.getValue();
    }

    get mode(): string {
        return this._modeSubject.getValue();
    }

    get changeAnswerFrom(): string {
        return this._formFocusedQuestionDataKeysSubject.getValue().from;
    }

    get isActivitySelected(): boolean {
        return !!this._selectedActivityKeySubject.getValue();
    }

    get activityKey(): string | null {
        return this._selectedActivityKeySubject.getValue();
    }

    get activityType(): string | null {
        return this._selectedActivityTypeSubject.getValue();
    }

    get questionKey(): string | null {
        return this._selectedQuestionKeySubject.getValue();
    }

    get sourceMode(): string | null {
        return this._selectedSourceModeSubject.getValue();
    }

    get selectedQuestionKey(): string {
        return this._selectedQuestionKeySubject.getValue();
    }

    get parentQuestionKey(): string {
        return this._formStateKeysSubject.getValue().sectionQuestionKey;
    }

    get focusedDemographicKey(): string {
        return this._formFocusedQuestionDataKeysSubject.getValue().demographicKey;
    }

    get focusedSectionDemographicKey(): string {
        return this._formFocusedQuestionDataKeysSubject.getValue().sectionDemographicKey;
    }

    get mergeModeResponsesKeys(): string[] {
        return this._mergeModeResponsesKeysSubject.getValue() || [];
    }

    get appKey(): string {
        return this._params.appKey;
    }

    get isInstance(): boolean {
        return this.router.url.startsWith('/instance');
    }

    get location(): string {
        return this.isInstance ? 'instance' : 'app';
    }

    get isEdit(): boolean {
        return this.router.url.includes('/edit');
    }

    get isForm(): boolean {
        return this.router.url.includes('/form');
    }

    get isAddActivity(): boolean {
        return this.router.url.includes('add-activity');
    }

    navigateToDownloadPage(type: DownloadTypes, key: string, questionKey?: string): void {
        const path = ['download'];
        const url = this.router.serializeUrl(
            this.router.createUrlTree(path, {
                queryParams: {
                    type,
                    key,
                    questionKey
                },
                relativeTo: this.route
            })
        );

        this.window.open(url, '_blank');
    }

    updateLastVisitedActivity(activityKey: string, activityType: string): Promise<void> {
        const path = this.isInstance ? 'app_instances' : 'apps';
        const isLeader = this.fbAuthUserService.isUserLeader(path, this.appKey);
        const data = {
            activity: { key: activityKey },
            activity_type: activityType
        };

        if (!isLeader) {
            return;
        }

        return this.fbAuthUserService.updateLastVisitedActivity(this.appKey, data);
    }

    toggleEditLog(projectId: string, logId: string): void {
        const queryParams = {
            project: projectId,
            editLog: logId
        };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams
        });
    }

    setHighlightedLog(logId: string): void {
        const queryParams = {
            log: logId
        };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams
        });
    }

    navigateToActivity(
        activityKey?: string,
        activityType?: string,
        queryParams?: Params,
        disableLastVisitedActivity?: boolean,
        completeAdding?: boolean
    ): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType] || this.activityType;
        const activityChanged = activityKey !== this.activityKey;
        queryParams = queryParams || ((activityChanged || completeAdding) ? this.resetQueryParams : { ...this._queryParamsSubject.getValue() });
        if (activityType === 'presentation') {
            this.navigateToPresentActivity(activityKey, queryParams);
            return;
        }
        const path = [this.location, this.appKey, activityType, activityKey];

        if (!disableLastVisitedActivity) {
            this.updateLastVisitedActivity(activityKey, activityType);
        }

        this.router.navigate(path, {
            queryParams,
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    navigateToFormActivity(mode: string, activityKey?: string, activityType?: string): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType] || this.activityType;
        const path = [this.location, this.appKey, activityType, activityKey, mode];

        this.updateLastVisitedActivity(activityKey, activityType);
        this.router.navigate(path, {
            relativeTo: this.route,
            queryParams: {
                ...initialFormState
            }
        });
    }

    navigateToFormSummary(activityKey: string, queryParams: Params = {}): void {
        const path = [this.location, this.appKey, 'form', activityKey, 'summary'];
        this.router.navigate(path, {
            queryParams,
            relativeTo: this.route
        });
    }

    navigateToFormChangeAnswer(
        isCondensedView: boolean,
        isAlignmentEnabled: boolean,
        questionToChangeKey: string,
        sectionKey?: string,
        demographicKey?: string,
        focusedQuestionKey?: string,
        parentQuestionKey?: string,
        subDemographicKey?: string,
        from?: 'review' | 'summary'
    ): void {
        const path = isAlignmentEnabled
            ? [this.location, this.appKey, 'form', this.activityKey, 'draft']
            : [this.location, this.appKey, 'form', this.activityKey];

        const queryParams: Params = {
            // change answer view keys
            fqk: questionToChangeKey, // focus
            fsk: sectionKey || null, // section
            fdk: demographicKey || null, // demographic
            fsqk: parentQuestionKey, // parent question
            fsdk: subDemographicKey || null, // section (secondary) demographic
            from,
            // form state keys
            qk: isCondensedView ? focusedQuestionKey || questionToChangeKey || null : null,
            sqk: parentQuestionKey,
            sdk: focusedQuestionKey && parentQuestionKey ? demographicKey || null : null,
        };
        this.router.navigate(path, {
            queryParams,
            relativeTo: this.route
        });
    }

    navigateToFormMode(mode: string): void {
        const path = !!mode
            ? [this.location, this.appKey, 'form', this.activityKey, mode]
            : [this.location, this.appKey, 'form', this.activityKey];
        this.router.navigate(path, {
            relativeTo: this.route,
            queryParams: this.queryParams
        });
    }

    navigateToFormSection(sectionQuestionKey: string): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                ...this.queryParams,
                sqk: sectionQuestionKey
            }
        });
    }

    navigateToFormFirstError(
        questionKey: string,
        demographicKey?: string,
        errorSectionQuestionKey?: string
    ): void {
        const queryParams: Params = {
            sdk: demographicKey || null, // demoKey
            qk: questionKey, // question
            sqk: errorSectionQuestionKey || null // errorSectionQuestionKey
        };

        this.router.navigate([], {
            queryParams,
            queryParamsHandling: 'merge',
            relativeTo: this.route
        });
    }

    navigateToFormEditNewWindow(activityKey: string, activityType: string): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType];
        const path = [this.location, this.appKey, activityType, activityKey, 'edit'];
        const url = this.router.serializeUrl(
            this.router.createUrlTree(path, {
                relativeTo: this.route,
                queryParams: this.getQueryParamsForEdit(),
                queryParamsHandling: 'merge'
            })
        );
        this.window.open(url, '_blank');
    }

    navigateToSourceQuestion(questionKey: string, activityKey?: string, activityType?: string): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType] || this.activityType;
        const path = [this.location, this.appKey, activityType, activityKey, 'question', questionKey];
        const mode = this.sourceMode;
        if (['group', 'merge'].includes(mode)) {
            path.push(mode);
        }

        this.updateLastVisitedActivity(activityKey, activityType);
        this.router.navigate(path, {
            relativeTo: this.route
        });
    }

    updateMergePanelResponses(responsesKeys: string[]): void {
        const path = [this.location, this.appKey, this.activityType, this.activityKey, 'question', this.selectedQuestionKey, 'merge'];

        this.router.navigate(path, {
            queryParams: {
                ...this.queryParams,
                responseKey: responsesKeys
            },
            relativeTo: this.route
        });
    }

    navigateToCollectQuestion(questionKey: string, activityKey?: string, activityType?: string): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType] || this.activityType;
        this.navigateToActivity(activityKey, activityType, {
            ...this.resetQueryParams,
            question: questionKey
        });
    }

    navigateToVoteGroup(groupKey: string, activityKey?: string, activityType?: string): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType] || this.activityType;
        this.navigateToActivity(activityKey, activityType, {
            ...this.resetQueryParams,
            groupKey
        });
    }

    navigateToDemographicsClass(demographicsClassKey: string, activityKey?: string, activityType?: string): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType] || this.activityType;
        this.navigateToActivity(activityKey, activityType, {
            ...this.resetQueryParams,
            classKey: demographicsClassKey
        });
    }

    navigateToPresentActivity(activityKey: string, queryParams: Params, isRedirectionFromEdit?: boolean, mode?: string): void {
        const clearEditLogParams = isRedirectionFromEdit || (activityKey !== this.activityKey) || mode !== this.mode;
        if (clearEditLogParams) {
            queryParams = {
                ...queryParams,
                editLog: null,
                project: null
            };
        }
        const path = [this.location, this.appKey, 'presentation', activityKey];
        this.router.navigate(path, {
            queryParams,
            relativeTo: this.route,
            queryParamsHandling: 'merge'
        });
    }

    navigateToSlide(activityKey: string, slideKey: string, isRedirectionFromEdit?: boolean, mode?: string): void {
        const queryParams = {
            ...this.resetQueryParams,
            slide: slideKey
        };
        this.navigateToPresentActivity(activityKey, queryParams, isRedirectionFromEdit, mode);
    }

    navigateToEdit(activityKey?: string, activityType?: string): void {
        activityKey = activityKey || this.activityKey;
        activityType = activityTypesDictionary[activityType] || this.activityType;
        const path = [this.location, this.appKey, activityType, activityKey];
        if (this.activityType === 'crowdsource') {
            const questionKey = this.questionKey || 'new';
            path.push('question', questionKey);
        }
        path.push('edit');
        this.router.navigate(path, {
            relativeTo: this.route,
            queryParams: this.getQueryParamsForEdit(),
            queryParamsHandling: 'merge'
        });
    }

    navigateToHome(): void {
        const path = [this.location, this.appKey];
        this.router.navigate(path);
    }

    navigateToTasksSetUp(): void {
        this.router.navigate(['/flow-setup'], {
            relativeTo: this.route
        });
    }

    navigateToAddActivity(): void {
        const path = [this.location, this.appKey, 'add-activity'];
        const queryParams = {
            currentActivityKey: this.activityKey,
            currentActivityType: this.getActivityType()
        };
        this.router.navigate(path, {
            queryParams,
            relativeTo: this.route,
        });
    }

    navigateToActivityReporting(activityKey?: string): void {
        activityKey = activityKey || this.activityKey;
        const path = [this.location, this.appKey, 'reporting', activityKey];
        this.router.navigate(path, {
            relativeTo: this.route
        });
    }

    navigateToRepositoryPage(): void {
        const path = ['administration', 'repository'];
        this.router.navigate(path, {
            relativeTo: this.route
        });
    }

    navigateToChangeLog(): void {
        const activityKey = this.activityKey;
        const activityType = this.activityType;
        const path = [this.location, this.appKey, activityType, activityKey, 'change-log'];
        this.router.navigate(path, {
            relativeTo: this.route
        });
    }

    setSelectedQuestion(questionKey: string): void {
        this._selectedQuestionKeySubject.next(questionKey);
    }

    setSelectedCondensedQuestion(data: Params): void {
        const queryParams = {
            qk: data.questionKey, // question
            sdk: data.demoKey, // demoKey
            sqk: data.sectionKey // errorSectionQuestionKey - section question = parent question
        };
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling: 'merge'
        });
    }

    setActivityType(type: string) {
        this._selectedActivityTypeSubject.next(activityTypesDictionary[type]);
    }

    changeSourceMode(mode: string): void {
        const path = [this.location, this.appKey, this.activityType, this.activityKey];
        const questionKey = this._params.questionKey;
        if (questionKey) {
            path.push('question', this._params.questionKey);

            if (mode && ['group', 'merge'].includes(mode)) {
                path.push(mode);
            }
        }
        this.router.navigate(path, {
            relativeTo: this.route
        });
    }

    updateAddActivityQueryParams(data: Params): Promise<boolean> {
        const queryParams = {
            currentActivityKey: data.activityKey,
            currentActivityType: data.activityType
        };
        return this.router.navigate([], {
            queryParams
        });
    }

    clearQueryParams(): void {
        this.router.navigate([], {
            queryParams: {
                type: null,
                state: null,
                request_key: null,
                workflow_card_key: null
            },
            queryParamsHandling: 'merge'
        });
    }

    clearCustomQueryParams(params: { [name: string]: null }): void {
        this.router.navigate([], {
            queryParams: params,
            queryParamsHandling: 'merge'
        });
    }

    private getCurrentActiveRoute(): Observable<ActivatedRoute> {
        return merge(
            of(new NavigationEnd(0, '', '')),
            this.router.events
        )
            .pipe(
                filter((event) => event instanceof NavigationEnd),
                map(() => this.route),
                map((activeRoute) => {
                    while (activeRoute.firstChild) {
                        activeRoute = activeRoute.firstChild;
                    }
                    return activeRoute;
                })
            );
    }

    private handleSnapshotChange(snapshot: ActivatedRouteSnapshot): void {
        const { params, queryParams, data } = snapshot;
        this._params = params;
        this._queryParamsSubject.next(queryParams);

        const appMode = this.verifyAppMode(this.router.url);
        if (appMode && appMode !== 'add-activity') { return; }

        const selectedActivityKey = (appMode === 'add-activity') ? queryParams.currentActivityKey : params.activityKey;
        this._selectedActivityKeySubject.next(selectedActivityKey);

        const selectedActivityType = (appMode === 'add-activity') ? queryParams.currentActivityType : this.getActivityType();
        this._selectedActivityTypeSubject.next(selectedActivityType);

        const {
            // question - collect, source activity
            question,
            // for demographic activity
            classKey,
            // for vote with demographics
            groupKey,
            // present activity
            slide,
            // to open edit log panel
            editLog,
            // project id from editable log
            project,
            // log id to highlight log while editing sub collections
            log,
            // is used to open details tab in summary/review table after redirection from change answer
            detailsView,
            // responses in the merge panel on Source Activity
            responseKey,

            // focus - for focused question key
            fqk,
            // section - for focused section key
            fsk,
            // demographic - for focused demographic key
            fdk,
            // subDemographicKey - for focused subform demographic
            // (when subform has it's own demographic class and user want to edit the response for it)
            fsdk,
            // parentQuestionKey = section question key - key of the question with subform type
            fsqk,
            // from - the view from where user was redirected to current view
            // (is used for change answer, possible values: 'summary' | 'review')
            from,

            // question - form selected question key
            qk,
            // errorSectionQuestionKey = section question key
            sqk,
            // demoKey - form condensed view selected section demographic
            sdk
        } = queryParams;

        const { mode, view } = data;
        this._modeSubject.next(mode);
        this._alignmentStepSubject.next(view);

        // params.questionKey - source activity selected question key
        const questionKey = params.questionKey || question || classKey || groupKey;
        this._selectedQuestionKeySubject.next(questionKey);
        this._selectedSourceModeSubject.next(this.getSourceMode());
        this._focusedSlideKeySubject.next(slide);
        this._mergeModeResponsesKeysSubject.next(typeof responseKey === 'string' ? [responseKey] : responseKey);

        // Change Answer View
        this._formFocusedQuestionDataKeysSubject.next({
            questionKey: fqk,
            sectionKey: fsk,
            demographicKey: fdk,
            sectionDemographicKey: fsdk,
            parentQuestionKey: fsqk,
            from
        });

        // Form State
        this._formStateKeysSubject.next({
            questionKey: qk || fqk,
            sectionQuestionKey: sqk || fsqk,
            sectionDemographicKey: sdk || fsdk
        });

        // open submit-log-panel component to update log
        this._editableLogIdSubject.next(editLog);
        this._editableLogProjectIdSubject.next(project);
        this._editableLogHighlightingSubject.next(log);

        this._detailsViewSubject.next(detailsView);
    }

    private get resetQueryParams(): Params {
        return {
            ...initialFormState,
            question: null,
            demographic: null,
            classKey: null,
            groupKey: null,
            currentActivityKey: null,
            currentActivityType: null
        };
    }

    private getQueryParamsForEdit(): Params {
        const queryParams: Params = {};
        if (this.activityType === 'collect') {
            queryParams.question = (this._queryParamsSubject.getValue()).question || 'new';
        }
        if (this.activityType === 'form') {
            queryParams.qk = null; // question
            queryParams.sdk = null; // demoKey
        }
        return queryParams;
    }

    private getSourceMode(): string {
        const url = this.router.url;
        if (url.includes('/group')) {
            return 'group';
        }

        if (!this.questionKey) {
            return null;
        }

        if (url.includes('/merge')) {
            return 'merge';
        }
        return 'responses';
    }

    private getActivityType(): string {
        const activityKey = this.activityKey;
        if (!activityKey) {
            return null;
        }
        const type = this.router.url.split('/')[3];
        return !!type
            ? type === 'reporting' ? this.activityType : activityTypesDictionary[type]
            : null;
    }

    private verifyAppMode(url: string): string {
        const isInvite = url.includes('invite');
        const isSharing = url.includes('sharing');
        const isAddActivity = url.includes('add-activity');
        let mode = null;

        if (isInvite) { mode = 'invite'; }
        if (isSharing) { mode = 'sharing'; }
        if (isAddActivity) { mode = 'add-activity'; }

        this._appModeSubject.next(mode);
        return mode;
    }
}
