import { createAction, props } from '@ngrx/store';

export const initializeCurrentUser = createAction(
    '[Auth] Initialize Current User',
    props<{ cognitoUserData: any }>()
);

export const initializeCurrentUserSuccess = createAction(
    '[Auth] Initialize Current User Success',
    props<{ userData: any }>()
);

export const initializeReturningUser = createAction(
    '[Auth] Initialize Returning User',
    props<{ userId: string }>()
);

export const initializeReturningUserSuccess = createAction(
    '[Auth] Initialize Returning User Success',
    props<{ userData: any }>()
);

export const signOut = createAction(
    '[Auth] Sign Out'
);

export const signOutSuccess = createAction(
    '[Auth] Sign Out Success'
);
