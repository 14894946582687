import {
    ChangeDetectionStrategy,
    Component,
    OnInit,
    OnChanges,
    Input,
    Output,
    EventEmitter,
    SimpleChanges
} from '@angular/core';

import { trackById } from '@app/core/utils';

@Component({
    selector: 'app-star-rating',
    templateUrl: './star-rating.component.html',
    styleUrls: ['./star-rating.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StarRatingComponent implements OnInit, OnChanges {
    @Input() isSearchResponseFound: boolean;
    @Input() value: number;
    @Input() disabled?: boolean;
    @Input() isFormEditQuestionView?: boolean;
    @Input() questionKey?: string; // trigger init after change of question
    @Input() demographicKey?: string; // trigger init for questions inside sections with demographics - ON
    @Output() onSetRatingValue = new EventEmitter<number>();

    selectedRating = 0;
    stars = [];
    trackById = trackById;
    defaultStarsNumber = 5;

    constructor() { }

    setRating(value: number): void {
        if (this.disabled || this.selectedRating === value) {
            return;
        }

        this.onSetRatingValue.emit(value);
        this.selectedRating = value;
        this.generateStars();
    }

    generateStars(): void {
        this.stars = [];
        // default 5 stars
        const numberOfStarsArr = new Array(this.defaultStarsNumber).fill('');
        numberOfStarsArr.forEach((_, id) => {
            const filled = !!this.selectedRating && id < this.selectedRating;
            this.stars.push({
                id,
                filled,
                value: id + 1
            });
        });
    }

    ngOnInit() {
        this.generateStars();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.demographicKey || changes.questionKey || (changes.value && (this.value || this.value === 0))) {
            this.selectedRating = this.value;
            this.generateStars();
        }
    }
}
