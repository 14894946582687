import { ChangeDetectionStrategy, Component, Inject, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { Gate, NodeType, Task, TaskType, EngageFormStatus } from '@thinktank/common-lib';
import { trackByKey } from '@app/core/utils';
import { AuthenticatedUserService, TaskService } from '@app/core/services';
import {
    blockedByGateTaskMessage,
    blockedByTasksAndGatesTaskMessage,
    blockedByTasksTaskMessage,
    formsTaskMessage
} from './tasks-list.component.contants';
import { AnsweredQuestionModel } from '@app/core/models';
import { TasksListService } from '@app/shared/tasks-list/tasks-list.service';

@Component({
    selector: 'app-tasks-list',
    templateUrl: './tasks-list.component.html',
    styleUrls: ['./tasks-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TasksListComponent implements OnChanges {
    @Input() isApp: boolean;
    @Input() isLeader: boolean;
    @Input() projectKey: string;
    @Input() list: (Task | Gate)[];
    @Input() emulate?: boolean;
    @Input() resetEmulation?: any;
    @Input() isHome?: boolean;
    @Input() answeredQuestions: {[activityId: string]: AnsweredQuestionModel};
    @Input() demographicStatusesMap: {[taskKey: string]: { name: string, status: EngageFormStatus }[]};

    userKey: string;
    trackByKey = trackByKey;

    bookmarkMap: { [activityId: string]: Observable<boolean> } = {};

    private excludeFromMessage: TaskType[] = [TaskType.Present, TaskType.Source];

    private emulatedCompliteness = {};

    constructor(
        authService: AuthenticatedUserService,
        private taskService: TaskService,
        private tasksListService: TasksListService,
        @Inject('Window') private window: Window
    ) {
        this.userKey = authService.getCurrentUserId();
    }

    getDemographics(task: Task): { name: string, status: EngageFormStatus }[] {
        return this.demographicStatusesMap && this.demographicStatusesMap[task.key];
    }

    isShowFormState(task: Task): boolean {
        if ((!task.form_status && !task.demographic_status)
            || task.type !== TaskType.Forms
            || this.isLeader
        ) {
            return;
        }

        if (!!task.demographic_status) {
            return !(Object.values(task.demographic_status)).find((item) => item.status !== EngageFormStatus.Approved);
        }
        return true;
    }

    showItem(item: Task | Gate): boolean {
        const gateData = (item as Gate).data;
        return !!gateData
            ? gateData.leader_check
            : true;
    }

    isGatePassed(gate: Gate, index: number): boolean {
        const { activities_required, leader_check, completed } = gate.data;
        const isDemographicGate = gate.key === 'Demographic_Gate_Key';
        const gateCompletedByLeader = this.emulate ? this.emulatedCompliteness[gate.key] : completed;

        // both toggles are off
        if (!activities_required && !leader_check && !isDemographicGate) {
            return true;
        }

        // both toggles are on
        if (activities_required && leader_check) {
            return gateCompletedByLeader && this.areTasksCompleteForGate(gate, index);
        }

        // only look at completed if there's a leader check
        if (leader_check) {
            return gateCompletedByLeader;
        }

        // do the task check (both demographic gate and non demographic gate)
        return this.areTasksCompleteForGate(gate, index);
    }

    private areTasksCompleteForGate(gate: Gate, index: number): boolean {
        const array = [...this.list];
        const isDemographicGate = gate.key === 'Demographic_Gate_Key';
        if (isDemographicGate) {
            const demoTask = array.find((task: Task) => task.type === TaskType.Demographics) as Task;
            return this.emulate
                ? !!this.emulatedCompliteness[demoTask.key]
                : demoTask.complete && demoTask.complete[this.userKey];
        }
        return array
            .splice(0, index)
            .filter(item => ![NodeType.Gate, TaskType.Source, TaskType.Present].includes(item.type))
            .every((task: Task) => {
                return this.emulate
                    ? !!this.emulatedCompliteness[task.key]
                    : task.complete && task.complete[this.userKey];
            });
    }

    isBlocked(item: Task | Gate, index: number): boolean {
        const isGate = !!(item as Gate).data;
        if (isGate) {
            return false;
        }

        if (this.isLeader && !this.emulate) {
            return true;
        }

        const task = item as Task;
        const taskCompleted = this.emulate
            ? !!this.emulatedCompliteness[task.key]
            : task.complete && task.complete[this.userKey];
        if (taskCompleted) {
            return false;
        }

        // find first gate before the task
        const cutArray = [...this.list].splice(0, index);
        const gates = cutArray
            .map((gateOrTask, i) => ({ ...gateOrTask, i }))
            .filter((gate: Gate & { i: number }) => gate.type === NodeType.Gate);

        if (gates.length) {
            return !gates.every((gate: Gate & { i: number }) => this.isGatePassed(gate as Gate, gate.i));
        }

        return false;
    }

    isCompleted(item: Task | Gate): boolean {
        const gate = (item as Gate).data;
        const isTask = !gate;
        if ((this.isLeader && isTask) && !this.emulate) {
            return false;
        }
        if (this.emulate) {
            return !!this.emulatedCompliteness[item.key];
        }
        const complete = (item as Task).complete;
        return isTask
            ? complete && complete[this.userKey]
            : gate.completed;
    }

    getActivityLink(item: Task | Gate, index?: number): string | null {
        if (this.emulate || (this.isBlocked(item, index) && !this.isLeader)) {
            return null;
        }

        const isGate = !!(item as Gate).data;
        if (isGate) {
            return null;
        }

        const { type, activity_id } = item as Task;
        const projectType = this.isApp ? 'app' : 'instance';
        return `/${projectType}/${this.projectKey}/${type}/${activity_id}`;
    }

    getAnsweredQuestionsByActivityId(item: Task): AnsweredQuestionModel {

        // progress only for task for Form Activity
        if (!!item && item.type !== TaskType.Forms) {
            return null;
        }

        const { activity_id } = item;
        return !!this.answeredQuestions && this.answeredQuestions[activity_id] || null;
    }

    getTooltipMessage(task: Task, index: number, isBlocked: boolean, isCompleted: boolean): string | null {

        if (isCompleted || this.isLeader) {
            return null;
        }

        const isFormsTask = task.type === TaskType.Forms;
        if (isBlocked) {
            // get all tasks before current blocked task
            const listBeforeCurrentTask = [...this.list].splice(0, index);
            let leaderCheckExist = false;
            let activityCheckExist = false;

            listBeforeCurrentTask.forEach((item, i) => {
                if (item.type === NodeType.Gate && item.data && !item.data.completed) {
                    leaderCheckExist = leaderCheckExist || item.data.leader_check;

                    if (item.key === 'Demographic_Gate_Key') {
                        const taskBeforeDemoGate = (<Task>listBeforeCurrentTask[i - 1]);
                        activityCheckExist = activityCheckExist || !(taskBeforeDemoGate.complete
                            && taskBeforeDemoGate.complete[this.userKey]);
                    }
                }
                if (item.type === NodeType.Gate && item.data && item.data.activities_required) {
                    const areTasksComplete = this.areTasksCompleteForGate(item, i);
                    activityCheckExist = !areTasksComplete;
                }
            });

            if (!leaderCheckExist && !activityCheckExist) {
                return null;
            }

            // Gate with (leader_check: true) exists
            // Gate with (activities_required: true) exists
            // then show message with the Tasks and Gates
            if (leaderCheckExist && activityCheckExist) {
                return blockedByTasksAndGatesTaskMessage();
            }

            // Gate with (leader_check: true) exists
            // then show message with the Gates only
            if (leaderCheckExist) {
                return blockedByGateTaskMessage();
            }

            // Gate with (activities_required: true) exists
            // then show message with the Tasks only
            if (activityCheckExist) {
                return blockedByTasksTaskMessage();
            }
        } else if (isFormsTask) {
            return formsTaskMessage(!!task.demographic_status);
        }

        return null;
    }

    toggleCompleted(key: string): void {
        this.emulatedCompliteness[key] = !this.emulatedCompliteness[key];
    }

    completeGate(gateKey: string, gateName: string): void {
        const instanceUrl = window.location.href.split('/').splice(0, 3).join('/');
        this.taskService.completeGate(this.projectKey, gateKey, gateName, instanceUrl);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.resetEmulation) {
            this.emulatedCompliteness = {};
        }

        if (!!changes.list && !!this.list && !!this.list.length) {
            this.bookmarkMap = this.tasksListService.updateBookmarkMap(this.list, this.bookmarkMap);
        }
    }
}
