<div class="change-password-dialog thinktank-theme">
  <h3 class="h3 dialog-title">Change password</h3>
  <mat-dialog-content class="change-password-content">
    <ul class="change-password-messages" *ngIf="isSubmitted">
      <li class="change-password-error text" *ngFor="let error of errors">{{ error }}</li>
      <li class="change-password-success text" *ngIf="!errors.length">Password successfully updated</li>
    </ul>
    <mat-form-field class="large" appearance="outline" color="primary" floatLabel="always" *ngIf="envIndicator">
      <mat-label>Old password</mat-label>
      <input
        matInput
        required
        type="password"
        [formControl]="oldPasswordControl"
        [(ngModel)]="oldPassword"
        (ngModelChange)="onChange()"
      />
      <mat-error *ngIf="oldPasswordControl.hasError('required')">This field is required</mat-error>
      <mat-error *ngIf="oldPasswordControl.hasError('minlength')"
        >Password must contain at least 8 characters
      </mat-error>
    </mat-form-field>
    <mat-form-field class="large" appearance="outline" color="primary" floatLabel="always">
      <mat-label>New password</mat-label>
      <input
        matInput
        required
        type="password"
        [formControl]="newPasswordControl"
        [(ngModel)]="newPassword"
        (ngModelChange)="onChange()"
      />
      <mat-error *ngIf="newPasswordControl.hasError('required')">This field is required</mat-error>
    </mat-form-field>
    <ul *ngIf="newPasswordControl.dirty" class="change-password-validation">
      <li
        *ngFor="let passwordValidator of newUserPasswordValidationText"
        class="change-password-validation-text text"
        [ngClass]="{
          hasError: checkPasswordValidation(passwordValidator)
        }"
      >
        <mat-icon *ngIf="!newPasswordControl.hasError(passwordValidator.errorName) && newPasswordControl.value?.length">
          done
        </mat-icon>
        <mat-icon
          *ngIf="newPasswordControl.hasError(passwordValidator.errorName) || newPasswordControl.hasError('required')"
        >
          close
        </mat-icon>
        {{ passwordValidator.validationText }}
      </li>
    </ul>
  </mat-dialog-content>
  <mat-error class="validation-message">
    <span>{{ errorMessage }}</span>
  </mat-error>
  <div class="validation-message-success">
    <span *ngIf="confirmation"> Your password has been reset </span>
  </div>
  <mat-dialog-actions class="change-password-actions">
    <br />
    <button cdkFocusInitial class="tt-button-filled" (click)="onSubmit()" [disabled]="submitValidation">
      {{ data.confirmBtnText }}
    </button>
    <button mat-dialog-close class="tt-button-outline">{{ data.cancelBtnText }}</button>
  </mat-dialog-actions>
</div>
