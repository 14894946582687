import { Pipe, PipeTransform } from '@angular/core';

import { User } from '@app/core/models/user.model';

@Pipe({
    name: 'userRole'
})

export class UserRolePipe implements PipeTransform {

    transform(
        users: User[], locationKey: string, role: string, location: 'apps' | 'app_instances' = 'app_instances'
    ): any {
        if (users) {
            return users.filter((user) => {
                if (user[location]) {
                    return user[location][locationKey] === role;
                }
                return user['role'] === role;
            });
        }
        return [];
    }

}
