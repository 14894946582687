import { FormBranchingVisibility, FormQuestionValidationError } from '@app/core/models';

export const getBranchingVisibility = (
    formBranchingVisibility: { [questionKey: string]: FormBranchingVisibility },
    demographicKey?: string
) => (questionKey: string, secondaryDemographicKey?: string) => {
    return !!secondaryDemographicKey
        ? !!((formBranchingVisibility[questionKey] || {})[demographicKey] || {})[secondaryDemographicKey]
        : (!!demographicKey
            ? !!(formBranchingVisibility[questionKey] || {})[demographicKey]
            : !!formBranchingVisibility[questionKey]);
};

export const filterErrorsByBranching = (
    formBranchingVisibility: { [questionKey: string]: FormBranchingVisibility }
) => (error: FormQuestionValidationError): boolean => {
    const { question_id, demographic_id, section_question_id, secondary_demographic_id, source_section_question_id } = error;

    const getQuestionVisibility = getBranchingVisibility(formBranchingVisibility, demographic_id);
    // if has section_question_id - question is inside the section
    // the question's parent section question should be visible
    // if question is in the repeated section - parent source section question should be visible
    const isParentSectionVisible = !!section_question_id
        ? getQuestionVisibility(section_question_id) && getQuestionVisibility(source_section_question_id)
        : true;
    // question should be visible
    const isQuestionVisible = getQuestionVisibility(question_id, secondary_demographic_id);

    return isParentSectionVisible && isQuestionVisible;
}
