<div class="project-name-dialog">
  <div mat-dialog-title class="dialog-title">
    <h3 mat-dialog-title class="h3">{{ title }}</h3>

    <button mat-icon-button mat-dialog-close class="close">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="project-name-dialog-content">
    <p class="text">{{ text }}</p>
    <div class="tt-theme-input-field">
      <mat-form-field class="project-name" appearance="outline" floatLabel="always">
        <input
          matInput
          required
          cdkFocusInitial
          class="form-control"
          [placeholder]="'Enter Client & Project name here'"
          [formControl]="projectNameControl"
        />
        <mat-error *ngIf="projectNameControl.hasError('required')">This field is required</mat-error>
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="project-name-dialog-actions">
    <button cdkFocusInitial class="tt-button-filled" [disabled]="projectNameControl.invalid" (click)="handleConfirm()">
      {{ data.confirmBtnText }}
    </button>
    <button mat-dialog-close class="tt-button-outline">{{ data.cancelBtnText }}</button>
  </mat-dialog-actions>
</div>
