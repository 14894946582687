import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { trackByKey } from '@app/core/utils';
import { OptionsViewDialogData } from './models';

@Component({
    selector: 'app-options-view-dialog',
    templateUrl: './options-view-dialog.component.html',
    styleUrls: ['./options-view-dialog.component.scss']
})
export class OptionsViewDialogComponent {
    public trackByKey = trackByKey;
    public demographics = null;
    public selectedValues = [];
    public choicesValues = [];

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OptionsViewDialogData,
        private _dialogRef: MatDialogRef<OptionsViewDialogComponent>
    ) {
        this.demographics = data.demographics;
        this.selectedValues = data.selectedValues;
        this.choicesValues = data.choicesValues;
    }

    public onClose() {
        this._dialogRef.close();
    }
}
