import { Deployment } from './deployment.model';
import { OverlayTemplate } from './overlay-template.model';
import { Demographic } from '@thinktank/common-lib';

export interface CreateDeploymentView {
    deployment_templates: Deployment[];
    template_length: number;
    global_demographics: Demographic[];
    deployment_copy_id?: string;
    overlay_templates?: OverlayTemplate[];
    currentUserId: string;
    isLoading: boolean;
}

export const initialCreateDeploymentView = {
    deployment_templates: [],
    template_length: 0,
    global_demographics: [],
    deployment_copy_id: '',
    overlay_templates: [],
    currentUserId: '',
    isLoading: true
}
