<div class="demographics-list">
  <div class="select-all">
    <p class="title h5">
      Select Countries
    </p>
    <div class="demographics-search">
      <mat-form-field>
        <input
          #search
          matInput
          [placeholder]="'Enter Your Search Here'"
          [(ngModel)]="searchValue"
        />
        <mat-icon *ngIf="!search.value" matSuffix>search</mat-icon>
        <mat-icon *ngIf="search.value" (click)="clearSearch()" matSuffix>close</mat-icon>
      </mat-form-field>
    </div>
  </div>

  <gs-demographics-list
    [demographicsList]="allDemographics"
    [searchValue]="searchValue"
    [selectedDemographics]="selectedDemographics$ | async"
    (onSelectDemographic)="selectDemographic($event)"
    (onSelectGroupDemographics)="selectGroupDemographics($event)"
  ></gs-demographics-list>
</div>
