import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, combineLatest, of } from 'rxjs';
import { take, map, flatMap } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { AuthenticatedUserService } from './authenticated-user.service';
import { FirebaseAppService } from './app.service';
import { FirebaseDemographicsService } from './demographics.service';
import {
    TaskService as FirestoreTaskServcie,
    GateService,
    Task,
    Gate,
    GateData,
    NodeType,
    TasksCount,
    TaskType
} from '@thinktank/common-lib';
import { FocusAbilityService } from './focus-ability.service';
import { AnsweredQuestionModel } from '@app/core/models';

@Injectable({
    providedIn: 'root'
})
export class TaskService {
    completeMyTaskMode = false;

    constructor(
        private db: AngularFireDatabase,
        private router: Router,
        private authService: AuthenticatedUserService,
        private appService: FirebaseAppService,
        private firestoreTaskService: FirestoreTaskServcie,
        private firestoreGateService: GateService,
        private fbDemographicsService: FirebaseDemographicsService,
        private focusAbilityService: FocusAbilityService
    ) { }

    get getCompleteMyTaskMode(): boolean {
        return this.completeMyTaskMode;
    }

    setCompleteMyTaskMode(val: boolean): void {
        this.completeMyTaskMode = val;
    }

    async completeTask(projectKey: string, taskKey: string): Promise<void> {
        const userKey = this.authService.getCurrentUserId();
        const tasksEnabled = await this.appService.getAppField(projectKey, 'participants_tasks_list').pipe(take(1)).toPromise();
        return tasksEnabled
            ? this.firestoreTaskService.completeTask(projectKey, taskKey, userKey)
            : Promise.resolve();
    }

    async completeGate(projectKey: string, gateKey: string, gateName: string, instanceUrl: string): Promise<void> {
        await this.firestoreGateService.completeGate(projectKey, gateKey);

        const updates = {};
        updates[`/ssot/_gate_complete/${this.db.createPushId()}`] = {
            name: gateName,
            project_id: projectKey,
            gate_id: gateKey,
            instance_url: instanceUrl,
            user_id: this.authService.getCurrentUserId()
        };

        return this.db.object('/').update(updates);
    }

    getTasksAndGates(projectKey: string): Observable<(Task | Gate)[]> {
        const tasks$: Observable<Task[]> = this.firestoreTaskService.getTasks(projectKey);
        const gates$: Observable<Gate[]> = this.firestoreGateService.getGates(projectKey);

        return combineLatest([tasks$, gates$])
            .pipe(
                map(([tasks, gates]) => {
                    const foundDemoTask = (tasks || []).find((task) => task.type === 'demographics');

                    if (foundDemoTask) {
                        const demoGate = {
                            key: 'Demographic_Gate_Key',
                            data: {
                                name: 'Demographic Activity Gate',
                                sequence: foundDemoTask.sequence + 0.00001
                            },
                            type: NodeType.Gate,
                            visible: true
                        };
                        // usually gates are 0.001 but this is to make sure it is ALWAYS right after the demographic activity
                        gates.push(demoGate);
                    }

                    return this.sortTasksAndGates([...tasks, ...gates]);
                })
            );
    }

    getNextIncompleteTask(appKey: string, activityKey: string): Observable<{ key: string; type: string; } | null> {
        const userKey = this.authService.getCurrentUserId();

        return this.getTasksAndGates(appKey)
            .pipe(
                take(1),
                flatMap(activities => {
                    const taskIndex = activities.findIndex((i: Task) => i.activity_id === activityKey);
                    const firstIncompleteTask = activities.find((task: Task | Gate, index: number) => {
                        return index > taskIndex && !this.isBlockedOrCompleted(activities, task, index, userKey);
                    });

                    if (!firstIncompleteTask) {
                        return of(null);
                    }

                    return of({
                        key: (<Task>firstIncompleteTask).activity_id,
                        type: firstIncompleteTask.type
                    });
                }));
    }

    getTasksCountByUser(projectKey: string): Observable<TasksCount> {
        const userKey = this.authService.getCurrentUserId();

        return this.firestoreTaskService.getTasks(projectKey)
            .pipe(
                map((tasks) => {
                    const totalTasksCount = tasks.length;
                    const incompletedTasksCount = tasks
                        .filter((task) => !(task.complete || {})[userKey]).length;
                    const completedTasksCount = totalTasksCount - incompletedTasksCount;
                    return <TasksCount>{ totalTasksCount, incompletedTasksCount, completedTasksCount };
                })
            );
    }

    getBlockingGateNames(projectKey: string, taskSequence: number): Observable<string[]> {
        return this.firestoreGateService.getGates(projectKey)
            .pipe(
                map(gates => gates
                    .filter(gate => {
                        const { leader_check, completed, sequence } = gate.data;
                        return sequence < taskSequence && leader_check && !completed;
                    })
                    .map(gate => gate.data.name))
            );
    }

    getAnsweredQuestions(sessionKey: string): Observable<{[activityId: string]: AnsweredQuestionModel}> {
        return this.db.object<{[activityKey: string]: AnsweredQuestionModel}>(`/ssot/_answered_questions/${sessionKey}`).valueChanges();
    }

    async completeMyTasks(appKey: string, isApp: boolean, isHome?: boolean): Promise<void> {
        const userKey = this.authService.getCurrentUserId();
        const focus = await this.focusAbilityService.getFocus(appKey).pipe(take(1)).toPromise();
        const tasks = await this.getTasksAndGates(appKey).pipe(take(1)).toPromise();

        const focusNotBlockedOrCompleted = !!focus && tasks.find((task, index) => {
            return (<Task>task).activity_id === focus.activity_id
                && !this.isBlockedOrCompleted(tasks, task, index, userKey);
        });
        const firstIncompleteTask = tasks.find((task: Task | Gate, index: number) => {
            return !this.isBlockedOrCompleted(tasks, task, index, userKey);
        });

        // This mode is used in navigateByStatus and navigateByFocus functions in apps component
        if (!isHome) {
            this.setCompleteMyTaskMode(true);
        }

        // Navigate first of all to focused activity if not blocked or completed
        if (!!focus && focusNotBlockedOrCompleted) {
            this.focusAbilityService.navigateUsingFocusObject(appKey, focus);
            return;
        }

        // If there is no focused activity or it's blocked or completed
        // Navigate to first incomplete task
        if (firstIncompleteTask) {
            this.router.navigate([
                isApp ? 'app' : 'instance',
                appKey,
                firstIncompleteTask.type,
                (<Task>firstIncompleteTask).activity_id
            ]);
        }
    }

    async canFocusOnActivity(projectKey: string, activityKey: string): Promise<boolean> {
        const tasks = await this.getTasksAndGates(projectKey).pipe(take(1)).toPromise();

        // get current tsak by activityKey and check gate state
        const currentTask = (tasks || []).find((task: Task | Gate, index) => {
            return ((<Task>task).activity_id === activityKey) && this.canFocusWithGate(tasks, index);
        });

        return !!currentTask;
    }

    private sortTasksAndGates(list: (Task | Gate)[]): (Task | Gate)[] {
        return list.sort((a, b) => {
            const i = a.type === NodeType.Gate ? <GateData>a.data : <Task>a;
            const j = b.type === NodeType.Gate ? <GateData>b.data : <Task>b;
            return -j.sequence - -i.sequence;
        });
    }

    private areTasksCompleteForGate(list: (Task | Gate)[], gate: Gate, index: number, userKey: string): boolean {
        const array = [...list];
        const isDemographicGate = gate.key === 'Demographic_Gate_Key';

        if (isDemographicGate) {
            const demoTask = array.find((task: Task) => task.type === TaskType.Demographics) as Task;
            return demoTask.complete && demoTask.complete[userKey];
        }

        return array
            .splice(0, index)
            .filter(item => ![NodeType.Gate, TaskType.Source, TaskType.Present].includes(item.type))
            .every((task: Task) => {
                return task.complete && task.complete[userKey];
            });
    }

    private isGatePassed(list: (Task | Gate)[], gate: Gate, index: number, userKey: string): boolean {
        const { activities_required, leader_check, completed } = gate.data;

        // both toggles are off
        if (!activities_required && !leader_check) {
            return true;
        }

        // both toggles are on
        if (activities_required && leader_check) {
            return this.areTasksCompleteForGate(list, gate, index, userKey) && completed;
        }

        // only look at completed if there's a leader check
        if (leader_check) {
            return completed;
        }

        // do the task check (both demographic gate and non demographic gate)
        return this.areTasksCompleteForGate(list, gate, index, userKey);
    }

    private isBlockedOrCompleted(list: (Task | Gate)[], item: (Task | Gate), index: number, userKey: string): boolean {
        const isGate = !!(item as Gate).data;

        if (isGate) {
            return true;
        }

        const task = item as Task;
        const taskCompleted = task.complete && task.complete[userKey];

        if (taskCompleted) {
            return true;
        }

        // find incomplete gates before the task
        const cutArray = [...list].splice(0, index);
        const gates = cutArray
            .map((gateOrTask, i) => ({ ...gateOrTask, i }))
            .filter((gate: Gate & { i: number }) => gate.type === NodeType.Gate);

        if (gates.length) {
            return !gates.every((gate: Gate & { i: number }) => this.isGatePassed(list, gate as Gate, gate.i, userKey));
        }

        return false;
    }

    private getGatesBeforeTask(list: (Task | Gate)[], index: number): Gate[] {
        const array = [...list];
        const cutArray = array.splice(0, index).reverse();
        const gates = cutArray
            .filter(i => i.type === NodeType.Gate)
            .map(item => item as Gate);

        return gates;
    }

    private canFocusWithGate(list: (Task | Gate)[], index: number): boolean {

        // find first gate before the task
        const gates = this.getGatesBeforeTask(list, index);

        // Leader can focus on Blocked activities if they are blocked due to other incomplete activities
        // i.e by default gate
        // or by the gate with Requires Completion of Activities toggle ON and Requires Leader Check toggle OFF
        const requiredGates = gates.filter(gate => {
            const gateData = gate.data;
            return gateData.leader_check && !gateData.completed;
        });

        return !requiredGates.length;
    }
}
