import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';

import { ModuleViewState, Ownership, Volunteer } from '../../models';

export interface ModuleViewFacade {
    vm$: Observable<ModuleViewState>;

    routeToDeployment(deploymentId: string): void;

    routeToAssignment(deploymentId: string, moduleId: string): void;

    routeToEdit(deploymentId: string, moduleId: string): void;

    navigateTo(data: {navigationItems: string[], queryParams: any}): void;

    volunteer(volunteerDataObject: Volunteer, deploymentId: string): void;

    unassignUser(ownershipToRemove: Ownership, deploymentId: string, moduleId: string): void;

    exportWorkbook(deploymentId: string, moduleId: string): Promise<{ filename: string, downloadUrl: string }[]>;
}

export const ModuleViewFacadeToken = new InjectionToken<ModuleViewFacade>('ModuleViewFacade');
