import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from '@app/login/login.component';
import { UserLoginComponent } from '@app/signon/user-login/user-login.component';
import { UserRegisterComponent } from '@app/signon/user-register/user-register.component';
import { SsoCallbackComponent } from '@app/signon/sso-callback/sso-callback.component';
import { DirectLoginComponent } from '@app/login/direct-login.component';
import { AuthenticationGuard } from '@app/core/guards/authentication-guard.service';
import { AppGuard } from '@app/core/guards/app.guard';
import { SessionJoinLinkComponent } from '@app/login/session-join-link/session-join-link.component';
import { DeploymentsGuard } from '@app/core/guards/deployments.guard';
import { environment } from '@env/environment';
import { ForgotPasswordComponent } from './signon/forgot-password/forgot-password.component';
import { LoginGuard } from '@app/core/guards/login.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/navigation',
        pathMatch: 'full',
    },
    {
        path: 'login',
        component: environment['aws'] ? LoginComponent : UserLoginComponent,
        pathMatch: 'full',
        canActivate: [LoginGuard],
    },
    {
        path: 'forgotPassword',
        component: ForgotPasswordComponent,
        pathMatch: 'full',
        canActivate: [LoginGuard],
    },
    {
        path: 'register',
        component: UserRegisterComponent,
        pathMatch: 'full',
        canActivate: [LoginGuard],
    },
    {
        path: 'callback',
        component: SsoCallbackComponent,
    },
    {
        path: 'directLogin',
        component: DirectLoginComponent,
        pathMatch: 'full',
    },
    {
        path: 'navigation',
        loadChildren: () => import('./navigation/navigation.module').then(m => m.NavigationModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'administration',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'instance/:appKey',
        loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
        canActivate: [AuthenticationGuard, AppGuard],
    },
    {
        path: 'app/:appKey',
        loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
        canActivate: [AuthenticationGuard, AppGuard],
    },
    {
        path: 'sessionJoin/:appPasskeyGuid',
        component: SessionJoinLinkComponent,
    },
    {
        path: 'deactivated',
        loadChildren: () =>
            import('./deactivated-screen/desctivated-screen.module').then(m => m.DeactivatedScreenModule),
    },
    {
        path: 'download',
        loadChildren: () => import('./file-download/file-download.module').then(m => m.FileDownloadModule),
        canActivate: [AuthenticationGuard],
    },
    {
        path: 'deployment',
        loadChildren: () => import('./deployments/deployments.module').then(m => m.DeploymentsModule),
        canActivate: [AuthenticationGuard, DeploymentsGuard],
    },
    {
        path: 'style-guide',
        loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule),
    },
    {
        path: 'userManagement',
        loadChildren: () =>
            import('./user-authentication-management/user-authentication-management.module').then(
                m => m.UserAuthenticationManagementModule,
            ),
    },
    {
        // TODO: Change this to redirect to an error page and process accordingly when we get closer to deployment...
        path: '**',
        redirectTo: 'navigation',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always' })],
    exports: [RouterModule],
    providers: [],
})
export class ThinkTankRoutingModule {}
