import {
    ChangeDetectionStrategy,
    Component,
    Input,
    OnInit,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges,
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AutocompleteOption } from '@app/core/models';
import { trackByIndex } from '@app/core/utils';
import { validationMessages } from '@app/apps/activities/present/present.component.constants';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AutocompleteComponent implements OnInit, OnChanges {
    @Input() allOptions: AutocompleteOption[];
    @Input() existOptions: AutocompleteOption[];

    @Output() clickByOption = new EventEmitter<AutocompleteOption>();
    @Output() openOptionsPanel = new EventEmitter<void>();

    autocompleteControl = new FormControl('', {
        validators: [Validators.maxLength(255)],
        updateOn: 'change',
    });

    filteredOptions$: Observable<AutocompleteOption[]>;
    autocompleteValue$: Observable<string>;
    optionArray?: AutocompleteOption[];

    private _allOptionsSubject$: BehaviorSubject<AutocompleteOption[]> = new BehaviorSubject([]);
    private _existOptionsSubject$: BehaviorSubject<AutocompleteOption[]> = new BehaviorSubject([]);

    validationMessages = {
        ...validationMessages,
    };

    trackByIndex = trackByIndex;

    constructor() {}

    ngOnInit() {
        this.filteredOptions$ = combineLatest(
            this.autocompleteControl.valueChanges,
            this._allOptionsSubject$,
            this._existOptionsSubject$,
        ).pipe(
            map(([optionName, allOptions, existOptions]) => {
                this.optionArray = [];
                if (optionName === '' || this.autocompleteControl.hasError('maxlength')) {
                    this.autocompleteControl.markAsTouched();

                    return this.optionArray;
                }

                if (optionName.trim() === '') {
                    this.autocompleteControl.patchValue('');

                    return this.optionArray;
                }
                const filteredOptions = this.optionsFilter(optionName.trim(), allOptions);

                const currentAppOptionNames = existOptions.reduce((acc, option) => {
                    return [...acc, option.name];
                }, []);

                this.optionArray = filteredOptions.filter(option => !currentAppOptionNames.includes(option.name));

                return this.optionArray;
            }),
        );
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['allOptions']) {
            this._allOptionsSubject$.next(this.allOptions);
        }

        if (changes['existOptions']) {
            this._existOptionsSubject$.next(this.existOptions);
        }
    }

    get noMatchesFound(): boolean {
        return (
            !this.optionArray?.length &&
            this.autocompleteControl.value.trim() !== '' &&
            !this.autocompleteControl.hasError('maxlength')
        );
    }

    private optionsFilter(name: string, allOptions: AutocompleteOption[]): AutocompleteOption[] {
        const filterValue = name.toLowerCase();

        const isFindOption = allOptions.find(el => {
            return el.name.toLowerCase() === filterValue;
        });

        if (!isFindOption) {
            const tempOptions = [{ name: name + ' (new tag)' }, ...allOptions];

            return tempOptions.filter(option => option.name.toLowerCase().includes(filterValue));
        } else {
            return allOptions.filter(option => option.name.toLowerCase().includes(filterValue));
        }
    }

    onClickByOption(option: AutocompleteOption): void {
        this.autocompleteControl.patchValue('');
        this.clickByOption.emit({ ...option, name: option.name.replace(' (new tag)', '') });
    }
}
