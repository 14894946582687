import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, Validators, ValidationErrors, ValidatorFn } from '@angular/forms';

const keywordValidator = (data): ValidatorFn => (control: AbstractControl): ValidationErrors | null => {
    const value: string = control.value;

    if (!value) {
        return null;
    }

    return (value.toLowerCase().trim() !== (data.keyword || 'remove'))
        ? { nomatch: true }
        : null;
};

@Component({
    selector: 'app-confirm-dialog-with-keyword',
    templateUrl: './confirm-dialog-with-keyword.component.html',
    styleUrls: ['./confirm-dialog-with-keyword.component.scss']
})

export class ConfirmDialogWithKeywordComponent implements OnInit {
    keywordControl: FormControl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ConfirmDialogWithKeywordComponent>
    ) {}

    handleConfirm(): void {
        const { handleConfirm, closeOnConfirm } = this.data;
        handleConfirm();

        if (closeOnConfirm) {
            this.dialogRef.close();
        }
    }

    ngOnInit(): void {
        this.keywordControl = new FormControl('', [
            Validators.required,
            keywordValidator(this.data)
        ]);
    }

}
