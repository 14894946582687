<!-- creation deployment snack bar -->
<ng-container *ngIf="!createDeploymentOpened && showSnackBar$.asObservable() | async">
  <gs-info-snackbar
    [isLoading]="deploymentLoading$.asObservable() | async"
    [isReady]="deploymentReady$.asObservable() | async"
    [deploymentName]="pendingDeploymentData?.deployment_name || ''"
    [clientName]="pendingDeploymentData?.client_name || ''"
    [text]="snackBarText"
    (onSetDeployment)="setNewDeployment()"
    (onClose)="onCloseSnackbar()"
  ></gs-info-snackbar>
</ng-container>
<!-- creation deployment snack bar -->
