<div class="gs-table-wrapper">
  <table class="gs-table">
    <thead>
      <tr *ngIf="!showProcessesImpacted">
        <th class="gs-table-th">Owner</th>
        <th *ngIf="showAll" class="gs-table-th">Process/Subprocess Name</th>
        <th *ngIf="showAll" class="gs-table-th">Type</th>
        <th *ngIf="!showAll && !showSubprocesses" class="gs-table-th">Process</th>
        <th *ngIf="showSubprocesses" class="gs-table-th">Sub Process</th>
        <th class="gs-table-th">Satisfaction</th>
        <th class="gs-table-th">Open<br/>Change<br/>Requests</th>
        <th class="gs-table-th">Total Number of <br/>Change<br/>Requests</th>
        <th class="gs-table-th">Last Pulse</th>
        <th class="gs-table-th">Actions</th>
      </tr>
      <tr *ngIf="showProcessesImpacted">
        <th class="gs-table-th">Process/Sub Process Impacted</th>
        <th class="gs-table-th">Type</th>
        <th class="gs-table-th">Owner Impacted</th>
        <th class="gs-table-th">Actions</th>
      </tr>
    </thead>

    <!-- all table -->
    <tbody *ngIf="showTable('all')">
      <ng-container *ngIf="(tableData$.asObservable() | async)?.length; else noData">
        <tr *ngFor="let item of getAllTableData(); trackBy: trackById">
          <td class="gs-table-td">
            <gs-assignees [users]="getOwners(item.id)" [limit]="1"></gs-assignees>
          </td>
          <td class="gs-table-td">{{ item.name }}</td>
          <td class="gs-table-td">{{ getTextForItem(item) }}</td>
          <td class="gs-table-td">{{ getSatisfaction(item) }}%</td>
          <td class="gs-table-td">{{ getNumberChangeRequests(item, true) }}</td>
          <td class="gs-table-td">{{ getNumberChangeRequests(item, false) }}</td>
          <td class="gs-table-td">{{ getPulseDate(item) | date }}</td>
          <td class="gs-table-td">
            <div class="actions">
              <button class="gs-button-flat gs-small" [routerLink]="[getTypeForItem(item), item.id]">Show Details</button>
              <button class="gs-button-flat gs-small" (click)="sendPulse(item)">Send Pulse</button>
              <button class="gs-button-flat gs-small" [routerLink]="[getTypeForItem(item), item.id, 'assign']">Assign Users</button>
              <button
                *ngIf="item.type === 'subprocess'"
                class="gs-button-flat gs-small"
                [disabled]="isVolunteerDisabled(item)"
                (click)="volunteerForSubprocess(item)"
              >
                Volunteer
              </button>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #noData>
        <tr>
          <td class="caption text-gray text-center gs-table-td" colspan="7">
            There are no Processes and Subprocesses in this {{ viewType | titlecase }} yet
          </td>
        </tr>
      </ng-template>
    </tbody>
    <!-- /all table -->

    <!-- processes table -->
    <tbody *ngIf="showTable('process')">
      <ng-container *ngIf="(tableData$.asObservable() | async)?.length; else noProcesses">
        <tr *ngFor="let process of (tableData$.asObservable() | async); trackBy: trackById">
          <td class="gs-table-td">
            <gs-assignees [users]="getOwners(process.id)" [limit]="1"></gs-assignees>
          </td>
          <td class="gs-table-td">{{ process.name }}</td>
          <td class="gs-table-td">{{ getSatisfaction(process) }}%</td>
          <td class="gs-table-td">{{ getNumberChangeRequests(process, true) }}</td>
          <td class="gs-table-td">{{ getNumberChangeRequests(process, false) }}</td>
          <td class="gs-table-td">{{ getPulseDate(process) | date }}</td>
          <td class="gs-table-td">
            <div class="actions">
              <button class="gs-button-flat gs-small" [routerLink]="[getTypeForItem(process), process.id]">Show Details</button>
              <button class="gs-button-flat gs-small" (click)="sendPulse(process)">Send Pulse</button>
              <button class="gs-button-flat gs-small" [routerLink]="[getTypeForItem(process), process.id, 'assign']">Assign Users</button>
            </div>
          </td>
        </tr>
      </ng-container>
      <ng-template #noProcesses>
        <tr>
          <td class="caption text-gray text-center gs-table-td" colspan="7">
            There are no Processes in this {{ viewType | titlecase }} yet
          </td>
        </tr>
      </ng-template>
    </tbody>
    <!-- /processes table -->

    <!-- sub processes table -->
    <tbody *ngIf="showTable('subprocess')">
      <ng-container *ngIf="subprocessCount$.asObservable(); else noSubprocesses">
        <ng-container *ngFor="let process of (tableData$.asObservable() | async); trackBy: trackById">
          <tr *ngFor="let subprocess of process.subprocesses; trackBy: trackById">
            <td class="gs-table-td">
              <gs-assignees [users]="getOwners(subprocess.id)" [limit]="1"></gs-assignees>
            </td>
            <td *ngIf="showProcesses" class="gs-table-td">{{ process.name }}</td>
            <td class="gs-table-td">{{ subprocess.name }}</td>
            <td class="gs-table-td">{{ getSatisfaction(subprocess) }}%</td>
            <td class="gs-table-td">{{ getNumberChangeRequests(subprocess, true) }}</td>
            <td class="gs-table-td">{{ getNumberChangeRequests(subprocess, false) }}</td>
            <td class="gs-table-td">{{ getPulseDate(subprocess) | date }}</td>
            <td class="gs-table-td">
              <div class="actions">
                <button class="gs-button-flat gs-small" [routerLink]="[getTypeForItem(subprocess), subprocess.id]">Show Details</button>
                <button class="gs-button-flat gs-small" (click)="sendPulse(subprocess)">Send Pulse</button>
                <button class="gs-button-flat gs-small" [routerLink]="[getTypeForItem(subprocess), subprocess.id, 'assign']">Assign Users</button>
                <button
                  class="gs-button-flat gs-small"
                  [disabled]="isVolunteerDisabled(subprocess)"
                  (click)="volunteerForSubprocess(subprocess)"
                >
                  Volunteer
                </button>
              </div>
            </td>
          </tr>
        </ng-container>
      </ng-container>
      <ng-template #noSubprocesses>
        <tr>
          <td class="caption text-gray text-center gs-table-td" colspan="8">
            There are no Sub Processes in this {{ viewType | titlecase }} yet
          </td>
        </tr>
      </ng-template>
    </tbody>
    <!-- /sub processes table -->

    <!-- processes impacted table -->
    <tbody *ngIf="showTable('impact')">
      <ng-container *ngIf="impactData?.length > 0; else noImpacted">
        <ng-container *ngFor="let impact of impactData; trackBy: trackById">
          <tr *ngIf="!impact.process_id || (impact.process_id !== impact.process_impacted_id)">
            <td class="gs-table-td">{{ impact.process_impacted_name }}</td>
            <td class="gs-table-td">Process</td>

            <td class="gs-table-td">
              <gs-assignees [users]="getOwners(impact.process_impacted_id)" [limit]="1"></gs-assignees>
            </td>

            <td class="gs-table-td">
              <div class="actions">
                <button
                  class="gs-button-flat gs-small"
                  [routerLink]="['../../process', impact.process_impacted_id]"
                >
                  Show Details
                </button>
              </div>
            </td>
          </tr>
          <ng-container *ngFor="let subprocess of impact.subprocesses; trackBy: trackById">
            <tr *ngIf="!subprocess.subprocess_id || (subprocess.subprocess_id !== subprocess.subprocess_impacted_id)">
              <td class="gs-table-td">{{ subprocess.subprocess_impacted_name }}</td>
              <td class="gs-table-td">Sub Process</td>

              <td class="gs-table-td">
                <gs-assignees [users]="getOwners(subprocess.subprocess_impacted_id)" [limit]="1"></gs-assignees>
              </td>

              <td class="gs-table-td">
                <div class="actions">
                  <button
                    class="gs-button-flat gs-small"
                    [routerLink]="['../../subprocess', subprocess.subprocess_impacted_id]"
                  >
                    Show Details
                  </button>
                </div>
              </td>
            </tr>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #noImpacted>
        <tr>
          <td class="caption text-gray text-center gs-table-td" colspan="8">
            There are no Processes/Sub Processes impacted yet
          </td>
        </tr>
      </ng-template>
      <ng-template #noSubprocesses>
        <tr>
          <td class="caption text-gray text-center gs-table-td" colspan="8">
            There are no Sub Processes in this {{ viewType | titlecase }} yet
          </td>
        </tr>
      </ng-template>
    </tbody>
    <!-- /processes impacted table -->
  </table>
</div>
