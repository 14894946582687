import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { DeploymentViewFacade, DeploymentViewFacadeToken } from './deployment-view-facade';
import {
    DeploymentViewState,
    Module,
    ObjectType,
    objectTypeSet,
    Ownership,
    Process,
    Subprocess,
    UserRole
} from '../../models';
import { trackById } from '@thinktank/common-lib';
import { uniqBy } from 'lodash';

@Component({
    selector: 'gs-deployment-view',
    templateUrl: './deployment-view.component.html',
    styleUrls: ['./deployment-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentViewComponent {
    vm$ = this.facade.vm$;

    trackById = trackById;
    ObjectType = ObjectType;
    activeTab = 0;

    constructor(@Inject(DeploymentViewFacadeToken) private facade: DeploymentViewFacade) {
    }

    isItemSubprocess(item: Module | Process | Subprocess): boolean {
        return this.getTypeForItem(item) === ObjectType.Subprocess;
    }

    getAllData(viewState: DeploymentViewState): any[] {
        let returnVal = [];
        viewState.modules.forEach((module) => {
            returnVal.push(module);
            const moduleProcesses = viewState.processes.filter((process) => process.module_id === module.id);
            moduleProcesses.forEach((process) => {
                returnVal.push(process);
                returnVal = returnVal.concat(
                    viewState.subprocesses.filter((subprocess) => subprocess.process_id === process.id));
            });
        });
        return returnVal;
    }

    getTypeForItem(item: Module | Process | Subprocess): string {
        return objectTypeSet[item.type].type;
    }

    getOwners(objectId: string, ownershipView: Ownership[]): Ownership[] {
        const objectUsers = ownershipView.filter(user => user.object_id === objectId && user.role === UserRole.Owner);
        return uniqBy(objectUsers, 'id');
    }

    goBackToDeployments(): void {
        console.log('goBackToDeployments called');
    }

    isVolunteerDisabled(item: Subprocess, vm: DeploymentViewState): boolean {
        let isDisabled: boolean;
        const myOwnership = vm.ownership.filter((ownershipObj) => {
            return ownershipObj.object_id === item.id &&
                ownershipObj.role === UserRole.Stakeholder &&
                ownershipObj.user_id === vm.authenticatedUserId;
        });

        if (item.global) {
            const isPrimaryGlobalOwner = myOwnership.find((owner) => owner.primary);
            isDisabled = !!isPrimaryGlobalOwner;
        } else {
            const myDemoOwnershipMap = {};
            vm.selectedDemographics.forEach((demographic) => {
                const isPrimaryDemoOwner = myOwnership.find((owner) => owner.demographic_id === demographic.id && owner.primary);
                myDemoOwnershipMap[demographic.id] = !!isPrimaryDemoOwner;
            });

            const hasFalseValues = (Object.values(myDemoOwnershipMap).some((isPrimarySME) => !isPrimarySME));
            isDisabled = !hasFalseValues;
        }

        return isDisabled;
    }

    volunteerForGeneric(item: Subprocess, vm: DeploymentViewState): void {
        console.log(`volunteerForGeneric called with subprocessId: ${item.id}`);
        // const myOwnership = vm.ownership.filter((ownershipObj) => {
        //     return ownershipObj.object_id === item.id &&
        //         ownershipObj.role === UserRole.Stakeholder &&
        //         ownershipObj.user_id === vm.authenticatedUserId &&
        //         ownershipObj.primary;
        // });
        //
        // let volunteerDemographics = vm.selectedDemographics;
        // if (myOwnership.length > 0) {
        //     volunteerDemographics = vm.selectedDemographics.filter((demographic) => {
        //         const isPrimaryDemoOwner = myOwnership.find((owner) => owner.demographic_id === demographic.id);
        //         return !isPrimaryDemoOwner;
        //     });
        // }
        //
        // this.facade.volunteer(<Volunteer>{
        //     item,
        //     demographics: volunteerDemographics
        // }, vm.deployment.id);
    }

    navigateToItemGeneric(item: Module | Process | Subprocess, deploymentId: string, isAssign: boolean): void {
        console.log('navigateToItemGeneric called');
        // const itemType = item.type;
        // switch (itemType) {
        //     case ObjectType.Module:
        //         isAssign
        //             ? this.deploymentFacade.routeToAssignment(itemType, this.route, deploymentId, item.id)
        //             : this.deploymentFacade.routeToItem(
        //             itemType,
        //             deploymentId,
        //             item.id
        //             );
        //         break;
        //     case ObjectType.Process:
        //         const process = <ProcessView>item;
        //         isAssign
        //             ? this.deploymentFacade.routeToAssignment(itemType, this.route, deploymentId, process.module_id, process.id)
        //             : this.deploymentFacade.routeToItem(
        //             itemType,
        //             deploymentId,
        //             process.module_id,
        //             process.id
        //             );
        //         break;
        //     case ObjectType.Subprocess:
        //         const subprocess = <SubprocessView>item;
        //         isAssign
        //             ? this.deploymentFacade.routeToAssignment(
        //             itemType,
        //             this.route,
        //             deploymentId,
        //             subprocess.module_id,
        //             subprocess.process_id,
        //             subprocess.id)
        //             : this.deploymentFacade.routeToItem(
        //             itemType,
        //             deploymentId,
        //             subprocess.module_id,
        //             subprocess.process_id,
        //             subprocess.id
        //             );
        //         break;
        // }
    }

}
