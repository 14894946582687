import {
    Component,
    Input,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { PaginationData } from './simple-pagination.component.models';

@Component({
    selector: 'app-simple-pagination-container',
    templateUrl: './simple-pagination-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePaginationContainerComponent implements OnChanges {
    @Input() data: PaginationData[];
    @Input() activeValue: string;
    @Output() pageChange = new EventEmitter<string>();

    paginatorArray$ = new BehaviorSubject<PaginationData[]>([]);
    activePageIndex$ = new BehaviorSubject<number>(1);

    constructor() { }

    changePage({ index, value }: PaginationData): void {
        this.activePageIndex$.next(+index);
        this.pageChange.emit(value);
    }

    ngOnChanges(changes: SimpleChanges) {
        this.createArrayForPaginator();
        this.setActivePageIndex(changes);
    }

    private createArrayForPaginator(): void {
        const paginatorArray = [];
        const numberOfPages = (this.data || []).length;
        if (numberOfPages === 0) {
            return;
        }

        // If pages in paginator less then 5
        if (numberOfPages < 6) {
            for (let i = 0; i < numberOfPages; i++) {
                const { label, value } = this.data[i];
                paginatorArray.push(new PaginationData(paginatorArray.length, label, value));
            }
            this.paginatorArray$.next(paginatorArray);
            return;
        }

        const activePageIndex = this.activePageIndex$.getValue();
        let pageCutLow = activePageIndex - 1;
        let pageCutHigh = activePageIndex + 1;

        if (activePageIndex === 0) {
            pageCutHigh += 2;
        } else if (activePageIndex === 1) {
            pageCutHigh += 1;
        }

        // Determine how many pages to show before the current page index
        if (activePageIndex === numberOfPages) {
            pageCutLow -= 3;
        } else if (activePageIndex === (numberOfPages - 1)) {
            pageCutLow -= 1;
        }

        if (activePageIndex > 1) {
            const { value, label } = this.data[0];
            paginatorArray.push(new PaginationData(paginatorArray.length, label, value));

            if (activePageIndex > 2) {
                paginatorArray.push(new PaginationData(-1, '...', '...'));
            }
        }

        for (let i = pageCutLow; i <= pageCutHigh; i++) {
            if (i === -1) {
                i += 1;
            }

            if (i > (numberOfPages - 1)) {
                continue;
            }

            const { index, value, label } = this.data[i];
            const pageIndex = !!paginatorArray.find(page => page.value === '...')
                ? index
                : paginatorArray.length;
            paginatorArray.push(new PaginationData(pageIndex, label, value));
        }

        if (activePageIndex < (numberOfPages - 2)) {
            if (activePageIndex < (numberOfPages - 3)) {
                paginatorArray.push(new PaginationData(-1, '...', '...'));
            }
            const { index, value, label } = this.data[numberOfPages - 1];
            paginatorArray.push(new PaginationData(+index, label, value));
        }

        this.paginatorArray$.next(paginatorArray);
    }

    private setActivePageIndex(changes: SimpleChanges): void {
        const shouldSetValue = (changes.data || changes.activeValue)
            && this.data && this.activeValue;
        if (shouldSetValue) {
            const activePage = [...this.data || []].find(item => item.value === this.activeValue);
            const activePageIndex = !!activePage ? +activePage.index : 0;
            if (this.activePageIndex$.getValue() !== activePageIndex) {
                this.activePageIndex$.next(activePageIndex);
            }
        }
    }
}
