export class Navigation {
    navigation_object_name: string;
    icon: string;
    color: string;
    description: string;
    image_url: string;
    observer_node: any;
    sequence: number;
    user_node: string;
    key?: string;
}

export const testNavigation = <Navigation>{
    navigation_object_name: 'My ThinkTank',
    icon: 'apps',
    color: '477FAA',
    description: 'All of my TT stuff',
    image_url: 'https://firebasestorage.googleapis.com/v0/b/thinktank-hi-q-dev.appspot.com/...',
    observer_node: 'TBD',
    sequence: 1,
    user_node: 'apps'
};
