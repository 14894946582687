<div class="wrapper" *ngIf="!initialized">
  <mat-spinner [strokeWidth]="5" [diameter]="200"></mat-spinner>
</div>

<p *ngIf="initialized && !!errorMessage">{{ errorMessage }}</p>

<div class="session-join-link-page" *ngIf="initialized && !errorMessage">
  <mat-card class="sign-in-panel">
    <mat-card-title class="sign-in-panel__banner">
      <img
        class="logo-customizable"
        src="https://dpp0gtxikpq3y.cloudfront.net/20180208185136/images/us-west-2_l2vgM2KaJ/ALL/images/image.jpg"
      />
    </mat-card-title>
    <mat-card-content class="sign-in-panel__content">
      <!-- App has passkey -->
      <div *ngIf="hasPasskey">
        <mat-card-subtitle *ngIf="!inputPasskeyValid" class="section-title">
          Please enter a session password
        </mat-card-subtitle>

        <form [formGroup]="loginForm">
          <div *ngIf="!inputPasskeyValid">
            <div class="form-block">
              <label class="form-label required">Password</label>
              <input
                class="form-control"
                type="password"
                placeholder="Session Password"
                formControlName="password"
                [class.invalid]="isPasswordControlInvalid"
                (input)="onPasswordChange()"
              />
            </div>
            <mat-error *ngIf="isPasswordControlInvalid" class="error-message"> This field is required. </mat-error>
            <mat-error *ngIf="isPasswordInvalid" class="error-message">
              {{
                tooManyLoginFailures
                  ? "Too many login failures. Please try later."
                  : "Please enter the correct password for the session."
              }}
            </mat-error>
          </div>

          <div *ngIf="inputPasskeyValid">
            <div class="form-block">
              <label class="form-label required">First Name</label>
              <input
                class="form-control"
                type="text"
                placeholder="First Name"
                formControlName="firstName"
                [class.invalid]="isFirstNameInvalid"
              />
            </div>

            <mat-error *ngIf="isFirstNameInvalid" class="error-message"> This field is required. </mat-error>

            <div class="form-block">
              <label class="form-label required">Last Name</label>
              <input
                class="form-control"
                type="text"
                placeholder="Last Name"
                formControlName="lastName"
                [class.invalid]="isLastNameInvalid"
              />
            </div>

            <mat-error *ngIf="isLastNameInvalid" class="error-message"> This field is required. </mat-error>

            <ng-container *ngIf="emailRequired">
              <div class="form-block">
                <label class="form-label required">Email</label>
                <input
                  class="form-control"
                  type="email"
                  placeholder="Email"
                  formControlName="email"
                  [class.invalid]="isEmailInvalid"
                />
              </div>

              <mat-error *ngIf="isEmailInvalid && isControlValueEmpty('email')" class="error-message">
                This field is required.
              </mat-error>

              <mat-error *ngIf="isEmailInvalid && !isControlValueEmpty('email')" class="error-message">
                Please enter a valid email address.
              </mat-error>
            </ng-container>
          </div>
        </form>

        <ng-container *ngIf="!inputPasskeyValid">
          <div class="buttons">
            <button class="guest-button" [disabled]="pending" (click)="validatePassword()">
              Submit password
              <app-spinner *ngIf="pending" class="guest-button-spinner"></app-spinner>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="inputPasskeyValid">
          <div class="buttons" *ngIf="!existingUserView">
            <button class="guest-button" [disabled]="disableButtons || pending" (click)="signInAsGuest()">
              Join as a Guest
              <app-spinner *ngIf="pending" class="guest-button-spinner"></app-spinner>
            </button>
          </div>
        </ng-container>
      </div>

      <!-- No Passkey display -->
      <div *ngIf="!hasPasskey">
        Your leader did not enable a password for the session and should not have published this link. Please contact
        your leader to either invite via email or add a password.
      </div>
    </mat-card-content>
  </mat-card>
</div>
