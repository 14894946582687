import { createAction, props } from '@ngrx/store';

const getActivityFormsKeysType = '[Forms] get keys of activity forms';
const getActivityFormsKeysSuccessType = '[Forms] get keys of activity forms success';
const getActivityFormsKeysFailType = '[Forms] get keys of activity forms fail';

const getActivityFormKeyType = '[Forms] get key of activity form';
const getActivityFormKeySuccessType = '[Forms] get key of activity form success';
const getActivityFormKeyFailType = '[Forms] get key of activity form fail';

const getIsErrorsVisibleType = '[Forms] get errors visibility for form';


export const getActivityFormsKeys = createAction(
    getActivityFormsKeysType,
    props<{ activityKey: string }>()
);

export const getActivityFormsKeysSuccess = createAction(
    getActivityFormsKeysSuccessType,
    props<{ activityKey: string, formsKeys: string[] }>()
);

export const getActivityFormsKeysFail = createAction(
    getActivityFormsKeysFailType,
    props<{ errorMessage: string }>()
);

export const getActivityParentFormKey = createAction(
    getActivityFormKeyType,
    props<{ activityKey: string }>()
);

export const getActivityParentFormKeySuccess = createAction(
    getActivityFormKeySuccessType,
    props<{ activityKey: string, formKey: string }>()
);

export const getActivityParentFormKeyFail = createAction(
    getActivityFormKeyFailType,
    props<{ errorMessage: string }>()
);

export const getIsErrorsVisible = createAction(
    getIsErrorsVisibleType,
    props<{ activityKey: string, isFormActivitySubmitted: boolean }>()
);
