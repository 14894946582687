import { Members, Choice } from '@app/core/models';

export type responseType =
    | 'text'
    | 'slider'
    | 'star_voting'
    | 'grid'
    | 'long_answer'
    | 'number'
    | 'short_answer'
    | 'video'
    | 'file'
    | 'single'
    | 'multi'
    | 'image'
    | 'date'
    | 'subform' // section
    | 'attachment'
    | 'top_x';

export type answerAction = 'end_activity' | 'next_question' | 'answer_specific' | 'question_no' | '';

export class Question {
    key?: string;
    question_key?: string;
    attachment?: boolean;
    visible: boolean;
    answered?: boolean;
    upvotes: boolean;
    answer_action?: answerAction;
    navigate_to?: string;
    number_responses: number;
    owner: string;
    flagged: boolean;
    question: string;
    description?: string;
    response_type: responseType;
    choices?: { [choiseKey: string]: Choice };
    step?: number;
    required?: boolean;
    duration?: string;
    sequence: number;
    image_url?: string;
    vote_details?: {
        max: number;
        members: Members;
        user_allocations: { [userId: string]: number };
        users_finished: number;
    };

    grid_rows?: number;
    default_responses?: any;
    response_prompt?: string;
    comment_prompt?: string;
    comment_title?: string;
    demographics?: any;
    group?: string;
    groupQuestions?: any;
    response_type_icon?: string;
    stateChangeType?: string;
    video_url?: string;
    activity_id?: string;
    options?: any;
    user_id?: string;
    form_id?: string;
    subform_id?: string;
    section_name?: string;
    form_branching?: {
        question_id: string; // question selected to hide/show off of
        condition: any; // string housing the condition to toggle the hide show
        display: boolean; // hide/show flag (true if show)
        and_or?: boolean; // true if AND. Used by Single and Multi Select
        value?: any; // value field for numeric form branching (can contain numer or date)
    };

    top_number?: number;
    type?: string;
    choicesAsArray?: Choice[];
    likes?: any;
    workbook_sheet_id?: string;
    map_answer?: string;
    map_options_enable?: boolean;
    allow_to_abstain?: boolean;
    move_to_jira?: boolean;
    number_of_votes?: number;
}

export class NewQuestion {
    constructor(public question: string = '', public duration: string = '') {}
}

export const testQuestion = <Question>{
    number_responses: 2,
    form_id: 'formKey',
    owner: 'user1',
    flagged: false,
    question: 'What is happening?',
    response_type: 'text',
    duration: '3:00',
    sequence: 1,
    user_id: '',
};

export const defaultCrowdSourceQuestion: Question = <Question>{
    question: '',
    upvotes: true,
    visible: true,
};

export const defaultQuestion: Question = <Question>{
    question: '',
    answer_action: 'next_question',
    response_type: 'text',
    required: false,
    flagged: false,
    duration: '',
    step: 1,
    upvotes: true,
    visible: true,
};

export const testSlideQuestion = <Question>{
    attachment: false,
    number_responses: 2,
    owner: 'user1',
    flagged: false,
    question: 'What is happening?',
    response_type: 'text',
    responses: {
        response1: true,
        response2: true,
    },
    slideImage_url: 'test_url.com', // eslint-disable-line @typescript-eslint/naming-convention
    sequence: 1,
    answer_action: 'next_question',
    upvotes: true,
    visible: true,
};

export const testTextVoteQuestion = <Question>{
    answer_action: 'next_question',
    number_responses: 1,
    owner: 'user1',
    duration: '3:00',
    flagged: false,
    question: 'What do you want to hear?',
    required: false,
    response_type: 'text',
    sequence: 1,
    step: 1,
    upvotes: true,
    visible: true,
};

export const testStarVoteQuestion = <Question>{
    attachment: false,
    answer_action: 'next_question',
    number_responses: 1,
    owner: 'user1',
    duration: '3:00',
    flagged: false,
    choices: {
        star1: {
            value: 'low',
            description: 'lowest star button',
            sequence: 1,
            condition: 'next_question',
        },
        star2: {
            value: 'low_medium',
            description: 'low middle star button',
            sequence: 2,
            condition: 'next_question',
        },
        star3: {
            value: 'middle',
            description: 'middle star button',
            sequence: 3,
            condition: 'next_question',
        },
        star4: {
            value: 'high_middle',
            description: 'high middle star button',
            sequence: 4,
            condition: 'next_question',
        },
        star5: {
            value: 'high',
            description: 'highest star button',
            sequence: 5,
            condition: 'next_question',
        },
    },
    question: 'Rate your life',
    required: false,
    response_type: 'star_voting',
    sequence: 1,
    step: 1,
    upvotes: true,
    visible: true,
};

export const testSliderVoteQuestion = <Question>{
    attachment: false,
    answer_action: 'next_question',
    owner: 'user1',
    number_responses: 1,
    duration: '3:00',
    flagged: false,
    choices: {
        choice1: {
            value: 'low',
            description: 'low',
            sequence: 1,
            condition: 'next_question',
        },
        choice2: {
            value: 'high',
            description: 'high',
            sequence: 2,
            condition: 'next_question',
        },
    },
    question: 'How well do you feel',
    required: false,
    response_type: 'slider',
    sequence: 1,
    step: 1,
    upvotes: true,
    visible: true,
};

export const testSingleSelectVoteQuestion = <Question>{
    attachment: false,
    answer_action: 'next_question',
    owner: 'user1',
    number_responses: 1,
    duration: '3:00',
    flagged: false,
    choices: {
        choice1: {
            value: 'yes',
            description: 'yes',
            sequence: 1,
            condition: 'next_question',
        },
        choice2: {
            value: 'no',
            description: 'no',
            sequence: 2,
            condition: 'next_question',
        },
    },
    question: 'Are you happy?',
    required: false,
    response_type: 'single',
    sequence: 1,
    step: 1,
    upvotes: true,
    visible: true,
};

export const testMultiSelectVoteQuestion = <Question>{
    attachment: false,
    answer_action: 'next_question',
    owner: 'user1',
    number_responses: 1,
    duration: '3:00',
    flagged: false,
    choice1: {
        value: 'exciting',
        description: 'exciting',
        sequence: 1,
        condition: 'next_question',
    },
    choice2: {
        value: 'depressing',
        description: 'depressing',
        sequence: 2,
        condition: 'next_question',
    },
    question: 'Select the bands you like',
    required: false,
    response_type: 'multi',
    sequence: 1,
    step: 1,
    upvotes: true,
    visible: true,
};
