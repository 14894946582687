import { Component, Input, ChangeDetectionStrategy, OnInit, OnDestroy, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { trackById, Demographic } from '@thinktank/common-lib';
import { AssignmentFacade, AssignmentFacadeToken } from '../../../assignment/assignment.facade';

@UntilDestroy()
@Component({
    selector: 'gs-users-panel-tab',
    templateUrl: './users-panel-tab.component.html',
    styleUrls: ['./users-panel-tab.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UsersPanelTabComponent implements OnInit, OnDestroy {
    @Input() demographics: Demographic[];
    @Input() users: Observable<any>;
    @Input() isModule?: boolean;
    @Input() isGlobal?: boolean;
    @Input() isStakeholder?: boolean;
    @Input() deploymentId?: string;
    @Input() processHaveSomeGlobalSubprocesses?: boolean;

    usersData: any;
    trackById = trackById;

    constructor(
        @Inject(AssignmentFacadeToken) private facade: AssignmentFacade
    ) { }

    onUpdatePrimary(event: {primaryId: string, previousPrimaryId?: string}): void {
        this.facade.updateOwnershipPrimary(
            event.primaryId,
            event.previousPrimaryId,
            this.deploymentId
        );
    }

    getPrimaryUser(demographicId: string): string | boolean {
        const user = !!this.usersData[demographicId] && this.usersData[demographicId].filter(item => item.primary)[0];
        if (!user) {
            return false;
        }

        return !!user.first_name && !!user.last_name
            ? `${user.first_name} ${user.last_name}`
            : `${user.email_address}`;
    }

    ngOnInit() {
        this.users
        .pipe(untilDestroyed(this))
        .subscribe(usersData => {
            this.usersData = usersData;
        });
    }

    ngOnDestroy() { } // Need empty method for untilDestroyed
}
