import { Node } from './node.model';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export interface Project {
    key?: string;
    nodes: {
        [key: string]: Node
    };
    created?: firebase.firestore.Timestamp;
    updated?: firebase.firestore.Timestamp;
}
