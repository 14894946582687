<app-login-header></app-login-header>
<div
  class="login-wrapper"
  fxLayout="row"
  fxLayoutAlign="center center"
  appBackgroundImages
  [imagesList]="backgroundImages"
>
  <mat-card class="box">
    <mat-card-header>
      <img src="/assets/images/acn/tt_logo.png" alt="Accenture ThinkTank" class="logo" />
    </mat-card-header>

    <form class="signon-form" [formGroup]="registerForm" (ngSubmit)="register()">
      <mat-card-content>
        <mat-form-field class="signon-full-width">
          <input matInput placeholder="E-Mail" name="email" formControlName="email" required />
          <mat-error *ngIf="email.hasError('email') && !email.hasError('required')">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="email.hasError('required')"> Please enter your email </mat-error>
          <mat-error *ngIf="!email.hasError('email') && email.hasError('isAccenture')">
            Accenture users cannot register. Use the SSO login
          </mat-error>
        </mat-form-field>

        <mat-form-field class="signon-full-width">
          <input matInput placeholder="First Name" name="firstName" formControlName="firstName" required />
          <mat-error *ngIf="firstName.hasError('required')"> Please enter your Given Name </mat-error>
        </mat-form-field>

        <mat-form-field class="signon-full-width">
          <input matInput placeholder="Last Name" name="lastName" formControlName="lastName" required />
          <mat-error *ngIf="lastName.hasError('required')"> Please enter your Family Name </mat-error>
        </mat-form-field>

        <mat-form-field class="signon-full-width">
          <input
            matInput
            type="password"
            placeholder="Password"
            name="password"
            formControlName="password"
            [type]="hide ? 'password' : 'text'"
            required
          />
          <mat-icon matSuffix (click)="hide = !hide">
            {{ hide ? "visibility_off" : "visibility" }}
          </mat-icon>
        </mat-form-field>
        <ul *ngIf="password.dirty" class="change-password-validation">
          <li
            *ngFor="let passwordValidator of newUserPasswordValidationText"
            class="change-password-validation-text text"
            [ngClass]="{
              hasError: checkPasswordValidation(passwordValidator)
            }"
          >
            <mat-icon *ngIf="!password.hasError(passwordValidator.errorName) && password.value?.length">
              done
            </mat-icon>
            <mat-icon *ngIf="password.hasError(passwordValidator.errorName) || password.hasError('required')">
              close
            </mat-icon>
            {{ passwordValidator.validationText }}
          </li>
        </ul>
      </mat-card-content>
      <mat-error class="error-message">
        <span>{{ errorMessage }}</span>
      </mat-error>
      <button mat-stroked-button color="accent" class="btn-block" type="submit" [disabled]="!registerForm.valid">
        Register
      </button>
      <div *ngIf="verificationMessage" class="verification-message">
        {{ verificationMessage }}
      </div>
    </form>
  </mat-card>
</div>
