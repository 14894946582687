import {
    Input,
    Output,
    OnChanges,
    Component,
    EventEmitter,
    ChangeDetectionStrategy,
} from '@angular/core';

@Component({
    selector: 'gs-info-snackbar',
    templateUrl: './info-snackbar.component.html',
    styleUrls: ['./info-snackbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoSnackbarComponent implements OnChanges {
    @Input() isLoading?: boolean;
    @Input() isReady?: boolean;
    @Input() deploymentName: string;
    @Input() clientName: string;
    @Input() text: string;
    @Output() onClose: EventEmitter<void> = new EventEmitter();
    @Output() onSetDeployment: EventEmitter<void> = new EventEmitter();

    constructor() { }

    setDeployment(): void {
        if (this.isLoading) {
            return;
        }

        this.onSetDeployment.emit();
    }

    close(): void {
        this.onClose.emit();
    }

    ngOnChanges() {
    }

}
