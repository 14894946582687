export class UserEvent {
    firstName: string;
    lastName: string;
    email: string;
    image_url: string;
    full_name?: string;
    created_at?: number;
    key?: string;
}


export const testUserEvent = <UserEvent>{
    firstName: 'firstName',
    lastName: 'lastName',
    email: 'testname@gmail.com',
    image_url: 'test_image_url',
    full_name: 'Test Name',
    created_at: 1544189137754,
    key: 'testEvent'
};
