import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import {
    Module,
    Ownership,
    Process,
    Subprocess,
    Volunteer
} from '../../models';
import { ModuleViewFacade, ModuleViewFacadeToken } from './module-view-facade';
import { LoaderService } from '../../services/loader.service';
import { WindowService } from '@thinktank/common-lib';

@Component({
    selector: 'gs-module-view',
    templateUrl: './module-view.component.html',
    styleUrls: ['./module-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleViewComponent {
    vm$ = this.facade.vm$;

    activeTab = 0;

    constructor(
        @Inject(ModuleViewFacadeToken) private facade: ModuleViewFacade,
        private loaderService: LoaderService,
        private windowService: WindowService
    ) {}

    getFilteredOwnership(entireDeploymentOwnership: Ownership[], moduleId: string): Ownership[] {
        return (entireDeploymentOwnership || []).filter((ownershipObj) => ownershipObj.object_id === moduleId);
    }

    sendPulseForProcess(process: Process): void {
        console.log('SEND PULSE TO PEOPLE ASSOCIATED WITH:', process);
    }

    sendPulseForSubprocess(subprocess: Subprocess): void {
        console.log('SEND PULSE TO PEOPLE ASSOCIATED WITH:', subprocess);
    }

    navigateTo(data: {navigationItems: string[], queryParams: any}): void {
        this.facade.navigateTo(data);
    }

    navigateToEdit(deploymentId: string, moduleId: string): void {
        this.facade.routeToEdit(deploymentId, moduleId);
    }

    goBackToDeploymentView(deploymentId: string): void {
        this.facade.routeToDeployment(deploymentId);
    }

    goToAssignment(deploymentId: string, moduleObj: Module): void {
        this.facade.routeToAssignment(deploymentId, moduleObj.id);
    }

    volunteer(event: {volunteerDataObject: Volunteer, deploymentId: string}): void {
        this.facade.volunteer(event.volunteerDataObject, event.deploymentId);
    }

    unassignUser(ownershipToRemove: Ownership, deploymentId: string, moduleId: string): void {
        this.facade.unassignUser(ownershipToRemove, deploymentId, moduleId);
    }

    async exportWorkbook(deploymentId: string, moduleId: string): Promise<void> {
        this.loaderService.display(true);

        try {
            const result = await this.facade.exportWorkbook(deploymentId, moduleId);

            result.forEach(({ filename, downloadUrl }) => {
                this.windowService.open(downloadUrl);
            });
        } catch (error) {
            console.error(JSON.stringify(error));
        } finally {
            this.loaderService.display(false);
        }
    }
}
