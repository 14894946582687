import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { map, mergeMap, take, takeUntil } from 'rxjs/operators';

import { User, UserTemplateRole } from '@app/core/models';
import { appRoles, AppUser } from '@app/core/models/app.model';
import { FirebaseUsersService } from '@app/core/services';
import { FirebaseAppService } from '@app/core/services/app.service';
import { checkIsIeOrEdgeBrowser } from '@app/core/utils';
import { inviteSentSuccessfullyText } from '../invite/invite.constants';

/* eslint-disable */
const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */
// email validator
const emailValidator = (control: FormControl): { invalidEmail: boolean } | null => {
    const value = (control.value || '').trim();

    if (value && !EMAIL_REGEX.test(value)) {
        return { invalidEmail: true };
    }

    return null;
};

type SharingRole = 'collaborators' | 'sessionCreators';
@Component({
    selector: 'app-sharing-form',
    templateUrl: './sharing-form.component.html',
    styleUrls: ['./sharing-form.component.scss'],
})
export class SharingFormComponent implements OnInit, OnDestroy {
    appKey: string;
    appUrl: string;
    collaborators: any[];
    sessionCreators: any[];
    message: string;
    sharingForm: FormGroup;
    isInviteFromAdmin: boolean;
    queryParams: Params;
    inviteSentSuccessfullyText = inviteSentSuccessfullyText;
    formSubmitted: { collaborators?: boolean; sessionCreators?: boolean };
    emailExist: { collaborators?: boolean; sessionCreators?: boolean };
    emailEnter: { collaborators?: boolean; sessionCreators?: boolean };
    emailList: { collaborators: any[]; sessionCreators: any[] };
    appOwners: any[];
    separatorKeysCodes = [ENTER, COMMA];
    isNotificationActive = false;
    emailsErrors: {
        invalidEmails: any[];
        emailsAlreadyExistInApp: {
            collaborators: any[];
            sessionCreators: any[];
        };
        emailsAlreadyExistInSession: {
            collaborators: any[];
            sessionCreators: any[];
        };
    };

    emailsErrorMessages: {
        collaborators: string;
        sessionCreators: string;
    };

    userRoles = <UserTemplateRole[]>['Owner', 'Collaborator', 'Session creator'];

    isIeOrEdgeBrowser: boolean;

    users$: Observable<User[]>;
    templateName$: Observable<string>;

    private destroySubject: Subject<void>;

    constructor(
        private route: ActivatedRoute,
        public router: Router,
        private location: Location,
        private appService: FirebaseAppService,
        private usersService: FirebaseUsersService,
        @Inject('Window') private window: Window,
    ) {
        this.formSubmitted = {};
        this.destroySubject = new Subject();
        this.message = '';
        this.emailExist = {};
        this.emailEnter = {};
        // Detecting Internet Explorer or Edge browsers
        this.isIeOrEdgeBrowser = checkIsIeOrEdgeBrowser();
    }

    add(event: MatChipInputEvent, controlName: SharingRole): void {
        const input = event.input;
        let value = (event.value || '').trim();
        const control: AbstractControl = this.sharingForm.controls[controlName];

        if (value) {
            // Remove all commas and empty spaces
            // Lowercase force
            value = value.replace(/,/g, ' ').replace(/(\s+)/g, ' ');
            value = value.toLowerCase();
            input.value = value;
            const emailValues = value.split(' ');

            this.formSubmitted[controlName] = true;
            this.emailsErrorMessages[controlName] = '';

            emailValues.forEach(emailValue => {
                emailValue = emailValue.trim();

                // check if user already exist
                this.emailExist[controlName] = this.appOwners.some(entry => {
                    if (emailValue === entry) {
                        this.emailsErrors.emailsAlreadyExistInApp[controlName].push(emailValue);
                    }

                    return emailValue === entry;
                });

                // check if user already exist in current session
                this.emailEnter[controlName] = [
                    ...this.emailList['collaborators'],
                    ...this.emailList['sessionCreators'],
                ].some(entry => {
                    if (emailValue === entry.email) {
                        this.emailsErrors.emailsAlreadyExistInSession[controlName].push(emailValue);
                    }

                    return emailValue === entry.email;
                });

                // check email
                const invalidEmail = !emailValue.match(EMAIL_REGEX);

                if (invalidEmail) {
                    this.emailsErrors.invalidEmails.push(emailValue);
                }
                control.setErrors({ invalidEmail });

                if (
                    !this.emailExist[controlName] &&
                    !this.emailEnter[controlName] &&
                    !control.hasError('invalidEmail')
                ) {
                    // push to the chips list
                    this[controlName].push({
                        email: emailValue,
                    });

                    // push to the main list
                    this.emailList[controlName].push({
                        email: emailValue,
                        username: emailValue,
                        created_at: Date.now(),
                        role: controlName === 'collaborators' ? 'leader' : 'launch-only',
                    });

                    input.value = input.value.replace(emailValue, '').replace(/,/g, ' ').replace(/(\s+)/g, ' ');
                }
            });

            const invalidEmails = this.emailsErrors.invalidEmails;
            const emailsExistInApp = this.emailsErrors.emailsAlreadyExistInApp[controlName];
            const emailsExistInSession = this.emailsErrors.emailsAlreadyExistInSession[controlName];

            let messageText: string;

            const errorsGroup = {
                invalidEmails: {
                    errorsPackage: invalidEmails,
                    errorsName: 'invalidEmails',
                },
                emailsExistInApp: {
                    errorsPackage: emailsExistInApp,
                    errorsName: 'emailsExistInApp',
                },
                emailsExistInSession: {
                    errorsPackage: emailsExistInSession,
                    errorsName: 'emailsExistInSession',
                },
            };

            const filteredGroups = Object.keys(errorsGroup).filter(key => {
                return errorsGroup[key].errorsPackage.length > 0;
            });

            const matchGroup = filteredGroups.length > 1 ? 'multiple' : filteredGroups[0];

            switch (matchGroup) {
                case errorsGroup.invalidEmails.errorsName:
                    messageText =
                        invalidEmails.length > 1
                            ? 'Please enter valid email addresses.'
                            : 'Please enter a valid email address.';

                    this.showMessageError(control, controlName, true, false, false, messageText);
                    break;
                case errorsGroup.emailsExistInApp.errorsName:
                    messageText =
                        emailsExistInApp.length > 1
                            ? 'Users with such email address are already template attendees.'
                            : 'User with this email address is already a template attendee.';

                    this.showMessageError(control, controlName, false, true, false, messageText);
                    break;
                case errorsGroup.emailsExistInSession.errorsName:
                    messageText =
                        emailsExistInSession.length > 1
                            ? 'Users with such email address are already invited with other roles'
                            : 'User with this email address is already invited with another role.';

                    this.showMessageError(control, controlName, false, false, true, messageText);
                    break;
                case 'multiple':
                    messageText = 'Please enter valid email addresses.';

                    this.showMessageError(control, controlName, true, false, false, messageText);
                    break;
            }

            this.resetEmailsErrors();
        }
    }

    cancel(): void {
        this.resetForm();

        if (this.isInviteFromAdmin) {
            this.router.navigate(['administration', 'manage'], {
                queryParams: { ...this.queryParams },
            });
        } else {
            this.location.back();
        }
    }

    invite(): void {
        const invites = [...this.emailList['collaborators'], ...this.emailList['sessionCreators']];

        this.appService.handleUsersInApp(invites, this.appKey, this.appUrl, this.sharingForm.controls.message.value);
        if (this.isInviteFromAdmin) {
            this.isNotificationActive = true;

            setTimeout(() => {
                this.closeNotification();
            }, 10000);
        }

        this.resetForm();
    }

    closeNotification(): void {
        this.isNotificationActive = false;
    }

    get inviteDisabled(): boolean {
        return !this.collaborators.length && !this.sessionCreators.length;
    }

    remove(value: any, controlName: SharingRole): void {
        this.emailList[controlName] = this.emailList[controlName].filter(entry => {
            return entry.email !== value.email;
        });
        const index = this[controlName].indexOf(value);

        if (index > -1) {
            this[controlName].splice(index, 1);
        }
    }

    updateSubmitState(controlName: SharingRole): void {
        this.formSubmitted[controlName] = false;
        this.emailsErrorMessages[controlName] = '';
    }

    ngOnInit() {
        this.sharingForm = new FormGroup({
            collaborators: new FormControl(this.collaborators, Validators.compose([emailValidator])),
            sessionCreators: new FormControl(this.sessionCreators, Validators.compose([emailValidator])),
            message: new FormControl(this.message),
        });
        this.collaborators = [];
        this.sessionCreators = [];
        this.emailList = { collaborators: [], sessionCreators: [] };
        this.emailsErrorMessages = {
            collaborators: '',
            sessionCreators: '',
        };
        this.resetEmailsErrors();
        this.appOwners = [];

        this.appUrl = window.location.href.substring(0, window.location.href.lastIndexOf('/'));
        this.isInviteFromAdmin = this.router.url.split('/')[1] === 'administration' ? true : false; // the first parameter

        this.route.params
            .pipe(
                mergeMap((params: Params) => {
                    this.appKey = params['appKey'];

                    return this.appService.getAppUsers(this.appKey).pipe(takeUntil(this.destroySubject));
                }),
            )
            .subscribe((appUsers: AppUser[]) => {
                this.appOwners = appUsers.map(user => user.username);
            });

        this.users$ = this.usersService.getAppUserList(this.appKey).pipe(
            map(users =>
                users
                    .filter(user => user.key)
                    .map(user => {
                        return {
                            key: user.key,
                            firstName: user.firstName || '',
                            lastName: user.lastName || '',
                            email: user.email,
                            app_role: appRoles?.[user?.apps?.[this.appKey]],
                            last_login: user.last_login,
                        };
                    }),
            ),
        );

        this.templateName$ = this.appService.getAppField(this.appKey, 'name');

        this.route.queryParams.pipe(take(1)).subscribe(queryParams => {
            this.queryParams = queryParams;
        });
    }

    ngOnDestroy(): void {
        this.destroySubject.next();
        this.destroySubject.complete();
    }

    private clearEmailErrors(): void {
        this.formSubmitted = {};
        this.emailExist = {};
    }

    private resetForm(): void {
        this.clearEmailErrors();
        this.resetEmailsErrors();
        this.collaborators.length = 0;
        this.sessionCreators.length = 0;
        this.emailList = { collaborators: [], sessionCreators: [] };
        this.sharingForm.reset();
        this.sharingForm.markAsTouched();

        let control: AbstractControl = null;

        Object.keys(this.sharingForm.controls).forEach(name => {
            control = this.sharingForm.controls[name];
            control.markAsTouched();
            control.markAsPristine();
            control.setErrors(null);
        });
    }

    private resetEmailsErrors(): void {
        this.emailsErrors = {
            invalidEmails: [],
            emailsAlreadyExistInApp: {
                collaborators: [],
                sessionCreators: [],
            },
            emailsAlreadyExistInSession: {
                collaborators: [],
                sessionCreators: [],
            },
        };
    }

    private showMessageError(
        control: AbstractControl,
        controlName: string,
        isEmailInvalid: boolean,
        isEmailExistInApp: boolean,
        isEmailExistInSession: boolean,
        text: string,
    ): void {
        control.setErrors({ invalidEmail: isEmailInvalid });
        this.emailExist[controlName] = isEmailExistInApp;
        this.emailEnter[controlName] = isEmailExistInSession;
        this.emailsErrorMessages[controlName] = text;
    }
}
