import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { FormGroupHandlerFactory } from '../form-group-handlers/form-group-handler.factory';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';
import { FormState } from '../../../models';
import {
    Demographic,
    Form,
    FormItem,
    FormItemType,
    FormQuestion,
    FormQuestionWithDemographicId,
    FormSection,
    FormStatus,
    FormStatusModel,
    getFormQuestionLabelValue,
    RepeatedSection,
    RepeatedSectionAddInfo,
    RepeatedSectionDeleteInfo,
    trackById,
    DialogService
} from '@thinktank/common-lib';
import { ChangeRequestService, ChangeRequestServiceToken } from '../../../services/change-request.service';


@Component({
    selector: 'gs-dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DynamicFormComponent {
    vm$ = this.formFacade.vm$
        .pipe(
            tap((viewModel) => {
                this.buildOrUpdateForm(viewModel);
            })
        );

    form: FormGroup;
    visibleItems: FormItem[];
    FormItemType = FormItemType;
    trackById = trackById;

    constructor(
        private formBuilder: FormBuilder,
        private formGroupHandlerFactory: FormGroupHandlerFactory,
        @Inject(DeploymentFormFacadeToken) private formFacade: DeploymentFormFacade,
        private dialogService: DialogService,
        @Inject(ChangeRequestServiceToken) private changeRequestService: ChangeRequestService
    ) {
        this.form = this.formBuilder.group({}, { updateOn: 'blur' });
    }

    shouldDisableChangeRequestButton(question: FormQuestion, formState: FormState): boolean {
        let returnVal = false;

        (formState.changeRequests || []).forEach((changeRequest) => {
            if (changeRequest.requesting_user_id === formState.authenticatedUserId &&
                changeRequest.question_id === question.id
            ) {
                returnVal = true;
            }
        });

        return returnVal;
    }

    isFormEmpty(formItems: FormItem[]): boolean {
        return !!formItems && formItems.length === 0
            || this.visibleItems.length === 0;
    }

    isFormSubmitted(formState: FormState): boolean {
        let returnVal = false;
        if (!!formState.form && !!formState.form.status) {
            if (this.isGlobalForm(formState.form)) {
                returnVal = formState.form.status['global'] === FormStatus.Approved;
            } else if ((formState.assignedDemographics || []).length > 0) {
                formState.assignedDemographics.forEach((demographic) => {
                    returnVal = formState.form.status[demographic.id] === FormStatus.Approved;
                });
            }
        }

        return returnVal;
    }

    isGlobalForm(form: Form): boolean {
        return !!form.global;
    }

    getQuestionLabel(question: FormQuestion): string {
        return getFormQuestionLabelValue(question.label);
    }

    numberOfChangeRequests(question: FormQuestion, formState: FormState): Number {
        const questionChangeRequestArray = (formState.changeRequests || []).filter((changeRequest) => {
            return changeRequest.question_id === question.id;
        });
        return questionChangeRequestArray.length;
    }

    requestChange(
        changeRequestEventObject: {question: FormQuestion, demographic?: Demographic, repeatedSectionId?: string},
        isSection: boolean,
        formState: FormState
    ): void {
        const isGlobal = formState.form.global;
        this.dialogService.open('ChangeRequestDialogComponent', {
            isGlobal: isGlobal,
            isSection: isSection,
            repeatedSectionId: changeRequestEventObject.repeatedSectionId,
            initialDemographic: changeRequestEventObject.demographic,
            demographics: formState.assignedDemographics,
            question: changeRequestEventObject.question,
            handleConfirm: (changeRequestDialogResponse: any) => {
                this.changeRequestService.createChangeRequest(
                    changeRequestDialogResponse.requestedResponse,
                    changeRequestEventObject.question,
                    changeRequestDialogResponse.seletedDemographics,
                    formState.form.id,
                    formState.form.parent_id,
                    formState.processId,
                    formState.moduleId,
                    formState.deploymentId
                );
            }
        });
    }

    isFormItemRequired(question: FormQuestion, formState: FormState): boolean {
        return (
            formState.form.global
                ? !!question.required['global']
                : (formState.assignedDemographics || []).some((demographic) => !!question.required[demographic.id])
        ) || !!question.required['default'];
    }

    questionVisible(question: FormQuestion): boolean {
        return Object.values(question.visible).some((value) => value);
    }

    getItemFormGroup(formGroupId: string, isSection?: boolean): FormGroup {
        return (this.isGlobalForm && !isSection) ? this.form : this.form.get(formGroupId) as FormGroup;
    }

    onAddRepeatedSection(sectionInfo: RepeatedSectionAddInfo, deploymentId: string): void {
        const repeatedSectionBuilder = this.formGroupHandlerFactory.repeatedSectionBuilder();
        const sectionFormGroupPath = `${sectionInfo.section.id}.${sectionInfo.demographic.id}`;
        const sectionFormGroup = this.form.get(sectionFormGroupPath) as FormGroup;
        const sectionId = repeatedSectionBuilder.getSectionId();
        sectionFormGroup.addControl(sectionId, repeatedSectionBuilder.build(sectionInfo));
        this.formFacade.addRepeatedSection({ ...sectionInfo, id: sectionId }, deploymentId);
    }

    onDeleteRepeatedSection(sectionInfo: RepeatedSectionDeleteInfo, deploymentId: string): void {
        this.formFacade.deleteRepeatedSection(sectionInfo, deploymentId);
    }

    navigateToFormView(viewName: string, formState: FormState): void {
        const formId = (formState.form.id).split(/_(.+)/)[1];
        this.formFacade.navigateToLocation([
            'deployment',
            formState.deploymentId,
            'form',
            formId,
            viewName
        ]);
    }

    onSubmit(formState: FormState): void {
        const status = {} as FormStatusModel;

        if (formState.form.global) {
            status['global'] = FormStatus.Approved;
        } else {
            formState.assignedDemographics.forEach(demographic => {
                status[demographic.id] = FormStatus.Approved;
            });
        }
        const questionsWithDefaultResponse = this.findQuestionsWithDefaultResponse(formState);
        this.formFacade.submitForm({
            status,
            questionsWithDefaultResponse,
            deploymentId: formState.deploymentId,
            formId: formState.form.id
        });
    }

    private itemVisible(item: FormItem): boolean {
        return Object.values(item.visible).some((value) => value);
    }

    private hasQuestionsAndChosenDemographics(formState: FormState): boolean {
        return formState.form.items.length > 0 && formState.assignedDemographics.length > 0;
    }

    private buildOrUpdateForm(formState: FormState): void {
        // TODO: figure out how to deal with changes coming back from the store that could cause a screen refresh and rebuild the form
        if (this.hasQuestionsAndChosenDemographics(formState)) {
            const formExists = this.formExists;
            formState.form.items.forEach((item) => {
                const formControlHandler = this.formGroupHandlerFactory.build(item.type, this.isGlobalForm(formState.form));
                if (formExists) {
                    formControlHandler.update(this.form, item, formState.assignedDemographics);
                } else {
                    const formControl = formControlHandler.build(item, formState.assignedDemographics);
                    this.form.addControl(item.id, formControl);
                }
            });
            this.visibleItems = formState.form.items.filter(this.itemVisible);
        }

        if (this.isFormSubmitted(formState)) {
            this.form.disable();
        }
    }

    private get formExists(): boolean {
        return Object.keys(this.form.controls).length > 0;
    }

    private findQuestionsWithDefaultResponse(formState: FormState): FormQuestionWithDemographicId[] {
        const questionsWithDefaultResponse = [];
        formState.form.items.forEach((item) => {
            formState.assignedDemographics.forEach((demographic) => {
                if (item.type === FormItemType.Section) {
                    const section = item as FormSection;
                    const sectionQuestionsWithDefaultResponse = this.sectionQuestionsWithDefaultResponse(section, demographic.id);
                    questionsWithDefaultResponse.push(...sectionQuestionsWithDefaultResponse);
                }
                const question = item as FormQuestion;
                if (!!question.default_response && !question.response[demographic.id]) {
                    questionsWithDefaultResponse.push({ question, demographicId: demographic.id });
                }
            });
        });
        return questionsWithDefaultResponse;
    }

    private sectionQuestionsWithDefaultResponse(section: FormSection, demographicId: string): any[] {
        const questionsWithDefaultResponse = [];
        section.questionsForView.forEach((sectionQuestion) => {
            if (!!sectionQuestion.default_response) {
                const allSectionIds = [ section.id, ...this.getRepeatedSectionIds(section.repeated_sections, demographicId)];
                const sectionQuestionsWithDefaultResponse = allSectionIds.reduce((accumulator, sectionId) => {
                    const responseId = `${demographicId}_${sectionId}`;
                    if (!sectionQuestion.response[responseId]) {
                        accumulator.push({ question: sectionQuestion, demographicId, sectionResponseId: sectionId });
                    }
                    return accumulator;
                }, []);
                questionsWithDefaultResponse.push(...sectionQuestionsWithDefaultResponse);
            }
        });
        return questionsWithDefaultResponse;
    }

    private getRepeatedSectionIds(repeatedSection: RepeatedSection, demographicId: string): string[] {
        const repeatedSectionIds = new Set();
        const repeatedSectionsByDemographic = repeatedSection[demographicId];
        Object.keys(repeatedSectionsByDemographic).forEach((repeatedSectionId) => repeatedSectionIds.add(repeatedSectionId));
        return <string[]>Array.from(repeatedSectionIds);
    }

}
