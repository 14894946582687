import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { take } from 'rxjs/operators';

import { environment } from '@env/environment';
import { LoginService } from '@app/login/services/login.service';
import { AuthenticatedUserService, FirebaseUsersService } from '@app/core/services';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    private readonly redirectKey = 'redirectTo';
    private readonly loginStateKey = 'loginStateKey';

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loginService: LoginService,
        @Inject('Window') private window: Window,
        private authService: AuthenticatedUserService,
        private usersService: FirebaseUsersService
    ) { }

    ngOnInit() {
        const routeSnapshot = this.route.snapshot;
        const queryParams = routeSnapshot.queryParamMap;
        const code = queryParams.get('code');
        const receivedStateKey = queryParams.get('state');
        const clientStateKey = localStorage.getItem(this.loginStateKey);

        if (!!code && receivedStateKey === clientStateKey) {
            localStorage.removeItem(this.loginStateKey);

            // Complete the login process and route to home
            this.loginService.finishLogin(window.location.href)
                .pipe(take(1)) // to avoid possible unfinished observables in the login service
                .subscribe(
                    () => {
                        if (localStorage.getItem('app_instance_id')) {
                            // get the app key
                            const appKey = localStorage.getItem('app_instance_id');
                            // remove the stored key from local storage
                            localStorage.removeItem('app_instance_id');
                            // add user to roster of app
                            this.usersService.addUserToInstance(this.authService.getCurrentUserId(), appKey);
                            // route to app
                            this.router.navigate(['/instance', appKey]);
                        } else {
                            const redirectTo = localStorage.getItem(this.redirectKey);
                            localStorage.removeItem(this.redirectKey);

                            if (redirectTo) {
                                this.router.navigateByUrl(redirectTo);
                            } else {
                                this.router.navigate(['/navigation']);
                            }
                        }
                    },
                    (error) => {

                        if (error.code === 'auth/user-disabled') {
                            this.authService.navigateToForbiddenPage();
                        } else {

                            // TODO: we need to figure out what to do in this case...
                            console.log(error);
                            this.router.navigate(['/']);
                        }
                    }
                );
        } else {
            // Send the user to Cognito saving the requested url (if exists) before going
            const redirectTo = queryParams.get(this.redirectKey);
            const loginStatekey = this.loginService.generateLoginStateKey();

            if (redirectTo) {
                localStorage.setItem(this.redirectKey, decodeURIComponent(redirectTo));
            }
            this.window.location.href = `${environment.firebase.cloudFunctionsURL}/forwardToCognito?state=${loginStatekey}`;
            localStorage.setItem(this.loginStateKey, loginStatekey);
        }
    }
}
