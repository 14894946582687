import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { ModuleEditorFacade, ModuleEditorFacadeToken } from './module-editor-facade';
import { ObjectType } from '../../models';

@Component({
    selector: 'gs-module-editor',
    templateUrl: './module-editor.component.html',
    styleUrls: ['./module-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModuleEditorComponent {
    vm$ = this.facade.vm$;
    objectType = ObjectType.Module;

    constructor(@Inject(ModuleEditorFacadeToken) private facade: ModuleEditorFacade) {}

}
