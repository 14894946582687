<div [formGroup]="parentForm">
  <mat-form-field
    appearance="outline"
    floatLabel="always"
    [ngClass]="{'without-label': isSectionQuestion}"
  >
    <mat-label *ngIf="!isSectionQuestion">{{ demographic.name }}</mat-label>
    <ng-container>
      <input
        matInput
        [type]="'number'"
        placeholder="Enter a Value"
        [formControlName]="'formControlName'"
      />
    </ng-container>
    <mat-error *ngIf="questionHasMinOrMaxError()">
      Please enter a number between {{question.numeric_range.min}} and {{question.numeric_range.max}}.
    </mat-error>
  </mat-form-field>
</div>
