import { ENTER } from '@angular/cdk/keycodes';
import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Params, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
    clientAndProjectNameTitle,
    projectNameForSessionText,
} from '@app/apps/agenda/activity-card/activity-card.component.constants';
import { DialogService, FeatureToggleService, FirebaseAppService } from '@app/core/services';
import { checkIsIeOrEdgeBrowser } from '@app/core/utils';

/* eslint-disable */
const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */

// email validator
export function emailValidator(control: FormControl): { invalidEmail: boolean } | null {
    const value = (control.value || '').trim();

    if (value && !EMAIL_REGEX.test(value)) {
        return { invalidEmail: true };
    } else {
        return null;
    }
}
// eslint-disable-next-line
const PASSKEY_REGEX = /^[a-zA-Z0-9._!]([a-zA-Z0-9._! @+=-]){3,99}$/;

// passkey validator
export function passkeyValidator(control: FormControl): { invalidPasskey: boolean } | null {
    const value = (control.value || '').trim();

    if ((value && !PASSKEY_REGEX.test(value)) || value.length > 100 || value.length < 4) {
        return { invalidPasskey: true };
    } else {
        return null;
    }
}

@Component({
    selector: 'app-invite-form',
    templateUrl: './invite-form.component.html',
    styleUrls: ['./invite-form.component.scss'],
})
export class InviteFormComponent implements OnInit, OnDestroy {
    @Input() instanceUsers: any[];
    @Input() users: any[];
    @Input() public addUsers: (usersList: any, message?: string) => void;
    @Input() cancelAction: () => void;
    @Input() isInviteFromAdmin: boolean;
    @Input() queryParams: Params;
    inviteForm: FormGroup;

    participants: { email: any }[];
    observers: { email: any }[];
    leaders: { email: any }[];
    emailList: { email: any; username: any; created_at: any; role: any }[];
    emailExist: { participants: boolean; observers: boolean; leaders: boolean };
    emailEnter: { participants: boolean; observers: boolean; leaders: boolean };
    rolesMapping: any;
    formSubmitted: { participant: boolean; observer: boolean; leader: boolean };
    separatorKeysCodes = [ENTER];
    isIeOrEdgeBrowser: boolean;

    url: string;
    passkeyEnabled: boolean;
    emailRequired: boolean;
    passkey: string;
    passkeyHidden: boolean;
    appKey: string;
    passkeyGUID: string;
    emailsErrors: {
        invalidEmails: any[];
        emailsAlreadyExistInApp: {
            participants: any[];
            observers: any[];
            leaders: any[];
        };
        emailsAlreadyExistInSession: {
            participants: any[];
            observers: any[];
            leaders: any[];
        };
    };

    emailsErrorMessages: {
        participants: string;
        observers: string;
        leaders: string;
    };

    joinSessionLinkEnabled: boolean;
    requiredProjectName: boolean;
    projectName: string;

    private onDestroy: Subject<void>;

    constructor(
        public fb: FormBuilder,
        private router: Router,
        private fbAppService: FirebaseAppService,
        private dialogService: DialogService,
        public location: Location,
        private featureToggleService: FeatureToggleService,
    ) {
        this.joinSessionLinkEnabled = true;
        this.passkey = '';
        this.passkeyHidden = true;
        this.onDestroy = new Subject();

        const parsedURL = router.url.split('/');

        this.appKey = parsedURL[2];

        // Detecting Internet Explorer or Edge browsers
        this.isIeOrEdgeBrowser = checkIsIeOrEdgeBrowser();
    }

    updatePasskey(event: any): void {
        if (event.srcElement) {
            this.passkey = event.srcElement.value;
        }

        if (event.target) {
            this.passkey = event.target.value;
        }

        let passKeyForDB = null;

        if (!this.passkeyInvalid()) {
            passKeyForDB = this.passkey;
        }

        this.fbAppService.updateAppField(this.appKey, 'passkey', passKeyForDB);
    }

    emailRequiredToggle(): void {
        this.emailRequired = !this.emailRequired;
        this.fbAppService.updateAppField(this.appKey, 'email_required', this.emailRequired);
    }

    passkeyInvalid(): boolean {
        return (
            (!this.inviteForm.controls['passkeyControl'].pristine &&
                this.inviteForm.controls['passkeyControl'].hasError('invalidPasskey')) ||
            (this.inviteForm.controls['passkeyControl'].touched && // touched was placed instead dirty because of IE bug
                this.inviteForm.controls['passkeyControl'].hasError('invalidPasskey'))
        );
    }

    copyURL(): void {
        if (this.passkeyInvalid()) {
            this.dialogService.open('AlertDialogComponent', {
                title: 'Invalid Password',
                text: 'A valid password is required to copy the session link.',
                confirmBtnText: 'OK',
            });

            return;
        }

        const el = document.createElement('textarea');

        el.value = this.url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    }

    togglePasskey(): void {
        this.passkeyEnabled = !this.passkeyEnabled;
        this.fbAppService.updateAppField(this.appKey, 'use_passkey', this.passkeyEnabled);
    }

    get inviteDisabled(): boolean {
        return !this.participants.length && !this.observers.length && !this.leaders.length;
    }

    pushToEmailList(emailValue: any, role: any): void {
        this.emailList.push({
            email: emailValue,
            username: emailValue,
            created_at: Date.now(),
            role: role,
        });
    }

    // clear errors
    clearEmailErrors(): void {
        this.formSubmitted = { participant: false, observer: false, leader: false };
        this.emailExist = { participants: false, observers: false, leaders: false };
        this.emailEnter = { participants: false, observers: false, leaders: false };
    }

    // add action
    add(event: MatChipInputEvent, role: string): void {
        const input = event.input;
        let value = (event.value || '').trim();
        const control: AbstractControl = this.inviteForm.controls[`${role}s`];

        if (value) {
            // Remove all commas and empty spaces
            // Lowercase force
            value = value.replace(/,/g, ' ').replace(/(\s+)/g, ' ');
            value = value.toLowerCase();
            input.value = value;
            const emailValues = value.split(' ');

            this.formSubmitted[role] = true;
            this.emailsErrorMessages[`${role}s`] = '';

            emailValues.forEach(emailValue => {
                emailValue = emailValue.trim();

                // check if user already exist
                this.emailExist[`${role}s`] = this.instanceUsers.some(entry => {
                    if (emailValue === entry) {
                        this.emailsErrors.emailsAlreadyExistInApp[`${role}s`].push(emailValue);
                    }

                    return emailValue === entry;
                });

                // check if user already exist in current session
                this.emailEnter[`${role}s`] = this.emailList.some(entry => {
                    if (emailValue === entry.email) {
                        this.emailsErrors.emailsAlreadyExistInSession[`${role}s`].push(emailValue);
                    }

                    return emailValue === entry.email;
                });

                // check email
                const invalidEmail = !emailValue.match(EMAIL_REGEX);

                if (invalidEmail) {
                    this.emailsErrors.invalidEmails.push(emailValue);
                }
                control.setErrors({ invalidEmail });

                const allowSetEmail =
                    !this.emailExist[`${role}s`] && !this.emailEnter[`${role}s`] && !control.hasError('invalidEmail');

                if (allowSetEmail) {
                    // push to the chips list
                    this.rolesMapping[role].push({
                        email: emailValue,
                    });

                    // push to the main list
                    this.pushToEmailList(emailValue, role);
                    input.value = input.value.replace(emailValue, '').replace(/,/g, ' ').replace(/(\s+)/g, ' ');
                }
            });

            const invalidEmails = this.emailsErrors.invalidEmails;
            const emailsExistInApp = this.emailsErrors.emailsAlreadyExistInApp[`${role}s`];
            const emailsExistInSession = this.emailsErrors.emailsAlreadyExistInSession[`${role}s`];

            let messageText: string;

            const errorsGroup = {
                invalidEmails: {
                    errorsPackage: invalidEmails,
                    errorsName: 'invalidEmails',
                },
                emailsExistInApp: {
                    errorsPackage: emailsExistInApp,
                    errorsName: 'emailsExistInApp',
                },
                emailsExistInSession: {
                    errorsPackage: emailsExistInSession,
                    errorsName: 'emailsExistInSession',
                },
            };

            const filteredGroups = Object.keys(errorsGroup).filter(key => {
                return errorsGroup[key].errorsPackage.length > 0;
            });

            const matchGroup = filteredGroups.length > 1 ? 'multiple' : filteredGroups[0];

            switch (matchGroup) {
                case errorsGroup.invalidEmails.errorsName:
                    messageText =
                        invalidEmails.length > 1
                            ? 'Please enter valid email addresses.'
                            : 'Please enter a valid email address.';

                    this.showMessageError(control, `${role}s`, true, false, false, messageText);
                    break;
                case errorsGroup.emailsExistInApp.errorsName:
                    messageText =
                        emailsExistInApp.length > 1
                            ? 'Users with such email address are already session attendees.'
                            : 'User with this email address is already a session attendee.';

                    this.showMessageError(control, `${role}s`, false, true, false, messageText);
                    break;
                case errorsGroup.emailsExistInSession.errorsName:
                    messageText =
                        emailsExistInSession.length > 1
                            ? 'Users with such email address are already invited with other roles'
                            : 'User with this email address is already invited with another role.';

                    this.showMessageError(control, `${role}s`, false, false, true, messageText);
                    break;
                case 'multiple':
                    messageText = 'Please enter valid email addresses.';

                    this.showMessageError(control, `${role}s`, true, false, false, messageText);
                    break;
            }

            this.resetEmailsErrors();
        }
    }

    updateSubmitState(role: string) {
        this.formSubmitted[role] = false;
        this.emailsErrorMessages[`${role}s`] = '';
    }

    remove(value: any, role: string): void {
        // remove from main list
        this.emailList = this.emailList.filter(obj => obj.email !== value.email);

        // remove from from chips list
        const index = this.rolesMapping[role].indexOf(value);

        if (index >= 0) {
            this.rolesMapping[role].splice(index, 1);
        }
    }

    resetForm(event?: MouseEvent): void {
        this.clearEmailErrors();
        this.resetEmailsErrors();
        this.participants.splice(0, this.participants.length);
        this.observers.splice(0, this.observers.length);
        this.leaders.splice(0, this.leaders.length);
        this.emailList = [];
        this.inviteForm.reset();
        this.inviteForm.markAsTouched();

        let control: AbstractControl = null;

        Object.keys(this.inviteForm.controls).forEach(name => {
            control = this.inviteForm.controls[name];
            control.markAsTouched();
            control.markAsPristine();
            control.setErrors(null);
        });

        if (this.isInviteFromAdmin && event) {
            this.router.navigate(['administration', 'manage'], {
                queryParams: { ...this.queryParams },
            });
        } else if (event) {
            this.location.back();
        }
    }

    invite(): void {
        if (this.requiredProjectName && !this.projectName) {
            this.dialogService.open('ProjectNameDialog', {
                title: clientAndProjectNameTitle,
                text: projectNameForSessionText,
                confirmBtnText: 'Confirm',
                handleConfirm: async (projectName: string) => {
                    await this.fbAppService.updateAppField(this.appKey, 'project_name', projectName);
                },
            });
        } else {
            this.addUsers(this.emailList, this.inviteForm.controls.message.value);
            this.resetForm();
        }
    }

    ngOnInit() {
        this.featureToggleService
            .getFeatureToggleValue('join_session_link')
            .pipe(takeUntil(this.onDestroy))
            .subscribe(featureToggleValue => {
                this.joinSessionLinkEnabled = featureToggleValue;
            });

        this.featureToggleService
            .getFeatureToggleValue('project_name')
            .pipe(takeUntil(this.onDestroy))
            .subscribe(featureToggleValue => {
                this.requiredProjectName = featureToggleValue;
            });

        this.fbAppService
            .getApp(this.appKey)
            .pipe(takeUntil(this.onDestroy))
            .subscribe(app => {
                if (app) {
                    if (!app.passkeyGUID) {
                        this.fbAppService.generateAppPasskeyGUID(this.appKey);
                    } else {
                        this.url = document.location.origin + '/sessionJoin/' + app.passkeyGUID;
                    }

                    if (app.use_passkey) {
                        this.passkeyEnabled = app.use_passkey;
                    }

                    if (app.passkey) {
                        this.passkey = app.passkey;
                    }

                    if (app.email_required) {
                        this.emailRequired = app.email_required;
                    }

                    if (app.project_name) {
                        this.projectName = app.project_name;
                    }
                }
            });
        this.inviteForm = new FormGroup({
            participants: new FormControl(this.participants, Validators.compose([emailValidator])),
            observers: new FormControl(this.observers, Validators.compose([emailValidator])),
            leaders: new FormControl(this.leaders, Validators.compose([emailValidator])),
            message: new FormControl(''),
            passkeyControl: new FormControl(this.passkey, Validators.compose([passkeyValidator])),
        });
        this.participants = [];
        this.observers = [];
        this.leaders = [];
        this.emailList = [];
        this.rolesMapping = {
            participant: this.participants,
            observer: this.observers,
            leader: this.leaders,
        };
        this.emailsErrorMessages = {
            participants: '',
            observers: '',
            leaders: '',
        };

        this.resetEmailsErrors();
        this.clearEmailErrors();
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }

    private resetEmailsErrors(): void {
        this.emailsErrors = {
            invalidEmails: [],
            emailsAlreadyExistInApp: {
                participants: [],
                observers: [],
                leaders: [],
            },
            emailsAlreadyExistInSession: {
                participants: [],
                observers: [],
                leaders: [],
            },
        };
    }

    private showMessageError(
        control: AbstractControl,
        controlName: string,
        isEmailInvalid: boolean,
        isEmailExistInApp: boolean,
        isEmailExistInSession: boolean,
        text: string,
    ): void {
        control.setErrors({ invalidEmail: isEmailInvalid });
        this.emailExist[controlName] = isEmailExistInApp;
        this.emailEnter[controlName] = isEmailExistInSession;
        this.emailsErrorMessages[controlName] = text;
    }
}
