import {
    Component,
    AfterViewInit,
    ContentChildren,
    QueryList,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    OnDestroy,
    SimpleChanges,
    OnChanges
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { EfficientListChildComponent } from './efficient-list-item.component';
import { ObserverType } from './efficient-list.models';
import { EfficientListViewService } from './efficient-list-view.service';
import { trackById } from '@app/core/utils';

@UntilDestroy()
@Component({
    selector: 'app-efficient-list-view',
    templateUrl: './efficient-list-view.component.html',
    styleUrls: ['./efficient-list-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class EfficientListViewComponent implements AfterViewInit, OnChanges, OnDestroy {
    @Input() sourceKey: string; // to trigger initialization on change
    @Input() disableVirtualScroll: boolean; // prevent from elements hiding
    @Input() disable: boolean;
    @Input() containerOffsetTop = 0;
    @ContentChildren(EfficientListChildComponent) listItems: QueryList<EfficientListChildComponent>;

    prevPageVisible = false;
    nextPageVisible = false;

    spaceTopHeight = 0;
    spaceBottomHeight = 0;

    prevSpaceTopHeights = [];
    prevSpaceBottomHeights = [];

    showLoader = true;
    trackById = trackById;
    timeouts = [];

    pageSize = 10; // amount of items ot display at the moment
    currentPage = 0; // current page to display
    pagesLoaded$ = new BehaviorSubject<number>(1); // amount of loaded pages
    loadedItems: EfficientListChildComponent[];
    loadedListItems$: Observable<EfficientListChildComponent[]>;

    private topElIndex = 1;
    private bottomElIndex = this.pageSize - 2;
    private idPrefix = 'efficient-item-';
    private rootElId = 'efficient-list-view-root';
    private prevScrollFromTop = false;
    private targetShowed: {
        [type: string]: boolean;
    } = {};

    private observers: {
        [type: string]: IntersectionObserver;
    } = {};
    private targets: {
        [type: string]: Element;
    } = {};
    private shouldSkipObserver: {
        [type: string]: boolean;
    } = {};

    constructor(
        private _cd: ChangeDetectorRef,
        private _efficientListViewService: EfficientListViewService
    ) { }

    get defaultListItems(): EfficientListChildComponent[] {
        if (this.disable) {
            return this.listItems.toArray();
        }
        return this.listItems
            ? this.listItems
                .toArray()
                .slice(0, this.pageSize * this.pagesLoaded$.getValue())
            : [];
    }

    getElId(id: string): string {
        return `${this.idPrefix}${id}`;
    }

    showListItem(index: number): boolean {
        const minVisibleItemIndex = this.prevPageVisible
            ? !!this.currentPage && ((this.currentPage - 1) * this.pageSize) || 0
            : this.currentPage * this.pageSize;
        const maxVisibleItemIndex = this.nextPageVisible
            ? (this.currentPage + 2) * this.pageSize
            : (this.currentPage + 1) * this.pageSize;
        return index >= minVisibleItemIndex
            && index < maxVisibleItemIndex;
    }

    ngAfterViewInit() {
        if (!this.loadedItems) {
            this.loadedListItems$ = combineLatest(
                this.listItems.changes,
                this.pagesLoaded$.pipe(distinctUntilChanged())
            ).pipe(
                untilDestroyed(this),
                map(([changes, pagesLoaded]) => {
                    const items = changes.toArray();
                    const loadedItems = !!items && items.length
                        ? items.slice(0, this.pageSize * pagesLoaded)
                        : [];
                    this.loadedItems = loadedItems;
                    return loadedItems;
                })
            );
        }
        this.initializeComponent();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!!changes.sourceKey) {
            this.timeouts.push(setTimeout(() => {
                this.currentPage = 0;
                this.pagesLoaded$.next(1);
                this.showLoader = this.listItems && this.listItems.length > this.pageSize;
                this.observers = {};
                this.targets = {};
                this.shouldSkipObserver = {};
                this.initializeComponent();
                this._cd.detectChanges();
            }));
        }
    }

    ngOnDestroy() {
        this.timeouts.forEach((timeout) => {
            clearTimeout(timeout);
        });

        Object.keys(this.observers).forEach(type => {
            this.observers[type].disconnect();
        });
    }

    private initializeComponent() {
        if (this.disable) { return; }

        const root = document.getElementById(this.rootElId);

        if (!root) { return; }

        root.scrollTop = 0;
        const options = { root };
        this.observers = {
            [ObserverType.PrevTop]: new IntersectionObserver(
                (entities: IntersectionObserverEntry[]) =>
                    this.observePage(ObserverType.PrevTop, entities[0]),
                options
            ),
            [ObserverType.PrevBottom]: new IntersectionObserver(
                (entities: IntersectionObserverEntry[]) =>
                    this.observePage(ObserverType.PrevBottom, entities[0]),
                options
            ),
            [ObserverType.NextTop]: new IntersectionObserver(
                (entities: IntersectionObserverEntry[]) =>
                    this.observePage(ObserverType.NextTop, entities[0]),
                options
            ),
            [ObserverType.NextBottom]: new IntersectionObserver(
                (entities: IntersectionObserverEntry[]) =>
                    this.observePage(ObserverType.NextBottom, entities[0]),
                options
            )
        };

        this.resetAllObservers();

        this._efficientListViewService.scrollReachedEnd$
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const canLoadMore = this.pagesLoaded$.getValue() < this.listItems.length / this.pageSize;
                if (canLoadMore) {

                    // if trigger to inclrease current page was skipped due to fast scroll
                    if (!this.targetShowed[ObserverType.NextBottom]) {
                        this.currentPage++;
                    }

                    this.loadNextPage();
                } else {

                    // hide loader if there are no more pages to load
                    this.timeouts.push(setTimeout(() => {
                        this.showLoader = false;
                        this._cd.detectChanges();
                    }, 1000));
                }
            });
    }

    private setIntersectionObserver(type: ObserverType, isMovingUp?: boolean): void {
        const elementIndex = this.getElementIndex(type, isMovingUp);

        const loadedListItems = this.loadedItems || [];
        if (elementIndex >= 0 && elementIndex <= loadedListItems.length) {
            const element = this.listItems.toArray()[elementIndex];
            if (!element) { return; }
            const elementId = this.getElId(element.id);

            const shouldSetObserver = !!this.targets[type]
                ? this.targets[type].id !== elementId
                : true;
            if (shouldSetObserver) {
                this.clearObserver(type);

                const target = document.getElementById(elementId);

                if (target) {
                    const shouldSkipObserver = !Object.keys(this.targets)
                        .find(key => this.targets[key] && this.targets[key].id === elementId);

                    this.targets[type] = target;
                    this.observers[type].observe(target);

                    this.shouldSkipObserver[type] = shouldSkipObserver;
                }
            }
        } else {
            this.clearObserver(type);
        }
    }

    private getElementIndex(type: ObserverType, isMovingUp?: boolean): number {
        const currentPage = isMovingUp
            ? this.currentPage - 1
            : this.currentPage;
        const currentPageFirstElIndex = currentPage * this.pageSize;
        const nextPageFirstElIndex = (currentPage + 1) * this.pageSize;
        switch (type) {
            case ObserverType.PrevTop:
                return currentPageFirstElIndex + this.topElIndex;
            case ObserverType.PrevBottom:
                return nextPageFirstElIndex + this.topElIndex;
            case ObserverType.NextTop:
                return currentPageFirstElIndex + this.bottomElIndex;
            case ObserverType.NextBottom:
                return nextPageFirstElIndex + this.bottomElIndex;
            default:
                return 0;
        }
    }

    private observePage(
        type: ObserverType,
        entry: IntersectionObserverEntry
    ): void {
        if (this.shouldSkipObserver[type]) {
            this.shouldSkipObserver[type] = false;
            return;
        }

        const { isIntersecting, boundingClientRect, rootBounds } = entry;
        const isAbove =
            (boundingClientRect as DOMRectReadOnly).y <
            (rootBounds as DOMRectReadOnly).y;

        switch (type) {
            case ObserverType.PrevTop:
                this.handlePrevTopIntersection(isIntersecting, isAbove);
                break;
            case ObserverType.PrevBottom:
                this.handlePrevBottomIntersection(isIntersecting, isAbove);
                break;
            case ObserverType.NextTop:
                this.handleNextTopIntersection(isIntersecting, isAbove);
                break;
            case ObserverType.NextBottom:
                this.handleNextBottomIntersection(isIntersecting, isAbove);
                break;
            default:
                break;
        }

        this.prevScrollFromTop = (isAbove && !isIntersecting) || (!isAbove && isIntersecting);
        if (isIntersecting) {
            this.targetShowed[type] = true;
        }
    }

    // prev top page trigger
    private handlePrevTopIntersection(
        isIntersecting: boolean,
        isAbove: boolean
    ): void {

        if (isIntersecting && isAbove) {
            this.showPrevPage();
            this.resetAllObservers(isAbove);
        }
    }

    // prev botttom page trigger
    private handlePrevBottomIntersection(
        isIntersecting: boolean,
        isAbove: boolean
    ): void {

        if (!isIntersecting) {

            if (isAbove) {
                this.hidePrevPage();
            } else {
                if (!this.prevScrollFromTop && this.currentPage) {
                    this.currentPage--;
                }
            }
        } else {

            if (this.prevScrollFromTop) {
                this.showPrevPage();
            }
        }
    }

    // next top page trigger
    private handleNextTopIntersection(
        isIntersecting: boolean,
        isAbove: boolean
    ): void {

        if (isIntersecting) { // if becomes visible

            // if becomes visible in the top of the view window
            if (!isAbove) {
                this.loadNextPage();
            }
        } else { // if becomes hidden

            // if becomes visible in the top of the view window
            if (isAbove) {

                if (this.prevScrollFromTop) {
                    this.currentPage++;
                }

            } else { // if becomes visible in the bottom of the view window
                this.hideNextPage();
            }
        }
    }

    // next bottom page trigger
    private handleNextBottomIntersection(
        isIntersecting: boolean,
        isAbove: boolean
    ): void {

        // if becomes visible in the top of the view window
        if (isIntersecting && !isAbove) {
            this.loadNextPage();
        }
    }

    private resetAllObservers(isMovingUp?: boolean): void {
        if (!this.disableVirtualScroll) {
            this.setIntersectionObserver(ObserverType.PrevTop, isMovingUp);
            this.setIntersectionObserver(ObserverType.PrevBottom, isMovingUp);
        }
        this.setIntersectionObserver(ObserverType.NextTop, isMovingUp);
        this.setIntersectionObserver(ObserverType.NextBottom, isMovingUp);

        this.targetShowed = {};
    }

    private clearObserver(type: ObserverType): void {
        if (this.targets[type]) {
            this.observers[type].disconnect();
            this.targets[type] = null;
        }
    }

    private loadNextPage(): void {
        this.showNextPage();
        this.pagesLoaded$.next(
            this.currentPage + 2 >= this.pagesLoaded$.getValue()
                ? this.currentPage + 2
                : this.pagesLoaded$.getValue()
        );
        this._cd.detectChanges();

        this.timeouts.push(setTimeout(() => {
            this.resetAllObservers();
        }));

        // hide loader if there are no more pages to load
        const canLoadMore = this.pagesLoaded$.getValue() < this.listItems.length / this.pageSize;
        if (!canLoadMore) {
            this.timeouts.push(setTimeout(() => {
                this.showLoader = false;
                this._cd.detectChanges();
            }, 2000));
        }
    }

    private hidePrevPage(): void {
        if (this.disableVirtualScroll || !this.currentPage) { return }
        this.prevPageVisible = false;

        const currPageFirstElIndex = this.currentPage * this.pageSize;
        const loadedListItems = this.loadedItems || [];
        if (currPageFirstElIndex >= loadedListItems.length) { return }
        const currPageFirstElId = this.getElId(this.listItems.toArray()[currPageFirstElIndex].id);
        this.timeouts.push(setTimeout(() => {
            const currPageFirstEl = document.getElementById(currPageFirstElId);
            if (currPageFirstEl) {
                const offset = currPageFirstEl.offsetTop - this.containerOffsetTop;
                this.prevSpaceTopHeights.push(this.spaceTopHeight);
                this.spaceTopHeight = offset;
            }
            this._cd.detectChanges();
        }));
    }

    private showPrevPage(): void {
        if (this.disableVirtualScroll) { return }
        this.prevPageVisible = true;

        const prevHeight = this.prevSpaceTopHeights.splice(-1, 1)[0] || 0;
        this.spaceTopHeight = prevHeight;
        this._cd.detectChanges();
    }

    private hideNextPage(): void {
        if (this.disableVirtualScroll) { return }
        this.nextPageVisible = false;

        let nextPageFirstElIndex = (this.currentPage + 1) * this.pageSize;
        const loadedListItems = this.loadedItems || [];
        const visibleDataLength = loadedListItems.length;
        nextPageFirstElIndex = nextPageFirstElIndex < visibleDataLength
            ? nextPageFirstElIndex
            : visibleDataLength - 1;
        let currentPageLastElIndex = ((this.currentPage + 1) * this.pageSize) - 1;
        currentPageLastElIndex = currentPageLastElIndex < visibleDataLength
            ? currentPageLastElIndex
            : visibleDataLength - 1;
        if (nextPageFirstElIndex <= currentPageLastElIndex) {
            this._cd.detectChanges();
            return;
        }

        const nextPageFirstElId = this.getElId(this.listItems.toArray()[nextPageFirstElIndex].id);
        this.timeouts.push(setTimeout(() => {
            const nextPageFirstEl = document.getElementById(nextPageFirstElId);
            if (nextPageFirstEl) {
                const offset = nextPageFirstEl.parentElement.offsetHeight - nextPageFirstEl.offsetTop - this.containerOffsetTop;
                this.prevSpaceBottomHeights.push(this.spaceBottomHeight);
                this.spaceBottomHeight = offset;
            }
            this._cd.detectChanges();
        }));
    }

    private showNextPage(): void {
        if (this.disableVirtualScroll) { return }
        this.nextPageVisible = true;

        const prevHeight = this.prevSpaceBottomHeights.splice(-1, 1)[0] || 0;
        this.spaceBottomHeight = prevHeight;
        this._cd.detectChanges();
    }
}
