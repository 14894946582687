import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import * as filestack from 'filestack-js';
import { Client } from 'filestack-js/build/main/lib/client';

const FIRESTORE_API_KEY = 'AhyMLDTsdRg2aza88TOnuz';

@Injectable()
export class WorkBookExportService {
    private fireCloudClient: Client = null;

    constructor(
        private afFun: AngularFireFunctions
    ) {
        this.fireCloudClient = filestack.init(FIRESTORE_API_KEY);
    }
    public async upload(files: File[]): Promise<void> {
        for (const file of files) {
            const storeParams = {
                filename: file.name,
                path: '/uploads/fred',
                location: 'gcs'
            };

            try {
                const fileStoreResult = await this.fireCloudClient.upload(file, {}, storeParams);
                await this.afFun.httpsCallable('uploadWorkbookByUrl')({
                    url: fileStoreResult.url,
                    handle: fileStoreResult.handle,
                    fileName: file.name
                }).toPromise();
            } catch (error) {
                throw error;
            }
        }
    }

    public async deleteFileFormGoogleDrive(fileId: string) {
        await this.afFun.httpsCallable('deleteFile')({
            fileId
        }).toPromise();
    }

    public async removeFileFromFileStack(handle: string): Promise<void> {
        try {
            return await this.fireCloudClient.remove(handle);
        } catch (error) {
            throw error;
        }
    }

    public async export(
        deploymentId: string,
        moduleId: string
    ): Promise<{ filename: string, downloadUrl: string }[]> {
        const result = await this.afFun.httpsCallable('workbookExportByDemographicId')({
            deploymentId,
            moduleId
        }).toPromise();

        return result as { filename: string, downloadUrl: string }[];
    }
}
