<div *ngIf="(vm$ | async) as vm">
  <div *ngIf="!vm.isLoading" class="form-page">
    <!-- <div class="form-impact">
      <button
        type="button"
        class="gs-button-ghost gs-small"
        (click)="navigateToFormView('impact', vm)"
      >
        Show Impact
      </button>
    </div> -->
    <div class="form-header">
      <p class="h5">{{ vm.form.name }}</p>
      <p class="subtitle-regular form-module-name">{{ vm.moduleName }}</p>
      <div class="form-chips">
        <span class="gs-status-chip-activity-type">Form</span>
        <span *ngIf="vm.form.global" class="gs-status-chip-global">Global</span>
      </div>
    </div>

    <div *ngIf="vm.form.items.length > 0" class="form-body-wrapper">
      <div class="condensed-view">
        <div class="questions-list">
          <!-- Condensed Question List -->
          <!-- Footer -->
        </div>

        <div class="answers-list">
          <!-- Condensed Answers List -->
        </div>

      </div>
    </div>
    <div *ngIf="vm.form.items.length < 1" class="gs-block-empty">
      <gs-empty-screen
        [imageUrl]="'/assets/svg/gs-empty-form.svg'"
        [text]="'There are no questions in this form'"
      ></gs-empty-screen>
    </div>
  </div>
</div>
