<div class="task-status" [ngClass]="{'clickable': emulate && !blocked}" (click)="toggleCompleted()">
  <mat-icon>done</mat-icon>
</div>

<div class="task-content">

  <h5 class="h5 task-name">
    <a *ngIf="activityLink" class="text-link name" (click)="navigate()">{{ data.name }}</a>
    <span *ngIf="!activityLink" class="name">{{ data.name }}</span>
    <ng-container *ngIf="showProgressInfo">
      <app-activity-progress-label
        [activeDemographicsIds]="activeDemographicsArray$ | async"
        [answeredQuestions]="answeredQuestions"
        [task]="data"
      ></app-activity-progress-label>
    </ng-container>
    <mat-icon
      *ngIf="showExpandIcon"
      class="drop-down-icon"
      [ngClass]="expanded ? 'arrow-up' : 'arrow-down'"
      (click)="toggleExpanded()"
    >keyboard_arrow_down</mat-icon>
    <mat-icon
      *ngIf="tooltipMessage"
      class="tooltip-info-icon"
      matTooltipClass="tt-tooltip long"
      matTooltipPosition="above"
      [matTooltip]="tooltipMessage"
    >info</mat-icon>

    <!-- Form status -->
    <app-form-state
      *ngIf="isShowFormState"
      [state]="formStatus"
    ></app-form-state>
    <!-- /Form status -->
  </h5>

  <p>
    <span class="task-label activity-type-label">{{displayType}}</span>
  </p>

  <!-- form task details -->
  <div *ngIf="expanded && data?.demographic_status" class="expandable">
    <div class="demographics-block">
      <div
        *ngFor="let demographic of demographics; trackBy: trackByKey;"
        class="demographics-block-item"
      >
        <span class="name demographic-name">{{demographic?.name}}</span>
        <span class="status">
          <app-form-state class="no-bg left" [state]="demographic?.status"></app-form-state>
        </span>
      </div>
    </div>
  </div>
  <!-- /form task details -->

</div>
