import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

import { FormBranchingService } from '@app/apps/activities/forms/form-services/form-branching.service';

@Component({
    selector: 'app-restore-response-branched-questions-dialog',
    templateUrl: './restore-response-branched-questions-dialog.component.html',
    styleUrls: ['./restore-response-branched-questions-dialog.component.scss']
})

export class RestoreResponseBranchedQestionsDialogComponent implements OnInit {
    isRemove: boolean;

    text$: Promise<SafeHtml>;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<RestoreResponseBranchedQestionsDialogComponent>,
        private _formBranchingService: FormBranchingService
    ) { }

    handleConfirm(): void {
        const { handleConfirm, closeOnConfirm } = this.data;
        handleConfirm();
        if (closeOnConfirm) {
            this.dialogRef.close();
        }
    }

    handleCancel(): void {
        const { handleCancel } = this.data;
        if (!!handleCancel) {
            handleCancel();
        }
        this.dialogRef.close();
    }

    ngOnInit() {
        const confirmText = this.data.confirmBtnText.toLowerCase();
        this.isRemove = confirmText === 'remove'
            || confirmText === 'delete';
        const { activityId, questionId, baseResponseValue, branchingDependencies, demographicId, parentDemographicId } = this.data;

        this.text$ = this._formBranchingService.getTextWithCountOfAffectedQuestions(
            activityId,
            questionId,
            baseResponseValue,
            branchingDependencies,
            demographicId,
            parentDemographicId
        );
    }
}
