import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { DeploymentFormFacadeToken, DeploymentFormFacade } from '../../deployment-form.facade';

@Component({
    selector: 'gs-condensed-form',
    templateUrl: './condensed-form.component.html',
    styleUrls: ['./condensed-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CondensedFormComponent {
    vm$ = this.formFacade.cvm$;

    constructor(@Inject(DeploymentFormFacadeToken) private formFacade: DeploymentFormFacade) {
    }

}
