<div *ngIf="(vm$ | async) as vm" class="full-height fred-theme">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div *ngIf="!vm.isLoading" class="create-deployment-view-container" [ngClass]="{ 'congrats-bg': isLastPage }">
    <div class="create-deployment-navigation">
      <button
        class="gs-button-icon-flat"
        [ngClass]="{ 'hidden': currentSlideIndex <= 0 || isLastPage }"
        (click)="goToPrevSlide()"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>

      <div *ngIf="!hideSteps" class="page-counter caption">
        {{ currentSlideIndex + 1 }} of {{ createDeploymentPages.length - 2 }} <!-- without last 2 screens-->
      </div>

      <button
        class="gs-button-icon-flat"
        (click)="onCloseCreateDeployment()"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <gs-create-deployment [activeView]="activeView">

      <div deployments class="slide-page deployments-page">
        <div class="content">
          <p class="title h5">Select Deployment Template</p>
          <p class="description">Please select Deployment template to be used</p>

          <div class="deployment-templates">
            <div
              *ngFor="let deployment of vm.deployment_templates; trackBy: trackById; let i = index"
              class="template"
            >
              <gs-deployment-template-card
                [isSelected]="deployment.id === selectedDeployment?.id"
                [deploymentTemplate]="deployment"
                [backgroundColorIndex]="calculateColorIndex(i, vm.template_length)"
                (click)="setSelectedDeployment(deployment)"
              ></gs-deployment-template-card>
            </div>
          </div>
          <div class="button-container">
            <button
              class="gs-button-filled"
              [disabled]="!selectedDeployment"
              (click)="goToNextSlide()"
            >
              Use Template
            </button>
          </div>
        </div>
      </div>

      <div deployment_description class="slide-page deployment-description">
        <div class="content">
          <ng-container *ngIf=!!selectedDeployment>
            <div class="description-header">
              <gs-image
                class="description-image"
                [deploymentTemplateImage]="selectedDeployment.image"
              ></gs-image>
              <div class="description-title">
                <p class="title h5">{{ selectedDeployment.name }}</p>
              </div>
            </div>

            <div class="description-content">
              <p class="subtitle-medium">Description</p>
              <p *ngIf="!!selectedDeployment.description" class="text-regular-small">
                {{ selectedDeployment.description }}
              </p>
            </div>
          </ng-container>
          <div class="button-container">
            <button
              class="gs-button-filled"
              (click)="goToNextSlide()"
            >
              Use Template
            </button>
          </div>
        </div>
      </div>

      <div client_name class="slide-page client-name">
        <div class="content">
          <p class="title h5">Add Client’s Name</p>
          <mat-label class="caption-gray">client's name<span class="required">*</span></mat-label>
          <mat-form-field appearance="outline" floatLabel="always">
            <input
              #clientNameInput
              matInput
              [placeholder]="'Enter name here'"
              [(ngModel)]="clientName"
            />
            <mat-error>This field is required</mat-error>
          </mat-form-field>
          <div class="button-container">
            <button
              class="gs-button-filled"
              [disabled]="isEmptyClientName"
              (click)="onSelectClientName()"
            >
              <span>Next</span>
              <mat-icon class="icon">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div modules class="slide-page modules-page">
        <ng-container *ngIf="!!selectedDeployment && selectedDeployment.modules as modules">
          <div class="content">
            <p class="title h5">
              Select Modules
              <button
                class="gs-button-flat gs-small"
                (click)="selectAllModules(modules)"
              >
                {{ allModulesCheck ? 'Unselect All' : 'Select All' }}
              </button>
            </p>
            <p class="description">Please select modules to be deployed</p>

            <div class="modules">
              <div *ngFor="let module of modules; trackBy: trackById; let i = index;" class="module">
                <div
                  class="module-checkbox"
                  [ngClass]="{ 'checked': isModuleChecked(module) }"
                  (click)="onSelectionChange(module, modules)"
                >
                  <mat-icon *ngIf="isModuleChecked(module)">done</mat-icon>
                </div>
                <mat-expansion-panel
                  class="module-content"
                  [ngClass]="{ 'checked': isModuleChecked(module) }"
                  [hideToggle]="true"
                  [expanded]="expandedModulesObject[i]"
                  (opened)="expandedModulesObject[i] = true"
                  (closed)="expandedModulesObject[i] = false"
                >
                  <mat-expansion-panel-header
                    class="module-header"
                    [collapsedHeight]="'auto'"
                    [expandedHeight]="'auto'"
                  >
                    <mat-panel-title class="text-regular module-name">
                      {{ module.name }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>

                  <p class="overline">Processes</p>
                  <div *ngFor="let process of module.processes; trackBy: trackById;" class="process-list">
                    <i class="process-name-icon" [ngClass]="{ visible: true }"></i>
                    <div class="text-regular process-name">{{ process.name }}</div>
                  </div>
                </mat-expansion-panel>
                <button
                  mat-icon-button
                  class="module-button"
                  (click)="expandModule(i)"
                >
                  <mat-icon *ngIf="!expandedModulesObject[i]">keyboard_arrow_down</mat-icon>
                  <mat-icon *ngIf="expandedModulesObject[i]">keyboard_arrow_up</mat-icon>
                </button>
              </div>

            </div>

            <div class="button-container">
              <button
                class="gs-button-filled"
                [disabled]="selectedModules.length === 0"
                (click)="goToNextSlide()"
              >
                <span>Next</span>
                <mat-icon class="icon">keyboard_arrow_right</mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
      </div>

      <div overlay_template class="slide-page overlay_template">
        <div class="content">
          <p class="title h5">Select an Overlay Template</p>
          <p class="description">Please select an overlay template to be used (optional)</p>

          <div *ngIf="vm.overlay_templates.length === 0" class="no-overlay-templates">
            <span>You are not associated with any overlay templates. Please create one or request to be invited as an owner to an existing one</span>
          </div>
          <div class="overlay-templates">
              <mat-card
                *ngFor="let overlayTemplate of getOverlayTemplatesForCoreTemplate(vm.overlay_templates); trackBy: trackById"
                class="overlay-template"
                (click)="selectOverlayTemplate(overlayTemplate.id)"
                [class.selected]="selectedOverlayTemplateId === overlayTemplate.id"
              >
                <mat-card-content>
                  <div class="overlay-template-title">
                    {{ overlayTemplate.name }}
                  </div>
                  <div class="overlay-template-owners">
                    <gs-assignees [users]="overlayTemplate.owners" [limit]="2"></gs-assignees>
                  </div>
                  <div class="overlay-template-actions">
                    <button class="gs-button-icon-flat gs-small" (click)="viewOverlayTemplate(overlayTemplate.id)">
                      <mat-icon>visibility</mat-icon>
                      View
                    </button>
                    <button class="gs-button-icon-flat gs-small" (click)="editOverlayTemplate(overlayTemplate.id)">
                      <mat-icon>create</mat-icon>
                      Edit
                    </button>
                    <button class="gs-button-icon-flat gs-small" (click)="deleteOverlayTemplate(overlayTemplate.id)">
                      <mat-icon>delete</mat-icon>
                      Delete
                    </button>
                  </div>
                </mat-card-content>
              </mat-card>
          </div>

          <div class="button-container">
            <button class="gs-button-ghost" (click)="editOverlayTemplate()">
              <mat-icon>create</mat-icon>
              Create Overlay Template
            </button>
          </div>

          <div class="button-container">
            <button
              class="gs-button-filled"
              (click)="goToNextSlide()"
            >
              <span>Next</span>
              <mat-icon class="icon">keyboard_arrow_right</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div demographics class="slide-page demographics">
        <ng-container *ngIf="vm.global_demographics as demographics">
          <div class="content">
            <p class="title h5">
              Select Countries
            </p>
            <div class="demographics-search">
              <mat-form-field class="search" appearance="outline" floatLabel="always">
                <input
                  #search
                  matInput
                  [(ngModel)]="searchValue"
                  [placeholder]="'Enter Your Search Here'"
                />
                <mat-icon *ngIf="!search.value" matSuffix>search</mat-icon>
                <mat-icon *ngIf="search.value" (click)="clearSearch()" matSuffix>close</mat-icon>
              </mat-form-field>
            </div>

            <div class="selected-demographics">
              <div class="chip-list">
                <ng-container *ngFor="let selected of (selectedDemographics$.asObservable() | async); trackBy: trackById">
                  <div class="demographics-list-item gs-demographic-chip-selected">
                    <div class="demographics-chip-icon">
                      <mat-icon class="gs-demographic-chip-icon-done">done</mat-icon>
                    </div>
                    <span class="gs-demographic-chip-label">
                      {{ selected.name }}
                    </span>
                    <mat-icon class="gs-demographic-chip-icon-clear" (click)="removeActiveDemographic(selected)">clear</mat-icon>
                  </div>
                </ng-container>
              </div>
            </div>

            <div class="button-container">
              <button
                class="gs-button-filled"
                [disabled]="!(selectedDemographics$.asObservable() | async).length"
                (click)="goToNextSlide()"
              >
                <span>Next</span>
                <mat-icon class="icon">keyboard_arrow_right</mat-icon>
              </button>
            </div>

            <gs-demographics-list
              [demographicsList]="demographics"
              [searchValue]="searchValue"
              [selectedDemographics]="selectedDemographics$.asObservable() | async"
              (onSelectDemographic)="selectDemographic($event)"
              (onSelectGroupDemographics)="selectGroupDemographics($event)"
            ></gs-demographics-list>
          </div>
        </ng-container>
      </div>

      <div deployment_view class="slide-page deployment-view">
        <div class="content">
          <p class="title h5">Your Future Deployment</p>
          <div class="deployment-view-data">
            <ng-container *ngIf=!!selectedDeployment>
              <div class="description-header">
                <gs-image
                  class="description-image"
                  [deploymentTemplateImage]="selectedDeployment.image"
                ></gs-image>
                <div class="description-title">
                  <p class="title h5">{{ selectedDeployment.name }}</p>
                </div>
              </div>

              <div class="description-content">
                <p class="label">Client’s name</p>
                <p class="text-regular">{{ clientName }}</p>

                <p class="label">Active modules</p>
                <ul class="gs-list">
                  <li *ngFor="let module of selectedModules; trackBy: trackById">
                    {{ module.name }}
                  </li>
                </ul>

                <p class="label">Countries</p>
                <div
                  *ngFor="let demographic of (selectedDemographics$.asObservable() | async); trackBy: trackById"
                  class="demographics-list-item gs-demographic-chip"
                >
                  <span class="gs-demographic-chip-label">{{ demographic.name }}</span>
                </div>
              </div>
            </ng-container>
          </div>

          <div class="button-container">
            <button
              class="gs-button-filled"
              [ngClass]="{'button-loaded': loading$ | async}"
              [disabled]="loading$ | async"
              (click)="createDeployment(vm.currentUserId)"
            >
              <gs-spinner
                *ngIf="loading$ | async"
                class="gs-global-spinner-normal"
              ></gs-spinner>
              {{ (loading$ | async) ? 'Creating deployment...' : 'Create Deployment' }}
            </button>
          </div>
          <div class="note-container">
            <p *ngIf="loading$ | async" class="caption long">
              Your deployment may take a few minutes.<br />
              You can get back to your tasks by closing this page and we will notify you when it’s ready
            </p>
            <ng-container *ngIf="!(loading$ | async)">
              <p class="caption">
                or you can Invite Collaborators and after that Create deployment
              </p>
              <button
                class="gs-button-flat gs-small"
                (click)="goToNextSlide()"
              >
                Invite Collaborators
              </button>
            </ng-container>
          </div>
        </div>
      </div>

      <div participant_invite class="slide-page invite-stakeholders">
        <div class="content">
          <p class="title h5">Invite Collaborators</p>
          <div class="participant-invite-form">
            <gs-stakeholder-invite
              [deploymentId]="deploymentCopyId"
              (onRosterUpdate)="onRosterUpdate($event)"
            ></gs-stakeholder-invite>
          </div>

          <div class="button-container">
            <button
              class="gs-button-filled"
              [ngClass]="{'button-loaded': loading$ | async}"
              [disabled]="loading$ | async"
              (click)="createDeployment(vm.currentUserId, true)"
            >
              <gs-spinner
                 *ngIf="loading$ | async"
                 class="gs-global-spinner-normal"
              ></gs-spinner>
              {{ (loading$ | async) ? 'Creating deployment...' : 'Invite & Create Deployment' }}
            </button>
          </div>
          <div class="note-container">
            <p *ngIf="loading$ | async" class="caption long">
              Your deployment may take a few minutes.<br />
              You can get back to your tasks by closing this page and we will notify you when it’s ready
            </p>
          </div>
        </div>
      </div>

      <div congrats_screen class="slide-page congrats-screen">
        <div class="content">
          <p class="title h5">Congrats!</p>
          <p class="text-regular">You have created
            <span class="bold">{{ selectedDeployment?.name }} {{ clientName }}</span> deployment!
          </p>

          <div class="button-container">
            <button
              class="gs-button-filled"
              [routerLink]="['/deployment', deploymentCopyId]"
            >
              Go to Deployment
            </button>
          </div>

          <div class="note-container">
            <p class="caption">
              Or you can
            </p>
            <button
              class="gs-button-flat gs-small"
              (click)="createNewDeployment()"
            >
              Create Another Deployment
            </button>
          </div>
        </div>
      </div>
    </gs-create-deployment>
  </div>
</div>
