import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'checked'
})

export class CheckedPipe implements PipeTransform {

    transform(values: any): any {
        if (values) {
            return Object.keys(values).filter((key) => values[key].checked);
        }
        return {};
    }

}
