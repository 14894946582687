import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
    AlignmentState,
    AlignmentStateMap,
    AlignmentStateManageable,
    Demographic,
    FormStatus,
    FormStatusModel,
    UserReadyForAlignment
} from '@app/core/models';
import { AlignmentStateManageableFactoryService } from '@app/apps/activities/forms/alignment/alignment-state-manageable-factory.service';
import { AuthenticatedUserService } from '@app/core/services';

@Injectable({
    providedIn: 'root'
})
export class AlignmentService {
    constructor(
        private db: AngularFireDatabase,
        private alignmentStateManageableFactory: AlignmentStateManageableFactoryService,
        private authUserService: AuthenticatedUserService
    ) { }

    transitionAlignment(activityKey: string, formKey: string, alignmentState: AlignmentState | FormStatus, demographicKeys: string[]) {
        const manager = this.buildAlignmentStateManager(alignmentState);
        manager.transition(this, activityKey, formKey, demographicKeys);
    }

    updateAlignmentState(
        activityKey: string, formKey: string, alignmentState: AlignmentState | FormStatus, demographicKeys: string[]
    ): Promise<void> {

        // Demographics OFF
        if (!demographicKeys) {
            return this.db.object(`ssot/formStatus/${activityKey}/${formKey}`).update({
                status: alignmentState,
                owner_id: this.authUserService.getCurrentUserId()
            });
        }

        // Demographics ON
        const updates = demographicKeys.reduce((accumulator, demographicKey) => {
            accumulator[`ssot/formStatus/${activityKey}/${formKey}/${demographicKey}`] = {
                status: alignmentState,
                owner_id: this.authUserService.getCurrentUserId()
            };
            return accumulator;
        }, {});
        return this.db.object('/').update(updates);
    }

    addLockedState(activityKey: string, formKey: string, currentUserKey: string, demographicKeys: string[]): Promise<void> {
        if (!demographicKeys.length) {
            return this.db.object(`ssot/formStatus/${activityKey}/${formKey}`)
                .set({ status: FormStatus.Locked, owner_id: currentUserKey });
        }
        const updates = demographicKeys.reduce((accumulator, demographicKey) => {
            accumulator[`ssot/formStatus/${activityKey}/${formKey}/${demographicKey}`] = {
                status: FormStatus.Locked,
                owner_id: currentUserKey
            };
            return accumulator;
        }, {});
        return this.db.object('/').update(updates);
    }

    clearFormStates(activityKey: string): Promise<void> {
        const updates = {};
        updates[`ssot/formStatus/${activityKey}`] = null;

        return this.db.object('/').update(updates);
    }

    getAlignmentStateByFormKey(activityKey: string, formKey: string): Observable<AlignmentStateMap> {
        return this.db.object<AlignmentStateMap>(`/ssot/formStatus/${activityKey}/${formKey}`).valueChanges();
    }

    private buildAlignmentStateManager(alignmentState: AlignmentState | FormStatus): AlignmentStateManageable {
        return this.alignmentStateManageableFactory.build(alignmentState);
    }

    calculateSimplestAlignmentState(
        userSelectedDemographicKeys: string[],
        alignmentStateMap: FormStatusModel,
        activeDemographicForUser: string | string[]
    ): FormStatus {
        let alignmentStateValues = [];

        if (typeof activeDemographicForUser === 'string') {
            if ('all' === activeDemographicForUser) {
                // loop through all of the user's selected demographic keys to get their states
                // CAUTION: The map is not initialized with values so we need to account for that key not existing in the map
                alignmentStateValues = (userSelectedDemographicKeys || []).map((userSelectedDemographicKey) => {
                    return (alignmentStateMap
                        && alignmentStateMap[userSelectedDemographicKey]
                        && alignmentStateMap[userSelectedDemographicKey].status)
                        || FormStatus.Draft;
                });
            }
        } else {
            // activeDemographicForUser is an array of demographic keys
            alignmentStateValues = (userSelectedDemographicKeys || []).map((userSelectedDemographicKey) => {
                if ((activeDemographicForUser || []).includes(userSelectedDemographicKey)) {
                    const state = (alignmentStateMap || {})[userSelectedDemographicKey] || {};
                    return state.status || FormStatus.Draft;
                }
            });
        }

        if (alignmentStateValues.includes(FormStatus.Draft) || alignmentStateValues.includes(FormStatus.Locked)) {
            return FormStatus.Draft;
        }
        if (alignmentStateValues.includes(FormStatus.DraftReview)) {
            return FormStatus.DraftReview;
        }
        if (alignmentStateValues.includes(FormStatus.Alignment)) {
            return FormStatus.Alignment;
        }
        if (alignmentStateValues.includes(FormStatus.PendingApproval)) {
            return FormStatus.PendingApproval;
        }
        if (alignmentStateValues.includes(FormStatus.Approved)) {
            return FormStatus.Approved;
        }
        return FormStatus.Draft;
    }

    // demoDefaultKey is the solution to needing 2 different ways of solving the mapping issue.
    // for non demographic forms, the demoDefaultKey will equal 'default' vs a demographic key
    setUserReadyForAlignment(
        appKey: string,
        activityKey: string,
        formKey: string,
        demoDefaultKey: string,
        demographicClassKey: string,
        userKey: string,
        instanceUrl: string,
        moveAllMasterToggle: boolean
    ): Promise<void> {
        const updates = {};
        const pushId = this.db.createPushId();
        const userReadyForAlignmentObject = {
            app_key: appKey,
            activity_key: activityKey,
            demographic_key: demoDefaultKey,
            demographic_class_key: demographicClassKey,
            form_key: formKey,
            instance_url: instanceUrl,
            move_all_master_toggle: moveAllMasterToggle,
            user_key: userKey
        };

        updates[`ssot/readyForAlignment/${pushId}`] = userReadyForAlignmentObject;

        return this.db.object('/').update(updates);
    }

    getUsersReadyForAlignments(userKey: string, formKey: string): Observable<UserReadyForAlignment[]> {
        return this.db.list<UserReadyForAlignment>('/ssot/readyForAlignment',
            ref => ref.orderByChild('user_key').equalTo(userKey))
            .valueChanges()
            .pipe(
                map((userReadyForAlignmentObjects) => {
                    return userReadyForAlignmentObjects.filter((userReadyForAlignmentObject) =>
                        userReadyForAlignmentObject.form_key === formKey
                    );
                })
            );
    }

    getDemographicReadyForAlignmentData(formKey: string, demographicKey: string): Observable<UserReadyForAlignment[]> {
        return this.db.list<UserReadyForAlignment>('/ssot/readyForAlignment',
            ref => ref.orderByChild('demographic_key').equalTo(demographicKey))
            .valueChanges()
            .pipe(
                map((userReadyForAlignmentObjects) => {
                    return userReadyForAlignmentObjects.filter((userReadyForAlignmentObject) =>
                        userReadyForAlignmentObject.form_key === formKey
                    );
                })
            );
    }

    getFormReadyForAlignmentData(formKey: string): Observable<UserReadyForAlignment[]> {
        return this.db.list<UserReadyForAlignment>('/ssot/readyForAlignment',
            ref => ref.orderByChild('form_key').equalTo(formKey))
            .valueChanges();
    }

    getDemographicsForAlignment(
        userKey: string,
        demographicClassKey: string,
        activeDemographics: any,
        useDemographics: boolean,
        userSelectedDemographics: Demographic[],
        userReadyForAlignmentMap: UserReadyForAlignment[],
        alignmentStateMap: FormStatusModel
    ): Demographic[] {
        const activeDemographicKey = `${userKey}_${demographicClassKey}`;
        const activeDemographicForUser = (activeDemographics || {})[activeDemographicKey];
        let demographicsForAlignment = [];

        if (userSelectedDemographics && useDemographics) {
            demographicsForAlignment = userSelectedDemographics.filter((userSelectedDemographic) => {
                let isActiveDemographic = false;
                // 'all' or if it's an array, it needs to include the key
                if (
                    (typeof activeDemographicForUser === 'string' && 'all' === activeDemographicForUser)
                    || (activeDemographicForUser.includes(userSelectedDemographic.key))
                ) {
                    isActiveDemographic = true;
                }

                const foundReadyForDemo = userReadyForAlignmentMap.find((readyForAlignmentObject) => {
                    return readyForAlignmentObject.demographic_key === userSelectedDemographic.key;
                });
                const isAlreadyReady = !!foundReadyForDemo;

                const isDemoInCorrectState = (alignmentStateMap[userSelectedDemographic.key] || {}).status === FormStatus.DraftReview;

                // cannot have already said i'm ready for alignment for this demographic
                // has to be an active demographic
                // has to be in draft review state
                return !isAlreadyReady && isActiveDemographic && isDemoInCorrectState;
            });
        }

        return demographicsForAlignment;
    }

    setDraftComplete(
        appKey: string,
        activityKey: string,
        formKey: string,
        demoDefaultKey: string,
        demographicClassKey: string,
        instanceUrl: string
    ): Promise<void> {
        const updates = {};
        const pushId = this.db.createPushId();
        const draftCompleteObject = {
            app_key: appKey,
            activity_key: activityKey,
            demographic_key: demoDefaultKey,
            demographic_class_key: demographicClassKey,
            form_key: formKey,
            instance_url: instanceUrl
        };

        updates[`ssot/draftComplete/${pushId}`] = draftCompleteObject;

        return this.db.object('/').update(updates);
    }
}
