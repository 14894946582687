import { Activity } from '@app/core/models/activity.model';

export type InstanceStatus = 'incomplete' | 'paused' | 'complete' | 'in_progress';

export class InstanceFocus {
    activity_id: string;
    activity_type: string;
    activity_name: string;
    user_id: string;
    user_name: string;
    timestamp: number; // to trigger update every time when focus activated (even when written data is the same)
    question_id?: string;
    slide_id?: string;
    group_id?: string;
    mode?: string;
    class_id?: string;
    filter_id?: string;
}

export class InstanceFocusPair {
    activity: Activity;
    focusObject: InstanceFocus;
    isSessionInProgress?: boolean;
}

export const testInstanceFocus = <InstanceFocus> {
    activity_id: 'activity1',
    activity_type: 'crowdsource',
    activity_name: 'CrowdSource',
    question_id: 'question1',
    mode: 'crowdsource'
};
