import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface ExpandedMap {
    [questionKey: string]: boolean;
}

@Injectable()
export class FormEditExpandStateService {
    private _expandStateByForm = new Map<string, BehaviorSubject<ExpandedMap>>();

    constructor() { }

    setExpandedState(formKey: string, questionKey: string, state: boolean): void {
        if (!this._expandStateByForm.has(formKey)) {
            this._expandStateByForm.set(formKey, new BehaviorSubject({
                [questionKey]: state
            }));
            return;
        }

        const currentValue = this._expandStateByForm.get(formKey).getValue();

        // in this case need to delete value to avoid cases with empty data
        if (state === null) {
            delete currentValue[questionKey]; 
            this._expandStateByForm.get(formKey).next({
                ...currentValue
            });
        } else {
            this._expandStateByForm.get(formKey).next({
                ...currentValue,
                [questionKey]: state
            });
        }
    }

    getFormExpandedState(formKey: string): Observable<ExpandedMap> {
        if (!this._expandStateByForm.has(formKey)) {
            this._expandStateByForm.set(formKey, new BehaviorSubject({}));
        }
        return this._expandStateByForm.get(formKey).asObservable();
    }

    getQuestionExpandedStateValue(formKey: string, questionKey: string): boolean {
        if (!this._expandStateByForm.has(formKey)) {
            return null;
        }
        return this._expandStateByForm.get(formKey).getValue()[questionKey];
    }

    setStateForAllForm(formKey: string, value: boolean): void {
        const currentState = this._expandStateByForm.get(formKey).getValue();
        const newState = Object.keys(currentState).reduce((acc, key) => {
            acc[key] = value;
            return acc;
        }, {});
        this._expandStateByForm.get(formKey).next(newState);
    }

    clear(): void {
        this._expandStateByForm.clear();
    }
}
