import { Question, testQuestion } from '@app/core/models/question.model';
import { DemographicsClass } from '@app/core/models/demographic.model';
import { SortDirection } from '@angular/material/sort';
import { Severity } from '@thinktank/common-lib';

export type activityType =
    | 'demographics'
    | 'crowdsource'
    | 'brainstorm'
    | 'categorize'
    | 'questionnaire'
    | 'presentation'
    | 'assessment'
    | 'form'
    | 'deployments';

export const activityTypes = ['demographics', 'presentation', 'crowdsource', 'form', 'vote', 'collect', 'deployments'];

export type ActivityStatus = 'incomplete' | 'complete_ready' | 'complete' | 'in_progress' | 'exported';

export enum ActivityType {
    Collect = 'questionnaire',
    Vote = 'assessment',
    Present = 'presentation',
    Brainstorm = 'brainstorm',
    Categorize = 'categorize',
    Form = 'form',
    Demographics = 'demographics',
    Crowdsource = 'crowdsource',
}

export const activityTypesDictionary = {
    brainstorm: 'brainstorm',
    categorize: 'categorize',
    crowdsource: 'crowdsource',
    source: 'crowdsource',
    assessment: 'vote',
    vote: 'vote',
    questionnaire: 'collect',
    collect: 'collect',
    demographics: 'demographics',
    presentation: 'presentation',
    form: 'form',
};

export const activityTypesDisplayNames = {
    crowdsource: 'source',
    vote: 'vote',
    collect: 'collect',
    demographics: 'demographics',
    presentation: 'present',
    form: 'form',
    GATE: 'gate',
};

export class Activity {
    activity_description: string;
    activity_name: string;
    activity_type: string;
    activity_votes?: any;
    app: string;
    apply_groups?: boolean;
    canDownloadPresentation?: boolean;
    change_response?: boolean;
    date_created: number;
    date_last_updated: number;
    demographics?: {
        [demographicsClassKey: string]: DemographicsClass;
    };

    demographics_class_id?: string;
    dependent_nodes?: DependentNodes;
    duration?: string;
    groups?: any;
    icon: string;
    image_url?: string;
    inheritance?: ActivityInheritance;
    instructions?: string;
    key?: string;
    live: string;
    no_of_users: number;
    number_questions: number;
    owner: string;
    // eslint-disable-next-line  @typescript-eslint/naming-convention
    presentationDownload_url?: string;
    questions?: { [questionKey: string]: Question };
    sequence: number;
    status: ActivityStatus;
    sub_questions?: { [questionKey: string]: Question };
    total_std_dev?: number;
    use_demographics?: boolean;
    has_bookmark?: boolean;
    visible: boolean;
    vote_on_groups?: boolean;
    vote_reporting_options?: VoteReportingOptions;
    form_id?: string;
    name?: string;
    display_name?: string;
    selected_question_text?: string;
    app_id?: string;
    single_view?: boolean; // source activity view
    collaboration_enabled?: boolean; // form collaboration toggle field
    alignment_enabled?: boolean; // form alignment mode toggle field
    workbook_id?: string;
    workbook_map_demographics?: string;
    workbook_duplicate_sheet_demographics?: boolean;
    workbook_export_disabled?: boolean;
    workbook_selected_manually?: boolean;
    workbook_sheet_selected?: boolean;
    import_type?: 'default_sheet' | 'duplicated_sheets';
    notes_visible?: boolean;
    mapping_template_id?: string;
    move_to_jira?: 0 | 1 | 2;
    disallow_participats_accept_change?: boolean;
    jira_export_disabled?: boolean;
    jira_issue_type?: string;
    import_custom_user_name?: string;
}

export class VoteReportingOptions {
    summary_table_show?: boolean;
    details_chart_show?: boolean;
    details_chart_sort_direction?: SortDirection;
    top_x_table_show?: boolean;
    bubble_chart_show?: boolean;
    selected_demographics?: any;
    active_column?: string;
    sort_direction?: SortDirection;
    selected_column_key?: string;
    selected_graph_options?: any;
    number_of_items?: string;
    bubble_chart_x?: string;
    bubble_chart_y?: string;
    bubble_chart_r?: string;
    bubble_chart_gradient?: string;
    bubble_chart_quadrant?: string;
    hidden_datasets?: number[];
    details_chart_hidden_datasets?: number[][];
}

export class DependentNodes {
    [activityKey: string]: boolean;
}

export class ActivityInheritance {
    use_inheritance: boolean;
    activity_type: string;
    activities_ids: { [activityKey: string]: boolean };
    inheritance_type: string;
    transform_into: string;
    questions_filter?: ActivityInheritanceFilter;
    responses_filter?: ActivityInheritanceFilter;
    categories_filter?: ActivityInheritanceFilter;
    comments_filter?: ActivityInheritanceFilter;
}

export class DependentNode {
    [activityKey: string]: boolean;
}

export class ActivityMoveModel {
    activityType: string;
    activityName: string;
    contentToMove?: string;
    filterForRows?: string;
    filterForResponses?: string;
    filterValue?: number;
}

export class ActivityInheritanceFilter {
    criteria: string;
    top_x?: number;
    bottom_x?: number;
}

export type ActivityInheritanceMapHandler = (activities: Activity[]) => Activity[];

export class AgendaActivity {
    [appKey: string]: boolean;
}

export class ActivityTab {
    key?: string;
    activity_type: string;
    live: string;
    name: string;
    owner: string;
    sequence: number;
}

export class ActivityTemplate {
    activity_description: string;
    activity_name: string;
    activity_type: activityType;
    image_url: string;
    icon: string;
    sequence: number;
    active: boolean;
    display_name: string;
}

export class SessionOptions {
    agenda_visible?: boolean;
    self_navigate?: boolean;
    participants_tasks_list?: boolean;
    status?: ActivityStatus;
    anonymous?: boolean;
    responses_visible: boolean;
    enable_comments: boolean;
    enable_responses: boolean;
    show_name_tags: boolean;
    sort_by: string;
    sort_order: 'asc' | 'desc';
    view: string;
    votes: number;
    vote_on_groups?: boolean;
    enable_waiting_screen?: boolean;
    timestamp?: number; // to trigger update every time when session options updates (even when written data is the same)
    enable_change_log?: boolean;
    view_self_logs?: boolean;
    enable_notes?: boolean;
    enable_prioritization?: boolean;
    severity?: Severity[];
    enable_top_x?: boolean;
    top_x_limit?: number;
    top_x_visible?: boolean;
    enable_votes?: boolean;
    votes_limit?: number;
    votes_visible?: boolean;
    show_all_upvotes?: boolean;
    timer_data?: TimerData;
    activity_timer?: boolean;
}

export const defaultActivityInheritanceFilter = <ActivityInheritanceFilter>{
    criteria: '',
    top_x: 5,
    bottom_x: 5,
};

export const defaultActivityInheritance = <ActivityInheritance>{
    use_inheritance: false,
    activity_type: '',
    activities_ids: {},
    inheritance_type: '',
    questions_filter: defaultActivityInheritanceFilter,
    responses_filter: defaultActivityInheritanceFilter,
    categories_filter: defaultActivityInheritanceFilter,
    comments_filter: defaultActivityInheritanceFilter,
};

export const testDependentNode = <DependentNode>{
    activity1: true,
};

export const testActivityMoveModel = <ActivityMoveModel>{
    activityType: 'crowdsource',
    activityName: 'Test crowdsource activity',
    contentToMove: 'responses',
    filterForRows: 'all',
    filterForResponses: 'all',
    filterValue: 5,
};

export const testActivity = <Activity>{
    activity_description: 'Please list all the risks associated with this project',
    activity_name: 'Risks',
    activity_type: 'brainstorm',
    form_id: 'form1',
    app: 'app1',
    date_created: 1510686478630,
    date_last_updated: 1510686478630,
    live: 'All',
    icon: 'lightbulb_outline',
    image_url: 'image_url',
    members: {
        user1: 'owner',
    },
    no_of_users: 1,
    number_questions: 2,
    owner: 'user1',
    questions: {
        question1: testQuestion,
        question2: testQuestion,
    },
    sequence: 1,
    total_std_dev: 1.75,
    status: 'incomplete',
    inheritance: defaultActivityInheritance,
    key: 'activity1',
    visible: true,
    demographics_class_id: 'testClassKey',
    use_demographics: true,
};

export const testVoteActivity = <Activity>{
    ...testActivity,
    questions: {
        question1: { ...testQuestion, type: 'row' },
        question2: { ...testQuestion, type: 'row' },
        subQuestion1: { ...testQuestion, type: 'column', response_type: 'slider' },
        subQuestion2: { ...testQuestion, type: 'column', response_type: 'star_voting' },
        subQuestion3: { ...testQuestion, type: 'column', response_type: 'top_x' },
    },
    key: null,
    activity_type: 'assessment',
};

export const testAgendaActivity = <AgendaActivity>{
    activity1: true,
};

export const testActivityTab = <ActivityTab>{
    activity_type: 'brainstorm',
    live: 'flagged',
    name: 'Risks',
    owner: 'userKey',
    sequence: 1,
};

export const testActivityTemplate = <ActivityTemplate>{
    activity_description: 'This is a test activity',
    activity_name: 'Activity1',
    activity_type: 'form',
    image_url: '',
    icon: 'lightbulb_outline',
};

export const defaultSessionOptions = <SessionOptions>{
    enable_comments: true,
    enable_responses: true,
    sort_by: 'time',
    sort_order: 'asc',
    view: 'all',
    votes: 3,
    responses_visible: false,
    show_name_tags: true,
    severity: [Severity.High, Severity.Medium, Severity.Low],
    top_x_limit: 3,
    votes_limit: 5,
};

export interface TimerData {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
    is_running: boolean;
    is_paused?: boolean;
    is_visible?: boolean;
}
