<div *ngIf="(vm$ | async) as vm">
  <div *ngIf="!vm.isLoading" class="form-page">
    <div class="form-revise" *ngIf="false">
      <button
        type="button"
        class="gs-button-ghost"
        (click)="navigateToFormView('edit', vm)"
      >
        Revise Form
      </button>
    </div>
    <div class="form-impact">
      <button
        type="button"
        class="gs-button-ghost gs-small"
        (click)="navigateToFormView('impact', vm)"
      >
        Show Impact
      </button>
    </div>
    <div class="form-header">
      <p class="h5">{{ vm.form.name }}</p>
      <p class="subtitle-regular form-module-name">{{ vm.moduleName }}</p>
      <div class="form-chips">
        <span class="gs-status-chip-activity-type">Form</span>
        <span *ngIf="vm.form.global" class="gs-status-chip-global">Global</span>
      </div>
    </div>

    <div *ngIf="!isFormEmpty(vm.form.items)" class="form-body-wrapper">
      <div class="form-body">
        <form [formGroup]="form">
          <div *ngFor="let item of visibleItems; trackBy: trackById; let i = index">
            <div class="form-question-container">
              <div class="form-question-wrapper" [formGroupName]="item.id">
                <gs-section
                  *ngIf="item.type === FormItemType.Section"
                  [section]="item"
                  [assignedDemographics]="vm.assignedDemographics || []"
                  [parentForm]="getItemFormGroup(item.id, true)"
                  [global]="vm.form.global"
                  [isFormSubmitted]="isFormSubmitted(vm)"
                  [changeRequests]="vm.changeRequests || []"
                  [attachedFiles]="vm.attachedFiles || []"
                  [currentUserId]="vm.authenticatedUserId"
                  [deploymentId]="vm.deploymentId"
                  (addRepeatedSection)="onAddRepeatedSection($event, vm.deploymentId)"
                  (deleteRepeatedSection)="onDeleteRepeatedSection($event, vm.deploymentId)"
                  (requestChange)="requestChange($event, true, vm)">
                </gs-section>

                <ng-container *ngIf="item.type !== FormItemType.Section">
                  <div *ngIf="questionVisible(item)" class="form-question">
                    <h4 class="form-question-label text-medium">
                      {{ getQuestionLabel(item) }}
                      <span *ngIf="isFormItemRequired(item, vm)" class="required">*</span>
                    </h4>

                    <gs-question-view
                      *ngFor="let demographic of vm.assignedDemographics || []; trackBy: trackById;"
                      [question]="item"
                      [demographic]="demographic"
                      [parentForm]="getItemFormGroup(item.id)"
                      [deploymentId]="vm.deploymentId"
                      [authenticateUserId]="vm.authenticatedUserId"
                      [attachedFiles]="vm.attachedFiles || []">
                    </gs-question-view>
                  </div>
                </ng-container>
              </div>

              <!-- sections have their own handling within them for change requests -->
              <div
                *ngIf="isFormSubmitted(vm) && item.type !== FormItemType.Section"
                class="change-request"
              >
                <p class="caption">Change Requests: {{ numberOfChangeRequests(item, vm) }}</p>
                <button
                  class="gs-button-icon-flat"
                  [disabled]="shouldDisableChangeRequestButton(item, vm)"
                  (click)="requestChange({question: item}, false, vm)"
                >
                  <span *ngIf="!shouldDisableChangeRequestButton(item, vm)">Request Change</span>
                  <span *ngIf="shouldDisableChangeRequestButton(item, vm)">Change Requested</span>
                </button>
              </div>
            </div>
          </div>

          <div class="form-button-container">
            <button
              type="button"
              class="form-button gs-button-filled"
              [disabled]="form.invalid || isFormSubmitted(vm)"
              (click)="onSubmit(vm)"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="isFormEmpty(vm.form.items)" class="gs-block-empty">
      <gs-empty-screen
        [imageUrl]="'/assets/svg/gs-empty-form.svg'"
        [text]="'There are no questions in this form'"
      ></gs-empty-screen>
    </div>
  </div>
</div>
