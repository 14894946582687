/**
 * Image Backrounds
 *
 * Resources taken from https://ts.accenture.com/sites/BrandSpace/images
 * check what's currently there at ../assets/images/backgrounds
 * resized heights to 720 (aspect ratio maintained), so files arentr unreasonably big
 *
 */

export const imagesResourceList = [
    '1128917940.jpg',
    '1275793057.jpg',
    '129179210.jpg',
    '3568233.jpg',
    '958885.jpg',
    '991482172.jpg',
];
