import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { groupBy as _groupBy } from 'lodash';

import { ImpactView, trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-impact-table',
    templateUrl: './impact-table.component.html',
    styleUrls: ['./impact-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImpactTableComponent implements OnChanges {
    @Input() formId: string;
    @Input() formName: string;
    @Input() deploymentId: string;
    @Input() impactData: ImpactView[];

    groupedImpactData: {[questionId: string]: ImpactView[]};
    questionIds: string[];
    trackById = trackById;

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.impactData && !!this.impactData) {
            this.groupedImpactData = _groupBy(this.impactData, 'question_id');
            this.questionIds = Object.keys(this.groupedImpactData);
        }
    }
}
