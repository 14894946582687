import { FoundResponse } from '@app/core/models';
import { FormBranchingVisibility } from '@app/core/models/forms.model';
import { BaseStateModel } from '@app/root-store/models/base-state.model';
import { FormStatusesMap } from '@app/root-store/models/forms-feature.models';

export interface FormsState extends BaseStateModel {
    formsKeysByActivityKey: { [activityKey: string]: string[] };
    parentFormByActivityKey: { [activityKey: string]: { formKey: string, isFormActivitySubmitted: boolean }};
    formStatusByActivityKey: { [activityKey: string]: FormStatusesMap };
    formBranchingVisibility: { [activityKey: string]: {[questionKey: string]: FormBranchingVisibility } };
    foundResponsesViaSearch: FoundResponse[];
    loadingForSearchResponses: boolean;
    currentResponseNumber: number;
    isSearchResponsesActive: boolean;
    isNavigationActive: boolean;
}

export const initialState: FormsState = {
    loading: false,
    loadingForSearchResponses: false,
    isSearchResponsesActive: false,
    isNavigationActive: false,
    currentResponseNumber: 0,
    errorMessage: null,
    formsKeysByActivityKey: {},
    parentFormByActivityKey: {},
    formStatusByActivityKey: {},
    formBranchingVisibility: {},
    foundResponsesViaSearch: [],
};
