import {
    Component,
    Input,
    EventEmitter,
    Output,
    ChangeDetectionStrategy
} from '@angular/core';

import { Navigation } from '@app/core/models';
import { trackByKey } from '@app/core/utils';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HeaderComponent {
    @Input() isNavigation: boolean;
    @Input() currentUserKey: string;
    @Input() isAdmin: boolean;
    @Input() hasUserWorkbookPermission: boolean;
    @Input() currentUserImage: string;
    @Input() currentUserName: string;
    @Input() shortUserName: string;
    @Input() navigationItems: Navigation[];
    @Input() selectedNavigationKey: string;
    @Input() selectedNavigationItem: Navigation;
    @Input() doesUserHaveApps: boolean;
    @Input() doesUserHaveDeployments: boolean;
    @Input() hasUserLeaderSessionRole: boolean;
    @Input() fredgaged: boolean;
    @Output() logout = new EventEmitter<void>();
    @Output() openTab = new EventEmitter<string>();

    trackByKey = trackByKey;

    constructor() {}

    shouldShowNavigationItem(item: any): boolean {
        if (item.user_node === 'apps') {
            return this.doesUserHaveApps;
        } else if (item.user_node === 'deployments') {
            return this.fredgaged && this.doesUserHaveDeployments;
        }

        // always show sessions tab
        return true;
    }

    openTabFunction(navigationItemKey: string): void {
        this.openTab.emit(navigationItemKey);
    }

    logoutFunction(): void {
        this.logout.emit();
    }
}
