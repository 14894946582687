import { Component, HostListener, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';

import { LoaderService } from '@app/core/services/loader.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [RouterOutlet]
})

export class AppComponent implements OnInit {
    showLoader: boolean;

    constructor(
        private loaderService: LoaderService,
        public fbAuthUserService: AuthenticatedUserService
    ) {}

    // set last_login when logged in user open/close the application tab
    @HostListener('window:load')
    @HostListener('window:beforeunload')
    setLastLogin(): void {
        this.fbAuthUserService.setUserLastLogin();
    }

    ngOnInit() {
        this.loaderService.status.subscribe((val: boolean) => {

            // setTimeout to fix ExpressionChangedAfterItHasBeenCheckedError
            setTimeout(() => {
                this.showLoader = val;
            });
        });
    }
}
