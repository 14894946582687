import { combineLatest, Observable } from 'rxjs';
import { map, take, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { isEqual } from 'lodash';

import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { Question, Activity, FormQuestion, ActivityType } from '@app/core/models';
import { sortBySequenceAsc } from '@app/core/utils';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { defaultQuestion, defaultCrowdSourceQuestion, responseType } from '@app/core/models/question.model';
import { questionResponseTypes } from '@app/apps/apps.component.forms-constants';
import { IssueType, RequirementsQuestionMap } from '@thinktank/common-lib';

@Injectable()
export class FirebaseQuestionsService extends FirebaseUtilityService {

    constructor(
        private db: AngularFireDatabase,
        private functions: AngularFireFunctions,
        public authUserService: AuthenticatedUserService
    ) {
        super();
    }

    addInitializationQuestionTrigger(questionKey: string, activityType: string): Promise<void> {
        const triggerId = this.db.createPushId();
        const initializationData = {
            initialization_type: 'question',
            activity_type: activityType,
            question_id: questionKey
        };
        return this.db.object(`/initialization_triggers/${triggerId}`).set(initializationData);
    }

    getQuestionTitle(questionKey: string): Observable<string> {
        return this.db.object<string>(`/ssot/_questions/${questionKey}/question`).valueChanges();
    }

    getQuestionsByActivityKey(activityKey: string): Observable<Question[]> {
        return this.listWithKeys(
            this.db.list<Question>(`/ssot/_questions`, ref => ref.orderByChild('activity_id').equalTo(activityKey)));
    }

    async updateMoveToJiraFlag(activityId: string, questionId: string, allQuestionsIds: string[], value: boolean): Promise<void> {
        try {
            await this.functions
                .httpsCallable('updateMoveToJiraQuestionFlag')({
                    questionKey: questionId,
                    prevValue: value,
                    allQuestionsKeys: allQuestionsIds,
                    activityKey: activityId
                }).toPromise();
        } catch (err) {
            console.log('Move to Jira flag update error');
            console.log(err);
        }
    }

    async updateJiraIssueType(
        appKey: string,
        activityKey: string,
        questionId: string,
        jiraIssueType: IssueType
    ): Promise<void> {
        try {
            await this.updateQuestionField(appKey, activityKey, questionId, 'jira_issue_type', jiraIssueType);
        } catch (err) {
            console.log('Jira Issue Type update error');
            console.log(err);
        }
    }

    getCollectQuestions(activityKey: string): Observable<Question[]> {
        const userKey = this.authUserService.getCurrentUserId();
        const questionsWithResponseRef = this.db.list(`reportResponsesByUser/${activityKey}/${userKey}`)
            .snapshotChanges(['child_added', 'child_removed']);
        const visibleQuestionsSequenceRef = this.db.object<string>(`reportCompletedQuestions/${activityKey}/${userKey}`)
            .valueChanges();
        const allQuestionsRef = this.getQuestionsByActivityKey(activityKey);

        return combineLatest(
            questionsWithResponseRef,
            visibleQuestionsSequenceRef,
            allQuestionsRef
        )
            .pipe(
                map(([questionsWithResponse, visibleQuestionsSequence, allQuestions]) => {
                    const visibleQuestions = (visibleQuestionsSequence || '')
                        .split(',')
                        .map(sequenceString => parseInt(sequenceString, 10));
                    const questionsWithResponseKeys = questionsWithResponse.map(question => question.key);

                    return sortBySequenceAsc(allQuestions)
                        .map((question: Question) => (<Question>{
                            ...question,
                            visible: visibleQuestions.includes(question.sequence),
                            answered: questionsWithResponseKeys.includes(question.key)
                        }));
                })
            );
    }

    getQuestionsByActivityKeyRef(activityKey: string): AngularFireList<Question> {
        return this.db.list<Question>(`/ssot/_questions`, ref => ref.orderByChild('activity_id').equalTo(activityKey));
    }

    getSubQuestionsRefByActivityKey(activityKey: string): AngularFireList<Question> {
        return this.db.list<Question>(`/ssot/activities/${activityKey}/questions`, ref => ref.orderByChild('type').equalTo('column'));
    }

    getQuestionFieldsArrayRef(questionKey: string): AngularFireList<any> {
        return this.db.list<any>(`/ssot/_questions/${questionKey}`);
    }

    getQuestionField<T>(questionKey: string, field: string): Observable<T> {
        return this.db.object<T>(`/ssot/_questions/${questionKey}/${field}`).valueChanges();
    }

    getSubQuestions(activityKey: string): Observable<any> {
        return this.listWithKeys(this.getSubQuestionsRefByActivityKey(activityKey))
            .pipe(
                map(sortBySequenceAsc),
                map(questions => {
                    return questions.map((question) => {
                        const orderedChoices = Object.keys(question.choices || [])
                            .map((key) => {
                                return {
                                    ...question.choices[key],
                                    key: key
                                };
                            });
                        return {
                            ...question,
                            choicesAsArray: sortBySequenceAsc(orderedChoices)
                        };
                    });
                })
            );
    }

    getSubQuestionField(activityKey: string, questionKey: string, field: string): Observable<any> {
        return this.db.object<any>(`/ssot/activities/${activityKey}/questions/${questionKey}/${field}`).valueChanges();
    }

    getFormQuestionOptions(questionKey: string): Observable<any> {
        return this.db.object(`/ssot/_questions/${questionKey}/options`).valueChanges();
    }

    getFormQuestion(questionKey: string): Observable<any> {
        return this.db.object(`/ssot/_questions/${questionKey}`).valueChanges();
    }

    getAppQuestionsNamesMap(appKey: string): Observable<RequirementsQuestionMap> {
        const activities$ = this.db.list<Activity>('ssot/_activities', ref => ref.orderByChild('app_id').equalTo(appKey));
        let activitiesNameMap = {};
        const activitiesKeys$ = this.listWithKeys(activities$)
            .pipe(
                map(activities => {
                    activitiesNameMap = sortBySequenceAsc(activities)
                        .filter(activity => activity.activity_type === ActivityType.Form)
                        .reduce((acc, activity) => {
                            acc[activity.key] = activity.activity_name;
                            return acc;
                        }, {});

                    return Object.keys(activitiesNameMap);
                }),
                distinctUntilChanged((prev, curr) => {
                    return isEqual(prev, curr);
                })
            );
        return activitiesKeys$
            .pipe(
                switchMap(activitiesKeys => {
                    const questions = activitiesKeys.map(activityKey => this.getQuestionsByActivityKey(activityKey));
                    return combineLatest(questions);
                }),
                map(questions => {
                    const allQuestions = [].concat(...questions);
                    const sectionQuestions = this.getSectionQuestions(allQuestions);
                    const { notSubQuestions, subQuestions } = this.divideQuestions(sectionQuestions, allQuestions);

                    return notSubQuestions.reduce((acc, question) => {
                        if (this.isQuestionInRequirementsQuestionMap(question)) {
                            acc[question.key] = {
                                question: question.question,
                                activity_id: question.activity_id,
                                activity_name: activitiesNameMap[question.activity_id]
                            };
                        }
                        if (question.response_type === 'subform') {
                            subQuestions.map((item) => {
                                if (item.form_id === question.subform_id && this.isQuestionInRequirementsQuestionMap(item)) {
                                    acc[item.key] = {
                                        question: item.question,
                                        activity_id: item.activity_id,
                                        activity_name: activitiesNameMap[item.activity_id]
                                    };
                                }
                            });
                        }
                        return acc;
                    }, {});
                })
            );
    }

    async addQuestionsToVoteCollaboration(
        appKey: string,
        activityKey: string,
        requestQuestionKey: string,
        requestQuestionType: responseType,
        requestDemographics?: string[],
        hadOtherOption?: boolean
    ): Promise<any> {
        const question = await this.getFormQuestion(requestQuestionKey).pipe(take(1)).toPromise();
        if (!question) {
            return Promise.resolve();
        }
        const choices = question.options ? question.options.choices : {};
        const questionStr = question.question || '';

        // list of questions with type: rows
        const questionsList = Object.keys(choices)
            .map((choiceKey: string, index: number) => (<Question>{
                question: (questionStr && choices[choiceKey].value)
                    ? `${questionStr} - ${choices[choiceKey].value}`
                    : `${questionStr}${choices[choiceKey].value}`.trim(),
                sequence: index + 1
            }));

        // add question 'Other' if multi had other option, when collaboration was requested
        if (hadOtherOption) {
            questionsList.push(<Question>{
                question: questionStr ? `${questionStr} - Other` : 'Other',
                sequence: questionsList.length + 1
            });
        }

        // list of questions with type: column
        const subQuestionsList = [];
        const topNumber = requestQuestionType === 'single' ? 1 : 3;
        if (requestDemographics && requestDemographics.length) {
            requestDemographics.forEach((demographicName: string) => {
                subQuestionsList.push(
                    <Question>{
                        ...defaultQuestion,
                        question: demographicName,
                        response_type: 'top_x',
                        top_number: topNumber,
                        sequence: 1
                    }
                );
            });
        } else {
            subQuestionsList.push(<Question>{
                ...defaultQuestion,
                question: 'Please select',
                response_type: 'top_x',
                top_number: topNumber,
                sequence: 1
            });
        }

        return Promise.all([
            this.addQuestions(activityKey, questionsList, appKey),
            this.addSubQuestions(activityKey, subQuestionsList, appKey)
        ]);
    }

    async addQuestionsToSourceCollaboration(
        appKey: string,
        activityKey: string,
        requestQuestionKey: string,
        demographicsString: string
    ): Promise<any> {
        const question = await this.getFormQuestion(requestQuestionKey).pipe(take(1)).toPromise();
        const questionObject = {
            ...defaultCrowdSourceQuestion,
            question: question.question,
            description: demographicsString
        };

        return this.addQuestion(activityKey, questionObject, appKey);
    }

    addQuestion(activityKey: string, question: Question, appKey: string): Promise<void> {
        const positionType = 'row';
        const updates = {};
        const questionKey = this.db.createPushId();
        const triggerId = this.db.createPushId();
        const newQuestion = {
            ...question,
            response_type: question.response_type || 'text',
            number_responses: 0,
            owner: this.authUserService.getCurrentUserId()
        };
        updates[`activities/${activityKey}/questions/${questionKey}`] = newQuestion;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.getActivityType(activityKey)
            .then((activityType) => {
                updates[`ssot/_questions/${questionKey}`] =
                    this.mapQuestionForSsot(newQuestion, appKey, activityKey, activityType, positionType);
                updates[`/initialization_triggers/${triggerId}`] = {
                    initialization_type: 'question',
                    activity_type: activityType,
                    question_id: questionKey
                };
                return this.db.object('/').update(updates);
            });
    }

    addQuestions(activityKey: string, questions: Question[], appKey: string): Promise<void> {
        const positionType = 'row';
        const updates = {};
        return this.getActivityType(activityKey)
            .then((activityType) => {
                questions
                    .map((question) => ({
                        ...question,
                        owner: this.authUserService.getCurrentUserId()
                    } as Question))
                    .forEach((question) => {
                        const questionKey = this.db.createPushId();
                        const triggerId = this.db.createPushId();
                        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
                        updates[`apps/${appKey}/date_last_updated`] = Date.now();
                        updates[`activities/${activityKey}/questions/${questionKey}`] = question;
                        updates[`ssot/_questions/${questionKey}`] =
                            this.mapQuestionForSsot(question, appKey, activityKey, activityType, positionType);
                        updates[`/initialization_triggers/${triggerId}`] = {
                            initialization_type: 'question',
                            activity_type: activityType,
                            question_id: questionKey
                        };
                    });
                return this.db.object('/').update(updates);
            });
    }

    addSubQuestions(activityKey: string, subQuestions: Question[], appKey: string): Promise<void> {
        const activityType = 'vote';
        const positionType = 'column';
        const updates = {};
        subQuestions.map((question) => (<Question>{
            ...question,
            owner: this.authUserService.getCurrentUserId()
        })).forEach((question) => {
            const questionKey = this.db.createPushId();
            const triggerId = this.db.createPushId();
            updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
            updates[`apps/${appKey}/date_last_updated`] = Date.now();
            updates[`activities/${activityKey}/sub_questions/${questionKey}`] = question;
            updates[`ssot/_questions/${questionKey}`] = this.mapQuestionForSsot(question, appKey, activityKey, activityType, positionType);
            updates[`/initialization_triggers/${triggerId}`] = {
                initialization_type: 'question',
                activity_type: ActivityType.Vote,
                question_id: questionKey
            };
        });
        return this.db.object('/').update(updates);
    }

    updateQuestions(appKey: string, activityKey: string, questions: Object): Promise<void> {
        const updates = {};

        Object.keys(questions).forEach((questionKey) => {
            Object.keys(questions[questionKey]).forEach((propertyKey) => {
                updates[`activities/${activityKey}/questions/${questionKey}/${propertyKey}`] = questions[questionKey][propertyKey];
                updates[`ssot/_questions/${questionKey}/${propertyKey}`] = questions[questionKey][propertyKey];
            });
        });
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        return this.db.object('/').update(updates);
    }

    updateSubQuestions(appKey: string, activityKey: string, questions: Object): Promise<void> {
        const updates = {};

        Object.keys(questions).forEach((questionKey) => {
            Object.keys(questions[questionKey]).forEach((propertyKey) => {
                updates[`activities/${activityKey}/sub_questions/${questionKey}/${propertyKey}`] = questions[questionKey][propertyKey];
                updates[`ssot/_questions/${questionKey}/${propertyKey}`] = questions[questionKey][propertyKey];
            });
        });
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        return this.db.object('/').update(updates);
    }

    updateQuestionField(appKey: string, activityKey: string, questionKey: string, field: string, fieldValue: any): Promise<void> {
        if (typeof fieldValue === 'undefined') {
            return;
        }

        const updates = {};
        updates[`activities/${activityKey}/questions/${questionKey}/${field}`] = fieldValue;
        updates[`ssot/_questions/${questionKey}/${field}`] = fieldValue;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        return this.db.object('/').update(updates);
    }

    async removeQuestions(appKey: string, activityKey: string): Promise<void> {
        await this.db.object(`/activities/${activityKey}/questions`).remove();
        const questionSnapshots = await this.db.list(
            `ssot/_questions`,
            ref => ref.orderByChild('activity_id').equalTo(activityKey)
        )
            .snapshotChanges()
            .pipe(take(1))
            .toPromise();
        const updates = {};
        questionSnapshots.forEach((questionSnapshot) => {
            updates[`ssot/_questions/${questionSnapshot.key}`] = null;
        });
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        return this.db.object('/').update(updates);
    }

    updateSubQuestionField(appKey: string, activityKey: string, questionKey: string, field: string, fieldValue: any): Promise<void> {
        const updates = {};
        updates[`activities/${activityKey}/sub_questions/${questionKey}/${field}`] = fieldValue;
        updates[`ssot/_questions/${questionKey}/${field}`] = fieldValue;

        // TODO: Update the activity_id because we have an issue where the apps activity_id is set to a sessions activity_id
        updates[`ssot/_questions/${questionKey}/activity_id`] = activityKey;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        return this.db.object('/').update(updates);
    }

    async removeQuestion(appKey: string, activityKey: string, questionKey: string): Promise<any> {
        try {
            await this.functions.httpsCallable('updatePropertiesRelatedToResponse')({
                appKey,
                activityKey,
                questionKey,
                responseKey: null
            }).toPromise();
        } catch (err) {
            console.log('Remove source question error');
            console.log(err);
        }
    }

    removeSubQuestion(appKey: string, activityKey: string, questionKey: string): Promise<any> {
        const updates = {};
        updates[`activities/${activityKey}/sub_questions/${questionKey}`] = null;
        updates[`ssot/_questions/${questionKey}`] = null;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        return this.db.object('/').update(updates);
    }

    getVoteQuestions(activityKey: string): Observable<Question[]> {
        return this.listWithKeys(
            this.db.list<Question>(
                '/ssot/_questions',
                ref => ref.orderByChild('activity_id').equalTo(activityKey)
            )
        )
            .pipe(
                map(questions => (questions || []).filter(question => question.type === 'row')),
                map(sortBySequenceAsc)
            );
    }

    getVoteSubQuestions(activityKey: string): Observable<Question[]> {
        return this.listWithKeys(
            this.db.list<Question>(
                '/ssot/_questions',
                ref => ref.orderByChild('activity_id').equalTo(activityKey)
            )
        )
            .pipe(
                map(questions => (questions || [])
                    .filter(question => question.type === 'column')
                    .map((question) => {
                        const orderedChoices = Object.keys(question.choices || [])
                            .map((key) => ({
                                ...question.choices[key],
                                key: key
                            }));
                        return {
                            ...question,
                            choicesAsArray: sortBySequenceAsc(orderedChoices)
                        };
                    })
                ),
                map(sortBySequenceAsc)
            );
    }

    updateFlag(activityKey: string, questionKey: string, flagged: boolean): Promise<void> {
        const updates = {};
        updates[`activities/${activityKey}/questions/${questionKey}/flagged`] = flagged;
        updates[`ssot/_questions/${questionKey}/flagged`] = flagged;
        return this.db.object('/').update(updates);
    }

    updateFormQuestionOptions(questionKey: string, dataToUpdate: Object, appKey: string): Promise<void> {
        const updates = {};
        Object.keys(dataToUpdate).forEach(field => {
            updates[`ssot/_questions/${questionKey}/options/${field}`] = dataToUpdate[field];
        });
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        return this.db.object('/').update(updates);
    }

    updateQuestion(appKey: string, activityKey: string, questionKey: string, value: any): Promise<string> {
        const positionType = 'row';
        const updates = {};

        return this.getActivityType(activityKey)
            .then((activityType) => {
                updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
                updates[`apps/${appKey}/date_last_updated`] = Date.now();
                if (!questionKey) {
                    questionKey = this.db.createPushId();
                    updates[`activities/${activityKey}/questions/${questionKey}`] = value;
                    updates[`ssot/_questions/${questionKey}`] =
                        this.mapQuestionForSsot(value, appKey, activityKey, activityType, positionType);
                } else {
                    // The properties have to be written to individually since this is a multi-path update
                    Object.keys(value).forEach((valueKey) => {
                        updates[`activities/${activityKey}/questions/${questionKey}/${valueKey}`] = value[valueKey];
                        updates[`ssot/_questions/${questionKey}/${valueKey}`] = value[valueKey];
                    });
                }
                this.db.object('/').update(updates);

                return Promise.resolve(questionKey);
            });
    }

    async updateSubQuestion(appKey: string, activityKey: string, questionKey: string, value: any): Promise<string> {
        const activityType = 'vote';
        const positionType = 'column';
        const updates = {};

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        if (!questionKey) {
            questionKey = this.db.createPushId();
            updates[`activities/${activityKey}/sub_questions/${questionKey}`] = value;
            updates[`ssot/_questions/${questionKey}`] = this.mapQuestionForSsot(value, appKey, activityKey, activityType, positionType);
        } else {
            // The properties have to be written to individually since this is a multi-path update
            Object.keys(value).forEach((valueKey) => {
                updates[`activities/${activityKey}/sub_questions/${questionKey}/${valueKey}`] = value[valueKey];
                updates[`ssot/_questions/${questionKey}/${valueKey}`] = value[valueKey];
            });
        }
        await this.db.object('/').update(updates);
        return Promise.resolve(questionKey);
    }

    removeQuestionField(appKey: string, activityKey: string, questionKey: string, field: string): Promise<void> {
        const updates = {};
        updates[`activities/${activityKey}/questions/${questionKey}/${field}`] = null;
        updates[`ssot/_questions/${questionKey}/${field}`] = null;
        return this.db.object('/').update(updates);
    }

    removeSubQuestionField(appKey: string, activityKey: string, questionKey: string, field: string): Promise<void> {
        const updates = {};
        updates[`activities/${activityKey}/sub_questions/${questionKey}/${field}`] = null;
        updates[`ssot/_questions/${questionKey}/${field}`] = null;
        return this.db.object('/').update(updates);
    }

    updateDefaultResponses(activityKey: string, questionKey: string, defaultResponses: any): Promise<void> {
        const updates = {};
        updates[`activities/${activityKey}/questions/${questionKey}/default_responses`] = defaultResponses;
        updates[`ssot/_questions/${questionKey}/default_responses`] = defaultResponses;
        return this.db.object('/').update(updates);
    }

    async updateFormQuestionBranching(question: FormQuestion, branchingData: any): Promise<void> {
        if (!branchingData) {
            await this.db.object(`/ssot/_questions/${question.key}/form_branching/`).remove();
        } else {
            await this.db.object(`/ssot/_questions/${question.key}/form_branching/`).update(branchingData);
        }

        this.functions.httpsCallable('formBranchingUpdate')({
            question_id: question.key
        }).toPromise().catch(error => console.error('formBranchingUpdate: ', error));
    }

    private mapQuestionForSsot(question: any, appKey: string, activityKey: string, activityType: string, positionType?: string): Question {
        const ssotQuestion = <Question>{
            ...question,
            app_id: appKey,
            activity_id: activityKey,
            activity_type: activityType,
            owner_id: this.authUserService.getCurrentUserId(),
            type: positionType
        };
        delete ssotQuestion.owner;

        return ssotQuestion;

    }

    getActivityType(activityKey: string): Promise<string> {
        return this.db.object<Activity>(`activities/${activityKey}`)
            .valueChanges()
            .pipe(take(1))
            .toPromise()
            .then((activity) => {
                return activity ? activity.activity_type : '';
            });
    }


    private getSectionQuestions(allQuestions): FormQuestion[] {
        return allQuestions.filter((item: FormQuestion) => item.response_type === 'subform');
    }

    private isQuestionInRequirementsQuestionMap(question: FormQuestion): boolean {
        return questionResponseTypes.includes(question.response_type) && question.response_type !== 'attachment';
    }

    private divideQuestions(sectionQuestions, allQuestions): { notSubQuestions: FormQuestion[], subQuestions: FormQuestion[] } {
        let subQuestions = [];
        let notSubQuestions = allQuestions;
        if (!!sectionQuestions.length) {
            subQuestions = [];
            notSubQuestions = [];
            allQuestions.forEach((item: FormQuestion) => {
                let isSubQuestion = false;

                sectionQuestions.forEach((question: FormQuestion) => {
                    if (item.form_id === question.subform_id) {
                        subQuestions.push(item);
                        isSubQuestion = true;
                    }
                });

                if (!isSubQuestion) {
                    notSubQuestions.push(item);
                }
            });
        }
        return { notSubQuestions: sortBySequenceAsc(notSubQuestions), subQuestions: sortBySequenceAsc(subQuestions) };
    }
}
