import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { isEqual } from 'lodash';

import { User, UserSessionRole, UserTemplateRole } from '@app/core/models';
import { trackByKey } from '@app/core/utils';

@Component({
    selector: 'app-current-users-app-management',
    templateUrl: './current-users-app-management.component.html',
    styleUrls: ['./current-users-app-management.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CurrentUsersAppManagementComponent implements OnInit {
    @Input() users: User[];
    @Input() userRoles: UserSessionRole[] | UserTemplateRole[];
    searchValue: string;
    roleControl: FormControl;

    trackByKey = trackByKey;

    applySearch(value: string): void {
        this.searchValue = (value || '').trim();
    }

    showCustomTrigger(selectedValues: string, allSelected: MatOption): string {
        return allSelected.selected ? allSelected.viewValue : selectedValues;
    }

    selectionChange(control: FormControl, statuses: UserSessionRole[] | UserTemplateRole[], all: MatOption): void {
        const isAll = (control.value || []).includes('all');

        if (!isAll && isEqual(control.value, statuses)) {
            all.select();
        }

        if (isAll && !isEqual(control.value, ['all', ...statuses])) {
            all.deselect();
        }
    }

    selectAll(control: FormControl, statuses: UserSessionRole[] | UserTemplateRole[]): void {
        const updatedValue = (control.value || []).includes('all') ? ['all', ...statuses] : [];

        control.setValue(updatedValue);
    }

    ngOnInit(): void {
        this.roleControl = new FormControl(['all', ...this.userRoles]);
    }
}
