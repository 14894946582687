<div class="alert-dialog">
  <h3 mat-dialog-title class="h3">{{alertTitle}}</h3>
  <mat-dialog-content class="alert-content text">
    {{alertMessage}}
  </mat-dialog-content>
  <mat-dialog-actions class="alert-actions">
    <button cdkFocusInitial class="tt-button-filled" (click)="onSubmit()">
      {{alertConfirmBtnText}}
    </button>
  </mat-dialog-actions>
</div>
