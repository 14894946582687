import { Members } from './member.model';
import { User } from '@app/core/models/user.model';

export class Comment {
    date_created: number;
    flagged: boolean;
    total_likes: number;
    owner?: string;
    value: string;
    likes?: Members[];
    user?: User;
}

export const testComment = <Comment>{
    date_created: 1510686478630,
    flagged: true,
    total_likes: 3,
    owner: 'user1',
    value: 'Great Thought'
};
