<div class="attachments-dialog">
  <h3 mat-dialog-title class="h3">{{title}}</h3>
  <mat-dialog-content class="attachments-content">
    <mat-table class="attachments-table" [dataSource]="attachments">
      <ng-container matColumnDef="name">
        <mat-header-cell class="attachments-column attachments-column-name" *matHeaderCellDef>Name</mat-header-cell>
        <mat-cell class="attachments-column attachments-column-name" *matCellDef="let attachment">{{attachment.name}}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="download">
        <mat-header-cell class="attachments-column" *matHeaderCellDef>Download</mat-header-cell>
        <mat-cell class="attachments-column" *matCellDef="let attachment">
          <button mat-icon-button (click)="downloadFile(attachment)">
            <mat-icon>file_download</mat-icon>
          </button>

        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="actions">
        <mat-header-cell class="attachments-column" *matHeaderCellDef>Actions</mat-header-cell>
        <mat-cell class="attachments-column" *matCellDef="let attachment">
          <button mat-icon-button (click)="removeFile(attachment)">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
  </mat-dialog-content>
  <mat-dialog-actions class="attachments-dialog-actions">
    <button mat-dialog-close class="tt-button-outline">{{data.cancelBtnText}}</button>
  </mat-dialog-actions>
</div>
