import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { isUndefined } from 'lodash';

import { ImageLightboxRef } from '../image-lightbox-ref';
import { IMAGE_LIGHTBOX_DATA } from '../image-lightbox.model';

@Component({
    selector: 'app-image-lightbox',
    templateUrl: 'image-lightbox.component.html',
    styleUrls: ['image-lightbox.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageLightboxComponent {
    private scale = 1;
    private shiftX = 0;
    private shiftY = 0;
    private previousScreenX: number;
    private previousScreenY: number;

    constructor(
        public dialogRef: ImageLightboxRef,
        @Inject(IMAGE_LIGHTBOX_DATA) public image: string
    ) {}

    get isResetDisabled(): boolean {
        return this.scale === 1;
    }

    get transformStyles(): {[property: string]: string} {
        const transformFields = ['-webkit-transform', '-ms-transform', 'transform'];
        const scaleRounded = +this.scale.toFixed(2);
        const transformValue = `translateX(-50%) translateX(${this.shiftX}px)
            translateY(-50%) translateY(${this.shiftY}px) scale(${scaleRounded})`;
        return transformFields.reduce((result: any, field: string) => {
            result[field] = transformValue;
            return result;
        }, {});
    }

    dragImage(event: MouseEvent): void {

        // we allow to grag image only when left mouse key is pressed and when scale is more then one
        if (event.buttons !== 1 || this.scale <= 1) {
            return;
        }

        const currentShiftX = !isUndefined(this.previousScreenX) ? event.screenX - this.previousScreenX : 0;
        const currentShiftY = !isUndefined(this.previousScreenY) ? event.screenY - this.previousScreenY : 0;
        this.shiftX += currentShiftX;
        this.shiftY += currentShiftY;
        this.previousScreenX = event.screenX;
        this.previousScreenY = event.screenY;
    }

    onDragEnd(): void {
        this.previousScreenX = undefined;
        this.previousScreenY = undefined;
    }

    zoomIn(): void {
        this.scale *= 1.5;
        this.resetShift();
    }

    zoomOut(): void {
        this.scale /= 1.5;
        this.resetShift();
    }

    resetZoom(): void {
        this.scale = 1;
        this.resetShift();
    }

    close(): void {
        this.dialogRef.close();
    }

    private resetShift(): void {
        this.shiftX = 0;
        this.shiftY = 0;
    }
}
