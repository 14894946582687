import { BehaviorSubject, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { FirebaseDemographicsService } from '@app/core/services/demographics.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FormStatus, NotificationType, formStatusesMap, WorkFlowCard, ReviewState } from '@app/core/models';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService extends FirebaseUtilityService {
    isLastCardLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private firebaseDemographicsService: FirebaseDemographicsService,
        private db: AngularFireDatabase
    ) {
        super();
    }

    getNotificationsPanelLoadedAsObservable(): Observable<boolean> {
        return this.isLastCardLoaded.asObservable();
    }

    setNotificationsPanelAsLoaded(isLoaded: boolean): void {
        this.isLastCardLoaded.next(isLoaded);
    }

    async checkNotificationVisibility(workFlowCard: WorkFlowCard, isLeader: boolean): Promise<boolean> {
        const notification = await this.getWorkFlowCardsContent(workFlowCard.type, workFlowCard.notification_id).pipe(take(1)).toPromise();
        const userRole = this.authenticatedUserService.getCurrentUserSessionRole(notification.app_id);
        // we dont want observers being notified
        if (!notification || userRole === 'observer') {
            return false;
        }

        if (notification.state === FormStatus.DraftReview) {
            // we either get a real demo key OR 'default' when the form has no demographics
            // this nonsense with nesting arrays just to use the existing functions is quite a headache...
            const containedDemographicKey = notification.demographics[notification.form_id][0];
            const doesUserHaveTheDemographicForNotification = (containedDemographicKey !== 'default')
                ? await this.getNotificationVisibility(notification)
                : true;

            return isLeader || doesUserHaveTheDemographicForNotification;
        }

        if (notification.state === FormStatus.Alignment) {
            // we either get a real demo key OR 'default' when the form has no demographics
            // this nonsense with nesting arrays just to use the existing functions is quite a headache...
            const demographics = 'demographic' in notification ? notification.demographic : notification.demographics;
            const containedDemographicKey = demographics[notification.form_id][0];
            const doesUserHaveTheDemographicForNotification = (containedDemographicKey !== 'default')
                ? await this.getNotificationVisibility(notification)
                : true;

            return isLeader || doesUserHaveTheDemographicForNotification;
        }

        if (formStatusesMap[notification.state] === FormStatus.PendingApproval) {
            return isLeader;
        }

        if (formStatusesMap[notification.state] === FormStatus.Approved) {
            if (workFlowCard.type === 'collaboration_requests') {
                return true;
            }

            if (workFlowCard.type === 'reviews') {
                const getApprovedReviewVisibilityValue = await this.getNotificationVisibility(notification);

                return isLeader || getApprovedReviewVisibilityValue;
            }
        }

        if (formStatusesMap[notification.state] === FormStatus.Denied) {
            if (workFlowCard.type === 'collaboration_requests') {
                return isLeader || this.authenticatedUserService.getCurrentUserId() === notification.participant_id;
            }

            if (workFlowCard.type === 'reviews') {
                const getApprovedReviewVisibilityValue = await this.getNotificationVisibility(notification);

                return isLeader || getApprovedReviewVisibilityValue;
            }
        }

        return true;
    }

    private async getNotificationVisibility(notification: any): Promise<boolean> {
        const parentFormKey = notification.form_id;
        const formDemographicsKeys = (notification.demographics || {})[parentFormKey] || [];

        if (!notification.demographics) {
            return true;
        }

        const userSelectedDemographicsClasses =
            await this.firebaseDemographicsService.getAllSelectedDemographics().pipe(take(1)).toPromise();
        let userSelectedDemographics = {};

        Object.keys(userSelectedDemographicsClasses || {}).forEach((demographicsClassKey) => {
            const selectedDemographics = userSelectedDemographicsClasses[demographicsClassKey].options;

            userSelectedDemographics = {
                ...userSelectedDemographics,
                ...selectedDemographics
            };
        });

        return !!formDemographicsKeys.find((formDemographicsKey) => userSelectedDemographics[formDemographicsKey]);
    }

    getWorkFlowCardsByAppId(app_id: string, isLeader): Observable<Promise<WorkFlowCard[]>> {
        return this.listWithKeys(
            this.db.list(
                '/ssot/_workflow',
                ref => ref.orderByChild('app_id').equalTo(app_id)
            )
        ).pipe(
            map(async (workFlowCards: WorkFlowCard[]) => {
                const filteredCards = [];
                await Promise.all(
                    workFlowCards.map(async (workFlowCard) => {
                        if (await this.checkNotificationVisibility(workFlowCard, isLeader)) {
                            filteredCards.push(workFlowCard);
                        }
                    })
                );

                return filteredCards;
            }),
            map(async (filteredCards: Promise<WorkFlowCard[]>) => {
                const workFlowCards = await filteredCards;
                return workFlowCards.sort((a, b) => -(a.date_created - b.date_created));
            })
        );
    }

    getWorkFlowCardKeyByReviewKey(reviewKey: string): Observable<string> {
        return this.listWithKeys(this.db.list('/ssot/_workflow', ref => ref.orderByChild('notification_id').equalTo(reviewKey)))
            .pipe(
                map((workFlowCard: WorkFlowCard[]) => {
                    return workFlowCard[0] ? workFlowCard[0].key : null;
                })
            );
    }

    getWorkFlowCardsContent(type: string, key: string): Observable<any> {
        return this.objectWithKey(this.db.object(`/ssot/_${type}/${key}`));
    }

    getWorkFlowCardStatus(type: string, key: string): Observable<any> {
        return this.db.object<any>(`/ssot/_${type}/${key}/state`).valueChanges();
    }

    updateWorkFlowDate(workflowCardKey: string, date?: number): Promise<any> {
        const updates = {};
        updates[`ssot/_workflow/${workflowCardKey}/date_created`] = date || +(new Date());

        return this.db.object('/').update(updates);
    }

    updateWorkFlowCardField(
        type: NotificationType,
        requestKey: string,
        field: string,
        value: any
    ): Promise<any> {
        const updates = {};
        updates[`ssot/_${type}/${requestKey}/${field}`] = value;

        return this.db.object('/').update(updates);
    }

    async updateWorkFlowCardStatus(
        type: NotificationType,
        workflowCardKey: string,
        requestKey: string,
        status: ReviewState,
        activityKey?: string
    ): Promise<any> {
        const updates = {};
        const reviewerKey = this.authenticatedUserService.getCurrentUserId();

        updates[`ssot/_${type}/${requestKey}/state`] = status;
        updates[`ssot/_${type}/${requestKey}/reviewer_id`] = reviewerKey;
        updates[`ssot/_${type}/${requestKey}/date_created`] = +(new Date());

        // Also update workflow card date
        this.updateWorkFlowDate(workflowCardKey);

        if (activityKey) {
            updates[`ssot/_${type}/${requestKey}/collaboration_activity_id`] = activityKey;
            updates[`ssot/_${type}/${requestKey}/collaboration_activity_status`] = 'incomplete';
        }

        await this.db.object('/').update(updates);
        return this.db.object(`ssot/_${type}/${requestKey}`)
            .valueChanges()
            .pipe(take(1))
            .toPromise();
    }
}
