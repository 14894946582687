import { InjectionToken } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { FormState, FormImpactState, CondensedFormState } from '../models';
import {
    Attachment,
    Demographic,
    FormQuestion,
    RepeatedSectionAddInfo,
    RepeatedSectionDeleteInfo,
    RepeatedSectionDescriptionUpdate,
    FormSubmittal,
    ImpactView
} from '@thinktank/common-lib';

export interface DeploymentFormFacade {
    vm$: Observable<FormState>;
    ivm$: Observable<FormImpactState>;
    cvm$: Observable<CondensedFormState>;

    deleteFormAttachment(currentAttachments: Attachment[], attachment: Attachment, question: FormQuestion, demographic: Demographic): void;
    openFilePicker(): void;
    transformVideoUrlToEmbed(questionVideoUrl): SafeResourceUrl;
    getFormImpactData(): Observable<ImpactView[]>;
    addRepeatedSection(repeatedSectionInfo: RepeatedSectionAddInfo, deploymentId: string): Promise<void>;
    deleteRepeatedSection(sectionInfo: RepeatedSectionDeleteInfo, deploymentId: string): Promise<void>;
    submitForm(formSubmittal: FormSubmittal): void;
    updateResponse(response: any, deploymentId: string, userId: string): void;
    updateSectionDescription(descriptionUpdate: RepeatedSectionDescriptionUpdate): void;
    navigateToLocation(navigationItems: any[]): void;

}

export const DeploymentFormFacadeToken = new InjectionToken<DeploymentFormFacade>('DeploymentFormFacade')
