import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireDatabase } from '@angular/fire/compat/database';

import { FormCollaborationRequest } from '@app/core/models/collaboration-request.model';
import {
    FirebaseUtilityService,
    AuthenticatedUserService,
    FirebaseDemographicsService
} from '@app/core/services';
import { DemographicNames } from '@app/core/models';

@Injectable()
export class FormCollaborationService extends FirebaseUtilityService {

    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private fbDemographicsService: FirebaseDemographicsService
    ) {
        super();
    }

    // TODO-demographics: consider to replace
    getDemographicNamesDictionary(appKey: string): Observable<DemographicNames> {
        return this.fbDemographicsService.getDemographicNamesDictionary(appKey);
    }

    getRequestsByQuestionKey(questionKey: string): Observable<FormCollaborationRequest[]> {
        return this.listWithKeys(
            this.db.list(`ssot/_collaboration_requests`, ref => ref.orderByChild('question_id').equalTo(questionKey))
        );
    }

    getCollaborationRequest(collaborationRequestKey: string): Observable<FormCollaborationRequest> {
        return this.db.object<FormCollaborationRequest>(`ssot/_collaboration_requests/${collaborationRequestKey}`).valueChanges();
    }

    addCollaborationRequest(
        collaborationRequest: FormCollaborationRequest
    ): Promise<void> {
        const participantId = this.authUserService.getCurrentUserId();
        const newCollaborationRequest = {
            ...collaborationRequest,
            participant_id: participantId,
            date_created: +(new Date())
        };
        const newCollaborationRequestKey = this.db.createPushId();
        const updates = {};
        updates[`ssot/_collaboration_requests/${newCollaborationRequestKey}`] = newCollaborationRequest;

        return this.db.object('/').update(updates);
    }
}
