import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-copy-app-dialog',
    templateUrl: './copy-app-dialog.component.html',
    styleUrls: ['./copy-app-dialog.component.scss']
})
export class CopyAppDialogComponent implements OnInit {

    appConfigControl: FormControl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<CopyAppDialogComponent>
    ) { }

    handleConfirm(): void {
        this.data.handleConfirm(this.appConfigControl.value);
        this.dialogRef.close();
    }

    configValidator(control: AbstractControl): ValidationErrors | null {
        if (!control.value) {
            return null;
        }
        let configRequiredFields: boolean;

        try {
            const config = JSON.parse(control.value);
            configRequiredFields = !!config.project_id
                && !!config.private_key
                && !!config.client_email;
        } catch {
            configRequiredFields = false;
        }


        return configRequiredFields ? null : { requiredFields: true };
    }

    ngOnInit(): void {
        this.appConfigControl = new FormControl('', [
            Validators.required,
            this.configValidator
        ]);
    }

}
