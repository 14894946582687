import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticatedUserService } from '../services/authenticated-user.service';
import { AppState } from '@app/root-store/state';
import { getAllFeatureToggles } from '@app/root-store/features/feature-toggles/feature-toggles.selectors';
import { loadFeatureToggles } from '@app/root-store/features/feature-toggles/feature-toggles.actions';

import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile, take, tap } from 'rxjs/operators';

@Injectable()
export class AuthenticationGuard implements CanActivate, CanActivateChild {

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private router: Router,
        private store: Store<AppState>
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return combineLatest([
            this.authenticatedUserService.isUserAuthenticated(),
            this.store.select(getAllFeatureToggles).pipe(take(1))
        ]).pipe(
            skipWhile(([ isUserAuthenticated ]) => isUserAuthenticated === undefined),
            tap(([ isUserAuthenticated, featureToggles ]) => {
                // Use tap here instead of map to handle side effects. Use the map below to return just the isUserAuthenticated
                if (isUserAuthenticated) {
                    if (Object.keys(featureToggles).length === 0) {
                        this.store.dispatch(loadFeatureToggles());
                    }
                } else {
                    const redirectTo = encodeURIComponent(state.url);
                    this.router.navigate(['/login'], {
                        queryParams: {
                            redirectTo,
                        },
                    });
                }
            }),
            map(([ isUserAuthenticated ]) => isUserAuthenticated)
        );
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.canActivate(route, state);
    }
}
