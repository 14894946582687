import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';

import { TaskService } from '@app/core/services/tasks.service';
import { TasksCount } from '@thinktank/common-lib';

@Component({
    selector: 'app-tasks-count-container',
    templateUrl: './tasks-count-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TasksCountContainerComponent implements OnChanges {
    @Input() projectKey: string;

    tasksCount$: Observable<TasksCount>;

    constructor(private taskService: TaskService) {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes.projectKey && changes.projectKey.currentValue) {
            this.tasksCount$ = this.taskService.getTasksCountByUser(this.projectKey);
        }
    }
}
