import { createSelector } from '@ngrx/store';

import { Demographic, DemographicsClass } from '@app/core/models';
import { AppState } from '@app/root-store/state';
import { DemographicsState } from '../state';
import { firebaseIdPattern } from '@app/core/constants';
import { formsKeysByActivityKeySelector } from '../../forms/selectors';

export const demographicState = (state: AppState) => state.demographics;

export const isLoadingSelector = createSelector(
    demographicState,
    (state) => state.loading
);

export const demographicsSelector = createSelector(
    demographicState,
    (state: DemographicsState, { classKey }: { classKey: string }) => {
        return state.demographicsByClassKey[classKey] || [];
    }
);

export const demographicsObjectSelector = createSelector(
    demographicState,
    (state: DemographicsState) => state.demographicsByClassKey
);

export const selectedDemographicsSelector = createSelector(
    demographicState,
    (state: DemographicsState, props: { userKey: string, classKey: string }) =>
        state.selectedDemographicsByClassKey[`${props.classKey}_${props.userKey}`] || []
);

export const selectedDemographicsObjectSelector = createSelector(
    demographicState,
    (state: DemographicsState) => state.selectedDemographicsByClassKey
);

export const hasSelectedDemographicsSelector = createSelector(
    demographicState,
    (state: DemographicsState, props: { userKey: string, classKey: string }) =>
        !!state.selectedDemographicsByClassKey[`${props.classKey}_${props.userKey}`]
);

export const selectedDemographicsKeysMapSelector = createSelector(
    selectedDemographicsSelector,
    (demographicArray: Demographic[], props: { userKey: string, classKey: string }) =>
        demographicArray
            .reduce((result: any, demographic: Demographic) => {
                result[demographic.key] = true;
                return result;
            }, {}) as { [demographicKey: string]: boolean }
);

export const formDemographicsSelector = createSelector(
    demographicState,
    (state: DemographicsState, props: { userKey: string, classKey: string, formKey: string }) =>
        state.formDemographics[`${props.classKey}_${props.userKey}_${props.formKey}`] || []
);

export const parentFormActiveDemographics = createSelector(
    demographicState,
    (state: DemographicsState, props: { formKey: string }) =>
        state.parentFormActiveDemographics[props.formKey] || null
);


export const formDemographicsByFilterSelector = createSelector(
    demographicState,
    (state: DemographicsState, props: { userKey: string, classKey: string, formKey: string, filter?: boolean }) => {
        const formDemographics = state.formDemographics[`${props.classKey}_${props.userKey}_${props.formKey}`] || [];

        return props.filter
            ? formDemographics.filter(dem => dem.active)
            : formDemographics;
    }
);

export const activeFormDemographicKeysSelector = createSelector(
    demographicState,
    (state: DemographicsState, props: { userKey: string, classKey: string, formKey: string }) =>
        state.formActiveDemographicsKeys[`${props.classKey}_${props.userKey}_${props.formKey}`] || null
);

export const formActiveDemographicsKeysObjectSelector = createSelector(
    demographicState,
    (state: DemographicsState) => state.formActiveDemographicsKeys
);

export const currentUserActiveDemographicsKeysSelector = createSelector(
    demographicState,
    formsKeysByActivityKeySelector,
    (state: DemographicsState, formsIds: string[], props: { currentUserId: string, useDemographics: boolean, activityId: string }) => {
        const demographicsKeys = Object.keys(state && state.formActiveDemographicsKeys || {});
        const activeDemographicsByFormId = {};

        demographicsKeys
            .forEach(demoKey => {
                const demographics = state.formActiveDemographicsKeys && state.formActiveDemographicsKeys[demoKey];
                const [classId, userId, formId] = demoKey.match(firebaseIdPattern);
                if (demographics && userId === props.currentUserId && formsIds.includes(formId)) {
                    activeDemographicsByFormId[formId] = state.formActiveDemographicsKeys[demoKey];
                }
            });

        return activeDemographicsByFormId;
    }
);

export const formDemographicClassIdSelector = createSelector(
    demographicState,
    (state: DemographicsState, props) =>
        state.formDemographicsClassId[props.formKey] || null
);

export const formDemographicClassIdObjectSelector = createSelector(
    demographicState,
    (state: DemographicsState) => state.formDemographicsClassId
);

export const errorMessageSelector = createSelector(
    demographicState,
    (state: DemographicsState) => state.errorMessage
);

export const demographicsNamesMapSelector = createSelector(
    demographicsSelector,
    (demographicsArray: Demographic[]) => demographicsArray.map(demographic => demographic.name)
);

export const activeDemographicsSelector = createSelector(
    demographicsSelector,
    (demographicsArray: Demographic[]) => demographicsArray.filter(demographic => demographic.active)
);

export const inactiveDemographicsSelector = createSelector(
    demographicsSelector,
    (demographicsArray: Demographic[]) => demographicsArray.filter(demographic => !demographic.active)
);

export const selectedDemographicsNamesMapSelector = createSelector(
    selectedDemographicsSelector,
    (selectedDemographicsArray: Demographic[]) => selectedDemographicsArray.map(demographic => demographic.name)
);

export const getFormDemographicsSelector = createSelector(
    [
        demographicsSelector,
        selectedDemographicsKeysMapSelector,
        activeFormDemographicKeysSelector,
        formDemographicClassIdSelector
    ],
    (
        classDemographics: Demographic[],
        selectedDemographicsKeysMap: { [demographicKey: string]: boolean },
        formActiveDemographics: string | string[],
        formDemographicsClassId: string,
        props: {
            userKey: string,
            classKey: string,
            formKey: string,
            limitLeaderInitiallyTo: number,
            isLeader: boolean,
            filter?: boolean
        }
    ) => {
        const isActiveDemographicsArray = Array.isArray(formActiveDemographics);

        const isFormActiveDemographicsNotInitialized = !formActiveDemographics;
        if (isFormActiveDemographicsNotInitialized) {
            formActiveDemographics = 'all';
        }

        const demographics = classDemographics
            .filter((demographic: Demographic, index: number) => {
                // Here is where we check to see if we are limiting the leader to a certain number of demographics
                // when formActiveDemographics is not defined
                if (props.isLeader && isFormActiveDemographicsNotInitialized) {
                    return index < props.limitLeaderInitiallyTo;
                }
                // leader can see all demographics from class
                return props.isLeader
                    ? true
                    : !!selectedDemographicsKeysMap[demographic.key];
            })
            .map((demographic: Demographic) => {
                const isGivenDemographicActive = isActiveDemographicsArray
                    ? formActiveDemographics.includes(demographic.key)
                    : false;
                const isOneOfAll = formActiveDemographics === 'all';

                // if formActiveDemographics returns 'empty' it means that all demographics are inactive after user's interacted
                return {
                    ...demographic,
                    active: formActiveDemographics !== 'empty'
                        && (isOneOfAll || isGivenDemographicActive)
                };
            });

        // we have to init initial demographics in the case when it is not defined
        // or when they were defined with the value 'all'
        const initialActiveDemographics = demographics.filter(demographic => demographic.active);
        const needWriteInitialActiveDemographics = formDemographicsClassId
            && (isFormActiveDemographicsNotInitialized || formActiveDemographics === 'all');

        return {
            formKey: props.formKey,
            classKey: props.classKey,
            demographics: props.filter
                ? [...demographics.filter(demographic => demographic.active)]
                : [...demographics],
            initialActiveDemographics,
            needWriteInitialActiveDemographics
        };
    },
);

export const demographicsByClassKeysMapSelector = createSelector(
    demographicState,
    (state: DemographicsState, props: { demographicsClasses: DemographicsClass[] }) => {
        const demographicsByClassKeysMap = {};
        (props.demographicsClasses || []).forEach((demographicsClass) => {
            const demographicsForCurrentClass = state.demographicsByClassKey && state.demographicsByClassKey[demographicsClass.key];
            if (demographicsForCurrentClass) {
                demographicsByClassKeysMap[demographicsClass.key] = demographicsForCurrentClass;
            }
        });

        return demographicsByClassKeysMap;
    }
);
