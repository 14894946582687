import { Observable } from 'rxjs';
import { Timestamp } from 'firebase/firestore';
import { answerAction } from '@app/core/models';

class CommonResponse {
    date_created: number;
    type: string;
    total_likes?: number;
    total_comments?: number;
    value: any;
    question_id?: string;
    activity_id?: string;
    owner_id?: string;
    key?: string;
}

export class Responses {
    [responseKey: string]: boolean;
}

export class FoundResponse {
    date_written: number;
    demographics_id?: string;
    parent_demographic_id?: string;
    form_id: string;
    form_status: string;
    owner_id: string;
    question_id: string;
    value: any;
    id: string;
    repeatable_question_id?: string;
    parent_question_id?: string;
    attachmentValue?: string;
    attachmentKey?: string;
}

export class SearchForResponsesData {
    activityId: string;
    useDemographics: boolean;
    activeDemographicsKeys: any;
    searchValue: string;
    timezoneOffset: number;
    currentViewDataFilterValue: string;
    demographicsClassId: string | undefined;
}

export class Response extends CommonResponse {
    $key?: string;
    key?: string;
    sequence: number;
    comments?: Observable<any>;
    likes?: Observable<any>;
    category?: string;
    flagged?: boolean;
    live?: string;
    owner: string;
    user?: string;
    sub_categories?: string;
    type: 'response';
    response_type?: string;
    vote_details?: {
        label: string;
        markers: {
            High: number;
            Low: number;
            Medium: number;
        };
        max: number;
        min: number;
        type: string;
    };

    sub_question_id?: string;
    local_response?: boolean;
    abstain?: boolean;
}

export class SSOTComment extends CommonResponse {
    type: 'comment';
    response_id?: string;
    question_id?: string;
}

export class Like {
    response_id: string;
    user_id: string;
}

export class Choice {
    key?: string;
    value: any;
    description: string;
    sequence: number;
    condition: answerAction;
    navigate_to?: string;

    constructor(sequence: number, value?: any, description?: string) {
        this.sequence = sequence;
        this.value = (!value && value !== 0) ? '' : value;
        this.description = description || '';
        this.condition = 'next_question';
    }
}

export const testResponse = <Response>{
    category: 'category1',
    date_created: 1510686478630,
    flagged: true,
    live: 'All',
    owner: 'user1',
    sequence: 1,
    sub_categories: 'subcategory1',
    total_likes: 2,
    total_comments: 0,
    value: 'new response',
    vote_details: {
        label: 'Likelihood',
        markers: {
            High: 10,
            Low: 1,
            Medium: 5
        },
        max: 10,
        min: 1,
        type: 'slider'
    }
};

export const testChoice = <Choice>{
    value: 'Choice',
    sequence: 1,
    condition: 'next_question',
    description: 'description'
};

export const testFormHistoryResponse = {
    id: 'testResponseKey',
    date_created: Timestamp.now(),
    value: 'testValue',
    owner_id: 'testOwnerId',
    owner_full_name: 'testOwnerFullName',
    form_status: 'DRAFT',
    comments_count: 0,
    upvotes: [],
    custom_name: 'testCustomName',
};
