<div class="confirm-dialog">
  <h3 mat-dialog-title class="h3">{{ data.title }}</h3>
  <mat-dialog-content class="confirm-dialog-content">
    <div *ngIf="data.step === 0" class="search">
      <app-search [placeholder]="searchPlaceholder" (onChange)="applySearch($event)"></app-search>
    </div>

    <div *ngIf="!data.isFinalStep" class="table-content" (scroll)="getNextData($event, data.apps.length)">
      <table #table matSort mat-table class="thinktank-theme" [dataSource]="dataSource">
        <!-- Select All Column -->
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="checkbox-cell">
            <mat-checkbox
              color="primary"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              (change)="$event ? toggleAllRows() : null"
            ></mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let element" class="checkbox-cell">
            <mat-checkbox
              color="primary"
              [checked]="selection.isSelected(element)"
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(element) : null"
            ></mat-checkbox>
          </td>
        </ng-container>
        <!--/ Select All Column -->

        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>{{ tabName }} Name</th>
          <td mat-cell *matCellDef="let element" class="name-cell">{{ element.name }}</td>
        </ng-container>
        <!--/ Name Column -->

        <!-- Description Column -->
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>{{ tabName }} Description</th>
          <td mat-cell *matCellDef="let element" class="description-cell">{{ element.description }}</td>
        </ng-container>
        <!--/ Description Column -->

        <!-- Tags Column -->
        <ng-container matColumnDef="tag_names">
          <th mat-header-cell *matHeaderCellDef>Tags</th>
          <td mat-cell *matCellDef="let element" class="tags-cell">
            {{ element.tag_names }}
          </td>
        </ng-container>
        <!--/ Tags Column -->

        <!-- Created Date Column -->
        <ng-container matColumnDef="date_created">
          <th mat-header-cell *matHeaderCellDef>Created Date</th>
          <td mat-cell *matCellDef="let element" class="date-cell">
            {{ element.date_created | date: "mediumDate" }}
          </td>
        </ng-container>
        <!--/ Created Date Column -->

        <!-- Owner Name Column -->
        <ng-container matColumnDef="owner_name">
          <th mat-header-cell *matHeaderCellDef>Owner</th>
          <td mat-cell *matCellDef="let element" class="owner-cell">
            {{ element.owner_name }}
          </td>
        </ng-container>
        <!--/ Owner Name Column -->

        <tr mat-header-row *matHeaderRowDef="tableColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: tableColumns"></tr>
      </table>
    </div>

    <!-- Message if no results after search -->
    <div *ngIf="!dataSource?.data?.length && data.step === 0" class="empty-search-results">
      <p class="empty-search-results-text text">There are no results related to your search.</p>
    </div>
    <!--/ Message if no results after search -->

    <div class="sharing-page" *ngIf="data.isFinalStep">
      <form class="sharing-form" [formGroup]="sharingForm">
        <!-- collaborators input -->
        <mat-form-field class="full-width" *ngIf="data.isInviteToTemplates">
          <mat-chip-list #collaboratorsList>
            <mat-chip
              *ngFor="let user of collaborators"
              [selectable]="false"
              [removable]="true"
              (removed)="removeUser(user, 'collaborators')"
            >
              {{ user.email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              matInput
              placeholder="Collaborator(s)"
              formControlName="collaborators"
              [matChipInputFor]="collaboratorsList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addUser($event, 'collaborators')"
              (input)="updateSubmitState('collaborators')"
            />
          </mat-chip-list>
        </mat-form-field>
        <!-- collaborators input error message -->
        <mat-error *ngIf="formSubmitted['collaborators'] && emailsErrorMessages['collaborators']">
          {{ emailsErrorMessages["collaborators"] }}
        </mat-error>
        <!-- /collaborators input error message -->
        <!-- /collaborators input -->

        <!-- session creators input -->
        <mat-form-field class="full-width" *ngIf="data.isInviteToTemplates">
          <mat-chip-list #sessionCreatorsList>
            <mat-chip
              *ngFor="let user of sessionCreators"
              [selectable]="false"
              [removable]="true"
              (removed)="removeUser(user, 'sessionCreators')"
            >
              {{ user.email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              matInput
              placeholder="Session Creator(s)"
              formControlName="sessionCreators"
              [matChipInputFor]="sessionCreatorsList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addUser($event, 'sessionCreators')"
              (input)="updateSubmitState('sessionCreators')"
            />
          </mat-chip-list>
        </mat-form-field>
        <!-- session creators input error message -->
        <mat-error *ngIf="formSubmitted['sessionCreators'] && emailsErrorMessages['sessionCreators']">
          {{ emailsErrorMessages["sessionCreators"] }}
        </mat-error>
        <!-- /session creators input error message -->
        <!-- /session creators input -->

        <!-- participants input -->
        <mat-form-field class="full-width" *ngIf="!data.isInviteToTemplates">
          <mat-chip-list #participantsList>
            <mat-chip
              *ngFor="let user of participants"
              [selectable]="false"
              [removable]="true"
              (removed)="removeUser(user, 'participants')"
            >
              {{ user.email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              matInput
              placeholder="Participant(s)"
              formControlName="participants"
              [matChipInputFor]="participantsList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addUser($event, 'participants')"
              (input)="updateSubmitState('participants')"
            />
          </mat-chip-list>
        </mat-form-field>
        <!-- participants input error message -->
        <mat-error *ngIf="formSubmitted['participants'] && emailsErrorMessages['participants']">
          {{ emailsErrorMessages["participants"] }}
        </mat-error>
        <!-- /participants input error message -->
        <!-- /participants input -->

        <!-- observers input -->
        <mat-form-field class="full-width" *ngIf="!data.isInviteToTemplates">
          <mat-chip-list #observersList>
            <mat-chip
              *ngFor="let users of observers"
              [selectable]="false"
              [removable]="true"
              (removed)="removeUser(users, 'observers')"
            >
              {{ users.email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              matInput
              placeholder="Observer(s)"
              formControlName="observers"
              [matChipInputFor]="observersList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addUser($event, 'observers')"
              (input)="updateSubmitState('observers')"
            />
          </mat-chip-list>
        </mat-form-field>
        <!-- observers input error message -->
        <mat-error *ngIf="formSubmitted['observers'] && emailsErrorMessages['observers']">
          {{ emailsErrorMessages["observers"] }}
        </mat-error>
        <!-- /observers input error message -->
        <!-- /observers input -->

        <!-- leaders input -->
        <mat-form-field class="full-width" *ngIf="!data.isInviteToTemplates">
          <mat-chip-list #leadersList>
            <mat-chip
              *ngFor="let users of leaders"
              [selectable]="false"
              [removable]="true"
              (removed)="removeUser(users, 'leaders')"
            >
              {{ users.email }}
              <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input
              matInput
              placeholder="Leader(s)"
              formControlName="leaders"
              [matChipInputFor]="leadersList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addUser($event, 'leaders')"
              (input)="updateSubmitState('leaders')"
            />
          </mat-chip-list>
        </mat-form-field>
        <!-- leaders input error message -->
        <mat-error *ngIf="formSubmitted['leaders'] && emailsErrorMessages['leaders']">
          {{ emailsErrorMessages["leaders"] }}
        </mat-error>
        <!-- /leaders input error message -->
        <!-- /leaders input -->

        <!-- message textarea -->
        <mat-form-field class="full-width textarea">
          <textarea
            *ngIf="!isIeOrEdgeBrowser"
            matInput
            placeholder="Message"
            formControlName="message"
            [(ngModel)]="message"
          ></textarea>

          <!-- Only for IE and EDGE -->
          <textarea
            *ngIf="isIeOrEdgeBrowser"
            matInput
            matTextareaAutosize
            placeholder="Message"
            formControlName="message"
            [(ngModel)]="message"
          ></textarea>
        </mat-form-field>
        <!-- /message textarea -->

        <div class="spacer"></div>
      </form>
    </div>
  </mat-dialog-content>

  <mat-error *ngIf="selection.selected.length > 200" class="selection-error">
    {{ errorMessage }}
  </mat-error>

  <mat-dialog-actions class="confirm-dialog-actions">
    <button *ngIf="data.step !== 0" class="tt-button-outline" (click)="back()">
      {{ data.backBtnText }}
    </button>
    <button
      cdkFocusInitial
      class="tt-button-filled"
      (click)="handleConfirm()"
      [disabled]="isConfirmButtonDisabled || inviteDisabled"
    >
      {{ data.confirmBtnText }}
    </button>
    <button class="tt-button-outline" (click)="closeModal()">
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
