import { Injectable } from '@angular/core';
import { Gate } from '../models/gate.model';
import { NodeType } from '../models/node.model';
import { FirestoreService } from './firestore.service';
import { Observable } from 'rxjs';
import { DocumentReference } from '@angular/fire/compat/firestore';

@Injectable({
    providedIn: 'root'
})
export class GateService {

    constructor(private firestoreService: FirestoreService) {
    }

    getGates(projectKey: string): Observable<Gate[]> {
        return this.firestoreService.getProjectNodesByType<Gate>(projectKey, NodeType.Gate);
    }

    getGate(projectKey: string, gateKey: string): Observable<Gate> {
        return this.firestoreService.getDocument(`/graph/${projectKey}/nodes/${gateKey}`);
    }

    save(gate: Gate): Promise<void | DocumentReference> {
        return this.firestoreService.upsertNode<Gate>(gate);
    }

    removeGate(gate: Gate): Promise<void> {
        const path = `/graph/${gate.project_key}/nodes/${gate.key}`;
        return this.firestoreService.delete(path);
    }

    completeGate(projectKey: string, gateKey: string): Promise<void> {
        const path = `/graph/${projectKey}/nodes/${gateKey}`;
        return this.firestoreService.upsert(path, {
            data: { completed: true }
        });
    }
}
