import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { FormControl, FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { patternValidator } from '@app/core/utils';
import { userPasswordValidationText } from '@app/admin/admin.component.constants';
import { imagesResourceList } from '../background-images';
import { DialogService } from '@thinktank/common-lib';
import { Router } from '@angular/router';

function nonAccentureEmailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null =>
        control.value?.endsWith('accenture.com') ? { isAccenture: true } : null;
}

@Component({
    selector: 'app-user-register',
    templateUrl: './user-register.component.html',
    styleUrls: ['./user-register.component.scss'],
})
export class UserRegisterComponent implements OnInit, OnDestroy {
    backgroundImages = imagesResourceList;
    registerForm: FormGroup;
    email = new FormControl('', [Validators.required, Validators.email, nonAccentureEmailValidator()]);

    firstName = new FormControl('', Validators.required);
    lastName = new FormControl('', Validators.required);
    password = new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        // eslint-disable-next-line
        patternValidator(/\d/, { hasNumber: true }),
        // eslint-disable-next-line
        patternValidator(/[A-Z]/, { hasUpperCase: true }),
        // eslint-disable-next-line
        patternValidator(/[a-z]/, { hasLowerCase: true }),
        // eslint-disable-next-line
        patternValidator(/[ -\/:-@\[-\`{-~]/, { hasSpecialCharacters: true }),
    ]);

    errorMessage: string;
    verificationMessage: string;
    hide = true;
    newUserPasswordValidationText = userPasswordValidationText;

    private onDestroy: Subject<void> = new Subject();

    constructor(
        private authenticationService: AuthenticationService,
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
        private router: Router,
    ) {
        this.registerForm = this.formBuilder.group({
            email: this.email,
            firstName: this.firstName,
            lastName: this.lastName,
            password: this.password,
        });
    }

    checkPasswordValidation(passwordValidator): boolean {
        return this.password.hasError(passwordValidator.errorName) || this.password.hasError('required');
    }

    register() {
        this.errorMessage = '';
        this.authenticationService
            .registerWithEmail(this.email.value, this.firstName.value, this.lastName.value, this.password.value)
            .then(userRegistered => {
                if (userRegistered) {
                    this.registerForm.reset();
                    this.dialogService.open('ConfirmActionDialogComponent', {
                        title: 'Verify Email Address',
                        text: 'A confirmation email has been sent to your email address. Click on the verify link in the email to verify your account.',
                        confirmBtnText: 'OK',
                        noCancelBtn: true,
                        handleConfirm: () => {
                            this.router.navigate(['/']);
                        },
                    });
                }
            });
    }

    ngOnInit(): void {
        this.authenticationService
            .errorMessage()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(error => {
                this.errorMessage = error;
            });
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
