import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginService } from '@app/login/services/login.service';
import { DbTokenGenerationService } from '@app/login/services/db-token-generation.service';
import { MaterialModule } from '@thinktank/common-lib';
import { LoginComponent } from './login.component';
import { SessionJoinLinkComponent } from './session-join-link/session-join-link.component';
import { SharedModule } from '@app/shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule
    ],
    declarations: [LoginComponent, SessionJoinLinkComponent],
    providers: [
        LoginService,
        DbTokenGenerationService,
        { provide: 'Window', useValue: window }
    ]
})
export class LoginModule {}
