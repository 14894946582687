import { Demographic } from './demographic.model';
import { ChangeRequestStatus } from './change-request.model';

export enum ImpactType {
    Variable = 'variable',
    Branching = 'branching'
}

export interface OwnershipView {
    id: string;
    object_id: string;
    role: string;
    demographic_id?: string;
    object_type: string;
    primary: boolean;
    user_id: string;
    email_address: string;    // Denormalized data, need to update if user changes their email address
    first_name: string;     // Denormalized data, need to update if user changes their first name
    last_name: string;     // Denormalized data, need to update if user changes their last name
}

export interface ImpactView {
    id: string; // {form_id}_{question_id}_{question_impacted_id}
    form_id: string;
    form_impacted_id: string;
    process_id: string;
    process_impacted_id: string;
    question_id: string;
    question_impacted_id: string;
    subprocess_id: string;
    subprocess_impacted_id: string;
    type: ImpactType;

    module_id?: string;
    process_impacted_name?: string;
    subprocess_impacted_name?: string;
    question_label?: {
        current_value?: string;
        default?: string;
    };
    question_impacted_label?: {
        current_value?: string;
        default?: string;
    };
}

export interface ChangeRequestView {
    id: string;
    form_id: string;
    subprocess_id: string;
    process_id: string;
    module_id: string;
    deployment_id: string;
    requesting_user_id: string;
    approving_user_id?: string;
    question_id: string;
    question_name: string;
    current_value: any;
    requested_value: any;
    demographics_to_change: string[];
    status: ChangeRequestStatus;
}

export interface DeploymentObjectView {
    readonly object_type: string;
    id: string;
    name: string;
}

export interface ModuleView extends DeploymentObjectView {
    active: boolean;
    sequence: number;
    demographics: Demographic[];
}

export interface ProcessView extends DeploymentObjectView {
    module_id: string;
    global: boolean;
    subprocesses?: SubprocessView[];
}

export interface SubprocessView extends DeploymentObjectView {
    module_id: string;
    process_id: string;
    global: boolean;
}

export interface DeploymentView {
    id: string;
    name: string;
    client_name: string;
    description: string;
    image: string;

    ownership: OwnershipView[];
    demographics: Demographic[];
    impact: ImpactView[];
    change_requests: ChangeRequestView[];
    modules: ModuleView[];
    processes: ProcessView[];
    subprocesses: SubprocessView[];
}
