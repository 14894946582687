import { Action, createReducer, on } from '@ngrx/store';
import { initialAuthState } from '@app/root-store/features/auth/state';
import {
    initializeCurrentUser,
    initializeCurrentUserSuccess,
    initializeReturningUser,
    initializeReturningUserSuccess,
    signOut,
    signOutSuccess
} from '@app/root-store/features/auth/actions/auth.actions';

const _authStateReducer = createReducer(
    initialAuthState,
    on(initializeCurrentUser, (state) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(initializeCurrentUserSuccess, (state, payload) => {
        return {
            ...state,
            user: payload.userData.user,
            firebaseUser: payload.userData.firebaseUser,
            isLoading: false
        };
    }),
    on(initializeReturningUser, (state) => {
        return {
            ...state,
            isLoading: true
        };
    }),
    on(initializeReturningUserSuccess, (state, payload) => {
        return {
            ...state,
            user: payload.userData.user,
            firebaseUser: payload.userData.firebaseUser,
            isLoading: false
        };
    }),
    on(signOut, (state) => {
        return {
            ...state,
            isLoading: false
        };
    }),
    on(signOutSuccess, (state) => {
        return {
            ...state,
            isLoading: false
        };
    })
);

export function authStateReducer(state: any, action: Action) {
    return _authStateReducer(state, action);
}
