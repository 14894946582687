<div class="attachments">
  <div class="upload-zone" [class.without-files]="!question.attached_files?.length">
    <button
      class="gs-button-outline upload-zone-btn"
      [disabled]="disabled"
      (click)="openFilePicker($event)"
    >
      Attach file
    </button>
  </div>
  <ul class="uploaded-files-list">
    <ng-container *ngFor="let attachment of question.attached_files; trackBy: trackByValue">
      <li *ngIf="!!attachment" class="uploaded-files-item">
        <span class="filename text">
          {{attachment.name}}
        </span>
        <div class="actions">
          <button class="gs-button-icon" (click)="downloadFile(attachment.download_url)">
            <mat-icon matPrefix>file_download</mat-icon> Download
          </button>
          <button [disabled]="parentForm.disabled" class="gs-button-icon" (click)="removeFile(attachment, question.attached_files)">
            <mat-icon matPrefix>delete</mat-icon> Delete
          </button>
        </div>
      </li>
    </ng-container>
  </ul>
  <mat-hint class="text-hint">
    <ng-container *ngIf="ownerFullName">
      <mat-icon>done</mat-icon>
      <span class="text-hint-inner">{{ownerFullName}}</span>
    </ng-container>
  </mat-hint>
</div>
