import { Component, Input } from '@angular/core';
import { Deployment } from '../../../models';

@Component({
    selector: 'gs-deployment-template-card',
    templateUrl: './deployment-template-card.component.html',
    styleUrls: ['./deployment-template-card.component.scss']
})
export class DeploymentTemplateCardComponent {
    @Input() isDeploymentsList?: boolean;
    @Input() isSelected?: boolean;
    @Input() deploymentTemplate: Deployment;
    @Input() backgroundColorIndex?: number;

    constructor() {
    }

}
