import { FormQuestionValidationError } from '@app/core/models';
import { BaseStateModel } from '@app/root-store/models/base-state.model';

export interface FormValidationErrorsState extends BaseStateModel {
    isErrorsVisible: boolean;
    errors: FormQuestionValidationError[];
    activityId: string;
}

export const initialState: FormValidationErrorsState = {
    isErrorsVisible: false,
    errors: [],
    activityId: null,
    errorMessage: null,
    loading: false
};
