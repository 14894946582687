import { Pipe, PipeTransform } from '@angular/core';

import { User, UserSessionRole, UserTemplateRole } from '@app/core/models';
import { filterByField } from '@app/core/utils';

const filterByRole = (user: User, role: ('all' | UserSessionRole | UserTemplateRole)[]): boolean => {
    return role.includes('all') || role.includes(user.app_role);
};

@Pipe({
    name: 'currentUsersAppManagement',
})
export class CurrentUsersAppManagementPipe implements PipeTransform {
    transform(users: User[] = [], role: ('all' | UserSessionRole)[], searchString: string): User[] {
        return users.filter(
            (user: User) =>
                filterByRole(user, role) &&
                ((searchString ? filterByField(`${user.firstName} ${user.lastName}`, searchString) : true) ||
                    (searchString ? filterByField(user.email, searchString) : true)),
        );
    }
}
