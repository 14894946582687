<div class="sharing-page">
  <ng-container *ngIf="!isInviteFromAdmin; else inviteFromAdmin">
    <h1 class="title">Invite Collaborators</h1>
  </ng-container>
  <ng-template #inviteFromAdmin>
    <span class="template-title">{{ templateName$ | async }}</span>
    <h1 class="title">Invite Collaborators</h1>
  </ng-template>

  <form class="sharing-form" [formGroup]="sharingForm">
    <!-- collaborators input -->
    <mat-form-field class="full-width">
      <mat-chip-list #collaboratorsList>
        <mat-chip
          *ngFor="let user of collaborators"
          [selectable]="false"
          [removable]="true"
          (removed)="remove(user, 'collaborators')"
        >
          {{ user.email }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          placeholder="Collaborator(s)"
          formControlName="collaborators"
          [matChipInputFor]="collaboratorsList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event, 'collaborators')"
          (input)="updateSubmitState('collaborators')"
        />
      </mat-chip-list>
    </mat-form-field>
    <!-- collaborators input error message -->
    <mat-error *ngIf="formSubmitted['collaborators'] && emailsErrorMessages['collaborators']">
      {{ emailsErrorMessages["collaborators"] }}
    </mat-error>
    <!-- /collaborators input error message -->
    <!-- /collaborators input -->

    <!-- session creators input -->
    <mat-form-field class="full-width">
      <mat-chip-list #sessionCreatorsList>
        <mat-chip
          *ngFor="let user of sessionCreators"
          [selectable]="false"
          [removable]="true"
          (removed)="remove(user, 'sessionCreators')"
        >
          {{ user.email }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input
          matInput
          placeholder="Session Creator(s)"
          formControlName="sessionCreators"
          [matChipInputFor]="sessionCreatorsList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="add($event, 'sessionCreators')"
          (input)="updateSubmitState('sessionCreators')"
        />
      </mat-chip-list>
    </mat-form-field>
    <!-- session creators input error message -->
    <mat-error *ngIf="formSubmitted['sessionCreators'] && emailsErrorMessages['sessionCreators']">
      {{ emailsErrorMessages["sessionCreators"] }}
    </mat-error>
    <!-- /session creators input error message -->
    <!-- /session creators input -->

    <!-- message textarea -->
    <mat-form-field class="full-width textarea">
      <textarea
        *ngIf="!isIeOrEdgeBrowser"
        matInput
        placeholder="Message"
        formControlName="message"
        [(ngModel)]="message"
      ></textarea>

      <!-- Only for IE and EDGE -->
      <textarea
        *ngIf="isIeOrEdgeBrowser"
        matInput
        matTextareaAutosize
        placeholder="Message"
        formControlName="message"
        [(ngModel)]="message"
      ></textarea>
    </mat-form-field>
    <!-- /message textarea -->

    <div class="spacer"></div>

    <!-- form actions -->
    <div
      [ngClass]="{
        'sharing-form__actions': !isInviteFromAdmin,
        'sharing-form-admin__actions': !!isInviteFromAdmin
      }"
    >
      <button
        mat-raised-button
        color="primary"
        class="sharing-form__action"
        type="submit"
        [disabled]="inviteDisabled"
        (click)="invite()"
      >
        Invite
      </button>
      <button mat-raised-button class="sharing-form__action" (click)="cancel()">Cancel</button>
    </div>
    <!-- /form actions -->
  </form>
  <app-current-users-app-management
    *ngIf="isInviteFromAdmin && users$ | async as users"
    [users]="users"
    [userRoles]="userRoles"
  ></app-current-users-app-management>
</div>

<ul *ngIf="isNotificationActive" class="notification-container">
  <li class="tt-snackbar-notification notification">
    <p class="tt-snackbar-message">{{ inviteSentSuccessfullyText }}</p>
    <p matRipple class="tt-snackbar-button" (click)="closeNotification()">Got it</p>
  </li>
</ul>
