import { FormItemNode } from './form-item-node.model';
import { Demographic } from './demographic.model';
import { FormQuestion } from './form-view.model';

export abstract class SelectQuestionNode extends FormItemNode {

    options: any;

    loadData(data: object) {
        super.loadData(data);
        this.options = data['options'] || [];
    }

    buildFormView(formItems: FormItemNode[], demographics: Demographic[]): FormQuestion {
        const formObject = super.buildFormView(formItems, demographics);
        return {
            ...formObject,
            options: this.options
        }
    }

}
