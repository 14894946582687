<div *ngIf="(vm$ | async) as vm" class="deployment">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div *ngIf="!vm.isLoading" class="deployment-container">
    <div class="deployment-info">
      <div class="deployment-image">
        <gs-image
          [deploymentTemplateImage]="vm.deployment.image"
        ></gs-image>
      </div>
      <div class="deployment-info-content">
        <div class="deployment-title">
          <h5 class="h5">{{ vm.deployment.name }}</h5>
          <button
            *ngIf="vm.isUserOwner"
            class="deployment-title-button gs-button-icon-flat gs-small"
            [routerLink]="['edit']"
          >
            <mat-icon>create</mat-icon>
            <span>Edit Info</span>
          </button>
          <button class="gs-button-icon-flat gs-small" (click)="goBackToDeployments()">
            <mat-icon>keyboard_arrow_left</mat-icon>
            Back
          </button>
        </div>
        <div class="deployment-info-data">
          <!-- client -->
          <div class="gs-block">
            <h5 class="gs-block-title">Client</h5>
            <p class="text-regular-small">{{ vm.deployment.client_name }}</p>
          </div>
          <!-- /client -->
          <!-- deployment manager -->
          <div class="gs-block">
            <h5 class="gs-block-title">Deployment Manager</h5>
            <p class="text-regular-small">{{ vm.deploymentManager }}</p>
          </div>
          <!-- /deployment manager -->
        </div>
      </div>
    </div>

    <p *ngIf="!!vm.deployment.description" class="gs-block text-regular-small">
      {{ vm.deployment.description }}
    </p>

    <!-- demographics -->
    <h5 class="gs-block-title">Countries</h5>
    <gs-view-demographics
      [demographics]="vm.selectedDemographics"
      [canChange]="false"
      [viewType]="'deployment'"
    ></gs-view-demographics>
    <!-- /demographics -->

    <!-- selected modules -->
    <h5 class="gs-block-title">Quick Details</h5>

    <!-- tabs -->
    <div class="gs-tabs">
      <div class="gs-tab" [ngClass]="{ active: activeTab === 0 }">
        <a (click)="activeTab = 0">All</a>
        <span class="gs-badge">{{ vm.allCount }}</span>
      </div>
      <div class="gs-tab" [ngClass]="{ active: activeTab === 1 }">
        <a (click)="activeTab = 1">Modules</a>
        <span class="gs-badge">{{ vm.modulesCount }}</span>
      </div>
      <div class="gs-tab" [ngClass]="{ active: activeTab === 2 }">
        <a (click)="activeTab = 2">Processes</a>
        <span class="gs-badge">{{ vm.processesCount }}</span>
      </div>
      <div class="gs-tab" [ngClass]="{ active: activeTab === 3 }">
        <a (click)="activeTab = 3">Sub Processes</a>
        <span class="gs-badge">{{ vm.subprocessesCount }}</span>
      </div>
    </div>
    <!-- /tabs -->

    <div class="gs-table-wrapper">
      <table class="gs-table">
        <thead>
        <tr>
          <th class="gs-table-th">Owner</th>
          <th *ngIf="activeTab === 0" class="gs-table-th">Name</th>
          <th *ngIf="activeTab === 0" class="gs-table-th">Type</th>
          <th *ngIf="activeTab === 1" class="gs-table-th">Module</th>
          <th *ngIf="activeTab === 2" class="gs-table-th">Process</th>
          <th *ngIf="activeTab === 3" class="gs-table-th">Sub Process</th>
          <th class="gs-table-th">Actions</th>
        </tr>
        </thead>
        <!-- all table -->
        <tbody *ngIf="activeTab === 0">
        <ng-container>
          <tr *ngFor="let item of getAllData(vm); trackBy: trackById">
            <td class="gs-table-td">
              <gs-assignees [users]="getOwners(item.id, vm.ownership)" [limit]="1"></gs-assignees>
            </td>
            <td class="gs-table-td">{{ item.name }}</td>
            <td class="gs-table-td">{{ item | itemText }}</td>
            <td class="gs-table-td">
              <div class="actions">
                <button
                  class="gs-button-flat gs-small"
                  [routerLink]="[getTypeForItem(item), item.id]"
                >
                  Show Details
                </button>
                <button
                  class="gs-button-flat gs-small"
                  [routerLink]="[getTypeForItem(item), item.id, 'assign']"
                >
                  Assign Users
                </button>
                <button
                  *ngIf="isItemSubprocess(item)"
                  class="gs-button-flat gs-small"
                  [disabled]="isVolunteerDisabled(item, vm)"
                  (click)="volunteerForGeneric(item, vm)"
                >
                  Volunteer
                </button>
              </div>
            </td>
          </tr>
          <tr *ngIf="!getAllData(vm).length">
            <td class="caption text-gray text-center gs-table-td" colspan="4">
              There are no Modules/Processes/Subprocesses in this Deployment yet
            </td>
          </tr>
        </ng-container>
        </tbody>
        <!-- /all table -->
      </table>
    </div>
  </div>
</div>
