import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';

import { ObjectType } from '../../models';
import { trackById } from '@thinktank/common-lib';
import { DeploymentEditorFacade, DeploymentEditorFacadeToken } from './deployment-editor-facade';

@Component({
    selector: 'gs-deployment-editor',
    templateUrl: './deployment-editor.component.html',
    styleUrls: ['./deployment-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentEditorComponent {
    vm$ = this.facade.vm$;

    trackById = trackById;
    objectType = ObjectType.Deployment;
    activeEditor: string;

    constructor(@Inject(DeploymentEditorFacadeToken) private facade: DeploymentEditorFacade) {}

    setActiveEditor(editorOption: string): void {
        this.activeEditor = editorOption;
    }

    updateDescription(newDescription: string, deploymentId: string): void {
        this.facade.updateDeploymentDescription(deploymentId, newDescription);
    }
}
