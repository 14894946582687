import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { FormItem, FormQuestion, FormSection, Demographic } from '@thinktank/common-lib';
import { QuestionFormControlFactory } from './question-form-control.factory';
import { FormGroupHandler } from './form-group-handler';

export class SectionFormGroupHandler implements FormGroupHandler {

    private static readonly SectionSequence = 0;

    constructor(
        private formBuilder: FormBuilder,
        private questionFormControlFactory: QuestionFormControlFactory
    ) {
    }

    build(section: FormSection, assignedDemographics: Demographic[]): FormGroup {
        const sectionFormGroup = this.formBuilder.group({});
        assignedDemographics.forEach((demographic) => {
            // Add a section demographic form group for each demographic
            const sectionDemographicFormGroup = this.formBuilder.group({});
            section.questionsForView.forEach((question: FormQuestion) => {
                // Add a single form control for each question since sections are grouped by demographics
                const questionWithSectionId =
                    this.createSectionQuestion(question, section.id, section.id, SectionFormGroupHandler.SectionSequence);
                const questionFormControlHandler = this.questionFormControlFactory.build(question.type);
                const questionFormControl = questionFormControlHandler.build(questionWithSectionId, demographic.id);
                sectionDemographicFormGroup.addControl(question.id, questionFormControl);
            });
            sectionFormGroup.addControl(demographic.id, sectionDemographicFormGroup);

            // Handle any repeated sections for this demographic
            const repeatedSections = !!section.repeated_sections && section.repeated_sections[demographic.id] || {};
            Object.keys(repeatedSections).forEach((repeatedSectionId) => {
                const repeatedSectionFormGroup = this.formBuilder.group({});
                repeatedSectionFormGroup.addControl(
                    'description',
                    this.formBuilder.control(repeatedSections[repeatedSectionId].description || '')
                );
                section.questionsForView.forEach((question: FormQuestion) => {
                    // Add a single form control for each question since repeated sections are also grouped by demographics
                    const questionWithSectionId =
                        this.createSectionQuestion(question, section.id, repeatedSectionId, repeatedSections[repeatedSectionId].sequence);
                    const questionFormControlHandler = this.questionFormControlFactory.build(question.type);
                    repeatedSectionFormGroup.addControl(
                        question.id, questionFormControlHandler.build(questionWithSectionId, demographic.id));
                });
                sectionDemographicFormGroup.addControl(repeatedSectionId, repeatedSectionFormGroup);
            });

            // Setup visibility for the demographic section
            if (!!section.visible[demographic.id] && !section.visible[demographic.id]) {
                sectionDemographicFormGroup.disable();
            }
        });

        return sectionFormGroup;
    }

    update(formGroup: FormGroup, section: FormSection, assignedDemographics: Demographic[]): void {
        // Check for visibility changes for the section itself
        assignedDemographics.forEach((demographic) => {
            const demographicSectionControl = formGroup.get([ section.id, demographic.id ]) as FormGroup;
            this.handleVisibilityChange(section, demographicSectionControl, demographic.id);

            // Check for visibility changes and response value changes for the questions in the section and update accordingly
            section.questionsForView.forEach((question) => {
                this.updateResponse(question, demographicSectionControl, section.id, demographic, section.id, 0);
            });

            // Handle repeated section updates
            const repeatedSections = !!section.repeated_sections && section.repeated_sections[demographic.id] || {};
            Object.keys(repeatedSections).forEach((repeatedSectionId) => {
                const repeatedSectionFormGroup = demographicSectionControl.get(repeatedSectionId) as FormGroup;
                section.questionsForView.forEach((question) => {
                    this.updateResponse(
                        question,
                        repeatedSectionFormGroup,
                        repeatedSectionId,
                        demographic,
                        section.id,
                        repeatedSections[repeatedSectionId].sequence
                    );
                });
            });
        });
    }

    private handleVisibilityChange(item: FormItem, control: AbstractControl, visibilityId: string): void {
        if (item.visible[visibilityId] && control.disabled) {
            // The demographic response is now visible and should be enabled
            control.enable();
        }
        if (!item.visible[visibilityId] && control.enabled) {
            // The demographic response is now invisible and should be disabled
            control.disable();
        }
    }

    private updateResponse(
        question: FormQuestion,
        formGroup: FormGroup,
        sectionId: string,
        demographic: Demographic,
        sectionNodeId: string,
        sequence: number
    ): void {
        const questionWithSectionId = this.createSectionQuestion(question, sectionNodeId, sectionId, sequence);
        const questionFormControlHandler = this.questionFormControlFactory.build(question.type);
        const formControl = formGroup.get(question.id);
        questionFormControlHandler.update(formControl, questionWithSectionId, demographic.id);
    }

    private createSectionQuestion(
        question: FormQuestion,
        sectionNodeId: string,
        sectionResponseId: string,
        sequence: number
    ): FormQuestion {
        return {
            ...question,
            section_node_id: sectionNodeId,
            section_response_id: sectionResponseId,
            section_sequence: sequence
        };
    }
}
