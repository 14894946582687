import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FirebaseAppService } from '@app/core/services/app.service';
import { FirebaseActivityService } from '@app/core/services/activity.service';

@Injectable()
export class RepositoryStoreService {
    private _appsNamesMap: Map<string, BehaviorSubject<string>> = new Map();
    private _formNamesMap: Map<string, BehaviorSubject<string>> = new Map();

    private _appsNamesDestroySubject = new Subject<void>();
    private _formNamesDestroySubject = new Subject<void>();

    constructor(
        private appService: FirebaseAppService,
        private activityService: FirebaseActivityService
    ) {}

    getAppName(appKey: string): BehaviorSubject<string> {
        if (!this._appsNamesMap.has(appKey)) {
            const appName$ = new BehaviorSubject<string>(null);

            this.appService.getAppTitle(appKey)
                .pipe(takeUntil(this._appsNamesDestroySubject))
                .subscribe(activityName => activityName && appName$.next(activityName));

            this._appsNamesMap.set(appKey, appName$);
        }

        return this._appsNamesMap.get(appKey);
    }

    getFormName(activityKey: string): BehaviorSubject<string> {
        if (!this._formNamesMap.has(activityKey)) {
            const formName$ = new BehaviorSubject<string>(null);

            this.activityService.getActivityTitle(activityKey)
                .pipe(takeUntil(this._formNamesDestroySubject))
                .subscribe(activityName => activityName && formName$.next(activityName));

            this._formNamesMap.set(activityKey, formName$);
        }

        return this._formNamesMap.get(activityKey);
    }

    clear(): void {
        this._appsNamesDestroySubject.next();
        this._formNamesDestroySubject.next();

        this._appsNamesMap.clear();
        this._formNamesMap.clear();
    }
}

