<form *ngIf="!!inviteForm" class="invite-form" [formGroup]="inviteForm">

  <!-- owner(s) -->
  <p class="subtitle-small"><span class="bold">Collaborator</span> is a person who is an authority in a particular area or topic. A domain expert is a person with special knowledge or skills in a particular area of endeavour.</p>
  <mat-form-field class="full-width">
    <mat-label>Owner</mat-label>
    <mat-chip-list #ownersList>
      <mat-chip
        *ngFor="let users of owners"
        class="gs-chip-filled"
        [selectable]="false"
        [removable]="true"
        (removed)="removeEmail(users, 'owner')"
      >
        {{ users.email_address }}
        <mat-icon class="gs-chip-icon-filled" matChipRemove>close</mat-icon>
      </mat-chip>
      <input
        #ownersInput
        matInput
        formControlName="owners"
        [placeholder]="getPlaceholder(owners, 'Email')"
        [matAutocomplete]="autoOwners"
        [matChipInputFor]="ownersList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addEmail($event, UserRole.Owner)"
        (keyup)="filterUsers($event)"
      />
    </mat-chip-list>
    <mat-autocomplete #autoOwners="matAutocomplete" [class]="'assign-panel'" (optionSelected)="selected($event, UserRole.Owner)">
      <mat-option *ngFor="let appUser of filteredUsers; trackBy: trackById" [value]="appUser.email_address">
        <ng-container *ngIf="!!appUser.first_name && !!appUser.last_name">
          {{ appUser.first_name }} {{ appUser.last_name }}
        </ng-container>
        <span>{{ appUser.email_address }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-error *ngIf="emailsErrorMessages['owners']">{{ emailsErrorMessages['owners'] }}</mat-error>
  <!-- /owner(s) -->

  <!-- sme(s) -->
  <p *ngIf="showSMERole" class="subtitle-small"><span class="bold">Subject-matter expert (SME)</span> or domain expert is a person who is an authority in a particular area or topic. A domain expert is a person with special knowledge or skills in a particular area of endeavour.</p>
  <mat-form-field *ngIf="showSMERole" class="full-width">
    <mat-label>SME</mat-label>
    <mat-chip-list #stakeholdersList>
      <mat-chip
        *ngFor="let users of stakeholders"
        class="gs-chip-filled"
        [selectable]="false"
        [removable]="true"
        (removed)="removeEmail(users, 'stakeholder')"
      >
        {{ users.email_address }}
        <mat-icon class="gs-chip-icon-filled" matChipRemove>close</mat-icon>
      </mat-chip>
      <input
        #stakeholdersInput
        matInput
        formControlName="stakeholders"
        [placeholder]="getPlaceholder(stakeholders, 'Email')"
        [matChipInputFor]="stakeholdersList"
        [matAutocomplete]="autoStakeholders"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="addEmail($event, UserRole.Stakeholder)"
        (keyup)="filterUsers($event)"
      />
    </mat-chip-list>

    <mat-autocomplete
      #autoStakeholders="matAutocomplete"
      [class]="'assign-panel'"
      (optionSelected)="selected($event, UserRole.Stakeholder)"
    >
      <mat-option *ngFor="let appUser of filteredUsers; trackBy: trackById" [value]="appUser.email_address">
        <ng-container *ngIf="!!appUser.first_name && !!appUser.last_name">
          {{ appUser.first_name }} {{ appUser.last_name }}
        </ng-container>
        <span>{{ appUser.email_address }}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-error *ngIf="emailsErrorMessages['stakeholders']">{{ emailsErrorMessages['stakeholders'] }}</mat-error>

  <!-- /sme(s) -->

  <!-- form actions -->
  <div class="gs-buttons-group">
    <button
      class="gs-button-filled"
      type="submit"
      [disabled]="assignDisabled"
      (click)="assignUsers()"
    >
      Assign
    </button>
    <button class="gs-button-ghost" (click)="resetForm()">Cancel</button>
  </div>
  <!-- /form actions -->
</form>
