<div class="form-submission-dialog">
  <h3 mat-dialog-title class="h3">Submit Form</h3>
  <mat-dialog-content class="alert-content">
    <div class="text">{{data.warningText}}</div>
  </mat-dialog-content>
  <mat-dialog-actions class="alert-actions">
    <button cdkFocusInitial class="tt-button-filled" (click)="onSubmit()">{{data.confirmBtnText}}</button>
    <button mat-dialog-close class="tt-button-outline">{{data.cancelBtnText}}</button>
  </mat-dialog-actions>
</div>
