import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { FormBranchingVisibility, FormQuestion } from '@app/core/models/forms.model';
import { FormComponentsService } from '@app/core/services/form-components.service';

@Injectable()
export class ChangeAnswerService {
    private responseWasChanged = false;
    private _changeAnswerChangedQuestionsKeys = {};
    private _questionsToAccept = {};
    private _sectionsToAccept = {};
    private _changeAnswerChangedQuestionsKeys$ = new BehaviorSubject<{ [questionKey: string]: boolean }>({});
    private _initialFormBranching: { [questionKey: string]: FormBranchingVisibility };

    constructor(private _formComponentsService: FormComponentsService) {}

    setInitialFormBranching(branchingVisibility: { [questionKey: string]: FormBranchingVisibility }): void {
        this._initialFormBranching = branchingVisibility;
    }

    getInitialBranchingVisibility(questionKey: string, demographicKey?: string, parentDemographicKey?: string): boolean {
        if (!this._initialFormBranching) {
            return false;
        }

        if (demographicKey && parentDemographicKey) {
            return this._initialFormBranching[questionKey][parentDemographicKey][demographicKey];
        }

        if (demographicKey || parentDemographicKey) {
            return this._initialFormBranching[questionKey][parentDemographicKey || demographicKey];
        }

        return this._initialFormBranching[questionKey] as boolean;
    }

    markQuestionToBeAccepted(questionKey: string, demographicKey: string, isAccepted: boolean): void {
        if (demographicKey) {
            this._questionsToAccept[questionKey] = {
                ...this._questionsToAccept[questionKey],
                [demographicKey]: isAccepted
            };
        } else {
            this._questionsToAccept[questionKey] = isAccepted;
        }
    }

    async markSectionToBeAccepted(
        question: FormQuestion,
        formBranching: FormBranchingVisibility,
        isAccepted: boolean,
        demographicKey?: string
    ): Promise<void> {
        if (demographicKey) {
            this._sectionsToAccept[question.subform_id] = {
                ...this._questionsToAccept[question.subform_id],
                [demographicKey]: isAccepted
            };

            const sectionQuestions = await this.getQuestionsByFormId(question.subform_id).pipe(take(1)).toPromise();

            // if section repeatable - mark to be accepted visible questions inside repeated sections too for suitable demographic
            if (question.repeatable_questions_ids) {
                await Promise.all(
                    Object.keys(question.repeatable_questions_ids).map(async (id: string) => {
                        const sectionQuestion = await this._formComponentsService.getQuestion(id).pipe(take(1)).toPromise();
                        this._sectionsToAccept[sectionQuestion.subform_id] = {
                            ...this._questionsToAccept[sectionQuestion.subform_id],
                            [demographicKey]: isAccepted
                        };
                        sectionQuestions.push(sectionQuestion);
                    })
                );
            }

            sectionQuestions.forEach((sectionQuestion) => {
                // Mark to be accepted only currently visible questions inside section
                // Applicable only in a case section doesn't have own demographics
                if (formBranching[sectionQuestion.key] && formBranching[sectionQuestion.key][demographicKey] === true) {
                    this._questionsToAccept[sectionQuestion.key] = {
                        ...this._questionsToAccept[sectionQuestion.key],
                        [demographicKey]: isAccepted
                    };
                }
            });
        } else {
            this._sectionsToAccept[question.subform_id] = isAccepted;

            const sectionQuestions = await this.getQuestionsByFormId(question.subform_id).pipe(take(1)).toPromise();

            // if section repeatable - mark to be accepted visible questions inside repeated sections too
            if (question.repeatable_questions_ids) {
                await Promise.all(
                    Object.keys(question.repeatable_questions_ids).map(async (id: string) => {
                        const sectionQuestion = await this._formComponentsService.getQuestion(id).pipe(take(1)).toPromise();
                        this._sectionsToAccept[sectionQuestion.subform_id] = isAccepted;
                        sectionQuestions.push(sectionQuestion);
                    })
                );
            }

            sectionQuestions.forEach((sectionQuestion) => {
                // Mark to be accepted only currently visible questions inside section
                if (formBranching[sectionQuestion.key]) {
                    this._questionsToAccept[sectionQuestion.key] = isAccepted;
                }
            });
        }
    }

    getIsQuestionToBeAccepted(questionKey: string, demographicKey?: string): boolean {
        const isQuestionAlreadySet = demographicKey
            ? (this._questionsToAccept[questionKey] || {})[demographicKey]
            : this._questionsToAccept[questionKey];
        return !!isQuestionAlreadySet;
    }

    getQuestionsToBeAccepted(): { [questionKey: string]: boolean | { [demographicKey: string]: boolean } } {
        return { ...this._questionsToAccept };
    }

    getIsSectionToBeAccepted(subformKey: string, demographicKey?: string): boolean {
        const isSectionAlreadySet = demographicKey
            ? (this._sectionsToAccept[subformKey] || {})[demographicKey]
            : this._sectionsToAccept[subformKey];
        return !!isSectionAlreadySet;
    }

    setResponseWasChanged(questionKey: string): void {
        this.responseWasChanged = true;
        if (questionKey) {
            this._changeAnswerChangedQuestionsKeys[questionKey] = true;
            this._changeAnswerChangedQuestionsKeys$.next(this._changeAnswerChangedQuestionsKeys);
        }
    }

    getResponseWasChanged(): boolean {
        return this.responseWasChanged;
    }

    isAnyAnswerChanged(): boolean {
        return !!Object.keys(this._changeAnswerChangedQuestionsKeys).length;
    }

    getQuestionChangedAsObservable(): Observable<{ [questionKey: string]: boolean }> {
        return this._changeAnswerChangedQuestionsKeys$.asObservable();
    }

    clearChangedQuestions(): void {
        this.responseWasChanged = false;
        this._changeAnswerChangedQuestionsKeys = {};
        this._changeAnswerChangedQuestionsKeys$.next({});
        this.clearInitialFormBranching();
        this.clearQuestionsToAccept();
    }

    private clearInitialFormBranching(): void {
        this._initialFormBranching = null;
    }

    private clearQuestionsToAccept(): void {
        this._questionsToAccept = {};
        this._sectionsToAccept = {};
    }

    private getQuestionsByFormId(formKey: string): Observable<FormQuestion[]> {
        return this._formComponentsService.getQuestionsByField('form_id', formKey);
    }
}
