import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { FormItem, FormItemType, FormQuestion, isFormQuestion } from '@thinktank/common-lib';

export abstract class BaseFormGroupHandler {
    protected formBuilder: FormBuilder;

    protected constructor(formBuilder: FormBuilder) {
        this.formBuilder = formBuilder;
    }

    protected buildItemControl(item: FormItem, demographicId: string): FormControl {
        const validators = [];
        if (item.required || ((item.required || {})[demographicId])) {
            validators.push(Validators.required);
        }
        if (item.type === FormItemType.Numeric) {
            validators.push(Validators.pattern('[-+]?([0-9]*\.[0-9]+|[0-9]+)'));
        }
        const itemControl = this.formBuilder.control(this.getResponse(item, demographicId), validators);

        if (!item.visible[demographicId]) {
            itemControl.disable();
        }
        return itemControl;
    }

    protected getResponse(item: FormItem, demographicId: string): string {
        if (isFormQuestion(item)) {
            const question = item as FormQuestion;
            const responseId = !!question.section_response_id ? `${demographicId}_${question.section_response_id}` : demographicId;
            const response = !!question.response && !!question.response[responseId] && question.response[responseId].value;
            if (!!response) {
                return response;
            }

            const hasDefaultResponse = !!question.default_response && !!question.default_response[responseId];
            return hasDefaultResponse ? question.default_response[responseId].value || '' : '';
        }
        return '';
    }

    protected handleVisibilityChange(item: FormItem, control: AbstractControl, visibilityId: string): void {
        if (item.visible[visibilityId] && control.disabled) {
            // The demographic response is now visible and should be enabled
            control.enable();
        }
        if (!item.visible[visibilityId] && control.enabled) {
            // The demographic response is now invisible and should be disabled
            control.disable();
        }
    }

}
