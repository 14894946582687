import { GraphNode } from './graph-node.model';
import { FormView } from './form-view.model';

export class FormNode extends GraphNode {

    type = 'form';
    parentId: string;
    status: { [demographicId: string]: string };

    loadData(data: object) {
        super.loadData(data);
        this.parentId = data['parent_id'] || null;
        this.status = data['status'] || {};
    }

    buildFormView(): FormView {
        return {
            deployment_id: this.deploymentId,
            global: this.global,
            form_id: this.nodeId,
            items: {},
            name: this.name,
            parent_id: this.parentId,
            status: {}
        }
    }

}
