import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { Observable, Subject, firstValueFrom } from 'rxjs';
import jwtDecode from 'jwt-decode';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat';
import UserCredential = firebase.auth.UserCredential;

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUsersService } from '@app/core/services/users.service';
import { authUserOrPasswordError, defaultAuthPasswordError, defaultAuthUserError } from '@app/core/constants';
import { AcnSsoService } from '@app/core/services/acn-sso.service';
import { User as EngageUser } from '@app/core/models/user.model';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    loading: boolean;
    error: string;
    confirmation: boolean;

    private confirmationMessage$ = new Subject<boolean>();
    private error$ = new Subject<string>();
    private loading$ = new Subject<boolean>();

    constructor(
        private afAuth: AngularFireAuth,
        private db: AngularFireDatabase,
        private router: Router,
        private authUserService: AuthenticatedUserService,
        private usersService: FirebaseUsersService,
        private acnSsoService: AcnSsoService,
        private afFun: AngularFireFunctions,
    ) {
        this.loading = false;
    }

    handleResetPassword(code: string, newPassword: string): Promise<void> {
        return this.afAuth.confirmPasswordReset(code, newPassword);
    }

    verifyPasswordResetCode(code: string): Promise<string> {
        return this.afAuth.verifyPasswordResetCode(code);
    }

    getAuthErrorMessage(errorMessage: string): string {
        if (errorMessage.includes(defaultAuthUserError) || errorMessage.includes(defaultAuthPasswordError)) {
            return authUserOrPasswordError;
        } else {
            return errorMessage;
        }
    }

    registerWithEmail(email: string, firstName: string, lastName: string, password: string): Promise<boolean> {
        this.loading = true;
        this.loading$.next(this.loading);
        let isRegistered = false;

        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then(credential => credential.user.sendEmailVerification().then(() => credential))
            .then(credential => this.updateUserData(credential.user.uid, email, firstName, lastName))
            .then(() => {
                isRegistered = true;

                return isRegistered;
            })
            .catch(error => {
                this.setError(error);

                return isRegistered;
            })
            .finally(async () => {
                await this.afAuth.signOut();
                this.loading = false;
                this.loading$.next(this.loading);
            });
    }

    async login(email?: string, password?: string): Promise<void> {
        this.loading = true;
        this.loading$.next(this.loading);

        if (email) {
            await this.afAuth
                .signInWithEmailAndPassword(email, password)
                .then(credential => {
                    if (!credential.user.emailVerified) {
                        throw Error('This account has not been verified');
                    }
                    this.getCurrentUser();
                })
                .catch(error => {
                    this.error = this.getAuthErrorMessage(error.message);
                    this.error$.next(this.error);
                    this.loading = false;
                    this.loading$.next(this.loading);
                });
        } else {
            await this.acnSsoService.login();
        }
    }

    async completeAuthentication(): Promise<void> {
        try {
            const { userCredential, userEmail, firstName, lastName } =
                await this.acnSsoService.completeAuthentication();

            if (userCredential.user) {
                await this.updateUserData(userCredential.user.uid, userEmail, firstName, lastName);

                return await this.getCurrentUser(firstName, lastName);
            }
        } catch (error) {
            console.log('AuthenticationService::completeAuthentication - Unable to complete SSO authentication', error);
            await this.acnSsoService.removeUser();
            this.router.navigate(['/']);
        }
    }

    isLoggedIn(): Promise<boolean> {
        return this.afAuth.currentUser.then(user => !!user);
    }

    async resetPasswordInit(email: string) {
        try {
            await this.afAuth.sendPasswordResetEmail(email);
            this.confirmation = true;
            this.confirmationMessage$.next(this.confirmation);
            setTimeout(() => {
                this.router.navigate(['login']);
            }, 1500);
        } catch (error) {
            this.handleSendPasswordResetEmailError(error);
        }
    }

    async changePassword(newPassword: string) {
        const user = await this.afAuth.currentUser;

        try {
            await user.updatePassword(newPassword);
            this.confirmation = true;
            this.confirmationMessage$.next(this.confirmation);
        } catch (error) {
            this.handleChangePasswordError(error);
        }
    }

    async verifyEmail(outOfBandCode: string): Promise<void> {
        return this.afAuth.applyActionCode(outOfBandCode);
    }

    async logout(): Promise<void> {
        this.loading = true;
        this.loading$.next(this.loading);

        await this.afAuth
            .signOut()
            .then(() => this.acnSsoService.removeUser())
            // Page needs to be reloaded, so we are not using navigate
            .then(() => (location.href = '/login'))
            .catch(error => console.log('AuthenticationService::logout - error during logout', error.message))
            .finally(() => {
                this.loading = false;
                this.loading$.next(this.loading);
            });
    }

    isLoading(): Observable<boolean> {
        return this.loading$.asObservable();
    }

    errorMessage(): Observable<string> {
        return this.error$.asObservable();
    }

    returnConfirmation(): Observable<boolean> {
        return this.confirmationMessage$.asObservable();
    }

    private async getCurrentUser(firstName = '', lastName = '') {
        const user = await this.afAuth.currentUser;
        const token = await user.getIdToken();
        const tokenData = jwtDecode(token) as Record<string, string>;

        // Need to update the token given_name (firstName) and family_name (lastName) because the firebase token
        // does not have this information. That data will be passed in from the new user register code.
        // This also applies to the Accenture SSO code, the names are passed in and we need to set email also (in sub property)
        if (!tokenData.given_name) {
            tokenData.given_name = firstName;
        }

        if (!tokenData.family_name) {
            tokenData.family_name = lastName;
        }

        if (!tokenData.email) {
            tokenData.email = tokenData.sub;
        }

        this.authUserService
            .initializeCurrentUser(tokenData)
            .pipe(take(1))
            .subscribe(() => {
                this.router.navigate(['/navigation']);
            });
    }

    private async updateUserData(uid, email, firstName, lastName): Promise<AngularFireObject<EngageUser>> {
        const match = await firstValueFrom(this.usersService.getUserByEmail(email).pipe(take(1)));
        const userKey = !!match ? match.id : this.db.createPushId();
        const userPath = `users/${userKey}`;

        const update = {
            active: true,
            firstName,
            lastName,
            email: email.toLowerCase(),
            username: email.toLowerCase(),
            firebaseAuthenticationId: uid,
        };

        await this.db.object(userPath).update(update);

        if (match?.authenticationId) {
            await this.db.object<string>(`${userPath}/authenticationId`).set(null);
        }

        if (!match) {
            try {
                // Assign the default template to the new user
                await firstValueFrom(this.afFun.httpsCallable('addUserToDefaultTemplate')({ userId: userKey, email: update.email }));
            } catch (error) {
                console.log(`AuthenticationService::updateUserData - Unable to add user to the default template for ${update.email}`, error);
            }
        }

        return this.db.object<EngageUser>(userPath);
    }

    private handleRegisterWithEmailError(error: Error): void {
        this.afAuth.signOut();
        this.setError(error);
        this.loading = false;
        this.loading$.next(this.loading);
    }

    private handleUpdateUserDataError(): void {
        this.afAuth.signOut();
    }

    private handleLoginWithEmailError(error: Error): void {
        this.error = this.getAuthErrorMessage(error.message);
        this.error$.next(this.error);
        this.loading = false;
        this.loading$.next(this.loading);
    }

    private handleSendPasswordResetEmailError(error: Error): void {
        this.setError(error);
        this.loading = false;
        this.loading$.next(this.loading);
    }

    private handleChangePasswordError(error: Error): void {
        this.setError(error);
    }

    private setError(error: Error): void {
        this.error = error.message;
        this.error$.next(this.error);
    }
}
