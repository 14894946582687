<div [hidden]="true">
  <mat-paginator
    class="hidden"
    [length]="dataLength"
    [pageSize]="pageSize"
    [hidePageSize]="true"
  >
  </mat-paginator>
</div>

<!-- Paginator -->
<div *ngIf="dataLength && dataLength >= 10" class="paginator">

  <button
    class="button prev"
    [disabled]="!hasPreviousPage"
    (click)="setActivePage(currentPageIndex - 1)"
  >
    <mat-icon>arrow_back_ios</mat-icon>
    <span>Previous</span>
  </button>

  <div class="pages">
    <ng-container *ngFor="let page of paginatorArray; trackBy: trackByIndex">
      <div
        class="page"
        [ngClass]="{ 'active' : page.index === activePageNumber }"
        (click)="setActivePage(page.index)"
      >
        {{ page.value }}
      </div>
    </ng-container>
  </div>

  <button
    class="button next"
    [disabled]="!hasNextPage"
    (click)="setActivePage(currentPageIndex + 1)"
  >
    <span>Next</span>
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>
