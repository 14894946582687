import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    CanActivateChild,
    RouterStateSnapshot,
    ActivatedRouteSnapshot
} from '@angular/router';

import { AuthenticatedUserService } from '../services/authenticated-user.service';

@Injectable()
export class AdminGuard implements CanActivate, CanActivateChild {

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private router: Router
    ) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.checkAdminRole(route.params);
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(route, state);
    }

    private checkAdminRole(params: any): boolean {
        const currentUserKey = this.authenticatedUserService.getCurrentUserId();
        if (params.userKey && (params.userKey === currentUserKey)) {
            return true;
        }

        if (this.authenticatedUserService.isUserAdmin()) {
            return true;
        }

        if (params.userKey && (params.userKey !== currentUserKey)) {
            this.router.navigate([
                'administration',
                'profile',
                currentUserKey
            ]);
            return false;
        }

        this.router.navigate(['/navigation']);

        return false;
    }
}
