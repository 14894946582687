import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireDatabase, AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFireFunctions, AngularFireFunctionsModule } from '@angular/fire/compat/functions';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
// import { StarRatingModule } from 'angular-star-rating';
import { NgxPendoModule } from 'ngx-pendo';
import { environment } from '@env/environment';
import { MaterialModule } from '@thinktank/common-lib';
import { CoreModule } from '@app/core/core.module';
import { LoginModule } from '@app/login/login.module';
import { DirectLoginModule } from '@app/login/direct-login.module';
import { SignonModule } from './signon/signon.module';
import { AppComponent } from '@app/app.component';
import { ThinkTankRoutingModule } from '@app/app-routing.module';
import { SharedModule } from '@app/shared/shared.module';
import { RootStoreModule } from './root-store/root-store.module';
import { NgxLinkifyjsModule } from 'ngx-linkifyjs';

@NgModule({
    declarations: [AppComponent],
    imports: [
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        // StarRatingModule.forRoot(),
        MaterialModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireDatabaseModule,
        AngularFireFunctionsModule,
        AngularFireAnalyticsModule,
        CoreModule,
        SharedModule,
        ThinkTankRoutingModule,
        HttpClientModule,
        LoginModule,
        DirectLoginModule,
        SignonModule,
        RootStoreModule,
        NgxPendoModule.forRoot({
            pendoApiKey: environment.pendo.APIKey,
        }),
        NgxLinkifyjsModule.forRoot(),
    ],
    providers: [AngularFireFunctions, AngularFireDatabase, ScreenTrackingService, UserTrackingService],
    bootstrap: [AppComponent],
})
export class AppModule {}
