import { TimerData, ValidationErrors } from '@app/core/models';

export const defaultTopXLimit = 3;
export const defaultVotesLimit = 5;

export const isActivityFocused = 'Focus activity to complete event flow';
export const isActivityCompleted = 'This activity has already been completed';
export const isActivitySelected = 'This activity is not selected in Event Flow';

export const completeText = 'Complete Event Flow';
export const completingText = 'Completing';

export const eventFlowValidationHtml = {
    [ValidationErrors.Responses]:
        '<b class="text-bold">•</b> There are <b class="text-bold">no responses</b> to carry over to ',
    [ValidationErrors.RowBookmarks]:
        '<b class="text-bold">•</b> <b class="text-bold">Bookmark</b> the <b class="text-bold">rows</b> you want to carry over to ',
    [ValidationErrors.Rows]: '<b class="text-bold">•</b> There are <b class="text-bold">no rows</b> to carry over to ',
    [ValidationErrors.Bookmarks]:
        '<b class="text-bold">•</b> <b class="text-bold">Bookmark</b> the <b class="text-bold">responses</b> you want to carry over to ',
    [ValidationErrors.Comments]:
        '<b class="text-bold">•</b> There are <b class="text-bold">no comments</b> to carry over to ',
    [ValidationErrors.Likes]:
        '<b class="text-bold">•</b> There are <b class="text-bold">no upvotes</b> to carry over to ',
    [ValidationErrors.Groups]:
        '<b class="text-bold">•</b> There are <b class="text-bold">no groups</b> to carry over to ',
};

export const initialTimerData: TimerData = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    is_running: false,
};
