import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AttachmentService } from '@app/core/services/attachment.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import {
    Attachment
} from '@app/core/models';
import { RoutingService } from '@app/apps/core/services/routing.service';

@Component({
    selector: 'app-attachments-dialog',
    templateUrl: './attachments-dialog.component.html',
    styleUrls: ['./attachments-dialog.component.scss']
})

export class AttachmentsDialogComponent implements OnInit, OnDestroy {
    demographicKey: string;
    questionKey: string;
    title: string;
    attachmentsType: string;
    userKey: string;
    attachments: Attachment[];
    displayedColumns: string[];
    permissions: string[];
    private onDestroy = new Subject<void>();

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _fbAttachmentService: AttachmentService,
        private _authUserService: AuthenticatedUserService,
        private _dialogRef: MatDialogRef<AttachmentsDialogComponent>,
        private _routingService: RoutingService
    ) {
        this.displayedColumns = ['name'];
        this.attachments = [];
    }

    downloadFile(attachment: Attachment): void {
        this._routingService.navigateToDownloadPage('attachment', attachment.key, this.questionKey);
    }

    removeFile(attachment: Attachment): void {
        this._fbAttachmentService.deleteAttachment(this.questionKey, attachment.key);
    }

    ngOnInit() {
        this.questionKey = this.data.questionKey;
        this.demographicKey = this.data.demographicKey;
        this.title = this.data.title || 'Files';
        this.attachmentsType = this.data.attachmentsType || 'response';
        this.permissions = this.data.permissions || ['download'];
        this.userKey = this._authUserService.getCurrentUserId();

        this.displayedColumns = this.displayedColumns.concat(this.permissions);

        const attachments$ = (this.attachmentsType === 'response')
            ? this._fbAttachmentService.getAttachmentByOwner(this.questionKey, this.userKey, 'response')
            : this._fbAttachmentService.getAttachmentByType(this.questionKey, 'question');

        attachments$
            .pipe(takeUntil(this.onDestroy))
            .subscribe((attachments) => {
                this.attachments = (this.attachmentsType === 'response')
                    ? attachments.filter((attachment) => !this.demographicKey ? true : attachment.sub_key === this.demographicKey)
                    : attachments;

                if (!this.attachments.length) {
                    this._dialogRef.close();
                }
            });
    }

    ngOnDestroy() {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
