export enum ValidationErrors {
    Responses = 'RESPONSES',
    Rows = 'ROWS',
    RowBookmarks = 'ROW_BOOKMARKS',
    Comments = 'COMMENTS',
    Groups = 'GROUPS',
    Bookmarks = 'BOOKMARKS',
    Likes = 'LIKES',
}

// validationErrorType -> validationErrors; string[] -> activityId[]
export interface ValidationErrorsType {
    [validationErrorType: string]: string[];
}
