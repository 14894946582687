<div class="description" [formGroup]="parentForm">
  <mat-form-field class="without-label">
    <input
      matInput
      type="text"
      formControlName="description"
      placeholder="Note"
      [id]="uniqueId">
  </mat-form-field>
</div>
