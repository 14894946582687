export enum IssueType {
    Bug = 'bug',
    Story = 'story',
    Task = 'task',
    Epic = 'epic',
    Default = 'story'
}

export const IssueTypesMap = {
    [IssueType.Bug]: 'Bug',
    [IssueType.Story]: 'Story',
    [IssueType.Task]: 'Task',
    [IssueType.Epic]: 'Epic'
};
