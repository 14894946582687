<div
  class="stars-container"
  [ngClass]="{ 'form-editable-question': isFormEditQuestionView }"
>
  <span
    *ngFor="let star of stars; trackBy: trackById"
    class="star"
    [ngClass]="{
      'filled': star.filled,
      'disabled': disabled,
      'found': isSearchResponseFound && star.filled
    }"
    (click)="setRating(star.value)"
  >
    <mat-icon>star</mat-icon>
  </span>
</div>
