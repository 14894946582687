import { Variable } from './variable.model';
import { FormItemNode } from './form-item-node.model';
import { FormItemType, FormSection } from './form-view.model';
import { Demographic } from './demographic.model';

interface SectionCache {
    [demographicSectionId: string]: {
        [nodeId: string]: any
    }
}

export class SectionNode extends FormItemNode {

    type = 'section';
    repeatable: boolean;
    description: string;
    repeated_sections: any;
    cache: SectionCache;

    loadData(data: object) {
        super.loadData(data);
        this.repeatable = data['repeatable'] || false;
        this.description = data['description'] || '';
        this.repeated_sections = data['repeated_sections'] || {};
        this.cache = data['cache'] || {};
    }

    buildFormView(formItems: FormItemNode[], demographics: Demographic[]): FormSection {
        const sectionQuestions = formItems.filter((formItem) => formItem.sectionId === this.nodeId);
        const questions = sectionQuestions.reduce((accumulator, sectionQuestion) => {
            accumulator[sectionQuestion.nodeId] = sectionQuestion.buildFormView(formItems, demographics);
            return accumulator;
        }, {});

        return {
            id: this.nodeId,
            form_id: this.formId,
            description: this.description,
            type: this.type as FormItemType,
            questions: questions,
            repeatable: this.repeatable,
            repeated_sections: this.repeated_sections,
            visible: this.generateVisibilityMap(demographics),
            sequence: this.sequence,
            required: this.required
        }
    }

    async deleteRepeatedSection(sectionId: string, demographicId: string): Promise<void> {
        const cacheKey = `${demographicId}_${sectionId}`;
        const repeatedSectionEntry = this.cache[cacheKey];
        if (!!repeatedSectionEntry) {
            delete this.cache[cacheKey];
            await this.graphService.updateNode(this.deploymentId, this.nodeId, { cache: this.cache });
            await this.propagateValues(demographicId);
        }
    }

    async setInput(name: string, variableData: Variable): Promise<void> {
        console.log(`SectionNode::setInput - ${this.nodeId} ${name}`, this.consoleLogVariable(variableData));
        if (name === 'visible') {
            return super.setInput(name, variableData);
        }

        if (name === 'input') {
            const { value, demographicId, sourceNode, sectionId } = variableData;
            const responseId = !!sectionId ? `${demographicId}_${sectionId}`: demographicId;
            const nodeId = sourceNode.nodeId;

            // Don't store a falsy value if the value is not already there
            const currentValue = this.getCacheValue(responseId, nodeId);
            if (!currentValue && !value) {
                return;
            }

            if (!this.cache[responseId]) {
                this.cache[responseId] = {};
            }
            this.cache[responseId][nodeId] = value;
            const update = { [`cache.${responseId}.${nodeId}`]: value };
            await this.graphService.updateNode(this.deploymentId, this.nodeId, update);
            await this.propagateValues(demographicId);
        }
    }

    async propagateValues(demographicId: string): Promise<void> {
        const value = Object.keys(this.cache).reduce((accumulator, demographicSectionId) => {
            const currentQuestionGrouping = this.cache[demographicSectionId];
            if (Object.keys(currentQuestionGrouping).length > 1) {
                accumulator.push(currentQuestionGrouping);
            }
            return accumulator;
        }, []);

        if (value.length > 0) {
            const variable = this.buildVariable(demographicId, value);
            await this.propagateVariable(variable);
        }
    }

    private getCacheValue(demographicSectionId: string, nodeId: string): any {
        return !!this.cache[demographicSectionId] && this.cache[demographicSectionId][nodeId];
    }

}
