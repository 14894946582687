<div *ngIf="demographics?.length; else noDemographics" class="demographics-list">
  <ng-container>
    <div
      *ngFor="let demographic of demographics; trackBy: trackById"
      class="demographics-list-item gs-demographic-chip"
    >
      <span class="gs-demographic-chip-label">{{ demographic.name }}</span>
    </div>
  </ng-container>
  <button *ngIf="canChange" class="gs-button-flat gs-small" (click)="change.emit()">Change</button>
</div>

<ng-template #noDemographics>
  <div class="demographics-list">
    <p class="caption text-gray">No countries have been selected for which this {{ viewType | titlecase }} will be used so far</p>
  </div>
</ng-template>
