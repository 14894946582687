export class Category {
    key?: string;
    category_name: string;
    number_responses: number;
    owner: string;
    color?: string;
    responses?: { [responseKey: string]: boolean };
    sequence?: number;
}

export const testCategory = <Category>{
    category_name: 'Winner',
    number_responses: 1,
    owner: 'user1',
    color: '#000',
    responses: {
        'response1': true,
        'response2': true
    },
    sequence: 1
};
