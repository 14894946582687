import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'shortName',
    pure: true
})
export class ShortNamePipe implements PipeTransform {

    transform(fullName: string): string {
        const [name, surname] = fullName.split(' ');

        return `${name && name[0]}${!!surname && surname[0]}`;
    }

}
