import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DeploymentsService, PendingDeployment } from '@thinktank/common-deployments';
import { AuthenticatedUserService, RouterService } from '@app/core/services';

@UntilDestroy()
@Component({
    selector: 'app-deployments-create-snack-bar',
    templateUrl: './deployments-create-snack-bar.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeploymentsCreateSnackBarComponent implements OnInit, OnDestroy {
    @Input() createDeploymentOpened?: boolean;

    deploymentLoading$ = new BehaviorSubject<boolean>(null);
    deploymentReady$ = new BehaviorSubject<boolean>(null);
    showSnackBar$ = new BehaviorSubject<boolean>(null);
    pendingDeploymentData: PendingDeployment;
    creatingDeploymentName: string;
    creatingDeploymentClientName: string;

    constructor(
        private deploymentsService: DeploymentsService,
        private fbAuthUserService: AuthenticatedUserService,
        private routerService: RouterService
    ) {
        this.deploymentLoading$.next(false);
        this.deploymentReady$.next(false);
    }

    get snackBarText(): string {
        return this.deploymentLoading$.getValue()
            ? 'deployment is creating'
            : 'deployment is ready';
    }

    onCloseSnackbar(): void {
        this.showSnackBar$.next(false);
        this.deploymentReady$.next(false);
    }

    setNewDeployment(): void {
        this.routerService.navigateTo([ '/deployment', this.pendingDeploymentData.id ]);
        this.onCloseSnackbar();
    }

    ngOnInit() {
        const currentUserId = this.fbAuthUserService.getCurrentUserId();
        this.deploymentsService.getPendingDeployments(currentUserId)
            .pipe(untilDestroyed(this))
            .subscribe((pendingDeployments) => {
                if (this.deploymentLoading$.getValue() && !pendingDeployments.length) {
                    this.showSnackBar$.next(true);
                    this.deploymentLoading$.next(false);
                    this.deploymentReady$.next(true);

                    // close snackbar after 10 sec
                    setTimeout(() => {
                        this.onCloseSnackbar();
                    }, 10000);
                    return;
                }

                if (!!pendingDeployments && pendingDeployments.length > 0) {
                    this.deploymentLoading$.next(true);
                    this.showSnackBar$.next(true);
                    this.deploymentReady$.next(false);
                    this.creatingDeploymentName = pendingDeployments[0].deployment_name;
                    this.creatingDeploymentClientName = pendingDeployments[0].client_name;
                    this.pendingDeploymentData = pendingDeployments[0];
                }
            });
    }

    ngOnDestroy() { }
}
