export enum FormStateEnum {
    InProgress = 'IN_PROGRESS',
    Draft = 'DRAFT',
    Locked = 'LOCKED',
    DraftReview = 'DRAFT_REVIEW',
    Alignment = 'ALIGNMENT',
    PendingApproval = 'PENDING_APPROVAL',
    Approved = 'APPROVED',
    Denied = 'DENIED'
}

export class FormState {
    form_id: string;
    activity_id: string;
    status: FormStateEnum;
    owner_id?: string;
    demographic_id?: string;
    key?: string;
}
