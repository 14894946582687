import { Component, Inject } from '@angular/core';

import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-form-impact',
    templateUrl: './form-impact.component.html',
    styleUrls: ['./form-impact.component.scss']
})
export class FormImpactComponent {
    vm$ = this.formFacade.ivm$;

    constructor(@Inject(DeploymentFormFacadeToken) private formFacade: DeploymentFormFacade) {}

    navigateToForm(deploymentId: string, formId: string): void {
        this.formFacade.navigateToLocation([
            'deployment',
            deploymentId,
            'form',
            formId
        ]);
    }
}
