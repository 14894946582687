export class MockGateService {
    getGates() { }
    getGate() { }
    completeGate() { }
}

export class MockLogService {
    getProjectLogs() {}
    getActivityLogs() {}
    getSlideLogs() {}
    getLog() {}
    addLog() {}
    addImpacts() {}
    addRequirements() {}
    addMitigationStrategies() {}
    hasProjectLogs() {}
    hasFormLevelLogs() {}
    removeSetupOption() {}
    updateSetupOption() {}
    addSetupOption() {}
    addCheckBoxOption() {}
    getSetupOptions() {}
    getProjectCountries() {}
    getDataOptions() {}
}

export class MockRepositoryService {
    getProjectExportLatestVersion() {}
    getProjectExportVersions() {}
    getProjectLatestVersion() {}
    getExportVersions() {}
}

export class MockResponseHistoryService {
    getQuestionResponsesHistory() {}
    getQuestionResponses() {}
    getHistoryResponseComments() {}
    buildResponse() {}
    getQuestionCurrentResponseCommentsCount() {}
    toggleUpvote() {}
    addComment() {}
    addResponse() {}
    updateComment() {}
    deleteComment() {}
    updateInitialResponse() {}
}

export class MockMatDialog {
    open() {}
}

export class MockDialogService {
    open() { }
}

export class MockTagsService {
    addCommonTag() {}
    changeTagCount() {}
    addTagInApp() {}
    deleteTagFromApp() {}
    getCommonTags() {}
}
