import { GraphNode } from './graph-node.model';
import { Variable } from './variable.model';
import { isEmpty } from 'lodash';
import { isEqual } from '../utils'
import { FormItemType, FormItem, FormQuestion, FormViewBuilder, Visibility } from './form-view.model';
import { Demographic, GlobalDemographic } from './demographic.model';

export interface Label {
    default: string;
    current_value?: string;
}

export class FormItemNode extends GraphNode implements FormViewBuilder {
    visible: any;
    required: any;
    label?: Label;
    response?: any;
    defaultResponse?: any;
    formId?: string;
    sequence: number;
    sectionId?: string;

    loadData(data: object) {
        super.loadData(data);
        this.label = data['label'];
        this.response = data['response'] || {};
        this.defaultResponse = data['default_response'] || '';
        this.visible = data.hasOwnProperty('visible') ? data['visible'] : {};
        this.required = data.hasOwnProperty('required') ? data['required'] : {};
        this.formId = data.hasOwnProperty('form_id') ? data['form_id'] : '';
        this.sequence = data.hasOwnProperty('sequence') ? data['sequence'] : 0;
        this.sectionId = data.hasOwnProperty('section_id') ? data['section_id'] : '';
    }

    buildFormView(formItems: FormItemNode[], demographics: Demographic[]): FormItem {
        const questionFormView =  {
            id: this.nodeId,
            form_id: this.formId,
            label: this.label,
            type: this.type as FormItemType,
            response: this.response,
            visible: this.generateVisibilityMap(demographics),
            required: this.required,
            default_response: this.defaultResponse,
            sequence: this.sequence
        } as FormQuestion;

        if (!!this.sectionId) {
            questionFormView.section_node_id = this.sectionId;
        }
        return questionFormView;
    }

    protected generateVisibilityMap(demographics: Demographic[]): Visibility {
        const visibilityMap = {};
        if (this.global) {
            visibilityMap['global'] = this.visible.hasOwnProperty('global') ? this.visible.global : true;
        } else {
            demographics.forEach((demographic: Demographic) => {
                if (this.visible.hasOwnProperty(demographic.id)) {
                    visibilityMap[demographic.id] = this.visible[demographic.id];
                } else {
                    visibilityMap[demographic.id] = true;
                }
            })
        }
        return visibilityMap;
    }

    getResponse(): any {
        return this.response || {};
    }

    async setResponse(responseId: string, responseObject: any, formId: string): Promise<any> {
        const currentResponse = this.getResponseValueForId(responseId);
        console.log('FormItemNode::setResponse', JSON.stringify({ currentResponse, responseObject }));
        if (!isEqual(responseObject.value, currentResponse)) {
            const responseUpdate = {
                [`response.${responseId}`]: responseObject,
                updated: Date.now()
            };
            this.response[responseId] = {
                ...this.response[responseId],
                ...responseObject
            };
            await this.graphService.setResponse(this.deploymentId, this.nodeId, responseUpdate);

            const value = this.getResponseValueForId(responseId);
            const variable = this.buildVariable(responseObject.demographic_id, value);
            const response = this.getResponseForId(responseId);
            if (!!response.section_response_id) {
                variable.sectionId = response.section_response_id;
            }
            return this.propagateVariable(variable);
        }
    }

    async setInput(name: string, variableData: Variable): Promise<void> {
        let changed = false;
        let oldVal;

        console.log(`FormItemNode::setInput - ${this.nodeId} ${name}`, this.consoleLogVariable(variableData));
        if (this.shouldDoSetInput(variableData.demographicId)) {
            switch (name) {
                case 'default_value':
                    oldVal = this.defaultResponse;
                    this.defaultResponse = await this.handleVariableUpdates('default_response', this.defaultResponse, variableData.value, variableData.demographicId);
                    changed = !isEqual(oldVal, this.defaultResponse);
                    break;
                case 'visible':
                    oldVal = this.visible;
                    this.visible = {
                        ...this.visible,
                        ...await this.handleVariableUpdates(name, this.visible, variableData.value, variableData.demographicId)
                    };
                    changed = !isEqual(oldVal, this.visible);
                    break;
                case 'required':
                    oldVal = this.required;
                    this.required = {
                        ...this.required,
                        ...await this.handleVariableUpdates(name, this.required, variableData.value, variableData.demographicId)
                    };
                    changed = !isEqual(oldVal, this.required);
                    break;
                case 'label':
                    oldVal = this.label;
                    if (this.shouldChangeLabel(variableData.value)) {
                        this.label = {
                            ...this.label,
                            current_value: variableData.value
                        };
                        const formViewPropertyPath = this.calculateFormViewPropertyPath(name);
                        await this.graphService.writeVariableUpdates(this.deploymentId, this.nodeId, this.formId, name, formViewPropertyPath, this.label);
                    }
                    break;
                case 'options':
                    console.log(`FormItemNode::setInput called with OPTIONS`, variableData);
                    break;
                default:
                    console.log('UNKNOWN NAME TO SET INPUT FOR:::', name);
            }
        }

        if (changed) {
            console.log(`FormItemNode::setInput() ${this.nodeId} changed data - propagating`);
            await this.propagateValues(variableData.demographicId);
        }
    }

    private shouldDoSetInput(demographicId: string): boolean {
        // The only case where we don't do setInput is this this is global and the demographicId is not global
        return !(this.global && demographicId !== GlobalDemographic.id);
    }

    async propagateValues(demographicId: string): Promise<void> {
        const value = this.getResponseValueForId(demographicId);
        const variable = this.buildVariable(demographicId, value);
        await this.propagateVariable(variable);
    }

    private shouldChangeLabel(value: string): boolean {
        // The value is not '' and it is either not the current_value (if it exists) or not the default value
        return !!value && (!!this.label.current_value ? this.label.current_value !== value : this.label.default !== value);
    }

    private createDemographicMapForGlobalUpdate(source: { [demographicId: string]: any }, value: any): { [demographicId: string]: any } {
        return Object.keys(source).reduce((accumulator, demographicId) => {
            accumulator[demographicId] = value;
            return accumulator;
        }, {});
    }

    private isGlobalToLocal(demographicId: string): boolean {
        return demographicId === 'global' && !this.global;
    }

    private getResponseForId(responseId: string): any {
        return isEmpty(this.response) ? {} : this.response[responseId] || {};
    }

    protected getResponseValueForId(responseId: string): any {
        return isEmpty(this.response) ? this.defaultResponse || '' : (this.response[responseId] || {}).value || '';
    }

    protected calculateFormViewPropertyPath(property: string): string {
        return !!this.sectionId
            ? `items.${this.sectionId}.questions.${this.nodeId}.${property}`
            : `items.${this.nodeId}.${property}`;
    }

    private async handleVariableUpdates(
        property: string,
        source: { [demographicId: string]: any },
        value: any,
        demographicId: string
    ): Promise<{ [demographicId: string]: any }> {
        let update;
        let graphPropertyPath;
        let formViewPropertyPath;
        if (this.isGlobalToLocal(demographicId)) {
            // We need to overwrite the current property map that contains the global key only with all demographics
            // use update on the specific object
            update = this.createDemographicMapForGlobalUpdate(source, value);
            graphPropertyPath = property;
            formViewPropertyPath = this.calculateFormViewPropertyPath(property);
        } else {
            // We need to update 1 key in the property map, use update with dot notation for the property
            update = value;
            graphPropertyPath = `${property}.${demographicId}`;
            formViewPropertyPath = `${this.calculateFormViewPropertyPath(property)}.${demographicId}`;
        }
        await this.graphService.writeVariableUpdates(this.deploymentId, this.nodeId, this.formId, graphPropertyPath, formViewPropertyPath, update);
        return update;
    }

}
