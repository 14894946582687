<div class="event-flow-results-dialog">
  <h3 mat-dialog-title class="h3">Event Flow</h3>
  <mat-dialog-content>
    <ng-container *ngIf="incorrectActivities.length">
      <p>
        We’re sorry, you’ve selected Apply Groups in your designation activit{{ incorrectActivities.length === 1 ? 'y' : 'ies' }}
        <span *ngFor="let activity of incorrectActivities; let last = last; trackBy: trackByKey">
          {{ activity.activity_name }}<span *ngIf="!last">, </span>
        </span>
        but there are no groups in it, so Event Flow can’t be performed for this activit{{ incorrectActivities.length === 1 ? 'y' : 'ies' }}.
      </p>
      <p *ngIf="successActivities.length">
        Event flow is successfully completed for:
        <span *ngFor="let activity of successActivities; let last = last; trackBy: trackByKey">
          {{ activity.activity_name }}<span *ngIf="!last">, </span>
        </span>
      </p>
    </ng-container>
    <ng-container *ngIf="!incorrectActivities.length">
      <p>Event Flow is Complete!</p>
    </ng-container>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button cdkFocusInitial class="tt-button-filled" (click)="onSubmit()">
      {{alertConfirmBtnText}}
    </button>
  </mat-dialog-actions>
</div>
