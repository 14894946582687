<div id="virtual-scroll-space-top" [style.height.px]="spaceTopHeight"></div>

<ng-container *ngFor="let el of (loadedListItems$ | async) || defaultListItems; trackBy: trackById let index = index">
  <div [attr.id]="getElId(el.id)" *ngIf="disableVirtualScroll || showListItem(index)">
    <ng-template *ngTemplateOutlet="el.innerTemplate"></ng-template>
  </div>
</ng-container>

<div *ngIf="showLoader && !disable" class="loader-container">
  <app-spinner class="spinner-primary-md"></app-spinner>
</div>

<div id="virtual-scroll-space-bottom" [style.height.px]="spaceBottomHeight"></div>
