import {
    Input,
    Output,
    OnChanges,
    Component,
    EventEmitter,
    SimpleChanges,
    ChangeDetectionStrategy
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { uniqBy } from 'lodash';

import { objectTypeSet, ObjectType, Ownership, Process, Subprocess, UserRole, Volunteer } from '../../../models';
import { getNumberChangeRequests, getPulseDate, getSatisfactionValue } from '../../../services/view-utils';
import { ChangeRequest, Demographic, trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-view-processes-table',
    templateUrl: './view-processes-table.component.html',
    styleUrls: ['./view-processes-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewProcessesTableComponent implements OnChanges {
    @Input() viewType: string;
    @Input() currentUserId?: string;
    @Input() deploymentId?: string;
    @Input() moduleId?: string;
    @Input() processId?: string;
    @Input() demographics: Demographic[];
    @Input() ownership: Ownership[];
    @Input() impactData?: any[];
    @Input() changeRequests?: ChangeRequest[];
    @Input() processes?: Process[];
    @Input() subprocesses?: Subprocess[];

    @Input() showProcesses?: boolean;
    @Input() showSubprocesses?: boolean;
    @Input() showProcessesImpacted?: boolean;
    @Input() showAll?: boolean;

    @Output() sendPulseForProcess = new EventEmitter<Process | Subprocess>();
    @Output() sendPulseForSubProcess = new EventEmitter<Process | Subprocess>();
    @Output() volunteer: EventEmitter<{volunteerDataObject: Volunteer, deploymentId: string}> = new EventEmitter<{volunteerDataObject: Volunteer, deploymentId: string}>();

    tableData$ = new BehaviorSubject<any>([]);
    trackById = trackById;
    subprocessCount$ = new BehaviorSubject<number>(0);

    constructor() { }

    showTable(tableName: string): boolean {
        let returnVal = false;
        switch (tableName) {
          case 'all':
            returnVal = this.showAll && !this.showSubprocesses && !this.showProcessesImpacted;
            break;
          case 'process':
            returnVal = !this.showAll && !this.showSubprocesses && !this.showProcessesImpacted;
            break;
          case 'subprocess':
            returnVal = this.showSubprocesses && !this.showProcessesImpacted;
            break;
          case 'impact':
            returnVal = this.showProcessesImpacted;
            break;
        }
        return returnVal;
    }

    isVolunteerDisabled(item: Subprocess): boolean {
        let isDisabled = true;
        const myOwnership = (this.ownership || []).filter((ownershipObj) => {
            return ownershipObj.object_id === item.id &&
                   ownershipObj.role === UserRole.Stakeholder &&
                   ownershipObj.user_id === this.currentUserId;
        });

        if (item.global) {
            const isPrimaryGlobalOwner = myOwnership.find((owner) => owner.primary);
            isDisabled = !!isPrimaryGlobalOwner;
        } else {
            const myDemoOwnershipMap = {};
            (this.demographics || []).forEach((demographic) => {
                const isPrimaryDemoOwner = myOwnership.find((owner) => owner.demographic_id === demographic.id && owner.primary);
                myDemoOwnershipMap[demographic.id] = !!isPrimaryDemoOwner;
            });

            const hasFalseValues = (Object.values(myDemoOwnershipMap).some((isPrimarySME) => !isPrimarySME));
            isDisabled = !hasFalseValues;
        }

        return isDisabled;
    }

    volunteerForSubprocess(item: Subprocess): void {
        const myOwnership = (this.ownership || []).filter((ownershipObj) => {
            return ownershipObj.object_id === item.id &&
                   ownershipObj.role === UserRole.Stakeholder &&
                   ownershipObj.user_id === this.currentUserId &&
                   ownershipObj.primary;
        });

        let volunteerDemographics = this.demographics;
        if (myOwnership.length > 0) {
            volunteerDemographics = volunteerDemographics.filter((demographic) => {
                const isPrimaryDemoOwner = myOwnership.find((owner) => owner.demographic_id === demographic.id);
                return !isPrimaryDemoOwner;
            });
        }

        this.volunteer.emit({
            volunteerDataObject: <Volunteer>{
                item: item,
                demographics: volunteerDemographics
            },
            deploymentId: this.deploymentId
        });
    }

    getNumberChangeRequests(item: Process | Subprocess, isOpen: boolean): number {
        return getNumberChangeRequests(item, (this.changeRequests || []), isOpen);
    }

    getSatisfaction(item: Process | Subprocess): number {
        return getSatisfactionValue(item, this.changeRequests || []);
    }

    getPulseDate(item: Process | Subprocess): Date/*firebase.firestore.Timestamp*/ {
        return getPulseDate(item);
    }

    sendPulse(item: Process | Subprocess): void {
        item.type === ObjectType.Subprocess
          ? this.sendPulseForSubProcess.emit(item)
          : this.sendPulseForProcess.emit(item);
    }

    getOwners(objectId: string): Ownership[] {
        const owners = (this.ownership || []).filter((user) => {
            return user.object_id === objectId && user.role === UserRole.Owner;
        });
        return uniqBy(owners, 'id');
    }

    getTypeForItem(item: Process | Subprocess): string {
        const type = item.type;
        return `../../${objectTypeSet[type].type}`;
    }

    getTextForItem(item: Process | Subprocess): string {
        const type = item.type;
        return objectTypeSet[type].text;
    }

    getAllTableData(): (Process | Subprocess)[] {
        const allTableData = [];
        (this.processes || []).forEach((process) => {
            allTableData.push(process);

            const subprocesses = (this.subprocesses || []).filter((item) => item.process_id === process.id);
            subprocesses.forEach((subprocess) => {
                allTableData.push(subprocess);
            });
        });
        return allTableData;
    }

    getTableData(): Process[] | {subprocesses: Subprocess[]}[] {
        switch (this.viewType) {
            case 'process':
                return this.getSubprocesses(this.processId, true);
            case 'module':
                return this.getProcessesWithSubprocesses();
            default:
                return [];
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        this.tableData$.next(this.getTableData());
    }

    private getProcessesWithSubprocesses(): Process[] {
        const processes = this.processes.filter(item => item.module_id === this.moduleId) || [];
        let subprocessesCount = 0;
        return processes.reduce((acc, data) => {
            const subprocessesData = this.getSubprocessesData(data.id);

            const isGlobal = this.isProcessGlobal(data.id);
            subprocessesCount = subprocessesCount + subprocessesData.length;
            this.subprocessCount$.next(subprocessesCount);
            acc.push({
                ...data,
                subprocesses: subprocessesData,
                global: isGlobal
            });

            return acc;
        }, []);
    }

    private getSubprocesses(processId: string, updateCount: boolean = false): {subprocesses: Subprocess[]}[] {
        const subprocesses = this.subprocesses;
        if (updateCount) {
            this.subprocessCount$.next(subprocesses.length);
        }
        return [{ subprocesses }];
    }

    private isProcessGlobal(processId: string): boolean {
        let returnValue = false;
        const subprocesses = this.getSubprocessesData(processId);
        const numberOfSubprocesses = subprocesses.length;
        const numberOfglobalSubprocesses = subprocesses.filter((subprocess) => subprocess.global).length;
        if (numberOfglobalSubprocesses !== 0 && numberOfglobalSubprocesses === numberOfSubprocesses) {
            returnValue = true;
        }
        return returnValue;
    }

    private getSubprocessesData(processId: string): Subprocess[] {
        return (this.subprocesses || []).filter(item => item.process_id === processId) || [];
    }
}
