import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, filter } from 'rxjs/operators';
import { of } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { DemographicsFeatureService } from '../services/demographics.service';
import * as demographicsActions from '../actions';

@UntilDestroy()
@Injectable()
export class DemographicsEffects implements OnDestroy {

    public getDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(demographicsActions.getDemographics),
            filter((action) => !this.getDemographicsMap[action.demographicsClassKey]),
            mergeMap((action) => {
                this.getDemographicsMap[action.demographicsClassKey] = true;

                return this.demographicsFeatureService
                    .getDemographics(action.demographicsClassKey)
                    .pipe(
                        map(demographics => demographicsActions.getDemographicsSuccess({
                            demographicsClassKey: action.demographicsClassKey,
                            demographics
                        })),
                        catchError((error) => of(demographicsActions.getDemographicsFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    public getSelectedDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(demographicsActions.getSelectedDemographics),
            filter((action) => !this.getSelectedDemographicsMap[`${action.userId}_${action.demographicsClassKey}`]),
            mergeMap((action) => {
                this.getSelectedDemographicsMap[`${action.userId}_${action.demographicsClassKey}`] = true;

                return this.demographicsFeatureService
                    .getSelectedDemographicsForUser(action.userId, action.demographicsClassKey)
                    .pipe(
                        map(selectedDemographics => demographicsActions.getSelectedDemographicsSuccess(
                            {
                                userId: action.userId,
                                demographicsClassKey: action.demographicsClassKey,
                                selectedDemographics: selectedDemographics
                            }
                        )),
                        catchError((error) => of(demographicsActions.getSelectedDemographicsFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    public getActiveFormsDemographicsKeys$ = createEffect(() =>
        this.actions$.pipe(
            ofType(demographicsActions.getActiveFormDemographicKeys),
            filter((action) => !this.getActiveFormsDemographicsKeysMap[`${action.userId}_${action.formKey}_${action.demographicsClassKey}`]),
            mergeMap((action) => {
                this.getActiveFormsDemographicsKeysMap[`${action.userId}_${action.formKey}_${action.demographicsClassKey}`] = true;

                return this.demographicsFeatureService
                    .getFormActiveDemographicsKeys(action.userId, action.formKey, action.demographicsClassKey)
                    .pipe(
                        map(keys => demographicsActions.getActiveFormDemographicKeysSuccess(
                            {
                                userId: action.userId,
                                demographicsClassKey: action.demographicsClassKey,
                                keys: keys,
                                formKey: action.formKey
                            }
                        )),
                        catchError((error) => of(demographicsActions.getActiveFormDemographicKeysFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    public getFormDemographicsClassId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(demographicsActions.getFormDemographicsClassId),
            filter((action) => !this.getFormDemographicsClassIdMap[action.formKey]),
            mergeMap(({ formKey }) => {
                this.getFormDemographicsClassIdMap[formKey] = true;

                return this.demographicsFeatureService
                    .getFormDemographicsClassId(formKey)
                    .pipe(
                        map(demographicClassId => demographicsActions.getFormDemographicsClassIdSuccess({
                            formKey,
                            demographicClassId
                        })),
                        catchError((error) => of(demographicsActions.getFormDemographicsClassIdFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    public getParentFormActiveDemographics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(demographicsActions.getParentFormActiveDemographics),
            filter((action) => !this.getParentFormActiveDemographicsMap[action.formKey]),
            mergeMap(({ formKey }) => {
                this.getParentFormActiveDemographicsMap[formKey] = true;

                return this.demographicsFeatureService
                    .getParentFormActiveDemographics(formKey)
                    .pipe(
                        map(activeDemographics => demographicsActions.getParentFormActiveDemographicsSuccess({
                            formKey,
                            activeDemographics
                        })),
                        catchError((error) => of(demographicsActions.getParentFormActiveDemographicsFail({ errorMessage: error }))),
                        untilDestroyed(this)
                    );
            })
        ));

    // This objects are used to store data that already created a subscription.
    // After check parameters if they already added we will not create new subscription.
    private getDemographicsMap = {};
    private getSelectedDemographicsMap = {};
    private getActiveFormsDemographicsKeysMap = {};
    private getFormDemographicsClassIdMap = {};
    private getParentFormActiveDemographicsMap = {};

    constructor(
        private actions$: Actions,
        private demographicsFeatureService: DemographicsFeatureService
    ) { }

    ngOnDestroy(): void { }
}
