import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { confirmationRestoreResponseWithBranhingModalText } from '@thinktank/common-lib';
import { FormBranchingVisibility, FormQuestion } from '@app/core/models/forms.model';
import { AuthenticatedUserService } from '@app/core/services';

@Injectable({
    providedIn: 'root',
})
export class FormBranchingService {
    constructor(
        private db: AngularFireDatabase,
        private _angularFireFunctions: AngularFireFunctions,
        private sanitizer: DomSanitizer,
        private authenticatedUserService: AuthenticatedUserService,
    ) {}

    getFormQuestionsVisibility(activityKey: string): Observable<{ [questionKey: string]: FormBranchingVisibility }> {
        return this.db
            .object<{ [questionKey: string]: FormBranchingVisibility }>(`ssot/formBranching/${activityKey}`)
            .valueChanges();
    }

    getQuestionVisibility(formKey: string, questionKey: string): Observable<any> {
        return this.db.object(`ssot/formBranching/${formKey}/${questionKey}`).valueChanges();
    }

    buildBranchingDependencies(questions: FormQuestion[]): { [questionKey: string]: FormQuestion[] } {
        return (questions || []).reduce((dependencies, question) => {
            if (question.form_branching) {
                const parentQuestionKey = question.form_branching.question_id;

                if (dependencies[parentQuestionKey]) {
                    dependencies[parentQuestionKey].push(question);
                } else {
                    dependencies[parentQuestionKey] = [question];
                }
            }

            return dependencies;
        }, {});
    }

    async getTextWithCountOfAffectedQuestions(
        activityId: string,
        questionId: string,
        baseResponseValue: any,
        branchingDependencies: { [questionKey: string]: FormQuestion[] },
        demographicId?: string,
        parentDemographicId?: string,
    ): Promise<SafeHtml> {
        try {
            const userId = this.authenticatedUserService.getCurrentUserId();
            const branchedQuestions = await this._angularFireFunctions
                .httpsCallable('calculateBranchingAffectedQuestions')({
                    activityId,
                    baseQuestionId: questionId,
                    branchingDependencies,
                    baseResponseValue,
                    demographicId,
                    parentDemographicId,
                    userId,
                })
                .pipe(take(1))
                .toPromise();

            return this.sanitizer.bypassSecurityTrustHtml(
                confirmationRestoreResponseWithBranhingModalText(branchedQuestions),
            );
        } catch (error) {
            console.log('Error while calculateBranchingAffectedQuestions');
            console.error(error);

            return this.sanitizer.bypassSecurityTrustHtml('');
        }
    }
}
