import {
    Input,
    Output,
    OnChanges,
    ViewChild,
    Component,
    ElementRef,
    EventEmitter,
    ChangeDetectionStrategy,
    SimpleChanges,
    Inject
} from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';

import { CreateDeploymentFacade, CreateDeploymentFacadeToken } from './create-deployment.facade';

import {
    Module,
    Deployment,
    UserRole,
    OverlayTemplate
} from '../../models';
import { Demographic, trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-create-deployment-container',
    styleUrls: ['./create-deployment-container.component.scss'],
    templateUrl: './create-deployment-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreateDeploymentContainerComponent implements OnChanges {
    vm$ = this.facade.vm$;

    @Input() deploymentLoading: boolean;
    @Input() deploymentReady: boolean;
    @Output() closeCreateDeployment: EventEmitter<boolean> = new EventEmitter();
    @ViewChild('clientNameInput', { static: true }) clientNameInput: ElementRef;
    @ViewChild('search', { static: true }) search: ElementRef;

    activeView: string;
    currentSlideIndex: number;

    modules$: Observable<Module[]>;

    selectedDeployment: Deployment;

    selectedModules: Module[];
    selectedModule: Module;
    selectedDemographics$ = new BehaviorSubject<Demographic[]>(null);

    loading$ = new BehaviorSubject<boolean>(false);

    deploymentRoster: string[];
    clientName: string;
    searchValue: string;
    expandedModulesObject: { [index: number]: boolean };
    emailList: { email: string }[];
    allModulesCheck: boolean;

    selectedOverlayTemplateId: string;
    deploymentCopyId: string;

    createDeploymentPages = [
        'deployments',
        'deployment_description',
        'client_name',
        'modules',
        // 'overlay_template',
        'demographics',
        'deployment_view',
        'participant_invite',
        'congrats_screen'
    ];
    trackById = trackById;

    constructor(
        @Inject(CreateDeploymentFacadeToken) private facade: CreateDeploymentFacade
    ) {
        this.resetComponentState();
    }

    onCloseCreateDeployment(): void {
        this.resetComponentState();
        this.closeCreateDeployment.emit();
    }

    viewOverlayTemplate(overlayTemplateId: string): void {
        this.facade.viewOverlayTemplate(overlayTemplateId);
    }

    editOverlayTemplate(overlayTemplateId?: string): void {
        this.facade.createEditOverlayTemplate(this.selectedDeployment.id, overlayTemplateId);
    }

    getOverlayTemplatesForCoreTemplate(overlayTemplateArray: OverlayTemplate[]): OverlayTemplate[] {
        if (!this.selectedDeployment) {
            return overlayTemplateArray;
        }
        return overlayTemplateArray.filter((template) => template.core_template_id === this.selectedDeployment.id);
    }

    deleteOverlayTemplate(overlayTemplateId: string): void {
        this.facade.deleteOverlayTemplate(overlayTemplateId);
    }

    get isEmptyClientName(): boolean {
        return !this.clientName || this.clientName.trim() === '';
    }

    get hideSteps(): boolean {
        return this.isLastPage || this.activeView === 'participant_invite';
    }

    get isLastPage(): boolean {
        return (this.currentSlideIndex + 1) === this.createDeploymentPages.length;
    }

    clearSearch(): void {
        this.searchValue = '';
    }

    updateClientName(value: string): void {
        this.clientName = value;
    }

    goToPrevSlide(): void {
        this.currentSlideIndex -= 1;
        this.activeView = this.createDeploymentPages[this.currentSlideIndex];
    }

    goToNextSlide(): void {
        this.currentSlideIndex += 1;
        this.activeView = this.createDeploymentPages[this.currentSlideIndex];
    }

    goToCongratsSlide(): void {
        this.currentSlideIndex = this.createDeploymentPages.length - 1;
        this.activeView = 'congrats_screen';
    }

    setSelectedDeployment(deployment: Deployment): void {
        this.selectedDeployment = deployment;
    }

    onSelectClientName(): void {
        this.modules$ = of(this.selectedDeployment.modules);
        this.goToNextSlide();
    }

    getModuleName(indexOfSelectedModule: number): string {
        return !!this.selectedModules[indexOfSelectedModule] && this.selectedModules[indexOfSelectedModule].name || '';
    }

    onSelectionChange(module: Module, modules: Module[]): void {
        this.allModulesCheck = false;
        if (this.isModuleChecked(module)) {
            const index = this.selectedModules.findIndex((selectedModule) => selectedModule.id === module.id);
            this.selectedModules.splice(index, 1);
            return;
        }

        this.selectedModules.push(module);
        this.allModulesCheck = this.selectedModules.length === modules.length;
    }

    isModuleChecked(value: Module): boolean {
        return !!this.selectedModules && this.selectedModules.includes(value);
    }

    selectAllModules(modules: Module[]): void {
        this.allModulesCheck = !this.allModulesCheck;

        if (this.allModulesCheck) {
            modules.forEach(demographic => {
                this.selectedModules.push(demographic);
            });
        } else {
            this.selectedModules = [];
        }
    }

    expandModule(index: number): void {
        this.expandedModulesObject[index] = !this.expandedModulesObject[index];
    }

    onRosterUpdate(data: {emailList: {email: string}[], message?: string}): void {
        this.emailList = data.emailList;
    }

    async addUsers(userEmailList: any[], deploymentId: string): Promise<void> {
        this.deploymentRoster = await Promise.all(
            userEmailList.map((userEmail) => this.facade.addUser(userEmail.email, deploymentId, UserRole.Collaborator)));
    }

    selectDemographic(data: {isChecked: boolean, demographic: Demographic}): void {
        const { isChecked, demographic } = data;

        if (!isChecked) {
            this.removeActiveDemographic(demographic);
            return;
        }

        const selectedDemographics = this.selectedDemographics$.getValue();
        this.selectedDemographics$.next([...selectedDemographics, demographic]);
    }

    selectGroupDemographics(event: {isChecked: boolean, demographics: Demographic[]}): void {
        const { isChecked, demographics } = event;
        if (isChecked) {
            demographics.forEach(demographic => {
                if (!this.selectedDemographics$.getValue().some(item => item.id === demographic.id)) {
                    const selectedDemographics = this.selectedDemographics$.getValue();
                    this.selectedDemographics$.next([...selectedDemographics, demographic]);
                }
            });
        } else {
            demographics.forEach(demographic => {
                const selectedDemographics = this.selectedDemographics$.getValue().filter(item => item.id !== demographic.id);
                this.selectedDemographics$.next(selectedDemographics);
            });
        }
    }

    removeActiveDemographic(demographic: Demographic): void {
        const selectedDemographics = this.selectedDemographics$.getValue().filter(item => item.id !== demographic.id);
        this.selectedDemographics$.next(selectedDemographics);
    }

    async createDeployment(currentUserId: string, invite?: boolean): Promise<void> {
        this.loading$.next(true);

        const selectedDemographics = this.selectedDemographics$.getValue();
        // start to create deployment
        this.deploymentCopyId = await this.facade.createDeployment(
            this.clientName,
            this.selectedDeployment,
            this.selectedModules,
            this.deploymentRoster,
            selectedDemographics,
            this.selectedOverlayTemplateId,
            currentUserId
        );

        if (invite && !!this.emailList) {
            await this.addUsers(this.emailList, this.deploymentCopyId);
        }
    }

    selectOverlayTemplate(overlayTemplateId: string): void {
        this.selectedOverlayTemplateId = overlayTemplateId;
    }

    createNewDeployment(): void {
        this.resetComponentState();
    }

    calculateColorIndex(cardIndex: number, deploymentTemplateLength: number): number {
        const numberOfRows = Math.ceil(deploymentTemplateLength / 6);
        let count;
        cardIndex += 1;

        for (let n = 1; n <= numberOfRows; n++) {
            if ((cardIndex / (6 * n)) > 1) {
                continue;
            }
            count = cardIndex - ((6 * (n - 1)));
        }
        return count;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.deploymentReady && this.deploymentReady && !!this.loading$.getValue()) {
            this.loading$.next(false);
            this.goToCongratsSlide();
        }
    }

    private resetComponentState(): void {
        this.activeView = this.createDeploymentPages[0];
        this.selectedDeployment = null;
        this.clientName = null;
        this.selectedModules = [];
        this.deploymentRoster = [];
        this.currentSlideIndex = 0;
        this.expandedModulesObject = {};
        this.selectedDemographics$.next([]);
        this.allModulesCheck = false;
        this.selectedOverlayTemplateId = null;
        this.loading$.next(false);
    }
}
