import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';

import { AuthenticatedUserService } from './authenticated-user.service';
import { httpsCallableOptions } from '../constants';

@Injectable()
export class DuplicateDataService {
    constructor(private afFun: AngularFireFunctions, private authService: AuthenticatedUserService) {}

    sessionToApp(sessionKey: string): Promise<any> {
        const userId = this.authService.getCurrentUserId();
        return this.afFun
            .httpsCallable(
                'duplicateData',
                httpsCallableOptions,
            )({
                sourceType: 'sessionToApp',
                sourceId: sessionKey,
                userId,
            })
            .toPromise()
            .then(
                res => res,
                err => {
                    // res.data === newAppKey
                    console.log('Error with session to app!');
                    console.error({ err });
                },
            );
    }

    appToSession(appKey: string): Promise<any> {
        const userId = this.authService.getCurrentUserId();
        return this.afFun
            .httpsCallable(
                'duplicateData',
                httpsCallableOptions,
            )({
                sourceType: 'appToSession',
                sourceId: appKey,
                userId,
            })
            .toPromise()
            .then(
                res => res,
                err => {
                    // res.data === newSessionKey
                    console.log('Error with app to session!');
                    console.error({ err });
                },
            );
    }

    appAcrossEnviroments(appKey: string, config: any): Promise<any> {
        const userId = this.authService.getCurrentUserId();
        const userEmail = this.authService.getCurrentUser().email;

        return this.afFun
            .httpsCallable(
                'duplicateData',
                httpsCallableOptions,
            )({
                sourceType: 'appAcrossEnviroments',
                sourceId: appKey,
                config,
                userId,
                userEmail,
            })
            .toPromise()
            .then(
                res => res,
                err => {
                    // res.data === newSessionKey
                    console.log('Error with copy app across enviroments!');
                    console.error({ err });
                },
            );
    }

    // NOTES:
    // 1. appIdToCopyTo can point to an app or a session
    // 2. appIdToCopyTo may point to the same app/session the activity to copy is located in
    // 3. appIdToCopyTo can be empty. Then the cloud function will create a new app/session with a copy of that activity (NOT YET CODED)
    duplicateActivity(activityIdToCopy: string, appIdToCopyTo: string, isApp: boolean): Promise<any> {
        const userId = this.authService.getCurrentUserId();
        const destinationType = isApp ? 'app' : 'session';
        // so app and session are using the same code and everything currently in the CF.
        // this discriminator will be of use when we want to create a new session/app from an activity (appIdToCopyTo would be null/empty)

        return this.afFun
            .httpsCallable(
                'duplicateData',
                httpsCallableOptions,
            )({
                sourceType: 'activity',
                sourceId: activityIdToCopy,
                destinationType: destinationType,
                destinationId: appIdToCopyTo,
                userId,
            })
            .toPromise()
            .then(
                res => res,
                err => {
                    // res.data === newActivityKey
                    console.log('Error duplicating activity within app/session!');
                    console.error({ err });
                },
            );
    }

    duplicateQuestion(appIdToCopyTo: string, activityIdToCopy: string, questionKey: string): Promise<any> {
        const userId = this.authService.getCurrentUserId();

        return this.afFun
            .httpsCallable(
                'duplicateData',
                httpsCallableOptions,
            )({
                sourceType: 'question',
                sourceId: activityIdToCopy,
                destinationId: appIdToCopyTo,
                sourceQuestionId: questionKey,
                userId,
            })
            .toPromise()
            .then(
                res => res,
                err => {
                    console.log('Error while duplicating question!');
                    console.error({ err });
                },
            );
    }
}
