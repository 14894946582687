export class Workbook {
    key?: string;
    name: string;
    owner_id: string;
    owner_name: string;
    description: string;
    download_url: string;
    date_created: number;
    spreadsheet_id?: string;
    previous_workbook_id?: string;
    app_ids?: {
        [sessionKey: string]: true;
    };

    constructor(name: string, url: string, ownerId: string, ownerFullName: string) {
        this.name = name;
        this.description = '';
        this.download_url = url;
        this.date_created = +new Date();
        this.owner_id = ownerId;
        this.owner_name = ownerFullName;
    }
}

export class WorkbookSheet {
    name: string;
    sequence: number;
    workbook_id: string;
    sheet_id: number;
    key?: string;
}

export class OverlappedQuestion {
    id: string;
    number: number;
    name: string;
    mapping: string;
    type: 'option' | 'question';
}

export class OverlappedFormActivity {
    formName: string;
    number: number;
    questions: OverlappedQuestion[];
    demographicsMapping?: string;
}

export class OverlappedQuestionsByActivity {
    [activityId: string]: OverlappedFormActivity;
}

export class OverlappingData {
    appId: string;
    workBookId: string;
    workBookSheetId: string;
    rectangleId: string;
    currentQuestionMapping: string;
    questionId?: string;
}

export class OverlappedQuestionsResponse {
    workbookName: string;
    workbookSheetName: string;
    overlappedQuestions: OverlappedQuestionsByActivity;
}

export const testWorkbook = <Workbook>{
    description: 'Test',
    name: 'Test',
    download_url: '',
};

export const testWorkbookSheet = <WorkbookSheet>{
    name: 'Test sheet',
    sequence: 1,
    workbook_id: 'workbook key',
    sheet_id: 10,
};

export const testOverlappedData = {
    appId: 'appId',
    workBookId: 'workbookId',
    workBookSheetId: 'workBookSheetId',
    questionId: 'questionId',
    rectangleId: 'questionId',
    currentQuestionMapping: 'a1',
};

export const testOverlappedQuestionsResponse = <OverlappedQuestionsResponse>{
    workbookName: 'workbookName',
    workbookSheetName: 'workbookSheetName',
    overlappedQuestions: {
        ['activityKey1']: {
            formName: 'formName1',
            number: 1,
            questions: [
                {
                    id: 'questionKey1',
                    number: 1,
                    name: 'questionName1',
                    mapping: 'A1',
                    type: 'question',
                },
                {
                    id: 'questionKey2',
                    number: 2,
                    name: 'questionName2',
                    mapping: 'A2',
                    type: 'question',
                },
                {
                    id: 'optionKey1',
                    number: 3,
                    name: 'questionName1 - optionName1',
                    mapping: 'A3',
                    type: 'option',
                },
            ],
        },
        ['activityKey2']: {
            formName: 'formName2',
            number: 2,
            questions: [
                {
                    id: 'questionKey1',
                    number: 1,
                    name: 'questionName1',
                    mapping: 'A1',
                    type: 'question',
                },
                {
                    id: 'questionKey2',
                    number: 2,
                    name: 'questionName2',
                    mapping: 'A2',
                    type: 'question',
                },
                {
                    id: 'optionKey1',
                    number: 3,
                    name: 'questionKey3 - optionName1',
                    mapping: 'A3',
                    type: 'option',
                },
            ],
        },
    },
};
