import { Component, OnChanges, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { skipWhile, take, tap } from 'rxjs/operators';

import { TaskService } from '@app/core/services';
import { TasksListService } from '@app/shared/tasks-list/tasks-list.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { AnsweredQuestionModel } from '@app/core/models';
import { EngageFormStatus, Gate, Task, TaskType } from '@thinktank/common-lib';
import { FormDemographicsService } from '@app/apps/activities/forms/form-services';

@Component({
    selector: 'app-tasks-list-container',
    templateUrl: './tasks-list-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TasksListContainerComponent implements OnChanges {
    @Input() isApp: boolean;
    @Input() projectKey: string;
    @Input() emulate?: boolean;
    @Input() isHome?: boolean;

    tasksAndGates$: Observable<any>;
    resetEmulation$: Observable<any>;
    answeredQuestions$: Observable<{[activityId: string]: AnsweredQuestionModel}>;
    demographicStatusMapByTaskKey$ = new BehaviorSubject<{[taskKey: string]: { name: string, status: EngageFormStatus }[]}>(null);
    isLeader: boolean;

    constructor(
        private taskService: TaskService,
        private tasksListService: TasksListService,
        private authService: AuthenticatedUserService,
        private formDemographicsService: FormDemographicsService
    ) { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.projectKey && changes.projectKey.currentValue) {
            this.isLeader = this.authService.isUserLeader(this.isApp ? 'apps' : 'app_instances', this.projectKey);
            this.tasksAndGates$ = this.taskService.getTasksAndGates(this.projectKey)
                .pipe(tap((tasksAndGates: (Task | Gate)[]) => {
                    const demographicStatus = this.filterDemographicsByUser(tasksAndGates);


                    setTimeout(() => {
                        if (!!Object.keys(demographicStatus).length) {
                            this.demographicStatusMapByTaskKey$.next(demographicStatus);
                        }
                    }, 500);
                }));
            this.resetEmulation$ = this.tasksListService.getResetEmulation();
            this.answeredQuestions$ = this.taskService.getAnsweredQuestions(this.projectKey);
        }
    }

    private filterDemographicsByUser(
        tasksAndGates: (Task | Gate)[]
    ): {[taskKey: string]: { name: string, status: EngageFormStatus }[]} {
        const isUserParticipant = this.authService.isUserParticipant(this.projectKey);
        const demographicStatus = {};
        tasksAndGates.forEach(async(data) => {
            if (data.type === TaskType.Forms && (data as Task).demographic_status) {
                if (isUserParticipant) {
                    const filteredDemographics = {};
                    const demographicKeys = await this.formDemographicsService
                        .getSelectedDemographicsByActivityId(data.activity_id).pipe(
                            skipWhile(keys => !keys.length),
                            take(1)
                        ).toPromise();
                    demographicKeys.forEach((demographicKey) => {
                        if (data.demographic_status[demographicKey]) {
                            filteredDemographics[demographicKey] = data.demographic_status[demographicKey];
                        }
                    });
                    data.demographic_status = filteredDemographics;
                }
                demographicStatus[data.key] = Object.values((data as Task).demographic_status);
            }
        });

        return demographicStatus;
    }
}
