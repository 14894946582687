import { ObjectType } from './deployment.model';
import { Process } from './process.model';
import { User } from './user.model';
import { TaskItem } from './task-list-item.model';
import { Demographic } from '@thinktank/common-lib';
import { DeploymentItem } from './deployment-item';

export class Module extends DeploymentItem {
    description?: string;
    tasks?: TaskItem[];
    processes?: Process[];
    owners?: User[];
    active?: boolean;
    demographics?: Demographic[];
    type = ObjectType.Module;

    constructor(module: Module) {
        super(module);
        this.description = module.description || '';
        this.tasks = module.tasks || [];
        this.owners = module.owners || [];
        this.active = !!module.active;
        this.demographics = module.demographics || [];
    }
}

export const testModule = {
    id: 'test_module',
    sequence: 1,
    name: 'Test Module',
    deployment_id: 'test_deployment',
    active: true,
    type: ObjectType.Module
};
