import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';

import { userRole } from '@app/core/models';
import { FirebaseUsersService } from '@app/core/services';
import { LoginService } from '@app/login/services/login.service';

/* eslint-disable */
const EMAIL_REGEX =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/* eslint-enable */

@UntilDestroy()
@Component({
    selector: 'app-session-join-link',
    templateUrl: './session-join-link.component.html',
    styleUrls: ['./session-join-link.component.scss'],
})
export class SessionJoinLinkComponent implements OnInit, OnDestroy {
    loginForm: FormGroup;
    loginFormSubmitted = false;

    appKey: string;
    emailRequired: boolean;
    hasPasskey: boolean;

    inputPasskeyValid: boolean;
    inputPasskeyChecked: boolean;
    tooManyLoginFailures: boolean;

    initialized: boolean;

    existingGuestId: string;
    existingGuestEmail: boolean;

    errorMessage: string;
    pending: boolean;

    guestInstances: { [key: string]: userRole };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private loginService: LoginService,
        private usersService: FirebaseUsersService,
    ) {
        this.inputPasskeyValid = false;
        this.inputPasskeyChecked = false;
        this.tooManyLoginFailures = false;
        this.initialized = false;
        this.loginForm = new FormGroup({
            password: new FormControl('', [Validators.required]),
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required, Validators.pattern(EMAIL_REGEX)]),
        });
    }

    get isPasswordInvalid(): boolean {
        return this.inputPasskeyChecked && !this.inputPasskeyValid;
    }

    get disableButtons(): boolean {
        return this.loginForm.invalid;
    }

    get isPasswordControlInvalid(): boolean {
        return this.isControlInvalid('password');
    }

    get isFirstNameInvalid(): boolean {
        return this.isControlInvalid('firstName');
    }

    get isLastNameInvalid(): boolean {
        return this.isControlInvalid('lastName');
    }

    get isEmailInvalid(): boolean {
        return this.isControlInvalid('email');
    }

    get isGuestLoginFormInvalid(): boolean {
        return this.isFirstNameInvalid || this.isLastNameInvalid || (this.emailRequired && this.isEmailInvalid);
    }

    isControlValueEmpty(controlName: string): boolean {
        const value = this.loginForm.get(controlName).value;

        return !(value || '').trim().length;
    }

    isControlInvalid(controlName: string): boolean {
        const control = this.loginForm.get(controlName);

        return (
            (this.isControlValueEmpty(controlName) || control.invalid) && (control.touched || this.loginFormSubmitted)
        );
    }

    getControlValue(controlName: string): any {
        return this.loginForm.get(controlName).value;
    }

    signInAsGuest(): void {
        this.loginFormSubmitted = true;
        if (this.isGuestLoginFormInvalid && !this.existingGuestId) {
            return;
        }

        this.pending = true;
        if (this.emailRequired && !this.existingGuestEmail) {
            this.existingGuestId = null;
        }
        this.loginService.signInGuestUser(
            this.existingGuestId,
            this.appKey,
            this.getControlValue('firstName'),
            this.getControlValue('lastName'),
            this.emailRequired ? this.getControlValue('email') : null,
        );
    }

    onPasswordChange(): void {
        this.inputPasskeyChecked = false;
    }

    async validatePassword(): Promise<void> {
        this.loginFormSubmitted = true;
        if (this.isPasswordControlInvalid) {
            return;
        }

        try {
            this.pending = true;
            const passcode = this.getControlValue('password');
            const isValid = await this.loginService.validatePasscode(this.appKey, passcode);

            if (
                isValid &&
                this.existingGuestId &&
                ((this.emailRequired && this.existingGuestEmail) || !this.emailRequired)
            ) {
                this.signInAsGuest();
            } else {
                this.pending = false;
                this.inputPasskeyChecked = true;
                this.inputPasskeyValid = isValid;
                this.tooManyLoginFailures = false;
                this.loginFormSubmitted = false;
            }
        } catch (e) {
            this.pending = false;
            this.inputPasskeyChecked = true;
            this.inputPasskeyValid = false;

            this.errorMessage = e.message;
            this.tooManyLoginFailures = true;
            this.loginFormSubmitted = false;
        }
    }

    async ngOnInit() {
        this.loginService
            .getGuestSubject()
            .pipe(untilDestroyed(this))
            .subscribe(guestUserKey => {
                // route to app now that we have added ourselves to the roster
                localStorage.setItem('tt_guest_user', guestUserKey);
                localStorage.removeItem('app_instance_id');
                this.router.navigate(['/instance', this.appKey]);
            });

        this.existingGuestId = localStorage.getItem('tt_guest_user');
        if (this.existingGuestId) {
            const userData = await lastValueFrom(this.usersService.getUserInfo(this.existingGuestId));

            this.existingGuestEmail = userData.email.includes('@');
            this.guestInstances = userData.app_instances;
        }

        const appPasskeyGuid = (await this.route.params.pipe(take(1)).toPromise()).appPasskeyGuid;

        if (appPasskeyGuid) {
            try {
                const appConfig = await this.loginService.findAppByPasskeyGUID(appPasskeyGuid);

                this.appKey = appConfig.key;
                if (this.appKey) {
                    // will be used if user login with already existing user
                    localStorage.setItem('app_instance_id', this.appKey);
                }

                if (this.guestInstances?.[this.appKey]) {
                    localStorage.removeItem('app_instance_id');
                    this.router.navigate(['/instance', this.appKey]);
                } else {
                    this.initialized = true;

                    this.emailRequired = appConfig.email_required;

                    // to disable validation for this control
                    if (!this.emailRequired) {
                        this.loginForm.get('email').disable();
                    }
                    this.hasPasskey = !appConfig.is_passkey_empty && !!appConfig.use_passkey;
                }
            } catch (e) {
                this.initialized = true;
                this.errorMessage = e.message;
            }
        } else {
            this.initialized = true;
        }
    }

    ngOnDestroy() {}
}
