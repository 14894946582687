import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-disagree-dialog',
    templateUrl: './disagree-dialog.component.html',
    styleUrls: ['./disagree-dialog.component.scss']
})
export class DisagreeDialogComponent implements OnInit {

    disagreeCommentControl: FormControl;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<DisagreeDialogComponent>
    ) { }

    handleConfirm(): void {
        this.data.handleConfirm(this.disagreeCommentControl.value);
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.disagreeCommentControl = new FormControl('', [
            Validators.required
        ]);
    }

}
