import { Injectable, Injector } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { filter, take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import { ImageLightboxServiceInterface } from '@thinktank/common-lib';

import { ImageLightboxComponent } from './image-lightbox/image-lightbox.component';
import { SlideLightboxComponent } from './slide-lightbox/slide-lightbox.component';
import { ImageLightboxRef } from './image-lightbox-ref';
import { IMAGE_LIGHTBOX_DATA, ImageLightboxConfig, IMAGE_LIGHTBOX_CAPTION } from './image-lightbox.model';

const DEFAULT_CONFIG: ImageLightboxConfig = {
    hasBackdrop: true,
    backdropClass: 'cdk-overlay-dark-backdrop'
};

const componentsMap = {
    ImageLightboxComponent,
    SlideLightboxComponent
};

@Injectable()
export class ImageLightboxService implements ImageLightboxServiceInterface {
    private closeTriggers = ['Esc', 'Escape'];

    constructor(
        private injector: Injector,
        private overlay: Overlay
    ) { }

    open(image: string, componentName = 'ImageLightboxComponent', caption?: string): ImageLightboxRef {

        // Returns an OverlayRef which is a PortalHost
        const overlayRef = this.createOverlay(DEFAULT_CONFIG);

        // Instantiate remote control
        const dialogRef = new ImageLightboxRef(overlayRef);

        const component = componentsMap[componentName];
        this.attachDialogContainer(overlayRef, image, caption, dialogRef, component);

        overlayRef.backdropClick()
            .pipe(take(1))
            .subscribe(() => dialogRef.close());

        overlayRef.keydownEvents()
            .pipe(
                filter((event) => this.closeTriggers.includes(event.key)),
                take(1)
            )
            .subscribe(() => dialogRef.close());

        return dialogRef;
    }

    openWithSpinner(image$: Observable<string>, componentName = 'ImageLightboxComponent', caption?: string): ImageLightboxRef {
        const close$ = new Subject<void>();

        // Returns an OverlayRef which is a PortalHost
        const overlayRef = this.createOverlay(DEFAULT_CONFIG);

        // Instantiate remote control
        const dialogRef = new ImageLightboxRef(overlayRef);

        const component = componentsMap[componentName];
        image$
            .pipe(takeUntil(close$))
            .subscribe((image) => {
                this.attachDialogContainer(overlayRef, image || '', caption, dialogRef, component);
            });

        overlayRef.backdropClick()
            .pipe(take(1))
            .subscribe(() => dialogRef.close());

        overlayRef.keydownEvents()
            .pipe(
                filter((event) => this.closeTriggers.includes(event.key)),
                take(1)
            )
            .subscribe(() => {
                dialogRef.close();
                close$.next();
                close$.complete();
            });

        return dialogRef;
    }

    private createOverlay(config: ImageLightboxConfig) {
        const overlayConfig = this.getOverlayConfig(config);
        return this.overlay.create(overlayConfig);
    }

    private attachDialogContainer(overlayRef: OverlayRef, image: string, caption: string, dialogRef: ImageLightboxRef, component: any) {
        const injector = this.createInjector(image, caption, dialogRef);

        const containerPortal = new ComponentPortal(component, null, injector);
        overlayRef.detach();
        overlayRef.attach(containerPortal);
    }

    private createInjector(image: string, caption: string, dialogRef: ImageLightboxRef): PortalInjector {
        const injectionTokens = new WeakMap();

        injectionTokens.set(ImageLightboxRef, dialogRef);
        injectionTokens.set(IMAGE_LIGHTBOX_DATA, image);
        injectionTokens.set(IMAGE_LIGHTBOX_CAPTION, caption);

        return new PortalInjector(this.injector, injectionTokens);
    }

    private getOverlayConfig(config: ImageLightboxConfig): OverlayConfig {
        const positionStrategy = this.overlay
            .position()
            .global();

        const overlayConfig = new OverlayConfig({
            hasBackdrop: config.hasBackdrop,
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            scrollStrategy: this.overlay.scrollStrategies.block(),
            positionStrategy
        });

        return overlayConfig;
    }
}
