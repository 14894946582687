<div *ngIf="(vm$ | async) as vm">
  <div *ngIf="!vm.isLoading" class="form-page">
    <div class="form-header">
      <p class="h5">Revising Form - {{ vm.form.name }}</p>
      <p class="subtitle-regular form-module-name">{{ vm.moduleName }}</p>
      <div class="form-chips">
        <span class="gs-status-chip-activity-type">Form</span>
        <span *ngIf="vm.form.global" class="gs-status-chip-global">Global</span>
      </div>
    </div>

    <div class="form-body-wrapper">
      <div class="form-body">
        <form [formGroup]="form">
          Figure out what goes here
          <div class="form-button-container">
            <button
              type="button"
              class="form-button gs-button-filled"
              (click)="onFinish(vm.deploymentId, vm.form.id)"
            >
              Finish Changes
            </button>
          </div>
        </form>
      </div>
    </div>
    <div *ngIf="!!vm.form.items && vm.form.items.length === 0" class="gs-block-empty">
      <gs-empty-screen
        [imageUrl]="'/assets/svg/gs-empty-form.svg'"
        [text]="'There are no questions in this form'"
      ></gs-empty-screen>
    </div>
  </div>
</div>
