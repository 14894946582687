import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';

import { userPasswordValidationText } from '@app/admin/admin.component.constants';
import { patternValidator } from '@app/core/utils';
import { environment } from '@env/environment';
import { AuthenticationService } from '@app/core/services/authentication.service';

@Component({
    selector: 'app-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss'],
})
export class ChangePasswordDialogComponent implements OnInit {
    oldPassword: string;
    newPassword: string;
    errors: string[];
    isSubmitted: boolean;
    oldPasswordControl: FormControl;
    newPasswordControl: FormControl;
    envIndicator: boolean;
    confirmation: boolean;
    errorMessage: string;
    newUserPasswordValidationText = userPasswordValidationText;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
        private authenticationService: AuthenticationService,
    ) {
        this.isSubmitted = false;
        this.errors = [];
        this.envIndicator = !!environment['aws'];
    }

    get submitValidation(): boolean {
        if (this.envIndicator) {
            return this.oldPasswordControl.invalid || this.newPasswordControl.invalid;
        } else {
            return this.newPasswordControl.invalid;
        }
    }

    onChange(): void {
        this.isSubmitted = false;
        this.errors = [];
    }

    checkPasswordValidation(passwordValidator): boolean {
        return (
            this.newPasswordControl.hasError(passwordValidator.errorName) ||
            this.newPasswordControl.hasError('required')
        );
    }

    onSubmit(): void {
        const { handleConfirm } = this.data;

        handleConfirm(this.oldPassword, this.newPassword).subscribe(
            () => {
                this.isSubmitted = true;
            },
            err => {
                this.isSubmitted = true;
                if (err.name === 'NotAuthorizedException') {
                    return (this.errors = ['Incorrect old password']);
                }
                this.errors = err.errors || [err.message];
            },
        );
    }

    initializeForm(): void {
        this.oldPasswordControl = new FormControl('', [Validators.required, Validators.minLength(8)]);
        this.newPasswordControl = new FormControl('', [
            Validators.required,
            Validators.minLength(8),
            // eslint-disable-next-line
            patternValidator(/\d/, { hasNumber: true }),
            // eslint-disable-next-line
            patternValidator(/[A-Z]/, { hasUpperCase: true }),
            // eslint-disable-next-line
            patternValidator(/[a-z]/, { hasLowerCase: true }),
            // eslint-disable-next-line
            patternValidator(/[ -\/:-@\[-\`{-~]/, {
                hasSpecialCharacters: true,
            }),
        ]);
    }

    ngOnInit(): void {
        this.initializeForm();
        this.authenticationService.returnConfirmation().subscribe(status => {
            this.confirmation = status;
        });
        this.authenticationService.errorMessage().subscribe(error => {
            this.errorMessage = error;
        });
    }
}
