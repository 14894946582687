<div class="event-flow-validation-dialog">
  <h3 mat-dialog-title class="h3">Event Flow</h3>
  <mat-dialog-content>
    <p class="text">
      This activity is NOT yet complete.
    </p>
    <p class="text">
      Please check your settings as you do not have the correct data to move based on your <b class="text-bold">Event Flow settings</b>:
    </p>

    <div class="event-flow-errors-data">
      <ul class="list">
        <li
          *ngFor="let error of validationTextData; trackBy: trackByKey"
          class="list-item text"
          [innerHTML]="error"
        ></li>
      </ul>
    </div>
    
    <p class="text">
      Once the settings are in place, hit <b class="text-bold">‘Complete Event Flow’</b> again to complete Event Flow successfully.
    </p>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button cdkFocusInitial class="tt-button-filled" (click)="onSubmit()">
      OK
    </button>
  </mat-dialog-actions>
</div>
