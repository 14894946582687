import { createSelector } from '@ngrx/store';

import { FormQuestionValidationError } from '@app/core/models';
import { AppState } from '@app/root-store/state';
import { FormSummaryState } from '../state';
import { FormSummaryData, PinnedTopSectionQuestionResponse } from '@app/core/models/form-summary.model';

export const formSummaryState = (state: AppState): FormSummaryState => state.formSummary;

export const formSummarySelector = createSelector(
    formSummaryState,
    (state: FormSummaryState): FormSummaryData[] => {
        return state.summary || [];
    }
);

export const formSectionsSummaryByDemographicSelector = createSelector(
    formSummaryState,
    (state: FormSummaryState, props: { demographicKey: string }): FormSummaryData[] => {
        return (state.summary || []).filter((item) => {
            const isSubform = item.response_type === 'subform';
            const isParentOfRepeated = !!item.repeatable_questions_ids;
            const isRepeated = !!item.repeatable_question_id
                && (!!props.demographicKey ? item.repeatedFromQuestion && item.repeatedFromQuestion[props.demographicKey] : true);
            return isSubform && (isParentOfRepeated || isRepeated);
        });
    }
);

export const repeatedSectionsFirstQuestionResponsesSelector = createSelector(
    formSummarySelector,
    (summary: FormSummaryData[], props: any): PinnedTopSectionQuestionResponse[] => {
        return summary
            .filter(row => {
                const hasRepeatedSectionsForSelectedDemographic = props.demographicId
                    ? (props.repeatedQuestionsIds
                        && props.repeatedQuestionsIds[row.key]
                        && props.repeatedQuestionsIds[row.key][props.demographicId])
                    : true;
                const isShortSingleQuestion = row.questions
                    && row.questions[0]
                    && ['short_answer', 'single'].includes(row.questions[0].response_type);

                return (row.key === props.sectionQuestionId
                    || (row.repeatable_question_id === props.sectionQuestionId && hasRepeatedSectionsForSelectedDemographic))
                    && isShortSingleQuestion;
            })
            .map(row => {
                const firstQuestionResponse = row.questions[0].response;
                const response = !!props.demographicId
                    ? firstQuestionResponse[props.demographicId]
                    : firstQuestionResponse;
                return {
                    response,
                    parentQuestionKey: row.key
                };
            });
    }
);
