<div
  class="confirm-dialog"
  [ngClass]="{'indent': !showNote}"
>
  <div mat-dialog-title>
    <h3 mat-dialog-title class="h3 dialog-title">
      {{data.title}}
      <button mat-icon-button mat-dialog-close class="close">
        <mat-icon>close</mat-icon>
      </button>
    </h3>

    <mat-form-field appearance="outline" floatLabel="always" class="search-input">
      <input
        matInput
        type="search"
        placeholder="Search session"
        [(ngModel)]="searchText"
      />
      <mat-icon *ngIf="!searchText" matSuffix>search</mat-icon>
      <mat-icon
        *ngIf="searchText"
        matSuffix
        class="close-icon"
        (click)="searchText = ''"
      >
        close
      </mat-icon>
    </mat-form-field>
  </div>

  <mat-dialog-content class="confirm-dialog-content">
    <div class="loading" *ngIf="!sessions">
      <mat-spinner class="loading"></mat-spinner>
    </div>

    <div class="empty-sessions-list" *ngIf="sessions?.length === 0 && !searchText.trim()">
      <mat-icon class="empty-sessions-list-icon">people_outline</mat-icon>
      <p class="empty-sessions-list-text">You have no sessions besides the one you're currently in</p>
    </div>

    <div class="empty-search-results" *ngIf="getSessions()?.length === 0 && !!searchText.trim()">
      <mat-icon class="empty-search-results-icon">search</mat-icon>
      <p class="empty-search-results-text">No search results</p>
    </div>

    <div class="list" *ngIf="sessions">
      <div
        *ngFor="let session of getSessions(); trackBy: trackByKey"
        class="list-item"
        [ngClass]="{
          'selected': selectedSessionKey === session.key,
          'disabled': isDisabled(session)
        }"
        (click)="onClick(session)"
      >
        <div class="session-image" [ngStyle]="{'background-image': 'url(' + session.image_url + ')'}">
          <mat-icon *ngIf="!session.image_url" class="session-image-icon">panorama</mat-icon>
        </div>
        <div class="text">
          {{session.name}}
          <span *ngIf="session.hasDemographicActivity && data.isDemographics">*</span>
        </div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    class="confirm-dialog-actions"
    [ngClass]="{'indent': showNote}"
  >
    <button
      cdkFocusInitial
      class="tt-button-filled"
      [disabled]="!selectedSessionKey"
      (click)="handleConfirm()"
    >
      {{data.confirmBtnText}}
    </button>
    <button mat-dialog-close class="tt-button-outline">
      {{data.cancelBtnText}}
    </button>
  </mat-dialog-actions>

  <p class="note sub-title-small" *ngIf="showNote">
    * {{demographicSessionsCount}} of {{sessions?.length}}
    sessions already have Demographics activity
  </p>
</div>
