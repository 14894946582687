import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';

import { environment } from '@env/environment';
import { DemographicsStateModule } from '@app/root-store/features/demographics';
import { AuthStateModule } from '@app/root-store/features/auth';
import { CustomSerializer } from '@app/root-store/features/router/state';
import { RouterStoreModule } from '@app/root-store/features/router/router-store.module';
import { FormValidationErrorsStateModule } from '@app/root-store/features/form-validation-errors';
import { FormSummaryStateModule } from '@app/root-store/features/form-summary';
import { FormsStateModule } from '@app/root-store/features/forms';
import { WorkbooksStateModule } from '@app/root-store/features/workbooks';
import { FeatureTogglesModule } from './features/feature-toggles/feature-toggles.module';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        RouterStoreModule,
        DemographicsStateModule,
        AuthStateModule,
        FormValidationErrorsStateModule,
        FormSummaryStateModule,
        FormsStateModule,
        WorkbooksStateModule,
        FeatureTogglesModule,
        StoreModule.forRoot({}, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false,
            }
        }),
        !environment.production ? StoreDevtoolsModule.instrument() : [],
        StoreRouterConnectingModule.forRoot({
            serializer: CustomSerializer,
            navigationActionTiming: NavigationActionTiming.PostActivation
        }),
        EffectsModule.forRoot([])
    ],
    exports: [],
    providers: []
})
export class RootStoreModule {}
