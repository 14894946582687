import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';

import { Notification } from '@app/core/models';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';

@Injectable()
export class NotificationService extends FirebaseUtilityService {

    constructor(
        private db: AngularFireDatabase
    ) {
        super();
    }

    async addNotification(sessionId: string, notification: Notification): Promise<void> {
        this.db.list(`/ssot/_export_notifications/${sessionId}`).push(notification);
    }

    getNotifications(notificationsEntityId: string, userId: string): Observable<Notification[]> {

        // notificationsEntityId -> 'user_report' or sessionId
        return this.listWithKeys(
            this.db.list(`/ssot/_export_notifications/${notificationsEntityId}`,
                ref => ref.orderByChild('owner_id').equalTo(userId))
        );
    }

    getActivityNotifications(sessionId: string, activityId: string): Observable<Notification[]> {
        return this.listWithKeys(
            this.db.list(`/ssot/_export_notifications/${sessionId}`,
                ref => ref.orderByChild('activity_id').equalTo(activityId))
        );
    }

    removeNotification(notificationsEntityId: string, notificationId: string): Promise<void> {

        // notificationsEntityId -> 'user_report' or sessionId
        return this.db
            .object(`/ssot/_export_notifications/${notificationsEntityId}/${notificationId}`)
            .remove();
    }
}
