<div class="confirm-dialog">
  <h3 mat-dialog-title class="h3">{{data.title}}</h3>
  <mat-dialog-content class="confirm-dialog-content">
    <p class="text">{{data.text}}</p>
    <mat-form-field *ngIf="data.isFinalStep" class="keyword">
      <input
        matInput
        cdkFocusInitial
        class="form-control"
        [formControl]="keywordControl"
      >
      <mat-error *ngIf="keywordControl.hasError('required')">This field is required</mat-error>
      <mat-error *ngIf="keywordControl.hasError('nomatch')">{{data.errorMessage}}</mat-error>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions class="confirm-dialog-actions">
    <button
      cdkFocusInitial
      class="tt-button-filled danger"
      [disabled]="keywordControl.invalid && data.isFinalStep"
      (click)="handleConfirm()"
    >
      {{data.confirmBtnText}}
    </button>
    <button mat-dialog-close class="tt-button-outline">{{data.cancelBtnText}}</button>
  </mat-dialog-actions>
</div>
