<span
  class="status overline"
  [ngClass]="{
    'clickable': clickable,
    'mac-os': isMac
  }"
>
  <i class="icon {{formStateClass}}"></i>
  <span>{{formState}}</span>
</span>
