export enum Severity {
    Extreme = 'EXTREME',
    High = 'HIGH',
    Medium = 'MEDIUM',
    Low = 'LOW',
    NoImpact = 'NO_IMPACT'
}

export const SeverityData = {
    [Severity.Extreme]: {
        value: 'Extreme',
        sequence: 4,
        icon: 'arrow_forward'
    },
    [Severity.High]: {
        value: 'High',
        sequence: 3,
        icon: 'arrow_upward'
    },
    [Severity.Medium]: {
        value: 'Medium',
        sequence: 2,
        icon: 'arrow_forward'
    },
    [Severity.Low]: {
        value: 'Low',
        sequence: 1,
        icon: 'arrow_downward'
    },
    [Severity.NoImpact]: {
        value: 'No Impact',
        sequence: 0,
        icon: 'not_interested'
    }
};

export interface SeverityModel {
    [Severity.Extreme]: SeverityUnit,
    [Severity.High]: SeverityUnit,
    [Severity.Medium]: SeverityUnit,
    [Severity.Low]: SeverityUnit,
    [Severity.NoImpact]: SeverityUnit
}

export interface SeverityUnit {
    value: string,
    sequence: number,
    icon: string
}

export interface SeverityConfig {
    [Severity.Extreme]: boolean[],
    [Severity.High]: boolean[],
    [Severity.Medium]: boolean[],
    [Severity.Low]: boolean[],
    [Severity.NoImpact]: boolean[],
}

