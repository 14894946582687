import {
    Component,
    ChangeDetectionStrategy,
    Input,
    OnChanges,
    SimpleChanges,
    Output,
    EventEmitter,
    SimpleChange
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { isEqual } from 'lodash';

import {
    Attachment,
    FormItemType,
    FormQuestion,
    FormSection,
    RepeatedSectionAddInfo,
    RepeatedSectionDeleteInfo,
    Demographic,
    ChangeRequest,
    getFormQuestionLabelValue,
    trackById
} from '@thinktank/common-lib';


interface RepeatedSectionUi {
    [demographicId: string]: { id: string, description: string, sequence: number }[];
}

@Component({
    selector: 'gs-section',
    templateUrl: './section.component.html',
    styleUrls: ['./section.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SectionComponent implements OnChanges {
    @Input() section: FormSection;
    @Input() assignedDemographics: Demographic[];
    @Input() parentForm: FormGroup;
    @Input() global: boolean;
    @Input() isFormSubmitted: boolean;
    @Input() changeRequests: ChangeRequest[];
    @Input() attachedFiles: Attachment[];
    @Input() currentUserId: string;
    @Input() deploymentId: string;
    @Output() addRepeatedSection = new EventEmitter<RepeatedSectionAddInfo>();
    @Output() deleteRepeatedSection = new EventEmitter<RepeatedSectionDeleteInfo>();
    @Output() requestChange = new EventEmitter<{question: FormQuestion, demographic: Demographic, repeatedSectionId?: string}>();

    trackById = trackById;
    FormItemType = FormItemType;
    repeatedSectionsByDemographic: RepeatedSectionUi;

    constructor() {
    }

    shouldDisableChangeRequestButton(question: FormQuestion, demographic: Demographic, sectionId: string): boolean {
        let returnVal = false;
        (this.changeRequests || []).forEach((changeRequest) => {
            if (changeRequest.requesting_user_id === this.currentUserId &&
                changeRequest.question_id === question.id &&
                !!changeRequest.requested_value[`${demographic.id}_${sectionId}`]
            ) {
                returnVal = true;
            }
        });

        return returnVal;
    }

    createRepeatedSectionQuestion(
        question: FormQuestion,
        repeatedSectionId: string,
        sectionNodeId: string,
        sequence: number
    ): FormQuestion {
        return {
            ...question,
            section_node_id: sectionNodeId,
            section_response_id: repeatedSectionId,
            section_sequence: sequence
        };
    }

    getQuestionLabel(question: FormQuestion): string {
        return getFormQuestionLabelValue(question.label);
    }

    numberOfChangeRequests(question: FormQuestion, demographic: Demographic, sectionId: string): Number {
        const questionChangeRequestArray = (this.changeRequests || []).filter((changeRequest) => {
            return changeRequest.question_id === question.id &&
                   !!changeRequest.requested_value[`${demographic.id}_${sectionId}`];
        });
        return questionChangeRequestArray.length;
    }

    hasArrayOfRepeatedSections(demographicId: string): boolean {
        return !!this.repeatedSectionsByDemographic &&
            !!this.repeatedSectionsByDemographic[demographicId] &&
            !!this.repeatedSectionsByDemographic[demographicId].length;
    }

    hasRepeatedSections(demographicId: string): boolean {
        return !!this.repeatedSectionsByDemographic && !!this.repeatedSectionsByDemographic[demographicId];
    }

    numberOfRepeatedSections(demographicId?: string): number {
        return this.hasRepeatedSections(demographicId)
            ? Object.keys(this.repeatedSectionsByDemographic[demographicId]).length + 1
            : 1;
    }

    onDeleteRepeatedSection(repeatedSectionId: string, demographicId: string, index: number): void {
        // Remove the repeated section from the UI and FormGroup
        this.repeatedSectionsByDemographic[demographicId].splice(index, 1);
        const repeatedSectionControl = this.parentForm.get(demographicId) as FormGroup;
        repeatedSectionControl.removeControl(repeatedSectionId);
        this.deleteRepeatedSection.emit({
            id: repeatedSectionId,
            demographicId,
            section: this.section
        });
    }

    onRepeatSectionAdd(demographic: Demographic): void {
        const sectionSequence = this.hasRepeatedSections(demographic.id)
            ? this.repeatedSectionsByDemographic[demographic.id].length + 1
            : 1;
        const sectionResponses = Object.keys(this.parentForm.controls).reduce((accumulator, controlId) => {
            accumulator[controlId] = this.parentForm.get(controlId).value;
            return accumulator;
        }, {});
        this.addRepeatedSection.emit({
            section: this.section,
            nextSequence: sectionSequence,
            demographic: demographic,
            sectionResponses
        });
    }

    questionViewParentForm(demographicId: string): FormGroup {
        return this.parentForm.get(demographicId) as FormGroup;
    }

    questionVisibleForDemographic(questionId: string, demographicId: string): boolean {
        const question = this.section.questionsForView.find((sectionQuestion) => sectionQuestion.id === questionId);
        return !!question && !!question.visible && question.visible[demographicId];
    }

    repeatedSectionQuestionViewParentForm(demographicId: string, sectionId: string): FormGroup {
        return this.parentForm.get([ demographicId, sectionId ]) as FormGroup;
    }

    sectionQuestionsVisible(demographicId: string): boolean {
        return !!this.section.visible[demographicId] &&
            this.section.questionsForView.some((question) => question.visible[demographicId]);
    }

    isFormItemRequired(question: FormQuestion): boolean {
        return (
            this.global
                ? question.required['global']
                : (this.assignedDemographics || []).some((demographic) => !!question.required[demographic.id])
        ) || !!question.required['default'];
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log('SectionComponent::ngOnChanges - changes', changes);
        this.handleSectionChanges(changes['section']);
    }

    private handleSectionChanges(change: SimpleChange): void {
        if (!!change && !!change.currentValue) {
            const previousRepeatedSections = !!change.previousValue && !!change.previousValue.repeated_sections || {};
            const currentRepeatedSections = change.currentValue.repeated_sections;
            if (!!currentRepeatedSections && !isEqual(previousRepeatedSections, currentRepeatedSections)) {
                this.repeatedSectionsByDemographic = Object.keys(this.section.repeated_sections).reduce((accumulator, demographicId) => {
                    accumulator[demographicId] =
                        Object.keys(this.section.repeated_sections[demographicId]).reduce((sectionAccumulator, sectionId) => {
                            sectionAccumulator.push({
                                ...this.section.repeated_sections[demographicId][sectionId],
                                id: sectionId,
                            });
                            return sectionAccumulator;
                        }, [])
                            .sort((a, b) => a.sequence - b.sequence);
                    return accumulator;
                }, {});
            }
        }
    }

}
