export class Slide {
    key?: string;
    app_id: string;
    activity_id: string;
    activity_type: string;
    owner_id: string;
    sequence: number;
    slideImage_url: string;
    name: string;
    form_activities_ids: string[];
    notes: { [noteKey: string]: string };
    invalid?: boolean;
}

export class SlidesMap {
    [slideKey: string]: SlideData
}

export class SlideData {
    slideName: string;
    slideImageUrl: string;
    form_activities_ids: string[];
}

export const testSlide = {
    app_id: 'user1',
    activity_id: 'user1',
    activity_type: 'presentation',
    owner_id: 'user1',
    sequence: 1,
    slideImage_url: 'test_url.com',
    form_activities_ids: ['test_activity_id1', 'test_activity_id2']
} as Slide;
