import { Component, OnChanges, Input, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { groupBy as _groupBy } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { trackById, Demographic } from '@thinktank/common-lib';
import { ObjectType } from '../../../models';

export enum UsersPanelNavigationItem {
    Owners = 'Owners',
    Stakeholders = 'Stakeholders'
}

@Component({
    selector: 'gs-users-panel',
    templateUrl: './users-panel.component.html',
    styleUrls: ['./users-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class UsersPanelComponent implements OnChanges {
    @Input() deploymentId: string;
    @Input() objectId: string;
    @Input() objectType: ObjectType;
    @Input() useDemographics: boolean;
    @Input() isGlobal: boolean;
    @Input() demographics: Demographic[];
    @Input() users: any[];
    @Input() processHaveSomeGlobalSubprocesses?: boolean;

    owners: {[demographicId: string]: any[]} = {};
    stakeholders: {[demographicId: string]: any[]} = {};
    owners$ = new BehaviorSubject<{[demographicId: string]: any[]}>(null);
    stakeholders$ = new BehaviorSubject<{[demographicId: string]: any[]}>(null);
    selectedNavigationItem: UsersPanelNavigationItem;
    isOwnersActive: boolean;
    trackById = trackById;
    UsersPanelNavigationItem = UsersPanelNavigationItem;

    constructor() {
        this.resetComponent();
    }

    get getOwnerNumber(): number {
        let ownerCount = 0;
        if (this.isGlobal) {
            ownerCount = (this.owners['global'] || []).length;
        } else {
            const users = (this.users || []).filter(item => item.role === 'owner');
            ownerCount = this.getUsersLength(users);
        }
        return ownerCount;
    }

    get getStakeholderNumber(): number {
        let stakeholderCount = 0;
        if (this.isGlobal) {
            stakeholderCount = (this.stakeholders['global'] || []).length;
        } else {
            const users = (this.users || []).filter(item => item.role === 'stakeholder');
            stakeholderCount = this.getUsersLength(users);
        }
        return stakeholderCount;
    }

    get isModule(): boolean {
        return this.objectType === ObjectType.Module;
    }

    get isSubProcess(): boolean {
        return this.objectType === ObjectType.Subprocess;
    }

    get hasOwners(): boolean {
        if (this.isGlobal) {
            return !this.owners || (this.owners['global'].length !== 0);
        } else {
            let demoOwnerCount = 0;
            Object.keys(this.owners).forEach((demographicId) => {
                demoOwnerCount += this.owners[demographicId].length;
            });
            return demoOwnerCount !== 0;
        }
    }

    get hasStakeholders(): boolean {
        if (this.isGlobal) {
            return !this.stakeholders || (this.stakeholders['global'].length !== 0);
        } else {
            let demoStakeholderCount = 0;
            Object.keys(this.stakeholders).forEach((demographicId) => {
                demoStakeholderCount += this.stakeholders[demographicId].length;
            });
            return demoStakeholderCount !== 0;
        }
    }

    setSelectedNavigationItem(item: UsersPanelNavigationItem): void {
        this.selectedNavigationItem = item;
        this.isOwnersActive = (item === UsersPanelNavigationItem.Owners);
    }

    getGroupedUsers(): void {
        const owners = (this.users || []).filter(item => item.role === 'owner');
        const stakeholders = (this.users || []).filter(item => item.role === 'stakeholder');

        if (this.isModule || this.isGlobal) {
            this.owners['global'] = owners;
            this.stakeholders['global'] = stakeholders;
        } else {
            this.owners = _groupBy(owners, 'demographic_id');
            this.stakeholders = _groupBy(stakeholders, 'demographic_id');
        }

        this.owners$.next(this.owners);
        this.stakeholders$.next(this.stakeholders);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.users && !!this.users) {
            this.getGroupedUsers();
        }

        if (changes.demographics && !!this.demographics) {
            this.getGroupedUsers();
        }
    }

    private resetComponent(): void {
        this.selectedNavigationItem = UsersPanelNavigationItem.Owners;
        this.isOwnersActive = true;
    }

    private getUsersLength(users: any[]): number {
        const filterUsers = {};
        users.forEach((item) => {
            filterUsers[item.user_id] = true;
        });
        return Object.keys(filterUsers).length;
    }
}
