import { createAction, props } from '@ngrx/store';

import { FormQuestionValidationError } from '@app/core/models';

const getFormValidationErrorsType = '[FormValidationErrors] validate form';
const getFormValidationErrorsSuccessType = '[FormValidationErrors] validate form success';
const getFormValidationErrorsFailType = '[FormValidationErrors] validate form fail';
const clearValidationType = '[FormValidationErrors] clear validation store';
const validateFormCallType = '[FormValidationErrors] validate form call';
const validateFormCallSuccessType = '[FormValidationErrors] validate form call success';
const validateFormCallFailType = '[FormValidationErrors] validate form call fail';

export const getFormValidationErrors = createAction(
    getFormValidationErrorsType,
    props<{ activityId: string }>()
);

export const getFormValidationErrorsSuccess = createAction(
    getFormValidationErrorsSuccessType,
    props<{ errors: FormQuestionValidationError[] }>()
);

export const getFormValidationErrorsFail = createAction(
    getFormValidationErrorsFailType,
    props<{ errorMessage: string }>()
);

export const clearValidationStore = createAction(
    clearValidationType,
    props<{ activityId?: string }>()
);

export const validateFormCall = createAction(
    validateFormCallType,
    props<{ activityId: string }>()
);

export const validateFormCallSuccess = createAction(
    validateFormCallSuccessType
);

export const validateFormCallFail = createAction(
    validateFormCallFailType,
    props<{ errorMessage: string }>()
);

