import { Timestamp, FieldValue } from 'firebase/firestore';

export class FormResponsesHistory {
    form_id: string;
    responses_count: number;
}

export class FormHistoryResponse {
    id?: string;
    date_created: Timestamp;
    value: any;
    owner_id: string;
    owner_full_name: string;
    initial_owner_id?: string;
    initial_owner_full_name?: string;
    initial_value?: any;
    form_status: string; // status of the form when the response was changed
    comments_count: number;
    upvotes: string[]; // array of owner_id[]
    current?: boolean;
    custom_name: string | null;
}

export const testFormHistoryResponse = {
    id: 'testResponseKey',
    date_created: Timestamp.now(),
    value: 'testValue',
    owner_id: 'testOwnerId',
    owner_full_name: 'testOwnerFullName',
    form_status: 'DRAFT',
    comments_count: 0,
    upvotes: [],
    custom_name: 'testcustomName',
}

export class DBFormHistoryResponse {
    date_created: FieldValue;
    owner_id: string;
    owner_full_name: string;
    value: any;
    form_status: string;
    comments_count: number;
    upvotes?: string[]; // array of owner_id[]
    initial_value?: any;
    initial_owner_id?: string;
    initial_owner_full_name?: string;
    old_date?: number; // used for backward compatibility
    custom_name?: string;
}

export class FormHistoryResponseComment {
    id?: string;
    owner_id: string;
    owner_full_name: string;
    date_created: Timestamp;
    date_updated?: Timestamp;
    value: string;
}

export const testFormHistoryResponseComment = {
    owner_id: 'testOwnerId',
    owner_full_name: 'testOwnerFullName',
    date_created: Timestamp.now(),
    value: 'testValue'
};

// Data related to response to write to firebase to be able to update use full name
export class UserHistoryResponseLink {
    id?: string;
    owner_id: string;
    response_id: string;
    comment_id?: string;
    question_id: string;
    demographic_id?: string;
    secondary_demographic_id?: string;
    is_initial?: boolean;
}
