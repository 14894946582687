import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { User } from '../../../models';
import { trackById } from '@thinktank/common-lib';

@Component({
    selector: 'gs-assignees',
    templateUrl: './assignees.component.html',
    styleUrls: ['./assignees.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssigneesComponent {
    @Input() users: any[];
    @Input() limit?: number;

    trackById = trackById;

    private defaultLimit = 4;

    constructor() { }

    get usersLimit(): number {
        return this.limit || this.defaultLimit;
    }

    get slicedUsersList(): any[] {
        const usersList = !!this.users ? [...this.users] : [];
        return usersList.slice(0, this.usersLimit);
    }

    get userLength(): number {
        return !!this.users
            ? this.users.slice(this.usersLimit).length
            : 0;
    }

    get getAllUsersNames(): string {
        if (!this.users) {
            return '';
        }
        const users = [...this.users].slice(this.usersLimit, this.users.length);
        return users.map(user => User.getUserName(user)).join(', ');
    }

    getUserName(user: User, shortName?: boolean): string {
        return User.getUserName(user, shortName);
    }
}
