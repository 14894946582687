import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { Ownership, Subprocess } from '../../models';
import { SubprocessViewFacade, SubprocessViewFacadeToken } from './subprocess-view-facade';

@Component({
    selector: 'gs-subprocess-view',
    templateUrl: './subprocess-view.component.html',
    styleUrls: ['./subprocess-view.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubprocessViewComponent {
    vm$ = this.facade.vm$;

    constructor(@Inject(SubprocessViewFacadeToken) private facade: SubprocessViewFacade) {
    }

    getFilteredOwnership(entireDeploymentOwnership: Ownership[], subprocessId: string): Ownership[] {
        return (entireDeploymentOwnership || []).filter((ownershipObj) => ownershipObj.object_id === subprocessId);
    }

    goBackToDeploymentView(deploymentId: string): void {
        this.facade.routeToDeployment(deploymentId);
    }

    goToAssignment(subprocess: Subprocess, deploymentId: string): void {
        this.facade.routeToAssignment(deploymentId, subprocess.id);
    }

    unassignUser(ownershipToRemove: Ownership, deploymentId: string, subprocessId: string): void {
        this.facade.unassignUser(ownershipToRemove, deploymentId, subprocessId);
    }
}
