import { GraphNode } from './models/graph-node.model';
import { SingleSelectQuestionNode } from './models/single-select-question-node.model';
import { MultiSelectQuestionNode } from './models/multi-select-question-node.model';
import { FormNode } from './models/form-node.model';
import { SectionNode } from './models/section-node.model';
import { StringCompareNode } from './models/string-compare-node.model';
import { PickNode } from './models/pick-node.model';
import { ListMaskNode } from './models/list-mask-node.model';
import { TextQuestionNode } from './models/text-question-node.model';

export function NodeFactory(deploymentId: string, nodeId: string, data: object, db?: any) {
    switch (data['type']) {
        case 'short_text':
        case 'long_text':
            return new TextQuestionNode(data['type'], deploymentId, nodeId, data, db);
        case 'single_select':
            return new SingleSelectQuestionNode(deploymentId, nodeId, data, db);
        case 'multi_select':
            return new MultiSelectQuestionNode(deploymentId, nodeId, data, db);
        case 'form':
            return new FormNode(deploymentId, nodeId, data, db);
        case 'section':
            return new SectionNode(deploymentId, nodeId, data, db);
        case 'string_compare':
            return new StringCompareNode(deploymentId, nodeId, data, db);
        case 'pick':
            return new PickNode(deploymentId, nodeId, data, db);
        case 'list_mask':
            return new ListMaskNode(deploymentId, nodeId, data, db);
        default:
            return null;
    }
}

/** this should go in a graph service */
/** It should also return an object from a factory depending on the data it finds in the graph */
export async function LoadNode(db: any, deploymentId: string, nodeId: string): Promise<GraphNode> {
    const snap = await db.doc(`/deployments/${deploymentId}/graph_nodes/${nodeId}`).get();
    return NodeFactory(deploymentId, nodeId, snap.data(), db);
}
