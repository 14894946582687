<div class="alert-dialog">
  <h3 mat-dialog-title class="h3 alert-dialog-title">
    {{alertTitle}}
    <button mat-icon-button mat-dialog-close class="close">
      <mat-icon>close</mat-icon>
    </button>
  </h3>
  <mat-dialog-content class="alert-content text" [innerHtml]="alertMessage"></mat-dialog-content>
  <mat-dialog-actions class="alert-actions">
    <button cdkFocusInitial class="tt-button-filled" (click)="onSubmit()">
      {{alertConfirmBtnText}}
    </button>
  </mat-dialog-actions>
</div>
