import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';

@Injectable()
export class RepositoryGuard implements CanActivate, CanActivateChild {

    constructor(
        private authenticatedUserService: AuthenticatedUserService,
        private router: Router
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.checkUserAccessToRepository();
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): boolean {
        return this.canActivate(route, state);
    }

    private checkUserAccessToRepository(): boolean {
        const currentUserId = this.authenticatedUserService.getCurrentUserId();
        if (this.authenticatedUserService.hasUserLeaderSessionRole(currentUserId)) {
            return true;
        }

        this.router.navigate(['/navigation']);

        return false;
    }
}
