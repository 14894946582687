import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'questionGroup'
})

export class QuestionGroupPipe implements PipeTransform {

    transform(value: any, groupKey: any): any {
        if (value) {
            return value.filter((item) => groupKey === item.group);
        }
        return null;
    }

}
