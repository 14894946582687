import { Injectable, OnDestroy } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, mergeMap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import * as FormSummaryActions from '../actions';
import { FormSummaryService } from '@app/core/services/form-summary.service';

@UntilDestroy()
@Injectable()
export class FromValidationEffects implements OnDestroy {

    public getFormSummary$ = createEffect(() =>
        this.actions$.pipe(
            ofType(FormSummaryActions.getFormSummary),
            mergeMap((action) => {
                return this.formSummaryService
                    .getFormSummaryData(action.activityId)
                    .pipe(
                        map(summary => FormSummaryActions.getFormSummarySuccess({
                            summary
                        })),
                        untilDestroyed(this)
                    );
            })
        ));

    constructor(
        private actions$: Actions,
        private formSummaryService: FormSummaryService
    ) { }

    ngOnDestroy() { }
}
