<div
  class="list-item"
  [ngClass]="{'active': active, 'small-sized': isSmallSized}"
  (click)="listItemClick($event)"
>
  <div class="list-item__content">
    <div
      class="circle"
      [ngStyle]="activeStyle"
    ></div>
    <h5 class="title text text-dark-primary">
      <ng-content></ng-content>
    </h5>
  </div>

  <!-- count of ungrouped responses -->
  <div class="count sub-title text-dark-primary" *ngIf="count || count == '0'">{{count}}</div>
  <!-- /count of ungrouped responses -->

</div>
