import { responseType } from '@app/core/models/question.model';

// label and placeholders
export const inputLabels = {
    title: 'Title',
    question: 'Question',
    description: 'Description',
    activityName: 'Activity Name',
    sectionName: 'Section Name',
    demographicClassName: 'Demographic Сlass',
    option: 'Option',
    addOption: 'Add Option',
    bottomLabel: 'Bottom Label',
    mediumLabel: 'Medium Label',
    topLabel: 'Top Label',
    step: 'Step',
    date: 'Choose a date',
    min: 'Min Value',
    max: 'Max Value',
    video: 'Add a Link:',
    videoPlaceholder: 'https://',
    image: 'Image',
    issueType: 'Issue Type',
    preset: 'Preset',
    selectPreset: 'Select Preset',
    actionScale: 'Action Scale',
    response: 'Enter response here...',
    enterDescriptionPlaceholder: 'Enter description here...',
    optionPlaceholder: 'Choose an option',
    repeatableNote: 'Put your note on the section here...',
    repeatableButton: 'Fill Section Out Again',
    sectionDemographicClasses: 'Use New Demographic Classes for Section',
    workbookMappingForOtherOption: 'Workbook mapping for \'Other\' option',
    mappingTemplate: 'Mapping Template',
    enterMappingTemplatePlaceholder: 'Enter the mapping template ID here',
};

export const checkboxMessages = {
    checkCollaborationLabel: 'Do Not Allow Changes to Other Participants Answers',
    collaborationCheckboxTooltip:
        'If selected, users will not have the ability to change other users\' answers without consensus during completion of the form',
    useDemographicsCheckboxTooltip: 'You must add Demographic Activity above the current one to use demographics',
    useDemographicsLabel: 'Use Demographics',
    alignmentEnabledLabel: 'Alignment Mode',
    allowParticipantDisagreements: 'Allow Participants to Accept/Change Answers to Disagreements',
    exportToJiraCheckboxLabel: 'Export to Jira',
    moveAllToJiraLabel: 'Move All to Jira',
};

export const validationMessages = {
    required: 'This field is required',
    requiredAttachment: 'This question is required. Please attach the file.',
    equalOrGrater: value => `Value must be equal to or greater than ${value}. Please verify.`,
    equalOrLess: value => `Value shall not exceed ${value}`,
    greater: value => `Value must be greater than ${value}. Please verify.`,
    maxGreaterThenMin: 'Max value should exceed Min value',
    step: value => `Step should be equal to or less than ${value}`,
    email: 'Please enter a valid email address.',
    videoLink: 'Please add a link to YouTube or Vimeo video.',
    search: 'No results have been found.',
    text: 'The text you entered is incorrect. Please try again.',
    minMaxPreset: (min, max) => `Please enter a number between ${min} and ${max}.`,
    minMaxPresetAlternate: (min, max) => `The number must be in range from ${min} to ${max}.`,
    minPreset: value => `Please enter a number greater than ${value}.`,
    maxPreset: value => `Please enter a number less than ${value}.`,
    wrongFormat: 'Wrong format',
    cellsOverlapping: 'Cells overlapping',
    nameAlreadyExists: 'This name already exists. Please choose a different name.',
    maxCharactersLength: (field, max) => `The ${field} shall not exceed ${max} characters.`,
    specialCharacters: 'A file name can`t contain any of the following characters: \\ / : * ? " | < > ;',
    // specifyMappingTemplate: 'Please specify the mapping template to enable export to XML',
    // TODO: use this hint instead of the next one after integration with Jitterbit
    specifyMappingTemplate: 'You currently don\'t have any XML mapping setup',
};

export const informationMessages = {
    single: ' is editing now…',
    multiple: 'Several people are editing now…',
    tooltip: 'Answer is being edited by ',
};

export const allDemographicsConst = 'all';

// response types
export const notQuestionResponseTypes: responseType[] = ['image', 'video', 'subform', 'file'];

export const questionResponseTypes: responseType[] = [
    'attachment',
    'date',
    'long_answer',
    'multi',
    'number',
    'short_answer',
    'single',
    'slider',
    'star_voting',
];

// response types without history
export const noHistoryResponseTypes: responseType[] = ['image', 'video', 'subform', 'file', 'attachment'];

export const smallWidthQuestionResponseTypes: responseType[] = ['date', 'multi', 'single', 'slider'];

export const imageVideoFileTypes: responseType[] = ['image', 'video', 'file'];

export const selectResponseTypes: responseType[] = ['multi', 'single'];

export const hasBasketRemoveButton: responseType[] = ['single', 'slider', 'star_voting'];

export const responseTypesWithExtraOptions: responseType[] = ['multi', 'number', 'subform', 'slider', 'star_voting'];

// template for preset subform object (the same as in the firebase-cloud-functions)
export const subformComponentType = {
    active: true,
    demographics: 'all',
    display_name: 'Section',
    icon: 'select_all',
    sequence: 16,
    type: 'subform',
    option_fields: {
        repeatable: {
            key: 'repeatable',
            templateOptions: {
                label: 'Make section repeatable',
                required: false,
            },
            type: 'checkbox',
        },
        section_note: {
            key: 'section_note',
            templateOptions: {
                label: 'Allow section notes',
                required: false,
            },
            type: 'checkbox',
        },
    },
    options: {
        repeatable: false,
        section_note: true,
        pin_top_question: true,
    },
};

export const repeatableSectionDemographics = 'Section can be either repeatable or use second demographic class';
export const alignmentModeSectionDemographics =
    'Section can’t use second demographic class if Alignment Mode is set to On';
export const collaborationOffSectionDemographics =
    'Section can\'t use second demographics class if changes to other participants answers are allowed';

export const allowedWorkbookResponseTypesMap: { [type: string]: boolean } = {
    short_answer: true,
    long_answer: true,
    number: true,
    date: true,
    single: true,
    multi: true,
    slider: true,
    star_voting: true,
    subform: true,
};

export const optionsCount = 4;
