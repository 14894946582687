import {
    Component,
    OnChanges,
    Input,
    ViewChild,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';

import { trackByIndex } from '@app/core/utils';

@Component({
    selector: 'app-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class PaginatorComponent implements OnChanges {
    @Input() data: any;
    @Input() dataLength: number;
    @Input() pageSize: number;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

    // Paginator
    numberOfPages: number;
    activePageNumber: number;
    paginatorArray: any[];
    trackByIndex = trackByIndex;

    constructor(private cdRef: ChangeDetectorRef) {
        this.activePageNumber = 0;
        this.numberOfPages = 0;
        this.paginatorArray = [];
    }

    get currentPageIndex(): number {
        return (this.data || 0) && this.data.paginator.pageIndex;
    }

    get hasNextPage(): boolean {
        return !!this.data && !!this.data.paginator && this.data.paginator.hasNextPage();
    }

    get hasPreviousPage(): boolean {
        return !!this.data && !!this.data.paginator && this.data.paginator.hasPreviousPage();
    }

    setActivePage(index: number): void {
        const newIndex = index - this.activePageNumber;

        if (newIndex === 0) {
            return;
        }

        this.activePageNumber = index;
        this.createArrayForPaginator();

        for (let i = 0; i < Math.abs(newIndex); i++) {
            if (newIndex > 0) {
                this.paginator.nextPage();
            } else {
                this.paginator.previousPage();
            }
        }
    }

    createArrayForPaginator(): void {
        this.paginatorArray = [];

        // If pages in paginator less then 5
        if (this.numberOfPages < 6) {
            for (let i = 0; i < this.numberOfPages; i++) {
                this.paginatorArray.push({ value: i + 1, index: i });
            }
            return;
        }

        let pageCutLow = this.activePageNumber - 1;
        let pageCutHigh = this.activePageNumber + 1;

        if (this.activePageNumber === 0) {
            pageCutHigh += 2;
        } else if (this.activePageNumber === 1) {
            pageCutHigh += 1;
        }

        // Determine how many pages to show before the current page index
        if (this.activePageNumber === (this.numberOfPages - 1)) {
            pageCutLow -= 2;
        } else if (this.activePageNumber === (this.numberOfPages - 2)) {
            pageCutLow -= 1;
        }

        if (this.activePageNumber > 1) {
            this.paginatorArray.push({ value: 1, index: 0 })

            if (this.activePageNumber > 2) {
                this.paginatorArray.push({ value: '...', index: (this.activePageNumber - 2) })
            }
        }

        for (let i = pageCutLow; i <= pageCutHigh; i++) {
            if (i === -1) {
                i += 1;
            }

            if (i > (this.numberOfPages - 1)) {
                continue;
            }

            this.paginatorArray.push({ value: i + 1, index: i });
        }

        if (this.activePageNumber < (this.numberOfPages - 2)) {
            if (this.activePageNumber < (this.numberOfPages - 3)) {
                this.paginatorArray.push({ value: '...', index: (this.activePageNumber + 2) });
            }
            this.paginatorArray.push({ value: this.numberOfPages, index: (this.numberOfPages - 1) });
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && changes.data) {
            this.data.paginator = this.paginator;

            // Need time for proper initialize of data paginator
            setTimeout(() => {
                this.numberOfPages = this.data.paginator.getNumberOfPages();
                this.createArrayForPaginator();
                this.cdRef.detectChanges();
            });
        }
    }
}
