import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { Subject, Observable } from 'rxjs';

@Component({
    selector: 'app-session-promoted-snack-bar',
    templateUrl: './session-promoted-snack-bar.component.html',
    styleUrls: ['./session-promoted-snack-bar.component.scss']
})

export class SessionPromotedSnackBarComponent implements OnDestroy {
    private _onOpen = new Subject<void>();

    constructor(
        public snackBarRef: MatSnackBarRef<SessionPromotedSnackBarComponent>,
        @Inject(MAT_SNACK_BAR_DATA) public data: any
    ) { }

    onOpen(): Observable<void> {
        return this._onOpen.asObservable();
    }

    open(): void {
        this._onOpen.next();
    }

    close(event: any): void {
        event.stopPropagation();
        this.snackBarRef.dismiss();
    }

    ngOnDestroy() {
        this._onOpen.complete();
    }

}
