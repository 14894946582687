<div class="section" [formGroup]="parentForm">
  <div *ngIf="!!section.description" class="section-description">
    <span class="section-description-text subtitle-medium text-uppercase">{{section.description}}</span>
  </div>

  <ng-container
    *ngFor="let demographic of assignedDemographics; trackBy: trackById"
    [formGroupName]="demographic.id"
  >
    <div
      class="section-item section-item-border"
      [ngClass]="{'not-repeated': !hasArrayOfRepeatedSections(demographic.id)}"
    >
      <div class="section-item-header">
        <div
          *ngIf="!global"
          class="section-demographic-name gs-demographic-chip gs-demographic-chip-label"
        >
          {{ demographic.name }}
        </div>

        <span
          *ngIf="section.repeatable && numberOfRepeatedSections(demographic.id) !== 1"
          class="repeatable-section-counter caption"
        >
          1 of {{ numberOfRepeatedSections(demographic.id) }}
        </span>
      </div>

      <ng-container *ngIf="!sectionQuestionsVisible(demographic.id)">
        <gs-empty-screen
          class="section-empty"
          [imageUrl]="'/assets/svg/gs-empty-section.svg'"
          [text]="'There are no questions in this section'"
        ></gs-empty-screen>
      </ng-container>

      <div *ngIf="sectionQuestionsVisible(demographic.id)" class="section-questions">
        <div *ngFor="let question of section.questionsForView; trackBy: trackById" class="section-question">
          <div class="section-question-wrapper">
            <h4 *ngIf="questionVisibleForDemographic(question.id, demographic.id)" class="section-question-label text-medium">
              {{ getQuestionLabel(question) }}
              <span *ngIf="isFormItemRequired(question)" class="required">*</span>
            </h4>
            <gs-question-view
              [question]="createRepeatedSectionQuestion(question, section.id, section.id, 0)"
              [demographic]="demographic"
              [parentForm]="questionViewParentForm(demographic.id)"
              [deploymentId]="deploymentId"
              [authenticateUserId]="currentUserId"
              [attachedFiles]="attachedFiles"
            ></gs-question-view>
          </div>

          <div
            *ngIf="isFormSubmitted && questionVisibleForDemographic(question.id, demographic.id)"
            class="change-request"
          >
            <p class="caption">Change Requests: {{ numberOfChangeRequests(question, demographic, section.id) }}</p>
            <button
              class="gs-button-icon-flat"
              [disabled]="shouldDisableChangeRequestButton(question, demographic, section.id)"
              (click)="requestChange.emit({question: question, demographic: demographic})"
            >
              <span *ngIf="!shouldDisableChangeRequestButton(question, demographic, section.id)">Request Change</span>
              <span *ngIf="shouldDisableChangeRequestButton(question, demographic, section.id)">Change Requested</span>
            </button>
          </div>
        </div>
        <div *ngIf="section.repeatable && !hasRepeatedSections(demographic.id) && !isFormSubmitted" class="repeatable-section-button">
          <button
            class="gs-button-ghost gs-small"
            (click)="onRepeatSectionAdd(demographic)"
          >
            Repeat Section
          </button>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!!repeatedSectionsByDemographic && !!repeatedSectionsByDemographic[demographic.id]">

      <div class="repeatable-sections section-item-border">
        <div class="repeatable-sections-container">
          <div
            *ngFor="let repeatedSection of repeatedSectionsByDemographic[demographic.id]; trackBy: trackById; let i = index;"
            class="repeatable-section-item section-item"
            [formGroupName]="repeatedSection.id"
          >
            <div class="repeatable-section-header">
              <div
                *ngIf="!global"
                class="gs-demographic-chip gs-demographic-chip-label section-demographic-name"
              >
                {{ demographic.name }}
              </div>

              <span *ngIf="numberOfRepeatedSections(demographic.id) !== 1" class="repeatable-section-counter caption">
                {{ i + 2 }} of {{ numberOfRepeatedSections(demographic.id) }}
              </span>
            </div>

            <div>
              <gs-section-description
                [sectionId]="section.id"
                [repeatedSectionId]="repeatedSection.id"
                [parentForm]="repeatedSectionQuestionViewParentForm(demographic.id, repeatedSection.id)"
                [demographicId]="demographic.id"
                [formId]="section.form_id"
                [deploymentId]="deploymentId"
              ></gs-section-description>
            </div>

            <div class="repeatable-section-questions">
              <div *ngFor="let question of section.questionsForView; trackBy: trackById" class="repeatable-section-question">
                <div class="section-question-wrapper">
                  <h4 *ngIf="questionVisibleForDemographic(question.id, demographic.id)" class="section-question-label text-medium">
                    {{ getQuestionLabel(question) }}
                    <span *ngIf="isFormItemRequired(question)" class="required">*</span>
                  </h4>
                  <gs-question-view
                    [question]="createRepeatedSectionQuestion(question, repeatedSection.id, section.id, repeatedSection.sequence)"
                    [demographic]="demographic"
                    [parentForm]="repeatedSectionQuestionViewParentForm(demographic.id, repeatedSection.id)"
                    [deploymentId]="deploymentId"
                    [authenticateUserId]="currentUserId"
                  ></gs-question-view>
                </div>

                <div
                  *ngIf="isFormSubmitted && questionVisibleForDemographic(question.id, demographic.id)"
                  class="change-request"
                >
                  <p class="caption">Change Requests: {{ numberOfChangeRequests(question, demographic, repeatedSection.id) }}</p>
                  <button
                    class="gs-button-icon-flat"
                    [disabled]="shouldDisableChangeRequestButton(question, demographic, repeatedSection.id)"
                    (click)="requestChange.emit({question: question, demographic: demographic, repeatedSectionId: repeatedSection.id})"
                  >
                    <span *ngIf="!shouldDisableChangeRequestButton(question, demographic, repeatedSection.id)">Request Change</span>
                    <span *ngIf="shouldDisableChangeRequestButton(question, demographic, repeatedSection.id)">Change Requested</span>
                  </button>
                </div>
              </div>
            </div>
            <div *ngIf="!isFormSubmitted" class="repeatable-section-delete">
              <button
                class="gs-button-icon-danger-flat"
                (click)="onDeleteRepeatedSection(repeatedSection.id, demographic.id, i)"
              >
                <mat-icon>delete</mat-icon>
                Delete Section
              </button>
            </div>
          </div>
        </div>
        <div *ngIf="!isFormSubmitted" class="repeatable-section-button">
          <button
            class="gs-button-ghost gs-small"
            (click)="onRepeatSectionAdd(demographic)"
          >
            Repeat Section
          </button>
        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
