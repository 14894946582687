<div class="task-status" [ngClass]="{'clickable': emulate}" (click)="toggleCompleted()">
  <mat-icon>done</mat-icon>
</div>

<div class="task-content">

  <h5 class="h5 task-name">
    <span class="name">{{data.name}}</span>
    <mat-icon
      *ngIf="showExpandIcon"
      class="drop-down-icon"
      [ngClass]="expanded ? 'arrow-up' : 'arrow-down'"
      (click)="toggleExpanded()"
    >keyboard_arrow_down</mat-icon>
    <button
      *ngIf="showCompleteGateButton"
      class="tt-button-flat complete-gate x-small"
      tt-button-color="primary"
      [disabled]="data.completed || isApp"
      (click)="completeGate()"
    >
      {{data.completed ? 'Completed' : 'Complete Gate'}}
    </button>
  </h5>

  <p>
    <span class="task-label activity-type-label gate">gate</span>
    <span *ngIf="startDate" class="sub-title-small">{{startDate|date:"MMM dd, yyyy"}}</span>
    <span *ngIf="startDate && endDate" class="sub-title-small"> - </span>
    <span *ngIf="endDate" class="sub-title-small">{{endDate|date:"MMM dd, yyyy"}}</span>
  </p>

  <!-- gate details -->
  <div *ngIf="expanded && data.details" class="expandable">
    <p class="sub-title-small gate-details">{{data.details}}</p>
  </div>
  <!-- /gate details -->

</div>
