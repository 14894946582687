import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Store, select } from '@ngrx/store';

import { Demographic, SelectedDemographics, ActiveDemographics } from '@app/core/models';
import { listWithKeys, sortBySequenceAsc } from '@app/core/utils';
import { AppState } from '@app/root-store/state';
import { demographicsSelector } from '../selectors';

@Injectable()
export class DemographicsFeatureService {
    constructor(
        private db: AngularFireDatabase,
        private store$: Store<AppState>
    ) { }

    public getDemographics(demographicsClassKey: string): Observable<Demographic[]> {

        if (!demographicsClassKey) {
            return of([]);
        }

        return listWithKeys<Demographic>(
            this.db.list<Demographic>(
                `/ssot/_demographics`,
                (ref) => ref.orderByChild('class_id').equalTo(demographicsClassKey)
            )
        ).pipe(map(sortBySequenceAsc));
    }

    public getSelectedDemographicsForUser(userKey: string, classKey: string): Observable<Demographic[]> {
        let selectedDemographicsKeys = [];
        let selectedDemographicsList = <SelectedDemographics>{};

        return this.db.object<SelectedDemographics>(`/ssot/_selected_demographics/${userKey}_${classKey}`)
            .valueChanges()
            .pipe(
                mergeMap((selectedDemographics: SelectedDemographics) => {
                    if (!selectedDemographics) {
                        return of([]);
                    }
                    selectedDemographicsList = selectedDemographics;
                    selectedDemographicsKeys = Object.keys(selectedDemographics.options || {});
                    return this.store$.pipe(select(demographicsSelector, { classKey }));
                }),
                map((demographics: Demographic[]) => {

                    // return only selected demographics from class
                    return demographics.filter((demographic: Demographic) => {
                        return selectedDemographicsKeys.includes(demographic.key);
                    });
                })
            );
    }

    public getFormActiveDemographicsKeys(userId: string, formKey: string, classKey: string): Observable<string | string[]> {
        return this.db
            .object(`/ssot/_forms/${formKey}/active_demographics/${userId}_${classKey}`)
            .valueChanges() as Observable<string | string[]>;
    }

    public getFormDemographicsClassId(formKey: string): Observable<string> {
        return this.db.object(`/ssot/_forms/${formKey}/demographics_class_id`)
            .valueChanges() as Observable<string>;
    }

    public getParentFormActiveDemographics(formKey: string): Observable<ActiveDemographics> {
        return this.db.object<any>(`/ssot/_forms/${formKey}/active_demographics`)
            .valueChanges();
    }

    public writeInitialActiveDemographics(userId: string, formKey: string, classKey: string, demographics: Demographic[]): Promise<void> {
        const demographicKeys = demographics.map((demographic) => demographic.key);
        return this.db.object(`/ssot/_forms/${formKey}/active_demographics/${userId}_${classKey}`).set(demographicKeys);
    }
}
