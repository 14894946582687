import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-alert-html-dialog',
    templateUrl: './alert-html-dialog.component.html',
    styleUrls: ['./alert-html-dialog.component.scss']
})
export class AlertHtmlDialogComponent {
    alertMessage: string;
    alertTitle: string;
    alertConfirmBtnText: string;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AlertHtmlDialogComponent>
    ) {
        this.alertTitle = this.data.title;
        this.alertMessage = this.data.text;
        this.alertConfirmBtnText = this.data.confirmBtnText;
    }

    onSubmit(): void {
        this.dialogRef.close();
    }
}
