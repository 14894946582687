import { Node, NodeData, NodeType } from './node.model';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export interface GateData extends NodeData {
    start_date?: firebase.firestore.Timestamp;
    end_date?: firebase.firestore.Timestamp;
    sequence?: number;
    details?: string;
    activity_id?: string;
    leader_check?: boolean;
    activities_required?: boolean;
    completed?: boolean;
}

export class Gate implements Node {
    key?: string;
    project_key?: string;
    x?: number;
    y?: number;
    readonly type: NodeType;
    data: GateData;
    created?: firebase.firestore.Timestamp;
    updated?: firebase.firestore.Timestamp;
    visible?: boolean;

    constructor(projectKey?: string, sequence?: number, key?: string) {
        this.type = NodeType.Gate;
        this.key = key || null;
        this.project_key = projectKey;
        this.data = {};
        this.data.activities_required = sequence !== 0.001;
        if (!!sequence) {
            this.data.sequence = sequence;
        }
    }
}
