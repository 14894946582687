import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'includesByField',
    pure: true
})
export class IncludesByFieldPipe implements PipeTransform {

    transform(array: any[], field: string, value: string): boolean {
        return !!(array || []).find(item => item[field] === value);
    }

}
