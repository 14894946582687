import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from '@app/core/services/authentication.service';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { imagesResourceList } from '../background-images';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
    public backgroundImages = imagesResourceList;

    emailForm: FormGroup;
    email = new FormControl('', [Validators.required, Validators.email]);
    errorMessage: string;
    confirmation: boolean;
    isLoading: boolean;

    constructor(private authenticationService: AuthenticationService, private formBuilder: FormBuilder) {
        this.emailForm = this.formBuilder.group({
            email: this.email,
        });
    }

    ngOnInit(): void {
        this.authenticationService.isLoading().subscribe(value => {
            this.isLoading = value;
        });
        this.authenticationService.errorMessage().subscribe(error => {
            this.errorMessage = error;
        });
        this.authenticationService.returnConfirmation().subscribe(status => {
            this.confirmation = status;
        });
    }

    passwordReset() {
        this.authenticationService.resetPasswordInit(this.email.value);
    }
}
