import { createAction, props } from '@ngrx/store';
import { FeatureToggles } from '@app/core/models/feature-toggles.models';

export const loadFeatureToggles = createAction(
    '[FeatureToggles] Load FeatureToggles'
);

export const loadFeatureTogglesSuccess = createAction(
    '[FeatureToggles] Load FeatureToggles Success',
    props<{ featureToggles: FeatureToggles }>()
);

export const loadFeatureTogglesFailure = createAction(
    '[FeatureToggles] Load FeatureToggles Failure',
    props<{ errorMessage: string }>()
);
