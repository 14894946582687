import { Injectable } from '@angular/core';
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js';
import { Observable, Subscriber } from 'rxjs';
import { HmacSHA256, enc } from 'crypto-js';

import { environment } from '@env/environment';

class CognitoUserPoolWithSecret extends CognitoUserPool {
    private readonly clientSecret: string;
    private readonly clientId: string;
    private readonly userName: string;

    constructor(config) {
        super(config);
        const user = this.getCurrentUser();

        this.clientSecret = config.ClientSecret;
        this.clientId = config.ClientId;
        this.userName = user && user['username'];
        const client = this['client'];

        client.request = (operation, params, callback) => {
            const updatedParams = Object.assign({}, params);
            const headers = {
                'Content-Type': 'application/x-amz-json-1.1',
                'X-Amz-Target': 'AWSCognitoIdentityProviderService.' + operation,
                'X-Amz-User-Agent': client.userAgent,
            };

            if (this.clientSecret && updatedParams.AuthParameters) {
                const message = this.userName + this.clientId;
                const secretHash = HmacSHA256(message, this.clientSecret).toString(enc.Base64);

                Object.assign(updatedParams, {
                    AuthParameters: Object.assign({}, updatedParams.AuthParameters, {
                        'SECRET_HASH': secretHash
                    })
                });
            }

            const options = {
                headers: headers,
                method: 'POST',
                mode: <RequestMode>'cors',
                cache: <RequestCache>'no-cache',
                body: JSON.stringify(updatedParams),
            };

            let response = void 0;
            let responseJsonData = void 0;

            fetch(client.endpoint, options)
                .then(
                    function (resp) {
                        response = resp;

                        return resp;
                    },
                    function (err) {
                        // If error happens here, the request failed
                        // if it is TypeError throw network error
                        if (err instanceof TypeError) {
                            throw new Error('Network error');
                        }
                        throw err;
                    },
                )
                .then(function (resp) {
                    return resp.json().catch(function () {
                        return {};
                    });
                })
                .then(function (data) {
                    // return parsed body stream
                    if (response.ok) {
                        return callback(null, data);
                    }
                    responseJsonData = data;

                    // Taken from aws-sdk-js/lib/protocol/json.js
                    // eslint-disable-next-line no-underscore-dangle
                    const code = (data.__type || data.code).split('#').pop();
                    const error = {
                        code: code,
                        name: code,
                        message: data.message || data.Message || null,
                    };

                    return callback(error);
                })
                .catch(function (err) {
                    // first check if we have a service error
                    if (response && response.headers && response.headers.get('x-amzn-errortype')) {
                        try {
                            const code = response.headers.get('x-amzn-errortype').split(':')[0];
                            const error = {
                                code: code,
                                name: code,
                                statusCode: response.status,
                                message: response.status ? response.status.toString() : null,
                            };

                            return callback(error);
                        } catch (ex) {
                            return callback(err);
                        }
                        // otherwise check if error is Network error
                    } else if (err instanceof Error && err.message === 'Network error') {
                        const _error = {
                            code: 'NetworkError',
                            name: err.name,
                            message: err.message,
                        };

                        return callback(_error);
                    } else {
                        return callback(err);
                    }
                });
        };
    }
}

@Injectable()
export class CognitoUtilityService {
    private readonly userPoolId: string;
    private readonly clientId: string;
    private readonly clientSecret: string;
    private userPool: CognitoUserPool;

    constructor() {
        const awsConfig = environment['aws'];

        if (!awsConfig) {
            return;
        }

        this.userPoolId = awsConfig.cognito.userPoolId;
        this.clientId = awsConfig.cognito.clientId;
        this.clientSecret = awsConfig.cognito.clientSecret;
        this.userPool = new CognitoUserPoolWithSecret({
            UserPoolId: this.userPoolId,
            ClientId: this.clientId,
            ClientSecret: this.clientSecret,
        });
    }

    getCurrentUser(): Observable<CognitoUser> {
        return new Observable((observer: Subscriber<any>) => {
            const currentUser = this.userPool.getCurrentUser();

            if (currentUser) {
                currentUser.getSession((error, result) => {
                    if (error) {
                        observer.error(error);

                        return;
                    }
                    observer.next(currentUser);
                    observer.complete();
                });
            } else {
                observer.error(new Error('Current user does not exist'));
            }
        });
    }
}
