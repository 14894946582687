<div *ngIf="!!numberOfPages" class="paginator">
  <button class="button prev" [disabled]="!hasPreviousPage" (click)="setPerviousPage()">
    <mat-icon>arrow_back_ios</mat-icon>
    <span>Previous</span>
  </button>

  <div class="pages">
    <ng-container *ngFor="let page of data; trackBy: trackByIndex">
      <div
        class="page"
        [ngClass]="{
          active: page.index === activePageIndex,
          dots: page.value === '...'
        }"
        (click)="setActivePage(page)"
      >
        {{ page.label }}
      </div>
    </ng-container>
  </div>

  <button class="button next" [disabled]="!hasNextPage" (click)="setNextPage()">
    <span>Next</span>
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>
</div>
