import { FormItemNode } from './form-item-node.model';
import { FormItemType } from './form-view.model';
import { GraphService } from '../services/graph.service';

export class TextQuestionNode extends FormItemNode {

    constructor(textQuestionType: FormItemType, deploymentId: string, nodeId: string, data: object, graphService: GraphService) {
        super(deploymentId, nodeId, data, graphService);
        this.type = textQuestionType;
    }

}
