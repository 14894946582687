import { BaseStateModel } from '@app/root-store/models/base-state.model';
import { FormSummaryData } from '@app/core/models/form-summary.model';

export interface FormSummaryState extends BaseStateModel {
    summary: FormSummaryData[];
}

export const initialState: FormSummaryState = {
    loading: false,
    errorMessage: null,
    summary: []
};
