<div
  class="image"
  [ngStyle]="{
    'background-image': 'url(' + deploymentTemplateImage + ')'
  }"
  [ngClass]="[
    backgroundColorIndex ? 'card-order-' + backgroundColorIndex : '',
    !deploymentTemplateImage ? 'default-bg' : 'white-bg',
    isSmall ? 'small' : ''
  ]"
></div>
