import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

const defaultColors = {
    default: '#6572A7',
    ungrouped: '#fff',
    border: '#dfdfdf'
};

@Component({
    selector: 'app-tt-list-item',
    templateUrl: './tt-list-item.component.html',
    styleUrls: ['./tt-list-item.component.scss']
})

export class TtListItemComponent implements OnInit {
    @Input() index: number;
    @Input() active: boolean;
    @Input() color: string;
    @Input() count?: string;
    @Input() isSmallSized?: boolean;
    @Output() onClick: EventEmitter<any> = new EventEmitter<any>();
    activeStyle: { background: string, border: string };

    constructor() {
        this.active = false;
        this.color = defaultColors.default;
    }

    listItemClick($event: Event): void {
        this.onClick.emit({
            event: $event,
            index: this.index
        });
    }

    ngOnInit() {
        const borderColor = this.color === defaultColors.ungrouped ? defaultColors.border : this.color;
        this.activeStyle = {
            background: this.color,
            border: `1px solid ${borderColor}`
        };
    }
}
