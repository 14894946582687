import { Observable } from 'rxjs';
import { Ownership, ObjectType, UserRole } from '../models';

export interface OwnershipService {
    getDeploymentOwnership(deploymentId: string): Observable<Ownership[]>;

    getOwnershipForUserForObject(deploymentId: string, userId: string, objectId: string): Observable<any>;

    getDeploymentOwnersWithUserName(deploymentId: string): Observable<Ownership[]>;

    updateOwnership(userId: string, deploymentId: string, objectId: string, objectType: ObjectType, role: UserRole, demographicId?: string): Promise<void>;

    updateOwnershipPrimaryVolunteer(deploymentId: string, newPrimaryOwnerId: string, objectId: string, demographicId?: string): Promise<void>;

    updateOwnershipPrimary(primaryOwnershipId: string, previousOwnershipId: string, deploymentId: string): Promise<void>;

    deleteUserRoleFromSubprocess(userId: string, deploymentId: string, subprocessId: string, demographicId: string, role: UserRole): Promise<void>;

    deleteUserRoleFromOwnership(userId: string, deploymentId: string, objectId: string, demographicId?: string): Promise<void>;

    getGroupedUsersByDeploymentId(deploymentId: string, objectId: string): Observable<Ownership[]>;
}
