export type FilestackUploadDoneFn = (uploadResult: FilestackUploadResult) => void;

export type FilestackUploadStartedFn = () => void;

export type FilestackUploadFailedFn = () => void;

export enum FilestackStorage {
    GoogleStorage = 'GoogleStorage',
    FirebaseStorage = 'FirebaseStorage'
}

export enum FilestackUploadType {
    Files = 'Files',
    Images = 'Images',
    EXCELFiles = 'EXCELFiles',
    PresentationFiles = 'PresentationFiles'
}

export interface FilestackUploadOptions {
    onUploadDone: FilestackUploadDoneFn; // success upload callback
    onUploadStarted?: FilestackUploadStartedFn; // start upload callback
    onUploadFailed?: FilestackUploadFailedFn; // failed upload callback
    storeTo?: FilestackStorage; // location where to store files; default = FilestackStorage.GoogleStorage
    uploadType?: FilestackUploadType; // images only or all allowed files; default = FilestackUploadType.Files
    fbFolder?: string; // folder name in firebase storage
    gsFolder?: string; // folder name in google storage
}

export interface FilestackUploadResult {
    url: string;
    fileName?: string;
    filestackFileId?: string;
    filestackFileUrl?: string;
}
