import { Directive, Input } from '@angular/core';
import { Host, Self, Optional } from '@angular/core';
import { MatSelect } from '@angular/material/select';

@Directive({
    selector: '[appSelectAllCaption]'
})
export class SelectAllCaptionDirective {
    @Input() appSelectAllCaption: string;

    constructor(
        @Host() @Self() @Optional() public hostSelector: MatSelect
    ) {

        // override default behavior of showing chosen values in the dropdown with enabled multiple option
        // there was added possibility to show custom text when all options was selected
        // instead of list of these options
        Object.defineProperty(hostSelector, 'triggerValue', {
            get: () => {
                const _multiple = (<any>hostSelector)._multiple;
                const _selectionModel = (<any>hostSelector)._selectionModel;
                const _isRtl = (<any>hostSelector)._isRtl.bind(hostSelector);
                const allOptions = (<any>hostSelector).options;

                if (hostSelector.empty) {
                    return '';
                }

                if (_multiple) {
                    const selectedOptions = _selectionModel.selected.map(option => option.viewValue);

                    if (_isRtl()) {
                        selectedOptions.reverse();
                    }

                    return (allOptions.length && allOptions.length === selectedOptions.length)
                        ? this.appSelectAllCaption
                        : selectedOptions.join(', ');
                }

                return _selectionModel.selected[0].viewValue;
            }
        });
    }
}
