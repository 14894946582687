import { Inject, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import { map, take, switchMap, distinctUntilChanged } from 'rxjs/operators';
import { Observable, combineLatest, of, firstValueFrom } from 'rxjs';
import { isEqual } from 'lodash';

import {
    DeploymentsUserService,
    User as CommonUser,
    UserRole as DeploymentUserRole,
} from '@thinktank/common-deployments';
import {
    User,
    AppUser,
    UserAccess,
    sessionInviteTypes,
    sessionRoles,
    appRoles,
    App,
    leaderRoles,
    UserManagementRoles,
} from '@app/core/models';
import { mergeSortByAlphabetic } from '@app/core/utils';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseAppService } from '@app/core/services/app.service';

@Injectable()
export class FirebaseUsersService extends FirebaseUtilityService implements DeploymentsUserService {
    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private appService: FirebaseAppService,
        private angularFireFunctions: AngularFireFunctions,
        @Inject('Window') private window: Window,
    ) {
        super();
    }

    getUserByEmail(email: string): Observable<CommonUser> {
        return this.db
            .list('/users', ref => ref.orderByChild('email').equalTo(email.toLowerCase()))
            .snapshotChanges()
            .pipe(
                map(users => {
                    // There should only be 1 entry here
                    const userData = users[0] && (users[0].payload.val() as CommonUser);

                    return users.length > 0 ? { ...userData, id: users[0].key } : null;
                }),
            );
    }

    getAlphabeticallySortedSessionUsers(instanceKey: string): Observable<User[]> {
        return this.getInstanceUsers(instanceKey).pipe(
            map(users => {
                return users
                    .filter(user => {
                        const userRole = (user['app_instances'] || {})[instanceKey];

                        return userRole && userRole !== 'observer';
                    })
                    .sort((a, b) => {
                        const aUserName = User.getUserName(a).toLowerCase();
                        const bUserName = User.getUserName(b).toLowerCase();

                        if (aUserName < bUserName) {
                            return -1;
                        } else if (aUserName > bUserName) {
                            return 1;
                        } else {
                            return 0;
                        }
                    });
            }),
        );
    }

    getSessionUsers(instanceKey: string): Observable<{ [userKey: string]: User }> {
        return this.objectWithKey<App>(this.db.object<App>(`/ssot/_apps/${instanceKey}`)).pipe(
            switchMap(session => {
                const userObservables = Object.keys(session.member_ids || {}).map(memberId =>
                    this.objectWithKey<User>(this.db.object<User>(`/users/${memberId}`)),
                );

                return userObservables.length === 0
                    ? of({})
                    : combineLatest(userObservables).pipe(
                          distinctUntilChanged((prevUsers, currentUsers) => {
                              if (prevUsers.length !== currentUsers.length) {
                                  return false;
                              }

                              const usersCollection = {};

                              // When at least one of the app_instances is changed return false
                              return ![...prevUsers, ...currentUsers].some(user => {
                                  if (!usersCollection[user.key]) {
                                      usersCollection[user.key] = user.app_instances;
                                  } else {
                                      return !(
                                          JSON.stringify(usersCollection[user.key]) ===
                                          JSON.stringify(user.app_instances)
                                      );
                                  }
                              });
                          }),
                          map(users =>
                              users.reduce(
                                  (acc, user) => ({
                                      ...acc,
                                      [user.key]: user,
                                  }),
                                  {},
                              ),
                          ),
                      );
            }),
        );
    }

    getAuthenticatedSessionUsers(instanceKey: string): Observable<User[]> {
        return this.getAlphabeticallySortedSessionUsers(instanceKey).pipe(
            map(users => users.filter(user => !user.disabled)),
        );
    }

    getAppUserList(appKey: string): Observable<User[]> {
        return this.objectWithKey(this.db.object<App>(`/ssot/_apps/${appKey}`)).pipe(
            switchMap(app => {
                const userObservables = Object.keys(app.ownership || {}).map(ownerId =>
                    this.objectWithKey<User>(this.db.object<User>(`/users/${ownerId}`)),
                );

                return userObservables.length === 0 ? of([]) : combineLatest(userObservables);
            }),
        );
    }

    getInstanceUsers(instanceKey: string): Observable<User[]> {
        return this.objectWithKey<App>(this.db.object<App>(`/ssot/_apps/${instanceKey}`)).pipe(
            switchMap(session => {
                const userObservables = Object.keys(session.member_ids || {}).map(memberId =>
                    this.objectWithKey<User>(this.db.object<User>(`/users/${memberId}`)),
                );

                return combineLatest(userObservables);
            }),
        );
    } // from roster

    getInstanceUsersMap(instanceKey: string): Observable<{ [userKey: string]: User }> {
        return this.getInstanceUsers(instanceKey).pipe(
            map(users => {
                const usersMap = {};

                users.forEach(userInfo => {
                    usersMap[userInfo.key] = {
                        firstName: userInfo.firstName,
                        lastName: userInfo.lastName,
                        email: userInfo.email,
                    };
                });

                return usersMap;
            }),
            distinctUntilChanged((currentUsersMap, previousUsersMap) => isEqual(currentUsersMap, previousUsersMap)),
        );
    }

    getRegularUsersList(): Observable<User[]> {
        // update user nodes to add active and guest fields
        // it is used to correct show active or inactive status for regular users
        // and for hiding guest users in the case of already created users
        // it's enough to call this function once a time on some environment to fix model
        // so all further calls of this function will not apply changes in the database
        // because we have already fixed model of recently created users
        this.normalizeUserFields();

        // we have to use the object method to get data
        // because the list method may trigger phantom 'child_added' events
        // and this causes unlimited updates of the data
        // it's really strange and it is likely bug of firebase
        // TODO: add a link to web discussion about this issue or find another initial problem
        return this.db
            .object<{ [userKey: string]: User }>('/users')
            .valueChanges()
            .pipe(
                map(usersSnapshot => {
                    return Object.keys(usersSnapshot || {}).map((userKey: string) => {
                        const user = usersSnapshot[userKey];

                        return <User>{
                            ...user,
                            key: userKey,
                        };
                    });
                }),
                map(users => {
                    const regularUsers = users.filter((user: User) => !user.guest);

                    return mergeSortByAlphabetic(regularUsers, 'firstName').map(
                        user =>
                            <User>{
                                ...user,
                                role:
                                    !!user.permission && user.permission['admin'] ? UserAccess.admin : UserAccess.basic,
                                apps_role: this.getUserAppsRole(user),
                                status: User.getStatus(user),
                            },
                    );
                }),
            );
    }

    getUserAppsRole(user: User): UserManagementRoles {
        const allUserApps = { ...user.app_instances, ...user.apps };
        const allUniqueUserRoles = [...new Set(Object.values(allUserApps))];
        let hasLeaderRole = false;

        allUniqueUserRoles.every(role => {
            if (leaderRoles.includes(role)) {
                hasLeaderRole = true;

                return false;
            } else {
                return true;
            }
        });

        return hasLeaderRole ? UserManagementRoles.Leader : UserManagementRoles.Participant;
    }

    getUserFullName(userKey: string): Observable<string> {
        return this.db
            .object<User>(`/users/${userKey}`)
            .valueChanges()
            .pipe(
                take(1),
                map((user: User) => {
                    return user?.firstName ? `${user?.firstName} ${user?.lastName}` : user?.username;
                }),
            );
    }

    getUserInfo(userKey: string): Observable<User> {
        // getUserByKey
        return this.db.object<User>(`/users/${userKey}`).valueChanges().pipe(take(1));
    }

    // added for user profile management - editing user info
    getUserInfoWithChanges(userKey: string): Observable<User> {
        return this.db.object<User>(`/users/${userKey}`).valueChanges();
    }

    async handleUsers(listUserData: any[], instanceKey: string, instanceUrl: string, message?: string): Promise<void> {
        const result = {};
        const session = await this.appService.getApp(instanceKey).pipe(take(1)).toPromise();
        const sessionName = (session && session.name) || null;
        const sessionImage = (session && session.image_url) || null;
        const sender = this.authUserService.getCurrentUser();
        const senderName = User.getUserName(sender);
        const sideEffectPromises = listUserData.map(async userData => {
            const snapshot = await this.authUserService.getUserByUsername(userData.email).pipe(take(1)).toPromise();
            const isNewUser = snapshot.length === 0;
            const newMessageKey = this.db.createPushId();
            const userKey = isNewUser ? this.db.createPushId() : snapshot[0].key;
            const inviteUserRole = await this.addOrUpdateUser(userData, userKey, instanceKey, isNewUser);
            const disableEmailNotifications = (<User>(snapshot[0] || {})).disable_email_notifications;

            if (!disableEmailNotifications) {
                result[`/messages/${newMessageKey}`] = {
                    email: userData.email,
                    instanceUrl: instanceUrl,
                    type: sessionInviteTypes[inviteUserRole],
                    data: {
                        appName: sessionName,
                        sessionImage,
                        senderName,
                        comment: message || null,
                        instanceUrl: instanceUrl,
                        env: instanceUrl.split('/instance')[0],
                    },
                };
            }
        });

        await Promise.all(sideEffectPromises);
        await this.db.object('/').update(result);
    }

    async addOrUpdateUser(userData: any, userKey: string, instanceKey: string, isNewUser: boolean): Promise<string> {
        const updates = {};

        if (isNewUser) {
            const newUserData = {
                email: userData.email,
                username: userData.email,
                created_at: userData.created_at,
                app_instances: {
                    [instanceKey]: userData.role,
                },
                active: false,
            };

            updates[`/users/${userKey}`] = newUserData;
        } else {
            updates[`/users/${userKey}/created_at`] = userData.created_at;
            updates[`/users/${userKey}/app_instances/${instanceKey}`] = userData.role;
        }

        updates[`/apps/${instanceKey}/members/${userKey}`] = userData.role;
        updates[`/ssot/_apps/${instanceKey}/member_ids/${userKey}`] = userData.role;

        await this.db.object('/').update(updates);

        return userData.role;
    }

    async addOrUpdateDeploymentUser(
        deploymentId: string,
        email: string,
        userKey: string,
        isNewUser = false,
    ): Promise<string> {
        const updates = {};
        const userId = userKey || this.db.createPushId();

        if (isNewUser) {
            updates[`users/${userId}`] = {
                email: email,
                username: email,
                created_at: firebase.database.ServerValue.TIMESTAMP,
                active: false,
                deployments: { [deploymentId]: true },
            };
        } else {
            updates[`users/${userId}/deployments/${deploymentId}`] = true;
        }
        await this.db.object('/').update(updates);

        return userId;
    }

    removeUserFromInstance(userKey: string, instanceKey: string): void {
        const dataToRemove = {};

        dataToRemove[`users/${userKey}/app_instances/${instanceKey}`] = null;
        dataToRemove[`apps/${instanceKey}/members/${userKey}`] = null;
        dataToRemove[`/ssot/_apps/${instanceKey}/member_ids/${userKey}`] = null;
        dataToRemove[`/ssot/_demographics_submit_info/${instanceKey}/participants/${userKey}`] = null;
        this.db.object('/').update(dataToRemove);
    }

    getUserRole(userKey: string, instanceKey: string): Promise<any> {
        return this.db.object(`users/${userKey}/app_instances/${instanceKey}`).valueChanges().pipe(take(1)).toPromise();
    }

    async addUserToInstance(userKey: string, instanceKey: string): Promise<void> {
        const role = await this.getUserRole(userKey, instanceKey);
        let roleToUse = 'participant';

        if (role) {
            roleToUse = role;
        }

        // We have to do this update first so we can use the security rules in /apps to validate the user is in the session
        await this.db.object(`/apps/${instanceKey}/members`).update({
            [userKey]: roleToUse,
        });

        const dataToAdd = {};

        dataToAdd[`users/${userKey}/app_instances/${instanceKey}`] = roleToUse;
        dataToAdd[`ssot/_apps/${instanceKey}/member_ids/${userKey}`] = roleToUse;

        return this.db.object('/').update(dataToAdd);
    }

    async addGuestUser(pushId: string, userData: any, appKey: string): Promise<void> {
        const dataToAdd = {};

        dataToAdd[`users/${pushId}`] = userData;
        dataToAdd[`apps/${appKey}/members/${pushId}`] = 'participant';

        return this.db.object('/').update(dataToAdd);
    }

    async addGuestDeploymentUser(emailAddress: string): Promise<string> {
        const newUserId = this.db.createPushId();
        const userData = {
            email: emailAddress,
            guest: true,
            created_at: Date.now(),
            disabled: false,
        };

        await this.db.object(`/users/${newUserId}`).set(userData);

        return newUserId;
    }

    async addDeployment(userId: string, deploymentId: string, role: DeploymentUserRole): Promise<void> {
        return this.db.object(`/users/${userId}/deployments`).update({
            [deploymentId]: role,
        });
    }

    async changeUserRole(
        isApp: boolean,
        user: User | AppUser,
        appKey: string,
        newRole: string,
        previousRole: string,
    ): Promise<void> {
        const dataToAdd = {};
        const appOrSessionDir = isApp ? 'apps' : 'app_instances';

        // For app users get user_id as a key
        const userEmail = user.username;
        const userKey = isApp ? (<AppUser>user).user_id : (<User>user).key;

        if (isApp) {
            dataToAdd[`ssot/appSharing/${user.key}/role`] = newRole;
            dataToAdd[`apps/${appKey}/ownership/${userKey}/role`] = newRole;
            dataToAdd[`ssot/_apps/${appKey}/ownership/${userKey}/role`] = newRole;
            dataToAdd[`ssot/_apps/${appKey}/member_ids/${userKey}`] = newRole;
        } else {
            dataToAdd[`apps/${appKey}/members/${userKey}`] = newRole;
            dataToAdd[`ssot/_apps/${appKey}/member_ids/${userKey}`] = newRole;
        }

        dataToAdd[`users/${userKey}/${appOrSessionDir}/${appKey}`] = newRole;
        dataToAdd[`ssot/_users/${userKey}/${appOrSessionDir}/${appKey}`] = newRole;

        // send email notification
        const app = await this.appService.getApp(appKey).pipe(take(1)).toPromise();
        const appName = app && app.name;
        const appImage = app && app.image_url;
        const messageKey = this.db.createPushId();
        const roles = isApp ? appRoles : sessionRoles;
        const instanceUrl = `${window.location.href.split(appKey)[0]}${appKey}`;
        const env = isApp ? instanceUrl.split('/app')[0] : instanceUrl.split('/instance')[0];
        const disableEmailNotifications = isApp
            ? (await this.getUserInfo((<AppUser>user).user_id).toPromise()).disable_email_notifications
            : (<User>user).disable_email_notifications;

        if (!disableEmailNotifications) {
            dataToAdd[`/messages/${messageKey}`] = {
                email: userEmail,
                type: isApp ? 'AppRoleChange' : 'SessionRoleChange',
                data: {
                    previousRole: roles[previousRole],
                    newRole: roles[newRole],
                    appName,
                    appImage,
                    sessionImage: appImage,
                    instanceUrl,
                    env,
                    [newRole]: true, // to detect the description for current role
                },
            };
        }

        await this.db.object('/').update(dataToAdd);
    }

    changeUserName(userKey: string, firstName: string, lastName: string): Promise<any> {
        const changeUserName = {};

        changeUserName[`users/${userKey}/firstName`] = firstName;
        changeUserName[`users/${userKey}/lastName`] = lastName;

        return this.db.object('/').update(changeUserName);
    }

    updateUsersDisabled(usersKeys: string[], disabled: boolean): Promise<any> {
        const updates = {};

        usersKeys.forEach(userKey => {
            updates[`users/${userKey}/disabled`] = disabled;
        });

        return this.db.object('/').update(updates);
    }

    updateUserField(userKey: string, field: string, value: any): Promise<any> {
        const updates = {};

        updates[`users/${userKey}/${field}`] = value;

        return this.db.object('/').update(updates);
    }

    verifyEmail(email: string): Promise<boolean> {
        return firstValueFrom(this.angularFireFunctions.httpsCallable('verifyUserEmail')({ email })).then(
            result => result.userEmailVerified,
        );
    }

    async exportUsers(env: string, userKey: string): Promise<void> {
        try {
            const timezone = new Date().getTimezoneOffset();
            const data = {
                userId: this.authUserService.getCurrentUserId(),
                env,
                timezone,
                userKey,
            };

            await this.angularFireFunctions.httpsCallable('exportUsers')(data).toPromise();
        } catch (err) {
            console.log('Error while exporting users!');
            console.error({ err });
        }
    }

    private async normalizeUserFields(): Promise<void> {
        return this.listWithKeys<User>(this.db.list<User>('users'))
            .pipe(take(1))
            .toPromise()
            .then((users: User[]) => {
                const updates = users.reduce((result: any, user: User) => {
                    const isGuestWithoutEmail = !!user.username && !user.username.includes('@');
                    const isGuestWithEmail =
                        !user.firebaseAuthenticationId &&
                        !user.authenticationId &&
                        !!user.username &&
                        user.username.includes('@') &&
                        !!user.firstName;
                    const isGuest = isGuestWithoutEmail || isGuestWithEmail;

                    if (!('active' in user)) {
                        const isActive = !!user.firebaseAuthenticationId || !!user.authenticationId || isGuest;

                        result[`users/${user.key}/active`] = isActive;
                    }

                    if (!('guest' in user) && isGuest) {
                        result[`users/${user.key}/guest`] = isGuest;
                    }

                    return result;
                }, {});

                return this.db.object('/').update(updates);
            });
    }
}
