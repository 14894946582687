import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { reducer } from './reducers';
import { WorkbooksEffects } from './effects';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        StoreModule.forFeature('workbooks', reducer),
        EffectsModule.forFeature([WorkbooksEffects])
    ],
    exports: [],
    providers: [],
})
export class WorkbooksStateModule { }
