import { createReducer, on, Action } from '@ngrx/store';

import * as FormsActions from '../actions';
import { initialState, FormsState } from '../state';

const FormsReducer = createReducer(
    initialState,
    on(FormsActions.getActivityFormsKeys, (state) => {
        return ({
            ...state,
            loading: true,
            errorMessage: null
        });
    }),
    on(FormsActions.getActivityFormsKeysSuccess, (state, { activityKey, formsKeys }) => {
        state.formsKeysByActivityKey[activityKey] = [...formsKeys];

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(FormsActions.getActivityFormsKeysFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(FormsActions.getActivityParentFormKey, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(FormsActions.getActivityParentFormKeySuccess, (state, { activityKey, formKey }) => {
        state.parentFormByActivityKey[activityKey] = {
            formKey,
            isFormActivitySubmitted: false
        };

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(FormsActions.getActivityParentFormKeyFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(FormsActions.getActivityFormStatuses, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(FormsActions.getActivityFormStatusesSuccess, (state, { activityKey, formStatuses }) => {
        state.formStatusByActivityKey[activityKey] = formStatuses;

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(FormsActions.getActivityFormStatusesFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(FormsActions.getBranchingVisibility, (state) => (
        {
            ...state,
            loading: true,
            errorMessage: null
        }
    )),
    on(FormsActions.getBranchingVisibilitySuccess, (state, { activityKey, formBranchingVisibility }) => {
        state.formBranchingVisibility[activityKey] = formBranchingVisibility;

        return ({
            ...state,
            loading: false,
            errorMessage: null
        });
    }),
    on(FormsActions.getBranchingVisibilityFail, (state, { errorMessage }) => (
        {
            ...state,
            loading: false,
            errorMessage: errorMessage
        }
    )),
    on(FormsActions.getIsErrorsVisible, (state, { activityKey, isFormActivitySubmitted }) => {
        state.parentFormByActivityKey[activityKey] = {
            ...state.parentFormByActivityKey[activityKey],
            isFormActivitySubmitted
        };

        return ({
            ...state
        });
    }),
    on(FormsActions.searchFormResponses, (state) => (
        {
            ...state,
            loadingForSearchResponses: true,
            isSearchResponsesActive: false,
            errorMessage: null,
        }
    )),
    on(FormsActions.searchFormResponsesSuccess, (state, { foundResponses }) => (
        {
            ...state,
            foundResponsesViaSearch: [...foundResponses],
            isSearchResponsesActive: true,
            loadingForSearchResponses: false,
            errorMessage: null,
        }
    )),
    on(FormsActions.searchFormResponsesFail, (state, { errorMessage }) => (
        {
            ...state,
            loadingForSearchResponses: false,
            errorMessage: errorMessage
        }
    )),
    on(FormsActions.clearSearchFormResponses, (state) => (
        {
            ...state,
            loadingForSearchResponses: false,
            isSearchResponsesActive: false,
            foundResponsesViaSearch: [],
            isNavigationActive: false,
        }
    )),
    on(FormsActions.increaseСurrentFoundResponse, (state) => (
        {
            ...state,
            currentResponseNumber: (state.currentResponseNumber + state.foundResponsesViaSearch.length + 1) % state.foundResponsesViaSearch.length,
        }
    )),
    on(FormsActions.decreaseСurrentFoundResponse, (state) => {
        if(state.currentResponseNumber === -1) {
            return {
                ...state,
                currentResponseNumber: (state.currentResponseNumber + state.foundResponsesViaSearch.length) % state.foundResponsesViaSearch.length,
            }
        }

        return {
            ...state,
            currentResponseNumber: (state.currentResponseNumber + state.foundResponsesViaSearch.length - 1) % state.foundResponsesViaSearch.length,
        }
    }),
    on(FormsActions.setCurrentFoundResponse, (state, { currentResponseNumber }) => (
        {
            ...state,
            currentResponseNumber
        }
    )),
    on(FormsActions.setNavigationActive, (state) => (
        {
            ...state,
            isNavigationActive: true,
        }
    )),
    on(FormsActions.setNavigationDisactive, (state) => (
        {
            ...state,
            isNavigationActive: false,
        }
    )),
);

export function reducer(state: FormsState | undefined, action: Action) {
    return FormsReducer(state, action);
}
