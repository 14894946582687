import { createReducer, on, Action } from '@ngrx/store';

import * as FormSummaryActions from '../actions';
import { initialState, FormSummaryState } from '../state';

const formSummaryReducer = createReducer(
    initialState,
    on(FormSummaryActions.getFormSummary, (state) => {
        return ({
            ...state,
            summary: [],
            loading: true
        });
    }),
    on(FormSummaryActions.getFormSummarySuccess, (state, { summary }) => {
        return ({
            ...state,
            summary,
            loading: false
        });
    }),
    on(FormSummaryActions.clearFormSummaryState, (state) => {
        return ({
            ...state,
            summary: [],
            loading: false
        });
    }),
);

export function reducer(state: FormSummaryState | undefined, action: Action) {
    return formSummaryReducer(state, action);
}
