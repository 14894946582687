import {
    Component,
    Input,
    OnInit,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    OnChanges,
    SimpleChanges
} from '@angular/core';

import { GateData } from '@thinktank/common-lib';

@Component({
    selector: 'app-gate',
    templateUrl: './gate.component.html',
    styleUrls: ['../task-gate.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class GateComponent implements OnInit, OnChanges {
    @Input() data: GateData;
    @Input() projectKey: string;
    @Input() emulate?: boolean;
    @Input() isLeader: boolean;
    @Input() isApp: boolean;
    @Output() checkboxClick = new EventEmitter<void>();
    @Output() gateCompleted = new EventEmitter<void>();

    expanded: boolean;
    startDate: Date;
    endDate: Date;

    constructor() { }

    get showExpandIcon(): boolean {
        return !this.emulate && !!this.data.details;
    }

    get showCompleteGateButton(): boolean {
        return (this.isApp || this.isLeader) && !this.emulate;
    }

    toggleExpanded(): void {
        this.expanded = !this.expanded;
    }

    toggleCompleted(): void {
        if (this.emulate) {
            this.checkboxClick.emit();
        }
    }

    completeGate(): void {
        this.gateCompleted.emit();
    }

    ngOnInit() {
        this.expanded = !this.emulate && !!this.data.details;
    }

    ngOnChanges(changes: SimpleChanges): void {

        if (changes.data) {
            const startDateTimestamp = this.data && this.data.start_date;
            const endDateTimestamp = this.data && this.data.end_date;
            this.startDate = startDateTimestamp && startDateTimestamp.toDate();
            this.endDate = endDateTimestamp && endDateTimestamp.toDate();
        }
    }
}
