import OrderByDirection = firebase.firestore.OrderByDirection;
import { Injectable } from '@angular/core';
import {
    AngularFirestore,
    DocumentChangeAction,
    CollectionReference,
    DocumentReference
} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { Project, Node, NodeType } from '../models';

export interface FanOutWrite {
    path: string;
    data?: any;
}

@Injectable({
    providedIn: 'root'
})
export class FirestoreService {

    constructor(
        private firestore: AngularFirestore
    ) {
    }

    arrayUnion(value: any): firebase.firestore.FieldValue {
        return firebase.firestore.FieldValue.arrayUnion(value);
    }

    changeCounterValue(value: number): firebase.firestore.FieldValue {
        return firebase.firestore.FieldValue.increment(value);
    }

    runTransaction<T>(
        updateFunction: (transaction) => Promise<T>
    ): Promise<T> {
        return this.firestore.firestore.runTransaction(updateFunction);
    }

    createBatch() {
        return this.firestore.firestore.batch();
    }

    get deleteField() {
        return firebase.firestore.FieldValue.delete();
    }

    delete(ref: string): Promise<void> {
        return this.firestore.doc(ref).delete();
    }

    getPushId(): string {
        return this.firestore.createId();
    }

    get firestoreRef(): AngularFirestore {
        return this.firestore;
    }

    get timestamp(): firebase.firestore.FieldValue {
        return firebase.firestore.FieldValue.serverTimestamp();
    }

    dateToFirestoreTimestamp(date: Date): firebase.firestore.Timestamp {
        return firebase.firestore.Timestamp.fromDate(date);
    }

    async addDocument<T>(collectionPath: string, data: T): Promise<string> {
        return (await this.firestore.collection<T>(collectionPath).add(data)).id;
    }

    addDocumentWithKey<T>(collectionPath: string, key: string, data: T): Promise<void> {
        return this.set(`${collectionPath}/${key}`, data);
    }

    getDocumentByKey<T>(collectionPath: string, key: string): Observable<T> {
        return this.firestore.doc<T>(`${collectionPath}/${key}`)
            .snapshotChanges()
            .pipe(
                map((snapshot) => {
                    return snapshot.payload.exists
                        ? {
                            ...snapshot.payload.data() as T,
                            key: snapshot.payload.id,
                            id: snapshot.payload.id
                        }
                        : null;
                })
            );
    }

    getDocumentsByProperty<T>(path: string, property: string, value: any): Observable<T[]> {
        return this.firestore.collection<T>(path, ref => ref.where(property, '==', value))
            .snapshotChanges()
            .pipe(
                map(this.mapKeysToCollectionObjects)
            );
    }

    getDocumentsByArrayContains<T>(path: string, property: string, value: any): Observable<T[]> {
        return this.firestore.collection<T>(path, ref => ref.where(property, 'array-contains', value))
            .snapshotChanges()
            .pipe(
                map(this.mapKeysToCollectionObjects)
            );
    }

    getCollectionRef<T>(ref: string): CollectionReference {
        return this.firestore.collection<T>(ref).ref;
    }

    getCollection<T>(ref: string, sortByField?: string, sortDirection: OrderByDirection = 'asc'): Observable<T[]> {
        const collectionRef = sortByField
            ? this.firestore.collection<T>(ref, ref => ref.orderBy(sortByField, sortDirection))
            : this.firestore.collection<T>(ref);
        return collectionRef
            .snapshotChanges()
            .pipe(
                map(this.mapKeysToCollectionObjects)
            );
    }

    getDocumentRef<T>(ref: string): DocumentReference {
        return this.firestore.doc<T>(ref).ref;
    }

    getDocument<T>(ref: string): Observable<T> {
        return this.firestore.doc<T>(ref)
            .snapshotChanges()
            .pipe(
                map((snapshot) => {
                    return snapshot.payload.exists
                        ? {
                            ...snapshot.payload.data() as T,
                            key: snapshot.payload.id,
                            id: snapshot.payload.id
                        }
                        : null;
                })
            );
    }

    getProjectNodesByType<T extends Node>(projectKey: string, type: NodeType): Observable<T[]> {
        const path = `/graph/${projectKey}/nodes`;
        return this.firestore.collection<T>(path, ref => ref.where('type', '==', type))
            .snapshotChanges()
            .pipe(
                map(this.mapKeysToCollectionObjects)
            );
    }

    update<T>(ref: string, data: T): Promise<void> {
        return this.firestore.doc(ref).set(data, { merge: true });
    }

    updateDoc<T>(ref: string, data: T): Promise<void> {
        return this.firestore.doc(ref).update(data);
    }

    async upsert<T>(ref: string, data: T): Promise<void> {
        const existingDocument = await this.firestore.doc(ref)
            .snapshotChanges()
            .pipe(take(1))
            .toPromise();
        return existingDocument.payload.exists ? this.update(ref, data) : this.set(ref, data);
    }

    async upsertNode<T extends Node>(data: T): Promise<void | DocumentReference> {
        await this.verifyProjectExists(data.project_key);
        return data.key ? this.updateNode(data) : this.addNode(data);
    }

    writeBatch(fanOutWrites: FanOutWrite[]): Promise<void> {
        const batch = this.createBatch();
        fanOutWrites.forEach((fanOutWrite) => {
            batch.set(this.firestore.firestore.doc(fanOutWrite.path), fanOutWrite.data, { merge: true });
        });
        return batch.commit();
    }

    deleteBatch(fanOutDeletes: FanOutWrite[]): Promise<void> {
        const batch = this.createBatch();
        fanOutDeletes.forEach((fanOutDelete) => {
            if (fanOutDelete.data) {
                const propertyToDelete = { [fanOutDelete.data]: this.deleteField };
                batch.update(this.firestore.firestore.doc(fanOutDelete.path), propertyToDelete);
            } else {
                batch.delete(this.firestore.firestore.doc(fanOutDelete.path))
            }
        });
        return batch.commit();
    }

    private mapKeysToCollectionObjects<T>(actions: DocumentChangeAction<T>[]): T[] {
        return actions.map((action: DocumentChangeAction<T>) => {
            const data = action.payload.doc.data() as T;
            const key = action.payload.doc.id;
            return {
                ...data,
                key,
                id: key
            };
        });
    }

    private addNode<T extends Node>(data: T): Promise<DocumentReference> {
        const timestamp = this.timestamp;
        const ref = `/graph/${data.project_key}/nodes`;
        return this.firestore.collection<T>(ref).add({
            ...data,
            updated: timestamp,
            created: timestamp
        });
    }

    private set<T>(ref: string, data: T): Promise<void> {
        return this.firestore.doc(ref).set(data);
    }

    private updateNode<T extends Node>(data: T): Promise<void> {
        const ref = `/graph/${data.project_key}/nodes/${data.key}`;
        return this.firestore.doc(ref).update({
            ...data,
            updated: this.timestamp,
        });
    }

    private async verifyProjectExists(projectKey: string): Promise<void> {
        const project = await this.firestore.doc<Project>(`/graph/${projectKey}`)
            .snapshotChanges()
            .pipe(take(1))
            .toPromise();
        if (project.payload.exists) {
            return;
        }

        const timestamp = this.timestamp;
        return this.firestore.doc(`/graph/${projectKey}`).set({
            created: timestamp,
            updated: timestamp
        });
    }
}
