import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';

import { ApprovalRequestDialogComponent } from '@app/shared/dialogs/approval-request-dialog/approval-request-dialog.component';

@Component({
    selector: 'app-change-username-dialog',
    templateUrl: './change-username-dialog.component.html',
    styleUrls: ['./change-username-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class ChangeUsernameDialogComponent implements OnInit {
    firstName: FormControl;
    lastName: FormControl;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ApprovalRequestDialogComponent>,
    ) {}

    get isDisabled(): boolean {
        return (this.data.firstName || '').trim() ===  (this.firstName.value || '').trim()
            && (this.data.lastName || '').trim() === (this.lastName.value || '').trim()
            || this.firstName.invalid || this.lastName.invalid ;
    }

    onSubmit(): void {
        const { handleConfirm } = this.data;
        handleConfirm((this.firstName.value || '').trim(), (this.lastName.value || '').trim())
            .then(() => {
                this.dialogRef.close();
            });
    }

    ngOnInit() {
        this.firstName = new FormControl(this.data.firstName, [this.requiredTrimValidator]);
        this.lastName = new FormControl(this.data.lastName, [this.requiredTrimValidator]);
    }

    private get requiredTrimValidator(): ValidatorFn {
        return (control: AbstractControl): {[key: string]: boolean} | null => {
            const isValid = control.value && control.value.trim();
            return isValid ? null : {
                requiredTrim: false
            };
        };
    }
}
