import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { FormHistoryResponse, FormHistoryResponseComment } from '../../models';
import { trackById } from '../../utils';
import { noResponse } from './response-history-card.constants';

@Component({
    selector: 'gs-response-history-card',
    templateUrl: './response-history-card.component.html',
    styleUrls: ['./response-history-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponseHistoryCardComponent implements OnInit {
    @Input() currentUserId: string;
    @Input() currentUserFullName: string;
    @Input() response: FormHistoryResponse;
    @Input() comments: FormHistoryResponseComment[];
    @Input() hasDependentQuestions: boolean;
    @Input() shouldOpenResponseComments?: boolean;
    @Input() isUserLeader?: boolean;
    @Input() isFocused?: boolean;
    @Input() canFocus?: boolean;
    @Input() isCommentDisabled?: boolean;
    @Output() onToggleUpvote = new EventEmitter<string>();
    @Output() onDeleteComment = new EventEmitter<FormHistoryResponseComment>();
    @Output() onAddComment = new EventEmitter<string>();
    @Output() onUpdateComment = new EventEmitter<{ id: string, value: string }>();
    @Output() onToggleFocus = new EventEmitter<FormHistoryResponse>();

    commentControl = new FormControl('');

    commentsView: boolean;
    editCommentsMode: boolean;
    editableComment: FormHistoryResponseComment;

    trackById = trackById;

    constructor() { }

    get upvotesCount(): number {
        return this.response && (this.response.upvotes || []).length;
    }

    get isUpvoteCounterVisible(): boolean {
        return this.isUserLeader && !!this.upvotesCount;
    }

    get commentControlValue(): string {
        return this.commentControl && (this.commentControl.value || '').trim();
    }

    get canToggleFocus(): boolean {
        return this.canFocus
            && this.response
            && !this.response.current;
    }

    get isResponseEmpty(): boolean {
        return this.response.value === null;
    }

    get getResponse(): string {
        return this.isResponseEmpty ? noResponse : this.response.value;
    }

    isCurrentUserCommentOwner(commentOwnerId: string): boolean {
        return this.currentUserId === commentOwnerId;
    }

    editComment(comment: FormHistoryResponseComment): void {
        this.editableComment = comment;
        this.editCommentsMode = true;
        this.commentControl = new FormControl(comment.value);
    }

    submitComment(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        const value = this.commentControl.value.trim();

        if (!value) {
            return;
        }

        if (this.editCommentsMode) {
            this.onUpdateComment.emit({
                id: this.editableComment.id,
                value
            });
        } else {
            this.onAddComment.emit(value);
        }

        this.resetComment();
    }

    resetComment(event?: MouseEvent): void {
        if (event) {
            event.stopPropagation();
        }

        this.commentControl.reset();
        this.editCommentsMode = false;
        this.editableComment = null;
    }

    toggleCommentsView(event: MouseEvent): void {
        event.stopPropagation();
        this.commentsView = !this.commentsView;
    }

    updateResponseUpvote(event: MouseEvent): void {
        event.stopPropagation();
        this.onToggleUpvote.emit();
    }

    deleteComment(comment: FormHistoryResponseComment): void {
        this.onDeleteComment.emit(comment);
    }

    toggleFocus(): void {
        if (this.canToggleFocus) {
            const responseToRestore = !this.isFocused ? this.response : null;
            this.onToggleFocus.emit(responseToRestore);
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.isCommentDisabled && this.commentControl) {
            const action = this.isCommentDisabled ? 'disable' : 'enable';
            this.commentControl[action]();
        }
    }

    ngOnInit() {
        const isCurrentResponse = this.response && this.response.current;
        this.commentsView = this.shouldOpenResponseComments && isCurrentResponse;
        this.editCommentsMode = false;
    }
}
