import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[appInitialFocus]'
})

// directive is using to focus element on init
export class InitialFocusDirective implements OnInit {
    private focus = true;
    @Input() set appInitialFocus(condition: boolean) {
        this.focus = condition;
    }

    constructor(private el: ElementRef) {
    }

    ngOnInit() {
        if (this.focus) {
            // To fix Angular error: Expression has changed after it was checked.
            setTimeout(() => {
                this.el.nativeElement.focus();
            });
        }
    }

}
