import {
    Input,
    ViewChild,
    Component,
    OnChanges,
    ElementRef,
    SimpleChanges,
    ChangeDetectionStrategy, Inject
} from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { Demographic, trackById } from '@thinktank/common-lib';
import { ObjectType } from '../../../models';
import { DeploymentEditorFacade, DeploymentEditorFacadeToken } from '../../deployment-editor/deployment-editor-facade';

@Component({
    selector: 'gs-demographics-editor',
    templateUrl: './demographics-editor.component.html',
    styleUrls: ['./demographics-editor.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class DemographicsEditorComponent implements OnChanges {
    @Input() allDemographics?: Demographic[];
    @Input() demographics?: Demographic[];
    @Input() objectType: ObjectType;
    @Input() deploymentId: string;
    @Input() moduleId?: string;

    @ViewChild('search', {static: true}) search: ElementRef;
    trackById = trackById;
    preselectFilteredDemographics: Demographic[];
    selectedDemographics$ = new BehaviorSubject<Demographic[]>([]);
    allChecked: boolean;
    searchValue: string;

    constructor(
        @Inject(DeploymentEditorFacadeToken) private facade: DeploymentEditorFacade
    ) {
        this.allChecked = false;
    }

    get isDeployment(): boolean {
        return !!this.objectType && this.objectType === ObjectType.Deployment;
    }

    clearSearch(): void {
        this.searchValue = '';
        this.allChecked = false;
    }

    async selectGroupDemographics(event: {isChecked: boolean, demographics: Demographic[]}): Promise<void> {
        const { isChecked, demographics } = event;
        const demographicsToUpdate = [];

        if (isChecked) {
            // to reactive checkboxes update
            demographics.forEach(demographic => {
                if (!this.selectedDemographics$.getValue().some(item => item.id === demographic.id)) {
                    const selectedDemographics = this.selectedDemographics$.getValue();
                    this.selectedDemographics$.next([...selectedDemographics, demographic]);
                    demographicsToUpdate.push(demographic);
                }
            })
            for (const demographicToAdd of demographicsToUpdate) {
                await this.facade.updateDemographics(this.deploymentId, demographicToAdd, this.moduleId);
            }
        } else {
            // to reactive checkboxes update
            demographics.forEach(demographic => {
                const selectedDemographics = this.selectedDemographics$.getValue().filter(item => item.id !== demographic.id);
                this.selectedDemographics$.next(selectedDemographics);
            })
            for (const demographicToRemove of demographics) {
                await this.facade.removeDemographicsById(this.deploymentId, demographicToRemove.id, this.moduleId);
            }
        }
    }

    async selectDemographic(event: {isChecked: boolean, demographic: Demographic}): Promise<void> {
        const { isChecked, demographic } = event;

        if (!isChecked) {
            await this.removeDemographic(demographic);
            return;
        }

        const selectedDemographics = this.selectedDemographics$.getValue();
        this.selectedDemographics$.next([...selectedDemographics, demographic]);
        await this.facade.updateDemographics(this.deploymentId, demographic, this.moduleId);
    }

    async removeDemographic(demographic: Demographic): Promise<void> {
        const selectedDemographics = this.selectedDemographics$.getValue();

        // we can't unselect if only one demographic
        // now - prevent deleting from the database
        // will be updated with Edit page updates
        if (selectedDemographics.length === 1) {
            return;
        }

        const demographicsToUpdate = selectedDemographics.filter(item => item.id !== demographic.id);
        this.selectedDemographics$.next(demographicsToUpdate);
        await this.facade.removeDemographicsById(this.deploymentId, demographic.id, this.moduleId);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.demographics && !!this.demographics) {
            if (!this.selectedDemographics$.getValue().length) {
                this.selectedDemographics$.next(this.demographics);
            }
        }
    }
}
