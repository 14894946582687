<div *ngIf="(vm$ | async) as vm" class="module-editor">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div *ngIf="!vm.isLoading" class="module-editor-container">
    <div class="module-editor-title">
      <h5 class="h5">Module Countries</h5>
      <button class="module-editor-title-button gs-button-icon-flat gs-small" [routerLink]="['..']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>

    <gs-demographics-editor
      [demographics]="vm.moduleDemographics"
      [allDemographics]="vm.deploymentDemographics"
      [objectType]="objectType"
      [deploymentId]="vm.deploymentId"
      [moduleId]="vm.moduleId"
    ></gs-demographics-editor>
  </div>
</div>
