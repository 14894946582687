import { createAction, props } from '@ngrx/store';

import { WorkbookSheet } from '@app/core/models/workbook.model';
import { FormQuestion } from '@app/core/models';

const getWorkbookSheetsByActivityType = '[Workbooks] get workbookSheets by activity';
const getWorkbookSheetsByActivitySuccessType = '[Workbooks] get workbookSheets by activity success';
const getWorkbookSheetsByActivityFailType = '[Workbooks] get workbookSheets by activity fail';
// getFormSheetKey
const getFormSheetKeyType = '[Workbooks] get form sheet key';
const getFormSheetKeySuccessType = '[Workbooks] get form sheet key success';
const getFormSheetKeyFailType = '[Workbooks] get form sheet key fail';
// getQuestionSheetKey
const getQuestionSheetKeyType = '[Workbooks] get question sheet key';
const getQuestionSheetKeySuccessType = '[Workbooks] get question sheet key success';
const getQuestionSheetKeyFailType = '[Workbooks] get question sheet key fail';
// getFormQuestionsWithWorkbookSheet
const getFormQuestionsWithWorkbookSheetType = '[Workbooks] get form questions with sheet';
const getFormQuestionsWithWorkbookSheetSuccessType = '[Workbooks] get form questions with sheet success';
const getFormQuestionsWithWorkbookSheetFailType = '[Workbooks] get form questions with sheet fail';

export const getWorkbookSheetsByActivity = createAction(
    getWorkbookSheetsByActivityType,
    props<{ activityId: string }>()
);

export const getWorkbookSheetsByActivitySuccess = createAction(
    getWorkbookSheetsByActivitySuccessType,
    props<{ activityId: string, workbookSheets: WorkbookSheet[] }>()
);

export const getWorkbookSheetsByActivityFail = createAction(
    getWorkbookSheetsByActivityFailType,
    props<{ errorMessage: string }>()
);

// getFormSheetKey
export const getFormSheetKey = createAction(
    getFormSheetKeyType,
    props<{ formId: string }>()
);

export const getFormSheetKeySuccess = createAction(
    getFormSheetKeySuccessType,
    props<{ formId: string, formSheetKey: string }>()
);

export const getFormSheetKeyFail = createAction(
    getFormSheetKeyFailType,
    props<{ errorMessage: string }>()
);

// getQuestionSheetKey
export const getQuestionSheetKey = createAction(
    getQuestionSheetKeyType,
    props<{ questionId: string }>()
);

export const getQuestionSheetKeySuccess = createAction(
    getQuestionSheetKeySuccessType,
    props<{ questionId: string, questionSheetKey: string }>()
);

export const getQuestionSheetKeyFail = createAction(
    getQuestionSheetKeyFailType,
    props<{ errorMessage: string }>()
);

// getFormQuestionsWithWorkbookSheet
export const getFormQuestionsWithWorkbookSheet = createAction(
    getFormQuestionsWithWorkbookSheetType,
    props<{ questionId: string }>()
);

export const getFormQuestionsWithWorkbookSheetSuccess = createAction(
    getFormQuestionsWithWorkbookSheetSuccessType,
    props<{ questionId: string, questions: FormQuestion[] }>()
);

export const getFormQuestionsWithWorkbookSheetFail = createAction(
    getFormQuestionsWithWorkbookSheetFailType,
    props<{ errorMessage: string }>()
);
