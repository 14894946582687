<div class="select-demographics-for-export">
  <div mat-dialog-title>
    <div mat-dialog-title class="dialog-title">
      <button
        *ngIf="demographicClassIds.length > 1 && demographicsClassIndex > 0"
        mat-icon-button
        class="back"
        (click)="navigateBetweenClasses(false)"
      >
        <mat-icon>arrow_back</mat-icon>
      </button>
      <h3 class="h3">{{data.title}}</h3>
      <div *ngIf="demographicClassIds.length > 1" class="demographic-classes-counter">
        {{ demographicsClassIndex + 1 }} of {{ demographicClassIds.length }}
      </div>
      <button mat-icon-button mat-dialog-close class="close">
        <mat-icon>close</mat-icon>
      </button>
    </div>

    <mat-form-field appearance="outline" floatLabel="always" class="search-input">
      <input
        #search
        matInput
        type="search"
        placeholder="Search demographics"
        (input)="filterDemographics($event.target.value)"
      />
      <mat-icon *ngIf="!search.value" matSuffix>search</mat-icon>
      <mat-icon
        *ngIf="search.value"
        matSuffix
        class="close-icon"
        (click)="clearSearch()"
      >
        close
      </mat-icon>
    </mat-form-field>
  </div>

  <mat-dialog-content class="select-demographics-for-export-content thinktank-theme">
    <div class="loading" *ngIf="!backingDemographicsLength">
      <mat-spinner class="loading"></mat-spinner>
    </div>

    <div class="empty-search-results" *ngIf="backingDemographicsLength && !demographics.length">
      <mat-icon class="empty-search-results-icon">search</mat-icon>
      <p class="empty-search-results-text">No demographics found</p>
    </div>

    <div *ngIf="demographics.length" class="select-demographics-list">
      <ng-container *ngIf="!search.value">
        <div class="select-demographics-item">
          <mat-checkbox
            color="primary"
            [checked]="checkAllDemographicsSelected"
            (change)="toggleAllDemographic($event.checked, currentDemographicClassId)"
          >
            All
          </mat-checkbox>
        </div>
        <div class="select-demographics-item"></div>
      </ng-container>

      <div *ngFor="let demographic of demographics" class="select-demographics-item">
        <mat-checkbox
          color="primary"
          [value]="demographic.key"
          [checked]="demographic.selected_for_export"
          (change)="toggleDemographic($event.checked, demographic)"
        >
          {{demographic.name}}
        </mat-checkbox>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    class="actions"
  >
    <button
      *ngIf="!canShowExportButton"
      cdkFocusInitial
      class="tt-button-filled"
      [disabled]="checkSomeDemographicsSelected"
      (click)="navigateBetweenClasses(true)"
    >
      Next
    </button>
    <button
      *ngIf="canShowExportButton"
      cdkFocusInitial
      class="tt-button-filled"
      [disabled]="checkSomeDemographicsSelected"
      (click)="exportDemographic()"
    >
      {{ data.confirmBtnText }}
    </button>
    <button mat-dialog-close class="tt-button-outline">
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
