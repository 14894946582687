import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export abstract class DeploymentItem {
    id?: string;
    name: string;
    deployment_id: string;
    created?: firebase.firestore.Timestamp;
    updated?: firebase.firestore.Timestamp;
    global?: boolean;

    protected constructor(deploymentItem: DeploymentItem) {
        this.id = deploymentItem.id || '';
        this.name = deploymentItem.name;
        this.deployment_id = deploymentItem.deployment_id;
        this.created = deploymentItem.created;
        this.updated = deploymentItem.updated;
        this.global = deploymentItem.global;
    }

}
