<div *ngIf="(vm$ | async) as vm" class="assignment">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div class="assignment-container" *ngIf="!vm.isLoading">
    <h5 class="assignment-title h5">Assign Stakeholders</h5>

    <div class="assignment-block">
      <div class="form">
        <div *ngIf="useDemographics(vm.global, vm.objectType)" class="demographics-panel-container">
          <h6 class="h6">Select Countries</h6>
          <gs-demographics-panel
            [demographics]="vm.demographics"
            [processHaveSomeGlobalSubprocesses]="vm.processHaveSomeGlobalSubprocesses"
            (onUpdate)="onUpdateDemographics($event)"
          ></gs-demographics-panel>
        </div>
        <h6 class="h6">Assign Role: Owner <ng-container *ngIf="isSubprocess(vm.objectType)">vs. SME</ng-container></h6>
        <gs-invite-form
          class="gs-block"
          [type]="vm.objectType"
          [users]="vm.usersOfObject"
          [demographicsLength]="demographicsInviteEnabled(vm.global, vm.objectType)"
          [activeDemographicList]="activeDemographics"
          [deploymentUserList]="vm.deploymentUserList"
          [isGlobal]="vm.global"
          (onInvite)="invite($event, vm.deploymentId, vm.objectId, vm.objectType, vm.global)"
        ></gs-invite-form>
      </div>

      <div class="panel">
        <div class="users-title label gs-block-title">Assigned Users</div>
        <gs-users-panel
          [deploymentId]="vm.deploymentId"
          [objectId]="vm.objectId"
          [objectType]="vm.objectType"
          [users]="vm.usersOfObject"
          [useDemographics]="useDemographics(vm.global, vm.objectType)"
          [isGlobal]="vm.global"
          [processHaveSomeGlobalSubprocesses]="vm.processHaveSomeGlobalSubprocesses"
          [demographics]="vm.demographics"
        ></gs-users-panel>
      </div>
    </div>
  </div>
</div>
