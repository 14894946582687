import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';

@Injectable()
export class LeaderGuard implements CanActivate {

    constructor(
        private router: Router,
        private authenticatedUserService: AuthenticatedUserService
    ) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        const appKey = next.params.appKey || '';
        return this.verifyAppOwnerOrLeader(appKey, state);
    }

    private verifyAppOwnerOrLeader(appKey: string, state: RouterStateSnapshot): boolean {
        const path = state.url.startsWith('/app') ? 'apps' : 'app_instances';

        if (this.authenticatedUserService.isUserLeader(path, appKey)) {
            return true;
        }

        this.router.navigate(['instance', appKey]);
        return false;
    }
}
