<div [formGroup]="parentForm">
  <mat-form-field [ngClass]="{'without-label': isSectionQuestion}">
    <mat-label *ngIf="!isSectionQuestion">{{ demographic.name }}</mat-label>
    <ng-container>
      <input
        matInput
        placeholder="Select a date"
        [matDatepicker]="datePicker"
        [formControlName]="controlName"
        [id]="controlId"
        [required]="!!question.required && question.required[demographic.id]"
        (click)="datePicker.open()"
      />
      <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
      <mat-datepicker
        #datePicker
      ></mat-datepicker>
    </ng-container>
  </mat-form-field>
</div>
