import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FormStatusPanelService {
    private formStatusPanelViewToggle: BehaviorSubject<boolean> = new BehaviorSubject(false);

    getFormStatusPanelViewToggle(): Observable<boolean> {
        return this.formStatusPanelViewToggle.asObservable();
    }

    setFormStatusPanelViewToggle(toggleValue: boolean): void {
        this.formStatusPanelViewToggle.next(toggleValue);
    }
}
