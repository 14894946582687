import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { UserManager } from 'oidc-client';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import firebase from 'firebase/compat/app';
import jwtDecode from 'jwt-decode';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AcnSsoService {
    private userManager = new UserManager({
        loadUserInfo: false,
        authority: environment['sso'].authority,
        redirect_uri: environment['sso'].redirectUri,
        client_id: environment['sso'].clientId,
        scope: 'openid email profile',
        response_type: 'token id_token',
        extraQueryParams: {
            resource: environment['sso'].clientId,
        },
    });

    constructor(private angularFireAuth: AngularFireAuth, private angularFireFunctions: AngularFireFunctions) {}

    async login(): Promise<void> {
        await this.userManager.signinRedirect();
    }

    async completeAuthentication(): Promise<{
        userCredential: firebase.auth.UserCredential;
        userEmail: string;
        firstName: string;
        lastName: string;
    }> {
        const user = await this.userManager.signinRedirectCallback();
        const tokenData = jwtDecode(user.access_token) as Record<string, string>;

        const userEmail = tokenData.upn.toLowerCase();
        const firstName = tokenData.given_name;
        const lastName = tokenData.family_name;

        // Get the custom token from Firebase token generator
        const firebaseAuthToken = await firstValueFrom(
            this.angularFireFunctions.httpsCallable('generateToken')({
                profile: {
                    upn: userEmail,
                },
            }),
        );

        // Sign the user in with the custom token
        const userCredential = await this.angularFireAuth.signInWithCustomToken(firebaseAuthToken.token);

        return {
            userCredential,
            userEmail,
            firstName,
            lastName,
        };
    }

    removeUser(): Promise<void> {
        return this.userManager.removeUser();
    }
}
