import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';

import { WorkflowService } from '@app/core/services/workflow.service';
import { ReviewState } from '@app/core/models/review.model';

@Component({
    selector: 'app-approval-request-dialog',
    templateUrl: './approval-request-dialog.component.html',
    styleUrls: ['./approval-request-dialog.component.scss']
})

export class ApprovalRequestDialogComponent implements OnInit {
    comment: FormControl;
    isDeny: boolean;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<ApprovalRequestDialogComponent>,
        private workflowService: WorkflowService
    ) {}

    async updateCommentField(): Promise<any> {
        const { handleConfirm } = this.data;

        // check status of the current request
        const requestStatus = await this.workflowService
                .getWorkFlowCardStatus(this.data.notificationType, this.data.requestKey)
                .pipe(take(1))
                .toPromise();

        // if this already has status - we don't need update comment
        if ([ReviewState.Approved, ReviewState.Denied].includes(requestStatus)) {
            handleConfirm();
            return;
        }

        // if review has demographics - add comment to the new review
        if (this.data.notificationType === 'reviews' && this.data.useDemographics) {
            handleConfirm(this.comment.value);
            return;
        }

        if (this.isDeny) {
            handleConfirm(this.comment.value);
            return;
        }

        this.workflowService.updateWorkFlowCardField(
            this.data.notificationType,
            this.data.requestKey,
            'comment',
            this.comment.value
        ).then(() => {
            handleConfirm();
        });
    }

    handleConfirm(): void {
        const { handleConfirm, closeOnConfirm } = this.data;

        if (this.comment.value) {

            // update comment field for request
            this.updateCommentField();
        } else {
            handleConfirm();
        }

        if (closeOnConfirm) {
            this.dialogRef.close();
        }
    }

    ngOnInit() {
        this.isDeny = this.data.confirmBtnText.toLowerCase() === 'deny';
        this.comment = new FormControl(
            '',
            this.data.commentValidate ? Validators.required : []
        );
    }
}
