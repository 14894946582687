import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { take, mergeMap } from 'rxjs/operators';

import { Review, ReviewState, FormStatusModel, FormStatus, formStatusesMap } from '@app/core/models';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { WorkflowService } from '@app/core/services/workflow.service';
import { FormComponentsService } from '@app/core/services/form-components.service';

@Injectable({
    providedIn: 'root'
})
export class ReviewService extends FirebaseUtilityService {
    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private workflowService: WorkflowService,
        private formComponentsService: FormComponentsService
    ) {
        super();
    }

    getReviewsByAppId(appKey: string): Observable<Review[]> {
        return this.listWithKeys(this.db.list('/ssot/_reviews', ref => ref.orderByChild('app_id').equalTo(appKey)));
    }

    getReviewsByActivityId(activityKey: string): Observable<Review[]> {
        return this.listWithKeys(this.db.list('/ssot/_reviews', ref => ref.orderByChild('activity_id').equalTo(activityKey)));
    }

    getReviewByKey(reviewKey: string): Observable<Review> {
        return this.db.object<Review>(`/ssot/_reviews/${reviewKey}`).valueChanges();
    }

    getReviewDemographics(reviewKey: string): Observable<Review> {
        return this.db.object<Review>(`/ssot/_reviews/${reviewKey}/demographics`).valueChanges();
    }

    getReviewByActivityKey(activityKey: string): Observable<ReviewState | FormStatus> {
        return this.db.object<string>(`/ssot/_activities/${activityKey}/form_id`)
            .valueChanges()
            .pipe(
                mergeMap((formKey: string): Observable<string> => {
                    if (!formKey) {
                        return of('');
                    }
                    return this.db.object<string>(`/ssot/_forms/${formKey}/review_id`).valueChanges();
                }),
                mergeMap((requestKey: string): Observable<ReviewState | FormStatus> => {
                    if (!requestKey) {
                        return of(<ReviewState | FormStatus>'');
                    }
                    return this.db.object<ReviewState | FormStatus>(`/ssot/_reviews/${requestKey}/state`).valueChanges();
                })
            );
    }

    getReviewKeyByFormKey(formKey: string): Observable<string> {
        return this.formComponentsService.getFormFieldByKey(formKey, 'review_id');
    }

    requestReview(
        activityId: string,
        appId: string,
        parentFormKey: string,
        demographics: { [formKey: string]: string[] } = {},
        instanceUrl?: string,
        state?: ReviewState | FormStatus,
        demographicsByOwnerDictionary?: { [ownerKey: string]: string[] },
        comment?: string
    ): Promise<void> {
        const reviewUpdate = {};

        const currentUserKey = this.authUserService.getCurrentUserId();
        const reviewKey = this.db.createPushId();
        const formKeys = Object.keys(demographics);
        const demographicsKeysMapByForm = formKeys.reduce((result, formKey) => {
            if (demographics[formKey].length) {
                result[formKey] = demographics[formKey];
            }
            return result;
        }, {});
        const hasDemographics = Object.keys(demographicsKeysMapByForm || {}).length;
        const reviewCommonPart = {
            date_created: Date.now(),
            state: formStatusesMap[state] || FormStatus.PendingApproval,
            participant_id: currentUserKey,
            activity_id: activityId,
            app_id: appId,
            form_id: parentFormKey,
            instance_url: instanceUrl
        };

        if (!hasDemographics) { // review created after submitting form without demographics
            reviewUpdate[`ssot/_reviews/${reviewKey}`] = reviewCommonPart;
        } else if (!state || formStatusesMap[state] === FormStatus.PendingApproval) {

            // review created after submitting form with demographics
            reviewUpdate[`ssot/_reviews/${reviewKey}`] = {
                ...reviewCommonPart,
                demographics: demographicsKeysMapByForm
            };
        } else {  // reviews created after approving/denying(case with demographics)

            // for each demographic's owner
            // update demographicsKeysMapByForm[parentFormKey]
            // and create review
            Object.keys(demographicsByOwnerDictionary || {})
                .forEach((ownerKey: string) => {
                    const newReviewKey = this.db.createPushId();
                    const demographicKeysByForm = {
                        ...demographicsKeysMapByForm
                    };
                    demographicKeysByForm[parentFormKey] = demographicsByOwnerDictionary[ownerKey];
                    reviewUpdate[`ssot/_reviews/${newReviewKey}`] = {
                        ...reviewCommonPart,
                        participant_id: ownerKey,
                        demographics: demographicKeysByForm,
                        reviewer_id: currentUserKey,
                        comment: comment || null
                    };
                });
        }

        return this.db.object('/').update(reviewUpdate)
            .then(() => {
                if (!state || formStatusesMap[state] === FormStatus.PendingApproval) {
                    this.formComponentsService.updateFormsReviewKey(activityId, reviewKey);
                }
            });
    }

    demographicsByOwnerDictionary(
        formStatus: FormStatusModel,
        demographicsActiveKeys: string[]
    ): { [ownerKey: string]: string[] } {
        const demographicsByOwnerDictionary = {};
        Object.keys(formStatus || {})
            .forEach((demographicKey: string) => {
                const isDemographicUnderReview = demographicsActiveKeys.includes(demographicKey)
                    && formStatus[demographicKey]
                    && formStatusesMap[formStatus[demographicKey].status] === FormStatus.PendingApproval;

                if (isDemographicUnderReview) {

                    // if demographics are empty for current ownerKey
                    if (!demographicsByOwnerDictionary[formStatus[demographicKey].owner_id]) {
                        demographicsByOwnerDictionary[formStatus[demographicKey].owner_id] = [];
                    }
                    demographicsByOwnerDictionary[formStatus[demographicKey].owner_id].push(demographicKey);
                }
            });
        return demographicsByOwnerDictionary;
    }

    // TODO: statuses refactoring required
    async updateStateForReview(
        reviewKey: string,
        reviewState: ReviewState,
        activityKey: string,
        alignmentEnabled?: boolean,
        comment?: string
    ): Promise<void> {
        const updates = {};
        const workflowKey = await this.workflowService.getWorkFlowCardKeyByReviewKey(reviewKey).pipe(take(1)).toPromise();
        if (!workflowKey) {
            return Promise.resolve();
        }
        updates[`ssot/_reviews/${reviewKey}/reviewer_id`] = this.authUserService.getCurrentUserId();
        // TODO: write actual status (FormStatus.Denied if denying)
        updates[`ssot/_reviews/${reviewKey}/state`] = reviewState;
        updates[`ssot/_reviews/${reviewKey}/date_created`] = +(new Date());

        if (!!comment) {
            updates[`ssot/_reviews/${reviewKey}/comment`] = comment;
        }

        this.workflowService.updateWorkFlowDate(workflowKey);

        return this.db.object('/').update(updates)
            // TODO: remove after refactoring
            .then(() => {
                if (reviewState === ReviewState.Denied) {
                    // need to write FormStatus.Denied to trigger CF to remove data
                    const status = alignmentEnabled ? FormStatus.Denied : null;
                    return this.formComponentsService.updateFormsReviewKey(activityKey, status);
                }
            });
    }
}
