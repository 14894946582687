<div class="assignees">
  <div
    *ngFor="let user of slicedUsersList; trackBy: trackById"
    class="user"
    matTooltip="{{ getUserName(user) }}"
    matTooltipClass="gs-tooltip"
    matTooltipPosition="above"
  >
    <div
      *ngIf="user.image_url"
      class="user-image"
      [ngStyle]="{'backgroundImage': 'url(' + user.image_url + ')'}"
    ></div>
    <span class="user-text" *ngIf="!user.image_url">{{ getUserName(user, true) }}</span>
  </div>

  <div
    *ngIf="users?.length > usersLimit"
    class="user with-number"
    matTooltip="{{ getAllUsersNames }}"
    matTooltipClass="gs-tooltip"
    matTooltipPosition="above"
  >
    <div
      *ngIf="users[usersLimit]?.image_url"
      class="user-image"
      [ngStyle]="{'backgroundImage': 'url(' + users[usersLimit]?.image_url + ')'}"
    ></div>
    <span class="user-number-bg"></span>
    <span class="user-text">+{{ userLength }}</span>
  </div>
</div>
