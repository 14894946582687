import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { take, skipWhile, map } from 'rxjs/operators';

import { FilestackService } from '@app/core/services/filestack.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { ConvertSlidesParameters } from '@app/core/models/convert-slides-parameters.model';
import { FirebaseQuestionsService } from '@app/core/services/questions.service';
import { FilestackUploadOptions, FilestackUploadType } from '../models/filestack.model';

@Injectable()
export class PresentService {
    private isSlideNameInvalid = false;
    private isActivityNameInvalid = false;
    private isAtLeastOneSlideInvalid = false;

    public validationLoaderStatus: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isDisablePresentButtonsByValidationSubject$ = new BehaviorSubject<boolean>(false);
    validationStatus = new BehaviorSubject<string>('VALID');

    constructor(
        public fbQuestionsService: FirebaseQuestionsService,
        private db: AngularFireDatabase,
        private filestackService: FilestackService,
        private angularFireFunctions: AngularFireFunctions,
        private authUserService: AuthenticatedUserService
    ) { }

    setIsActivityNameInvalid(isInvalid: boolean): void {
        this.isActivityNameInvalid = isInvalid;
        this.updateButtonsDisabledState();
    }

    setIsSlideNameInvalid(isInvalid: boolean): void {
        this.isSlideNameInvalid = isInvalid;
        this.updateButtonsDisabledState();
    }

    setIsAtLeastOneSlideInvalid(isInvalid: boolean): void {
        this.isAtLeastOneSlideInvalid = isInvalid;
        this.updateButtonsDisabledState();
    }

    setValidationLoader(isShow: boolean): void {
        this.validationLoaderStatus.next(isShow);
    }

    async checkValidationCompletion(): Promise<boolean> {
        return await this.validationStatus.pipe(
            skipWhile((status) => {
                return !status.includes('VALID'); // check if status is 'VALID' or 'INVALID'
            }),
            take(1),
            map((status) => {
                return status === 'VALID';
            })
        ).toPromise();
    }

    filePicker(appKey: string, activityKey: string): Observable<boolean> {
        const uploadingIndicator = new Subject<boolean>();

        const omUploadComplete = () => {
            uploadingIndicator.next(false);
            uploadingIndicator.complete();
        };
        const onUploadDone = async ({ filestackFileUrl, filestackFileId, url }) => {
            await this.fbQuestionsService.removeQuestions(appKey, activityKey);

            try {
                const slidesCount = await this.filestackService.getSlidesCount(filestackFileUrl);
                // parse presentation into slides
                await this.parsePresentationSlides({
                    appKey,
                    activityKey,
                    pptHandle: filestackFileId,
                    pptURL: filestackFileUrl,
                    numberOfPages: slidesCount,
                    owner: this.authUserService.getCurrentUserId()
                } as ConvertSlidesParameters);
                // write presentation url to the activity
                await this.setDownloadURL(activityKey, url);
                // hide loader
                omUploadComplete();
            } catch (e) {
                console.error('Error while converting PPT', e);
                uploadingIndicator.next(false);
                uploadingIndicator.error(e);
            }
        };
        // filestack upload options
        const options = {
            uploadType: FilestackUploadType.PresentationFiles,
            gsFolder: 'ppt',
            onUploadDone: onUploadDone,
            onUploadStarted: () => uploadingIndicator.next(true),
            onUploadFailed: omUploadComplete
        } as FilestackUploadOptions;

        this.filestackService.uploadFile(options);

        return uploadingIndicator.asObservable();
    }

    private setDownloadURL(activityKey: string, url: string): Promise<void> {
        const updates = {};

        if (url) {
            updates[`activities/${activityKey}/presentationDownload_url`] = url;
            updates[`ssot/activities/${activityKey}/presentationDownload_url`] = url;
            updates[`ssot/_activities/${activityKey}/presentationDownload_url`] = url;
        }
        return this.db.object('/').update(updates);
    }

    private parsePresentationSlides(parameters: ConvertSlidesParameters): Promise<void> {
        return this.angularFireFunctions.httpsCallable('uploadPresentation')(parameters).pipe(take(1)).toPromise();
    }

    private updateButtonsDisabledState(): void {
        this.setValidationLoader(false);
        const currentValue = this.isDisablePresentButtonsByValidationSubject$.getValue();
        const newValue = this.isSlideNameInvalid || this.isActivityNameInvalid || this.isAtLeastOneSlideInvalid;
        if (currentValue !== newValue) {
            this.isDisablePresentButtonsByValidationSubject$.next(newValue);
        }
    }
}
