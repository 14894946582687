import { InjectionToken } from '@angular/core';
import { Ownership, ProcessViewState, Volunteer } from '../../models';
import { Observable } from 'rxjs';

export interface ProcessViewFacade {
    vm$: Observable<ProcessViewState>;

    routeToAssignment(deploymentId: string, processId: string): void;

    volunteer(volunteerDataObject: Volunteer, deploymentId: string): void;

    unassignUser(ownershipToRemove: Ownership, deploymentId: string, processId: string): void;
}

export const ProcessViewFacadeToken = new InjectionToken<ProcessViewFacade>('ProcessViewFacade');
