import { Component, ChangeDetectionStrategy, EventEmitter, Output, Input } from '@angular/core';

import { Tag, TypesOfTagSorting } from '@app/core/models';
import { trackByKey } from '@app/core/utils';

@Component({
    selector: 'app-all-tags-panel',
    templateUrl: './all-tags-panel.component.html',
    styleUrls: ['./all-tags-panel.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllTagsPanelComponent {
    @Input() isNavigationPanel: false;
    @Input() allTags: Tag[];
    @Input() existTagNames: string[];
    @Input() typeSorting: TypesOfTagSorting;

    @Output() closeTagsPanel = new EventEmitter<void>();
    @Output() onChangeTypeSorting = new EventEmitter<TypesOfTagSorting>();
    @Output() addTagInApp = new EventEmitter<Tag>();
    @Output() removeTagFromApp = new EventEmitter<Tag>();

    collapsed = true;
    maxWidthTagText = 268;
    sortingValues = { frequency_of_use: 'frequency of use', tag_name: 'tag name' };

    trackByKey = trackByKey;

    checkExistTagInApp(tag: Tag): void {
        if (this.existTagNames.includes(tag.name)) {
            this.removeTagFromApp.emit(tag);
        } else {
            this.addTagInApp.emit(tag);
        }
    }
}
