// Remove History Response Comment
export const confirmationDeleteCommentModalText =
    'Are you sure you want to remove this Comment?';

export const secondConfirmationDeleteCommentModalText =
    'This will seriously be gone forever if you click \'Remove\'. Are you sure you want to remove this Comment?';

// Restore History Response
export  const confirmationRestoreResponseModalText = 'Are you sure you want to restore this response?';
export  const confirmationRestoreResponseWithBranhingModalText = (countOfBranchedQuestions: number): string => {
    return !!countOfBranchedQuestions
        ? `This question has branching and when restoring the response it will affect the visibility of <b class="text-bold">${countOfBranchedQuestions}</b> related questions. Would you like to continue?`
        : 'This question has branching but when restoring the response it will not affect the visibility of related questions. Would you like to continue?';
};
