/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

import { VolunteerDialogComponent } from '@thinktank/common-deployments';

import { ConfirmActionDialogComponent } from '@app/shared/dialogs/confirm-action-dialog/confirm.action.dialog.component';
import { ChangePasswordDialogComponent } from '@app/shared/dialogs/change-password-dialog/change-password-dialog.component';
import { AttachmentsDialogComponent } from '@app/shared/dialogs/attachments-dialog/attachments-dialog.component';
import { ConfirmDialogWithKeywordComponent } from '@app/shared/dialogs/confirm-dialog-with-keyword/confirm-dialog-with-keyword.component';
import { AlertHtmlDialogComponent } from '@app/shared/dialogs/alert-html-dialog/alert-html-dialog.component';
import { ImportQuestionsDialogComponent } from '@app/shared/dialogs/import-questions-dialog/import-questions-dialog.component';
import { AlertDialogComponent } from '@app/shared/dialogs/alert-dialog/alert-dialog.component';
import { UserRoleChangedComponent } from '@app/shared/dialogs/user-role-changed-dialog/user-role-changed-dialog.component';
import { FormSubmissionDialogComponent } from '@app/shared/dialogs/form-submission-dialog/form-submission-dialog.component';
import { ProjectNameDialog } from '@app/shared/dialogs/project-name-dialog/project-name-dialog.component';
import { ApprovalRequestDialogComponent } from '@app/shared/dialogs/approval-request-dialog/approval-request-dialog.component';
import { EventFlowResultsDialogComponent } from '@app/shared/dialogs/event-flow-results-dialog/event-flow-results-dialog.component';
import { EventFlowValidationDialogComponent } from '@app/shared/dialogs/event-flow-validation-dialog/event-flow-validation-dialog.component';
import { SessionsListDialogComponent } from '@app/shared/dialogs/sessions-list-dialog/sessions-list-dialog.component';
import { ChangeUsernameDialogComponent } from '@app/shared/dialogs/change-username-dialog/change-username-dialog.component';
import { DisagreeDialogComponent } from '@app/shared/dialogs/disagree-dialog/disagree-dialog.component';
import { CopyAppDialogComponent } from '@app/shared/dialogs/copy-app-dialog/copy-app-dialog.component';
import { ContributorsListDialogComponent } from '@app/shared/dialogs/contributors-list-dialog/contributors-list-dialog.component';
import { SelectDemographicsForExportComponent } from '@app/shared/dialogs/select-demographics-for-export-dialog/select-demographics-for-export-dialog.component';
import { WorkbooksExportDialogComponent } from '@app/shared/dialogs/workbooks-export-dialog/workbooks-export-dialog.component';
import { OptionsViewDialogComponent } from '@app/shared/dialogs/options-view-dialog';
import { ExportInfoDialogComponent } from '@app/shared/dialogs/export-info-dialog/export-info-dialog.component';
import { ExportRenameDialogComponent } from '@app/shared/dialogs/export-rename-dialog/export-rename-dialog.component';
import { ExportXmlJsonDialogComponent } from '@app/shared/dialogs/export-xml-json-dialog/export-xml-json-dialog.component';
import { OverlappedQuestionsDialogComponent } from '@app/shared/dialogs/overlapped-questions-dialog/overlapped-questions-dialog.component';
import { RestoreResponseBranchedQestionsDialogComponent } from '@app/shared/dialogs/restore-response-branched-question-dialog/restore-response-branched-questions-dialog.component';
import { BulkInviteDialogComponent } from '@app/shared/dialogs/bulk-invite-dialog/bulk-invite-dialog.component';

@Injectable()
export class DialogService {
    private dialogComponents: { [key: string]: ComponentType<any> };
    private defaultDialogData: {
        confirmBtnText: string;
        cancelBtnText: string;
        closeOnConfirm: boolean;
    };

    constructor(public dialog: MatDialog) {
        this.dialogComponents = {
            ConfirmActionDialogComponent,
            ChangePasswordDialogComponent,
            AttachmentsDialogComponent,
            ImportQuestionsDialogComponent,
            AlertDialogComponent,
            UserRoleChangedComponent,
            ConfirmDialogWithKeywordComponent,
            FormSubmissionDialogComponent,
            ProjectNameDialog,
            ApprovalRequestDialogComponent,
            EventFlowResultsDialogComponent,
            EventFlowValidationDialogComponent,
            SessionsListDialogComponent,
            ChangeUsernameDialogComponent,
            DisagreeDialogComponent,
            CopyAppDialogComponent,
            ContributorsListDialogComponent,
            SelectDemographicsForExportComponent,
            WorkbooksExportDialogComponent,
            AlertHtmlDialogComponent,
            OptionsViewDialogComponent,
            ExportInfoDialogComponent,
            ExportRenameDialogComponent,
            ExportXmlJsonDialogComponent,
            OverlappedQuestionsDialogComponent,
            VolunteerDialogComponent,
            RestoreResponseBranchedQestionsDialogComponent,
            BulkInviteDialogComponent,
        };

        this.defaultDialogData = {
            confirmBtnText: 'Ok',
            cancelBtnText: 'Cancel',
            closeOnConfirm: true,
        };
    }

    open(dialogComponentName: string, data: any): MatDialogRef<any> {
        const dialogComponent = this.dialogComponents[dialogComponentName];

        return this.dialog.open(dialogComponent, {
            data: {
                ...this.defaultDialogData,
                ...data,
            },
            width: data['width'] || '300px',
            height: data['height'] || 'auto',
            maxWidth: data['maxWidth'] || '300px',
            panelClass: data['panelClass'] || '',
            disableClose: data['disableClose'] || false,
            restoreFocus: data['restoreFocus'] || false,
        });
    }
}
