import { Component, OnChanges, Input, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';

@Component({
    selector: 'gs-image',
    templateUrl: './image.component.html',
    styleUrls: ['./image.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageComponent implements OnChanges {
    @Input() deploymentTemplateImage: string;
    @Input() backgroundColorIndex?: string;
    @Input() isSmall?: boolean;

    constructor() { }

    ngOnChanges(changes: SimpleChanges) {
    }
}
