<div class="image">

  <!-- Image description and error -->
  <div *ngIf="canEdit" class="image-description">
    <p
      *ngIf="!invalidImageFormat"
      class="hidden"
      [ngClass]="{ 'show': showImageDescription }"
    >
      Required {{imageFormats.join(', ')}} formats.
    </p>

    <div *ngIf="invalidImageFormat">
      <p class="mat-error">{{invalidImageFormatMessage}}</p>
      <p>Please choose one of the following formats {{imageFormats.join(', ')}} and try again.</p>
    </div>
  </div>
  <!-- /Image description and error -->

  <div
    class="image-container"
    [ngClass]="{ 'default-img': imageUrl === defaultImageUrl }"
    [ngStyle]="{ 'background-image': 'url(' + imageUrl + ')' }"
  >
    <div *ngIf="canEdit" class="image-upload">

      <!-- Upload Image Button -->
      <div
        class="image-upload-button"
        (click)="setImage()"
        (mouseover)="showImageDescription = true"
        (mouseleave)="showImageDescription = false"
      >
        <mat-icon>file_download</mat-icon>
        <span>Upload Image</span>
      </div>
      <!-- /Upload Image Button -->

    </div>
  </div>
</div>
