export class FormQuestionValidationError {
    key?: string;
    question_id?: string;
    demographic_id?: string;
    secondary_demographic_id?: string;
    section_question_id?: string;
    source_section_question_id?: string;
    form_id?: string;
}

export class FormQuestionValidationData extends FormQuestionValidationError {
    currentUserId?: string;
    useDemographics?: boolean;
    activityId?: string;
    focusedDemographicKey?: string;
    focusedSectionDemographicKey?: string;
}
