import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'objectKeys',
    pure: true
})
export class ObjectKeysPipe implements PipeTransform {

    transform(object: Object): string[] {
        return Object.keys(object || {});
    }

}
