import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-form-submission-dialog',
    templateUrl: './form-submission-dialog.component.html',
    styleUrls: ['./form-submission-dialog.component.scss']
})
export class FormSubmissionDialogComponent {

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FormSubmissionDialogComponent>
    ) {}

    onSubmit(): void {
        const { handleConfirm } = this.data;
        handleConfirm();
        this.dialogRef.close();
    }
}
