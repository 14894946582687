import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'length',
    pure: true
})
export class LengthPipe implements PipeTransform {

    transform(array: any[]): number {
        return (array || []).length;
    }

}
