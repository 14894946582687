<div *ngIf="(vm$ | async) as vm" class="process">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div *ngIf="!vm.isLoading" class="process-container">
    <div class="process-title">
      <h5 class="h5">{{ vm.process.name }}</h5>
      <button class="gs-button-icon-flat gs-small" [routerLink]="['/deployment', vm.deploymentId]">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>

    <!-- demographics -->
    <h5 class="gs-block-title">Countries</h5>
    <gs-view-demographics
      [demographics]="vm.selectedDemographics"
      [canChange]="false"
      [viewType]="'process'"
    ></gs-view-demographics>
    <!-- /demographics -->

    <!-- assignees details -->
    <h5 class="gs-block-title">Assignees</h5>
    <gs-view-assignees
      viewType="process"
      [deploymentOwnership]="getFilteredOwnership(vm.deploymentOwnership, vm.process.id)"
      [useDemographics]="true"
      (assignUsers)="goToAssignment(vm.deploymentId, vm.process)"
      (unassignUser)="unassignUser($event, vm.deploymentId, vm.process.id)"
    ></gs-view-assignees>
    <!-- /assignees details -->

    <!-- sub processes table -->
    <h5 class="gs-block-title">Quick Details</h5>

    <!-- processes/sub processes tabs -->
    <div class="gs-tabs">
      <div class="gs-tab" [ngClass]="{ active: processesActiveTab === 1 }">
        <a (click)="processesActiveTab = 1">Sub Processes</a>
        <span class="gs-badge">{{ vm.subprocesses.length }}</span>
      </div>
      <div class="gs-tab" [ngClass]="{ active: processesActiveTab === 2 }">
        <a (click)="processesActiveTab = 2">Processes/Sub Processes Impacted</a>
        <!-- <span class="gs-badge">{{ getProcessesImpactedCount(vm.impactData, vm.process.id) }}</span> -->
      </div>
    </div>
    <!-- /processes/sub processes tabs -->

    <!-- table with subprocesses and impact data -->
    <gs-view-processes-table
      [viewType]="'process'"
      [currentUserId]="vm.authenticatedUserId"
      [deploymentId]="vm.deploymentId"
      [processId]="vm.process.id"
      [ownership]="vm.deploymentOwnership"
      [demographics]="vm.selectedDemographics"
      [impactData]="vm.impactData"
      [changeRequests]="vm.changeRequests"
      [showSubprocesses]="processesActiveTab === 1"
      [showProcessesImpacted]="processesActiveTab === 2"
      [subprocesses]="vm.subprocesses"
      (sendPulseForSubProcess)="sendPulseForSubprocess($event)"
      (volunteer)="volunteer($event)"
    ></gs-view-processes-table>
    <!-- /table with subprocesses and impact data -->

  </div>
</div>
