import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { FormItemType, FirestoreService } from '@thinktank/common-lib';
import { SectionFormGroupHandler } from './section-form-group-handler';
import { QuestionFormGroupHandler } from './question-form-group-handler';
import { RepeatedSectionBuilder } from './repeated-section-builder';
import { QuestionFormControlFactory } from './question-form-control.factory';
import { GlobalQuestionFormGroupHandler } from './global-question-form-group-handler';
import { FormGroupHandler } from './form-group-handler';

@Injectable({
    providedIn: 'root'
})
export class FormGroupHandlerFactory {

    constructor(
        private formBuilder: FormBuilder,
        private firestoreService: FirestoreService,
        private questionFormControlFactory: QuestionFormControlFactory
    ) {}

    build(type: FormItemType, global: boolean): FormGroupHandler {
        if (type === FormItemType.Section) {
            return new SectionFormGroupHandler(this.formBuilder, this.questionFormControlFactory);
        }
        return global
            ? new GlobalQuestionFormGroupHandler(this.questionFormControlFactory)
            : new QuestionFormGroupHandler(this.formBuilder, this.questionFormControlFactory);
    }

    repeatedSectionBuilder(): RepeatedSectionBuilder {
        return new RepeatedSectionBuilder(this.formBuilder, this.questionFormControlFactory, this.firestoreService);
    }
}
