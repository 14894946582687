import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import { FileUpload, Progress } from '@app/core/models';

@Injectable()
export class FileUploadService {

    constructor(private db: AngularFireDatabase) { }

    uploadFile(fileUpload: FileUpload, folderPath: string, progress?: Progress) {
        const storageRef = firebase.storage().ref();
        const generatedFileName = this.db.createPushId();
        const fileType = fileUpload.file.type.split('/')[1];
        const uniqueFileName = `${generatedFileName}.${fileType}`;
        const uploadTask = storageRef.child(`${folderPath}/${uniqueFileName}`).put(fileUpload.file);

        return (onSuccess?: Function, onError?: Function) => {
            uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED,
                (snapshot: firebase.storage.UploadTaskSnapshot) => {
                    if (progress) {
                        const percentage = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                        progress.setProgress(percentage !== 100);
                    }
                },
                (error) => {
                    console.log(error);
                    if (onError) {
                        onError(error);
                    }
                },
                () => {
                    if (progress) {
                        progress.setProgress(false);
                    }
                    if (onSuccess) {
                        onSuccess(uploadTask.snapshot);
                    }
                }
            );
        };
    }

}
