import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { isUndefined, omitBy, flatten, isNull, isEqual } from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Store } from '@ngrx/store';

import { Form, FormResponse } from '@app/core/models/forms.model';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { emptyResponse } from '@app/core/services/form-summary.service';
import { ResponseHistoryService, DBFormHistoryResponse } from '@thinktank/common-lib';
import { FormQuestionValidationError, FormStatus, responseType as ResponseType } from '@app/core/models';
import { FormValidationService } from '@app/root-store/features/form-validation-errors/services';
import { FormValidationErrorsStoreActions } from '@app/root-store/features/form-validation-errors';
import { AppState } from '@app/root-store/state';

@Injectable({
    providedIn: 'root',
})
export class FormResponseService extends FirebaseUtilityService {
    private backingResponses: { [questionAndDemographicKeys: string]: FormResponse } = {};
    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private responseHistoryService: ResponseHistoryService,
        private formValidationService: FormValidationService,
        private angularFireFunctions: AngularFireFunctions,
        private store$: Store<AppState>,
    ) {
        super();
    }

    formatResponseValue(value: any, responseType: ResponseType): any {
        if (typeof value === 'undefined' || value === null) {
            return;
        }
        switch (responseType) {
            case 'date':
                return new Date(value);
            default:
                return value;
        }
    }

    clearBackingResponses(): void {
        this.backingResponses = {};
    }

    getCurrentUserId(): string {
        return this.authUserService.getCurrentUserId();
    }

    setBackingResponse(response: FormResponse, removeResponse?: boolean): FormResponse {
        const { question_id, demographic_id, parent_demographic_id } = response;
        const path = `${question_id}:${demographic_id || ''}:${parent_demographic_id || ''}`;

        return (this.backingResponses[path] = removeResponse ? null : response);
    }

    getBackingResponse(questionKey: string, demographicKey: string, parentDemographicKey?: string): FormResponse {
        return (
            this.backingResponses[`${questionKey}:${demographicKey || ''}:${parentDemographicKey || ''}`] ||
            <FormResponse>{}
        );
    }

    buildResponse(
        value: any,
        formKey: string,
        questionKey: string,
        demographicKey: string,
        parentDemographicKey?: string,
        changeResponse?: boolean,
        formStatus?: FormStatus,
    ): FormResponse {
        const dbResponse = this.getBackingResponse(questionKey, demographicKey, parentDemographicKey);

        if (isEqual(dbResponse.value, value) || (isUndefined(dbResponse.value) && isNull(value))) {
            return null;
        }

        const key = dbResponse.key || this.db.createPushId();
        const initialResponse = dbResponse.initial_response || dbResponse.value || emptyResponse;
        const initialResponseValue = changeResponse ? initialResponse : null;
        const initialCustomName = dbResponse.custom_name || null;

        return <FormResponse>omitBy(
            {
                key,
                value,
                form_status: formStatus,
                owner_id: this.authUserService.getCurrentUserId(),
                form_id: formKey,
                question_id: questionKey,
                demographic_id: demographicKey,
                parent_demographic_id: parentDemographicKey,
                initial_response: initialResponseValue,
                initial_response_owner_id: changeResponse ? dbResponse.owner_id : null,
                initial_custom_name: initialCustomName,
            },
            result => isUndefined(result) || isNull(result),
        );
    }

    async handleResponse({ key, ...response }: FormResponse): Promise<void> {
        const updates = {};
        const responseKey = key || this.db.createPushId();

        const demographicKey = response.demographic_id || response.parent_demographic_id;
        const oldResponse = this.getBackingResponse(responseKey, demographicKey, response.parent_demographic_id);

        if (response.value && oldResponse.value === response.value) {
            return Promise.resolve();
        }

        const newResponse = {
            ...response,
            date_written: response.date_written || +new Date(),
        } as FormResponse;

        updates[`ssot/_responses/${responseKey}`] = newResponse;

        // if response was changed -> should be automatically accepted
        const hasInitialResponse = response.initial_response || response.initial_response === 0;

        if (hasInitialResponse) {
            updates[`ssot/_questions/${response.question_id}/accepted/${!!demographicKey ? demographicKey : ''}`] =
                true;
        }

        const questionAcceptedUserId = await this.db
            .object(`ssot/_questions/${response.question_id}/accepted_owner_id`)
            .valueChanges()
            .pipe(take(1))
            .toPromise();

        if (hasInitialResponse && !questionAcceptedUserId) {
            updates[
                `ssot/_questions/${response.question_id}/accepted_owner_id/${!!demographicKey ? demographicKey : ''}`
            ] = this.authUserService.getCurrentUserId();
        }
        await this.db.object('/').update(updates);
    }

    async handleHistoryResponse(
        { key, ...response }: FormResponse,
        previousResponse: FormResponse,
        activityKey: string,
        formStatus: FormStatus,
        questionResponseType: string,
        userId: string,
    ): Promise<void> {
        const newHistoryResponse = this.responseHistoryService.buildResponse(
            response.value,
            this.authUserService.getCurrentUserId(),
            this.authUserService.getCurrentUserFullName(),
            formStatus,
            questionResponseType,
        );
        const previousHistoryResponse =
            !!previousResponse && !!Object.keys(previousResponse).length
                ? this.responseHistoryService.buildResponse(
                      previousResponse.value,
                      previousResponse.owner_id,
                      '', // will be calculated on CF side
                      previousResponse.form_status,
                      questionResponseType,
                      +previousResponse.date_written,
                      previousResponse.custom_name,
                  )
                : ({} as DBFormHistoryResponse);

        await this.responseHistoryService.addResponse(
            newHistoryResponse,
            previousHistoryResponse,
            activityKey,
            response.question_id,
            userId,
            response.parent_demographic_id || response.demographic_id,
            !!response.parent_demographic_id ? response.demographic_id : null,
        );
    }

    async deleteQuestionErrors(
        { question_id, demographic_id, parent_demographic_id }: FormResponse,
        activityKey: string,
    ): Promise<any> {
        const props: FormQuestionValidationError = {
            question_id,
        };

        if (parent_demographic_id) {
            props.demographic_id = parent_demographic_id;
            props.secondary_demographic_id = demographic_id;
        } else {
            props.demographic_id = demographic_id;
        }

        const error = await this.getValidationErrorFromDb(activityKey, props);

        if (error) {
            await this.formValidationService.clearValidationError(activityKey, error.key);
        }
    }

    async getValidationErrorFromDb(
        activityKey: string,
        props: FormQuestionValidationError,
    ): Promise<FormQuestionValidationError> {
        const errors = await this.formValidationService.getValidationErrors(activityKey).pipe(take(1)).toPromise();

        return (errors || []).find(error => {
            return (
                (!!props.question_id ? props.question_id === error.question_id : true) &&
                (!!props.source_section_question_id
                    ? props.source_section_question_id === error.source_section_question_id
                    : true) &&
                (!!props.demographic_id ? props.demographic_id === error.demographic_id : true)
            );
        });
    }

    async updateResponse(
        { stateChangeType, ...response }: FormResponse,
        previousResponse: FormResponse,
        activityKey: string,
        formStatus: FormStatus,
        questionResponseType: string,
        hasNumberRangeValidationError: boolean,
        userId: string,
    ): Promise<void> {
        await this.handleResponse(response);
        await this.handleHistoryResponse(
            response,
            previousResponse,
            activityKey,
            formStatus,
            questionResponseType,
            userId,
        );
        if (hasNumberRangeValidationError) {
            this.store$.dispatch(FormValidationErrorsStoreActions.validateFormCall({ activityId: activityKey }));
        } else {
            await this.deleteQuestionErrors(response, activityKey);
        }
    }

    async updateResponses(
        responses: { response: FormResponse; previousResponse: FormResponse }[],
        activityKey: string,
        formStatus: FormStatus,
        questionResponseType: string,
        hasValidationErrors: boolean,
        userId: string,
    ): Promise<void> {
        await Promise.all(
            responses.map(({ response }) => {
                return this.handleResponse(response);
            }),
        );

        await Promise.all(
            responses.map(({ response, previousResponse }) => {
                return this.handleHistoryResponse(
                    response,
                    previousResponse,
                    activityKey,
                    formStatus,
                    questionResponseType,
                    userId,
                );
            }),
        );

        if (!hasValidationErrors) {
            await Promise.all(
                responses.map(({ response }) => {
                    return this.deleteQuestionErrors(response, activityKey);
                }),
            );
        }
    }

    applyAttachmentsToDemographics(
        attachmentsKeys: string[],
        questionKey: string,
        demographicsKeys: string[],
    ): Promise<any> {
        return this.angularFireFunctions
            .httpsCallable('applyAttachmentsToDemographics')({
                attachmentsKeys,
                questionKey,
                demographicsKeys,
            })
            .toPromise()
            .then(
                res => res,
                err => {
                    console.error({ err });
                },
            );
    }

    updateResponseField(responseKey: string, field: string, value: string): Promise<void> {
        const updates = {};

        updates[`ssot/_responses/${responseKey}/${field}`] = value;

        return this.db.object('/').update(updates);
    }

    getResponsesByForm(formKey: string): Observable<FormResponse[]> {
        return this.listWithKeys(
            this.db.list<FormResponse>('ssot/_responses', ref => ref.orderByChild('form_id').equalTo(formKey)),
        ).pipe(
            map((responses: FormResponse[]) => {
                // Temporary solution
                responses.forEach(response => {
                    this.setBackingResponse(response);
                });

                return responses;
            }),
        );
    }

    getQuestionResponsesStateChange(questionKey: string, parentDemographicKey?: string): Observable<FormResponse> {
        return this.db
            .list<FormResponse>('ssot/_responses', ref => ref.orderByChild('question_id').equalTo(questionKey))
            .stateChanges(['child_added', 'child_changed', 'child_removed'])
            .pipe(
                map(responseSnapshot => {
                    const response = responseSnapshot.payload.val();
                    // for backward compatibility to display old responses in section
                    const responseParentDemographicKey = !!parentDemographicKey
                        ? response.parent_demographic_id || parentDemographicKey
                        : undefined;

                    return {
                        ...response,
                        key: responseSnapshot.key,
                        stateChangeType: responseSnapshot.type,
                        parent_demographic_id: responseParentDemographicKey,
                    };
                }),
                filter(response => {
                    return !!parentDemographicKey ? response.parent_demographic_id === parentDemographicKey : true;
                }),
                map(response => {
                    const removeResponse = response.stateChangeType === 'child_removed';

                    return this.setBackingResponse(response, removeResponse);
                }),
            );
    }

    getResponsesByActivity(activityKey: string): any {
        return this.db
            .list<Form>('ssot/_forms', ref => ref.orderByChild('activity_id').equalTo(activityKey))
            .snapshotChanges()
            .pipe(
                map((forms: any[]) =>
                    forms.map((form: Form) => {
                        return this.listWithKeys(
                            this.db.list<FormResponse>('ssot/_responses', ref =>
                                ref.orderByChild('form_id').equalTo(form.key),
                            ),
                        );
                    }),
                ),
                mergeMap(responsesRefs => combineLatest(responsesRefs)),
                map((responses: FormResponse[][]) => flatten(responses)),
            );
    }
}
