export const allAcceptedFileTypes = [
    '.ppt',
    '.pptx',
    '.doc',
    '.docx',
    '.xlsx',
    '.xls',
    '.odt',
    '.jpg',
    '.tiff',
    '.png',
    '.gif',
    '.bmp',
    '.mp4',
    '.mov',
    '.mpeg',
    '.avi',
    '.wmv',
    '.pdf',
    '.txt',
    '.rtf'
];

export const allAcceptedImageTypes = [
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/bmp'
];

export const allAcceptedImageTypesToDownload = [
    'png',
    'jpeg',
    'jpg',
    'svg',
    'gif',
    'ico',
    'bmp'
];

export const allAcceptedEXCELFileTypes = [
    '.csv',
    '.xlsx',
    '.xls',
    '.xlsm',
    '.xlsb',
    '.xltx',
    '.xltm',
    '.xlt',
    '.xml',
    '.xlam',
    '.xla',
    '.xlw'
];


export const allAcceptedPresentationTypes = [
    '.ppt',
    '.pptx',
    '.pdf'
];
