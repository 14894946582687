import { NavigationCardItem } from '@thinktank/common-lib';

import { ActivityTab, testActivityTab } from '@app/core/models/activity.model';
import { InstanceFocus, InstanceStatus } from '@app/core/models/instance.model';
import { Tag } from '@app/core/models/tag.model';
import { User } from '@app/core/models/user.model';
import { Members, testMembers } from './member.model';

export class App implements NavigationCardItem {
    // NavigationCardItem members
    name: string;
    description: string;
    image_url: string;
    ownerImageUrl?: string;
    key?: string;
    project_name?: string;
    goal: string;
    instances?: {
        [key: string]: string;
    };

    meatball_menu?: boolean;
    date_created: number;
    date_last_updated: number;
    owner: string;
    ownerFullName?: string;
    members?: Members;
    member_ids?: Members;
    no_of_users?: number;
    agenda_visible?: boolean;
    self_navigate?: boolean;
    participants_tasks_list?: boolean;
    Live?: boolean;
    activities?: { [key: string]: ActivityTab };
    app_id?: string;
    focus?: InstanceFocus;
    status?: InstanceStatus;
    live?: string;
    ownership?: { [key: string]: AppUser };
    use_passkey?: boolean;
    email_required?: boolean;
    passkey?: string;
    passkeyGUID?: string;
    light?: boolean;
    owner_id?: string;
    hasDemographicActivity?: boolean;
    isAppParticipant?: boolean;
    workbook_id?: string;
    tags?: { [key: string]: Tag };
    tag_names?: string;
    number_of_users?: number;
    owner_name?: string;
}

export interface AppRoster {
    app_id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    username: string;
    role: string;
    key?: string;
}

export interface AppSharing {
    app_id: string;
    user_id: string;
    first_name: string;
    last_name: string;
    username: string;
    role: string;
    key?: string;
}

export const emptyApp = <App>{
    description: '',
    goal: '',
    instances: {},
    name: 'New Template',
    image_url: '',
    meatball_menu: true,
    date_created: Date.now(),
    date_last_updated: Date.now(),
    agenda_visible: false,
    self_navigate: false,
    participants_tasks_list: false,
    owner: null,
    members: {},
    no_of_users: 2,
};

export const testApp = <App>{
    description: 'Test description',
    project_name: 'Test project name',
    goal: 'Test goal',
    instances: {
        app_instance1: 'user1',
    },
    name: 'Test app',
    image_url: 'https://firebasestorage.googleapis.com/v0/b/thinktank-hi-q-dev.appspot.com/...',
    meatball_menu: true,
    date_created: 1510686478630,
    date_last_updated: 1510686478630,
    owner: 'user1',
    members: testMembers,
    member_ids: testMembers,
    no_of_users: 2,
    status: 'incomplete',
    Live: true,
    activities: {
        activity1: testActivityTab,
        activity2: testActivityTab,
        activity3: testActivityTab,
    },
    app_id: 'app1',
};

export type AppRole = 'owner' | 'leader' | 'launch-only';

export class AppUser {
    key?: string;
    email: string;
    firstName: string;
    lastName: string;
    role?: AppRole;
    user?: User;
    username?: string;
    user_id?: string;
    app_id?: string;
    online?: string;
}

export const appRoles = {
    owner: 'Owner',
    leader: 'Collaborator',
    'launch-only': 'Session creator',
};

export const sessionRoles = {
    leader: 'Leader',
    participant: 'Participant',
    observer: 'Observer',
};

export const appInviteTypes = {
    leader: 'AppInviteCollaborator',
    'launch-only': 'AppInviteSessionCreator',
};

export const sessionInviteTypes = {
    leader: 'SessionInviteLeader',
    participant: 'SessionInviteParticipant',
    observer: 'SessionInviteObserver',
};

export interface SortingData {
    active: string;
    direction: string;
}

export interface FilterForAppsData {
    sortingData: SortingData;
    filterValue: string;
}

export interface InvitedUser {
    created_at: number;
    email: string;
    role: string;
    username: string;
}
