import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { TasksCount } from '@thinktank/common-lib';

@Component({
    selector: 'app-tasks-count',
    templateUrl: './tasks-count.component.html',
    styleUrls: ['./tasks-count.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class TasksCountComponent {
    @Input() tasksCount: TasksCount;
    @Input() projectKey: string;

    constructor() {}
}
