<mat-radio-group
  *ngIf="isStakeholder && isEdit"
  [(ngModel)]="selectedId"
  (change)="onChangeSelected($event)"
>
  <mat-radio-button
    *ngFor="let user of users; trackBy: trackById"
    class="users-item subtitle-small"
    color="primary"
    [ngClass]="{'module': isGlobal}"
    [value]="user.id"
  >
    <ng-container *ngIf="!!user.first_name && !!user.last_name; else elseUserBlock">
      {{ user.first_name }} {{ user.last_name }}
    </ng-container>

    <ng-template #elseUserBlock>
      {{ user.email_address }}
    </ng-template>
    <span *ngIf="user.primary" class="primary-label">
      Primary
    </span>
  </mat-radio-button>
</mat-radio-group>

<ng-container *ngIf="showUsersList">
  <div
    *ngFor="let user of users; trackBy: trackById"
    class="users-item subtitle-small"
    [ngClass]="{'module': isModule || isGlobal}"
  >
    <ng-container *ngIf="!!user.first_name && !!user.last_name; else elseUserBlock">
      <label>{{ user.first_name }} {{ user.last_name }}</label>
    </ng-container>

    <ng-template #elseUserBlock>
      <label>{{ user.email_address }}</label>
    </ng-template>
    <span *ngIf="user.primary" class="primary-label">
      Primary
    </span>
  </div>
</ng-container>
