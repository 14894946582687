import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { Demographic } from './demographic.model';
import { ChangeRequest } from './change-request.model';
import { FormItemNode } from './form-item-node.model';
import { Variable } from './variable.model';
import { Attachment } from './attachment.model';

export enum FormItemType {
    ShortText = 'short_text',
    LongText = 'long_text',
    SingleSelect = 'single_select',
    MultiSelect = 'multi_select',
    Section = 'section',
    Numeric = 'numeric',
    Date = 'date',
    Video = 'video',
    Image = 'image',
    Attachment = 'attachment',
    File = 'file'
}

export enum FormStatus {// TODO: rewrite to uppercase while refactoring
    Draft = 'draft',
    DraftReview = 'draft_review',
    Aligned = 'aligned',
    InProgress = 'in_progress',
    PendingApproval = 'pending_approval',
    Approved = 'approved'
}

export type FormStatusModel = { [demographicId: string]: FormStatus };

export const formStatusesSet = {
    'approved': {
        status: 'approved',
        text: 'Approved',
        color: 'approved'
    },
    'in_progress': {
        status: 'in_progress',
        text: 'In Progress',
        color: 'in-progress'
    },
    'IN_PROGRESS': {
        status: 'in_progress',
        text: 'In Progress',
        color: 'in-progress'
    },
    'DRAFT': {
        status: 'draft',
        text: 'Draft',
        color: 'draft'
    },
    'DENIED': {
        status: 'denied',
        text: 'Denied',
        color: 'denied'
    },
    'LOCKED': {
        status: 'locked',
        text: 'Locked',
        color: 'locked'
    },
    'DRAFT_REVIEW': {
        status: 'draft review',
        text: 'Draft Review',
        color: 'draft-review'
    },
    'ALIGNMENT': {
        status: 'alignment',
        text: 'Alignment',
        color: 'alignment'
    },
    'PENDING_APPROVAL': {
        status: 'pending approval',
        text: 'Pending Approval',
        color: 'pending-approval'
    },
    'APPROVED': {
        status: 'approved',
        text: 'Approved',
        color: 'approved'
    },
};

export interface FormSubmittal {
    status: FormStatusModel;
    questionsWithDefaultResponse: FormQuestionWithDemographicId[];
    deploymentId: string;
    formId: string;
}

export interface FormQuestionWithDemographicId {
    question: FormQuestion;
    demographicId: string;
    sectionResponseId?: string;
}

export interface FormQuestionLabel {
    default: string;
    current_value?: string;
}

export const getFormQuestionLabelValue = (label: FormQuestionLabel): string => {
    return !!label.current_value ? label.current_value : label.default;
};

export interface FormItem {
    id?: string;
    sequence: number;
    type: FormItemType;
    required: RequiredDemographic;
    visible: Visibility;
    description?: string; // no clue who or what will use this at the moment...
    label?: FormQuestionLabel;
    form_id?: string;
    global?: boolean; // need to figure out how to set this for global forms...
}

export interface FormResponse {
    [demographicId__sectionId: string]: {
        value: any;
        user_id: string;
        demographic_id: string;
        section_node_id?: string;
        section_response_id?: string;
        section_sequence?: number;
        updated: firebase.firestore.Timestamp
    };
}

export interface FormUpdatedResponse {
    value: any;
    questionId: string;
    demographicId: string;
    sectionNodeId?: string;
    sectionResponseId?: string;
    sectionSequence?: number;
    formId?: string;
}

export interface FormQuestion extends FormItem {
    options?: Option[];
    response?: FormResponse;
    default_response?: any;
    section_node_id?: string;
    section_response_id?: string;
    section_sequence?: number;
    attached_files?: Attachment[];
    image_url?: string;
    video_url?: string;
    range_enabled?: boolean;
    numeric_range?: {
        min: number,
        max: number
    }
}

export interface RepeatedSection {
    [demographicId: string]: {
        [repeatedSectionId: string]: {
            description: string;
            user_id: string;
            sequence: number;
        };
    };
}

export interface RepeatedSectionAddInfo {
    section: FormSection;
    nextSequence: number;
    demographic: Demographic;
    sectionResponses: { [questionId: string]: any };
    id?: string;
}

export interface RepeatedSectionDescriptionUpdate {
    description: string;
    sectionId: string;
    repeatedSectionId: string;
    demographicId: string;
    formId: string;
    deploymentId: string;
}

export interface RepeatedSectionDeleteInfo {
    id: string;
    demographicId: string;
    section: FormSection;
}

export interface FormSection extends FormItem {
    description?: string;
    questions: FormStorageItemMap;
    questionsForView?: FormQuestion[];
    repeatable: boolean;
    repeated_sections?: RepeatedSection;
}

export const isFormQuestion = (formItem: any): boolean => formItem.type !== FormItemType.Section;

export interface Form {
    id?: string;
    name: string;
    owner_id: string;
    items?: FormItem[];
    parent_id: string;
    status?: FormStatusModel;
    global?: boolean;
}

// The items are stored as a map in the form view in Firestore. They are used as an array in the Form interface above
export interface FormView {
    id?: string;
    name: string;
    owner_id?: string;
    items: FormStorageItemMap;
    parent_id: string;
    status?: FormStatusModel;
    global?: boolean;
    form_id?: string;
    deployment_id?: string;
}

export interface PartialFormState {
    form: Form;
    assignedDemographics: Demographic[];
    changeRequests: ChangeRequest[];
}

export interface FormStorageItemMap {
    [item_id: string]: FormQuestion | FormSection;
}

export interface Option {
    value: string;
}

export interface Visibility {
    [demographicId: string]: boolean;
}

export interface RequiredDemographic {
    [demographicId: string]: boolean;
}

export interface SelectResponse {
    value: string;
}

export interface FormViewBuilder {
    buildFormView(formItems: FormItemNode[], demographics: Demographic[]): FormItem;
}

export interface Propagatable {
    propagateValues(demographicId: string): Promise<void>;
    setInput(name: string, variableData: Variable): Promise<void>;
}
