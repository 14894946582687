export class FileUpload {
    $key: string;
    name: string;
    url: string;
    file: File;

    constructor(file: File) {
        this.file = file;
    }
}

export class Progress {
    percentage: number;
    inProgress: boolean;

    constructor() {
        this.percentage = 0;
        this.inProgress = false;
    }

    setProgress(value: boolean): void {
        this.inProgress = value;
    }
}
