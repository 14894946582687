import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ofActivityType'
})

export class OfActivityTypePipe implements PipeTransform {

  transform(value: any, types: any): any {
    if (value) {
      return value.filter((item) => types.includes(item.payload.val().activity_type));
    }
    return null;
  }

}
