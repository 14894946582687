import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

import { LogImpact } from './log-impact.model';
import { LogRequirement } from './log-requirement.model';
import { MitigationStrategy } from './mitigation-strategy.model';
import { Severity, SeverityConfig } from './severity.model';
import { Frequency, FrequencyConfig } from './frequency.model';

export class Log {
    id?: string;
    date_created: firebase.firestore.Timestamp;
    owner_id: string;
    change: string;
    mitigation_strategies?: MitigationStrategy[];
    impacts: LogImpact[];
    requirements?: LogRequirement[];
    upvotes?: { [userId: string]: true };
    upvotes_count?: number;
    top_x: { [userId: string]: true };
    top_x_count?: number;
    slide_id: string;
    project_id: string;
    activity_id?: string;
    is_new?: boolean;
    requirements_map?: { [questionId: string]: string };
    requirements_activity_map?: { [activityId: string]: true };
    log_severity?: Severity;
    log_frequency? : Frequency;
    countries_map?: { [country: string]: true };
}

export class EditableLog {
    log: Log;
    impacts: LogImpact[];
    requirements: LogRequirement[];
    mitigationStrategies: MitigationStrategy[];
}

export interface VotesAndTopXMap {
    upvotes: { [logId: string]: true }
    top_x: { [logId: string]: true }
}

export class LogSavedField {
    logKey: string;
    type?: string;
    data: LogImpact | LogRequirement | MitigationStrategy;
    defaultField: LogImpact | LogRequirement | MitigationStrategy;
    fieldKey: string;
}

export interface SetupOption {
    id?: string;
    key?: string;
    option: string;
    sequence: string;
}

export enum ChangeLogSetupFields {
    severity = 'severity',
    frequency = 'frequency',
    process = 'process',
    stakeholder = 'stakeholder',
    businessUnit = 'business_unit',
    changeMitigationSuggestion = 'change_mitigation_suggestion'
}

export interface AssignmentViewModel {
    projectId: string;
    process: SetupOption[];
    stakeholder: SetupOption[];
    businessUnit: SetupOption[];
    changeMitigationSuggestion: SetupOption[];
    severity: SeverityConfig;
    frequency: FrequencyConfig;
    hiddenSetupOptions: string[];
}


export interface SetupOptionsData {
    frequency: FrequencyConfig;
    severity: SeverityConfig;
    hidden_setup_options: string[];
}
