<form class="invite-form" [formGroup]="inviteForm">
  <!-- participants input -->
  <mat-form-field class="full-width">
    <mat-chip-list #participantsList>
      <mat-chip
        *ngFor="let user of participants"
        [selectable]="false"
        [removable]="true"
        (removed)="remove(user, 'participant')"
      >
        {{ user.email }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        matInput
        placeholder="Participant(s)"
        formControlName="participants"
        [matChipInputFor]="participantsList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, 'participant')"
        (input)="updateSubmitState('participant')"
      />
    </mat-chip-list>
  </mat-form-field>
  <!-- participants input error message -->
  <mat-error *ngIf="formSubmitted.participant && emailsErrorMessages['participants']">
    {{ emailsErrorMessages["participants"] }}
  </mat-error>
  <!-- /participants input error message -->
  <!-- /participants input -->

  <!-- observers input -->
  <mat-form-field class="full-width">
    <mat-chip-list #observersList>
      <mat-chip
        *ngFor="let users of observers"
        [selectable]="false"
        [removable]="true"
        (removed)="remove(users, 'observer')"
      >
        {{ users.email }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        matInput
        placeholder="Observer(s)"
        formControlName="observers"
        [matChipInputFor]="observersList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, 'observer')"
        (input)="updateSubmitState('observer')"
      />
    </mat-chip-list>
  </mat-form-field>
  <!-- observers input error message -->
  <mat-error *ngIf="formSubmitted.observer && emailsErrorMessages['observers']">
    {{ emailsErrorMessages["observers"] }}
  </mat-error>
  <!-- /observers input error message -->
  <!-- /observers input -->

  <!-- leaders input -->
  <mat-form-field class="full-width">
    <mat-chip-list #leadersList>
      <mat-chip
        *ngFor="let users of leaders"
        [selectable]="false"
        [removable]="true"
        (removed)="remove(users, 'leader')"
      >
        {{ users.email }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
      <input
        matInput
        placeholder="Leader(s)"
        formControlName="leaders"
        [matChipInputFor]="leadersList"
        [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
        [matChipInputAddOnBlur]="true"
        (matChipInputTokenEnd)="add($event, 'leader')"
        (input)="updateSubmitState('leader')"
      />
    </mat-chip-list>
  </mat-form-field>
  <!-- leaders input error message -->
  <mat-error *ngIf="formSubmitted.leader && emailsErrorMessages['leaders']">
    {{ emailsErrorMessages["leaders"] }}
  </mat-error>
  <!-- /leaders input error message -->
  <!-- /leaders input -->

  <!-- message textarea -->
  <mat-form-field class="full-width textarea">
    <textarea *ngIf="!isIeOrEdgeBrowser" matInput placeholder="Message" formControlName="message"></textarea>

    <!-- Only for IE and EDGE -->
    <textarea
      *ngIf="isIeOrEdgeBrowser"
      matInput
      matTextareaAutosize
      placeholder="Message"
      formControlName="message"
    ></textarea>
  </mat-form-field>
  <!-- /message textarea -->

  <!-- Begin Session Join Link -->
  <div class="session-link-button-area" *ngIf="joinSessionLinkEnabled">
    <button mat-button *ngIf="!passkeyEnabled" color="primary" (click)="togglePasskey()">
      <mat-icon>link</mat-icon>
      Enable link access
    </button>

    <button mat-button *ngIf="passkeyEnabled" color="primary" (click)="togglePasskey()">
      <mat-icon>link</mat-icon>
      Remove link access
    </button>
    <div *ngIf="passkeyEnabled" class="session-link-button-area__text">
      Anyone with the link will join the session as a participant
    </div>
  </div>

  <div *ngIf="passkeyEnabled">
    <div class="passkey-email">
      <mat-form-field class="passkey-field">
        <input
          matInput
          placeholder="Password"
          formControlName="passkeyControl"
          [type]="passkeyHidden ? 'password' : 'text'"
          [(ngModel)]="passkey"
          (keyup)="updatePasskey($event)"
        />
        <mat-icon matSuffix (click)="passkeyHidden = !passkeyHidden">
          {{ passkeyHidden ? "visibility" : "visibility_off" }}
        </mat-icon>
      </mat-form-field>

      <mat-checkbox
        color="primary"
        class="passkey-email__checkbox"
        [checked]="emailRequired"
        [value]="emailRequired"
        (change)="emailRequiredToggle()"
      >
        Make email required
      </mat-checkbox>
    </div>
    <mat-error *ngIf="passkeyInvalid()" class="passkey-error">
      Please set a password (4-100 characters). Link access won't work until this is done.
    </mat-error>
    <mat-form-field class="full-width-url">
      <input class="disabled_field" matInput [disabled]="true" [value]="url" />
      <button mat-icon-button class="copy-url" [disabled]="passkeyInvalid()" (click)="copyURL()">
        <mat-icon>content_copy</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <!-- End Session Join Link -->

  <!-- form actions -->
  <div
    class="invite-form__actions"
    [ngClass]="{
      'invite-form__actions': !isInviteFromAdmin,
      'invite-form-admin__actions': !!isInviteFromAdmin
    }"
  >
    <button
      mat-raised-button
      class="invite-form__action"
      color="primary"
      type="submit"
      [disabled]="inviteDisabled"
      (click)="invite()"
    >
      Invite
    </button>
    <button mat-raised-button class="invite-form__action" (click)="resetForm($event)">Cancel</button>
  </div>
  <!-- /form actions -->
</form>
