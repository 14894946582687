<div class="options-view-dialog-contatiner">
  <div class="title-wrapper options-view-dialog-content">
    <div mat-dialog-title class="title-content-wrapper">
      <div class="h3 title-text">Details</div>
      <ng-container *ngIf="demographics?.length">
        <div
          class="demographic-item"
          *ngFor="let demographicName of demographics; trackBy: trackByKey"
        >
          <span class="demographic">{{ demographicName }}</span>
        </div>
      </ng-container>
    </div>
    <button mat-icon-button class="title-icon-button" (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-dialog-content class="text">
    <ng-container *ngIf="selectedValues?.length">
      <div class="text-separator">Chosen</div>
      <div
        *ngFor="let selectedOption of selectedValues; trackBy: trackByKey"
        class="options-view-dialog-content chosen-options-item"
      >
        {{ selectedOption }}
      </div>
      <div class="line-between-sections"></div>
    </ng-container>
    <div class="text-separator">All options</div>
    <div
      *ngFor="let option of choicesValues; trackBy: trackByKey"
      class="options-view-dialog-content all-options-item"
    >
      {{ option }}
    </div>
  </mat-dialog-content>
  <div class="horizontal-separator"></div>
  <mat-dialog-actions class="options-view-dialog-content">
    <button
      cdkFocusInitial
      type="button"
      class="tt-button-filled"
      (click)="onClose()"
    >
      Got It
    </button>
  </mat-dialog-actions>
</div>
