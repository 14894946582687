import { EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { random, isEqual } from 'lodash';

import { notQuestionResponseTypes } from '@app/apps/apps.component.forms-constants';
import { responseType } from '@app/core/models/question.model';
import { ReviewState, AlignmentState, FormStatus, ActiveDemographics } from '@app/core/models';

export class Form {
    key?: string;
    activity_id: string;
    parent_form_id?: string;
    stateChangeType?: string;
    questions?: FormQuestion[];
    demographics_class_id?: string;
    active_demographics?: ActiveDemographics;
    review_id?: string;
    state?: ReviewState;
    repeatable_section_id?: string;
    repeatable_sections_ids?: { [section_id: string]: boolean };
    alignment_state?: FormStatus | AlignmentState;
    workbook_sheet_id?: string;
}

export class FormQuestionMetaData {
    key?: string;
    stateChangeType?: string;
}

export interface RepeatableQuestionsIds {
    [question_id: string]: boolean | { [demographic_id: string]: boolean };
}

export type RepeatableNote = string | { [demographic_id: string]: string };

export class FormQuestion extends FormQuestionMetaData {
    question: string;
    description?: string;
    required?: boolean;
    sequence: number;
    options: QuestionOptions;
    activity_id: string;
    user_id: string;
    form_id: string;
    number_responses: number;
    response_type: responseType;
    response_type_icon: string;
    subform_id?: string; // For 'subform' response type
    section_name?: string;
    form_branching?: FormBranching;
    video_name?: string;
    video_url?: string;
    image_url?: string;
    demographics?: 'all' | string[];
    demographics_class_id?: string; // For 'subform' response type
    repeatable_question_id?: string; // For repeated 'subform' - source question id
    repeatable_questions_ids?: RepeatableQuestionsIds; // for repeatable 'subform' source - questions ids to repeat
    repeatable_note?: RepeatableNote; // For repeatable 'subform'
    accepted?: boolean | { [demographicKey: string]: boolean }; // Alignment is on
    workbook_sheet_id?: string;
    change_workbook_sheet?: boolean;
    map_answer?: string;
    map_options_enable?: boolean;
    move_to_jira?: boolean;
    jira_issue_type?: string;

    static isRequired(question: FormQuestion): boolean {
        if (!question || !question.options) {
            return false;
        }

        const cannotBeRequiredResponseTypes = notQuestionResponseTypes.filter(answerType => answerType !== 'subform');

        if (cannotBeRequiredResponseTypes.includes(question.response_type)) {
            return false;
        }

        return !!(question.options.is_section_required || question.options.is_required);
    }
}

export class FormBranching {
    // branching options for the current question
    question_id: string; // question selected to hide/show off of
    condition: string; // string housing the condition to toggle the hide show
    display: boolean; // hide/show flag
    and_or?: boolean; // true if AND. Used by Single and Multi Select
    value?: any; // value field for numeric form branching
}

export class QuestionOptions {
    other_option?: boolean;
    range?: {
        min: number;
        max: number;
    };

    step?: number;
    scale?: number;
    is_required?: boolean;
    is_section_required?: boolean;
    description?: string; // Question description for the Form activity
    choices?:
        | {
              // Single, Multi choices; Slider, Star voting labels
              [key: string]: OptionsChoice;
          }
        | OptionsChoice[];

    has_range?: boolean;
    repeatable?: boolean; // only for sections ('subform' response type)
    section_note?: boolean; // only for sections ('subform' response type)
    pin_top_question?: boolean; // only for repeatable sections ('subform' response type)
    workbook_map_other_option?: string;
}

export class OptionsChoice {
    key?: string;
    description: string;
    value: any;
    sequence: number;
    map_option_answer?: string;

    constructor(sequence: number, value: any = '', description: string = '') {
        this.description = description;
        this.value = value;
        this.sequence = sequence;
    }
}

export const testOptionsChoice = {
    key: 'choiceKey',
    description: 'choice description',
    value: 'choice1',
    sequence: 1,
    map_option_answer: true,
};

export class FormComponentModel {
    display_name: string;
    icon: string;
    type: string;
    sequence: number;
    active: boolean;
    key?: string;
}

// extension of FormControl class
// to have ability to set/update additionally information for single form response
interface InfoData {
    [infoTitle: string]: string | number | boolean;
}
export class FormControlInfo extends FormControl {
    info: InfoData;
    infoChanges: EventEmitter<InfoData> = new EventEmitter();

    setInfoMessage(infoData: InfoData): void {
        const control = <FormControlInfo>this;

        if (!control.info || (control.info && !isEqual(control.info, infoData))) {
            control.info = control.info ? { ...control.info, ...infoData } : infoData;
            this.infoChanges.emit();
        }
    }
}

export class FormPinnedQuestion {
    question: string;
    section: string;
    demographic: string;
    response?: FormControl;
    responseType?: responseType;
    data?: string | string[];
    scrollPosition?: number;
}

export const testFormComponentModel = <FormComponentModel>{
    display_name: 'Number',
    icon: 'description',
    type: 'number',
    sequence: 1,
    active: true,
    key: 'testNumber',
};

export class FormResponse extends FormQuestionMetaData {
    value?: any;
    owner_id: string;
    form_id: string; // The form_id may represent a form or form section
    question_id: string;
    demographic_id?: string;
    parent_demographic_id?: string;
    type?: string;
    date_written: string;
    initial_response?: any;
    initial_response_owner_id?: string;
    form_status?: FormStatus;
    comments_count?: number;
    custom_name: string | null;
}

export class AnsweredQuestion {
    [questionId: string]: boolean; // for questions inside section it will look like sectionQuestionKey:questionKey
}

export interface AnsweredQuestionModel {
    [questionId: string]:
        | boolean
        | {
              [demographicId: string]:
                  | boolean
                  | {
                        [sectionDemographicId: string]: boolean;
                    };
          };
}

export class WorkbookObject {
    hasWorkbookAccess: boolean;
    isParentWorkbookSheetChosen: boolean;
    isSectionWithDemographicsClass: boolean;
}

export type FormBranchingVisibility =
    | boolean
    | { [parentDemographicKey: string]: boolean }
    | { [parentDemographicKey: string]: { [sectionDemographicKey: string]: boolean } };

export class ActiveDemographicKeysStorage {
    [demographicKey: string]: boolean;
}

export const testFormResponse = <FormResponse>{
    key: `testFormResponse${random(1, 1000)}`,
    value: 8,
    form_id: `testForm${random(1, 1000)}`,
    question_id: `testQuestion${random(1, 1000)}`,
    owner_id: `testUser${random(1, 1000)}`,
};

export const testDemographicFormResponse = <FormResponse>{
    key: `testFormResponse${random(1, 1000)}`,
    value: 'A demographic answer',
    form_id: `testForm${random(1, 1000)}`,
    question_id: `testQuestion${random(1, 1000)}`,
    demographic_id: `testDemographic${random(1, 1000)}`,
    owner_id: `testUser${random(1, 1000)}`,
};

export const testFormQuestion = <FormQuestion>{
    number_responses: 2,
    form_id: 'formKey',
    question: 'What is happening?',
    response_type: 'single',
    response_type_icon: 'radio_button_checked',
    sequence: 1,
    activity_id: '',
    user_id: '',
    options: {
        choices: {},
    },
};

export const testForm = <Form>{
    activity_id: `testActivity${random(1, 1000)}`,
    parent_form_id: `testParentForm${random(1, 1000)}`,
    questions: [testFormQuestion],
};
