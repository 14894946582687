import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { DeploymentViewEditState } from '../../models';
import { Demographic } from '@thinktank/common-lib';

export interface DeploymentEditorFacade {
    vm$: Observable<DeploymentViewEditState>;

    updateDeploymentDescription(deploymentId: string, newDescription: string): Promise<void>;

    updateDemographics(deploymentId: string, demographicToAdd: Demographic, moduleId: string): Promise<void>;

    removeDemographicsById(deploymentId: string, demographicId: string, moduleId: string): Promise<void>;
}

export const DeploymentEditorFacadeToken = new InjectionToken<DeploymentEditorFacade>('DeploymentEditorFacade');
