import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, of, combineLatest } from 'rxjs';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';

import {
    AllSelectedDemographics,
    Demographic,
    DemographicsClass,
    DemographicNames,
    SelectedDemographics,
    Activity,
} from '@app/core/models';
import { listWithKeys, sortBySequenceAsc } from '@app/core/utils';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseActivityService } from '@app/core/services/activity.service';
import { AppState } from '@app/root-store/state';
import { DemographicsStoreSelectors, DemographicsStoreActions } from '@app/root-store/features/demographics';

@Injectable()
export class FirebaseDemographicsService {
    constructor(
        private db: AngularFireDatabase,
        private authUserService: AuthenticatedUserService,
        private fbActivityService: FirebaseActivityService,
        private store$: Store<AppState>,
        private _angularFireFunctions: AngularFireFunctions,
    ) {}

    getDemographicsClasses(activityKey: string): Observable<DemographicsClass[]> {
        return listWithKeys<DemographicsClass>(
            this.db.list<DemographicsClass>(`activities/${activityKey}/demographics/`),
        ).pipe(
            map(sortBySequenceAsc),
            map((demographicsClasses: DemographicsClass[]) => {
                return demographicsClasses.map(demographicsClass => {
                    const options = demographicsClass.options || {};
                    const unsortedDemographics = Object.keys(options).map(demographicKey => ({
                        ...options[demographicKey],
                        key: demographicKey,
                    }));

                    demographicsClass.demographics = sortBySequenceAsc(unsortedDemographics);

                    return demographicsClass;
                });
            }),
        );
    }

    // EXCLUSIVE TO THE DEMOGRAPHICS ACTIVITY WILL NOT WORK OUTSIDE OF THAT ACTIVITY
    getDemographicDescription(activityKey: string, demographicKey: string): Observable<string> {
        return this.db
            .object<DemographicsClass>(`/activities/${activityKey}/demographics/${demographicKey}`)
            .valueChanges()
            .pipe(
                take(1),
                map(demographic => {
                    return demographic.description ? `${demographic.description}` : '';
                }),
            );
    }

    getDemographicName(demographicKey: string): Observable<string> {
        return this.db.object<string>(`/ssot/_demographics/${demographicKey}/name`).valueChanges();
    }

    // TODO: this method is using in many components
    getDemographicNamesDictionary(appKey: string): Observable<DemographicNames> {
        return listWithKeys<Demographic>(
            this.db.list<Demographic>('/ssot/_demographics', ref => ref.orderByChild('app_id').equalTo(appKey)),
        ).pipe(
            map((demographics: Demographic[]) => {
                const demographicNamesDictionary = {};

                demographics.forEach((demographic: Demographic) => {
                    demographicNamesDictionary[demographic.key] = demographic.name;
                });

                return demographicNamesDictionary;
            }),
        );
    }

    addDemographicsClass(activityKey: string, appKey: string): Promise<string> {
        const updates = {};
        const demographicsClassKey = this.db.createPushId();

        return this.getDemographicsClasses(activityKey)
            .pipe(take(1))
            .toPromise()
            .then(demographicsClasses => {
                const demographicsClass = new DemographicsClass(demographicsClasses.length + 1);

                updates[`activities/${activityKey}/demographics/${demographicsClassKey}`] = demographicsClass;
                updates[`ssot/_demographics_classes/${demographicsClassKey}`] = this.mapDemographicsClassForSsot(
                    demographicsClass,
                    activityKey,
                    appKey,
                );
                updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
                updates[`apps/${appKey}/date_last_updated`] = Date.now();

                return this.db.object('/').update(updates);
            })
            .then(() => demographicsClassKey);
    }

    updateDemographicsClassField(
        activityKey: string,
        appKey: string,
        demographicsClassKey: string,
        fieldName: string,
        value: any,
    ): Promise<void> {
        return this.updateDemographicsClassesField(activityKey, appKey, fieldName, {
            [demographicsClassKey]: value,
        });
    }

    updateDemographicsClassesField(
        activityKey: string,
        appKey: string,
        fieldName: string,
        fieldUpdates: {
            [demographicsClassKey: string]: any;
        },
    ): Promise<void> {
        const updates = Object.keys(fieldUpdates).reduce((result: any, demographicsClassKey: string) => {
            result[`activities/${activityKey}/demographics/${demographicsClassKey}/${fieldName}`] =
                fieldUpdates[demographicsClassKey];
            result[`ssot/_demographics_classes/${demographicsClassKey}/${fieldName}`] =
                fieldUpdates[demographicsClassKey];
            result[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
            result[`apps/${appKey}/date_last_updated`] = Date.now();

            return result;
        }, {});

        return this.db.object('/').update(updates);
    }

    // TODO: remove collaboration requests for this class
    removeDemographicsClass(activityKey: string, appKey: string, demographicsClassKey: string): Promise<void> {
        return this.removeDemographicsClasses(activityKey, appKey, [demographicsClassKey]);
    }

    removeDemographicsClasses(activityKey: string, appKey: string, demographicsClassKeys: string[]): Promise<void> {
        const updates = {};

        demographicsClassKeys.forEach(demographicsClassKey => {
            updates[`activities/${activityKey}/demographics/${demographicsClassKey}`] = null;
            updates[`ssot/_demographics_classes/${demographicsClassKey}`] = null;
            updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
            updates[`apps/${appKey}/date_last_updated`] = Date.now();
        });

        return this.db.object('/').update(updates);
    }

    getDemographics(demographicsClassKey: string): Observable<Demographic[]> {
        return this.store$.pipe(
            select(DemographicsStoreSelectors.demographicsSelector, { classKey: demographicsClassKey }),
        );
    }

    getAllDemographicsByClassList(
        demographicsClassKeys: string[],
    ): Observable<{ [demographicsClassKey: string]: Demographic[] }[]> {
        if (!demographicsClassKeys) {
            return of([]);
        }

        return combineLatest(
            demographicsClassKeys.map(demographicsClassKey => {
                return this.store$.pipe(select(DemographicsStoreSelectors.demographicsObjectSelector)).pipe(
                    tap(demographicsObject => {
                        if (!demographicsObject[demographicsClassKey]) {
                            this.store$.dispatch(DemographicsStoreActions.getDemographics({ demographicsClassKey }));
                        }
                    }),
                    mergeMap(() => {
                        return this.getDemographics(demographicsClassKey).pipe(
                            map((demographics: Demographic[]) => {
                                return {
                                    [demographicsClassKey]: demographics,
                                };
                            }),
                        );
                    }),
                );
            }),
        );
    }

    addDemographic(
        activityKey: string,
        appKey: string,
        demographicsClassKey: string,
        index?: number,
        name?: string,
    ): Promise<string> {
        const updates = {};
        const demographicKey = this.db.createPushId();

        return this.getDemographics(demographicsClassKey)
            .pipe(take(1))
            .toPromise()
            .then(demographics => {
                const demographic = new Demographic(
                    Number.isInteger(index) ? index + 1 : demographics.length + 1,
                    name,
                );

                updates[`activities/${activityKey}/demographics/${demographicsClassKey}/options/${demographicKey}`] =
                    demographic;
                updates[`ssot/_demographics/${demographicKey}`] = this.mapDemographicForSsot(
                    demographic,
                    demographicsClassKey,
                    activityKey,
                    appKey,
                );
                updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
                updates[`apps/${appKey}/date_last_updated`] = Date.now();

                return this.db.object('/').update(updates);
            })
            .then(() => demographicKey);
    }

    async addDemographics(
        activityKey: string,
        appKey: string,
        demographicsClassKey: string,
        demographicsNames: string[],
    ): Promise<void> {
        const existingDemographicNames = await listWithKeys(
            this.db.list<Demographic>('/ssot/_demographics', ref =>
                ref.orderByChild('class_id').equalTo(demographicsClassKey),
            ),
        )
            .pipe(
                take(1),
                map(demographics => demographics.map(demographic => demographic.name.toLocaleLowerCase().trim())),
            )
            .toPromise();

        //Removing the first empty element from the array for the correct sequence of demographics
        if (!existingDemographicNames[0]) {
            existingDemographicNames.shift();
        }

        for (let i = 0; i < demographicsNames.length; i++) {
            const demographicName = demographicsNames[i];
            const trimmedValue = demographicName?.toLocaleLowerCase()?.trim();

            if (!trimmedValue || existingDemographicNames.includes(trimmedValue)) {
                continue;
            }
            existingDemographicNames.push(trimmedValue);
            const demographicNameIndex = existingDemographicNames.length - 1;

            await this.addDemographic(activityKey, appKey, demographicsClassKey, demographicNameIndex, demographicName);
        }
    }

    updateDemographicField(
        activityKey: string,
        appKey: string,
        demographicsClassKey: string,
        demographicKey: string,
        fieldName: string,
        value: any,
    ): Promise<void> {
        return this.updateDemographicsField(activityKey, appKey, demographicsClassKey, fieldName, {
            [demographicKey]: value,
        });
    }

    updateDemographicsField(
        activityKey: string,
        appKey: string,
        demographicsClassKey: string,
        fieldName: string,
        fieldUpdates: {
            [demographicKey: string]: any;
        },
    ): Promise<void> {
        const updates = Object.keys(fieldUpdates).reduce((result: any, demographicKey: string) => {
            result[
                `activities/${activityKey}/demographics/${demographicsClassKey}/options/${demographicKey}/${fieldName}`
            ] = fieldUpdates[demographicKey];
            result[`ssot/_demographics/${demographicKey}/${fieldName}`] = fieldUpdates[demographicKey];

            return result;
        }, {});

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        return this.db.object('/').update(updates);
    }

    async removeDemographic(
        activityKey: string,
        appKey: string,
        demographicsClassKey: string,
        demographicKey: string,
    ): Promise<void> {
        await this.removeDemographics(activityKey, appKey, {
            [demographicsClassKey]: [demographicKey],
        });
        await this._angularFireFunctions
            .httpsCallable('removeResponsesByDemographicId')({
                userId: this.authUserService.getCurrentUserId(),
                sessionId: appKey,
                demographicId: demographicKey,
            })
            .toPromise();
    }

    removeDemographics(
        activityKey: string,
        appKey: string,
        mapDemographicsKeys: { [demographicsClassKey: string]: string[] },
    ): Promise<void> {
        const updates = {};

        Object.keys(mapDemographicsKeys).forEach(demographicsClassKey => {
            const demographicKeys = mapDemographicsKeys[demographicsClassKey];

            demographicKeys.forEach(demographicKey => {
                updates[`activities/${activityKey}/demographics/${demographicsClassKey}/options/${demographicKey}`] =
                    null;
                updates[`ssot/_demographics/${demographicKey}`] = null;
            });
            updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
            updates[`apps/${appKey}/date_last_updated`] = Date.now();
        });

        return this.db.object('/').update(updates);
    }

    setSelectedDemographics(
        activityKey: string,
        appKey: string,
        demographicClassKey: string,
        selectedDemographicKeys: { [demographicKey: string]: boolean },
    ): Promise<void> {
        const updates = {};
        const userId = this.authUserService.getCurrentUserId();
        const selectedDemographics = <SelectedDemographics>{
            activity_id: activityKey,
            options: selectedDemographicKeys,
        };

        updates[`users/${userId}/selectedDemographics/${demographicClassKey}`] = selectedDemographics;
        updates[`ssot/_selected_demographics/${userId}_${demographicClassKey}`] = this.mapSelectedDemographicsForSSOT(
            selectedDemographics,
            appKey,
            demographicClassKey,
        );

        return this.db.object('/').update(updates);
    }

    setParticipantsSubmittedDemographics(appId: string, instanceUrl: string): Promise<void> {
        const updates = {};
        const infoPath = `ssot/_demographics_submit_info/${appId}`;
        const participantId = this.authUserService.getCurrentUserId();

        updates[`${infoPath}/instance_url`] = instanceUrl;
        updates[`${infoPath}/participants/${participantId}`] = true;

        return this.db.object('/').update(updates);
    }

    getAllSelectedDemographics(): Observable<AllSelectedDemographics> {
        const userId = this.authUserService.getCurrentUserId();

        return this.db.object<AllSelectedDemographics>(`/users/${userId}/selectedDemographics`).valueChanges();
    }

    getDemographicsClassesByAppId(appId: string): Observable<DemographicsClass[]> {
        return listWithKeys<DemographicsClass>(
            this.db.list<DemographicsClass>('/ssot/_demographics_classes', ref =>
                ref.orderByChild('app_id').equalTo(appId),
            ),
        );
    }

    getAllSelectedDemographicsInApp(appKey: string): Observable<string[]> {
        let allSelectedDemographics: AllSelectedDemographics;

        return this.getAllSelectedDemographics().pipe(
            mergeMap((selectedDemographics: AllSelectedDemographics) => {
                allSelectedDemographics = selectedDemographics;

                return listWithKeys<DemographicsClass>(
                    this.db.list<DemographicsClass>('/ssot/_demographics_classes', ref =>
                        ref.orderByChild('app_id').equalTo(appKey),
                    ),
                );
            }),
            map((demographicClasses: DemographicsClass[]) => {
                if (!allSelectedDemographics || !demographicClasses) {
                    return [];
                }

                return demographicClasses
                    .map((demographicClass: DemographicsClass) => allSelectedDemographics[demographicClass.key])
                    .filter(val => !!val)
                    .reduce((result: string[], selectedDemographic: any) => {
                        const selectedDemographicKeys = Object.keys(selectedDemographic.options || {});

                        return [...result, ...selectedDemographicKeys];
                    }, []);
            }),
        );
    }

    getSelectedDemographicsForUser(userKey: string, classKey: string): Observable<Demographic[]> {
        return this.store$.pipe(select(DemographicsStoreSelectors.selectedDemographicsSelector, { classKey, userKey }));
    }

    getSelectedDemographicsByClass(classKey: string): Observable<Demographic[]> {
        const userId = this.authUserService.getCurrentUserId();

        return this.store$.select(DemographicsStoreSelectors.selectedDemographicsSelector, {
            classKey,
            userKey: userId,
        });
    }

    getAllSelectedDemographicsByClass(classKey: string): Observable<any> {
        return combineLatest(
            this.db
                .list<SelectedDemographics>('/ssot/_selected_demographics', ref =>
                    ref.orderByChild('class_id').equalTo(classKey),
                )
                .valueChanges(),
            this.getDemographics(classKey),
        ).pipe(
            map(([selectedDemographics, classDemographics]) => {
                const allSelectedDemographics = selectedDemographics.reduce((result, selectedDemographic) => {
                    const userKey = selectedDemographic.user_id;

                    result[userKey] = selectedDemographic.options;

                    return result;
                }, {});

                Object.keys(allSelectedDemographics).forEach(userKey => {
                    Object.keys(allSelectedDemographics[userKey]).forEach(demographicKey => {
                        allSelectedDemographics[userKey][demographicKey] = classDemographics.find(demographic => {
                            return demographic.key === demographicKey;
                        });
                    });
                });

                return allSelectedDemographics;
            }),
        );
    }

    getAllUsersOfSelectedDemographics(classKey: string): Observable<{ [demographicKey: string]: string[] }> {
        return combineLatest(
            this.db
                .list<SelectedDemographics>('/ssot/_selected_demographics', ref =>
                    ref.orderByChild('class_id').equalTo(classKey),
                )
                .valueChanges(),
            this.getDemographics(classKey),
        ).pipe(
            map(([selectedDemographics, classDemographics]) => {
                const AllUsersOfSelectedDemographics = selectedDemographics.reduce((result, selectedDemographic) => {
                    const demographics = Object.keys(selectedDemographic.options || {});
                    const userKey = selectedDemographic.user_id;

                    demographics.forEach(demographicKey => {
                        if (!classDemographics.find(demographic => !!(demographic.key === demographicKey))) {
                            return;
                        }

                        if (!result[demographicKey]) {
                            result[demographicKey] = [userKey];
                        } else {
                            result[demographicKey].push(userKey);
                        }
                    });

                    return result;
                }, {});

                return AllUsersOfSelectedDemographics;
            }),
        );
    }

    getSelectedDemographicsByActivity(activityKey: string): Observable<Demographic[]> {
        const userId = this.authUserService.getCurrentUserId();

        return combineLatest(
            this.fbActivityService.getActivityDemographicsClassKey(activityKey),
            this.store$.pipe(select(DemographicsStoreSelectors.demographicsObjectSelector)),
            this.store$.pipe(select(DemographicsStoreSelectors.selectedDemographicsObjectSelector)),
        ).pipe(
            tap(([demographicsClassKey, demographicsObject, selectedDemographicsObject]) => {
                if (!demographicsObject[demographicsClassKey]) {
                    this.store$.dispatch(DemographicsStoreActions.getDemographics({ demographicsClassKey }));
                }

                if (!selectedDemographicsObject[`${demographicsClassKey}_${userId}`]) {
                    this.store$.dispatch(
                        DemographicsStoreActions.getSelectedDemographics({ userId, demographicsClassKey }),
                    );
                }
            }),
            map(([demographicsClassKey]) => demographicsClassKey),
            mergeMap((demographicsClassKey: string) => {
                if (!demographicsClassKey) {
                    return of([]);
                }

                return this.getSelectedDemographicsByClass(demographicsClassKey);
            }),
        );
    }

    getActivityDemographicClasses(appKey: string, activityKey: string): Observable<DemographicsClass[]> {
        let selectedActivity: Activity;

        return this.fbActivityService.getActivitySsot(activityKey).pipe(
            mergeMap((activity: Activity) => {
                selectedActivity = activity;
                if (activity.use_demographics) {
                    return this.fbActivityService.getDemographicsActivityKey(appKey);
                }

                return of('');
            }),
            mergeMap((demographicsKey: string) => {
                if (demographicsKey) {
                    return this.getDemographicsClasses(demographicsKey);
                }

                return of([]);
            }),
            map((demographicsClasses: DemographicsClass[]) => {
                // Remove demographic class which is selected in parent form (activity)
                if (demographicsClasses.length && selectedActivity.demographics_class_id) {
                    const activityClassIndex = demographicsClasses.findIndex((demographicsClass: DemographicsClass) => {
                        return demographicsClass.key === selectedActivity.demographics_class_id;
                    });

                    if (activityClassIndex !== -1) {
                        demographicsClasses.splice(activityClassIndex, 1);

                        return demographicsClasses;
                    }
                }

                return demographicsClasses;
            }),
        );
    }

    getFormActivityDemographics(
        appKey: string,
        activityKey: string,
        subformClassKey?: string,
    ): Observable<Demographic[]> {
        return this.fbActivityService.getActivityDemographics(activityKey).pipe(
            mergeMap((useDemographics: boolean) => {
                if (useDemographics) {
                    // if question inside subform get demographic_class_id
                    // if no demographic_class_id return empty demographicsList
                    const demographicKey = !!subformClassKey
                        ? of(subformClassKey)
                        : combineLatest(
                              this.fbActivityService.getActivityDemographicsClassKey(activityKey),
                              this.store$.pipe(select(DemographicsStoreSelectors.demographicsObjectSelector)),
                          ).pipe(
                              tap(([demographicsClassKey, demographicsObject]) => {
                                  if (!!demographicsClassKey && !demographicsObject[demographicsClassKey]) {
                                      this.store$.dispatch(
                                          DemographicsStoreActions.getDemographics({ demographicsClassKey }),
                                      );
                                  }
                              }),
                              map(([demographicClassKey]) => demographicClassKey),
                          );

                    return demographicKey;
                }

                return of('');
            }),
            mergeMap((demographicClassKey: string) => {
                if (demographicClassKey) {
                    return this.store$.pipe(select(DemographicsStoreSelectors.demographicsObjectSelector)).pipe(
                        tap(demographicsObject => {
                            if (!demographicsObject[demographicClassKey]) {
                                this.store$.dispatch(
                                    DemographicsStoreActions.getDemographics({
                                        demographicsClassKey: demographicClassKey,
                                    }),
                                );
                            }
                        }),
                        mergeMap(() => {
                            return this.getDemographics(demographicClassKey).pipe(
                                map((demographics: Demographic[]) => demographics),
                            );
                        }),
                    );
                }

                return of([]);
            }),
            map(demographicsList => {
                return demographicsList.filter(demographic => demographic.app_id === appKey);
            }),
        );
    }

    getDemographicsActivityKey(appKey: string): Observable<string> {
        return this.db
            .list<Activity>('/ssot/_activities', ref => ref.orderByChild('app_id').equalTo(appKey))
            .snapshotChanges()
            .pipe(
                map(activities => {
                    const demographicsActivity = activities.filter(
                        activity => activity.payload.val().activity_type === 'demographics',
                    )[0];

                    return demographicsActivity.key || null;
                }),
            );
    }

    private mapDemographicsClassForSsot(
        demographicsClass: DemographicsClass,
        activityKey: string,
        appKey: string,
    ): DemographicsClass {
        return <DemographicsClass>{
            ...demographicsClass,
            activity_id: activityKey,
            app_id: appKey,
            owner_id: this.authUserService.getCurrentUserId(),
        };
    }

    private mapDemographicForSsot(
        demographic: Demographic,
        demographicsClassKey: string,
        activityKey: string,
        appKey: string,
    ): Demographic {
        return <Demographic>{
            ...demographic,
            class_id: demographicsClassKey,
            activity_id: activityKey,
            app_id: appKey,
            owner_id: this.authUserService.getCurrentUserId(),
        };
    }

    private mapSelectedDemographicsForSSOT(
        selectedDemographics: SelectedDemographics,
        appKey: string,
        classKey: string,
    ): SelectedDemographics {
        return <SelectedDemographics>{
            ...selectedDemographics,
            app_id: appKey,
            class_id: classKey,
            user_id: this.authUserService.getCurrentUserId(),
        };
    }
}
