export enum ExportableFormat {
    XML = 'xml',
    JSON = 'json',
    JiraJSON = 'jira_json',
    Workbook = 'workbook',
    Logs = 'logs',
    Session = 'session',
    Billing = 'billing',
    UserReport = 'user report',
    TemplateReport = 'template report',
    SessionReport = 'session report'
}

export const ExportableFormatsMap = {
    [ExportableFormat.Workbook]: 'Workbook',
    [ExportableFormat.JSON]: 'ThinkTank Forms JSON',
    [ExportableFormat.XML]: 'XML Workbook',
    [ExportableFormat.JiraJSON]: 'Jira',
    [ExportableFormat.Billing]: 'User Report',
    [ExportableFormat.UserReport]: 'User Report',
    [ExportableFormat.TemplateReport]: 'Template Report',
    [ExportableFormat.SessionReport]: 'Session Report'
};
