<div class="create-deployment-view-page" [@slide]="activeView">
  <div>
    <ng-content select="[deployments]"></ng-content>
  </div>

  <div>
    <ng-content select="[deployment_description]"></ng-content>
  </div>

  <div>
    <ng-content select="[client_name]"></ng-content>
  </div>

  <div>
    <ng-content select="[modules]"></ng-content>
  </div>

  <div>
    <ng-content select="[overlay_template]"></ng-content>
  </div>

  <div>
    <ng-content select="[demographics]"></ng-content>
  </div>

  <div>
    <ng-content select="[deployment_view]"></ng-content>
  </div>

  <div>
    <ng-content select="[participant_invite]"></ng-content>
  </div>

  <div>
    <ng-content select="[congrats_screen]"></ng-content>
  </div>
</div>
