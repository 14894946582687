import { createAction, props } from '@ngrx/store';

import { ActiveDemographics } from '@app/core/models';

const getActiveFormDemographicKeysType = '[Demographics] get active form demographics keys';
const getActiveFormDemographicKeysSuccessType = '[Demographics] get active form demographics keys success';
const getActiveFormDemographicKeysFailType = '[Demographics] get active form demographics keys fail';

const getParentFormActiveDemographicsType = '[Demographics] get parent active form demographics keys';
const getParentFormActiveDemographicsSuccessType = '[Demographics] get parent active form demographics keys success';
const getParentFormActiveDemographicsFailType = '[Demographics] get parent active form demographics keys fail';

export const getActiveFormDemographicKeys = createAction(
    getActiveFormDemographicKeysType,
    props<{ userId: string, demographicsClassKey: string, formKey: string }>()
);

export const getActiveFormDemographicKeysSuccess = createAction(
    getActiveFormDemographicKeysSuccessType,
    props<{ userId: string, demographicsClassKey: string, formKey: string, keys: string | string[] }>()
);

export const getActiveFormDemographicKeysFail = createAction(
    getActiveFormDemographicKeysFailType,
    props<{ errorMessage: string }>()
);

export const getParentFormActiveDemographics = createAction(
    getParentFormActiveDemographicsType,
    props<{ formKey: string }>()
);

export const getParentFormActiveDemographicsSuccess = createAction(
    getParentFormActiveDemographicsSuccessType,
    props<{ formKey: string, activeDemographics: ActiveDemographics }>()
);

export const getParentFormActiveDemographicsFail = createAction(
    getParentFormActiveDemographicsFailType,
    props<{ errorMessage: string }>()
);
