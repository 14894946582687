import { Inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TokenData } from '@app/login/services/token-data';
import { environment } from '@env/environment';

@Injectable()
export class DbTokenGenerationService {
    private tokenHost = !!environment['aws'] ? `https://${environment['aws']['apiGatewayUrl']}` : '';

    private tokenPath = 'token';

    constructor(private http: HttpClient, @Inject('Window') private window: Window) {}

    generateFirebaseToken(tokenData: TokenData, apiToken: string): Observable<any> {
        let prefix = this.window.location.host;

        // remove :port
        let n = prefix.indexOf(':');

        if (n > 0) {
            prefix = prefix.substring(0, n);
        }

        // remove anything after first dot
        n = prefix.indexOf('.');
        if (n > 0) {
            prefix = prefix.substring(0, n);
        }

        const headers = {
            headers: new HttpHeaders().set('Authorization', apiToken),
        };

        const url = `${this.tokenHost}/${prefix}/${this.tokenPath}`;

        return this.generateToken(url, tokenData, headers);
    }

    generateToken(url: string, tokenData: TokenData, headers, tryCount = 0): Observable<any> {
        if (tryCount >= 3) {
            throw throwError('Generate token failed');
        }

        try {
            return this.http.post(url, tokenData, headers);
        } catch (e) {
            console.log(e);

            return this.generateToken(url, tokenData, headers, tryCount + 1);
        }
    }
}
