import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';

import { trackById, FormItem, Demographic } from '@thinktank/common-lib';
import { FormGroupHandlerFactory } from '../form-group-handlers/form-group-handler.factory';
import { FormState } from '../../../models';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-form-edit',
    templateUrl: './form-edit.component.html',
    styleUrls: ['./form-edit.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormEditComponent {
    vm$ = this.formFacade.vm$
      .pipe(
          tap((viewModel) => {
              this.buildOrUpdateForm(viewModel);
          })
      );

    form: FormGroup;
    trackById = trackById;

    constructor(
        private formBuilder: FormBuilder,
        private formGroupHandlerFactory: FormGroupHandlerFactory,
        @Inject(DeploymentFormFacadeToken) private formFacade: DeploymentFormFacade
    ) {
        this.form = this.formBuilder.group({}, { updateOn: 'blur' });
    }

    onFinish(deploymentId: string, formId: string): void {
        this.formFacade.navigateToLocation([
            'deployment',
            deploymentId,
            'form',
            formId
        ]);
    }

    private hasQuestionsAndChosenDemographics(formItems: FormItem[], assignedDemographics: Demographic[]): boolean {
        return formItems.length > 0 && assignedDemographics.length > 0;
    }

    private buildOrUpdateForm(formState: FormState) {
        const formItems = formState.form.items;
        const formAssignedDemographics = formState.assignedDemographics;
        if (this.hasQuestionsAndChosenDemographics(formItems, formAssignedDemographics)) {
            const formExists = this.formExists;
            formItems.forEach((item) => {
                const formControlHandler = this.formGroupHandlerFactory.build(item.type, formState.form.global);
                if (formExists) {
                    formControlHandler.update(this.form, item, formAssignedDemographics);
                } else {
                    const formControl = formControlHandler.build(item, formAssignedDemographics);
                    this.form.addControl(item.id, formControl);
                }
            });
        }

        this.form.disable();
    }

    private get formExists(): boolean {
        return Object.keys(this.form.controls).length > 0;
    }
}
