import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { Observable } from 'rxjs';
import { isArray } from 'lodash';

import {
    FormStatusModel,
    FormStatusObject,
    FormStatus,
    UserReadyForAlignment,
} from '@app/core/models/form-status.model';
import { Demographic } from '@app/core/models';

@Injectable({
    providedIn: 'root',
})
export class StateService {
    constructor(private db: AngularFireDatabase) {}

    getFormActivityState(activityKey: string, formKey: string): Observable<FormStatusModel> {
        return this.db.object<FormStatusModel>(`/ssot/formStatus/${activityKey}/${formKey}`).valueChanges();
    }

    updateFormState(
        activityKey: string,
        formKey: string,
        status: FormStatusObject,
        useDemographics: boolean,
        demographicKeys: string[],
    ): Promise<void> {
        const updates = {};

        if (!useDemographics) {
            updates[`/ssot/formStatus/${activityKey}/${formKey}`] = status;
        } else {
            demographicKeys.forEach(demographicKey => {
                updates[`/ssot/formStatus/${activityKey}/${formKey}/${demographicKey}`] = status;
            });
        }

        return this.db.object('/').update(updates);
    }

    resetFormState(
        activityKey: string,
        useDemographics: boolean,
        mainFormKey?: string,
        otherFormKeys?: string[],
        demographicKeys?: string[],
    ): Promise<void> {
        const updates = {};

        if (!useDemographics) {
            updates[`/ssot/formStatus/${activityKey}`] = null;
        } else {
            demographicKeys.forEach(demographicKey => {
                updates[`/ssot/formStatus/${activityKey}/${mainFormKey}/${demographicKey}`] = null;
            });

            otherFormKeys.forEach(formKey => {
                updates[`/ssot/formStatus/${activityKey}/${formKey}`] = null;
            });
        }

        return this.db.object('/').update(updates);
    }

    hasDemographicsStatus(
        status: FormStatus | FormStatus[],
        demographics: Demographic[] = [],
        statusesMap: FormStatusModel,
    ): boolean {
        const compareArray: FormStatus[] = isArray(status) ? (status as FormStatus[]) : [status as FormStatus];

        return (demographics || []).some(demographic => {
            const demographicStatus = demographic.status || this.getDemographicStatus(statusesMap, demographic.key);

            return demographic.active && compareArray.includes(demographicStatus);
        });
    }

    hasAllDemographicsStatus(status: FormStatus, demographics: Demographic[], statusesMap: FormStatusModel): boolean {
        return demographics
            .filter(demographic => demographic.active)
            .every(demographic => {
                const demographicStatus = demographic.status || this.getDemographicStatus(statusesMap, demographic.key);

                return demographicStatus === status;
            });
    }

    hasDraftReviewDemographicNotReady(
        demographics: Demographic[],
        statusesMap: FormStatusModel,
        readyForAlignmentMap: UserReadyForAlignment[] = [],
    ): boolean {
        const readyForAlignmentDemographic = (readyForAlignmentMap || []).map(item => item.demographic_key);

        return (demographics || [])
            .filter(demographic => {
                const demographicStatus = demographic.status || this.getDemographicStatus(statusesMap, demographic.key);

                return demographic.active && demographicStatus === FormStatus.DraftReview;
            })
            .some(demographic => {
                return !readyForAlignmentDemographic.includes(demographic.key);
            });
    }

    getStatus(statusesMap: FormStatusModel, alignmentDisabled?: boolean): FormStatus {
        return (
            (((statusesMap || {}) as FormStatusObject).status as FormStatus) || this.getDefaultStatus(alignmentDisabled)
        );
    }

    getDemographicStatus(
        statusesMap: FormStatusModel = {},
        demographicKey: string,
        alignmentDisabled?: boolean,
    ): FormStatus {
        return ((statusesMap || {})[demographicKey] || {}).status || this.getDefaultStatus(alignmentDisabled);
    }

    getDefaultStatus(alignmentDisabled?: boolean, status?: FormStatus): FormStatus {
        const defaultStatus = alignmentDisabled ? FormStatus.InProgress : FormStatus.Draft;

        return status || defaultStatus;
    }
}
