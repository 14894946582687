<mat-card class="template-card" [class.selected]="isSelected">
  <mat-card-content class="content">
    <div
      class="image"
      [ngStyle]="{ 'background-image': 'url(' + (!!deploymentTemplate ? deploymentTemplate.image : '') + ')' }"
      [ngClass]="[
        backgroundColorIndex ? 'card-order-' + backgroundColorIndex : '',
        !deploymentTemplate || !deploymentTemplate.image ? 'default-bg' : ''
      ]"
    ></div>
    <gs-image
      [deploymentTemplateImage]="!!deploymentTemplate ? deploymentTemplate.image : null"
      [backgroundColorIndex]="backgroundColorIndex"
    ></gs-image>
    <div class="title" *ngIf="!!deploymentTemplate">
      {{ isDeploymentsList ? deploymentTemplate.client_name : deploymentTemplate.name }}
    </div>
    <div
      *ngIf="isDeploymentsList && !!deploymentTemplate"
      class="deployment-name"
    >
      {{ deploymentTemplate.name }}
    </div>
  </mat-card-content>
</mat-card>
