import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-user-role-changed-dialog',
    templateUrl: './user-role-changed-dialog.component.html',
    styleUrls: ['./user-role-changed-dialog.component.scss']
})
export class UserRoleChangedComponent {
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<UserRoleChangedComponent>
    ) { }

    onSubmit(): void {
        const { handleConfirm } = this.data;
        handleConfirm();
        this.dialogRef.close();
    }

}
