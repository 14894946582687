import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[appSetTitle]',
})
export class SetTitleDirective implements AfterViewInit {
    @Input('appSetTitle') maxWidth: number;

    constructor(private el: ElementRef) {}

    ngAfterViewInit() {
        if (this.el.nativeElement.offsetWidth >= this.maxWidth) {
            this.el.nativeElement.parentNode.title = this.el.nativeElement.textContent;
        }
    }
}
