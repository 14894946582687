import {
    Component,
    ChangeDetectionStrategy,
    Output,
    EventEmitter,
    Input
} from '@angular/core';

import { trackByIndex } from '@app/core/utils';
import { PaginationData } from './simple-pagination.component.models';

@Component({
    selector: 'app-simple-pagination',
    templateUrl: './simple-pagination.component.html',
    styleUrls: ['./simple-pagination.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimplePaginationComponent {
    @Input() source: PaginationData[];
    @Input() data: PaginationData[];
    @Input() activePageIndex: number;
    @Input() numberOfPages: number;
    @Output() pageChange = new EventEmitter<PaginationData>();

    trackByIndex = trackByIndex;

    constructor() {
        this.activePageIndex = 0;
        this.numberOfPages = 0;
    }

    get hasNextPage(): boolean {
        return this.activePageIndex < this.numberOfPages - 1;
    }

    get hasPreviousPage(): boolean {
        return this.activePageIndex > 0;
    }

    setNextPage(): void {
        const data = this.source[this.activePageIndex + 1];
        this.setActivePage(data);
    }

    setPerviousPage(): void {
        const data = this.source[this.activePageIndex - 1];
        this.setActivePage(data);
    }

    setActivePage(data: PaginationData): void {
        if (data.value === '...') {
            return;
        }
        this.pageChange.emit(data);
    }
}
