import { InjectionToken } from '@angular/core';

export const IMAGE_LIGHTBOX_DATA = new InjectionToken<string>('IMAGE_LIGHTBOX_DATA');
export const IMAGE_LIGHTBOX_CAPTION = new InjectionToken<string>('IMAGE_LIGHTBOX_CAPTION');

export class ImageLightboxConfig {
    panelClass?: string;
    hasBackdrop?: boolean;
    backdropClass?: string;
    image?: string;
    caption?: string;
}
