export const blockedByTasksTaskMessage = () =>
    'Please complete the activities above to start this activity';

export const blockedByGateTaskMessage = () =>
    'Please wait till leader completes the gate to start this activity';

export const blockedByTasksAndGatesTaskMessage = () =>
    'Please complete the activities above and wait till leader completes the gate to start this activity';

export const formsTaskMessage = (hasDemographics: boolean) => {
    return hasDemographics 
        ? 'Activity will be completed as soon as all your demographics are submitted'
        : 'Activity will be completed as soon as form is submitted';
}
