export type userRole = 'observer' | 'participant' | 'leader' | 'launch-only' | 'owner' | 'stakeholder' | 'collaborator' | 'deployment_manager';
export type Role = 'admin' | 'workbook';

export enum UserRole {
    Deployment_Manager = 'deployment_manager',
    Collaborator = 'collaborator',
    Stakeholder = 'stakeholder',
    Owner = 'owner'
}

export enum UserAccess {
    admin = 'Admin',
    basic = 'Basic'
}

export enum UserStatus {
    active = 'Active',
    inactive = 'Inactive',
    deactivated = 'Deactivated'
}

export class DeploymentManager {
    key?: string;
    permission?: {
        [role in Role]?: true
    };
    firstName?: string;
    first_name?: string;
    lastName?: string;
    last_name?: string;
    email?: string;
    email_address?: string;
    phoneNumber?: string;
    image_url?: string;
}

export class User {
    // engage fields
    Navigation?: {
        [navigationKey: string]: boolean
    };
    app_instances?: {
        [userKey: string]: userRole
    };
    apps?: {
        [userKey: string]: userRole
    };
    deployments?: Map<string, userRole>;
    created_at?: number;
    email?: string;
    username?: string;
    firstName?: string;
    lastName?: string;
    key?: string;
    online?: {
        [sessionKey: string]: true
    };
    disabled?: boolean;
    phoneNumber?: string;
    authenticationId?: string;
    permission?: {
        [role in Role]?: true
    };
    role?: UserAccess;
    active?: boolean;
    guest?: true;
    workbooks_connecting_in_process?: boolean;

    // fred fields
    id?: string;
    email_address?: string;
    first_name?: string;
    last_name?: string;
    image_url?: string;
    disable_email_notifications?: boolean;
    selected_deployment_id?: string;

    static getUserName(user: User, shortName?: boolean): string {
        let userName: string;

        if (!user) {
            return '';
        }

        if (shortName) {
            userName = user.first_name && user.last_name
                ? `${user.first_name[0]}${user.last_name[0]}`
                : (user.email_address).substring(0, 2); // if no first or last names get first 2 symbols of username
        } else {
            userName = user.first_name && user.last_name
                ? `${user.first_name} ${user.last_name}`
                : `${user.email_address}`;
        }

        return userName;
    }
}
