<div class="users-panel-tab">
  <div *ngIf="isModule || isGlobal" class="ownership">
    <div class="users-list">
      <gs-users-panel-list
        [isStakeholder]="isStakeholder"
        [users]="usersData['global']"
        [isModule]="isModule"
        [isGlobal]="isGlobal"
        [isEdit]="true"
        (onUpdatePrimary)="onUpdatePrimary($event)"
      ></gs-users-panel-list>
    </div>
  </div>

  <mat-accordion *ngIf="!(isModule || isGlobal)" [multi]="true">
    <mat-expansion-panel
      *ngFor="let demographic of demographics; trackBy: trackById"
      class="demographic-group"
      [hideToggle]="!usersData[demographic.id]?.length"
      [disabled]="!usersData[demographic.id]?.length"
    >
      <mat-expansion-panel-header class="demographic-name" [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <mat-panel-title>
          <span class="demographic-name-title subtitle-medium">
            {{ demographic.name }}
            <span class="gs-badge counter">{{ usersData[demographic.id]?.length || '0' }}</span>
          </span>
          <span *ngIf="!!getPrimaryUser(demographic.id)" class="primary-user subtitle-small">
            {{ getPrimaryUser(demographic.id) }}
            <span class="primary-label">
              Primary
            </span>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="users-list">
        <gs-users-panel-list
          [isStakeholder]="isStakeholder"
          [users]="usersData[demographic.id]"
          [isEdit]="true"
          (onUpdatePrimary)="onUpdatePrimary($event)"
        ></gs-users-panel-list>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel
      *ngIf="processHaveSomeGlobalSubprocesses"
      class="demographic-group"
      [hideToggle]="!usersData['global']?.length"
      [disabled]="!usersData['global']?.length"
    >
      <mat-expansion-panel-header class="demographic-name" [collapsedHeight]="'auto'" [expandedHeight]="'auto'">
        <mat-panel-title>
          <span class="demographic-name-title subtitle-medium">
            Global
            <span class="gs-badge counter">{{ usersData['global']?.length || '0' }}</span>
          </span>
          <span *ngIf="!!getPrimaryUser('global')" class="primary-user subtitle-small">
            {{ getPrimaryUser('global') }}
            <span class="primary-label">
              Primary
            </span>
          </span>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="users-list">
        <gs-users-panel-list
          [isStakeholder]="isStakeholder"
          [users]="usersData['global']"
          [isEdit]="true"
          (onUpdatePrimary)="onUpdatePrimary($event)"
        ></gs-users-panel-list>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
