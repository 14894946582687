import { AlignmentService } from '@app/apps/activities/forms/form-services/alignment.service';
import { AlignmentStateManageable } from '@app/core/models';
import { FormStatus } from '@app/core/models/form-status.model';

export class AlignedState implements AlignmentStateManageable {
    transition(context: AlignmentService, activityKey: string, formKey: string, demographicKeys: string[]) {
        context.updateAlignmentState(activityKey, formKey, FormStatus.PendingApproval, demographicKeys);
    }

}
