import { Severity } from './severity.model';
import { Frequency } from './frequency.model';

export class LogImpact {
    id?: string;
    sequence: number;
    value: string;
    severity?: Severity;
    frequency?: Frequency;
    process?: string[];
    stakeholder?: string;
    business_unit?: string;
    country?: string;
    change_mitigation_suggestion?: string[];
    owner_id: string;
}
