import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { LoginService } from '@app/login/services/login.service';

@Component({
    selector: 'direct-login',
    template: 'Automated Login ... please wait'
})
export class DirectLoginComponent implements OnInit {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private loginService: LoginService,
        private authUserService: AuthenticatedUserService
    ) {}

    ngOnInit() {
        this.route.queryParams.pipe(
            filter(params => params.token))
            .subscribe(params => {
                this.loginService.directLogin(params.token).subscribe(() => {

                    // A valid authenticated user session requires a userID from the /users/ node
                    // hard coded test user TEST_USER_1 has been created in Firebase by hand
                    localStorage.setItem(AuthenticatedUserService.userIdKey, 'TEST_USER_1');

                    this.authUserService.loadUserData();

                    // redirect home
                    this.router.navigate(['/navigation']);
                });
            });
    }

}
