<div *ngIf="(vm$ | async) as vm" class="deployment-editor">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div *ngIf="!vm.isLoading" class="deployment-editor-container">
    <div class="deployment-editor-title">
      <h5 class="h5">{{ vm.deployment.name }}</h5>
      <button class="deployment-editor-title-button gs-button-icon-flat gs-small" [routerLink]="['..']">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back to {{ vm.deployment.name }}
      </button>
    </div>

    <div class="edit-options">
      <span class="edit-options-text">What would you like to edit?</span>
      <div class="edit-options-selection">
        <table class="gs-table">
          <tbody>
            <ng-container>
              <tr>
                <div class="buttons">
                  <button
                    class="buttons-big"
                    (click)="setActiveEditor('description')"
                  >
                    Description
                  </button>
                  <button
                    class="buttons-big"
                    (click)="setActiveEditor('module_edit')"
                  >
                    Modules
                  </button>
                  <button
                    class="buttons-big"
                    (click)="setActiveEditor('demographic_edit')"
                  >
                    Demographics
                  </button>
                </div>
              </tr>
              <tr>
                <div class="buttons">
                  <button
                    class="buttons-big"
                    (click)="setActiveEditor('task_order')"
                  >
                    Task Order
                  </button>
                  <button
                    class="buttons-big"
                    (click)="setActiveEditor('add_activity')"
                  >
                    Add Activity
                  </button>
                  <button
                    class="buttons-big"
                  >
                    To Be Added
                  </button>
                </div>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>

    <!-- description -->
    <mat-form-field *ngIf="activeEditor === 'description'">
      <textarea
        matInput
        floatLabel="never"
        placeholder="Deployment Description"
        [value]="vm.deployment.description || ''"
        (blur)="updateDescription($event.target.value, vm.deployment.id)"
        (keyup.enter)="$event.target.blur()"
      ></textarea>
    </mat-form-field>
    <!-- /description -->

    <!-- modules -->
    <div *ngIf="activeEditor === 'module_edit'">
      <h5 class="gs-block-title">Select Modules For Your Deployment</h5>
      <gs-module-selection-editor
        [deploymentId]="vm.deployment.id"
        [deploymentModules]="vm.modules"
      ></gs-module-selection-editor>
    </div>
    <!-- /modules -->

    <!-- demographics -->
    <div *ngIf="activeEditor === 'demographic_edit'">
      <h5 class="gs-block-title demographics-title">Deployment Demographics</h5>
      <gs-demographics-editor
        [demographics]="vm.selectedDemographics"
        [allDemographics]="vm.globalDemographics"
        [objectType]="objectType"
        [deploymentId]="vm.deployment.id"
      ></gs-demographics-editor>
    </div>
    <!-- /demographics -->

    <!-- add activity-->
    <div *ngIf="activeEditor === 'add_activity'">
      Add Activity Stuff Here
    </div>
    <!-- /add activity-->

    <!-- task ordering-->
    <div *ngIf="activeEditor === 'task_order'">
      Modify Task Ordering Here
    </div>
    <!-- /task ordering-->
  </div>
</div>
