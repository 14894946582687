<div class="overlapped-questions">
  <div mat-dialog-title>
    <div mat-dialog-title class="dialog-title">
      <h3 class="h3">{{ data.title }}</h3>
      <button mat-icon-button mat-dialog-close tabindex="-1" class="close">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <mat-dialog-content class="overlapped-questions thinktank-theme">
    <div class="loading" *ngIf="!overlappedQuestionsResponse">
      <mat-spinner class="loading"></mat-spinner>
    </div>

    <div *ngIf="!!overlappedQuestionsResponse" class="overlapped-questions-list">

      <!-- mapping in the current question dialog section -->
      <div class="overlapped-questions-subsection">
        <h6 class="overline text-uppercase subheader subheader-indent"><span>mapping in the current question</span><hr noshade size="0.5" /></h6>
        <p class="text overlapped-questions-indent">Workbook: <span class="text-bold">{{ overlappedQuestionsResponse.workbookName }}</span></p>
        <p class="text overlapped-questions-indent">Workbook Sheet: <span class="text-bold">{{ overlappedQuestionsResponse.workbookSheetName }}</span></p>
        <p *ngIf="currentQuestionMapping" class="text overlapped-questions-indent">Mapping: <span class="text-bold">{{ currentQuestionMapping }}</span></p>
      </div>
      <!-- /mapping in the current question dialog section -->

      <!-- list of the form activities with overlapped questions/options -->
      <div class="overlapped-questions-subsection">
        <h6 class="overline text-uppercase subheader"><span>overlapping</span><hr noshade size="0.5" /></h6>
        <div
          *ngFor="let overlappedActivity of overlappedActivities | keyvalue: preventKeyValueOrder; trackBy: trackByKey"
          id="{{ overlappedActivity.key }}"
          class="overlapped-list"
        >

          <!-- activity name and sequence -->
          <div class="overlapped-list_name">
            <p class="text text-primary" (click)="redirectToForm(overlappedActivity.key)">{{overlappedActivity.value?.number}}. {{ overlappedActivity.value?.formName }}</p>
          </div>
          <!-- /activity name and sequence -->

          <!-- list of overlapped questions -->
          <div class="overlapped-list_questions">

            <!-- demographics mapping -->
            <div *ngIf="!!overlappedActivity.value?.demographicsMapping" class="overlapped-list-item">
              <div class="overlapped-list-item_name text-small overlapped-questions-indent text-dark-primary">Demographics</div>
              <div class="text-small overlapped-questions-indent">Mapping: <span class="text-dark-primary">{{ overlappedActivity.value?.demographicsMapping }}</span></div>
            </div>
            <!-- /demographics mapping -->

            <div *ngFor="let overlappedQuestion of overlappedActivity.value?.questions" class="overlapped-list-item">
              <div class="overlapped-list-item_name text-small overlapped-questions-indent text-dark-primary">{{ overlappedQuestion.number }}. {{ overlappedQuestion.name }}</div>
              <div class="text-small overlapped-questions-indent">Mapping: <span class="text-dark-primary">{{ overlappedQuestion.mapping }}</span></div>
            </div>
          </div>
          <!-- /list of overlapped questions -->

        </div>
      </div>
      <!-- /list of the form activities with overlapped questions/options -->

    </div>
  </mat-dialog-content>

  <mat-dialog-actions
    class="actions"
  >
    <button mat-dialog-close class="tt-button-filled">
      {{ data.cancelBtnText }}
    </button>
  </mat-dialog-actions>
</div>
