import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromAuthStateActions from '../actions/auth.actions';
import { map, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { of, combineLatest, Observable } from 'rxjs';
import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthSubscriptionService } from '@app/root-store/features/auth/services/auth-subscription.service';
import { User } from '@thinktank/common-deployments';

@Injectable()
export class AuthEffects {
    initializeCurrentUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAuthStateActions.initializeCurrentUser),
            map((action) => action.cognitoUserData),
            switchMap((cognitoUserData) => {
                return combineLatest([this.getAuthState(), this.getUser(cognitoUserData.email)])
                    .pipe(
                        switchMap(([firebaseUser, user]) => {
                            const dbUser$: Observable<any> = of(user);
                            // if (!user) {
                            //     const [first_name, last_name] = firebaseUser.displayName.split(' ');
                            //     const newUser = {
                            //         email_address: firebaseUser.email,
                            //         first_name,
                            //         last_name
                            //     };
                            //     dbUser$ = of(this.userService.createUser(newUser, firebaseUser.uid))
                            //         .pipe(
                            //             map((() => of(newUser)))
                            //         );
                            // }
                            return combineLatest([dbUser$, of(firebaseUser)])
                                .pipe(
                                    map(([dbUser, firebaseAuthUser]) => fromAuthStateActions.initializeCurrentUserSuccess({
                                        userData: {
                                            user: {...dbUser, id: !!firebaseAuthUser && firebaseAuthUser.uid || ''},

                                            // to fix login error caused by firebaseAuthUser object, use only necessary properties
                                            firebaseUser: {
                                                uid: !!firebaseAuthUser && firebaseAuthUser.uid || '',
                                                email: !!firebaseAuthUser && firebaseAuthUser.email || '',
                                                displayName: !!firebaseAuthUser && firebaseAuthUser.displayName || ''
                                            },
                                            isLoading: false
                                        }
                                    }))
                                );
                        })
                    );
            })
        )
    );

    initializeReturningUser$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAuthStateActions.initializeReturningUser),
            map((action) => action.userId),
            switchMap((userId) =>
                combineLatest([this.getAuthState(), this.getUser(userId)])
                    .pipe(
                        map(([firebaseUser, user]) => {
                            return fromAuthStateActions.initializeReturningUserSuccess({
                                userData: {
                                    user,
                                    firebaseUser,
                                    isLoading: false
                                }
                            });
                        })
                    )
            )
        )
    );

    signOut$ = createEffect(() =>
        this.actions$.pipe(
            ofType(fromAuthStateActions.signOut),
            map((action) => {
                this.authSubscriptionService.unsubscribeComponent$.next();
                this.afAuth.signOut();
                // this.routerService.navigateTo(['']);
                return fromAuthStateActions.signOutSuccess();
            })
        )
    );

    constructor(
        private actions$: Actions,
        private authUserService: AuthenticatedUserService,
        private afAuth: AngularFireAuth,
        private authSubscriptionService: AuthSubscriptionService
        // private routerService: RouterService
    ) {
    }

    private getAuthState() {
        return this.afAuth.authState
            .pipe(
                takeUntil(this.authSubscriptionService.unsubscribe$)
            );
    }

    private getUser(userId: string): Observable<User> {
        return of(this.authUserService.getCurrentUser())
            .pipe(
                takeUntil(this.authSubscriptionService.unsubscribe$)
            );
    }
}
