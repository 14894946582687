import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';

import {
    AnalyticsService,
    AuthenticatedUserService,
    AwsCognitoAuthService,
    AcnSsoService,
    AuthenticationService,
    FirebaseAuthenticationService,
    AuthenticationGuard,
    AdminGuard,
    WorkbookGuard,
    FocusAbilityService,
    FullscreenService,
    ImagesService,
    VideoService,
    FirebaseDemographicsService,
    FilestackService,
    UserEventsService,
    WorkbookService,
    NameValidationService,
    NotificationService,
    RepositoryGuard,
} from '@app/core/services';
import { LoaderService } from '@app/core/services/loader.service';
import { FirebaseCategoryService } from '@app/core/services/categories.service';
import { DialogService } from '@app/core/services/dialog.service';
import { FileUploadService } from '@app/core/services/file-upload.service';
import { FirebaseNavigationService } from '@app/core/services/navigation.service';
import { PresentService } from '@app/core/services/present.service';
import { AttachmentService } from '@app/core/services/attachment.service';
import { FirebaseResponsesService } from '@app/core/services/responses.service';
import { FirebaseUsersService } from '@app/core/services/users.service';
import { FirebaseAppService } from '@app/core/services/app.service';
import { CanDeactivateGuard } from '@app/core/guards/can-deactivate.guard';
import { AwsCognitoService } from '@app/core/services/aws-cognito.service';
import { CognitoUtilityService } from '@app/core/services/cognito-utility.service';
import { FirebaseActivityService } from '@app/core/services/activity.service';
import { FirebaseQuestionsService } from './services/questions.service';
import { LeaderGuard } from '@app/core/services/leader.guard';
import { AppGuard } from '@app/core/guards/app.guard';
import { FormComponentsService } from '@app/core/services/form-components.service';
import { FormAttachmentService } from '@app/apps/activities/forms/form-services/form-attachment.service';
import { FormDemographicsService } from '@app/apps/activities/forms/form-services/form-demographics.service';
import { FormCollaborationService } from '@app/apps/activities/forms/form-services/form-collaboration.service';
import { FormDisagreementService } from '@app/apps/activities/forms/form-services/form-disagreement.service';
import { FeatureToggleService } from '@app/core/services/feature-toggle.service';
import { DuplicateDataService } from '@app/core/services/duplicate-data.service';
import { FormService } from '@app/core/services/form.service';
import { FormStatusPanelService } from '@app/core/services/form-status-panel.service';
import { TaskService } from '@app/core/services/tasks.service';
import { RepositoryStoreService } from '@app/core/services/repository-store.service';
import { DeploymentsGuard } from '@app/core/guards/deployments.guard';
import { RoutingService } from '@app/apps/core/services/routing.service';
import { CommonDeploymentsModule } from '@thinktank/common-deployments';
import { LoginGuard } from '@app/core/guards/login.guard';

@NgModule({
    imports: [CommonModule, CommonDeploymentsModule],
    providers: [
        { provide: 'Window', useValue: window },
        { provide: 'Document', useValue: document },
        AnalyticsService,
        AttachmentService,
        AuthenticatedUserService,
        AuthenticationGuard,
        AdminGuard,
        LoginGuard,
        WorkbookGuard,
        RepositoryGuard,
        AwsCognitoAuthService,
        AwsCognitoService,
        AuthenticationService,
        AcnSsoService,
        CanDeactivateGuard,
        CognitoUtilityService,
        DialogService,
        FileUploadService,
        FirebaseActivityService,
        FirebaseDemographicsService,
        FirebaseAppService,
        FirebaseAuthenticationService,
        FirebaseCategoryService,
        FirebaseNavigationService,
        FirebaseQuestionsService,
        FirebaseResponsesService,
        FirebaseUsersService,
        LeaderGuard,
        AppGuard,
        LoaderService,
        PresentService,
        FocusAbilityService,
        FullscreenService,
        ImagesService,
        VideoService,
        FormComponentsService,
        FilestackService,
        FormAttachmentService,
        FormDemographicsService,
        FormCollaborationService,
        FormDisagreementService,
        UserEventsService,
        FeatureToggleService,
        DuplicateDataService,
        WorkbookService,
        FormService,
        FormStatusPanelService,
        TaskService,
        NameValidationService,
        NotificationService,
        RepositoryStoreService,
        DeploymentsGuard,
        RoutingService,
    ],
})
export class CoreModule {
    /* make sure CoreModule is imported only by one NgModule the AppModule */
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import only in AppModule');
        }
    }
}
