export class Demographic {
    id?: string;
    name: string;
    region?: string; // for preselect
}

export const GlobalDemographic = {
    id: 'global',
    name: 'Global'
};

export const testDemographic = {
    id: 'Test Demographic',
    name: 'Test Demographic',
    region: 'Asia'
};
