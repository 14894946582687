import { ObjectType } from './deployment.model';

export enum TaskStatus {
    Blocked = 'blocked',
    Completed = 'completed',
    Incomplete = 'incomplete'
}

export type TaskType = 'form' | 'activity'; // in future will be added new types

// default model
export interface TaskItem {
    data: {
        form_id: string;
    };
    name: string;
    type: TaskType;
    parent_id: string;
    parent_type: ObjectType;
    sequence?: number;
    status?: TaskStatus;
    global: boolean;
    blocked_by?: { [taskId: string]: boolean };
    blocks?: { [taskId: string]: boolean };
}

// model for the Task view
export interface TaskViewItem extends TaskItem {
    id?: string; // combined id {deploymentId_taskId}
    task_id: string;
    type: TaskType;
    deployment_id: string;
    deployment_name?: string;
    client_name?: string;
    module_id: string;
    module_name?: string;
    form_id?: string;
    process_id?: string;
    subprocess_name?: string;
    module_sequence?: number;
    demographics?: string[];
    is_user_primary?: boolean;
}
