import { Directive, HostListener, Input, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appTrimValue]'
})
export class TrimValueDirective {

    @Input() trimSymbols: string[];

    constructor(private el: ElementRef, private ngControl: NgControl) { }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        if (!this.trimSymbols) { return; }

        let trimmedValue = value;
        const maskSign = '\\';

        for (const valueForTream of this.trimSymbols) {
            if (trimmedValue.includes(valueForTream)) {
                trimmedValue = trimmedValue.replace(new RegExp(`[${maskSign}${valueForTream}]`, 'g'), '');
                break;
            }
        }

        if (trimmedValue !== value) {
            this.ngControl.control.patchValue(trimmedValue, { emitEvent: false });
        }
    }
}
