import { Component, Input } from '@angular/core';

import { TaskItem } from '../../../models';

@Component({
    selector: 'gs-task-list-card',
    templateUrl: './task-list-card.component.html',
    styleUrls: ['./task-list-card.component.scss']
})
export class TaskListCardComponent {

    @Input() taskList: TaskItem[];
    @Input() moduleName: string;

    constructor() {
    }

}
