<div *ngIf="(vm$ | async) as vm" class="subprocess">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div *ngIf="!vm.isLoading" class="subprocess-container">
    <div class="subprocess-title">
      <h5 class="h5">{{ vm.subprocess.name }}</h5>
      <button class="gs-button-icon-flat gs-small" [routerLink]="['/deployment', vm.deploymentId]">
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
    </div>

    <!-- demographics -->
    <ng-container *ngIf="!vm.subprocess?.global">
      <h5 class="gs-block-title">Countries</h5>
      <gs-view-demographics
        [demographics]="vm.selectedDemographics"
        [canChange]="false"
        [viewType]="'subprocess'"
      ></gs-view-demographics>
    </ng-container>
    <!-- /demographics -->

    <!-- assignees details -->
    <h5 class="gs-block-title">Assignees</h5>
    <gs-view-assignees
      viewType="subprocess"
      [deploymentOwnership]="getFilteredOwnership(vm.deploymentOwnership, vm.subprocess.id)"
      [useDemographics]="!vm.subprocess.global"
      (assignUsers)="goToAssignment(vm.subprocess, vm.deploymentId)"
      (unassignUser)="unassignUser($event, vm.deploymentId, vm.subprocess.id)"
    ></gs-view-assignees>
    <!-- /assignees details -->

    <!-- sub processes impact table -->
    <h5 class="gs-block-title">
      Processes/Sub Processes Impacted
      <!-- <span class="gs-badge">{{ getSubprocessesImpactedCount(vm.subprocessImpactData, vm.subprocess.id)}}</span> -->
    </h5>

    <!-- sub processes impact table -->
    <gs-view-processes-table
      [viewType]="'subprocess'"
      [currentUserId]="vm.authenticatedUserId"
      [deploymentId]="vm.deploymentId"
      [moduleId]="vm.subprocess.module_id"
      [demographics]="vm.selectedDemographics"
      [ownership]="vm.deploymentOwnership"
      [impactData]="vm.subprocessImpactData"
      [showSubprocesses]="false"
      [showProcessesImpacted]="true"
    ></gs-view-processes-table>
    <!-- /sub processes impact table -->

    <!-- view change request table-->
    <h5 class="gs-block-title">Change Requests</h5>
    <gs-view-change-requests-table
      [changeRequests]="vm.changeRequests"
      [deploymentUsers]="vm.deploymentOwnership"
    ></gs-view-change-requests-table>
    <!-- /view change request table -->

  </div>
</div>
