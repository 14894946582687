<div *ngIf="(vm$ | async) as vm" class="module">
  <div *ngIf="vm.isLoading" class="layout-flex-center">
    <gs-spinner class="spinner-primary spinner-lg"></gs-spinner>
  </div>
  <div *ngIf="!vm.isLoading" class="module-container">
    <div class="module-title">
      <h5 class="h5">{{ vm.module.name }}</h5>
      <button
        class="module-title-button gs-button-icon-flat gs-small"
        (click)="navigateToEdit(vm.deploymentId, vm.module.id)"
      >
        <mat-icon>create</mat-icon>
        <span>Edit Info</span>
      </button>
      <button
        class="gs-button-icon-flat gs-small"
        [routerLink]="['/deployment', vm.deploymentId]"
      >
        <mat-icon>keyboard_arrow_left</mat-icon>
        Back
      </button>
      <div class="header-separator"></div>
      <button
        class="export-button gs-button-filled gs-small"
        (click)="exportWorkbook(vm.deploymentId, vm.module.id)"
      >
        Export to Workbook
      </button>
    </div>

    <!-- demographics -->
    <h5 class="gs-block-title">Countries</h5>
    <gs-view-demographics
      [demographics]="vm.selectedDemographics"
      [canChange]="true"
      [viewType]="'module'"
      (change)="navigateToEdit(vm.deploymentId, vm.module.id)"
    ></gs-view-demographics>
    <!-- /demographics -->

    <!-- assignees details -->
    <h5 class="gs-block-title">Assignees</h5>
    <gs-view-assignees
      viewType="module"
      [deploymentOwnership]="getFilteredOwnership(vm.deploymentOwnership, vm.module.id)"
      [useDemographics]="true"
      (assignUsers)="goToAssignment(vm.deploymentId, vm.module)"
      (unassignUser)="unassignUser($event, vm.deploymentId, vm.module.id)"
    ></gs-view-assignees>
    <!-- /assignees details -->

    <!-- quick details -->
    <h5 class="gs-block-title">Quick Details</h5>

    <!-- processes/sub processes tabs -->
    <div class="gs-tabs">
      <div class="gs-tab" [ngClass]="{ active: activeTab === 0 }">
        <a (click)="activeTab = 0">All</a>
        <span class="gs-badge">{{ vm.processes.length + vm.subprocesses.length }}</span>
      </div>
      <div class="gs-tab" [ngClass]="{ active: activeTab === 1 }">
        <a (click)="activeTab = 1">Processes</a>
        <span class="gs-badge">{{ vm.processes.length }}</span>
      </div>
      <div class="gs-tab" [ngClass]="{ active: activeTab === 2 }">
        <a (click)="activeTab = 2">Sub Processes</a>
        <span class="gs-badge">{{ vm.subprocesses.length }}</span>
      </div>
    </div>
    <!-- /processes/sub processes tabs -->

    <!-- table with processes/sub processes and impact data -->
    <gs-view-processes-table
      [viewType]="'module'"
      [currentUserId]="vm.authenticatedUserId"
      [deploymentId]="vm.deploymentId"
      [moduleId]="vm.module.id"
      [demographics]="vm.selectedDemographics"
      [ownership]="vm.deploymentOwnership"
      [showAll]="activeTab === 0"
      [showProcesses]="activeTab === 1"
      [showSubprocesses]="activeTab === 2"
      [changeRequests]="vm.changeRequests"
      [processes]="vm.processes"
      [subprocesses]="vm.subprocesses"
      (sendPulseForProcess)="sendPulseForProcess($event)"
      (sendPulseForSubProcess)="sendPulseForSubprocess($event)"
      (volunteer)="volunteer($event)"
      (routeTo)="navigateTo($event)"
    ></gs-view-processes-table>
    <!-- /table with processes/sub processes and impact data -->

    <!-- /quick details -->
  </div>
</div>
