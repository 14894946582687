<div class="search white indent">
  <mat-form-field appearance="outline" floatLabel="none" class="no-label">
    <input
      #search
      matInput
      [placeholder]="placeholder || 'Search'"
      (input)="searchChanged($event.target.value)"
    />
    <mat-icon *ngIf="!search.value" matSuffix>search</mat-icon>
    <mat-icon *ngIf="search.value" class="hovered" (click)="clearSearch()" matSuffix>close</mat-icon>
  </mat-form-field>
</div>
