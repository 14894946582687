import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

export enum NodeType {
    Gate = 'GATE',
    Deployment = 'DEPLOYMENT',
    Module = 'MODULE',
    Process = 'PROCESS'
}

export interface NodeData {
    name?: string;
}

export interface Node {
    key?: string;
    project_key?: string;
    x?: number;
    y?: number;
    readonly type: NodeType;
    data?: NodeData;
    created?: firebase.firestore.Timestamp;
    updated?: firebase.firestore.Timestamp;
}
