import { ChangeDetectionStrategy, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BaseQuestionComponent } from '../base-question/base-question.component';
import { DeploymentFormFacade, DeploymentFormFacadeToken } from '../../deployment-form.facade';

@Component({
    selector: 'gs-date-question',
    templateUrl: './date-question.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DateQuestionComponent extends BaseQuestionComponent implements OnInit, OnDestroy, OnChanges {

    constructor(@Inject(DeploymentFormFacadeToken) formFacade: DeploymentFormFacade) {
        super(formFacade);
    }

    ngOnInit(): void {
        this.onValueChanges();
    }

    ngOnChanges(changes: SimpleChanges): void {
        console.log(`DateQuestionComponent::ngOnChanges - changes for ${this.question.id}`, changes);
    }

    ngOnDestroy(): void {
        this.onDestroy();
    }

}
