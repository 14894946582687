<div class="alert-dialog">
  <h3 mat-dialog-title class="h3">Your role has been changed</h3>
  <mat-dialog-content class="alert-content text">
    Your role has been changed in this app. Please refresh your page!
  </mat-dialog-content>
  <mat-dialog-actions class="alert-actions">
    <button cdkFocusInitial class="tt-button-filled" (click)="onSubmit()">
      Refresh
    </button>
  </mat-dialog-actions>
</div>
