export const deactivateAdminUserModalText = 'Unfortunately you are not able to change another Administrators status. '
    + 'If you have any questions please contact ThinkTank support at support@thinktank.net. Thank you';

export const confirmationDeactivateUserModalText = (isMultiUsers?: boolean) => {
    const usersText = isMultiUsers ? 'these users' : 'this user';
    return `Are you sure you wish to deactivate ${usersText}? They will lose all access to Engage once deactivated.`;
};

export const secondConfirmationDeactivateUserModalText = (isMultiUsers?: boolean) => {
    const usersText = isMultiUsers ? 'these users' : 'this user';
    return `Once deactivated, ${usersText} will not have access to Engage and will need to contact an administrator to be reactivated. ` +
        'Are you sure you want to deactivate them?';
};

export const confirmationActivateUserModalText = (isMultiUsers?: boolean) => {
    const usersText = isMultiUsers ? 'these users' : 'this user';
    return `Are you sure you wish to activate ${usersText}? They will have all access to Engage once activated.`;
};

export const userPasswordValidationText = [
    {
        validationText: 'Password must contain a lower case letter',
        errorName: 'hasLowerCase'
    },
    {
        validationText: 'Password must contain an upper case letter',
        errorName: 'hasUpperCase'
    },
    {
        validationText: 'Password must contain a special character',
        errorName: 'hasSpecialCharacters'
    },
    {
        validationText: 'Password must contain a number',
        errorName: 'hasNumber'
    },
    {
        validationText: 'Password must contain at least 8 characters',
        errorName: 'minlength'
    }
];
