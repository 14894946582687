import { createSelector } from '@ngrx/store';

import { AppState } from '@app/root-store/state';
import { WorkbooksState } from '../state';

export const workbooksState = (state: AppState) => state.workbooks;

export const workbooksSheetsByActivitySelector = createSelector(
    workbooksState,
    (state: WorkbooksState, props: { activityId: string }) => {
        return state.workbooksSheetsByActivity[props.activityId] || [];
    }
);

export const formSheetKeySelector = createSelector(
    workbooksState,
    (state: WorkbooksState, props: { formId: string }) => {
        return state.formSheetKeyByForm[props.formId] || null;
    }
);

export const questionSheetKeySelector = createSelector(
    workbooksState,
    (state: WorkbooksState, props: { questionId: string }) => {
        return state.questionSheetKeyByQuestion[props.questionId] || null;
    }
);

export const formQuestionsWithWorkbookSheetSelector = createSelector(
    workbooksState,
    (state: WorkbooksState, props: { questionId: string }) => {
        return state.formQuestionsWithWorkbookSheet[props.questionId] || [];
    }
)
