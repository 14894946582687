import { Component, Inject, ViewChild, ElementRef, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take, tap } from 'rxjs/operators';

import { Demographic } from '@app/core/models/demographic.model';
import { FirebaseDemographicsService } from '@app/core/services/demographics.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'app-select-demographics-for-export-dialog',
    templateUrl: './select-demographics-for-export-dialog.component.html',
    styleUrls: ['./select-demographics-for-export-dialog.component.scss']
})
export class SelectDemographicsForExportComponent implements OnInit, OnDestroy {
    title: string;
    demographicClassIds: string[];
    backingDemographics: { [demographicClassId: string]: any[] } = {};
    demographics: any[] = [];
    demographicsClassIndex = 0;
    @ViewChild('search', { static: true }) search: ElementRef;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<SelectDemographicsForExportComponent>,
        private fbDemographicsService: FirebaseDemographicsService,
    ) {
        this.title = this.data.title;
        this.demographicClassIds = this.data.demographicClassIds;
    }

    get canShowExportButton(): boolean {
        return this.demographicsClassIndex === (this.demographicClassIds.length - 1);
    }

    get currentDemographicClassId(): string {
        return this.demographicClassIds[this.demographicsClassIndex];
    }

    get checkAllDemographicsSelected(): boolean {
        return !!(this.backingDemographics[this.currentDemographicClassId] || [])
            .every((demographic) => !!demographic.selected_for_export);
    }

    get checkSomeDemographicsSelected(): boolean {
        const demographics = this.backingDemographics[this.currentDemographicClassId];
        if (!Array.isArray(demographics)) {
            return false;
        }

        return !(demographics || [])
            .some((demographic) => !!demographic.selected_for_export);
    }

    get backingDemographicsLength(): boolean {
        return !!Object.keys(this.backingDemographics).length;
    }

    navigateBetweenClasses(next: boolean): void {
        next ? this.demographicsClassIndex += 1 : this.demographicsClassIndex -= 1;
        this.getDemographics();
    }

    exportDemographic(): void {
        let selectedDemographicsKeys = [];

        Object.keys(this.backingDemographics).forEach((demographicClassId) => {
            const newSelectedDemographicsKeys = this.backingDemographics[demographicClassId]
                .filter((demographic) => !!demographic.selected_for_export)
                .map((demographic) => demographic.key);

            selectedDemographicsKeys = [
                ...selectedDemographicsKeys,
                ...newSelectedDemographicsKeys
            ];
        });

        this.data.handleConfirm(selectedDemographicsKeys);
        this.dialogRef.close();
    }

    toggleDemographic(checked: boolean, demographic: any): void {
        const backingDemographicsIndex = this.backingDemographics[this.currentDemographicClassId].indexOf(demographic);
        const demographicsIndex = this.demographics.indexOf(demographic);

        this.backingDemographics[this.currentDemographicClassId][backingDemographicsIndex].selected_for_export = checked;
        this.demographics[demographicsIndex].selected_for_export = checked;
    }

    toggleAllDemographic(checked: boolean, demographicClassId: string): void {
        this.backingDemographics[demographicClassId].forEach((demographic) => {
            demographic.selected_for_export = checked;
        });
    }

    filterDemographics(filterSting: string): void {
        this.demographics = this.backingDemographics[this.currentDemographicClassId].filter((demographic: Demographic) => {
            return (demographic.name || '').toLowerCase().includes(filterSting.toLowerCase());
        });
    }

    clearSearch(): void {
        this.search.nativeElement.value = '';
        this.filterDemographics('');
    }

    getDemographics(): void {
        this.demographics = this.backingDemographics[this.currentDemographicClassId];
    }

    getAllDemographicsByClassList(): void {
        this.fbDemographicsService.getAllDemographicsByClassList(this.demographicClassIds)
            .pipe(
                tap((demographics) => {
                    if (!demographics.length) {
                        return;
                    }

                    demographics.forEach((demographicObject) => {
                        const filteredDemographicsObject = this.data.givenDemographics
                            ? Object.keys(demographicObject).reduce((acc, demographicClassKey) => {
                                acc[demographicClassKey] = demographicObject[demographicClassKey]
                                    .filter(({ key }) => this.data.givenDemographics.includes(key));
                                return acc;
                            }, {})
                            : demographicObject;
                        this.backingDemographics = {
                            ...this.backingDemographics,
                            ...filteredDemographicsObject
                        };
                    });

                    this.getDemographics();
                }),
                untilDestroyed(this)
            ).subscribe();
    }

    ngOnInit() {
        this.getAllDemographicsByClassList();

        this.dialogRef.afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.resetDemographicsForAllClasses();
            });
    }

    ngOnDestroy() {}

    private resetDemographicsForAllClasses(): void {
        this.demographicClassIds.forEach(demographicClassId => {
            this.toggleAllDemographic(false, demographicClassId);
        });
    }
}
