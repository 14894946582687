import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable, merge } from 'rxjs';
import { distinctUntilChanged, filter, map, take, debounceTime } from 'rxjs/operators';

import { listWithKeys, sortBySequenceAsc } from '@app/core/utils';
import { AuthenticatedUserService } from './authenticated-user.service';
import {
    Form,
    FormQuestion,
    OptionsChoice,
    UserEvent,
    RepeatableQuestionsIds,
    ActivityType,
    AnsweredQuestionModel,
} from '@app/core/models';
import { FirebaseUtilityService } from '@app/core/services/firebase-utility.service';
import { UserEventsService } from '@app/core/services/user-events.service';
import {
    allowedWorkbookResponseTypesMap,
    notQuestionResponseTypes,
    subformComponentType,
} from '@app/apps/apps.component.forms-constants';
import { httpsCallableOptions } from '../constants';

@Injectable()
export class FormComponentsService extends FirebaseUtilityService {
    private expanded = {
        general: true,
        jira: true,
        jsonXml: true,
        branching: true,
    };

    constructor(
        private db: AngularFireDatabase,
        private functions: AngularFireFunctions,
        private authUserService: AuthenticatedUserService,
        private userEventsService: UserEventsService,
    ) {
        super();
    }

    getExpanded(tabKey: string): boolean {
        return !!this.expanded && this.expanded[tabKey];
    }

    toggleExpanded(tabKey: string): void {
        this.expanded[tabKey] = !this.expanded[tabKey];
    }

    resetExpanded(): void {
        this.expanded = {
            general: true,
            jira: true,
            jsonXml: true,
            branching: true,
        };
    }

    async buildQuestion(
        activityKey: string,
        questionData: any,
        sequence: number,
        formId: string,
    ): Promise<FormQuestion> {
        const { type, icon, active, display_name, key, ...question } = questionData;
        const moveAllToJira = await this.db
            .object<any>(`/ssot/_activities/${activityKey}/move_to_jira`)
            .valueChanges()
            .pipe(take(1))
            .toPromise();

        const newQuestion = <FormQuestion>{
            ...question,
            question: '',
            form_id: formId,
            response_type: type,
            move_to_jira: moveAllToJira === 2,
            response_type_icon: icon,
            sequence: sequence,
            activity_id: activityKey,
            options: {
                ...question.options,
                is_required: false,
            },
        };

        if (type === 'subform') {
            delete newQuestion.question;
            newQuestion.section_name = '';
        }

        return newQuestion;
    }

    async updateFormDemographicClassId(formKey: string, demographicClassKey: string): Promise<void> {
        const updates = {};

        updates[`ssot/_forms/${formKey}/demographics_class_id`] = demographicClassKey;

        return this.db.object('/').update(updates);
    }

    async removeAllFormsDemographicClassId(activityKey: string): Promise<void> {
        const updates = {};
        const forms = await this.getFormsByActivity(activityKey).pipe(take(1)).toPromise();

        forms.forEach(form => {
            updates[`ssot/_forms/${form.key}/demographics_class_id`] = null;
        });

        return this.db.object('/').update(updates);
    }

    updateFormComponentSubform(): Promise<void> {
        const updates = {};

        updates['formComponents/subform'] = subformComponentType;
        updates['formComponents/section/active'] = false;

        return this.db.object('/').update(updates);
    }

    // function to drive custom option fields for forms designer options view
    getSpecificOptionFieldsForResponseType(response_type: string): Observable<any> {
        return this.db.object(`/formComponents/${response_type}/option_fields`).valueChanges();
    }

    async removeFormsSecondDemographicClass(activityKey: string, parentFormKey: string): Promise<void> {
        const forms = await this.getFormsByActivity(activityKey).pipe(take(1)).toPromise();

        for (const form of forms) {
            if (form.key === parentFormKey) {
                const questions = await this.getFormQuestionsByFormKey(form.key).pipe(take(1)).toPromise();
                const updates = {};

                questions.forEach(question => {
                    if (question.response_type === 'subform') {
                        updates[`ssot/_questions/${question.key}/demographics_class_id`] = null;
                    }
                });
                await this.db.object('/').update(updates);
            } else {
                await this.removeQuestionsDemographics('form_id', form.key);
                await this.updateFormDemographicClassId(form.key, null);
            }
        }
    }

    // need for backward compatibility with the older databases
    async getSpecificOptionFieldsForSubform(): Promise<any> {
        const options = await this.db
            .object('/formComponents/subform/option_fields')
            .valueChanges()
            .pipe(
                take(1),
                map(optionsObj => {
                    const optionsList = Object.keys(optionsObj || {}).map(optionKey => ({
                        ...optionsObj[optionKey],
                        optionKey,
                    }));

                    return sortBySequenceAsc(optionsList).reduce((acc, optionListItem, index) => {
                        const { optionKey, ...option } = optionListItem as any;

                        return { ...acc, [`${optionKey}`]: option };
                    }, {});
                }),
            )
            .toPromise();

        // add 'subform' object, and set inactive for 'section' object
        if (!options) {
            await this.updateFormComponentSubform();
        }

        return options;
    }

    getFormObjectByKey(formKey: string): Observable<Form> {
        return this.objectWithKey(this.db.object(`/ssot/_forms/${formKey}`)).pipe(
            distinctUntilChanged<Form>(),
            filter((form: Form) => {
                // when leader toggle Use Demographics to OFF mode,
                // active_demographics property temporarily exists in form,
                // so remove active_demographics from form without demographics
                // to load correct form data
                if (!form.demographics_class_id) {
                    delete form.active_demographics;
                }

                // check if all data come to form with demographics
                return !form.active_demographics || !!(form.active_demographics && form.demographics_class_id);
            }),
        );
    }

    getFormQuestionsByFormKey(formKey: string): Observable<FormQuestion[]> {
        return this.getQuestionsByField('form_id', formKey);
    }

    getFormFieldByKey(formKey: string, formField: string): Observable<any> {
        return this.db.object<any>(`/ssot/_forms/${formKey}/${formField}`).valueChanges();
    }

    getFormsByActivity(activityKey: string): Observable<Form[]> {
        return this.listWithKeys(
            this.db.list('/ssot/_forms', ref => ref.orderByChild('activity_id').equalTo(activityKey)),
        );
    }

    updateFormsReviewKey(activityKey: string, reviewKey: string): void {
        this.getFormsByActivity(activityKey)
            .pipe(take(1))
            .subscribe((forms: Form[]) => {
                const updates = {};

                forms.forEach(form => {
                    updates[`/ssot/_forms/${form.key}/review_id`] = reviewKey;
                });

                this.db.object('/').update(updates);
            });
    }

    getQuestionsByField(field: string, value: any): Observable<FormQuestion[]> {
        return this.db
            .list('/ssot/_questions', ref => ref.orderByChild(`${field}`).equalTo(value))
            .snapshotChanges()
            .pipe(
                map(questionsSnapshot => {
                    return questionsSnapshot.map(
                        questionSnapshot =>
                            <FormQuestion>{
                                ...(<FormQuestion>questionSnapshot.payload.val()),
                                key: questionSnapshot.key,
                            },
                    );
                }),
                map(sortBySequenceAsc),
            );
    }

    async removeQuestionsDemographics(field: string, value: any): Promise<void> {
        const questions = await this.db
            .list('/ssot/_questions', ref => ref.orderByChild(field).equalTo(value))
            .snapshotChanges()
            .pipe(take(1))
            .toPromise();

        if (questions.length > 0) {
            const updates = questions.reduce((accumulator, question) => {
                accumulator[`ssot/_questions/${question.key}/demographics`] = 'all';
                accumulator[`ssot/_questions/${question.key}/demographics_class_id`] = null;

                return accumulator;
            }, {});

            return this.db.object('/').update(updates);
        }
    }

    getQuestion(questionKey: string): Observable<FormQuestion> {
        return this.db
            .object<FormQuestion>(`/ssot/_questions/${questionKey}`)
            .valueChanges()
            .pipe(
                map(question => ({
                    ...question,
                    key: questionKey,
                })),
            );
    }

    getActivityQuestionsCount(activityKey: string): Observable<number> {
        return this.db
            .list<FormQuestion>('/ssot/_questions', ref => ref.orderByChild('activity_id').equalTo(activityKey))
            .valueChanges()
            .pipe(
                map((questions: FormQuestion[]) => {
                    return questions.filter(question => !notQuestionResponseTypes.includes(question.response_type))
                        .length;
                }),
            );
    }

    async addQuestion(formId: string, activityKey: string, question: FormQuestion, appKey: string): Promise<string> {
        const updates = {};
        const questionKey = this.db.createPushId();
        const triggerId = this.db.createPushId();
        const isAllowedWorkbookResponseType = allowedWorkbookResponseTypesMap[question.response_type];
        const initializationData = {
            initialization_type: 'question',
            activity_type: ActivityType.Form,
            question_id: questionKey,
        };
        const newQuestion = {
            ...question,
            response_type: question.response_type || 'text',
            number_responses: 0,
            user_id: this.authUserService.getCurrentUserId(),
        };

        if (question.response_type === 'subform') {
            if (!question.subform_id) {
                newQuestion.subform_id = this.db.createPushId();
                updates[`ssot/_forms/${newQuestion.subform_id}`] = {
                    activity_id: activityKey,
                    parent_form_id: newQuestion.form_id,
                };
            }
        }

        if (isAllowedWorkbookResponseType && !newQuestion.workbook_sheet_id) {
            const workbookSheetId =
                (await this.getFormFieldByKey(formId, 'workbook_sheet_id').pipe(take(1)).toPromise()) || '';

            if (workbookSheetId) {
                newQuestion.workbook_sheet_id = workbookSheetId;
            }
        }

        updates[`ssot/_questions/${questionKey}`] = newQuestion;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        updates[`/initialization_triggers/${triggerId}`] = initializationData;

        const key = await this.db
            .object('/')
            .update(updates)
            .then(() => questionKey);

        try {
            await this.functions
                .httpsCallable(
                    'updateItemsOrder',
                    httpsCallableOptions,
                )({
                    activityId: formId,
                    newSequence: question.sequence,
                    oldSequence: null,
                    activityType: ActivityType.Form,
                    type: 'questions',
                })
                .toPromise();
        } catch (err) {
            console.log('Add question error');
            console.log(err);
        }

        return key;
    }

    addImportQuestions(questions: FormQuestion[], appKey: string): Promise<void> {
        const updates = {};

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        questions.forEach((question: FormQuestion) => {
            const questionKey = this.db.createPushId();
            const triggerId = this.db.createPushId();
            const newQuestion = {
                ...question,
                response_type: question.response_type || 'text',
                number_responses: 0,
                user_id: this.authUserService.getCurrentUserId(),
            };

            updates[`ssot/_questions/${questionKey}`] = newQuestion;
            updates[`/initialization_triggers/${triggerId}`] = {
                initialization_type: 'question',
                activity_type: ActivityType.Form,
                question_id: questionKey,
            };
        });

        return this.db.object('/').update(updates);
    }

    async updateQuestionsOrderAfterSort(
        formId: string,
        appKey: string,
        question: FormQuestion,
        newSequence: number,
    ): Promise<void> {
        const updates = {};

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        updates[`ssot/_questions/${question.key}/sequence`] = newSequence;

        await this.db.object('/').update(updates);

        try {
            await this.functions
                .httpsCallable(
                    'updateItemsOrder',
                    httpsCallableOptions,
                )({
                    activityId: formId,
                    newSequence,
                    oldSequence: question.sequence,
                    activityType: ActivityType.Form,
                    type: 'questions',
                })
                .toPromise();
        } catch (err) {
            console.log('Update questions order after sort error');
            console.log(err);
        }
    }

    updateQuestion(questionKey: string, value: any, appKey: string): Promise<string> {
        const updates = {};

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        if (!questionKey) {
            questionKey = this.db.createPushId();
            updates[`ssot/_questions/${questionKey}`] = value;
        } else {
            // The properties have to be written to individually since this is a multi-path update
            Object.keys(value).forEach(valueKey => {
                updates[`ssot/_questions/${questionKey}/${valueKey}`] = value[valueKey];
            });
        }

        this.db.object('/').update(updates);

        return Promise.resolve(questionKey);
    }

    async updateQuestionField(questionKey: string, field: string, fieldValue: any, appKey: string): Promise<void> {
        const updates = {};

        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();
        updates[`ssot/_questions/${questionKey}/${field}`] = fieldValue;

        return this.db.object('/').update(updates);
    }

    updateQuestionAccepted(questionKey: string, demographicKey?: string): Promise<void> {
        const updates = {};

        updates[`ssot/_questions/${questionKey}/accepted/${demographicKey || ''}`] = true;
        updates[`ssot/_questions/${questionKey}/accepted_owner_id/${demographicKey || ''}`] =
            this.authUserService.getCurrentUserId();

        return this.db.object('/').update(updates);
    }

    updateQuestionChangeDefaultSheet(questionKey: string, state: boolean): Promise<void> {
        const updates = {
            [`ssot/_questions/${questionKey}/change_workbook_sheet`]: !!state,
        };

        return this.db.object('/').update(updates);
    }

    updateQuestionMapOptions(questionKey: string, state: boolean): Promise<void> {
        const updates = {
            [`ssot/_questions/${questionKey}/map_options_enable`]: !!state,
        };

        return this.db.object('/').update(updates);
    }

    async removeQuestion(formId: string, question: FormQuestion, appKey: string): Promise<void> {
        await this.updateDependentChangeManagementData(question, appKey);

        const updates = {};

        updates[`ssot/_questions/${question.key}`] = null;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        if (question.response_type === 'subform') {
            updates[`ssot/_forms/${question.subform_id}`] = null;
        }

        await this.db.object('/').update(updates);

        try {
            await this.functions
                .httpsCallable(
                    'updateItemsOrder',
                    httpsCallableOptions,
                )({
                    activityId: formId,
                    newSequence: null,
                    oldSequence: question.sequence,
                    activityType: ActivityType.Form,
                    type: 'questions',
                })
                .toPromise();
        } catch (err) {
            console.log('Remove question error');
            console.log(err);
        }
    }

    getChoices(questionKey: string): Observable<OptionsChoice[]> {
        return listWithKeys(this.db.list(`ssot/_questions/${questionKey}/options/choices`)).pipe(
            map((choices: OptionsChoice[]) => {
                return sortBySequenceAsc(choices);
            }),
        );
    }

    async addChoice(questionKey: string, choice: OptionsChoice, appKey: string): Promise<string> {
        const updates = {};
        const choiceKey = choice.key;
        const newChoice = new OptionsChoice(choice.sequence, choice.description, choice.description);

        newChoice.key = choiceKey;

        updates[`ssot/_questions/${questionKey}/options/choices/${choiceKey}`] = newChoice;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        const key = await this.db
            .object('/')
            .update(updates)
            .then(() => choiceKey);

        try {
            await this.functions
                .httpsCallable(
                    'updateItemsOrder',
                    httpsCallableOptions,
                )({
                    questionKey,
                    newSequence: choice.sequence,
                    oldSequence: null,
                    activityType: ActivityType.Form,
                    type: 'options',
                })
                .toPromise();
        } catch (err) {
            console.log('Add choice error');
            console.log(err);
        }

        return key;
    }

    async removeChoice(questionKey: string, choice: OptionsChoice, appKey: string): Promise<void> {
        const updates = {};

        updates[`ssot/_questions/${questionKey}/options/choices/${choice.key}`] = null;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        await this.db.object('/').update(updates);
        try {
            await this.functions
                .httpsCallable(
                    'updateItemsOrder',
                    httpsCallableOptions,
                )({
                    questionKey,
                    newSequence: null,
                    oldSequence: choice.sequence,
                    activityType: ActivityType.Form,
                    type: 'options',
                })
                .toPromise();
        } catch (err) {
            console.log('Remove choice error');
            console.log(err);
        }
    }

    recalculateChoicesSequences(questionKey: string, choices: any): Promise<void> {
        if (!choices.length) {
            return Promise.resolve();
        }

        const updates = {};

        choices.forEach((choice, i) => {
            choice.sequence = i + 1;
            updates[`ssot/_questions/${questionKey}/options/choices/${choice.key}`] = choice;
        });

        return this.db.object('/').update(updates);
    }

    async updateQuestionChoice(
        questionKey: string,
        choice: OptionsChoice,
        appKey: string,
        oldSequence: number,
    ): Promise<any> {
        const updates = {};

        if (!choice.key || choice.key === 'new') {
            choice.key = this.db.createPushId();
        }

        updates[`ssot/_questions/${questionKey}/options/choices/${choice.key}`] = choice || null;
        updates[`ssot/_apps/${appKey}/date_last_updated`] = Date.now();
        updates[`apps/${appKey}/date_last_updated`] = Date.now();

        await this.db.object('/').update(updates);

        try {
            await this.functions
                .httpsCallable(
                    'updateItemsOrder',
                    httpsCallableOptions,
                )({
                    questionKey,
                    newSequence: choice.sequence,
                    oldSequence,
                    activityType: ActivityType.Form,
                    type: 'options',
                })
                .toPromise();
        } catch (err) {
            console.log('Update choice error');
            console.log(err);
        }
    }

    updateQuestionMapChoiceAnswer(questionKey: string, choiceKey: string, value: string): Promise<any> {
        const updates = {};

        updates[`ssot/_questions/${questionKey}/options/choices/${choiceKey}/map_option_answer`] = value;

        return this.db.object('/').update(updates);
    }

    getPresetDataOptions(type: string): Observable<any> {
        return this.db.object(`/formComponents/${type}/options`).valueChanges();
    }

    getQuestionDemographics(questionKey: string): Observable<any> {
        return this.db.object(`/ssot/_questions/${questionKey}/demographics`).valueChanges();
    }

    getQuestionOption(questionKey: string, field: string): Observable<any> {
        return this.db.object(`/ssot/_questions/${questionKey}/options/${field}`).valueChanges();
    }

    getDefaults(): Observable<any> {
        return this.db
            .object('/formComponents/defaults')
            .valueChanges()
            .pipe(
                map(defaults => {
                    return {
                        options: Object.keys(defaults['options'])
                            .map(optionKey => {
                                return {
                                    key: optionKey,
                                    ...defaults['options'][optionKey],
                                };
                            })
                            .sort((a, b) => a.sequence - b.sequence),
                    };
                }),
            );
    }

    getCustomOptions(type: string): Observable<any[]> {
        const key = this.mapTypeToKey(type);

        return listWithKeys(this.db.list(`/formComponents/${key}/options`)).pipe(
            map(options => sortBySequenceAsc(options)),
        );
    }

    getTypingUsersForQuestion(
        questionKey: string,
        parentDemographicKey: string,
        demographicKey?: string,
    ): Observable<UserEvent[]> {
        const basePath = ['typing', 'question', questionKey, parentDemographicKey].filter((node: string) => !!node);

        // subscribe to typing event in case with or without demographic
        const typing$ = !!demographicKey
            ? this.userEventsService.getEvents(basePath.concat(demographicKey))
            : this.userEventsService.getEvents(basePath);

        const latestTyping$ = typing$.pipe(
            map((userEvents: UserEvent[]) => {
                const delimiter = Date.now() - 1000;

                // get events for each user
                // each event is not older than 1 second
                // so per unit of time we have only latest typing typing for question
                const latestUserEvents: { [userKey: string]: UserEvent } = {};

                userEvents.forEach((userEvent: UserEvent) => {
                    const userKey = userEvent.key;
                    const latestUserEvent = latestUserEvents[userKey];
                    const isLatest = userEvent.created_at >= delimiter;
                    const canNotBeRewritten = !!latestUserEvent && userEvent.created_at < latestUserEvent.created_at;

                    if (isLatest && !canNotBeRewritten) {
                        latestUserEvents[userKey] = userEvent;
                    }
                });

                return Object.values(latestUserEvents);
            }),
        );

        // this stream will emit value only when typing stream will not emit values for 1 second
        const typingStopped$ = typing$.pipe(
            debounceTime(1000),
            map(() => []),
        );

        return merge(latestTyping$, typingStopped$);
    }

    pushTypingEvent(questionKey: string, parentDemographicKey?: string, demographicKey?: string): Promise<void> {
        const pathToQuestionTyping = ['typing', 'question', questionKey, parentDemographicKey, demographicKey].filter(
            (node: string) => {
                return !!node;
            },
        );
        const userKey = this.authUserService.getCurrentUserId();
        const { firstName, lastName, email, image_url } = this.authUserService.getCurrentUser();
        const userData = <UserEvent>{
            full_name: this.authUserService.getCurrentUserFullName(),
            firstName,
            lastName,
            email,
            image_url: image_url || null,
        };

        return this.userEventsService.triggerEvent(pathToQuestionTyping, userKey, userData);
    }

    resetQuestionAccepted(questionKey: string, demographicKey?: string): Promise<void> {
        const updates = {};

        updates[`ssot/_questions/${questionKey}/accepted/${demographicKey || ''}`] = false;
        updates[`ssot/_questions/${questionKey}/accepted_owner_id/${demographicKey || ''}`] = '';

        return this.db.object('/').update(updates);
    }

    async addRepeatableSection(
        activityKey: string,
        parentQuestionKey: string,
        repeatableQuestionsKeys: RepeatableQuestionsIds,
        times: number,
        formKeyToCopyAnswers: string | null,
        demographicKey?: string,
        formStatus?: string,
    ): Promise<string> {
        const updates = {};
        const questionsKeys = Object.keys(repeatableQuestionsKeys);
        const repeatableQuestionsKeysAfterRepeat = {
            ...repeatableQuestionsKeys,
        };
        const newSectionsQuestionsKeys = [];

        Array.from(Array(times)).forEach((_, i) => {
            const newSectionQuestionKey = this.db.createPushId();

            if (!demographicKey) {
                updates[`/ssot/_questions/${parentQuestionKey}/repeatable_questions_ids/${newSectionQuestionKey}`] =
                    true;
                newSectionsQuestionsKeys.push(newSectionQuestionKey);
            } else {
                // if current repeat is first time and section exists - get section question existing key
                const existSectionQuestionKey = !!demographicKey
                    ? questionsKeys.find((questionKey: string) => {
                          const demographicsMap = repeatableQuestionsKeysAfterRepeat[questionKey];

                          return !demographicsMap[demographicKey];
                      })
                    : null;

                if (existSectionQuestionKey) {
                    repeatableQuestionsKeysAfterRepeat[existSectionQuestionKey][demographicKey] = true;
                }
                const sectionQuestionKeyToWrite = existSectionQuestionKey || newSectionQuestionKey;

                updates[
                    `/ssot/_questions/${parentQuestionKey}/repeatable_questions_ids/${sectionQuestionKeyToWrite}/${demographicKey}`
                ] = true;
                newSectionsQuestionsKeys.push(sectionQuestionKeyToWrite);
            }
        });
        await this.db.object('/').update(updates);

        await this.functions
            .httpsCallable(
                'repeatSection',
                httpsCallableOptions,
            )({
                parentQuestionKey,
                demographicKey,
                newSectionsQuestionsKeys,
                formKeyToCopyAnswers,
                formStatus,
                ownerKey: this.authUserService.getCurrentUserId(),
            })
            .toPromise();

        this.functions
            .httpsCallable(
                'validateForm',
                httpsCallableOptions,
            )({
                activityId: activityKey,
            })
            .toPromise();

        return newSectionsQuestionsKeys[0] || ''; // Return key of the first repeated section to navigate to proper section
    }

    async removeRepeatableSection(
        parentQuestionKey: string,
        repeatableQuestionKey: string,
        demographicKey?: string,
    ): Promise<void> {
        const path = !demographicKey
            ? `/ssot/_questions/${parentQuestionKey}/repeatable_questions_ids/${repeatableQuestionKey}`
            : `/ssot/_questions/${parentQuestionKey}/repeatable_questions_ids/${repeatableQuestionKey}/${demographicKey}`;

        await this.db.object(path).remove();
    }

    getActivityAnsweredQuestions(sessionKey: string, activityKey: string): Observable<AnsweredQuestionModel> {
        return this.db
            .object<AnsweredQuestionModel>(`/ssot/_answered_questions/${sessionKey}/${activityKey}`)
            .valueChanges();
    }

    generateDatabaseKey(): string {
        return this.db.createPushId();
    }

    private async updateDependentChangeManagementData(question: FormQuestion, appKey: string): Promise<void> {
        try {
            const userId = this.authUserService.getCurrentUserId();

            await this.functions
                .httpsCallable(
                    'updateDependentChangeManagementData',
                    httpsCallableOptions,
                )({
                    projectId: appKey,
                    activityId: question.activity_id,
                    questionId: question.key,
                    subformId: question.response_type === 'subform' ? question.subform_id : null,
                    userId,
                })
                .toPromise();
        } catch (e) {
            console.error(e, 'error while updating change management data');
        }
    }

    private mapTypeToKey(type: string): string {
        switch (type) {
            case 'number':
                return 'number';
        }
    }
}
