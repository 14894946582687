export interface Tag {
    name: string;
    key?: string;
    count?: number;
    date_created?: number;
}

export enum OptionChangeTagCount {
    increment = 'increment',
    decrement = 'decrement',
}

export type TypesOfTagSorting = 'frequency_of_use' | 'tag_name';
