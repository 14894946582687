import { ReviewState } from './review.model';
import { responseType } from './question.model';

export type collaborationActivityType = 'vote' | 'source';

export class FormCollaborationRequest {
    app_id: string;
    activity_id: string;
    question_id: string;
    response_type: responseType;
    participant_id: string;
    date_created: number;
    type: collaborationActivityType;
    state: ReviewState;
    instance_url: string; // for email approve/deny
    collaboration_activity_id?: string;
    collaboration_activity_status?: string;
    key?: string;
    description?: string;
    reviewer_id?: string;
    demographics?: string[];
    comment?: string; // comment added when approved/disapproved request
    had_other_option?: boolean;
    parent_demographic_id?: string;

    constructor(
        app_id: string,
        activity_id: string,
        question_id: string,
        response_type: responseType,
        type: collaborationActivityType = 'source',
        state: ReviewState = ReviewState.Under_Review,
        instance_url: string,
        description: string = '',
        had_other_option?: boolean, // field for multi select with other option
        demographics?: string[],
        parent_demographic_id?: string
    ) {
        this.app_id = app_id;
        this.activity_id = activity_id;
        this.question_id = question_id;
        this.response_type = response_type;
        this.type = type;
        this.state = state;
        this.instance_url = instance_url;
        this.description = description;
        this.had_other_option = had_other_option || null;
        this.demographics = demographics || null;
        this.parent_demographic_id = parent_demographic_id || null;
    }
}

export const testFormCollaborationRequest = <FormCollaborationRequest>{
    app_id: 'appKey',
    activity_id: 'activityKey',
    question_id: 'questionKey',
    response_type: 'short_answer',
    participant_id: 'creatorKey',
    date_created: 1548251646043,
    type: 'source',
    key: 'requestKey',
    description: 'description',
    state: ReviewState.Under_Review
};

export const approvedCollaborationRequest = {
    ...testFormCollaborationRequest,
    collaboration_activity_id: 'activityKey',
    state: ReviewState.Approved
};

export const testCollaborationRequestWithDemographics = <FormCollaborationRequest>{
    app_id: 'appKey',
    activity_id: 'activityKey',
    question_id: 'questionKey',
    response_type: 'short_answer',
    participant_id: 'creatorKey',
    date_created: 1548251646043,
    type: 'source',
    key: 'requestKey',
    description: 'description',
    state: ReviewState.Under_Review,
    demographics: ['testDemographicKey']
};
