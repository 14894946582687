<div class="workbook-export-dialog">
  <div mat-dialog-title class="dialog-header">
    <h3 class="h3 text-dark-primary">{{data?.title}}</h3>
    <mat-icon
      matSuffix
      class="close-icon"
      (click)="dialogRef.close()"
    >
      close
    </mat-icon>
  </div>
  <div class="loading" *ngIf="!(workbooks$ | async) as workbooks">
    <mat-spinner class="loading"></mat-spinner>
  </div>
  <div class="dialog-content thinktank-theme" *ngIf="(workbooks$ | async) as workbooks" [formGroup]="exportedWorkbook">
    <p class="text text-dark-primary dialog-text">{{data?.text}}</p>
    <mat-checkbox *ngFor="let workbook of workbooks; trackBy: trackByKey" [formControlName]="workbook.key">
      {{workbook.name}}
    </mat-checkbox>
  </div>

  <mat-dialog-actions>
    <button
      class="tt-button-filled"
      [disabled]="this.exportedWorkbook?.invalid"
      (click)="handleConfirm()"
    >
      {{data.confirmBtnText}}
    </button>
    <button mat-dialog-close class="tt-button-outline">{{data.cancelBtnText}}</button>
  </mat-dialog-actions>
</div>
