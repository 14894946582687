import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

import { FormHistoryResponse, FormHistoryResponseComment } from '../../models';
import { DialogService } from '../../services/dialog.service';
import { FirestoreService } from '../../services/firestore.service';
import { ResponseHistoryService } from '../../services/response-history.service';
import { confirmationDeleteCommentModalText, secondConfirmationDeleteCommentModalText } from '../dialogs/dialog.constants';

@Component({
    selector: 'gs-response-history-card-container',
    templateUrl: './response-history-card-container.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponseHistoryCardContainerComponent implements OnInit {
    @Input() formId: string;
    @Input() questionId: string;
    @Input() currentUserId: string;
    @Input() currentUserFullName: string;
    @Input() response: FormHistoryResponse;
    @Input() hasDependentQuestions: boolean;
    @Input() demographicId?: string;
    @Input() secondaryDemographicId?: string;
    @Input() shouldOpenResponseComments?: boolean;
    @Input() isUserLeader?: boolean;
    @Input() isFocused?: boolean;
    @Input() canFocus?: boolean;
    @Output() onToggleFocus = new EventEmitter<FormHistoryResponse>();

    historyResponseComments$: Observable<FormHistoryResponseComment[]>;
    isCommentDisabled$ = new BehaviorSubject<boolean>(false);

    constructor(
        private _responseHistoryService: ResponseHistoryService,
        private _firestoreService: FirestoreService,
        private _dialogService: DialogService
    ) { }

    async toggleResponseUpvote(): Promise<void> {
        await this._responseHistoryService.toggleUpvote(
            this.response && this.response.id,
            this.currentUserId,
            this.questionId,
            this.demographicId,
            this.secondaryDemographicId
        );
    }

    async addComment(value: string): Promise<void> {
        this.isCommentDisabled$.next(true);
        const timestamp = this._firestoreService.timestamp;

        const newComment = {
            owner_id: this.currentUserId,
            owner_full_name: this.currentUserFullName,
            value,
            date_created: timestamp
        } as FormHistoryResponseComment;

        await this._responseHistoryService.addComment(
            newComment,
            this.response && this.response.id,
            this.formId,
            this.questionId,
            this.demographicId,
            this.secondaryDemographicId
        );
        this.isCommentDisabled$.next(false);
    }

    async updateComment(comment: { id: string, value: string }): Promise<void> {
        await this._responseHistoryService.updateComment(
            comment,
            this.response && this.response.id,
            this.questionId,
            this.demographicId,
            this.secondaryDemographicId
        );
    }

    async deleteComment({ id, owner_id }: FormHistoryResponseComment): Promise<void> {
        await this._responseHistoryService.deleteComment(
            id,
            this.response && this.response.id,
            owner_id,
            this.questionId,
            this.demographicId,
            this.secondaryDemographicId
        );
    }

    openDeleteCommentDialog(comment: FormHistoryResponseComment, isFinal?: boolean): void {
        this._dialogService.open('ConfirmActionDialogComponent', {
            title: 'Remove comment',
            text: (!isFinal)
                ? confirmationDeleteCommentModalText
                : secondConfirmationDeleteCommentModalText,
            confirmBtnText: 'Remove',
            handleConfirm: () => {
                (isFinal)
                    ? this.deleteComment(comment)
                    : this.openDeleteCommentDialog(comment, true);
            }
        });
    }

    toggleFocus(response: FormHistoryResponse): void {
        this.onToggleFocus.emit(response);
    }

    ngOnInit() {
        this.historyResponseComments$ = this._responseHistoryService.getHistoryResponseComments(
            this.response && this.response.id,
            this.questionId,
            this.demographicId,
            this.secondaryDemographicId
        );
    }
}
