import { Observable, interval, Subject } from 'rxjs';
import { filter, map, mergeMap, take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import jwtDecode from 'jwt-decode';
import * as cryptoJS from 'crypto-js';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Store } from '@ngrx/store';

import { AuthenticatedUserService } from '@app/core/services/authenticated-user.service';
import { FirebaseUsersService } from '@app/core/services/users.service';
import { AwsCognitoAuthService } from '@app/core/services/aws-cognito-auth.service';
import { DbTokenGenerationService } from '@app/login/services/db-token-generation.service';
import { FirebaseAuthenticationService } from '@app/core/services/firebase-authentication.service';
import { TokenData } from '@app/login/services/token-data';
import { environment } from '@env/environment';
import { AuthState } from '@app/root-store/features/auth/state';
import { AuthStoreActions } from '@app/root-store/features/auth';

interface ResponseData {
    key: string;
    use_passkey: boolean;
    email_required: boolean;
    is_passkey_empty: boolean;
}

@Injectable()
export class LoginService {
    guestSubject = new Subject<any>();

    constructor(
        private db: AngularFireDatabase,
        private awsCognitoAuthService: AwsCognitoAuthService,
        private dbTokenGenerationService: DbTokenGenerationService,
        private firebaseAuthenticationService: FirebaseAuthenticationService,
        private authenticatedUserService: AuthenticatedUserService,
        private usersService: FirebaseUsersService,
        private store: Store<AuthState>,
        private functions: AngularFireFunctions,
    ) {}

    generateLoginStateKey(): string {
        return this.db.createPushId();
    }

    finishLogin(url: string): Observable<void> {
        let cognitoUserData;

        // Handle the tokens returned from the Cognito authentication
        this.awsCognitoAuthService.parseTokens(url);

        // wait until cognito receive id token
        return interval(500).pipe(
            map(() => {
                return this.awsCognitoAuthService.getIdToken();
            }),
            filter(apiToken => !!apiToken),
            take(1),
            mergeMap(apiToken => {
                cognitoUserData = jwtDecode(apiToken);
                const tokenData: TokenData = this.createTokenData(cognitoUserData);

                // Get a firebase token from the serverless token generator
                return this.dbTokenGenerationService.generateFirebaseToken(tokenData, apiToken);
            }),
            mergeMap((tokenResponse: any) => {
                // Authenticate the user with Firebase
                return this.firebaseAuthenticationService.authenticate(tokenResponse.token);
            }),
            mergeMap(() => {
                this.store.dispatch(AuthStoreActions.initializeCurrentUser({ cognitoUserData }));

                // Initialize the AuthenticatedUserService with the current user
                return this.authenticatedUserService.initializeCurrentUser(cognitoUserData);
            }),
        );
    }

    directLogin(token: string): Observable<firebase.auth.UserCredential> {
        return this.firebaseAuthenticationService.authenticate(token);
    }

    private createTokenData(apiTokenData: any): TokenData {
        return {
            username: apiTokenData.email.toLowerCase(),
            env: environment.name,
        };
    }

    getGuestSubject(): Observable<any> {
        return this.guestSubject.asObservable();
    }

    signInGuestUser(
        guestPushId: string,
        appKey: string,
        guestFirstName: string,
        guestLastName: string,
        guestEmail?: string,
    ): void {
        firebase.auth().onAuthStateChanged(async user => {
            if (user && user.isAnonymous) {
                if (!guestPushId) {
                    const emailOrUid = guestEmail ? guestEmail.toLowerCase() : user.uid;
                    const userToAdd = {
                        created_at: Date.now(),
                        email: emailOrUid,
                        firstName: guestFirstName,
                        lastName: guestLastName,
                        username: emailOrUid,
                        active: true, // a guest user is active by default
                        guest: true,
                        app_instances: {
                            [appKey]: 'participant',
                        },
                    };

                    const pushId = user.uid;

                    await this.authenticatedUserService.initializeGuestUser(user, pushId);
                    await this.usersService.addGuestUser(pushId, userToAdd, appKey);
                    await this.usersService.addUserToInstance(pushId, appKey);
                    this.guestSubject.next(pushId);
                } else {
                    await this.authenticatedUserService.initializeGuestUser(user, guestPushId);
                    await this.usersService.addUserToInstance(guestPushId, appKey);
                    this.guestSubject.next(guestPushId);
                }
            }
        });

        firebase
            .auth()
            .signInAnonymously()
            .catch(error => {
                console.log('Anonymous sign in failed!');
                console.log('Error Code: ', error.code);
                console.log('Error Message: ', error.message);
            });
    }

    async findAppByPasskeyGUID(passkeyGUID: string): Promise<ResponseData> {
        return this.functions.httpsCallable('findAppByPasskeyGuid')({ passkeyGUID }).toPromise();
    }

    async validatePasscode(appId: string, passcode: string): Promise<boolean> {
        const data = {
            app_id: appId,
            password: passcode,
        };
        const response = await this.functions.httpsCallable('validateSessionPassword')(data).toPromise();
        const decrypted = cryptoJS.AES.decrypt(response, appId);
        const result = JSON.parse(decrypted.toString(cryptoJS.enc.Utf8));

        return !!result.valid;
    }
}
