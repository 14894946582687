import {
    allAcceptedEXCELFileTypes,
    allAcceptedFileTypes,
    allAcceptedImageTypes,
    allAcceptedPresentationTypes,
} from '@thinktank/common-lib';
import { PickerOptions } from 'filestack-js';

export const filestackFilesSources = ['local_file_system', 'googledrive'];

const defaultConfig = {
    dropPane: {},
    exposeOriginalFile: true,
    fromSources: filestackFilesSources,
    minFiles: 1,
};

export const imagesUploadConfig: PickerOptions = {
    ...defaultConfig,
    accept: allAcceptedImageTypes,
    maxFiles: 1,
};

export const filesUploadConfig = {
    ...defaultConfig,
    accept: allAcceptedFileTypes,
    maxFiles: 20,
};

export const excelFilesUploadConfig = {
    ...defaultConfig,
    accept: allAcceptedEXCELFileTypes,
    maxFiles: 20,
};

export const presentationFilesUploadConfig = {
    ...defaultConfig,
    accept: allAcceptedPresentationTypes,
    maxFiles: 1,
};
