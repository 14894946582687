<div class="confirm-dialog thinktank-theme">
  <h3 class="h3 dialog-title">{{data.title}}</h3>
  <mat-dialog-content>
    <mat-form-field class="large first-input" appearance="outline" floatLabel="always">
      <mat-label>First Name</mat-label>
      <input
        matInput
        required
        type="text"
        [formControl]="firstName"
      />
      <mat-error>This field is required</mat-error>
    </mat-form-field>
    <mat-form-field class="large second-input" appearance="outline" floatLabel="always">
      <mat-label>Last Name</mat-label>
      <input
        matInput
        required
        type="text"
        [formControl]="lastName"
      />
      <mat-error>This field is required</mat-error>
      </mat-form-field>
  </mat-dialog-content>
  <mat-dialog-actions class="alert-actions">
    <button
      cdkFocusInitial
      class="tt-button-filled"
      [disabled]="isDisabled"
      (click)="onSubmit()"
    >
      {{data.confirmBtnText}}
    </button>
    <button mat-dialog-close class="tt-button-outline">{{data.cancelBtnText}}</button>
  </mat-dialog-actions>
</div>
